import { db, auth } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
  doc,
} from "firebase/firestore";

const LockBoxService = {
  fetchDocumentsFromLockbox: async (userId, collectionName) => {
    try {
      const documentsCollectionRef = collection(
        db,
        "lockbox",
        userId,
        collectionName
      );
      const documentsSnapshot = await getDocs(documentsCollectionRef);

      const documentsList = documentsSnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
        };
      });

      return documentsList;
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  },

  addDocumentToLockbox: async (userId, documentData, type) => {
    try {
      const documentsCollectionRef = collection(db, "lockbox", userId, type);

      await addDoc(documentsCollectionRef, documentData);

      console.log("Document successfully added to the lockbox!");
    } catch (error) {
      console.error("Error adding document to lockbox: ", error);
      throw error;
    }
  },

  updateDocumentInLockbox: async (userId, documentId, documentData, type) => {
    try {
      if (!documentId) {
        throw new Error("documentId is required for updating.");
      }

      const documentsCollectionRef = collection(db, "lockbox", userId, type);
      
      // Use the appropriate ID field based on the collection type
      const idField = type === "photos_data" ? "PhotosID" : "documentsID";
      
      const querySnapshot = await getDocs(
        query(documentsCollectionRef, where(idField, "==", documentId))
      );

      if (querySnapshot.empty) {
        console.error(`No document found with ID: ${documentId} in ${type}`);
        return;
      }

      const docRef = querySnapshot.docs[0].ref; // Get the actual document reference

      await updateDoc(docRef, documentData);

      console.log(`Document ${documentId} successfully updated in ${type}!`);
    } catch (error) {
      console.error("Error updating document in lockbox:", error);
      throw error;
    }
  },

  softDeleteItemInLockbox: async (userId, documentId, type) => {
    try {
      if (!documentId) {
        throw new Error("documentId is required");
      }

      const documentsCollectionRef = collection(db, "lockbox", userId, type);
      const documentRef = doc(documentsCollectionRef, documentId);

      // Update the document to mark it as deleted instead of actually deleting it
      const updatedData = {
        is_deleted: true,
        modified_date: new Date().toLocaleDateString("en-US"),
      };

      await updateDoc(documentRef, updatedData);
      console.log("Document successfully marked as deleted in lockbox!");
    } catch (error) {
      console.error("Error marking document as deleted in lockbox: ", error);
      throw error;
    }
  },

  // Delete a document from the "documents_data" collection based on documentId
  deleteDocumentFromLockbox: async (userId, documentId, type) => {
    try {
      // Log documentId to check if it's defined
      console.log("Deleting document with ID:", documentId);

      if (!documentId) {
        throw new Error("documentId is undefined or null");
      }

      // Create a query to find the document with the given documentId
      const documentsCollectionRef = collection(db, "lockbox", userId, type);
      const idField = type === "documents_data" ? "documentsID" : "PhotosID";

      const q = query(documentsCollectionRef, where(idField, "==", documentId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Delete each matching document (should be only one in your case)
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await deleteDoc(documentRef);
        });
        console.log("Document successfully deleted from the lockbox!");
      } else {
        console.error("No document found with the given ID to delete.");
        throw new Error("No document found with the given ID to delete.");
      }
    } catch (error) {
      console.error("Error deleting document from lockbox: ", error);
      throw error;
    }
  },

  submitAccessRequest: async ({ userId, documents, message, requested_by, requested_by_org, requested_by_role, requested_by_name }) => {
    try {
      const requestData = {
        documents,
        message,
        status: 'pending',
        requested_by,
        requested_by_name,
        requested_by_org,
        requested_by_role,
        requested_at: new Date().toISOString(),
        is_deleted: false,
      };
  
      const accessRequestsRef = collection(db, "lockbox", userId, "access_requests");
      await addDoc(accessRequestsRef, requestData);
      console.log("Access request successfully added to the lockbox!");
    } catch (error) {
      console.error("Error submitting access request:", error);
      throw error;
    }
  },

  copyNewPartnerDocument: async (userId, newDocument) => {
    console.log("newDocument", newDocument)
    try {
      if (!userId || !newDocument) {
        console.error("Missing userId or document data.");
        return;
      }

      // ✅ Check if the document is Active before copying
      if (newDocument.status !== "Active") {
        console.error(
          `Document ${newDocument.documentsID} is not Active. Skipping copy.`
        );
        return;
      }

      console.log(
        `🔄 Copying new Active document ${newDocument.documentsID} to center_documents...`
      );

      // Step 1: Find all users whose center_id array contains userId
      const usersRef = collection(db, "users");
      const usersQuery = query(
        usersRef,
        where("center_id", "array-contains", userId)
      );
      const usersSnapshot = await getDocs(usersQuery);

      if (usersSnapshot.empty) {
        console.error("No users found with matching center_id.");
        return;
      }

      const matchedUsers = usersSnapshot.docs.map((doc) => doc.id);

      // Step 2: Copy the new document to each user's center_documents
      for (const matchedUserId of matchedUsers) {
        const userLockboxRef = collection(
          db,
          "lockbox",
          matchedUserId,
          "center_documents"
        );

        const newDocumentData = {
          ...newDocument,
          documentsID: generateUniqueId(),
          original_documentsID: newDocument.documentsID,
          copied_from_center: userId,
          copied_at: new Date().toISOString(),
          is_deleted: false,
        };

        await addDoc(userLockboxRef, newDocumentData);
        console.log(
          `Copied new Active document ${newDocument.documentsID} to user ${matchedUserId}.`
        );
      }
    } catch (error) {
      console.error("Error copying new partner document:", error);
    }
  },

  updateCopyPartnerDocuments: async (userId, documentId, updatedDocument) => {
    try {
      if (!userId || !documentId) {
        console.error("Missing userId or documentId.");
        return;
      }

      console.log(
        `🔄 Checking document ${documentId} in center_documents for updates...`
      );

      // Step 1: Find all users whose center_id array contains userId
      const usersRef = collection(db, "users");
      const usersQuery = query(
        usersRef,
        where("center_id", "array-contains", userId)
      );
      const usersSnapshot = await getDocs(usersQuery);

      if (usersSnapshot.empty) {
        console.error("No users found with matching center_id.");
        return;
      }

      const matchedUsers = usersSnapshot.docs.map((doc) => doc.id);

      // Step 2: Loop through each matched user
      for (const matchedUserId of matchedUsers) {
        const userLockboxRef = collection(
          db,
          "lockbox",
          matchedUserId,
          "center_documents"
        );

        // Step 3: Check if a document with the same `original_documentsID` exists
        const existingDocQuery = query(
          userLockboxRef,
          where("original_documentsID", "==", documentId)
        );
        const existingDocSnapshot = await getDocs(existingDocQuery);

        if (!existingDocSnapshot.empty) {
          // **Found an existing document matching original_documentsID**
          const existingDoc = existingDocSnapshot.docs[0];
          const existingDocRef = doc(
            db,
            "lockbox",
            matchedUserId,
            "center_documents",
            existingDoc.id
          );
          const existingDocData = existingDoc.data();

          // Step 4: Check if the document has `partner_signature` or `partner_acknowledged`
          if (
            existingDocData.partner_signature ||
            existingDocData.partner_acknowledged
          ) {
            console.log(
              `Document ${documentId} has signature/acknowledgment. Changing originalId & creating a new copy.`
            );

            await updateDoc(existingDocRef, {
              original_documentsID: generateUniqueId(),
            });

            // **Create a new copy**
            const updatedDescription = updatedDocument.description?.includes(
              "(Updated)"
            )
              ? updatedDocument.description
              : `${updatedDocument.description} (Updated)`;

            const newDocumentData = {
              ...updatedDocument,
              documentsID: generateUniqueId(),
              original_documentsID: documentId,
              copied_from_center: userId,
              copied_at: new Date().toISOString(),
              is_deleted: false,
              description: updatedDescription,
            };

            await addDoc(userLockboxRef, newDocumentData);
            console.log(
              `Created a new copy of document ${documentId} for user ${matchedUserId}.`
            );
          } else {
            // **Update the existing document if not signed or acknowledged**
            console.log(
              `🔄 Updating existing document ${documentId} for user ${matchedUserId}.`
            );
            await updateDoc(existingDocRef, {
              ...updatedDocument,
              copied_at: new Date().toISOString(),
            });
          }
        } else {
          // **Step 5: If no document matches, create a new copy**
          console.log(
            `No existing copy found. Creating new document ${documentId} for user ${matchedUserId}.`
          );

          const newDocumentData = {
            ...updatedDocument,
            documentsID: generateUniqueId(),
            original_documentsID: documentId,
            copied_from_center: userId,
            copied_at: new Date().toISOString(),
            is_deleted: false,
          };

          await addDoc(userLockboxRef, newDocumentData);
        }
      }
    } catch (error) {
      console.error("Error updating/copying partner documents:", error);
    }
  },
};

function generateUniqueId(length = 28) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export default LockBoxService;
