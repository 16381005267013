import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(() => {
    return sessionStorage.getItem("userRole") || "citizen";
  });
  const [userId, setUserId] = useState(() => {
    return sessionStorage.getItem("userId") || "";
  });

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    setIsAuthenticated(!!accessToken);
  }, []);

  const login = (token) => {
    sessionStorage.setItem("accessToken", token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("employeeId");
    setIsAuthenticated(false);
    // Sign out from Firebase authentication
    const auth = getAuth();
    signOut(auth).catch((error) => {
      console.error("Error signing out from Firebase:", error);
    });
    console.log("Signed out");
  };

  const setRoleBasedRoute = (r) => {
    sessionStorage.setItem("userRole", r);
    setRole(r);
  };

  const setUserIdToUse = (id) => {
    sessionStorage.setItem("userId", id);
    setUserId(id);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        role,
        setRoleBasedRoute,
        login,
        logout,
        setUserIdToUse,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
