import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import LockImage from "../../assets/lockbox.png";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import LockBoxService from "../../service/citizen/LockboxService";
import { ImageDialog } from "./../Disasters/AssistanceLog";
import { db, auth } from "../../firebase";
import {
  and,
  collection,
  doc,
  getDoc,
  query,
  where,
  or,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { FaTrash } from "react-icons/fa";
import { Query } from "@syncfusion/ej2-data";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { Controller, useForm } from "react-hook-form";
import Calendar from "../../assets/disaster-icons/calendar.svg";
import NavigationArrow from "../../assets/disaster-icons/navigation_arrow.svg";
import panZoomIcon from "../../assets/disaster-icons/pan_zoom.svg";
import AddNewButton from "../../components/AddNewButton";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomTextArea from "../../components/CustomTextArea";
import DeleteModal from "../../components/DeleteModal";
import Modal from "../../components/Modal";
import ModalFileViewer from "../../components/ModalFileViewer";
import PdfViewer from "../../components/PDFViewer";
import PdfViewerMultiplePage from "../../components/PDFViewerMultiplePage";
import PhotoColumn from "../../components/PhotoColumn";
import SimpleFileUploader from "../../components/SimpleFileUploader";
import ThreeDotMenu from "../../components/ThreeDotMenu";
import ViewPhotosModal from "../../components/ViewPhotosModal";
import ViewPhotoSignModal from "../../components/ViewPhotoSignModal";
import { decrypt } from "../../service/encryption/Encryption";
import HeaderTitle from "../../components/HeaderTitle";
import ShowPin from "./../Profile-Popup/ShowPin";

const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  mode: "Dialog",
  showDeleteConfirmDialog: true,
  allowEditOnDblClick: false,
  dialog: { cssClass: "custom-dialog" },
};

const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    value: new Date(),
    showClearButton: false,
    showTodayButton: false,
    start: "Decade",
    depth: "Day",
  },
};

const documentTypes = [
  { label: "Driver's License", value: "Driver's License" },
  {
    label: "Credit Cards/Banking Information",
    value: "Credit Cards/Banking Information",
  },
  { label: "Insurance Information", value: "Insurance Information" },
  { label: "Passport", value: "Passport" },
  { label: "Titles/Deed/Registration", value: "Titles/Deed/Registration" },
];

const mouTypes = [
  { label: "MOU", value: "MOU" },
  { label: "Driver's License", value: "Driver's License" },
  {
    label: "Credit Cards/Banking Information",
    value: "Credit Cards/Banking Information",
  },
  { label: "Insurance Information", value: "Insurance Information" },
  { label: "Passport", value: "Passport" },
  { label: "Titles/Deed/Registration", value: "Titles/Deed/Registration" },
];

const typeParams = {
  params: {
    allowFiltering: true,
    dataSource: documentTypes,
    fields: { text: "idName", value: "idName" },
    query: new Query(),
  },
};

const PartnerLockbox = ({ className = "", onNavigateBack }) => {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState("");
  const [correctPIN, setCorrectPIN] = useState("1111");
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [PhotosData, setPhotosData] = useState([]);
  const [centerDocumentsData, setCenterDocumentsData] = useState([]);
  const [accessOptions, setAccessOptions] = useState([]);
  const [showPinInstructions, setShowPinInstructions] = useState(
    localStorage.getItem("pinMessageShown") !== "true"
  );
  const [pinShow, setPinShow] = useState(false);
  const selectedUserId = sessionStorage.getItem("userId");

  // PHOTOS
  const [showViewPhotosModal, setShowViewPhotosModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);

  // DOCUMENTS
  const [showViewDocumentsModal, setShowViewDocumentsModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  //PARTNERS DOCUMENTS
  const [showViewCenterDocumentsModal, setShowViewCenterDocumentsModal] =
    useState(false);
  const [selectedCenterDocument, setSelectedCenterDocument] = useState(null);
  const [selectedCenterDocumentId, setSelectedCenterDocumentId] =
    useState(null);
  const [showViewCenterDocumentSignModal, setShowViewCenterDocumentSignModal] =
    useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);

        const userDocc = doc(db, "users", selectedUserId);
        const userDocSnap = await getDoc(userDocc);
        // Fetch the user's PIN
        const userDocRef = doc(db, "partners", userDocSnap.data().partner_id);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().pin) {
          setCorrectPIN(decrypt(userDoc.data().pin));
          if (decrypt(userDoc.data().pin) === "7777") {
            setTimeout(() => setPinShow(true), 100);
          }
        }
        // If no PIN is set, it will use the default '1111'
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Update the data fetching effect to use userId
  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });

        const documents = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "documents_data"
        );

        const activeDocuments = documents.filter((doc) => !doc.is_deleted);

        setDocumentsData(activeDocuments);

        const centerDocuments = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "center_documents"
        );

        const activeCenterDocuments = centerDocuments.filter(
          (doc) => doc.is_deleted !== true
        );

        setCenterDocumentsData(activeCenterDocuments);

        const photos = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "photos_data"
        );

        // Filter out photos with status set to false
        const activePhotos = photos.filter((photo) => !photo.is_deleted);

        setPhotosData(activePhotos);
        let centerId = userDoc.data().center_id;
        // If center_id is empty, set it to "1" to avoid matching with documents with empty center_id
        if (centerId === "") {
          centerId = "1234";
        }

        const q = query(
          collection(db, "users"),
          and(
            or(
              where("center_id", "array-contains", centerId),
              where("center_id", "==", centerId)
            ),
            or(
              where("user_role", "==", "center"),
              where("user_role", "==", "partner")
            )
          )
        );
        const querySnapshot = await getDocs(q);

        const options = await Promise.all(
          querySnapshot.docs.map(async (document) => {
            const userData = document.data();
            console.log("userData: ", userData);
            setData(userData);

            if (userData.user_role === "center") {
              const centerDocRef = doc(db, "centers", userData.center_id);
              const centerDoc = await getDoc(centerDocRef);

              if (centerDoc.exists()) {
                const centerData = centerDoc.data();
                console.log("Center Data: ", centerData);
                return {
                  value: centerData.name_of_hub,
                  text: `${centerData.name_of_hub} (Center)`,
                };
              } else {
                console.error("No such center document!");
              }
            } else if (userData.user_role === "partner") {
              const partnerDocRef = doc(db, "partners", userData.partner_id);
              const partnerDoc = await getDoc(partnerDocRef);
              if (partnerDoc.exists()) {
                const partnerData = partnerDoc.data();
                console.log("Partner Data: ", partnerData);
                return {
                  value: partnerData.name_of_org,
                  text: `${partnerData.name_of_org} (Partner)`,
                };
              } else {
                console.error("No such partner document!");
              }
            }

            return null; // Ensure a valid return to avoid empty elements in the final array
          })
        );

        setAccessOptions([
          ...options,
          // ,
          // {
          //   value: "Disaster Case Management Agency",
          //   text: "Disaster Case Management Agency",
          // },
        ]);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleUnlock = async () => {
    if (enteredPIN === correctPIN) {
      setIsUnlocked(true);
      setShowPinInstructions(false);
      localStorage.setItem("pinMessageShown", "true");
    } else {
      setWrongPinMessage(true);
      setEnteredPIN("");
      setInputValues(["", "", "", ""]);
      inputRefs.current[0].focus();

      setTimeout(() => {
        setWrongPinMessage(false);
      }, 3000);
    }
  };

  const onInput = (index, event) => {
    const value = event.target.value;
    if (/^\d$/.test(value)) {
      const newValues = [...inputValues];
      newValues[index] = value;
      setInputValues(newValues);
      setEnteredPIN(newValues.join(""));

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const onKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      event.preventDefault();

      const newValues = [...inputValues];

      if (inputValues[index]) {
        newValues[index] = "";
      } else if (index > 0) {
        newValues[index - 1] = "";
        inputRefs.current[index - 1].focus();
      }

      setInputValues(newValues);
      setEnteredPIN(newValues.join(""));
    }
  };

  const handleGoBack = () => {
    window.history.back();
    window.history.back();
  };

  let selectedRow;
  const fields = { value: "value", text: "text" };

  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [wrongPinMessage, setWrongPinMessage] = useState(false);
  const inputRefs = useRef([]);

  const accessEditTemplateDocuments = (props) => {
    const currentAccess = Array.isArray(props?.access) ? props.access : [];

    return (
      <div>
        <label htmlFor="access" className="mb-2 block text-xs text-gray-500">
          People with Access
        </label>
        <MultiSelectComponent
          id="access"
          dataSource={accessOptions}
          fields={fields}
          value={currentAccess}
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  const PhotosGridRef = useRef(null);

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (
        data.hasOwnProperty(key) &&
        data[key] !== undefined &&
        typeof data[key] !== "function" &&
        !(data[key] instanceof Element) &&
        !(
          data[key] instanceof Object && data[key].constructor.name !== "Object"
        )
      ) {
        sanitizedData[key] = data[key];
      }
    }
    return sanitizedData;
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const [deleteDialogProps, setDeleteDialogProps] = useState({
    isOpen: false,
    title: "",
    content: "",
    onConfirm: null,
  });

  const handleDeleteDialogClose = () => {
    setDeleteDialogProps((prev) => ({ ...prev, isOpen: false }));
  };

  const handleDeleteConfirm = () => {
    if (deleteDialogProps.onConfirm) {
      deleteDialogProps.onConfirm();
    }
    handleDeleteDialogClose();
  };

  const [activeMenuDocuments, setActiveMenuDocuments] = useState(null);
  const [isEditingDocuments, setIsEditingDocuments] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const documentsFormRef = useRef(null);
  const [isSubmittingDocuments, setIsSubmittingDocuments] = useState(false);
  const [documentsToDeleteId, setDocumentsToDeleteId] = useState(null);
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);

  const {
    register: documentsRegister,
    handleSubmit: documentsHandleSubmit,
    formState: { errors: documentsErrors },
    reset: documentsReset,
    control: documentsControl,
    setValue: documentsSetValue,
    watch: documentsWatch,
  } = useForm({
    defaultValues: {},
  });

  const documentsValues = documentsWatch();

  const onSubmitDocuments = async (values) => {
    setIsSubmittingDocuments(true);
    try {
      const documentId = isEditingDocuments
        ? values.documentsID
        : generateUniqueId();
      const currentDate = new Date();

      const uploadedDocuments = values.photodocuments || [];

      const updatedData = {
        ...values,
        documentsID: documentId,
        photodocuments: Array.isArray(uploadedDocuments)
          ? uploadedDocuments
          : [uploadedDocuments],
        is_deleted: false,
        modified_date: currentDate.toLocaleDateString("en-US"),
        ...(isEditingDocuments
          ? {}
          : { date_inserted: currentDate.toLocaleDateString("en-US") }),
        access: Array.isArray(values.access) ? values.access : [],
      };

      const sanitizedData = sanitizeData(updatedData);

      if (isEditingDocuments) {
        delete sanitizedData.date_inserted;

        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "documents_data"
        );

        const updatedDocumentsData = documentsData.map((item) =>
          item.documentsID === documentId ? sanitizedData : item
        );
        setDocumentsData(updatedDocumentsData);
      } else {
        await LockBoxService.addDocumentToLockbox(
          userId,
          sanitizedData,
          "documents_data"
        );
      }

      await fetchData();
      setShowDocumentsModal(false);
      setIsEditingDocuments(false);
      documentsReset();
      setIsSubmittingDocuments(false);
    } catch (error) {
      console.error("Error saving document:", error);
      setIsSubmittingDocuments(false);
    }
  };

  const actionBeginDocuments = async (args) => {
    if (documentGridRef.current && args.requestType === "beginEdit") {
      const cols = documentGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = false; // Hide modifiedDate when editing
        }
        if (col.field === "date_inserted") {
          col.allowEditing = false; // Disable editing for date_inserted
        }
      }
    }

    if (documentGridRef.current && args.requestType === "add") {
      const cols = documentGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = false; // Hide modifiedDate when adding
        }
      }
    }

    if (documentGridRef.current && args.requestType === "save") {
      const cols = documentGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = true; // Show modifiedDate after saving
        }
      }
    }

    if (args.requestType === "save" && args.action === "add") {
      args.data.documentsID = generateUniqueId();
      args.data.is_deleted = false;

      const currentDate = new Date();
      args.data.modified_date = currentDate.toLocaleDateString("en-US");
      args.data.date_inserted = currentDate.toLocaleDateString("en-US");

      const sanitizedData = sanitizeData(args.data);
      await LockBoxService.addDocumentToLockbox(
        userId,
        sanitizedData,
        "documents_data"
      );
    }

    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      deleteDocument(args.data);
      await fetchData();
    }
  };

  const deleteDocument = async (data) => {
    try {
      for (const docData of data) {
        const documentId = docData.id;

        await LockBoxService.softDeleteItemInLockbox(
          userId,
          documentId,
          "documents_data"
        );
      }
      // Refresh the grid data after marking as deleted
      fetchData();
    } catch (error) {
      console.error("Error marking document as deleted:", error);
    }
  };

  const actionCompleteDocuments = async (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      try {
        const documentId = selectedRow.documentsID;

        delete args.data.date_inserted;

        if (args.data.modified_date) {
          const dateObj = new Date(args.data.modified_date);
          args.data.modified_date = dateObj.toLocaleDateString("en-US");
        }

        const sanitizedData = sanitizeData(args.data);

        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "documents_data"
        );

        const updatedData = documentsData.map((item) =>
          item.documentsID === documentId ? sanitizedData : item
        );

        setDocumentsData(updatedData);
        await fetchData();
      } catch (error) {
        console.error("Error updating document:", error);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  const onCloseDocumentsModal = useCallback(() => {
    setShowViewDocumentsModal(false);
    setSelectedDocument(null);
    setSelectedDocumentId(null);
  }, []);

  const handleEditFileDocuments = useCallback(
    (newData) => {
      setIsEditingDocuments(true);
      setShowDocumentsModal(true);
      setActiveMenuDocuments(null);
      const latestDocumentData = documentsData?.filter(
        (document) => document?.id === selectedDocumentId
      )[0];
      documentsReset({
        ...latestDocumentData,
        photodocuments: newData,
      });
    },
    [documentsData, documentsReset, selectedDocumentId]
  );

  const handleDocumentsCollectionDelete = useCallback(
    async (newData) => {
      try {
        setIsSubmittingDocuments(true);

        const lockboxRef = doc(
          db,
          `lockbox/${userId}/documents_data/${selectedDocumentId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          await updateDoc(lockboxRef, {
            photodocuments: newData,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedDocument((prev) => ({
            ...prev,
            photodocuments: newData,
          }));

          if (newData.length === 0) {
            onCloseDocumentsModal();
          }
        }

        await fetchData();
      } catch (error) {
        console.error("Error deleting documents:", error);
      } finally {
        setIsSubmittingDocuments(false);
      }
    },
    [userId, selectedDocumentId, fetchData, onCloseDocumentsModal]
  );

  const handleDocumentsCollectionUpdate = useCallback(
    async (newFile) => {
      try {
        const lockboxRef = doc(
          db,
          `lockbox/${userId}/documents_data/${selectedDocumentId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          const existingDocuments = lockboxData.data()?.photodocuments || [];
          const formattedNewFile = {
            dateAdded: new Date().toISOString(),
            details: "",
            name: newFile.name,
            src: newFile.src,
            type: newFile.type || "application/pdf",
          };

          existingDocuments.push(formattedNewFile);

          await updateDoc(lockboxRef, {
            photodocuments: existingDocuments,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedDocument((prev) => ({
            ...prev,
            photodocuments: existingDocuments,
          }));
        }

        await fetchData();
      } catch (error) {
        console.error("Error updating documents:", error);
      }
    },
    [userId, selectedDocumentId, fetchData]
  );

  const renderDocumentsModal = useMemo(() => {
    return (
      <ViewPhotosModal
        isOpen={showViewDocumentsModal}
        onClose={() => {
          onCloseDocumentsModal();
        }}
        photosData={selectedDocument?.photodocuments || []}
        storagePath={`lockbox/documents/${userId}`}
        handleCollectionUpdate={handleDocumentsCollectionUpdate}
        dateKey="dateAdded"
        onEditClick={(newData) => {
          onCloseDocumentsModal();
          handleEditFileDocuments(newData);
        }}
        isLoadingDelete={isSubmittingDocuments}
        acceptedTypes=".pdf,image/*"
        handleDelete={handleDocumentsCollectionDelete}
      />
    );
  }, [
    showViewDocumentsModal,
    selectedDocument,
    userId,
    handleDocumentsCollectionUpdate,
    isSubmittingDocuments,
    handleDocumentsCollectionDelete,
    handleEditFileDocuments,
    onCloseDocumentsModal,
  ]);

  const documentGridRef = useRef(null);

  const [activeMenuPhotos, setActiveMenuPhotos] = useState(null);
  const [isEditingPhotos, setIsEditingPhotos] = useState(false);
  const [showPhotosModal, setShowPhotosModal] = useState(false);
  const photosFormRef = useRef(null);
  const [isSubmittingPhotos, setIsSubmittingPhotos] = useState(false);
  const [photosToDeleteId, setPhotosToDeleteId] = useState(null);
  const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false);

  const {
    register: photosRegister,
    handleSubmit: photosHandleSubmit,
    formState: { errors: photosErrors },
    reset: photosReset,
    control: photosControl,
    setValue: photosSetValue,
    watch: photosWatch,
  } = useForm({
    defaultValues: {},
  });

  const photosValues = photosWatch();

  const onSubmitPhotos = async (values) => {
    setIsSubmittingPhotos(true);
    try {
      const photoId = isEditingPhotos ? values.PhotosID : generateUniqueId();
      const currentDate = new Date();

      const updatedData = {
        title: values.title,
        description: values.description,
        PhotosID: photoId,
        photoPhotos: values.photo || null,
        is_deleted: false,
        modified_date: currentDate.toLocaleDateString("en-US"),
        date_inserted: isEditingPhotos
          ? values.date_inserted
          : currentDate.toLocaleDateString("en-US"),
        access: Array.isArray(values.access) ? values.access : [],
      };

      const sanitizedData = sanitizeData(updatedData);

      // Log the final data to debug
      console.log("Sanitized data:", sanitizedData);

      if (isEditingPhotos) {
        await LockBoxService.updateDocumentInLockbox(
          userId,
          photoId,
          sanitizedData,
          "photos_data"
        );
      } else {
        await LockBoxService.addDocumentToLockbox(
          userId,
          sanitizedData,
          "photos_data"
        );
      }

      await fetchData();

      setShowPhotosModal(false);
      setIsEditingPhotos(false);
      photosReset();
      setIsSubmittingPhotos(false);
    } catch (error) {
      setIsSubmittingPhotos(false);
    }
  };

  const handleEditFilePhotos = useCallback(
    (newData) => {
      setIsEditingPhotos(true);
      setShowPhotosModal(true);
      setActiveMenuPhotos(null);
      const latestPhotoData = PhotosData?.filter(
        (photo) => photo?.id === selectedPhotoId
      )[0];
      photosReset({
        ...latestPhotoData,
        photo: newData,
      });
    },
    [PhotosData, photosReset, selectedPhotoId]
  );

  const onClosePhotosModal = useCallback(() => {
    setShowViewPhotosModal(false);
    setSelectedPhotoId(null);
    setSelectedPhoto({});
  }, []);

  const handlePhotosCollectionDelete = useCallback(
    async (newData) => {
      try {
        setIsSubmittingPhotos(true);

        const lockboxRef = doc(
          db,
          `lockbox/${userId}/photos_data/${selectedPhotoId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          await updateDoc(lockboxRef, {
            photoPhotos: newData,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedPhoto((prev) => ({
            ...prev,
            photoPhotos: newData,
          }));

          if (newData.length === 0) {
            onClosePhotosModal();
          }
        }

        await fetchData();
      } catch (error) {
        console.error("Error deleting photos:", error);
      } finally {
        setIsSubmittingPhotos(false);
      }
    },
    [userId, selectedPhotoId, fetchData, onClosePhotosModal]
  );

  const handlePhotosCollectionUpdate = useCallback(
    async (newFile) => {
      try {
        const lockboxRef = doc(
          db,
          `lockbox/${userId}/photos_data/${selectedPhotoId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          const existingPhotos = lockboxData.data()?.photoPhotos || [];
          const formattedNewFile = {
            dateAdded: new Date().toISOString(),
            details: "",
            name: newFile.name,
            src: newFile.src,
            type: newFile.type || "image/jpeg",
          };

          existingPhotos.push(formattedNewFile);

          await updateDoc(lockboxRef, {
            photoPhotos: existingPhotos,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedPhoto((prev) => ({
            ...prev,
            photoPhotos: existingPhotos,
          }));
        }

        await fetchData();
      } catch (error) {
        console.error("Error updating photos:", error);
      }
    },
    [userId, selectedPhotoId, fetchData]
  );

  const accessEditTemplatePhotos = (props) => {
    const currentAccess = Array.isArray(props?.access) ? props.access : [];

    return (
      <div>
        <label htmlFor="access" className="mb-2 block text-xs text-gray-500">
          People with Access
        </label>
        <MultiSelectComponent
          id="access"
          dataSource={accessOptions}
          fields={fields}
          value={currentAccess}
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  const renderPhotosModal = useMemo(() => {
    return (
      <ViewPhotosModal
        isOpen={showViewPhotosModal}
        onClose={() => {
          onClosePhotosModal();
        }}
        photosData={selectedPhoto?.photoPhotos || []}
        storagePath={`lockbox/photos/${userId}`}
        handleCollectionUpdate={handlePhotosCollectionUpdate}
        dateKey="dateAdded"
        onEditClick={(newData) => {
          onClosePhotosModal();
          handleEditFilePhotos(newData);
        }}
        isLoadingDelete={isSubmittingPhotos}
        handleDelete={handlePhotosCollectionDelete}
      />
    );
  }, [
    showViewPhotosModal,
    selectedPhoto,
    userId,
    handlePhotosCollectionUpdate,
    isSubmittingPhotos,
    handlePhotosCollectionDelete,
    handleEditFilePhotos,
    onClosePhotosModal,
  ]);

  const centerDocumentGridRef = useRef(null);

  const [activeMenuCenterDocs, setActiveMenuCenterDocs] = useState(null);
  const [isEditingCenterDocs, setIsEditingCenterDocs] = useState(false);
  const [showCenterDocsModal, setShowCenterDocsModal] = useState(false);
  const [isSubmittingCenterDocs, setIsSubmittingCenterDocs] = useState(false);
  const [centerDocsToDeleteId, setCenterDocsToDeleteId] = useState(null);
  const [showDeleteCenterDocsModal, setShowDeleteCenterDocsModal] =
    useState(false);

  const centerDocsFormRef = useRef(null);

  const {
    register: centerDocsRegister,
    handleSubmit: centerDocsHandleSubmit,
    formState: { errors: centerDocsErrors },
    reset: centerDocsReset,
    control: centerDocsControl,
    setValue: centerDocsSetValue,
    watch: centerDocsWatch,
  } = useForm({ defaultValues: {} });

  const centerDocsValues = centerDocsWatch();

  const onSubmitCenterDocs = async (values) => {
    setIsSubmittingCenterDocs(true);
    try {
      const docId = isEditingCenterDocs
        ? values.documentsID
        : generateUniqueId();
      const currentDate = new Date();

      const updatedData = {
        ...values,
        documentsID: docId,
        is_deleted: false,
        modified_date: currentDate.toLocaleDateString("en-US"),
        ...(isEditingCenterDocs
          ? {}
          : { date_inserted: currentDate.toLocaleDateString("en-US") }),
        access: Array.isArray(values.access) ? values.access : [],
      };

      const sanitizedData = sanitizeData(updatedData);

      if (isEditingCenterDocs) {
        await LockBoxService.updateDocumentInLockbox(
          userId,
          docId,
          sanitizedData,
          "center_documents"
        );
      } else {
        await LockBoxService.addDocumentToLockbox(
          userId,
          sanitizedData,
          "center_documents"
        );
      }

      await fetchData();
      setShowCenterDocsModal(false);
      setIsEditingCenterDocs(false);
      centerDocsReset();
    } catch (error) {
      console.error("Error saving center document:", error);
    } finally {
      setIsSubmittingCenterDocs(false);
    }
  };

  const onCloseCenterDocumentsModal = useCallback(() => {
    setShowViewCenterDocumentsModal(false);
    setSelectedCenterDocument(null);
    setSelectedCenterDocumentId(null);
  }, []);

  const onCloseCenterDocumentSignModal = useCallback(() => {
    setShowViewCenterDocumentSignModal(false);
    setSelectedCenterDocument(null);
    setSelectedCenterDocumentId(null);
  }, []);

  const handleCenterDocumentsCollectionUpdate = useCallback(
    async (newFile) => {
      try {
        const lockboxRef = doc(
          db,
          `lockbox/${userId}/center_documents/${selectedCenterDocumentId}`
        );

        const lockboxData = await getDoc(lockboxRef);
        if (lockboxData.exists()) {
          const existingDocuments = lockboxData.data()?.photodocuments || [];
          const formattedNewFile = {
            dateAdded: new Date().toISOString(),
            details: "",
            name: newFile.name,
            src: newFile.src,
            type: newFile.type || "application/pdf",
          };

          existingDocuments.push(formattedNewFile);

          await updateDoc(lockboxRef, {
            photodocuments: existingDocuments,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedCenterDocument((prev) => ({
            ...prev,
            photodocuments: existingDocuments,
          }));
        }

        await fetchData();
      } catch (error) {
        console.error("Error updating center documents:", error);
      }
    },
    [userId, selectedCenterDocumentId, fetchData]
  );

  const handleCenterDocumentsCollectionDelete = useCallback(
    async (newData) => {
      try {
        setIsSubmittingCenterDocs(true);

        const lockboxRef = doc(
          db,
          `lockbox/${userId}/center_documents/${selectedCenterDocumentId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          await updateDoc(lockboxRef, {
            photodocuments: newData,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedCenterDocument((prev) => ({
            ...prev,
            photodocuments: newData,
          }));

          if (newData.length === 0) {
            onCloseCenterDocumentsModal();
          }
        }

        await fetchData();
      } catch (error) {
        console.error("Error deleting center documents:", error);
      } finally {
        setIsSubmittingCenterDocs(false);
      }
    },
    [userId, selectedCenterDocumentId, fetchData, onCloseCenterDocumentsModal]
  );

  const renderCenterDocumentsModal = useMemo(() => {
    return (
      <ViewPhotosModal
        isOpen={showViewCenterDocumentsModal}
        onClose={onCloseCenterDocumentsModal}
        photosData={selectedCenterDocument?.photodocuments || []}
        storagePath={`lockbox/center_documents/${userId}`}
        handleCollectionUpdate={handleCenterDocumentsCollectionUpdate}
        dateKey="dateAdded"
        onEditClick={(newData) => {
          onCloseCenterDocumentsModal();
          setIsEditingCenterDocs(true);
          setShowCenterDocsModal(true);
          centerDocsReset({
            ...selectedCenterDocument,
            photodocuments: newData,
          });
        }}
        isLoadingDelete={isSubmittingCenterDocs}
        acceptedTypes=".pdf,image/*"
        handleDelete={handleCenterDocumentsCollectionDelete}
      />
    );
  }, [
    showViewCenterDocumentsModal,
    selectedCenterDocument,
    userId,
    handleCenterDocumentsCollectionUpdate,
    isSubmittingCenterDocs,
    handleCenterDocumentsCollectionDelete,
    onCloseCenterDocumentsModal,
    centerDocsReset,
    data,
  ]);

  const renderCenterDocumentSignModal = useMemo(() => {
    return (
      <ViewPhotoSignModal
        isOpen={showViewCenterDocumentSignModal}
        onClose={onCloseCenterDocumentSignModal}
        photosData={selectedCenterDocument?.photodocuments || []}
        storagePath={`lockbox/center_documents/${userId}`}
        handleCollectionUpdate={handleCenterDocumentsCollectionUpdate}
        dateKey="dateAdded"
        onEditClick={(newData) => {
          onCloseCenterDocumentSignModal();
          setIsEditingCenterDocs(true);
          setShowCenterDocsModal(true);
          centerDocsReset({
            ...selectedCenterDocument,
            photodocuments: newData,
          });
        }}
        isLoadingDelete={isSubmittingCenterDocs}
        acceptedTypes=".pdf,image/*"
        handleDelete={handleCenterDocumentsCollectionDelete}
        pov={"partner"}
        userData={data}
        documentData={selectedCenterDocument}
        userId={userId}
        onAfterSave={fetchData}
      />
    );
  }, [
    showViewCenterDocumentSignModal,
    selectedCenterDocument,
    userId,
    handleCenterDocumentsCollectionUpdate,
    isSubmittingCenterDocs,
    handleCenterDocumentsCollectionDelete,
    onCloseCenterDocumentSignModal,
    centerDocsReset,
  ]);

  const [selectedSignature, setSelectedSignature] = useState(null);

  const openSignatureDetails = (data, type) => {
    setSelectedSignature({ data, type });
  };

  const closeSignatureDetails = () => {
    setSelectedSignature(null);
  };

  const signatureTemplateCenter = (props) => {
    if (props.center_signature_required) {
      return props.center_signature ? (
        <span
          className="cursor-pointer rounded border border-[#003460] border-solid w-full px-3 py-1 text-[#003460]"
          onClick={() => openSignatureDetails(props, "center_signature")}
        >
          Signed
        </span>
      ) : (
        <span>Not Signed</span>
      );
    } else if (props.copied_at && !props.center_signature) {
      return props.center_acknowledged ? (
        <span
          className="cursor-pointer rounded border border-[#003460] border-solid w-full px-3 py-1 text-[#003460]"
          onClick={() => openSignatureDetails(props, "center_acknowledged")}
        >
          Acknowledged
        </span>
      ) : (
        <span>Not Acknowledged</span>
      );
    } else {
      return <span>Not Required</span>;
    }
  };

  const signatureTemplatePartner = (props) => {
    if (props.partner_signature_required) {
      return props.partner_signature ? (
        <span
          className="cursor-pointer rounded border border-[#003460] border-solid w-full px-3 py-1 text-[#003460]"
          onClick={() => openSignatureDetails(props, "partner_signature")}
        >
          Signed
        </span>
      ) : (
        <span>Not Signed</span>
      );
    } else if (props.copied_at && !props.partner_signature) {
      return props.partner_acknowledged ? (
        <span
          className="cursor-pointer rounded border border-[#003460] border-solid w-full px-3 py-1 text-[#003460]"
          onClick={() => openSignatureDetails(props, "partner_acknowledged")}
        >
          Acknowledged
        </span>
      ) : (
        <span>Not Acknowledged</span>
      );
    } else {
      return <span>Not Required</span>;
    }
  };

  const SignatureDetailsModal = ({ selectedSignature, onClose }) => {
    if (!selectedSignature) return null;

    const { data, type } = selectedSignature;
    let title = "";
    let details = [];

    if (type === "center_signature") {
      title = "Center Signature Details";
      details = [
        { label: "Center Signature", value: data.center_signature },
        { label: "Signed At", value: formatDate(data.center_signed_at) },
        { label: "Signed By", value: data.center_signed_by },
      ];
    } else if (type === "center_acknowledged") {
      title = "Center Acknowledgment Details";
      details = [
        {
          label: "Center Acknowledged",
          value: data.center_acknowledged ? "Yes" : "No",
        },
        { label: "Acknowledged By", value: data.center_acknowledged_by },
        {
          label: "Acknowledged Date",
          value: formatDate(data.center_acknowledged_date),
        },
      ];
    } else if (type === "partner_signature") {
      title = "Partner Signature Details";
      details = [
        { label: "Partner Signature", value: data.partner_signature },
        { label: "Signed At", value: formatDate(data.partner_signed_at) },
        { label: "Signed By", value: data.partner_signed_by },
      ];
    } else if (type === "partner_acknowledged") {
      title = "Partner Acknowledgment Details";
      details = [
        {
          label: "Partner Acknowledged",
          value: data.partner_acknowledged ? "Yes" : "No",
        },
        { label: "Acknowledged By", value: data.partner_acknowledged_by },
        {
          label: "Acknowledged Date",
          value: formatDate(data.partner_acknowledged_date),
        },
      ];
    }

    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="w-[500px] rounded-lg bg-white shadow-lg">
          <div className="flex items-center justify-between rounded-t-lg bg-[#C4DAF3] p-4">
            <p className="font-semibold text-[#04447A]">{title}</p>
            <button
              className="cursor-pointer bg-[#C4DAF3]"
              onClick={onClose}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.0013 15.8641L17.868 19.7307C18.1124 19.9752 18.4235 20.0974 18.8013 20.0974C19.1791 20.0974 19.4902 19.9752 19.7346 19.7307C19.9791 19.4863 20.1013 19.1752 20.1013 18.7974C20.1013 18.4196 19.9791 18.1085 19.7346 17.8641L15.868 13.9974L19.7346 10.1307C19.9791 9.88628 20.1013 9.57517 20.1013 9.1974C20.1013 8.81962 19.9791 8.50851 19.7346 8.26406C19.4902 8.01962 19.1791 7.8974 18.8013 7.8974C18.4235 7.8974 18.1124 8.01962 17.868 8.26406L14.0013 12.1307L10.1346 8.26406C9.89019 8.01962 9.57908 7.8974 9.2013 7.8974C8.82352 7.8974 8.51241 8.01962 8.26797 8.26406C8.02352 8.50851 7.9013 8.81962 7.9013 9.1974C7.9013 9.57517 8.02352 9.88628 8.26797 10.1307L12.1346 13.9974L8.26797 17.8641C8.02352 18.1085 7.9013 18.4196 7.9013 18.7974C7.9013 19.1752 8.02352 19.4863 8.26797 19.7307C8.51241 19.9752 8.82352 20.0974 9.2013 20.0974C9.57908 20.0974 9.89019 19.9752 10.1346 19.7307L14.0013 15.8641ZM14.0013 27.3307C12.1569 27.3307 10.4235 26.9807 8.8013 26.2807C7.17908 25.5807 5.76797 24.6307 4.56797 23.4307C3.36797 22.2307 2.41797 20.8196 1.71797 19.1974C1.01797 17.5752 0.667969 15.8418 0.667969 13.9974C0.667969 12.153 1.01797 10.4196 1.71797 8.79739C2.41797 7.17517 3.36797 5.76406 4.56797 4.56406C5.76797 3.36406 7.17908 2.41406 8.8013 1.71406C10.4235 1.01406 12.1569 0.664062 14.0013 0.664062C15.8457 0.664062 17.5791 1.01406 19.2013 1.71406C20.8235 2.41406 22.2346 3.36406 23.4346 4.56406C24.6346 5.76406 25.5846 7.17517 26.2846 8.79739C26.9846 10.4196 27.3346 12.153 27.3346 13.9974C27.3346 15.8418 26.9846 17.5752 26.2846 19.1974C25.5846 20.8196 24.6346 22.2307 23.4346 23.4307C22.2346 24.6307 20.8235 25.5807 19.2013 26.2807C17.5791 26.9807 15.8457 27.3307 14.0013 27.3307Z"
                  fill="#E72528"
                />
              </svg>
            </button>
          </div>
          <div className="space-y-4 px-4 py-5">
            {details.map((item, index) => (
              <p key={index}>
                <span>{item.label}: </span> <strong> {item.value || "N/A"}</strong>
              </p>
            ))}
          </div>
          <div className="m-4 flex justify-center">
            <button
              onClick={onClose}
              className="w-full border border-[#003460] text-[24px] justify-center rounded px-4 py-4 text-[#003460] "
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (!isUnlocked) {
    return (
      <>
        {pinShow ? (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
            <ShowPin
              isOpen={pinShow}
              onClose={() => setPinShow(false)}
              firstTime={true}
            />
          </div>
        ) : (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
            <div className="absolute right-4 top-4">
              <button
                onClick={() => setShowPinInstructions(!showPinInstructions)}
                className="hover:bg-gray-100 inline-flex h-8 w-8 items-center justify-center rounded-full border border-gray-300 bg-gray-200 text-sm text-gray-500 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                title="Show PIN instructions"
              >
                ?
              </button>
              {showPinInstructions && (
                <div className="absolute right-0 top-10 z-10 w-64 rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
                  <p className="text-gray-600 mb-2 text-sm font-medium">
                    To view your Lockbox PIN:
                  </p>
                  <ol className="text-gray-600 ml-4 list-decimal text-left text-sm">
                    <li>Click on your profile icon in the top right</li>
                    <li>Select "Show Lockbox PIN"</li>
                    <li>Enter your account password to reveal the PIN</li>
                  </ol>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
              <img
                src={LockImage}
                alt="Lockbox"
                className="mb-6 h-[290px] w-[290px]"
              />
              <div className="mb-4 text-center text-sm font-medium text-red-600">
                <strong>Note:</strong> For secure access, your Lockbox PIN can
                be viewed in "My Profile" menu <br />
                by selecting 'Show Lockbox PIN'.
              </div>

              <div className="mb-4 flex space-x-8">
                {inputValues.map((value, index) => (
                  <input
                    key={index}
                    type="password"
                    maxLength="1"
                    className="lockbox-input h-12 w-12 rounded-xl border border-gray-300 bg-gray-300 text-center text-2xl"
                    pattern="[0-9]"
                    value={value}
                    onChange={(event) => onInput(index, event)}
                    onKeyDown={(event) => onKeyDown(index, event)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              {wrongPinMessage && (
                <div className="mb-4 text-sm text-red-600">
                  Wrong PIN entered, please try again.
                </div>
              )}
              <div className="flex w-full space-x-4">
                <button
                  onClick={handleGoBack}
                  className="btn-cancel flex flex-1 cursor-pointer items-center justify-center px-4 py-2"
                >
                  <span className="mr-2">←</span> Go Back
                </button>
                <button
                  onClick={handleUnlock}
                  className="btn-default flex flex-1 cursor-pointer items-center justify-center px-4 py-2"
                >
                  <svg
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <g id="Shield Done">
                      <path
                        id="Fill 1"
                        opacity="0.4"
                        d="M9.08649 20.5C8.96266 20.5 8.83884 20.4716 8.72709 20.4137L5.12599 18.5496C4.10415 18.0201 3.30481 17.4259 2.68063 16.7336C1.31449 15.2195 0.554402 13.276 0.542321 11.2599L0.500038 4.62426C0.495004 3.85842 0.989312 3.17103 1.72826 2.91215L8.3405 0.606788C8.73313 0.466564 9.17106 0.464603 9.57073 0.599924L16.2081 2.82684C16.9511 3.07493 17.4535 3.75742 17.4575 4.52228L17.4998 11.1628C17.5129 13.176 16.779 15.1274 15.434 16.6581C14.8168 17.3602 14.0245 17.9632 13.0128 18.5025L9.44388 20.4088C9.33314 20.4686 9.21032 20.499 9.08649 20.5Z"
                        fill="white"
                      />
                      <path
                        id="Fill 4"
                        d="M8.31936 12.8209C8.12607 12.8219 7.93278 12.7523 7.78378 12.6091L5.86695 10.7656C5.57097 10.4793 5.56795 10.0145 5.86091 9.72617C6.15387 9.4369 6.63207 9.43396 6.92906 9.71931L8.30829 11.0451L11.6758 7.72479C11.9698 7.43552 12.448 7.43258 12.744 7.71793C13.041 8.00426 13.044 8.47004 12.751 8.75735L8.85193 12.6022C8.70494 12.7474 8.51266 12.8199 8.31936 12.8209Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {renderPhotosModal}
      {renderDocumentsModal}
      {renderCenterDocumentsModal}
      {renderCenterDocumentSignModal}
      <div
        className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5  leading-[normal] tracking-[normal] ${className}`}
      >
        <div className="w-full py-2">
          {/* <div className="text-[24px] text-[#003460] font-semibold pb-3">Lockbox</div> */}
          {/* DOCUMENTS  */}
          <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <HeaderTitle id="documents" title="DOCUMENTS" />
            <div className="mt-3 flex w-full items-center justify-end"></div>
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                key={documentsData.length}
                ref={documentGridRef}
                dataSource={documentsData}
                // allowSorting={true}
                // sortSettings={sortSettings}
                editSettings={editing}
                // filterSettings={filterSettings}
                // allowFiltering={true}
                actionBegin={actionBeginDocuments}
                actionComplete={actionCompleteDocuments}
                allowPaging={true}
                pageSettings={pageSettings}
                // showColumnMenu={true}
                columnMenuItems={columnMenuItems}
                cssClass="new-grid-color allow-overflow-grid allow-overflow-grid-text"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="documentsID"
                    headerText="documentsID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="type"
                    headerText="Type"
                    width="100"
                    editType="dropdownedit"
                    edit={typeParams}
                  />
                  <ColumnDirective
                    field="description"
                    headerText="Description"
                    width="100"
                  />
                  <ColumnDirective
                    field="access"
                    headerText="People with Access"
                    width="80"
                    editTemplate={accessEditTemplateDocuments}
                    template={(props) => {
                      return Array.isArray(props.access)
                        ? props.access.length
                        : 0;
                    }}
                  />
                  <ColumnDirective
                    field="date_inserted"
                    headerText="Date Uploaded"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="modified_date"
                    headerText="Modified"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    visible={false}
                  />
                  <ColumnDirective
                    field="photodocuments"
                    headerText="Documents"
                    width="100"
                    // template={documentsTemplate}
                    template={(props) => {
                      return (
                        <PhotoColumn
                          data={props.photodocuments}
                          // showImage={true}
                          // src={props?.photodocuments[0]?.src}
                          type="document"
                          onClick={() => {
                            const { column, index, foreignKeyData, ...rest } =
                              props;
                            console.log("Clicked props:", props);

                            setShowViewDocumentsModal(true);
                            setSelectedDocument(rest);
                            setSelectedDocumentId(props.id);
                          }}
                        />
                      );
                    }}
                    // edit={documentsEdit}
                  />

                  <ColumnDirective
                    headerText="Actions"
                    textAlign="Center"
                    width="120"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.documentsID}
                            activeMenuId={activeMenuDocuments}
                            setActiveMenuId={setActiveMenuDocuments}
                            clickOutside={false}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // setSelectedHouseholdMember(props);
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingDocuments(true);
                              setShowDocumentsModal(true);
                              setActiveMenuDocuments(null);
                              documentsReset({
                                title: props?.title || "",
                                description: props?.description || "",
                                access: props?.access || "",
                                modified_date: props?.modified_date || "",
                                relationship: props?.relationship || "",
                                photo: props?.photoDocuments || "",
                                documentsID: props?.documentsID || "",
                                ...rest,
                              });
                              console.log("edit");
                              console.log("PROPSSSSSS", props);
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteDocumentModal(true);
                              setActiveMenuDocuments(null);
                              setDocumentsToDeleteId(props?.id);
                              console.log("delete");
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  {/* <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                /> */}
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    Sort,
                    Filter,
                    ColumnMenu,
                    CommandColumn,
                  ]}
                />
              </GridComponent>
            </div>
            <AddNewButton
              onClick={() => {
                setIsEditingDocuments(false);
                setShowDocumentsModal(true);
                documentsReset({
                  type: "",
                  description: "",
                  access: "",
                  date_inserted: "",
                  modified_date: "",
                  photodocuments: "",
                  is_deleted: false,
                  documentsID: "",
                  fileUrl: "",
                  documents: null,
                });
              }}
              name="Add New Documents"
            />
          </section>

          {/* PHOTOS  */}
          <section className="mt-4 flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <HeaderTitle id="photos" title="PHOTOS" />
            <div className="flex w-full justify-end"></div>
            <div className=" mt-3 flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={PhotosGridRef}
                dataSource={PhotosData}
                // allowSorting={true}
                // sortSettings={sortSettings}
                editSettings={editing}
                // filterSettings={filterSettings}
                // allowFiltering={true}
                // actionComplete={actionCompletePhotos}
                // actionBegin={actionBeginPhotos}
                allowPaging={true}
                pageSettings={pageSettings}
                // showColumnMenu={true}
                // columnMenuItems={columnMenuItems}
                cssClass="new-grid-color allow-overflow-grid allow-overflow-grid-text"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="PhotosID"
                    headerText="PhotosID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="is_deleted"
                    headerText="Status"
                    visible={false}
                  />
                  <ColumnDirective
                    field="title"
                    headerText="Title"
                    width="100"
                  />
                  <ColumnDirective
                    field="description"
                    headerText="Description"
                    width="100"
                  />

                  <ColumnDirective
                    field="access"
                    headerText="People with Access"
                    width="80"
                    editTemplate={accessEditTemplatePhotos}
                    template={(props) => {
                      return Array.isArray(props.access)
                        ? props.access.length
                        : 0;
                    }}
                  />
                  <ColumnDirective
                    field="date_inserted"
                    headerText="Date Uploaded"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    allowEditing={false}
                  />

                  <ColumnDirective
                    field="modified_date"
                    headerText="Modified"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    visible={false}
                  />
                  <ColumnDirective
                    field="photoPhotos"
                    headerText="Photos"
                    width="100"
                    // template={photosTemplate}
                    template={(props) => {
                      return (
                        <PhotoColumn
                          data={props.photoPhotos}
                          onClick={() => {
                            const { column, index, foreignKeyData, ...rest } =
                              props;
                            console.log("Clicked props:", props);

                            setShowViewPhotosModal(true);
                            setSelectedPhoto(rest);
                            setSelectedPhotoId(props.id);
                          }}
                        />
                      );
                    }}
                    // edit={PhotosEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    textAlign="Center"
                    width="120"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.PhotosID}
                            activeMenuId={activeMenuPhotos}
                            setActiveMenuId={setActiveMenuPhotos}
                            clickOutside={false}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingPhotos(true);
                              setShowPhotosModal(true);
                              setActiveMenuPhotos(null);

                              photosReset({
                                title: props?.title || "",
                                description: props?.description || "",
                                access: props?.access || "",
                                modified_date: props?.modified_date || "",
                                relationship: props?.relationship || "",
                                photoPhotos: props?.photoPhotos || "",
                                photo: props?.photoPhotos || "",
                                PhotosID: props?.PhotosID || "",
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeletePhotoModal(true);
                              setActiveMenuPhotos(null);
                              setPhotosToDeleteId(props?.id);
                              console.log("delete");
                            }}
                          />
                        </div>
                      );
                    }}
                  />

                  {/* <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                /> */}
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, Sort, Filter, ColumnMenu]}
                />
              </GridComponent>
            </div>
            <AddNewButton
              onClick={() => {
                setIsEditingPhotos(false);
                setShowPhotosModal(true);
                photosReset({
                  title: "",
                  description: "",
                  access: "",
                  date_inserted: "",
                  modified_date: "",
                  photoPhotos: "",
                  fileUrl: "",
                  is_deleted: false,
                  PhotosID: "",
                  photo: null,
                });
              }}
              name="Add New Photos"
            />
            {/* <button
            type="button"
            className="mt-3 flex cursor-pointer items-center gap-1 bg-white py-1 text-[#003460] hover:text-blue-800"
            onClick={handleAddClickPhotos}
          >
            <FaPlus className="h-4 w-4" />
            <span className="px-1 text-[#003460]">Add New Photos</span>
          </button> */}
          </section>

          {/* PARTNER DOCUMENTS  */}
          <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <HeaderTitle id="partnerdocuments" title="CENTER DOCUMENTS" />
            <div className="mt-3 flex w-full items-center justify-end"></div>
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                key={centerDocumentsData.length}
                ref={centerDocumentGridRef}
                dataSource={centerDocumentsData}
                editSettings={editing}
                allowPaging={true}
                pageSettings={pageSettings}
                columnMenuItems={columnMenuItems}
                cssClass="new-grid-color allow-overflow-grid allow-overflow-grid-text"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="documentsID"
                    headerText="documentsID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="type"
                    headerText="Type"
                    width="100"
                    editType="dropdownedit"
                    edit={typeParams}
                  />
                  <ColumnDirective
                    field="description"
                    headerText="Description"
                    width="100"
                  />
                  {/* <ColumnDirective
                    field="access"
                    headerText="People with Access"
                    width="80"
                    editTemplate={accessEditTemplateDocuments}
                    template={(props) =>
                      Array.isArray(props.access) ? props.access.length : 0
                    }
                  /> */}
                  <ColumnDirective
                    field="date_inserted"
                    headerText="Date Uploaded"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="modified_date"
                    headerText="Modified"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    visible={false}
                  />
                  <ColumnDirective
                    field="center_signature"
                    headerText="Center Signature"
                    width="100"
                    template={signatureTemplateCenter}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="partner_signature"
                    headerText="Partner Signature"
                    width="100"
                    template={signatureTemplatePartner}
                  />
                  <ColumnDirective
                    field="photodocuments"
                    headerText="Documents"
                    width="100"
                    template={(props) =>
                      !props?.copied_at ? (
                        <PhotoColumn
                          data={props.photodocuments}
                          type="document"
                          onClick={() => {
                            setShowViewCenterDocumentsModal(true);
                            setSelectedCenterDocument(props);
                            setSelectedCenterDocumentId(props.id);
                          }}
                        />
                      ) : (
                        <PhotoColumn
                          data={props.photodocuments}
                          type="document"
                          onClick={() => {
                            setShowViewCenterDocumentSignModal(true);
                            setSelectedCenterDocument(props);
                            setSelectedCenterDocumentId(props.id);
                          }}
                        />
                      )
                    }
                  />
                  <ColumnDirective
                    headerText="Actions"
                    textAlign="Center"
                    width="120"
                    template={(props) => (
                      <div className="flex w-full justify-center overflow-visible px-4">
                        {!props?.copied_at && (
                          <ThreeDotMenu
                            menuId={props?.documentsID}
                            activeMenuId={activeMenuCenterDocs}
                            setActiveMenuId={setActiveMenuCenterDocs}
                            clickOutside={false}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setIsEditingCenterDocs(true);
                              setShowCenterDocsModal(true);
                              setActiveMenuCenterDocs(null);
                              centerDocsReset({
                                ...props,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteCenterDocsModal(true);
                              setActiveMenuCenterDocs(null);
                              setCenterDocsToDeleteId(props?.id);
                            }}
                          />
                        )}
                      </div>
                    )}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    Sort,
                    Filter,
                    ColumnMenu,
                    CommandColumn,
                  ]}
                />
              </GridComponent>
            </div>
            <AddNewButton
              onClick={() => {
                setIsEditingCenterDocs(false);
                setShowCenterDocsModal(true);
                centerDocsReset({
                  type: "",
                  description: "",
                  access: "",
                  date_inserted: "",
                  modified_date: "",
                  photodocuments: "",
                  is_deleted: false,
                  documentsID: "",
                });
              }}
              name="Add New Center Document"
            />
          </section>
        </div>
        <DialogComponent
          header={deleteDialogProps.title}
          content={deleteDialogProps.content}
          visible={deleteDialogProps.isOpen}
          showCloseIcon={true}
          closeOnEscape={true}
          width="300px"
          buttons={[
            {
              click: handleDeleteConfirm,
              buttonModel: { content: "Yes", isPrimary: true },
            },
            {
              click: handleDeleteDialogClose,
              buttonModel: { content: "No" },
            },
          ]}
          close={handleDeleteDialogClose}
          cssClass="delete-confirmation-dialog"
        />

        <Modal
          isOpen={showDocumentsModal}
          title={isEditingDocuments ? "Edit Documents" : "Add New Documents"}
          onClose={() => {
            setShowDocumentsModal(false);
            setActiveMenuDocuments(false);
            documentsReset();
            // setSelectedHouseholdMember(null);
          }}
          formRef={documentsFormRef}
          isLoading={isSubmittingDocuments}
          isEdit={isEditingDocuments}
        >
          <form
            onSubmit={documentsHandleSubmit(onSubmitDocuments)}
            className="flex flex-col gap-4"
            ref={documentsFormRef}
          >
            <SimpleFileUploader
              storagePath={`lockbox/documents/${userId}`}
              onFileUpload={(files) => {
                if (files) {
                  documentsSetValue("photodocuments", files);
                }
              }}
              initialPhoto={documentsWatch("photodocuments")}
              acceptedTypes=".pdf,image/*"
              className="mb-4"
            />
            <div className="w-full">
              <CustomSelect
                name="type"
                placeholder="Type"
                register={{
                  ...documentsRegister("type", {
                    required: "Type is required",
                  }),
                }}
                options={documentTypes}
                errors={documentsErrors}
              />
            </div>
            <div className="w-full">
              <CustomTextArea
                type="text"
                name="description"
                placeholder="Description"
                register={{
                  ...documentsRegister("description", {
                    required: "Description is required",
                    // pattern: {
                    //   value: alphanumericRegex,
                    //   message: "Numbers are not allowed in names",
                    // },
                  }),
                }}
                errors={documentsErrors}
              />
            </div>
            <div className="col-span-4">
              <Controller
                control={documentsControl}
                name="access"
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label
                      htmlFor="access"
                      className="mb-2 block text-xs text-gray-500"
                    >
                      People with Access
                    </label>
                    <MultiSelectComponent
                      id="access"
                      dataSource={accessOptions}
                      fields={fields}
                      value={value}
                      mode="CheckBox"
                      selectAllText="Select All"
                      unSelectAllText="Unselect All"
                      showSelectAll={true}
                      change={(e) => onChange(e.value)}
                    >
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                  </div>
                )}
              />
            </div>
          </form>
        </Modal>

        <DeleteModal
          isOpen={showDeleteDocumentModal}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeleteDocumentModal(false);
            setDocumentsToDeleteId(null);
          }}
          onDelete={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsSubmittingDocuments(true);

            try {
              // Call the LockBoxService to soft delete the document
              await LockBoxService.softDeleteItemInLockbox(
                userId,
                documentsToDeleteId,
                "documents_data"
              );

              // Close modal and refresh data
              setShowDeleteDocumentModal(false);
              setIsSubmittingDocuments(false);
              await fetchData();
            } catch (error) {
              console.error("Error deleting document:", error);
              setIsSubmittingDocuments(false);
            }
          }}
          itemName="Document"
          loading={isSubmittingDocuments}
        />

        <Modal
          isOpen={showPhotosModal}
          title={isEditingPhotos ? "Edit Photos" : "Add New Photos"}
          onClose={() => {
            setShowPhotosModal(false);
            setActiveMenuPhotos(false);
            photosReset();
            // setSelectedHouseholdMember(null);
          }}
          formRef={photosFormRef}
          isLoading={isSubmittingPhotos}
          isEdit={isEditingPhotos}
        >
          <form
            onSubmit={photosHandleSubmit(onSubmitPhotos)}
            className="flex flex-col gap-4"
            ref={photosFormRef}
          >
            <SimpleFileUploader
              storagePath={`lockbox/photos/${userId}`}
              onFileUpload={(photoUrls) => {
                if (photoUrls) {
                  photosSetValue("photo", photoUrls);
                  photosSetValue("photoPhotos", photoUrls);
                }
              }}
              initialPhoto={photosValues.photo}
              acceptedTypes="image/*"
              className="mb-4"
            />
            <div className="w-full">
              <CustomInput
                type="text"
                name="title"
                placeholder="Name"
                register={{
                  ...photosRegister("title", {
                    required: "Name is required",
                  }),
                }}
                errors={photosErrors}
              />
            </div>
            <div className="w-full">
              <CustomTextArea
                type="text"
                name="description"
                placeholder="Description"
                register={{
                  ...photosRegister("description", {
                    required: "Description is required",
                    // pattern: {
                    //   value: alphanumericRegex,
                    //   message: "Numbers are not allowed in names",
                    // },
                  }),
                }}
                errors={photosErrors}
              />
            </div>
            <div className="col-span-4">
              <Controller
                control={photosControl}
                name="access"
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label
                      htmlFor="access"
                      className="mb-2 block text-xs text-gray-500"
                    >
                      People with Access
                    </label>
                    <MultiSelectComponent
                      id="access"
                      dataSource={accessOptions}
                      fields={fields}
                      value={value}
                      mode="CheckBox"
                      selectAllText="Select All"
                      unSelectAllText="Unselect All"
                      showSelectAll={true}
                      change={(e) => onChange(e.value)}
                    >
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                  </div>
                )}
              />
            </div>
          </form>
        </Modal>

        <DeleteModal
          isOpen={showDeletePhotoModal}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeletePhotoModal(false);
            setPhotosToDeleteId(null);
          }}
          onDelete={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsSubmittingPhotos(true);

            try {
              // Call the LockBoxService to soft delete the document
              await LockBoxService.softDeleteItemInLockbox(
                userId,
                photosToDeleteId,
                "photos_data"
              );

              // Close modal and refresh data
              setShowDeletePhotoModal(false);
              setIsSubmittingPhotos(false);
              await fetchData();
            } catch (error) {
              console.error("Error deleting Photo:", error);
              setIsSubmittingPhotos(false);
            }
          }}
          itemName="Photo"
          loading={isSubmittingPhotos}
        />

        <Modal
          isOpen={showCenterDocsModal}
          title={
            isEditingCenterDocs
              ? "Edit Center Document"
              : "Add New Center Document"
          }
          onClose={() => {
            setShowCenterDocsModal(false);
            setActiveMenuCenterDocs(null);
            centerDocsReset();
          }}
          formRef={centerDocsFormRef}
          isLoading={isSubmittingCenterDocs}
          isEdit={isEditingCenterDocs}
        >
          <form
            onSubmit={centerDocsHandleSubmit(onSubmitCenterDocs)}
            className="flex flex-col gap-4"
            ref={centerDocsFormRef}
          >
            <SimpleFileUploader
              storagePath={`lockbox/center_documents/${userId}`}
              onFileUpload={(files) => {
                if (files) {
                  centerDocsSetValue("photodocuments", files);
                }
              }}
              initialPhoto={centerDocsWatch("photodocuments")}
              acceptedTypes=".pdf,image/*"
              className="mb-4"
            />
            <div className="w-full">
              <CustomSelect
                name="type"
                placeholder="Type"
                register={{
                  ...centerDocsRegister("type", {
                    required: "Type is required",
                  }),
                }}
                options={mouTypes}
                errors={centerDocsErrors}
              />
            </div>
            <div className="w-full">
              <CustomTextArea
                type="text"
                name="description"
                placeholder="Description"
                register={{
                  ...centerDocsRegister("description", {
                    required: "Description is required",
                  }),
                }}
                errors={centerDocsErrors}
              />
            </div>
            {/* <div className="col-span-4">
              <Controller
                control={centerDocsControl}
                name="access"
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <label
                      htmlFor="access"
                      className="mb-2 block text-xs text-gray-500"
                    >
                      People with Access
                    </label>
                    <MultiSelectComponent
                      id="access"
                      dataSource={accessOptions}
                      fields={fields}
                      value={value}
                      mode="CheckBox"
                      selectAllText="Select All"
                      unSelectAllText="Unselect All"
                      showSelectAll={true}
                      change={(e) => onChange(e.value)}
                    >
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                  </div>
                )}
              />
            </div> */}
          </form>
        </Modal>

        <DeleteModal
          isOpen={showDeleteCenterDocsModal}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeleteCenterDocsModal(false);
            setCenterDocsToDeleteId(null);
          }}
          onDelete={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsSubmittingCenterDocs(true);

            try {
              await LockBoxService.softDeleteItemInLockbox(
                userId,
                centerDocsToDeleteId,
                "center_documents"
              );

              setShowDeleteCenterDocsModal(false);
              setIsSubmittingCenterDocs(false);
              await fetchData();
            } catch (error) {
              console.error("Error deleting center document:", error);
              setIsSubmittingCenterDocs(false);
            }
          }}
          itemName="Center Document"
          loading={isSubmittingCenterDocs}
        />

        <SignatureDetailsModal
          selectedSignature={selectedSignature}
          onClose={closeSignatureDetails}
        />
      </div>
    </>
  );
};

export default PartnerLockbox;
