import PropTypes from "prop-types";
import React, { useState } from "react";

const CustomAccordion = ({
  title,
  children,
  defaultOpen = false,
  className = "",
  classNameIcon = "",
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className={`w-full rounded-lg border ${className}`}>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="hover:bg-gray-50 flex w-full items-center justify-between bg-white px-2"
      >
        <span className="font-medium">{title}</span>
        <svg
          className={`h-[18px] w-[18px] transform transition-transform ${classNameIcon} ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && <div className="mt-2 border-t">{children}</div>}
    </div>
  );
};

CustomAccordion.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
  className: PropTypes.string,
};

export default CustomAccordion;
