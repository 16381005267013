import React, { useState, useEffect, useRef } from "react";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot,
  writeBatch,
  updateDoc,
  where,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject,
} from "@syncfusion/ej2-react-dropdowns";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { USStates } from "../datasource";
import countiesData from "../../counties_list.json";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

import {
  HtmlEditor,
  Image,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

const toolbarSettings = {
  items: [
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "OrderedList",
    "UnorderedList",
    "Outdent",
    "Indent",
    "|",
    "CreateLink",
    "Image",
    "|",
    "|",
    "Undo",
    "Redo",
  ],
};

const quickToolbarSettings = {
  image: [
    "Replace",
    "Align",
    "Caption",
    "Remove",
    "InsertLink",
    "OpenImageLink",
    "-",
    "EditImageLink",
    "RemoveImageLink",
    "Display",
    "AltText",
    "Dimension",
  ],
};

const MessageCenter = () => {
  const [editorContent, setEditorContent] = useState("");
  const [userData, setUserData] = useState({});
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageType, setMessageType] = useState("");
  const [selectedRecipient, setSelectedRecipient] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [counties, setCounties] = useState([]);
  const [subject, setSubject] = useState("");
  const [urgency, setUrgency] = useState("general");
  const [isUpdating, setIsUpdating] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    messageType: false,
    recipient: false,
    subject: false,
    content: false,
  });

  const selectedUserId = sessionStorage.getItem("userId");

  const handleMessageTypeChange = (e) => {
    setMessageType(e.value || "");
    setSelectedRecipient([]);
  };

  const handleCancel = () => {
    setSubject("");
    setEditorContent("");
    setMessageType("");
    setSelectedRecipient([]);
    setSelectedState("");
    setCounties([]);
    setUrgency("general");

    setValidationErrors({
      messageType: false,
      recipient: false,
      subject: false,
      content: false,
    });

    setIsModalOpen(false);

    if (userData?.state) {
      const stateObject = USStates.find(
        (state) => state.abbreviation === userData.state
      );
      const stateName = stateObject ? stateObject.name : "";

      const filteredCounties = countiesData
        .filter((county) => county.State === stateName)
        .map((county) => ({ County: county.County }));

      setCounties(filteredCounties);
    }
  };

  useEffect(() => {
    const loadRecipients = async () => {
      if (!messageType) return;

      const db = getFirestore();
      try {
        let collectionName;
        let transformFunction;

        switch (messageType) {
          case "county":
            return;

          case "disaster":
            collectionName = "declared-disaster";
            const querySnapshot = await getDocs(collection(db, collectionName));

            const userStateObj = USStates.find(
              (state) => state.abbreviation === userData?.state
            );
            const userStateName = userStateObj ? userStateObj.name : "";

            console.log("User State (full):", userStateName);

            let recipientsList = querySnapshot.docs
              .map((doc) => ({
                id: doc.id,
                disaster_name: doc.data().disaster_name,
                status: doc.data().disaster_status,
                disaster_type: doc.data().disaster_type,
                county: doc.data().disaster_county,
                state: doc.data().disaster_state,
              }))
              .filter(
                (disaster) =>
                  disaster.status === "active" &&
                  disaster.state === userStateName
              );

            setRecipients(recipientsList);
            return;

          case "partner":
            collectionName = "users";
            const auth = getAuth();
            const currentUser = auth.currentUser;
            try {
              if (currentUser) {
                const userDoc = await getDoc(doc(db, "users", selectedUserId));
                const userData = userDoc.data();
                const userCenterId = userData?.center_id;

                if (!userCenterId) {
                  console.log("No center_id found for current user");
                  setRecipients([]);
                  return;
                }

                const querySnapshot = await getDocs(
                  collection(db, collectionName)
                );
                let recipientsList = querySnapshot.docs
                  .map((doc) => {
                    const data = doc.data();
                    if (!data) return null;

                    if (
                      data.user_role === "partner" &&
                      data.center_id &&
                      data.center_id.includes(userCenterId)
                    ) {
                      return {
                        id: doc.id || "",
                        name:
                          `${data.first_name || ""} ${
                            data.last_name || ""
                          }`.trim() || "Unknown",
                        email: data.email || "",
                        organization: data.name_of_org || "No Organization",
                      };
                    }
                    return null;
                  })
                  .filter((item) => item !== null);

                setRecipients(recipientsList);
              } else {
                console.log("No current user found");
                setRecipients([]);
              }
            } catch (error) {
              console.error("Error in partner case:", error);
              setRecipients([]);
            }
            break;
          default:
            return;
        }

        if (messageType === "partner") {
          const auth = getAuth();
          const currentUser = auth.currentUser;
          try {
            if (selectedUserId && currentUser) {
              const userDoc = await getDoc(doc(db, "users", selectedUserId));
              const userData = userDoc.data();
              const userCenterId = userData?.center_id;

              if (!userCenterId) {
                console.log("No center_id found for current user");
                setRecipients([]);
                return;
              }

              const querySnapshot = await getDocs(
                collection(db, collectionName)
              );
              let recipientsList = querySnapshot.docs
                .map((doc) => {
                  const data = doc.data();
                  if (!data) return null;

                  if (
                    data.user_role === "partner" &&
                    data.center_id &&
                    data.center_id.includes(userCenterId)
                  ) {
                    return {
                      id: doc.id || "",
                      name:
                        `${data.first_name || ""} ${
                          data.last_name || ""
                        }`.trim() || "Unknown",
                      email: data.email || "",
                      organization: data.name_of_org || "No Organization",
                    };
                  }
                  return null;
                })
                .filter((item) => item !== null);

              setRecipients(recipientsList);
            } else {
              console.log("No current user found");
              setRecipients([]);
            }
          } catch (error) {
            console.error("Error in partner case:", error);
            setRecipients([]);
          }
        }
      } catch (error) {
        console.error("Error loading recipients:", error);
      }
    };

    loadRecipients();
  }, [messageType, userData?.state]);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const db = getFirestore();
      const user = auth.currentUser;

      if (user) {
        try {
          const userDocRef = doc(db, "centers", selectedUserId);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleDelete = async () => {
    const db = getFirestore();
    const batch = writeBatch(db);

    try {
      // Update each selected message
      for (const messageId of selectedMessages) {
        const messageRef = doc(db, "message-center", messageId);
        batch.update(messageRef, {
          is_deleted: true,
        });
      }

      await batch.commit();
      setIsDeleteMode(false);
      setSelectedMessages([]);

      // If the currently selected message was deleted, clear it
      if (selectedMessage && selectedMessages.includes(selectedMessage.id)) {
        setSelectedMessage(null);
      }
    } catch (error) {
      console.error("Error deleting messages:", error);
      alert("Failed to delete messages. Please try again.");
    }
  };

  useEffect(() => {
    const db = getFirestore();
    const q = query(
      collection(db, "message-center"),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((message) => !message.is_deleted);

      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userData?.state) {
      const stateObject = USStates.find(
        (state) => state.abbreviation === userData.state
      );
      const stateName = stateObject ? stateObject.name : "";

      const filteredCounties = countiesData
        .filter((county) => county.State === stateName)
        .map((county) => ({ County: county.County }));

      setCounties(filteredCounties);
    }
  }, [userData]);

  const getUrgencyDetails = (urgency) => {
    switch (urgency) {
      case "emergency":
        return { letter: "E", color: "bg-red-500" };
      case "warning":
        return { letter: "W", color: "bg-orange-500" };
      case "updates":
        return { letter: "U", color: "bg-green-500" };
      default:
        return { letter: "G", color: "bg-blue-500" };
    }
  };

  const handleEditorChange = (args) => {
    setEditorContent(args.value);
  };

  const handleSend = async () => {
    setValidationErrors({
      messageType: !messageType,
      recipient:
        messageType === "state"
          ? !selectedState || selectedRecipient.length === 0
          : !selectedRecipient,
      subject: !subject.trim(),
      content: !editorContent.trim(),
    });

    if (
      !messageType ||
      (messageType === "state"
        ? !selectedState || selectedRecipient.length === 0
        : !selectedRecipient) ||
      !subject.trim() ||
      !editorContent.trim()
    ) {
      return;
    }

    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const userDoc = await getDoc(doc(db, "users", selectedUserId));
    if (!userDoc.exists()) {
      console.error("User document not found");
      return;
    }
    const userData = userDoc.data();

    if (!user) {
      alert("You must be logged in to send messages");
      return;
    }

    try {
      const messageId = doc(collection(db, "message-center")).id;

      const messageData = {
        messageId: messageId,
        type: `${messageType}_message`,
        title: subject,
        content: editorContent,
        urgency,
        timestamp: serverTimestamp(),
        read: false,
        global_notification: true,
        sender: {
          id: selectedUserId,
          name: `${userData.first_name || ""} ${
            userData.last_name || ""
          }`.trim(),
          email: userDoc.email,
          organization: userData.name_of_org || "",
          role: userData.user_role || "",
        },
        active: true,
        expirationDate: expirationDate ? expirationDate.getTime() : null,
      };

      if (messageType === "county") {
        messageData.recipients = {
          counties: selectedRecipient,
        };
        messageData.county = selectedRecipient;
      } else if (messageType === "disaster") {
        const disasterDoc = await getDoc(
          doc(db, "declared-disaster", selectedRecipient)
        );
        const disasterData = disasterDoc.data();

        messageData.recipients = {
          id: selectedRecipient,
          type: messageType,
          disaster_name: disasterData.disaster_name,
          disaster_county: disasterData.disaster_county,
          disaster_state: disasterData.disaster_state,
        };
        messageData.disaster_county = disasterData.disaster_county;
        messageData.disaster_state = disasterData.disaster_state;
        messageData.disaster_name = disasterData.disaster_name;
      } else if (messageType === "partner") {
        const partnerDetails = recipients
          .filter((partner) => selectedRecipient.includes(partner.id))
          .map((partner) => ({
            id: partner.id,
            email: partner.email,
            name: partner.name,
          }));

        messageData.recipients = {
          ids: partnerDetails,
          type: messageType,
        };
        messageData.targetedPartners = selectedRecipient;
      }

      await setDoc(doc(db, "message-center", messageId), messageData);

      const batch = writeBatch(db);
      const createNotificationData = (additionalData = {}) => ({
        ...messageData,
        ...additionalData,
      });

      if (messageType === "county") {
        const usersSnapshot = await getDocs(collection(db, "users"));

        usersSnapshot.forEach((userDoc) => {
          const userData = userDoc.data();
          if (selectedRecipient.includes(userData.county)) {
            const notificationRef = doc(
              collection(db, "notifications", userDoc.id, "notifications")
            );
            batch.set(
              notificationRef,
              createNotificationData({
                county: userData.county,
              })
            );
          }
        });
      } else if (messageType === "partner") {
        for (const partnerId of selectedRecipient) {
          const notificationRef = doc(
            collection(db, "notifications", partnerId, "notifications")
          );
          batch.set(notificationRef, createNotificationData());
        }
      } else if (messageType === "disaster") {
        const disasterDoc = await getDoc(
          doc(db, "declared-disaster", selectedRecipient)
        );
        const disasterData = disasterDoc.data();

        if (disasterData) {
          const usersSnapshot = await getDocs(collection(db, "users"));

          usersSnapshot.forEach((userDoc) => {
            const userData = userDoc.data();
            if (userData.county === disasterData.disaster_county) {
              const notificationRef = doc(
                collection(db, "notifications", userDoc.id, "notifications")
              );
              batch.set(
                notificationRef,
                createNotificationData({
                  disaster_county: disasterData.disaster_county,
                  disaster_state: disasterData.disaster_state,
                  disaster_name: disasterData.disaster_name,
                })
              );
            }
          });
        }
      }

      await setDoc(doc(db, "message-center", messageId), messageData);

      setSubject("");
      setEditorContent("");
      setMessageType("");
      setSelectedRecipient([]);
      setUrgency("general");
      setIsModalOpen(false);
      setExpirationDate(null);

      const dropdownElement = document.querySelector(".e-dropdownlist");
      if (dropdownElement) {
        dropdownElement.ej2_instances[0].text = "";
        dropdownElement.ej2_instances[0].value = null;
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message. Please try again.");
    }
  };

  return (
    <div className="m-0 box-border flex h-screen w-full">
      <div className="flex w-1/3 flex-col border-r p-4">
        <div className="mb-4">
          <div className="mb-4 flex items-center justify-between">
            <h2 className="text-xl font-semibold">Message Center</h2>
            {isDeleteMode ? (
              <div className="flex gap-2">
                <button
                  onClick={handleDelete}
                  disabled={selectedMessages.length === 0}
                  className={`rounded px-3 py-1 text-white ${
                    selectedMessages.length === 0
                      ? "cursor-not-allowed bg-gray-400"
                      : "bg-red-500 hover:bg-red-600"
                  }`}
                >
                  Delete ({selectedMessages.length})
                </button>
                <button
                  onClick={() => {
                    setIsDeleteMode(false);
                    setSelectedMessages([]);
                  }}
                  className="rounded bg-gray-200 px-3 py-1 hover:bg-gray-300"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                onClick={() => setIsDeleteMode(true)}
                className="text-gray-600 bg-white p-2 hover:text-gray-800"
              >
                <svg
                  className="h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            )}
          </div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="btn-default w-full cursor-pointer rounded-lg px-4 py-2 hover:bg-blue-500"
          >
            Compose Message
          </button>
        </div>

        {/* Inbox Section */}
        <div className="flex-1 overflow-y-auto">
          {messages.map((message) => {
            const urgencyDetails = getUrgencyDetails(message.urgency);
            return (
              <div
                key={message.id}
                className={`mb-2 flex cursor-pointer items-start gap-4 rounded-lg border-[1px] 
                  border-solid p-4 
                  ${
                    selectedMessage?.id === message.id
                      ? "border-[#0072C6] bg-[#0072C6] text-white"
                      : "border-silver-100 hover:bg-gray-500"
                  }`}
                onClick={() => !isDeleteMode && setSelectedMessage(message)}
              >
                {isDeleteMode && (
                  <div
                    className="flex items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedMessages((prev) =>
                        prev.includes(message.id)
                          ? prev.filter((id) => id !== message.id)
                          : [...prev, message.id]
                      );
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedMessages.includes(message.id)}
                      onChange={() => {}}
                      className="mr-2 h-4 w-4"
                    />
                  </div>
                )}
                {/* Urgency Avatar */}
                <div
                  className={`h-10 w-10 flex-shrink-0 ${urgencyDetails.color} flex items-center justify-center rounded-full`}
                >
                  <span className="font-semibold text-white">
                    {urgencyDetails.letter}
                  </span>
                </div>

                {/* Message Content */}
                <div className="min-w-0 flex-1">
                  <div className="flex items-center justify-between">
                    <h3 className="truncate font-semibold">{message.title}</h3>
                    <span className="text-xs text-gray-400">
                      {message.timestamp
                        ? format(message.timestamp.toDate(), "MMM d, h:mm a")
                        : "Just now"}
                    </span>
                  </div>
                  <div className="text-gray-600 flex items-center gap-2 text-sm">
                    <span className="font-medium">To:</span>
                    <span className="truncate">
                      {message.recipients?.counties &&
                        `${message.recipients.counties.length} ${
                          message.recipients.counties.length === 1
                            ? "County"
                            : "Counties"
                        }`}
                      {message.recipients?.disaster_name &&
                        `${message.recipients.disaster_name} - ${message.recipients.disaster_county}`}
                      {message.recipients?.ids && (
                        <>
                          {message.recipients.ids.length > 3
                            ? `${message.recipients.ids[0].name} +${
                                message.recipients.ids.length - 1
                              } more`
                            : message.recipients.ids
                                .map((partner) => partner.name)
                                .join(", ")}
                        </>
                      )}
                    </span>
                  </div>
                  <div className="mt-1 truncate text-sm text-gray-500">
                    {message.content.replace(/<[^>]*>/g, "")}
                  </div>
                </div>
              </div>
            );
          })}
          {messages.length === 0 && (
            <div className="mt-4 text-center text-gray-500">
              No messages yet
            </div>
          )}
        </div>
      </div>

      {/* Right Panel*/}
      <div className="w-2/3 border-[1px] border-solid border-silver-100 p-4 ">
        {selectedMessage ? (
          <div className="flex h-full flex-col rounded-lg border-[1px] border-solid border-silver-100">
            {/* Header*/}
            <div className="rounded-t-lg bg-[#467BA4] p-4 text-[#FDB841]">
              <div className="mb-2 flex items-center justify-between">
                <h2 className="text-xl font-semibold">
                  {selectedMessage.title}
                </h2>

                <span className="text-sm text-white/80">
                  {selectedMessage.timestamp
                    ? format(
                        selectedMessage.timestamp.toDate(),
                        "MMM d, yyyy h:mm a"
                      )
                    : "Just now"}
                </span>
              </div>

              {/* Sender Info */}
              <div className="flex flex-col gap-2 text-sm text-white/90">
                <div className="flex items-start justify-between">
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      <span className="font-medium">From: </span>
                      {selectedMessage.sender.name} (
                      {selectedMessage.sender.organization})
                    </div>
                    <div className="flex items-start gap-2">
                      <span className="font-medium">To:</span>
                      <div className="flex-1">
                        {selectedMessage.recipients?.counties && (
                          <>
                            <span>
                              {`${selectedMessage.recipients.counties.length} ${
                                selectedMessage.recipients.counties.length === 1
                                  ? "County"
                                  : "Counties"
                              }`}
                            </span>
                            <div className="ml-1 inline-flex flex-wrap gap-1">
                              {selectedMessage.recipients.counties.length >
                              3 ? (
                                <>
                                  {selectedMessage.recipients.counties
                                    .slice(0, 3)
                                    .map((county, index) => (
                                      <span
                                        key={index}
                                        className="bg-gray-50 inline-flex items-center rounded border border-gray-200 px-2 py-0.5 text-xs"
                                      >
                                        {county}
                                      </span>
                                    ))}
                                  <span className="bg-gray-50 inline-flex items-center rounded border border-gray-200 px-2 py-0.5 text-xs">
                                    +
                                    {selectedMessage.recipients.counties
                                      .length - 3}{" "}
                                    more
                                  </span>
                                </>
                              ) : (
                                selectedMessage.recipients.counties.map(
                                  (county, index) => (
                                    <span
                                      key={index}
                                      className="bg-gray-50 inline-flex items-center rounded border border-gray-200 px-2 py-0.5 text-xs"
                                    >
                                      {county}
                                    </span>
                                  )
                                )
                              )}
                            </div>
                          </>
                        )}

                        {selectedMessage.type === "disaster_message" && (
                          <span>
                            {`${selectedMessage.recipients.disaster_name} - ${selectedMessage.recipients.disaster_county}`}
                          </span>
                        )}

                        {selectedMessage.type === "partner_message" && (
                          <div className="flex flex-col gap-1">
                            {selectedMessage.recipients.ids.length > 3 ? (
                              <>
                                {selectedMessage.recipients.ids
                                  .slice(0, 3)
                                  .map((partner, index) => (
                                    <span
                                      key={index}
                                      className="text-gray-600 text-sm"
                                    >
                                      {partner.email || partner.name}
                                    </span>
                                  ))}
                                <span className="text-sm text-gray-500">
                                  +{selectedMessage.recipients.ids.length - 3}{" "}
                                  more partners
                                </span>
                              </>
                            ) : (
                              selectedMessage.recipients.ids.map(
                                (partner, index) => (
                                  <span
                                    key={index}
                                    className="text-gray-600 text-sm"
                                  >
                                    {partner.email || partner.name}
                                  </span>
                                )
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {selectedMessage.urgency && (
                    <button
                      onClick={async () => {
                        try {
                          setIsUpdating(true);
                          const messageRef = doc(
                            db,
                            "message-center",
                            selectedMessage.id
                          );
                          await updateDoc(messageRef, {
                            active: !selectedMessage.active,
                          });

                          const usersSnapshot = await getDocs(
                            collection(db, "users")
                          );
                          const batch = writeBatch(db);
                          const promises = [];

                          usersSnapshot.forEach((userDoc) => {
                            const notificationsRef = collection(
                              db,
                              "notifications",
                              userDoc.id,
                              "notifications"
                            );
                            const q = query(
                              notificationsRef,
                              where("id", "==", selectedMessage.id)
                            );
                            promises.push(getDocs(q));
                          });

                          const notificationSnapshots = await Promise.all(
                            promises
                          );

                          notificationSnapshots.forEach(
                            (notificationSnapshot) => {
                              notificationSnapshot.forEach(
                                (notificationDoc) => {
                                  const notificationRef = doc(
                                    db,
                                    "notifications",
                                    notificationDoc.ref.parent.parent.id,
                                    "notifications",
                                    selectedMessage.id
                                  );
                                  batch.update(notificationRef, {
                                    active: !selectedMessage.active,
                                  });
                                }
                              );
                            }
                          );

                          await batch.commit();

                          setSelectedMessage((prev) => ({
                            ...prev,
                            active: !prev.active,
                          }));
                        } catch (error) {
                          console.error("Error toggling status:", error);
                          alert("Failed to update status. Please try again.");
                        } finally {
                          setIsUpdating(false);
                        }
                      }}
                      disabled={isUpdating}
                      className={`ml-4 flex cursor-pointer items-center gap-1.5 whitespace-nowrap rounded px-3 py-1.5 text-sm transition-colors duration-200
            ${isUpdating ? "cursor-not-allowed opacity-75 " : ""}
            ${
              selectedMessage.active
                ? "bg-green-600 text-white hover:bg-green-700"
                : "bg-red-600 text-white hover:bg-red-700"
            }`}
                    >
                      {isUpdating ? (
                        <svg
                          className="animate-spin h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          {selectedMessage.active ? (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          ) : (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          )}
                        </svg>
                      )}
                      {isUpdating
                        ? "Updating..."
                        : selectedMessage.active
                        ? "Turn On"
                        : "Turn Off"}
                    </button>
                  )}
                </div>
              </div>

              {/* Urgency Badge*/}
              <div className="mt-2">
                <span
                  className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium
                        ${
                          selectedMessage.urgency === "emergency"
                            ? "bg-red-100 text-red-800"
                            : selectedMessage.urgency === "warning"
                            ? "bg-orange-100 text-orange-800"
                            : selectedMessage.urgency === "updates"
                            ? "bg-green-100 text-green-800"
                            : "bg-white/10 text-white"
                        }`}
                >
                  {selectedMessage.urgency.charAt(0).toUpperCase() +
                    selectedMessage.urgency.slice(1)}
                </span>
              </div>
            </div>

            {/* Message Content */}
            <div className="flex-1 overflow-y-auto p-6">
              <div
                className="prose max-w-none"
                dangerouslySetInnerHTML={{ __html: selectedMessage.content }}
              />
            </div>
          </div>
        ) : (
          <div className="flex h-full items-center justify-center rounded-lg border-2 border-dashed text-gray-500">
            Select a message to view its contents
          </div>
        )}
      </div>

      {/* Modal */}
      <DialogComponent
        isModal={true}
        visible={isModalOpen}
        close={handleCancel}
        header="New Message"
        closeOnEscape={true}
        showCloseIcon={true}
        target="#root"
        cssClass="message-dialog"
      >
        <div className="flex h-full flex-col">
          {/* Recipient Section */}
          <div className="flex items-center justify-between border-b p-4">
            <div className="flex flex-1 items-center gap-2">
              <div className="flex items-center gap-1">
                To:
                <span className="text-red-500">*</span>
              </div>
              <div className="flex flex-1 items-center gap-2">
                <div>
                  <DropDownListComponent
                    dataSource={[
                      { text: "County", value: "county" },
                      { text: "Disaster", value: "disaster" },
                      { text: "Partner", value: "partner" },
                    ]}
                    value={messageType || null}
                    change={(e) => {
                      handleMessageTypeChange(e);
                      setValidationErrors((prev) => ({
                        ...prev,
                        messageType: false,
                      }));
                    }}
                    cssClass={`e-outline ${
                      validationErrors.messageType ? "e-error" : ""
                    }`}
                    floatLabelType="Auto"
                    placeholder="Select Recipient Type"
                    fields={{ text: "text", value: "value" }}
                    allowReset={true}
                    showClearButton={true}
                  />
                  {validationErrors.messageType && (
                    <div className="mt-1 text-xs text-red-500">
                      Please select a recipient type
                    </div>
                  )}
                </div>

                {messageType === "county" && counties.length > 0 && (
                  <div className="flex-1">
                    <MultiSelectComponent
                      id="countySelect"
                      dataSource={counties}
                      fields={{ text: "County", value: "County" }}
                      value={selectedRecipient}
                      change={(e) => {
                        setSelectedRecipient(
                          Array.isArray(e.value) ? e.value : []
                        );
                        setValidationErrors((prev) => ({
                          ...prev,
                          recipient: false,
                        }));
                      }}
                      cssClass={`e-outline ${
                        validationErrors.recipient ? "e-error" : ""
                      }`}
                      floatLabelType="Auto"
                      placeholder="Select County"
                      mode="CheckBox"
                      selectAllText="Select All"
                      unSelectAllText="Unselect All"
                      showSelectAll={true}
                    >
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                    {validationErrors.recipient && (
                      <div className="mt-1 text-xs text-red-500">
                        Please select a county
                      </div>
                    )}
                  </div>
                )}

                {messageType && messageType !== "county" && (
                  <div className="flex-1">
                    {messageType === "partner" ? (
                      <MultiSelectComponent
                        id="partnerSelect"
                        dataSource={recipients || []}
                        fields={{
                          text: "name",
                          value: "id",
                        }}
                        value={selectedRecipient}
                        change={(e) => {
                          setSelectedRecipient(
                            Array.isArray(e.value) ? e.value : []
                          );
                          setValidationErrors((prev) => ({
                            ...prev,
                            recipient: false,
                          }));
                        }}
                        cssClass={`e-outline ${
                          validationErrors.recipient ? "e-error" : ""
                        }`}
                        floatLabelType="Auto"
                        placeholder="Select Partners"
                        mode="CheckBox"
                        selectAllText="Select All"
                        unSelectAllText="Unselect All"
                        showSelectAll={true}
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    ) : (
                      <DropDownListComponent
                        dataSource={recipients || []}
                        fields={{
                          text: "disaster_name",
                          value: "id",
                        }}
                        value={selectedRecipient}
                        change={(e) => {
                          setSelectedRecipient(e.value);
                          setValidationErrors((prev) => ({
                            ...prev,
                            recipient: false,
                          }));
                        }}
                        cssClass={`e-outline ${
                          validationErrors.recipient ? "e-error" : ""
                        }`}
                        floatLabelType="Auto"
                        placeholder="Select Disaster"
                      />
                    )}
                    {validationErrors.recipient && (
                      <div className="mt-1 text-xs text-red-500">
                        Please select a recipient
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Urgency Section - remains unchanged */}
          <div className="flex items-center justify-between border-b p-4">
            <div className="flex items-center gap-4">
              <span className="font-medium">Urgency:</span>
              <div className="flex gap-6">
                <label className="flex cursor-pointer items-center gap-2">
                  <input
                    type="radio"
                    name="urgency"
                    value="emergency"
                    checked={urgency === "emergency"}
                    onChange={(e) => setUrgency(e.target.value)}
                    className="text-red-500 focus:ring-red-500"
                  />
                  <span className="font-medium text-red-500">Emergency</span>
                </label>

                <label className="flex cursor-pointer items-center gap-2">
                  <input
                    type="radio"
                    name="urgency"
                    value="warning"
                    checked={urgency === "warning"}
                    onChange={(e) => setUrgency(e.target.value)}
                    className="text-orange-500 focus:ring-orange-500"
                  />
                  <span className="font-medium text-orange-500">Warning</span>
                </label>

                <label className="flex cursor-pointer items-center gap-2">
                  <input
                    type="radio"
                    name="urgency"
                    value="general"
                    checked={urgency === "general"}
                    onChange={(e) => setUrgency(e.target.value)}
                    className="text-blue-500 focus:ring-blue-500"
                  />
                  <span className="font-medium text-blue-500">
                    General News
                  </span>
                </label>

                <label className="flex cursor-pointer items-center gap-2">
                  <input
                    type="radio"
                    name="urgency"
                    value="updates"
                    checked={urgency === "updates"}
                    onChange={(e) => setUrgency(e.target.value)}
                    className="text-green-500 focus:ring-green-500"
                  />
                  <span className="font-medium text-green-500">Updates</span>
                </label>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between border-b p-4">
            <div className="flex items-center gap-4">
              <span className="font-medium">Expiration Date:</span>
              <DatePickerComponent
                value={expirationDate}
                onChange={(e) => setExpirationDate(e.value)}
                placeholder="Select expiration date"
                format="MMMM d, yyyy"
                cssClass="e-outline"
              />
            </div>
          </div>

          {/* Subject Section */}
          <div className="flex items-center justify-between border-b p-4">
            <div className="flex flex-1 items-center">
              <div className="w-full">
                <TextBoxComponent
                  placeholder="Subject*"
                  value={subject}
                  change={(e) => {
                    setSubject(e.value);
                    setValidationErrors((prev) => ({
                      ...prev,
                      subject: false,
                    }));
                  }}
                  cssClass={`e-outline ${
                    validationErrors.subject ? "e-error" : ""
                  }`}
                  floatLabelType="Auto"
                  width="100%"
                />
                {validationErrors.subject && (
                  <div className="mt-1 text-xs text-red-500">
                    Subject is required
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Editor Section */}
          <div className="flex-1 overflow-y-auto p-4">
            <div className="mb-1 flex items-center gap-1">
              <span className="text-red-500">*</span>
              <span className="text-gray-600 text-sm">Message Content</span>
            </div>
            <div
              className={
                validationErrors.content
                  ? "rounded-lg border-2 border-red-500"
                  : ""
              }
            >
              <RichTextEditorComponent
                height={350}
                toolbarSettings={toolbarSettings}
                quickToolbarSettings={quickToolbarSettings}
                value={editorContent}
                change={(e) => {
                  handleEditorChange(e);
                  setValidationErrors((prev) => ({ ...prev, content: false }));
                }}
              >
                <Inject
                  services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
            {validationErrors.content && (
              <div className="mt-1 text-xs text-red-500">
                Message content is required
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="border-t p-4">
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-500">* Required fields</div>
              <div className="flex gap-2">
                <button
                  onClick={handleCancel}
                  className="btn-cancel flex cursor-pointer gap-1 px-3 py-1.5"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSend}
                  className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogComponent>
    </div>
  );
};

export default MessageCenter;
