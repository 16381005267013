import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDeferred } from "../../hooks/useDeferred";

const SurveyQuestionAccordion = ({
  question,
  type,
  options,
  onResponseChange,
  userResponse,
  followUp,
  section,
  disabled,
  handleSubmitSurvey,
}) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [followUpResponse, setFollowUpResponse] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { cbDebounce } = useDeferred();

  useEffect(() => {
    if (type === "checkbox") {
      setCheckedItems(userResponse?.answer || {});
    } else if (type === "text") {
      setInputValue(userResponse?.answer || "");
    }

    if (
      userResponse?.answer === "Other" ||
      (type === "checkbox" && userResponse?.answer?.Other)
    ) {
      setShowFollowUp(true);
      setFollowUpResponse(userResponse.followUp || "");
    } else {
      setShowFollowUp(false);
      setFollowUpResponse("");
    }
  }, [userResponse, type]);

  const handleRadioChange = (option) => {
    const isOther = option === "Other";
    setShowFollowUp(isOther);
    setFollowUpResponse(isOther ? userResponse?.followUp || "" : "");

    // cbDebounce(() => {
    onResponseChange(question, {
      answer: option,
      followUp: isOther ? followUpResponse : undefined,
    });
    // });
  };

  const handleCheckboxChange = (option) => {
    if (option === "Check All") {
      const allChecked =
        Object.values(checkedItems).every(Boolean) &&
        Object.keys(checkedItems).length === options.length - 1;
      const newCheckedItems = {};
      options.forEach((opt) => {
        if (opt !== "Check All") {
          newCheckedItems[opt] = !allChecked;
        }
      });
      setCheckedItems(newCheckedItems);
      cbDebounce(() => {
        const newCheckedItems = {};
        options.forEach((opt) => {
          if (opt !== "Check All") {
            newCheckedItems[opt] = !allChecked;
          }
        });
        onResponseChange(question, { answer: newCheckedItems });
      });
      return;
    }

    const newCheckedItems = {
      ...checkedItems,
      [option]: !checkedItems[option],
    };
    setCheckedItems(newCheckedItems);
    const isOther = option === "Other" && newCheckedItems[option];
    setShowFollowUp(isOther);
    if (!isOther) setFollowUpResponse("");
    cbDebounce(() => {
      const newCheckedItems = {
        ...checkedItems,
        [option]: !checkedItems[option],
      };
      const isOther = option === "Other" && newCheckedItems[option];
      onResponseChange(question, {
        answer: newCheckedItems,
        followUp: isOther ? followUpResponse : undefined,
      });
    });
  };

  const handleFollowUpChange = (event) => {
    const value = event.target.value;
    setFollowUpResponse(value);
    cbDebounce(() => {
      onResponseChange(question, {
        answer: userResponse?.answer,
        followUp: event.target.value,
      });
    });
  };

  const handleTextInputChange = (e) => {
    const value = e.target.value;
    // Only allow numbers and validate max value
    if (/^\d*$/.test(value) && (value === "" || parseInt(value) <= 20)) {
      setInputValue(value);
      cbDebounce(() => {
        console.log("value", e.target.value);
        onResponseChange(question, {
          answer: e.target.value,
        });
      });
    }
  };

  const renderRadioButtons = () => {
    return options.map((option, index) => (
      <label
        key={index}
        className="mb-2 flex w-full cursor-pointer items-start"
      >
        <input
          className="form-radio h-5 min-h-[1.25rem] w-5 min-w-[1.25rem] appearance-none border-gray-300"
          type="radio"
          name={question.replace(/\s+/g, "-").toLowerCase()}
          value={option}
          checked={userResponse?.answer === option}
          onChange={() => handleRadioChange(option)}
          disabled={disabled}
        />
        <span className="ml-2 break-words text-sm text-gray-800">{option}</span>
      </label>
    ));
  };

  const renderCheckboxes = () => {
    if (question === "Do you have insurance on your home or contents?") {
      return (
        <div className="flex flex-col gap-2">
          {options.includes("Check All") && (
            <label key="check-all" className="flex cursor-pointer items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 min-h-[1rem] w-5 min-w-[1rem] rounded border-gray-300 focus:ring-blue-500"
                checked={
                  Object.values(checkedItems).every(Boolean) &&
                  Object.keys(checkedItems).length === options.length - 1
                }
                onChange={() => handleCheckboxChange("Check All")}
                disabled={disabled}
              />
              <span className="text-gray-700 ml-2 text-sm">Check All</span>
            </label>
          )}
          {options
            .filter((opt) => opt !== "Check All")
            .map((option, index) => (
              <label key={index} className="flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 min-h-[1rem] w-5 min-w-[1rem] rounded border-gray-300 focus:ring-blue-500"
                  checked={checkedItems[option] || false}
                  onChange={() => handleCheckboxChange(option)}
                  disabled={disabled}
                />
                <span className="text-gray-700 ml-2 text-sm">{option}</span>
              </label>
            ))}
        </div>
      );
    } else if (question === "There is visible damage to the home's:" || question === "What parts of the home have visible damage?") {
      return (
        <div className="grid grid-cols-3 gap-4">
          {options.includes("Check All") && (
            <label key="check-all" className="flex cursor-pointer items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 min-h-[1rem] w-5 min-w-[1rem] rounded border-gray-300 focus:ring-blue-500"
                checked={
                  Object.values(checkedItems).every(Boolean) &&
                  Object.keys(checkedItems).length === options.length - 1
                }
                onChange={() => handleCheckboxChange("Check All")}
                disabled={disabled}
              />
              <span className="text-gray-700 ml-2 text-sm">Check All</span>
            </label>
          )}
          {options
            .filter((opt) => opt !== "Check All")
            .map((option, index) => (
              <label key={index} className="flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 min-h-[1rem] w-5 min-w-[1rem] rounded border-gray-300 focus:ring-blue-500"
                  checked={checkedItems[option] || false}
                  onChange={() => handleCheckboxChange(option)}
                  disabled={disabled}
                />
                <span className="text-gray-700 ml-2 text-sm">{option}</span>
              </label>
            ))}
        </div>
      );
    }
    return (
      <div className="flex flex-col gap-2">
        {options.map((option, index) => (
          <label key={index} className="flex cursor-pointer items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 min-h-[1rem] w-5 min-w-[1rem] rounded border-gray-300 focus:ring-blue-500"
              checked={checkedItems[option] || false}
              onChange={() => handleCheckboxChange(option)}
              disabled={disabled}
            />
            <span className="text-gray-700 ml-2 text-sm">{option}</span>
          </label>
        ))}
      </div>
    );
  };

  // const isAnswerEmpty = () => {
  //   if (type === "checkbox") {
  //     return (
  //       Object.entries(userResponse?.answer || {}).filter(
  //         ([_, checked]) => checked
  //       ).length === 0
  //     );
  //   }
  // };

  return (
    <div
      className={`box-border flex w-full flex-col overflow-hidden rounded-mini border border-gainsboro-300 px-2
      
      `}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className={`flex w-full items-start justify-between gap-x-8 bg-transparent py-0 text-start text-sm font-medium leading-5 text-darkslategray-100`}
      >
        <div className=" flex flex-col gap-1">
          <div>
            {section && (
              <h3 className="text-lg font-bold text-blue-800">{section}</h3>
            )}
            <p className={`text-sm font-medium text-black`}>
              {/* <p
              className={`text-sm font-medium text-black ${
                !userResponse?.answer ||
                Object.entries(userResponse?.answer || {}).filter(
                  ([_, checked]) => checked
                ).length === 0
                  ? "font-medium text-dark-burgundy"
                  : ""
              }`}
            > */}
              {question}{" "}
            </p>
          </div>
          <p className="text-sm font-semibold text-black">
            {" "}
            {type === "checkbox" ? (
              Object.entries(userResponse?.answer || {})
                .filter(([_, checked]) => checked)
                .map(([option]) => option)
                .join(", ") || (
                <span className="text-dark-burgundy">No answer</span>
              )
            ) : userResponse?.answer ? (
              userResponse?.answer
            ) : (
              <span className="text-dark-burgundy">No answer</span>
            )}
            {userResponse?.followUp &&
              (Array.isArray(userResponse.followUp)
                ? `, ${userResponse.followUp.join(", ")}`
                : `, ${userResponse.followUp}`)}
          </p>
        </div>
        <svg
          className={`flex-shrink-0 transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          width={16}
          height={16}
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="mb-2 mt-2 px-2">
          {type === "radio" && (
            <div className="space-y-2">{renderRadioButtons()}</div>
          )}
          {type === "checkbox" && (
            <div className="flex flex-col gap-2">{renderCheckboxes()}</div>
          )}
          {type === "text" && (
            <input
              type="text"
              value={inputValue}
              onChange={handleTextInputChange}
              className="w-full rounded border p-2"
              disabled={disabled}
              pattern="[0-9]*"
              maxLength="2"
              placeholder="Enter a number (max: 20)"
            />
          )}
          {showFollowUp && (
            <div className="ml-4 mt-2">
              <label className="mb-2 block font-medium">Please specify:</label>
              <input
                type="text"
                value={followUpResponse}
                onChange={handleFollowUpChange}
                className="w-full rounded border p-2"
                disabled={disabled}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SurveyQuestionAccordion.propTypes = {
  question: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["radio", "checkbox", "text"]).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onResponseChange: PropTypes.func.isRequired,
  userResponse: PropTypes.shape({
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    followUp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  followUp: PropTypes.shape({
    condition: PropTypes.string,
    question: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  section: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SurveyQuestionAccordion;
