import React, { useState, useEffect, useRef } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Toolbar,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "../style.css";
import { config } from "../../utils/config";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const editOptions = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog, w-[800px]" },
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} `,
  }));
};

const Contacts = ({ className = "" }) => {
  const [commContacts, setCommContacts] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(selectedUserId);
        console.log("User ID:", selectedUserId);
        fetchContacts(); // Use the new fetchContacts function
      } else {
        setUserId(null);
        setCommContacts([]);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const fetchContacts = async () => {
    if (!userId) {
      console.log("No user ID available, skipping fetch.");
      return;
    }

    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "community-contacts"),
          where("user_id", "==", userId)
        )
      );
      const contactsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Fetched contacts:", contactsData);
      setCommContacts(preprocessData(contactsData));
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleAdd = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const actionBegin = async (args) => {
    if (gridRef.current) {
      try {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          setTimeout(() => {
            if (args.dialog) {
              // Set the dialog header based on the action
              if (args.requestType === "beginEdit") {
                args.dialog.header = "Edit Contact";
              } else {
                args.dialog.header = "Add Contact";
              }

              // Set the dialog height
              args.dialog.element.style.maxHeight = "900px"; // Set max height
              args.dialog.element.style.height = "auto"; // Set height to auto

              // Set the header styles
              const headerContent = args.dialog.element.querySelector(
                ".e-dlg-header-content"
              );
              const header = args.dialog.element.querySelector(".e-dlg-header");
              if (headerContent) {
                headerContent.style.backgroundColor = "#348BFF";
                headerContent.style.color = "#FFFFFF";
              }
              if (header) {
                header.style.color = "#FFFFFF";
              }

              // Style the save and cancel buttons
              const saveButton = args.dialog.element.querySelector(".e-primary");
              const cancelButton = args.dialog.element.querySelector(
                ".e-btn:not(.e-primary)"
              );

              if (saveButton) {
                saveButton.style.backgroundColor = "#FFFFFF";
                saveButton.style.color = "#348BFF";
                saveButton.style.border = "none";
              }

              if (cancelButton) {
                cancelButton.style.backgroundColor = "#FFFFFF";
                cancelButton.style.color = "#348BFF";
                cancelButton.style.border = "1px solid #348BFF";
              }
            }
          }, 0); // Delay execution to allow dialog to initialize

          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = true;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = false;
            }
          }
        }

        if (args.requestType === "save") {
          setLastEditedTime(new Date());

          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = true;
            }
          }

          // Update Name and Address fields
          const data = args.data;
          data.Name = `${data.first_name} ${data.last_name}`;
          data.Address = `${data.address1 || ""} ${data.address2 || ""} ${
            data.city || ""
          } ${data.state || ""} ${data.zip || ""}`;

          // Only attempt geocoding if we have the minimum required address fields
          let lat = null,
            lng = null;
          if (data.address1 && data.city && data.state && data.zip) {
            const address = `${data.address1}, ${data.city}, ${data.state}, ${data.zip}`;
            try {
              const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                  address
                )}&key=${config.google_maps}`
              );
              const geoData = await response.json();

              if (geoData.results && geoData.results.length > 0) {
                lat = geoData.results[0].geometry.location.lat;
                lng = geoData.results[0].geometry.location.lng;
              } else {
                console.warn(
                  "No geocoding results found for address:",
                  address
                );
              }
            } catch (err) {
              console.error("Error fetching geolocation:", err);
            }
          }

          // Remove undefined fields and ensure lat/lng are null if not found
          const cleanedData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== undefined)
          );

          if (args.action === "add") {
            console.log("Adding new contact:", cleanedData);
            await addDoc(collection(db, "community-contacts"), {
              ...cleanedData,
              contactID: userId,
              last_updated: serverTimestamp(),
              inserted_at: serverTimestamp(),
              user_id: userId,
              is_deleted: false,
              latitude: lat ? lat : null, // Will be null if geocoding failed
              longitude: lng ? lng : null, // Will be null if geocoding failed
            });
            await fetchContacts();
          } else if (args.action === "edit") {
            if (data.id) {
              // Sanitize data fields
              const sanitizedData = {
                ...data,
                first_name: data.first_name || "",
                last_name: data.last_name || "",
                address1: data.address1 || "",
                address2: data.address2 || "",
                city: data.city || "",
                state: data.state || "",
                zip: data.zip || "",
                email: data.email || "",
                mobileNumber: data.mobileNumber || "",
                occupation: data.occupation || "",
              };

              // Get lat/lng if address is complete
              let lat = null,
                lng = null;
              if (
                sanitizedData.address1 &&
                sanitizedData.city &&
                sanitizedData.state &&
                sanitizedData.zip
              ) {
                const address = `${sanitizedData.address1}, ${sanitizedData.city}, ${sanitizedData.state}, ${sanitizedData.zip}`;
                try {
                  const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                      address
                    )}&key=${config.google_maps}`
                  );
                  const geoData = await response.json();

                  if (geoData.results && geoData.results.length > 0) {
                    lat = geoData.results[0].geometry.location.lat;
                    lng = geoData.results[0].geometry.location.lng;
                  } else {
                    console.warn(
                      "No geocoding results found for address:",
                      address
                    );
                  }
                } catch (err) {
                  console.error("Error fetching geolocation:", err);
                }
              }

              const contactDoc = doc(db, "community-contacts", data.id);
              await updateDoc(contactDoc, {
                ...sanitizedData,
                contactID: userId, // Use logged in user UID as contactID
                last_updated: serverTimestamp(),
                is_deleted: false,
                latitude: lat,
                longitude: lng,
              });
            } else {
              console.error("Document ID is missing for update.");
            }
            await fetchContacts();
          }
        }

        if (args.requestType === "delete") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          const data = args.data[0];
          if (data.id) {
            const contactDoc = doc(db, "community-contacts", data.id);
            await updateDoc(contactDoc, {
              is_deleted: true,
              last_updated: serverTimestamp(),
            });
          } else {
            console.error("Document ID is missing for delete.");
          }
          await fetchContacts();
        }
      } catch (error) {
        console.error("Error during actionBegin:", error);
      }
    }
  };

  return (
    <>
      <div
        className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
      >
        <div id="dialog-container"></div>
        <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
          <a className="relative inline-block min-w-[40px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Database
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Community Contacts
          </a>
        </div>
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div className="relative mb-2 inline-block max-w-full font-[Inter] font-medium leading-[21px] text-darkslategray-200">
            <p>
              The Community Contacts section is designed for you to store and
              share the contact information of key community members essential
              to disaster response and recovery. Having quick access to these
              contacts helps ensure effective communication and coordination
              during emergencies. Please provide accurate and up-to-date
              information to strengthen your community's readiness.
            </p>
          </div>
        </div>
        <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 text-sm font-bold text-blue-500">
                COMMUNITY CONTACTS
              </h2>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-2 px-3 py-1.5"
                onClick={handleAdd}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>
            </div>
          </header>
          <div>
            <GridComponent
              dataSource={commContacts.filter(
                (contact) => contact.is_deleted !== true
              )}
              actionBegin={actionBegin}
              editSettings={editOptions}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowSorting={true}
              ref={gridRef}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="contactID"
                  headerText="Contact ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="first_name"
                  headerText="First Name"
                  visible={false}
                />
                <ColumnDirective
                  field="last_name"
                  headerText="Last Name"
                  visible={false}
                />
                <ColumnDirective field="Name" headerText="Name" width="150" />
                <ColumnDirective
                  field="occupation"
                  headerText="Title"
                  width="150"
                />
                <ColumnDirective
                  field="mobileNumber"
                  headerText="Mobile Number"
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="150"
                />
                <ColumnDirective
                  field="address1"
                  headerText="Address 1"
                  visible={false}
                />
                <ColumnDirective
                  field="address2"
                  headerText="Address 2"
                  visible={false}
                />
                <ColumnDirective
                  field="city"
                  headerText="City"
                  visible={false}
                />
                <ColumnDirective
                  field="state"
                  headerText="State"
                  visible={false}
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: USStates,
                      fields: { text: "abbreviation", value: "abbreviation" },
                      query: new Query(),
                    },
                  }}
                />
                <ColumnDirective field="zip" headerText="Zip" visible={false} />
                <ColumnDirective
                  headerText="Commands"
                  width="120"
                  textAlign="Center"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Edit,
                  Filter,
                  Sort,
                  Page,
                  CommandColumn,
                  ColumnMenu,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contacts;
