const loadEnv = (key) => {
  const value = process.env[`REACT_APP_${key}`];
  if (!value) {
    console.error(`Environment variable REACT_APP_${key} is not set.`);
  }
  return value;
};

const BLUESKY_EMAIL_AUTH = "vxavier326@gmail.com";
const BLUESKY_PASSWORD = "Longpassword1";

export const config = {
  google_maps: loadEnv("GOOGLE_MAPS_API_KEY"),
  bluesky_email_auth: BLUESKY_EMAIL_AUTH,
  bluesky_password: BLUESKY_PASSWORD,
};
