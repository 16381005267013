import React, { useState, useEffect } from "react";
import LockBoxService from "../service/citizen/LockboxService";

const FileDialog = ({
  isOpen,
  onClose,
  files,
  currentIndex,
  setCurrentIndex,
  dataset,
  userId,
  userData,
  pov,
}) => {
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  useEffect(() => {
    if (dataset && pov) {
      setAcknowledged(dataset[`${pov}_acknowledged`] || false);
    }
  }, [dataset, pov]);

  if (!isOpen || !Array.isArray(files) || files.length === 0) return null;

  const file = files[currentIndex];
  const isImage = file?.type?.startsWith("image/");
  const fileName = file?.name || "Untitled File";
  const isViewerOnly = pov !== "partner" && pov !== "center";

  const requiresSignature =
    !isViewerOnly && dataset?.[`${pov}_signature_required`] === true;
  const showAcknowledgment =
    !isViewerOnly && dataset?.copied_at && !requiresSignature;
  const name = `${userData?.first_name || ""} ${userData?.last_name || ""}`;

  let signature = dataset?.[`${pov}_signature`] || "";

  const handleSignatureChange = (event) => {
    const sanitizedValue = event.target.value.replace(/[^a-zA-Z\s]/g, "");
    signature = sanitizedValue;
  };

  const handleSaveSignature = async () => {
    if (isViewerOnly) return;

    try {
      await LockBoxService.updateDocumentInLockbox(
        userId,
        dataset.documentsID,
        {
          [`${pov}_signature`]: signature,
          [`${pov}_signed_at`]: new Date().toISOString(),
          [`${pov}_signed_by`]: name,
        },
        "center_documents"
      );
      setShowSuccessDialog(true);
    } catch (error) {
      console.error("Error saving signature:", error);
    }
  };

  const handleAcknowledgmentChange = async (event) => {
    if (isViewerOnly) return;

    const acknowledgedStatus = event.target.checked;
    setAcknowledged(acknowledgedStatus);

    try {
      await LockBoxService.updateDocumentInLockbox(
        userId,
        dataset.documentsID,
        {
          [`${pov}_acknowledged`]: acknowledgedStatus,
          [`${pov}_acknowledged_by`]: acknowledgedStatus ? name : "",
          [`${pov}_acknowledged_date`]: acknowledgedStatus
            ? new Date().toISOString()
            : "",
        },
        "center_documents"
      );
      setShowSuccessDialog(true);
    } catch (error) {
      console.error("Error saving acknowledgment:", error);
    }
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : files.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < files.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {/* Main File Dialog */}
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-75"
        onClick={handleBackgroundClick}
      >
        <div className="relative flex h-[80vh] w-full max-w-4xl flex-col">
          {/* Header */}
          <div className="absolute left-0 right-0 top-0 flex items-center justify-between rounded-t-lg bg-[#467ba4] p-4 text-[#fdb841]">
            <h2 className="text-xl truncate font-bold">{fileName}</h2>
            <button
              onClick={onClose}
              className="flex h-10 w-10 cursor-pointer items-center justify-center bg-[#467ba4] text-[32px] text-[#fdb841]"
            >
              &times;
            </button>
          </div>

          {/* Viewer */}
          <div className="relative mt-16 flex-grow bg-black">
            {isImage ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  src={file.src}
                  alt={fileName}
                  className="max-h-full max-w-full object-contain"
                />
              </div>
            ) : (
              <iframe
                src={file.src}
                title={fileName}
                className="absolute inset-0 h-full w-full"
              />
            )}
            {files.length > 1 && (
              <>
                <button
                  onClick={handlePrev}
                  className="absolute left-4 top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
                >
                  &lt;
                </button>
                <button
                  onClick={handleNext}
                  className="absolute right-4 top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
                >
                  &gt;
                </button>
              </>
            )}
          </div>

          {/* Footer */}
          <div className="flex w-full items-center justify-between rounded-b-lg bg-[#467ba4] p-4 text-[#fdb841]">
            {!isViewerOnly && requiresSignature && (
              <div className="bg-[#467ba4]text-[#fdb841] flex items-center justify-between gap-4">
                <div className="text-gray-700 w-40 text-sm font-medium">
                  <p>Sign here:</p>
                </div>
                <input
                  type="text"
                  className="mt-1 w-full rounded-md border border-gray-300 p-2"
                  defaultValue={signature}
                  onChange={handleSignatureChange}
                  placeholder="Sign Here"
                  maxLength={50}
                />
                <button
                  className="mt-2 rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-600"
                  onClick={handleSaveSignature}
                >
                  Save
                </button>
              </div>
            )}
            {!isViewerOnly && showAcknowledgment && (
              <div className="bg-[#467ba4]text-[#fdb841] flex items-center justify-between gap-4 ">
                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    checked={acknowledged}
                    onChange={handleAcknowledgmentChange}
                    className="h-5 w-5"
                  />
                  <label className="text-sm text-[#fdb841]">
                    I here by acknowledged that I have received and reviewed the
                    content of this Document
                  </label>
                </div>
              </div>
            )}
            <p className="justify-end text-sm">
              {isImage ? "Image" : "Document"} {currentIndex + 1} of{" "}
              {files.length}
            </p>
          </div>

          {/* Show Signature & Acknowledgment only when pov is "partner" or "center" */}
        </div>

        {showSuccessDialog && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="rounded-lg bg-white p-6 text-center shadow-lg">
              <h2 className="text-lg font-semibold text-green-600">
                {requiresSignature
                  ? "Signature Saved Successfully"
                  : "Acknowledgment Saved Successfully"}
              </h2>
              <button
                className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                onClick={() => setShowSuccessDialog(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FileDialog;
