import React, { useEffect, useState } from "react"; // Added useEffect and useState
import PropTypes from "prop-types";
import DrcCenterService from "../service/citizen/DrcCenterService"; // Adjusted path
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../firebase";
import HeaderTitle from "../components/HeaderTitle";

const DrcCenter = ({ className = "" }) => {
  const [data, setData] = useState(null); // State to hold data
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to manage errors
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      if (selectedUserId) {
        try {
          const userId = selectedUserId;
          const userDocRef = doc(db, "users", userId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            console.log("user data", userData);
            const userDoc = await DrcCenterService.getCitizenProfileByCenterId(
              userData.center_id
            ); // Fetch user data

            if (userDoc) {
              console.log("User doc: ", userDoc);

              setData(userDoc);
            }
          } else {
            console.error("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        setError("User is not logged in.");
      }
      setLoading(false);
    };
    fetchData();
  }, []); // Fetch data on component mount

  const renderField = (label, value) => {
    return (
      <div className="flex flex-col items-start justify-start gap-1">
        <div className="text-gray-600 relative text-left  text-xs leading-[20px]">
          {label}
        </div>
        <div
          className={`box-border flex h-10 flex-row items-center justify-between self-stretch rounded-md border-[1px] border-solid border-gray-300 bg-gray-200 px-3 py-2`}
        >
          <div
            className={`flex-1  text-xs ${
              value ? "font-semibold text-gray-800" : "text-gray-500"
            } text-left`}
          >
            {value || ""}
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-lg text-gray-700 ">Loading...</div>
      </div>
    ); // Show loading state
  }

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <HeaderTitle id="centerinfo" title="Center Information" />
      <div className="relative text-sm font-medium leading-[21px] px-2">
        If you find yourself in a location without a certified Community,
        I encourage you to contact your local emergency management
        authorities to learn more about the DRC recovery awareness program
        and take steps towards becoming a certified community.
      </div>
      <section className="w-full rounded-3xs pb-2 text-left text-xs text-darkslateblue px-2">
        <div className="mr-3 flex flex-col gap-4 self-stretch">
          {renderField("Name of Center", data?.name_of_hub || "")}
          {renderField(
            "Contact Person",
            `${data?.first_name || ""} ${data?.last_name || ""}`
          )}
          {renderField(
            "Address",
            `${data?.address1 || ""} ${data?.address2 || ""} ${
              data?.city || ""
            } ${data?.state || ""} ${data?.zip || ""}`.trim() || ""
          )}
          <div className="flex flex-row gap-4 self-stretch">
            <div className="flex-1">
              {renderField("Email", data?.email || "")}
            </div>
            <div className="flex-1">
              {renderField("Website", data?.website || "")}
            </div>
          </div>
          <div className="flex flex-row gap-4 self-stretch">
            <div className="flex-1">
              {renderField("Social Media", data?.soc_med || "")}
            </div>
            <div className="flex-1">
              {renderField(
                "Other Information Provided",
                data?.other_info || ""
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

DrcCenter.propTypes = {
  className: PropTypes.string,
};

export default DrcCenter;
