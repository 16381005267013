import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const dataService = {
  getUserProfile: async (collectionName, docId) => {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error reading document: ", error);
      throw error;
    }
  },

  getUsersByCenterIdAndRole: async (centerIds) => {
    try {
      const usersRef = collection(db, "users");
      const centersRef = collection(db, "centers");

      // Build multiple queries for each centerId in the array
      const queries = centerIds.map((centerId) => {
        return query(
          usersRef,
          where("center_id", "==", centerId),
          where("user_role", "==", "center")
        );
      });

      const users = [];

      // Run each query and accumulate the results
      for (let q of queries) {
        const querySnapshot = await getDocs(q);
        // Use Promise.all to handle async operations correctly
        await Promise.all(
          querySnapshot.docs.map(async (userDoc) => {
            const centerId = userDoc.data().center_id;
            const centerDocRef = doc(centersRef, centerId);
            const centerDocSnap = await getDoc(centerDocRef);
            if (centerDocSnap.exists()) {
              users.push({
                id: centerDocSnap.id,
                ...centerDocSnap.data(),
              });
            }
          })
        );
      }
      console.log("Users:", users);
      return users; // This will now always be an array
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },

  getCitizenByCenterIdAndRole: async (centerIds, role) => {
    try {
      const usersRef = collection(db, "users");

      // Build multiple queries for each centerId in the array
      const queries = centerIds.map((centerId) => {
        return query(
          usersRef,
          where("center_id", "==", centerId),
          where("user_role", "==", role)
        );
      });

      const users = [];

      // Run each query and accumulate the results
      for (let q of queries) {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          users.push({ id: doc.id, ...doc.data() });
        });
      }

      return users;
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },

  getAllCenters: async () => {
    try {
      // Query centers from the "users" collection
      const usersQuery = query(collection(db, "centers"));
      const usersSnapshot = await getDocs(usersQuery);
      const users = usersSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      return users;
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },

  getAllCitizens: async (collectionName) => {
    try {
      const q = query(
        collection(db, collectionName),
        where("user_role", "==", "citizen")
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },

  getPartnerForApproval: async (centerId) => {
    try {
      const q = query(
        collection(db, "partner-for-approval"),
        where("center_id", "==", centerId)
      );
      const querySnapshot = await getDocs(q);

      const partners = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (partner) =>
            partner.is_approved === false || partner.is_approved === undefined
        );

      return partners;
    } catch (error) {
      console.error("Error getting partners for approval:", error);
      throw error;
    }
  },

  getApprovedPartners: async (centerId) => {
    try {
      const q = query(
        collection(db, "users"),
        where("user_role", "==", "partner"),
        where("is_approved", "==", true),
        where("center_id", "array-contains", centerId)
      );
      const querySnapshot = await getDocs(q);
      const partnerIds = querySnapshot.docs.map((doc) => doc.data().partner_id);
      const partners = [];
      for (const partnerId of partnerIds) {
        const partnerDocRef = doc(db, "partners", partnerId);
        const partnerDoc = await getDoc(partnerDocRef);
        if (partnerDoc.exists()) {
          partners.push({ ...partnerDoc.data(), id: partnerDoc.id });
        }
      }
      return partners;
    } catch (error) {
      console.error("Error getting approved partners:", error);
      throw error;
    }
  },

  getPartnerForCertify: async (centerId) => {
    try {
      const q = query(
        collection(db, "partner-for-approval"),
        where("center_id", "==", centerId)
      );
      const querySnapshot = await getDocs(q);

      const partners = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (partner) =>
            partner.is_certified === false || partner.is_certified === undefined
        );

      return partners;
    } catch (error) {
      console.error("Error getting partners for approval:", error);
      throw error;
    }
  },

  getCertifiedPartners: async (centerId) => {
    try {
      const q = query(
        collection(db, "users"),
        where("user_role", "==", "partner"),
        where("is_certified", "==", true),
        where("center_id", "array-contains", centerId)
      );
      const querySnapshot = await getDocs(q);
      const partnerIds = querySnapshot.docs.map((doc) => doc.data().partner_id);
      const partners = [];
      for (const partnerId of partnerIds) {
        const partnerDocRef = doc(db, "partners", partnerId);
        const partnerDoc = await getDoc(partnerDocRef);
        if (partnerDoc.exists()) {
          partners.push({ ...partnerDoc.data(), id: partnerDoc.id });
        }
      }
      return partners;
    } catch (error) {
      console.error("Error getting approved partners:", error);
      throw error;
    }
  },

  generateCaseNumber: async () => {
    try {
      // Query for the document with type "CaseNo"
      const systemsCodeQuery = query(
        collection(db, "systems_code"),
        where("type", "==", "CaseNo")
      );
      const querySnapshot = await getDocs(systemsCodeQuery);

      if (!querySnapshot.empty) {
        const systemsCodeDoc = querySnapshot.docs[0];

        // Get the current value and increment it
        const oldCaseNo = parseInt(systemsCodeDoc.data().value);
        const newCaseNo = oldCaseNo + 1;

        // Update the systems_code document with new value
        await updateDoc(doc(db, "systems_code", systemsCodeDoc.id), {
          value: newCaseNo.toString(),
        });

        return newCaseNo;
      } else {
        console.error("Case number document not found");
        throw new Error("Case number document not found");
      }
    } catch (error) {
      console.error("Error generating case number:", error);
      throw error;
    }
  },
};
export default dataService;
