import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext";
import Profile from "./CaseManagementProfile";
import Password from "./CaseManagementPassword";
import Pin from "./Pin";
import ShowPin from "./CaseManagementShowPin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useGeneralContext } from "../../context/GeneralContext";
import { decrypt } from "../../service/encryption/Encryption";

const CaseManagementMenu = ({ isOpen, onClose, initialData }) => {
  const [userData, setUserData] = useState(initialData || {});
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [pinShow, setPinShow] = useState(false);
  const menuRef = useRef(null);
  const { logout, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [hasOrganizations, setHasOrganizations] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showOrgs, setShowOrgs] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  const [employeeRole, setEmployeeRole] = useState(null);
  const [userDataId, setUserDataId] = useState(null);
  const { login } = useAuth();
  const [role, setRole] = useState(null);
  const { employeeId, setEmployeeIdfunc } = useGeneralContext();
  const [employeeData, setEmployeeData] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");

  const checkIfEmployeeChangedPassword = async () => {
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      where(
        "darci_id",
        "==",
        JSON.parse(sessionStorage.getItem("employeeDetails")).darci_id
      )
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      console.log(userData);
      if (!userData.password_changed) {
        console.log("Password changed");
        setShowPassword(true);
        sessionStorage.setItem("needToUpdatePassword", "true");
      }
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", selectedUserId);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const userDataId = userDocSnap.id;
            setUserDataId(userDataId);
            setRole(userData.user_role);

            // Only check employee data for partner and center roles
            if (
              userData.user_role === "partner" ||
              userData.user_role === "center"
            ) {
              const collectionName =
                userData.user_role === "partner" ? "partners" : "centers";

              // Query employees subcollection directly across all organizations
              const employeesQuery = query(
                collection(
                  db,
                  `${collectionName}/${
                    userData.user_role === "partner"
                      ? userData.partner_id
                      : userData.center_id
                  }/employees`
                ),
                where("user_id", "==", employeeId)
              );

              const employeeSnapshot = await getDocs(employeesQuery);

              if (!employeeSnapshot.empty) {
                const employeeData = employeeSnapshot.docs[0].data();
                console.log(
                  `Found ${collectionName} employee data:`,
                  employeeData
                );
                setEmployeeData(employeeData);
                setRoles(employeeData.roles);
              } else {
                console.log("No employee data found");
              }
            }

            if (userData.user_role === "center") {
              const centerDocRef = doc(db, "centers", userData.center_id);
              console.log("Center");
              const centerDocSnap = await getDoc(centerDocRef);
              if (centerDocSnap.exists()) {
                setUserData(centerDocSnap.data());
              }
            } else if (userData.user_role === "partner") {
              const partnerDocRef = doc(db, "partners", userData.partner_id);
              const partnerDocSnap = await getDoc(partnerDocRef);
              if (partnerDocSnap.exists()) {
                setUserData(partnerDocSnap.data());
              }
            } else {
              setUserData(userData);
              setUserRole(userData.user_role);
            }
          }
          if (sessionStorage.getItem("employeeDetails")) {
            const employeeDetails = JSON.parse(
              sessionStorage.getItem("employeeDetails")
            );
            if (employeeDetails.user_role === "employee") {
              checkIfEmployeeChangedPassword();
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen || showProfile || showPassword || showPin || pinShow) {
      const fetchUserData = async () => {
        const auth = getAuth();
        const db = getFirestore();
        const user = auth.currentUser;

        if (user && selectedUserId) {
          try {
            const userDocRef = doc(db, "users", selectedUserId);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              if (userData.user_role === "center") {
                const centerDocRef = doc(db, "centers", userData.center_id);
                const centerDocSnap = await getDoc(centerDocRef);
                if (centerDocSnap.exists()) {
                  setUserData(centerDocSnap.data());
                }
              } else if (userData.user_role === "partner") {
                const partnerDocRef = doc(db, "partners", userData.partner_id);
                const partnerDocSnap = await getDoc(partnerDocRef);
                if (partnerDocSnap.exists()) {
                  setUserData(partnerDocSnap.data());
                }
              } else {
                setUserData(userData);
              }
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        }
      };

      fetchUserData();
    }
  }, [isOpen, showProfile, showPassword, showPin, pinShow]);

  if (!isOpen && !showProfile && !showPassword && !showPin && !pinShow)
    return null;

  const handleProfileUpdate = (updatedData) => {
    setUserData(updatedData);
  };

  const handleProfileClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowProfile(true), 100); // Open the Profile popup after a short delay
  };

  const handlePasswordClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowPassword(true), 100); // Open the Password popup after a short delay
  };

  const handlePinShow = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setPinShow(true), 100); // Open the Pin popup after a short delay
  };

  const handlePinClick = () => {
    onClose(); // Close the Menu popup
    setTimeout(() => setShowPin(true), 100); // Open the Pin popup after a short delay
  };

  const logoutClicked = () => {
    sessionStorage.clear();
    logout();
    navigate("/login");
    onClose(); // Close the menu after logging out
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-start justify-end overflow-auto bg-black bg-opacity-50 pt-14">
          <div
            ref={menuRef}
            className="mr-2 w-[400px] overflow-hidden rounded-b-lg bg-white shadow-xl"
          >
            <div>
              <h2 className="bg-[#F7CA41] px-4 pb-6 pt-6 text-base font-semibold text-[#242424]">
                My Profile
              </h2>
            </div>
            <div className="flex items-center justify-center border-b p-4">
              {userData.profile_image ? (
                <img
                  src={userData.profile_image}
                  alt="Profile"
                  className="mr-4 h-16 w-16 rounded-full"
                />
              ) : (
                <div className="cm-user-profile-thumbnail mr-4 flex h-16 w-16 items-center justify-center rounded-full">
                  {userData.first_name
                    ? userData.first_name.charAt(0).toUpperCase()
                    : "?"}
                </div>
              )}
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-lg pb-2 font-semibold text-black">
                  {userData.first_name
                    ? `${userData.first_name} ${userData.last_name || ""}`
                    : "User Name"}
                </h3>
                <p className="text-sm text-gray-800">
                  {userData.email || "user@example.com"}
                </p>
              </div>
            </div>
            <nav className="p-4">
              <ul className="space-y-2">
                <li>
                  <button
                    onClick={handleProfileClick}
                    className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                  >
                    Profile
                  </button>
                </li>
                <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                <li>
                  <button
                    onClick={handlePasswordClick}
                    className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                  >
                    Change Password
                  </button>
                </li>

                <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />

                {(role === "citizen" ||
                  role === "partner" ||
                  role === "center") &&
                  hasOrganizations && (
                    <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                  )}

                {(role === "citizen" ||
                  role === "partner" ||
                  role === "center") && (
                  <li>
                    <button
                      onClick={handlePinShow}
                      className="block w-full cursor-pointer rounded bg-white px-4 py-2 text-left text-[20px] font-semibold text-gray-800 hover:bg-gray-200"
                    >
                      Show Lockbox PIN
                    </button>
                  </li>
                )}
                {(role === "citizen" ||
                  role === "partner" ||
                  role === "center") && (
                  <hr className="mx-4 my-2 border-t-[1px] border-gray-200" />
                )}
              </ul>
              <div className="flex items-center justify-center pt-4">
                <button
                  onClick={logoutClicked}
                  className="cm-btn-default block cursor-pointer px-4 py-2 hover:bg-blue-700"
                >
                  Sign Out
                </button>
              </div>
            </nav>
          </div>
        </div>
      )}
      {showProfile && userData && (
        <Profile
          isOpen={showProfile}
          onClose={() => setShowProfile(false)}
          data={userData}
          onUpdate={handleProfileUpdate}
        />
      )}
      {showPassword && (
        <Password
          isOpen={showPassword}
          onClose={() => setShowPassword(false)}
        />
      )}
      {(role === "citizen" || role === "partner" || role === "center") &&
        showPin && <Pin isOpen={showPin} onClose={() => setShowPin(false)} />}
      {(role === "citizen" || role === "partner" || role === "center") &&
        pinShow && (
          <ShowPin
            isOpen={pinShow}
            onClose={() => setPinShow(false)}
            firstTime={false}
          />
        )}
    </>
  );
};

export default CaseManagementMenu;
