import {
    CheckBoxSelection,
    MultiSelectComponent,
  } from "@syncfusion/ej2-react-dropdowns";
  import {
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    CommandColumn,
    Edit,
    Filter,
    GridComponent,
    Inject,
    Page,
    Sort,
    Toolbar,
  } from "@syncfusion/ej2-react-grids";
  import { Uploader } from "@syncfusion/ej2-react-inputs";
  import {
    and,
    collection,
    doc,
    getDoc,
    getDocs,
    or,
    query,
    serverTimestamp,
    updateDoc,
    where,
  
  } from "firebase/firestore";
  import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import { FaTrash } from "react-icons/fa";
  import { db, auth } from "../../../firebase";
  import LockBoxService from "../../../service/citizen/LockboxService";
  
  import { Query } from "@syncfusion/ej2-data";
  import { DialogComponent } from "@syncfusion/ej2-react-popups";
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import {
    deleteObject,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
    uploadString,
  } from "firebase/storage";
  import { Controller, useForm } from "react-hook-form";
  import Calendar from "../../../assets/disaster-icons/calendar.svg";
  import NavigationArrow from "../../../assets/disaster-icons/navigation_arrow.svg";
  import panZoomIcon from "../../../assets/disaster-icons/pan_zoom.svg";
  import AddNewButton from "../../../components/AddNewButton";
  import CustomInput from "../../../components/CustomInput";
  import CustomSelect from "../../../components/CustomSelect";
  import CustomTextArea from "../../../components/CustomTextArea";
  import DeleteModal from "../../../components/DeleteModal";
  import Modal from "../../../components/Modal";
  import ModalFileViewer from "../../../components/ModalFileViewer";
  import PdfViewer from "../../../components/PDFViewer";
  import PdfViewerMultiplePage from "../../../components/PDFViewerMultiplePage";
  import PhotoColumn from "../../../components/PhotoColumn";
  import ThreeDotMenu from "../../../components/ThreeDotMenu";

  const documentTypes = [
    { label: "Driver's License", value: "Driver's License" },
    {
      label: "Credit Cards/Banking Information",
      value: "Credit Cards/Banking Information",
    },
    { label: "Insurance Information", value: "Insurance Information" },
    { label: "Passport", value: "Passport" },
    { label: "Titles/Deed/Registration", value: "Titles/Deed/Registration" },
  ];

  const CaseManagementDisasterLockbox = ({ className = "" }) => {
    const [activeMenuDocuments, setActiveMenuDocuments] = useState(null);
    const [showViewDocumentsModal, setShowViewDocumentsModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);
    const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
    const [documentsToDeleteId, setDocumentsToDeleteId] = useState(null);
    const [documentsData, setDocumentsData] = useState([]);
    const documentGridRef = useRef(null);

    const editing = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Dialog'
    };
  
    const pageSettings = { pageSize: 10 };
    const columnMenuItems = ['SortAscending', 'SortDescending', 'Filter'];
  

    const [isEditingDocuments, setIsEditingDocuments] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const documentsFormRef = useRef(null);
    const [isSubmittingDocuments, setIsSubmittingDocuments] = useState(false);
    const {
        register: documentsRegister,
        handleSubmit: documentsHandleSubmit,
        formState: { errors: documentsErrors },
        reset: documentsReset,
        control: documentsControl,
        setValue: documentsSetValue,
        watch: documentsWatch,
      } = useForm({
        defaultValues: {},
      });

      const onSubmitDocuments = async (values) => {
        
      };

    return (
    <section className="p-2">
      <GridComponent
        key={documentsData.length}
        ref={documentGridRef}
        dataSource={documentsData}
        editSettings={editing}
        allowPaging={true}
        pageSettings={pageSettings}
        columnMenuItems={columnMenuItems}
        cssClass="new-grid-color allow-overflow-grid allow-overflow-grid-text"
      >
        <ColumnsDirective>
          <ColumnDirective
            field="documentsID"
            headerText="documentsID"
            isPrimaryKey={true}
            visible={false}
          />
          <ColumnDirective
            field="type"
            headerText="Type"
            width="100"
            editType="dropdownedit"
           
          />
          <ColumnDirective
            field="description"
            headerText="Description"
            width="100"
          />
         
          {/* <ColumnDirective
            field="date_inserted"
            headerText="Date Submitted"
            width="100"
            format="MM/dd/yyyy"
            editType="datepickeredit"
            
            allowEditing={false}
          /> */}
          {/* <ColumnDirective
            field="photodocuments"
            headerText="Documents"
            width="100"
            template={(props) => (
              <PhotoColumn
                data={props.photodocuments}
                type="document"
                onClick={() => {
                  const { column, index, foreignKeyData, ...rest } = props;
                  setShowViewDocumentsModal(true);
                  setSelectedDocument(rest);
                  setSelectedDocumentId(props.id);
                }}
              />
            )}
          /> */}
          <ColumnDirective
            headerText="Actions"
            textAlign="Center"
            width="120"
            template={(props) => (
              <div className="flex w-full justify-center overflow-visible px-4">
                <ThreeDotMenu
                  menuId={props?.documentsID}
                  activeMenuId={activeMenuDocuments}
                  setActiveMenuId={setActiveMenuDocuments}
                  clickOutside={false}
                  onEditClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const { column, index, foreignKeyData, ...rest } = props;
                    setIsEditingDocuments(true);
                    setShowDocumentsModal(true);
                    setActiveMenuDocuments(null);
                    documentsReset({
                      title: props?.title || "",
                      description: props?.description || "",
                      access: props?.access || "",
                      modified_date: props?.modified_date || "",
                      relationship: props?.relationship || "",
                      photo: props?.photoDocuments || "",
                      documentsID: props?.documentsID || "",
                      ...rest,
                    });
                  }}
                  onDeleteClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDeleteDocumentModal(true);
                    setActiveMenuDocuments(null);
                    setDocumentsToDeleteId(props?.id);
                  }}
                />
              </div>
            )}
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Edit,
            Toolbar,
            Sort,
            Filter,
            ColumnMenu,
            CommandColumn,
          ]}
        />
      </GridComponent>
      <AddNewButton
      onClick={() => {
        setIsEditingDocuments(false);
        setShowDocumentsModal(true);
        documentsReset({
          type: "",
          description: "",
          access: "",
          date_inserted: "",
          modified_date: "",
          photodocuments: "",
          is_deleted: false,
          documentsID: "",
          fileUrl: "",
          documents: null,
        });
      }}
      name="Add New Documents"
    />
    <Modal
            isOpen={showDocumentsModal}
            title={isEditingDocuments ? "Edit Documents" : "Add New Documents"}
            onClose={() => {
              setShowDocumentsModal(false);
              setActiveMenuDocuments(false);
              documentsReset();
              // setSelectedHouseholdMember(null);
            }}
            formRef={documentsFormRef}
            isLoading={isSubmittingDocuments}
            isEdit={isEditingDocuments}
          >
            <form
              onSubmit={documentsHandleSubmit(onSubmitDocuments)}
              className="flex flex-col gap-4"
              ref={documentsFormRef}
            >
              <div className="w-full">
                <CustomSelect
                  name="type"
                  placeholder="Type"
                  register={{
                    ...documentsRegister("type", {
                      required: "Type is required",
                    }),
                  }}
                  options={documentTypes}
                  errors={documentsErrors}
                />
              </div>
              <div className="w-full">
                <CustomTextArea
                  type="text"
                  name="description"
                  placeholder="Description"
                  register={{
                    ...documentsRegister("description", {
                      required: "Description is required",
                    }),
                  }}
                  errors={documentsErrors}
                />
              </div>
              
              <div className="flex flex-row gap-6">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="signatureRequired"
                    {...documentsRegister("signatureRequired")}
                    className="h-4 w-4"
                  />
                  <label htmlFor="signatureRequired" className="cursor-pointer">
                    Signature Required
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="documentsRequired"
                    {...documentsRegister("documentsRequired")}
                    className="h-4 w-4"
                  />
                  <label htmlFor="documentsRequired" className="cursor-pointer">
                    Documents Required
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="verificationRequired"
                    {...documentsRegister("verificationRequired")}
                    className="h-4 w-4"
                  />
                  <label htmlFor="verificationRequired" className="cursor-pointer">
                    Verification Required
                  </label>
                </div>
              </div>
            </form>
          </Modal>
    </section>
    );
  };
  
  export default CaseManagementDisasterLockbox;