import { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  where,
  getDocs,
  addDoc,
  getDoc,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../../firebase";

export const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [emergencyNotification, setEmergencyNotification] = useState(null);
  const [showEmergencyPopup, setShowEmergencyPopup] = useState(false);

  useEffect(() => {
    let notificationUnsubscribe;

    const setupRealtimeListeners = async () => {
      if (!auth.currentUser) return;

      try {
        const userDoc = await getDoc(
          doc(db, "users", sessionStorage.getItem("userId"))
        );
        const userData = userDoc.data();

        const messagesQuery = query(
          collection(db, "message-center"),
          where("active", "==", true),
          where("global_notification", "==", true)
        );

        notificationUnsubscribe = onSnapshot(messagesQuery, (snapshot) => {
          const notificationData = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((message) => {
              if (message.type === "county_message") {
                return message.recipients.counties.includes(userData.county);
              } else if (message.type === "disaster_message") {
                return userData.county === message.disaster_county;
              } else if (message.type === "partner_message") {
                return message.targetedPartners.includes(
                  sessionStorage.getItem("userId")
                );
              }
              return false;
            });

          setNotifications(notificationData);

          const unreadCount = notificationData.filter(
            (doc) => !doc.read
          ).length;
          setUnreadNotifications(unreadCount);

          const activeEmergencies = notificationData.filter(
            (notification) =>
              notification.urgency === "emergency" && notification.active
          );
          setEmergencyNotification(activeEmergencies);

          // if (activeEmergency && !activeEmergency.read) {
          //     setShowEmergencyPopup(true);
          // }
        });
      } catch (error) {
        console.error("Error in notifications setup:", error);
      }
    };

    setupRealtimeListeners();

    const authUnsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setupRealtimeListeners();
      } else {
        setNotifications([]);
        setUnreadNotifications(0);
        setEmergencyNotification(null);
        setShowEmergencyPopup(false);
      }
    });

    return () => {
      if (notificationUnsubscribe) notificationUnsubscribe();
      if (authUnsubscribe) authUnsubscribe();
    };
  }, []);

  const markNotificationAsRead = async (notificationId) => {
    if (!auth.currentUser) return;

    try {
      const notificationRef = doc(db, "message-center", notificationId);

      await updateDoc(notificationRef, {
        read: true,
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const markEmergencyAsRead = async () => {
    if (!emergencyNotification || !auth.currentUser) return;

    try {
      await markNotificationAsRead(emergencyNotification.id);
      setShowEmergencyPopup(false);
    } catch (error) {
      console.error("Error marking emergency as read:", error);
    }
  };

  return {
    notifications,
    unreadNotifications,
    emergencyNotification,
    // showEmergencyPopup,
    markEmergencyAsRead,
    markNotificationAsRead,
  };
};
