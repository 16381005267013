import React, { useEffect, useRef, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import partnerProfileService from "../service/partner/PartnerProfileService";
import userService from "../service/UserService";
import { Query } from "@syncfusion/ej2-data";
import { serverTimestamp } from "firebase/firestore";
import CenterService from "../service/center/CenterService";
import { useAuth } from "../components/AuthContext";
import AdminDataService from "../service/admin/adminService";
import { encrypt, decrypt } from "../service/encryption/Encryption";
import mailer from "../service/api/mailer";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const Employees = ({ className = "", ...props }) => {
  const gridRef = useRef(null);
  const gridRefSearch = useRef(null);
  const [orgId, setOrgId] = useState(null);
  const [orgType, setOrgType] = useState(null);
  const [organization, setOrganization] = useState({});
  const [employeesData, setEmployeesData] = useState([]);
  const [searchedEmployeesData, setSearchedEmployeesData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const { role, setRoleBasedRoute } = useAuth();
  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];
  const pageSettings = { pageSize: 10 };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedUserId = sessionStorage.getItem("userId");
  const [orgName, setOrgName] = useState("");
  const [organizationData, setOrganizationData] = useState({});

  useEffect(() => {
    if (props.data?.orgId && props.data?.orgType && props.data?.org) {
      setOrgId(props.data.orgId);
      setOrgType(props.data.orgType);
      setOrganization(props.data.org);
      setOrganizationData(props.data.org);

      if (props.data.orgType === "center") {
        setOrgName(props.data.org.name_of_hub);
      } else if (props.data.orgType === "partner") {
        getOrgName(
          doc(db, "partners", props.data.org.partner_id),
          props.data.org.partner_id,
          "partner"
        );
      } else if (props.data.orgType === "admin") {
        getOrgName(
          doc(db, "users", props.data.orgId),
          props.data.orgId,
          "admin"
        );
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (orgId && orgType) {
      switch (orgType) {
        case "partner":
          getPartnerEmployees();
          break;
        case "center":
          getCenterEmployees();
          break;
        case "admin":
          getAdminEmployees();
          break;
        default:
          setEmployeesData([]);
      }
    }
  }, [orgId, orgType]);

  const getOrgName = async (docRef, id, orgType) => {
    try {
      const orgSnap = await getDoc(docRef);
      if (orgSnap.exists()) {
        const orgData = orgSnap.data();
        switch (orgType) {
          case "center":
            setOrgName(orgData.name_of_hub);
            break;
          case "partner":
            setOrgName(orgData.name_of_org);
            break;
          case "admin":
            setOrgName("BlueSky");
            break;
          default:
            console.warn("Unknown organization type:", orgType);
        }
      } else {
        console.warn(`No ${orgType} document found for id:`, id);
      }
    } catch (error) {
      console.error(`Error fetching ${orgType} name:`, error);
    }
  };

  const getPartnerEmployees = async () => {
    try {
      let emps = await partnerProfileService.getEmployees(
        organizationData.partner_id
      );
      emps = emps.filter((emp) => !emp.is_deleted);
      setEmployeesData(emps);
      console.log("emps", emps);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const getCenterEmployees = async () => {
    try {
      let emps = await CenterService.getEmployees(orgId);
      emps = emps.filter((emp) => !emp.is_deleted);
      setEmployeesData(emps);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const getAdminEmployees = async () => {
    try {
      let emps = await AdminDataService.getEmployees(orgId);
      emps = emps.filter(
        (emp) => emp.is_deleted === false || !("is_deleted" in emp)
      );
      setEmployeesData(emps);
    } catch (error) {
      console.error("Error fetching employees", error);
    }
  };

  const createEmployee = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // Get a new document reference from Firebase
        const docIdPartner = userService.generateNewDocId();
        // Create user document in users collection with employeeData
        const userDataPartner = { ...employeeData };
        await userService.createEmployeeInUsers(docIdPartner, userDataPartner);

        // Create employee in partner collection
        const partnerData = { ...employeeData };
        const {
          password: partnerPassword,
          password_changed: partnerPasswordChanged,
          organization_id: partnerOrganizationId,
          user_role: partnerUserRole,
          organization_type: partnerOrgType,
          organization_user_id: partnerSelectedUserId,
          ...cleanedDataForPartner
        } = partnerData;
        await partnerProfileService.createEmployeeInPartner(
          organizationData.partner_id,
          docIdPartner,
          cleanedDataForPartner
        );
        await AdminDataService.incrementDarciNo();
        await userService.addToUsersList(docIdPartner, employeeData.email);
        break;
      case "center":
        const docIdCenter = userService.generateNewDocId();
        // Create user document in users collection with employeeData
        const userDataCenter = { ...employeeData };
        await userService.createEmployeeInUsers(docIdCenter, userDataCenter);

        // Create employee in center collection
        const centerData = { ...employeeData };
        const {
          password: centerPassword,
          password_changed: centerPasswordChanged,
          organization_id: centerOrganizationId,
          user_role: centerUserRole,
          organization_type: centerOrgType,
          organization_user_id: centerSelectedUserId,
          ...cleanedDataForCenter
        } = centerData;
        await CenterService.createEmployeeInCenter(
          orgId,
          docIdCenter,
          cleanedDataForCenter
        );

        await AdminDataService.incrementDarciNo();
        await userService.addToUsersList(docIdCenter, employeeData.email);
        // TODO: create a link account if user registers.
        break;
      case "admin":
        const docIdAdmin = userService.generateNewDocId();
        // Create user document in users collection with employeeData
        const userDataAdmin = { ...employeeData };
        await userService.createEmployeeInUsers(docIdAdmin, userDataAdmin);

        // Create employee in admin collection
        const adminData = { ...employeeData };
        const {
          password,
          password_changed,
          organization_id,
          user_role,
          organization_type,
          organization_user_id,
          ...cleanedDataForAdmin
        } = adminData;
        await AdminDataService.createEmployeeInAdmin(
          orgId,
          docIdAdmin,
          cleanedDataForAdmin
        );

        await AdminDataService.incrementDarciNo();
        await userService.addToUsersList(docIdAdmin, employeeData.email);
        break;
      default:
      // do nothing
    }
  };

  const editAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // Update user document in users collection
        const userDataPartner = { ...employeeData };
        await userService.updateEmployeeInUsers(
          employeeData.docId,
          userDataPartner
        );

        // Update employee in partner collection
        const partnerData = { ...employeeData };
        const {
          password: partnerPassword,
          password_changed: partnerPasswordChanged,
          organization_id: partnerOrganizationId,
          ...cleanedDataForPartner
        } = partnerData;
        await partnerProfileService.updateEmployeeInPartner(
          organizationData.partner_id,
          employeeData.docId,
          cleanedDataForPartner
        );
        break;
      case "center":
        // Update user document in users collection
        const userDataCenter = { ...employeeData };
        await userService.updateEmployeeInUsers(
          employeeData.docId,
          userDataCenter
        );

        // Update employee in center collection
        const centerData = { ...employeeData };
        const {
          password: centerPassword,
          password_changed: centerPasswordChanged,
          organization_id: centerOrganizationId,
          ...cleanedDataForCenter
        } = centerData;
        await CenterService.updateEmployeeInCenter(
          orgId,
          employeeData.docId,
          cleanedDataForCenter
        );
        break;
      case "admin":
        // Update user document in users collection
        const userData = { ...employeeData };
        await userService.updateEmployeeInUsers(employeeData.docId, userData);

        // Update employee in admin collection
        const adminData = { ...employeeData };
        const {
          password,
          password_changed,
          organization_id,
          ...cleanedDataForAdmin
        } = adminData;
        await AdminDataService.updateEmployeeInAdmin(
          orgId,
          employeeData.docId,
          cleanedDataForAdmin
        );
        break;
      default:
      // do nothing
    }
  };

  const deleteAction = async (employeeData) => {
    switch (orgType) {
      case "partner":
        // Soft delete in partners collection
        await partnerProfileService.updateEmployeeInPartner(
          organizationData.partner_id,
          employeeData.docId,
          {
            is_deleted: true,
          }
        );
        // Soft delete in users collection
        await userService.updateEmployeeInUsers(employeeData.docId, {
          is_deleted: true,
        });
        break;
      case "center":
        // Soft delete in centers collection
        await CenterService.updateEmployeeInCenter(orgId, employeeData.docId, {
          is_deleted: true,
        });
        // Soft delete in users collection
        await userService.updateEmployeeInUsers(employeeData.docId, {
          is_deleted: true,
        });
        break;
      case "admin":
        // Soft delete in users collection
        await userService.updateEmployeeInUsers(employeeData.docId, {
          is_deleted: true,
        });
        // Soft delete in admin collection
        await AdminDataService.updateEmployeeInAdmin(
          orgId,
          employeeData.docId,
          {
            is_deleted: true,
          }
        );
        break;
      default:
      // do nothing
    }
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        const exists = await userService.checkIfEmailExists(args.data.email);
        if (exists) {
          args.cancel = true;
          // Get the dialog instance
          const dialog = gridRef.current.dialogModule;
          if (dialog) {
            // Remove any existing error messages first
            const existingError = dialog.dialogObj.element.querySelector(
              ".email-error-message"
            );
            if (existingError) {
              existingError.remove();
            }

            // Find the email input element and add error class
            const emailInput = dialog.dialogObj.element.querySelector(
              'input[name="email"]'
            );
            if (emailInput) {
              emailInput.classList.add("e-invalid"); // Changed from e-error to e-invalid
              emailInput.style.borderColor = "red"; // Add explicit border color
              // Add error message below the input
              const errorDiv = document.createElement("div");
              errorDiv.className = "email-error-message";
              errorDiv.style.color = "red";
              errorDiv.style.fontSize = "12px";
              errorDiv.style.marginTop = "4px";
              errorDiv.textContent = "Email already exists";
              emailInput.parentNode.appendChild(errorDiv);
            }

            // Keep the dialog open
            dialog.dialogObj.overlayClick = () => false; // Changed to return false
            dialog.dialogObj.closeOnEscape = false; // Prevent Escape key from closing
            const closeBtn = dialog.dialogObj.element.querySelector(
              ".e-dlg-closeicon-btn"
            );
            if (closeBtn) {
              closeBtn.style.display = "none";
            }

            // Prevent form submission
            const saveButton =
              dialog.dialogObj.element.querySelector(".e-primary");
            if (saveButton) {
              saveButton.onclick = (e) => {
                e.preventDefault();
                return false;
              };
            }
          }
          return false; // Added explicit return false
        } else {
          const darciId = (await AdminDataService.getDarciNo()) + 1;

          // Sanitize employee data before creating
          const sanitizedData = {
            email: args.data.email?.trim().toLowerCase(),
            first_name: args.data.first_name?.trim(),
            last_name: args.data.last_name?.trim(),
            mobile_number: args.data.mobile_number?.trim(),
            role: args.data.role,
            darci_id: darciId.toString(),
            username: darciId.toString(),
            password: encrypt(Math.random().toString(36).substring(2, 10)),
            password_changed: false,
            organization_id: orgId,
            organization_type: orgType,
            organization_user_id: selectedUserId,
            user_role: "employee",
            status: "active",
            is_deleted: false,
            created_date: serverTimestamp(),
            can_declare_disaster:
              orgType === "center" ? args.data.can_declare_disaster : false,
            can_approve_disaster:
              orgType === "center" ? args.data.can_approve_disaster : false,
            is_representative: args.data.is_representative ? true : false,
          };
          await createEmployee(sanitizedData);
          // Send email to employee with temporary password
          // console.log("sanitized first name", sanitizedData.first_name);
          // console.log("org type", orgType);
          // console.log("darci id", sanitizedData.darci_id);
          // console.log("password", decrypt(sanitizedData.password));
          await mailer.sendEmployeeInviteEmail(
            sanitizedData.email,
            sanitizedData.first_name,
            orgName,
            sanitizedData.darci_id,
            decrypt(sanitizedData.password)
          );

          if (role == "admin") getAdminEmployees();
          else if (role == "partner") getPartnerEmployees();
          else if (role == "center") getCenterEmployees();

          if (gridRef.current) {
            setTimeout(() => {
              gridRef.current.refresh();
            }, 100);
          }
        }
      } else if (args.action === "edit") {
        const sanitizedData = {
          email: args.data.email?.trim().toLowerCase(),
          first_name: args.data.first_name?.trim(),
          last_name: args.data.last_name?.trim(),
          mobile_number: args.data.mobile_number?.trim(),
          role: args.data.role,
          darci_id: args.data.darci_id ?? "",
          can_declare_disaster:
            orgType === "center"
              ? args.data.can_declare_disaster
                ? args.data.can_declare_disaster
                : false
              : false,
          can_approve_disaster:
            orgType === "center"
              ? args.data.can_approve_disaster
                ? args.data.can_approve_disaster
                : false
              : false,
          is_representative: args.data.is_representative ? true : false,
          docId: args.data.id,
        };
        await editAction(sanitizedData); // Call the edit action with updated data
      }
    } else if (args.requestType === "delete") {
      const dataToDelete = args.data[0];
      dataToDelete.docId = dataToDelete.id;
      await deleteAction(dataToDelete);
    }
    if (args.requestType === "beginEdit") {
      setTimeout(() => {
        if (args.dialog) {
          args.dialog.header = "Edit Employee"; // Set the desired header
        }
      }, 0); // Delay execution to allow dialog to initialize
      setIsEditMode(true); // Set edit mode to true
      // Make columns visible again
      if (role === "center") {
        gridRef.current.columns.forEach((column) => {
          if (
            column.field === "can_declare_disaster" ||
            column.field === "can_approve_disaster" ||
            column.field === "is_representative" ||
            column.field === "is_admin"
          ) {
            column.visible = true;
          }
        });
      }
    } else if (args.requestType === "add") {
      setTimeout(() => {
        if (args.dialog) {
          args.dialog.header = "Add Employee"; // Set the desired header
        }
      }, 0); // Delay execution to allow dialog to initialize
    } else if (args.requestType === "save" || args.requestType === "cancel") {
      setIsEditMode(false); // Reset edit mode on save or cancel
    }
  };

  const roleDatasource = [
    { idName: "Admin", id: "Admin" },
    { idName: "Regular Employee", id: "Regular Employee" },
    { idName: "Case Supervisor", id: "Case Supervisor" },
    { idName: "Case Manager", id: "Case Manager" },
  ];

  const typeParams = {
    params: {
      allowFiltering: true,
      dataSource: roleDatasource,
      fields: { text: "idName", value: "idName" },
      query: new Query(),
    },
  };

  const handleAddEmployee = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSearchedEmployeesData([]);
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      {isModalOpen && (
        <div
          id="search-modal"
          className="fixed inset-0 z-50 flex items-center justify-center rounded-lg bg-black bg-opacity-50"
        >
          <div className="w-9/12 rounded-lg bg-white shadow-lg">
            <div className="bg-blue flex items-center justify-between rounded-tl-lg rounded-tr-lg p-4">
              <h2 className="bg-blue  text-base font-semibold">
                Add an Employee
              </h2>
              <button
                class="bg-blue cursor-pointer rounded-full text-[24px] text-white"
                onClick={closeModal}
              >
                ×
              </button>
            </div>
          </div>
        </div>
      )}
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          {orgType === "partner" ? "Partner" : "Center"}{" "}
          <span className="text-gray-400"> / </span> Employees
        </a>
      </div>
      <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <div className="mb-2 flex w-full justify-end">
          <button
            type="button"
            className="btn-default my-auto flex cursor-pointer gap-1 px-3 py-1.5"
            onClick={handleAddEmployee}
          >
            Add Employee
          </button>
        </div>
        <GridComponent
          dataSource={employeesData}
          editSettings={{
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true,
            mode: "Dialog",
          }}
          ref={gridRef}
          cssClass="custom-grid-dialog"
          actionBegin={actionBegin}
          allowFiltering={true}
          filterSettings={filterSettings}
          allowSorting={true}
          sortSettings={sortSettings}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="email"
              headerText="Email"
              width={250}
              validationRules={{
                required: true,
                email: true, // Add email validation rule
              }}
              onValueChange={async (args) => {
                // Validate email format
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(args.value)) {
                  args.cancel = true; // Cancel the change if invalid
                  alert("Please enter a valid email addressss");
                }
              }}
            />
            <ColumnDirective
              field="mobile_number"
              headerText="Mobile Number"
              width={200}
              validationRules={{
                required: true,
              }}
            />
            <ColumnDirective
              field="first_name"
              headerText="First Name"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="last_name"
              headerText="Last Name"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="role"
              headerText="Role"
              editType="dropdownedit"
              edit={typeParams}
            />

            <ColumnDirective
              field="can_declare_disaster"
              headerText="Can Declare Disaster"
              template={(props) => (props.can_declare_disaster ? "Yes" : "No")}
              editType="booleanedit"
              visible={role === "center"}
            />
            <ColumnDirective
              field="can_approve_disaster"
              headerText="Can Approve Disaster"
              template={(props) => (props.can_approve_disaster ? "Yes" : "No")}
              editType="booleanedit"
              visible={role === "center"}
            />

            <ColumnDirective
              field="is_representative"
              headerText="Representative"
              template={(props) => (props.is_representative ? "Yes" : "No")}
              editType="booleanedit"
              visible={role !== "admin"}
            />
            <ColumnDirective
              headerText="Actions"
              width={100}
              commands={[
                {
                  type: "Edit",
                  buttonOption: {
                    content: `<i class="fas fa-edit"></i>`,
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Delete",
                  buttonOption: {
                    content: '<i class="fas fa-trash-alt"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
              ]}
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default Employees;
