import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  updateDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import mailer from "../service/api/mailer";
import userIcon from "../../src/icons/Profile/userIcon.png";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import { useSearchParams } from "react-router-dom";
import { useGeneralContext } from "../context/GeneralContext";
import { useAuth } from "../components/AuthContext";
import { encrypt, decrypt } from "../service/encryption/Encryption";
import Popup from "../components/Popup";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { config } from "../utils/config";
import { UserRoles, UserStatus } from "../utils/enums";

const Register = () => {
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [code, setCode] = useState("");
  const [agree, setAgree] = useState(false);
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { dupAddressData } = useGeneralContext();
  const { setRoleBasedRoute, logout } = useAuth();
  const location = useLocation();
  const [inviteId, setInviteId] = useState(null);
  const [emailToUse, setEmailToUse] = useState(location.state?.email);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const initializeRegister2 = async () => {
      try {
        const email = location.state?.email;
        const stateInviteId = location.state?.inviteId;

        if (!email) {
          throw new Error("Email is required to proceed");
        }

        setEmailToUse(email);
        if (stateInviteId) {
          setInviteId(stateInviteId);
        }

        const user = await getUserByEmail(email);
        if (user) {
          setUserType(
            user.user_role.charAt(0).toUpperCase() + user.user_role.slice(1)
          );
          setFirstName(user.first_name);
          setLastName(user.last_name);
        } else {
          console.error("User not found.");
          throw new Error("User data not found");
        }
      } catch (error) {
        console.error("Error in Register2 initialization:", error);
        navigate("/register2", { state: { email: emailToUse } });
      } finally {
        setIsLoading(false);
      }
    };

    initializeRegister2();
  }, [location.state]);

  const hasInvited = async () => {
    const invitedUsersRef = collection(db, "invited_users");
    await updateDoc(doc(invitedUsersRef, inviteId), {
      acc_created: true,
      acc_created_date: serverTimestamp(),
    });
  };

  const onContinue = async () => {
    // Prevent multiple clicks while processing
    if (buttonDisabled) {
      return;
    }
    setButtonDisabled(true);

    try {
      const user = await getUserByEmail(emailToUse);

      if (decrypt(user.otp) == code) {
        // Use hardcoded credentials for authentication
        const auth = getAuth();
        if (!auth.currentUser) {
          await signInWithEmailAndPassword(
            auth,
            config.bluesky_email_auth,
            config.bluesky_password
          );
        }

        const userRef = doc(db, "users", user.id);
        if (user.user_role === UserRoles.CITIZEN) {
          await updateDoc(userRef, {
            status: UserStatus.EMAIL_VERIFIED,
          });
        } else {
          await updateDoc(userRef, {
            status: UserStatus.ADDRESS_VERIFIED,
          });
        }

        // Clear auth state
        await logout();

        navigate("/login", {
          state: { email: emailToUse },
        });
        return;

        // Now proceed with Firestore updates
        if (dupAddressData?.fraud_email === emailToUse || user.dup_address) {
          try {
            const verificationData = dupAddressData;

            // Check if verificationData exists before accessing its properties
            if (!verificationData) {
              const userRef = doc(db, "users", user.id);
              await updateDoc(userRef, {
                disabled: true,
                status: "inactive",
              });
            } else {
              const userRef = doc(db, "users", user.id);
              await updateDoc(userRef, {
                disabled: true,
                status: "inactive",
                dup_address_otp: encrypt(verificationData.otp),
                dup_address_token: verificationData.token,
              });
            }

            setRoleBasedRoute(null);
            logout();

            if (inviteId) {
              await hasInvited();
            }

            navigate("/addressverify", {
              state: { email: emailToUse },
            });
            return;
          } catch (error) {
            console.error("Error handling duplicate address:", error);
            // Still navigate to address verify even if there's an error
            navigate("/addressverify", {
              state: { email: emailToUse },
            });
          }
        } else {
          const userRef = doc(db, "users", user.id);
          await updateDoc(userRef, {
            status: "active",
            profile_completed: false,
          });

          // Store complete user data in session

          sessionStorage.setItem("userId", user.id);
          sessionStorage.setItem("userRole", user.user_role);
          sessionStorage.setItem(user.user_role, JSON.stringify(user));

          // Set role before navigation
          setRoleBasedRoute(user.user_role);

          // Navigate based on user role
          if (!user.profile_completed) {
            const targetPath =
              user.user_role === "citizen"
                ? "/citizenprofile"
                : user.user_role === "center"
                ? "/centerprofile"
                : user.user_role === "partner"
                ? "/partnerprofile"
                : "/home";
            navigate(targetPath, { replace: true });
          } else {
            navigate("/home", { replace: true });
          }
        }
      } else {
        setIsInvalidCode(true);
        setTimeout(() => setIsInvalidCode(false), 3000);
      }
    } catch (err) {
      console.error("Error in onContinue:", err);
      console.error("Error details:", {
        message: err.message,
        stack: err.stack,
      });
      alert(err.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const getUserByEmail = async (email) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      }

      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });

      return userData.length > 0 ? userData[0] : null;
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  };

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const [showPopup, setShowPopup] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const resendCode = async () => {
    if (resendTimer > 0) return;

    // Set timer immediately to disable the button
    setResendTimer(60);

    try {
      console.log("Resending code");
      const newOtp = generateOtp();

      const user = await getUserByEmail(emailToUse);
      if (!user) {
        throw new Error("User not found");
      }

      await signInWithEmailAndPassword(
        auth,
        config.bluesky_email_auth,
        config.bluesky_password
      );

      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, {
        otp: encrypt(newOtp),
      });

      await mailer.sendOtpEmail(emailToUse, newOtp, user.first_name, user.id);

      setTimeout(() => setShowPopup(true), 2000);

      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (err) {
      // Reset timer if there's an error
      setResendTimer(0);
      console.error("Error in resendCode:", err);
    }
  };

  if (isLoading) {
    return (
      <div className="flex min-h-screen w-full items-center justify-center">
        Loading...
      </div>
    );
  }

  return (
    <div className="flex min-h-screen w-full items-center justify-center overflow-y-auto   bg-[rgb(225,190,116)]/[0.13]">
      {showPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-8 shadow-lg">
            <h3 className="mb-4 text-xl font-bold text-gray-800">
              Email Sent!
            </h3>
            <p className="text-gray-600 mb-6">
              A new confirmation code has been sent to your email address.
              <br />
              <br />
              If you don't see it in your inbox, please:
              <ul className="mt-2 list-disc pl-5">
                <li>Check your spam or junk folder</li>
                <li>Wait a few minutes for the email to arrive</li>
                <li>Make sure your email address is correct</li>
              </ul>
            </p>
            <button
              onClick={() => setShowPopup(false)}
              className="w-full rounded bg-blue-500 px-4 py-2 text-white hover:cursor-pointer hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="viewport-bg flex min-h-screen w-full items-center justify-center bg-[rgb(225,190,116)]/[0.13] pb-2 pt-2">
        <div className="flex rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)] ">
          <div className="w-[450px] rounded-l-lg bg-white pb-2">
            <header className="text-3xl text-gray-700 z-0 flex w-[483px] max-w-full flex-col items-center justify-center whitespace-nowrap bg-white px-2 py-1.5 text-center font-bold leading-none tracking-wide">
              <img
                loading="lazy"
                src={blueSkyLogo}
                className="aspect-[0.99] w-[90px] object-contain"
                alt="DARCI logo"
              />
              <label className="login-logo-label mt-2.5">DARCI</label>
            </header>
            <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center ">
              <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                <div className="text-xl leading-none">
                  {/* <span className="text-base">Powered by </span> */}
                  <span className="text-base text-blue-500">
                    Disaster Aware and Ready Communities
                  </span>
                </div>
                <div className="mt-1.5 text-xs">Step 3 of 3</div>
                <div className="ml-1 mt-1.5 text-base leading-none">
                  Create account as a
                  <span className="ml-1 text-blue-500">{userType}</span>
                </div>
              </div>
            </div>
            <div className="max-md:px-5 flex max-w-[483px] flex-col justify-center rounded-2xl bg-white p-8">
              <h2 className="max-md:max-w-full text-xl font-semibold leading-tight text-slate-600">
                Enter Your Confirmation Code
              </h2>
              {userType === "Citizen" && (
                <div className="mt-4 flex">
                  <div>
                    <img
                      loading="lazy"
                      src={userIcon}
                      className="aspect-[0.99] w-[40px] object-contain"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                      {emailToUse}
                    </p>
                    <p
                      className="max-md:max-w-full mt-2 whitespace-nowrap"
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {lastName ? lastName : "Last Name not provided"},
                      {firstName ? firstName : "First Name not provided"}
                    </p>
                  </div>
                </div>
              )}
              <section className="max-md:max-w-full mt-2.5  flex w-full flex-col justify-center">
                <p className="font-small max-md:max-w-full text-slate-600">
                  Please enter the confirmation code sent to your email.
                </p>
                <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-7">
                  <input
                    type="text"
                    aria-label="Confirmation code"
                    className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] focus:outline-none"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-2.5 text-base font-medium">
                  {isInvalidCode ? (
                    <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                      You have entered an invalid code!
                    </div>
                  ) : (
                    <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                      {" "}
                    </div>
                  )}
                  <button
                    className={`my-auto w-[121px] self-stretch bg-white text-right ${
                      resendTimer > 0
                        ? "cursor-not-allowed text-gray-400"
                        : "text-blue-600 hover:cursor-pointer"
                    }`}
                    onClick={resendCode}
                    disabled={resendTimer > 0}
                  >
                    Resend Code
                  </button>
                </div>
                <div
                  className="flex max-w-[483px] flex-col justify-center tracking-wide"
                  style={{ marginTop: "20px" }}
                >
                  <div className="max-md:max-w-full mt-3 flex w-full flex-col justify-center text-center">
                    <button
                      className="max-md:max-w-full bg-login-btn w-full gap-2.5 self-stretch whitespace-nowrap rounded-md p-2.5 hover:cursor-pointer"
                      onClick={onContinue}
                      disabled={buttonDisabled}
                    >
                      {buttonDisabled ? "Please wait..." : "Continue"}
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="bg-login-right-panel block w-[350px] rounded-r-lg p-4 leading-4 mq700:hidden">
            <h3 className="pt-10 leading-relaxed text-white">
              Welcome to the DARCI Program, a disaster aware and community ready
              application.
              <br />
              <br />
              We are here to support your efforts in strengthening community
              preparedness and recovery.
              <br />
              <br />
              Access comprehensive tools, resources, and insights to enhance
              your leadership, response, and recovery capabilities.
              <br />
              <br />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  className: PropTypes.string,
};

export default Register;
