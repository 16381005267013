const CustomTextArea = ({
  errors,
  name,
  placeholder,
  register,
  rows = 4,
  ...props
}) => {
  return (
    <div className="relative">
      <textarea
        rows={rows}
        placeholder=" "
        onResize={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = e.target.scrollHeight + "px";
        }}
        className={`peer w-full resize-y rounded-[4px] px-3 pb-2 pt-6 text-base outline-none
          ${
            errors[name]
              ? "border-red-500 focus:border-red-500"
              : "border-gray-200 hover:border-[#8F8F8F] focus:border-darkslateblue"
          }
        `}
        style={{
          border: errors[name] ? "1px solid #FF0000" : "1px solid #CFCFCF",
          borderColor: errors[name] ? "#FF0000" : "#CFCFCF",
          "&:hover": {
            borderColor: errors[name] ? "#FF0000" : "#8F8F8F",
          },
          "&:focus": {
            borderColor: errors[name] ? "#FF0000" : "#193861",
          },
          lineHeight: "1.5",
        }}
        {...props}
        {...register}
      />
      <label
        htmlFor={name}
        className={`absolute left-3 top-2 flex bg-white px-[2px] text-[14px] transition-all 
          ${errors[name] ? "text-red-500" : "text-smokey-grey"}
        `}
      >
        {placeholder}
      </label>
      {errors[name] && (
        <p className="mt-1 text-xs text-red-500">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default CustomTextArea;
