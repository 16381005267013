import { Query } from "@syncfusion/ej2-data";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import {
  Aggregate,
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { DialogUtility } from "@syncfusion/ej2-react-popups";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import AddNewButton from "../../../components/AddNewButton";
import CustomAccordion from "../../../components/CustomAccordion";
import CustomInput from "../../../components/CustomInput";
import CustomSelect from "../../../components/CustomSelect";
import CustomTag from "../../../components/CustomTag";
import DatePickerTemplate from "../../../components/DatePickerTemplate";
import DeleteModal from "../../../components/DeleteModal";
import HeaderTitle from "../../../components/HeaderTitle";
import Modal from "../../../components/Modal";
import PhotoColumn from "../../../components/PhotoColumn";
import SimpleFileUploader from "../../../components/SimpleFileUploader";
import CustomSwitch from "../../../components/Switch";
import ThreeDotMenu from "../../../components/ThreeDotMenu";
import ViewPhotosModal from "../../../components/ViewPhotosModal";
import { useDisasterObjContext } from "../../../context/DisasterObjContext";
import { db } from "../../../firebase";
import "../../../pages/style.css";
import citizenProfileService from "../../../service/citizen/CitizenProfile";
import {
  AnnualIncomeOptions,
  AutomotiveInsuranceTypeOptions,
  countyDropdownByState,
  HomeInsuranceTypeOptions,
  OwnerOrRenterOptions,
  PetsCoatOptions,
  PetsSizeOptions,
  RelationshipOptions,
  GenderOptions,
  EthnicityOptions,
  USStatesOptions,
  addressTypeDropdown,
  addressConditionDropdown,
  subsidizedByDropdown,
  yesNoDropdown,
} from "../../../utils/constants";
import {
  alphabetRegex,
  emailRegex,
  REGEX,
  usMobileNumberRegex,
} from "../../../utils/regex";
import {
  fireQuestions,
  surveyQuestions,
  tornadoQuestions,
  floodQuestions,
  hurricaneQuestions,
} from "../../datasource";
import SurveyQuestionAccordion from "../../Disasters/SurveyQuestionAccordion";
import { isValidDate } from "../../../utils/formatter";

// const scrollToTop = (event) => {
//   event.preventDefault();
//   const header = document.getElementById("survey-questions-header");
//   if (header) {
//     header.scrollIntoView({ behavior: "smooth" });
//   }
// };
// const scrollToBottom = (event) => {
//   event.preventDefault();
//   const footer = document.getElementById("survey-questions-footer");
//   if (footer) {
//     footer.scrollIntoView({ behavior: "smooth" });
//   }
// };

// const chunkQuestions = (questions, size) => {
//   const chunkedArr = [];
//   for (let i = 0; i < questions.length; i += size) {
//     chunkedArr.push(questions.slice(i, i + size));
//   }
//   return chunkedArr;
// };

// const questionGroups = chunkQuestions(surveyQuestions, 3);

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

// const editing = {
//   allowDeleting: true,
//   allowAdding: true,
//   allowEditing: true,
//   showDeleteConfirmDialog: true,
//   mode: "Dialog",

//   dialog: { cssClass: "custom-dialog" },
// };

const filterSettings = {
  type: "CheckBox",
};

// const intl = new Internationalization();

// const formatDate = (date) => {
//   if (date) {
//     return intl.formatDate(new Date(date), { format: "MM/dd/yyyy" });
//   }
//   return "";
// };
// const formattedData = householdMembers.map((item) => ({
//   ...item,
//   date_of_birth: formatDate(item.date_of_birth),
//   household_member_id: item.household_member_id,
// }));
// const datepickerparams = {
//   params: {
//     format: "MM/dd/yyyy",
//     value: new Date(),
//     showClearButton: false,
//     showTodayButton: false,
//     start: "Decade",
//     depth: "Day",
//   },
// };

// const militaryvetTypes = [{ value: "No" }, { value: "Yes" }];

// const militaryvetParams = {
//   params: {
//     allowFiltering: true,
//     dataSource: militaryvetTypes,
//     fields: { text: "value", value: "value" },
//     query: new Query(),
//   },
// };

const CaseManagementImmediateResponseSurvey = ({
  // selectedDisasters,
  activeDisasters,
  parentSetCaseNumber,
}) => {
  const { disaster } = useDisasterObjContext();
  console.log(disaster, "disaster");
  const selectedDisasters = useMemo(() => {
    if (disaster) {
      return [disaster?.id];
    }
    return [];
  }, [disaster]);
  const currentDisasterType = useMemo(() => {
    if (disaster) {
      return disaster?.disaster_type?.toLowerCase();
    }
    return null;
  }, [disaster]);
  // console.log(selectedDisasters, "selectedDisasters");
  const [currentUserData, setCurrentUserData] = useState(null);
  const accessedUserId = currentUserData?.accessed_userId;
  const [data, setData] = useState(null);
  // const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  // const [isDisplacedInfoVisible, setIsDisplacedInfoVisible] = useState(true);
  // const [displacedEditMode, setDisplacedEditMode] = useState(false);
  // const [tempFormData, setTempFormData] = useState({});
  // const [displacedTempData, setDisplacedTempData] = useState({});
  // const [lastEditedTime, setLastEditedTime] = useState(null);
  const [householdMembers, setHouseholdMembers] = useState();
  const [responses, setResponses] = useState({}); // State to hold responses
  // const [dob, setDob] = useState(null);
  // const errorTimeoutRef = useRef(null);
  // const [validationErrors, setValidationErrors] = useState({});
  // const [impactedAddress, setImpactedAddress] = useState(null);
  const [householdId, setHouseholdId] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");
  const [activeMenuAlternateContact, setActiveMenuAlternateContact] =
    useState(false);
  const [showAlternateContactModal, setShowAlternateContactModal] =
    useState(false);
  const alternateContactFormRef = useRef(null);
  const [isSubmittingAlternateContact, setIsSubmittingAlternateContact] =
    useState(false);
  // const [selectedImpactedAddress, setSelectedImpactedAddress] = useState(null);
  // const [activeMenuImpactedAddress, setActiveMenuImpactedAddress] =
  //   useState(null);
  // const [selectedDisplacedAddress, setSelectedDisplacedAddress] =
  //   useState(null);
  // const [activeMenuDisplacedAddress, setActiveMenuDisplacedAddress] =
  //   useState(null);

  // HOUSEHOLD INFO
  const [activeMenuHouseholdInfo, setActiveMenuHouseholdInfo] = useState(false);
  const [showHouseholdInfoModal, setShowHouseholdInfoModal] = useState(false);
  const householdInfoFormRef = useRef(null);
  const [isSubmittingHouseholdInfo, setIsSubmittingHouseholdInfo] =
    useState(false);

  // HOUSEHOLD MEMBERS
  // const [selectedHouseholdMember, setSelectedHouseholdMember] = useState(null);
  const [activeMenuHouseholdMember, setActiveMenuHouseholdMember] =
    useState(null);
  const [isEditingHouseholdMember, setIsEditingHouseholdMember] =
    useState(false);
  const [showHouseholdMemberModal, setShowHouseholdMemberModal] =
    useState(false);
  const householdMemberFormRef = useRef(null);
  const [isSubmittingHouseholdMember, setIsSubmittingHouseholdMember] =
    useState(false);
  const [householdMemberToDeleteId, setHouseholdMemberToDeleteId] =
    useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // PETS
  const [activeMenuPets, setActiveMenuPets] = useState(null);
  const [isEditingPets, setIsEditingPets] = useState(false);
  const [showPetsModal, setShowPetsModal] = useState(false);
  const petsFormRef = useRef(null);
  const [isSubmittingPets, setIsSubmittingPets] = useState(false);
  const [petsToDeleteId, setPetsToDeleteId] = useState(null);
  const [showDeletePetModal, setShowDeletePetModal] = useState(false);

  // ADDRESSES
  const [addresses, setAddresses] = useState([]);
  const [activeMenuAddress, setActiveMenuAddress] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const addressFormRef = useRef(null);
  const [isSubmittingAddress, setIsSubmittingAddress] = useState(false);
  const [addressToDeleteId, setAddressToDeleteId] = useState(null);
  const [showDeleteAddressModal, setShowDeleteAddressModal] = useState(false);
  const [activeMenuAddressDisplaced, setActiveMenuAddressDisplaced] =
    useState(null);

  // VIEW PET PHOTOS MODAL
  const [showPetPhotosModal, setShowPetPhotosModal] = useState(false);
  const [selectedPet, setSelectedPet] = useState({});
  const [selectedPetId, setSelectedPetId] = useState(null);

  // Add useEffect to fetch current user data to get accessedUserId
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // First get the current user's data to get the accessed_userId
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUserData(userData);

          // If there's an accessed_userId, fetch their survey responses
          if (userData.accessed_userId) {
            const accessedUserDoc = await getDoc(
              doc(db, "users", userData.accessed_userId)
            );
            if (accessedUserDoc.exists()) {
              setData(accessedUserDoc.data());
              await fetchUserSurveyResponses(userData.accessed_userId);
            }
          }
        }
      } else {
        setData(null);
        setCurrentUserData(null);
      }
    });

    return () => unsubscribe();
  }, [selectedUserId]);

  const fetchAddresses = useCallback(async () => {
    const addressId = data?.address_id;
    if (addressId) {
      const addressRef = collection(db, `address/${addressId}/addresses`);
      const addressSnapshot = await getDocs(addressRef);
      if (!addressSnapshot.empty) {
        const addressData = addressSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAddresses(addressData?.filter((obj) => obj.is_deleted !== true));
      }
    }
  }, [data]);

  useEffect(() => {
    if (accessedUserId) {
      fetchAddresses();
    }
  }, [accessedUserId, fetchAddresses]);

  const fetchData = useCallback(async () => {
    try {
      // First fetch the default profile data
      const citizenData = await citizenProfileService.getCitizenProfile(
        "users",
        accessedUserId
      );

      if (citizenData) {
        setHouseholdId(citizenData.household_id);
        // If we have selected disasters, try to fetch disaster-specific address
        if (selectedDisasters.length === 1) {
          const disasterId = selectedDisasters[0];

          // Get impacted address
          const impactedAddressRef = doc(
            db,
            `users/${accessedUserId}/impacted-addresses/${disasterId}`
          );
          const impactedAddressDoc = await getDoc(impactedAddressRef);

          await fetchImpactedAddress();

          await fetchHouseholdInfo();
          await fetchHouseholdMembers();
          await fetchPets();
        } else {
          // Use default profile data when no disaster is selected
          setData(citizenData);
          // setTempFormData(citizenData);
          if (citizenData.updated_at) {
            // setLastEditedTime(citizenData.updated_at.toDate());
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessedUserId, selectedDisasters]);

  useEffect(() => {
    if (accessedUserId) {
      fetchData();
    }
  }, [accessedUserId, fetchData]);

  const fetchHouseholdInfo = useCallback(async () => {
    if (selectedDisasters.length === 1 && accessedUserId) {
      const disasterId = selectedDisasters[0];
      const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/household_info/details`;
      const disasterHouseholdInfoRef = doc(db, disasterHouseholdPath);
      const disasterHouseholdInfoDoc = await getDoc(disasterHouseholdInfoRef);

      if (disasterHouseholdInfoDoc.exists()) {
        const householdData = disasterHouseholdInfoDoc.data();
        setTempHouseholdFormData({
          ...householdData,
          id: disasterHouseholdInfoRef.id,
        });
      } else {
        const householdInfoRef = doc(
          db,
          `household/${data?.household_id}/household_info/details`
        );
        const householdInfoDoc = await getDoc(householdInfoRef);
        if (householdInfoDoc.exists()) {
          const householdData = householdInfoDoc.data();
          await setDoc(disasterHouseholdInfoRef, {
            ...householdData,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          });
          await fetchHouseholdInfo();
        }
      }
    }
  }, [selectedDisasters, accessedUserId, data]);

  const fetchHouseholdMembers = useCallback(async () => {
    if (selectedDisasters.length === 1 && accessedUserId) {
      const disasterId = selectedDisasters[0];
      const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/household_members`;
      const membersCollectionRef = collection(db, disasterHouseholdPath);
      const membersSnapshot = await getDocs(membersCollectionRef);

      if (!membersSnapshot.empty) {
        const updatedMembers = membersSnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((member) => !member.is_deleted)
          .map((member) => ({
            ...member,
            date_of_birth: member.date_of_birth || "",
          }));

        setHouseholdMembers(updatedMembers);
      } else {
        const householdMemberPath = `household/${data?.household_id}/household_members`;
        const householdMembersRef = collection(db, householdMemberPath);
        const householdMembersDoc = await getDocs(householdMembersRef);
        const householdMembersData = householdMembersDoc.docs;
        const defaultHouseholdMembersData = householdMembersData
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .filter((member) => member.is_deleted !== true);

        for (const member of defaultHouseholdMembersData) {
          const memberDocRef = doc(db, disasterHouseholdPath, member.id);
          await setDoc(memberDocRef, {
            ...member,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          });
        }

        await fetchHouseholdMembers();
      }
    }
  }, [selectedDisasters, accessedUserId, data]);

  const fetchPets = useCallback(async () => {
    if (selectedDisasters.length === 1 && accessedUserId) {
      const disasterId = selectedDisasters[0];
      const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/pets`;
      const petsCollectionRef = collection(db, disasterHouseholdPath);
      const petsSnapshot = await getDocs(petsCollectionRef);

      if (!petsSnapshot.empty) {
        const updatedPets = petsSnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }))
          .filter((pet) => !pet.is_deleted)
          .map((pet) => ({
            ...pet,
          }));

        setPets(updatedPets || []);
      } else {
        const householdPetsPath = `household/${data?.household_id}/pets`;
        const householdPetsRef = collection(db, householdPetsPath);
        const householdPetsDoc = await getDocs(householdPetsRef);
        const householdPetsData = householdPetsDoc.docs;
        const defaultHouseholdPetsData = householdPetsData
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .filter((pet) => pet.is_deleted !== true);

        for (const pet of defaultHouseholdPetsData) {
          const petDocRef = doc(db, disasterHouseholdPath, pet.id);
          await setDoc(petDocRef, {
            ...pet,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          });
        }

        await fetchPets();
      }

      // console.log("petsSnapshot", petsSnapshot.docs);
    }
  }, [selectedDisasters, accessedUserId, data]);

  // const togglePersonalInfo = (event) => {
  //   event.preventDefault();
  //   setIsPersonalInfoVisible(!isPersonalInfoVisible);
  // };

  // const startEditing = (event) => {
  //   event.preventDefault();
  //   setTempFormData({ ...data });
  //   setIsEditMode(true);
  // };

  // const toggleDisplacedInfo = (event) => {
  //   event.preventDefault();
  //   setIsDisplacedInfoVisible((prev) => !prev);
  // };

  const petCoatParams = {
    params: {
      allowFiltering: true,
      dataSource: [
        { value: "Wire" },
        { value: "Straight" },
        { value: "Shaggy" },
        { value: "Curly" },
        { value: "Wavy" },
      ],
      fields: { text: "value", value: "value" },
      query: new Query(),
    },
  };

  const petSizeParams = {
    params: {
      allowFiltering: true,
      dataSource: [{ value: "Small" }, { value: "Medium" }, { value: "Large" }],
      fields: { text: "value", value: "value" },
      query: new Query(),
    },
  };

  // const relationshipParams = {
  //   params: {
  //     allowFiltering: true,
  //     dataSource: [
  //       { value: "Husband" },
  //       { value: "Wife" },
  //       { value: "Daughter" },
  //       { value: "Son" },
  //       { value: "Other relatives" },
  //     ],
  //     fields: { text: "value", value: "value" },
  //     query: new Query(),
  //   },
  // };

  // const savePersonalInfo = async () => {
  //   const errors = validatePersonalInfo(tempFormData);
  //   if (Object.keys(errors).length > 0) {
  //     setValidationErrors(errors);

  //     if (errorTimeoutRef.current) {
  //       clearTimeout(errorTimeoutRef.current);
  //     }

  //     errorTimeoutRef.current = setTimeout(() => {
  //       setValidationErrors({});
  //     }, 5000);

  //     return;
  //   }

  //   const currentTime = new Date();
  //   const updatedData = { ...tempFormData, updated_at: currentTime };

  //   try {
  //     const requiredFields = [
  //       "first_name",
  //       "last_name",
  //       "mobile_number",
  //       "address1",
  //       "city",
  //       "state",
  //       "zip",
  //       "county",
  //       "alternate_contact_name",
  //       "alternate_email",
  //       "alternate_mobile_number",
  //       "date_of_birth",
  //       "gender",
  //     ];
  //     const isProfileComplete = requiredFields.every(
  //       (field) => updatedData[field]
  //     );
  //     if (isProfileComplete) {
  //       await citizenProfileService.updateCitizenProfile(
  //         "users",
  //         accessedUserId,
  //         {
  //           ...updatedData,
  //           profile_completed: true,
  //         }
  //       );
  //     } else {
  //       await citizenProfileService.updateCitizenProfile(
  //         "users",
  //         accessedUserId,
  //         {
  //           ...updatedData,
  //           profile_completed: true,
  //         }
  //       );
  //     }
  //     setData(updatedData);
  //     setIsEditMode(false);
  //     setLastEditedTime(currentTime);
  //     setValidationErrors({});
  //   } catch (error) {
  //     console.error("Error updating profile: ", error);
  //   }
  // };

  // const onEditDisplacedInfo = (toggle) => (event) => {
  //   event.preventDefault();
  //   setDisplacedEditMode(() => toggle);
  // };

  // const onChangeDisplacedDetails = ({ target: { name, value } }) => {
  //   setDisplacedTempData((prev) => ({ ...prev, [name]: value }));
  // };

  // const onSaveDisplacedDetails = useCallback(async () => {
  //   await citizenProfileService.updateDisplacedDetails(
  //     accessedUserId,
  //     displacedTempData
  //   );
  //   const citizenData = await citizenProfileService.getCitizenProfile(
  //     "users",
  //     accessedUserId
  //   );
  //   setData(citizenData);
  //   onSetDefaultDisplacedDetails(citizenData);
  // }, [displacedTempData, accessedUserId]);

  const onDisplaced = async ({ checked }) => {
    await citizenProfileService.toggleDisplaced(accessedUserId, checked);
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      accessedUserId
    );
    setData((prev) => ({
      ...prev,
      ...citizenData,
    }));
    // onSetDefaultDisplacedDetails(citizenData);
  };

  // const onSetDefaultDisplacedDetails = (data) => {
  //   const { d_address_1, d_address_2, d_city, d_state, d_zip } = data || {};
  //   setDisplacedTempData((prev) => ({
  //     ...prev,
  //     d_address_1: d_address_1 || "",
  //     d_address_2: d_address_2 || "",
  //     d_city: d_city || "",
  //     d_state: d_state || "",
  //     d_zip: d_zip || "",
  //   }));
  // };

  // const saveChanges = async (event) => {
  //   event.preventDefault();
  //   const currentTime = new Date();

  //   // Separate user profile data from address data
  //   const userProfileData = {
  //     alternate_contact_name: tempFormData.alternate_contact_name,
  //     alternate_email: tempFormData.alternate_email,
  //     alternate_mobile_number: tempFormData.alternate_mobile_number,
  //     disability: tempFormData.disability,
  //     military_vet: tempFormData.military_vet,
  //     updated_at: currentTime,
  //   };

  //   const addressData = {
  //     first_name: tempFormData.first_name,
  //     last_name: tempFormData.last_name,
  //     birth_date: tempFormData.birth_date,
  //     address1: tempFormData.address1,
  //     address2: tempFormData.address2,
  //     city: tempFormData.city,
  //     state: tempFormData.state,
  //     zip: tempFormData.zip,
  //     email: tempFormData.email,
  //     mobile_number: tempFormData.mobile_number,
  //     gender: tempFormData.gender,
  //     updated_at: currentTime,
  //   };

  //   try {
  //     // Always update user profile data in users collection
  //     await citizenProfileService.updateCitizenProfile(
  //       "users",
  //       accessedUserId,
  //       userProfileData
  //     );

  //     // Update address data based on disaster selection
  //     if (selectedDisasters.length === 1) {
  //       const disasterId = selectedDisasters[0];
  //       const impactedAddressRef = doc(
  //         db,
  //         `users/${accessedUserId}/impacted-addresses/${disasterId}`
  //       );
  //       await setDoc(impactedAddressRef, {
  //         ...addressData,
  //         disaster_id: disasterId,
  //         created_at: serverTimestamp(),
  //         updated_at: serverTimestamp(),
  //       });
  //     } else {
  //       // If no specific disaster selected, update address in user profile
  //       await citizenProfileService.updateCitizenProfile(
  //         "users",
  //         accessedUserId,
  //         addressData
  //       );
  //     }

  //     // Update local state
  //     setData({
  //       ...tempFormData,
  //       updated_at: currentTime,
  //     });
  //     setIsEditMode(false);
  //     setLastEditedTime(currentTime);
  //   } catch (error) {
  //     console.error("Error updating profile:", error);
  //   }
  // };

  // const cancelEditing = (event) => {
  //   event.preventDefault();
  //   setTempFormData({ ...data });
  //   setIsEditMode(false);
  // };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setTempFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleInputBlur = async () => {
  //   // Remove the auto-save functionality from blur
  //   const errors = validatePersonalInfo(tempFormData);
  //   if (Object.keys(errors).length > 0) {
  //     setValidationErrors(errors);
  //     if (errorTimeoutRef.current) {
  //       clearTimeout(errorTimeoutRef.current);
  //     }
  //     errorTimeoutRef.current = setTimeout(() => {
  //       setValidationErrors({});
  //     }, 5000);
  //   }
  // };

  // const handleHouseholdInputBlur = async () => {
  //   // Remove the auto-save functionality from blur
  //   const errors = validateHouseholdInfo(tempHouseholdFormData);
  //   if (Object.keys(errors).length > 0) {
  //     setValidationErrors(errors);
  //     if (errorTimeoutRef.current) {
  //       clearTimeout(errorTimeoutRef.current);
  //     }
  //     errorTimeoutRef.current = setTimeout(() => {
  //       setValidationErrors({});
  //     }, 5000);
  //   }
  // };

  // const formatLastEditedTime = (time) => {
  //   if (!time) return "Never";

  //   const now = new Date();
  //   const diffInSeconds = Math.floor((now - time) / 1000);

  //   if (diffInSeconds < 60) return "Just now";
  //   if (diffInSeconds < 3600)
  //     return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  //   if (diffInSeconds < 86400)
  //     return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  //   if (diffInSeconds < 604800)
  //     return `${Math.floor(diffInSeconds / 86400)} days ago`;

  //   return time.toLocaleDateString();
  // };

  // const generateUniqueID = () => {
  //   return "_" + Math.random().toString(36).substr(2, 9);
  // };

  // const renderField = (
  //   label,
  //   name,
  //   placeholder,
  //   isDropdown = false,
  //   className = ""
  // ) => {
  //   const alwaysReadOnly = [
  //     "first_name",
  //     "last_name",
  //     "birth_date",
  //     "gender",
  //     "email",
  //     "mobile_number",
  //   ];

  //   const requiredFields = [
  //     "address1",
  //     "city",
  //     "state",
  //     "zip",
  //     "alternate_contact_name",
  //     "alternate_email",
  //     "alternate_mobile_number",
  //     "county",
  //   ];
  //   const isFieldEditable = isEditMode && !alwaysReadOnly.includes(name);
  //   const isRequired = requiredFields.includes(name);

  //   return (
  //     <div
  //       className={`flex flex-col items-start justify-start gap-1 ${className}`}
  //     >
  //       <div className="text-gray-600 relative flex items-center text-left text-xs leading-[20px]">
  //         {label}
  //         {isRequired && !alwaysReadOnly.includes(name) && (
  //           <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
  //             *
  //           </p>
  //         )}
  //       </div>
  //       <div
  //         className={`self-stretch rounded-md ${
  //           isFieldEditable ? "bg-white" : "bg-gray-200"
  //         } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${
  //           validationErrors[name] ? "border-red-500" : "border-gray-300"
  //         } px-3 py-2`}
  //       >
  //         {isFieldEditable ? (
  //           name === "birth_date" ? (
  //             <DatePickerTemplate
  //               value={tempFormData[name || ""]}
  //               onChange={(val) =>
  //                 handleInputChange({ target: { name, value: val } })
  //               }
  //             />
  //           ) : isDropdown ? (
  //             <select
  //               className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
  //               name={name}
  //               value={tempFormData[name] || ""}
  //               onChange={handleInputChange}
  //               onBlur={handleInputBlur}
  //             >
  //               <option value="" disabled>
  //                 {placeholder}
  //               </option>
  //               {name === "gender" ? (
  //                 <>
  //                   <option value="Male">Male</option>
  //                   <option value="Female">Female</option>
  //                 </>
  //               ) : (
  //                 USStates.map((state) => (
  //                   <option key={state.stateID} value={state.abbreviation}>
  //                     {state.abbreviation}
  //                   </option>
  //                 ))
  //               )}
  //             </select>
  //           ) : (
  //             <input
  //               className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
  //               type="text"
  //               name={name}
  //               value={tempFormData[name] || ""}
  //               onChange={handleInputChange}
  //               onBlur={handleInputBlur}
  //               placeholder={placeholder}
  //             />
  //           )
  //         ) : (
  //           <div
  //             className={`flex-1 text-xs ${
  //               tempFormData[name]
  //                 ? "font-semibold text-gray-800"
  //                 : "text-gray-500"
  //             } text-left`}
  //           >
  //             {tempFormData[name] || placeholder}
  //           </div>
  //         )}
  //       </div>
  //       {isFieldEditable && validationErrors[name] && (
  //         <div className="mt-1 text-xs text-red-500">
  //           {validationErrors[name]}
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  // const renderDisplacedField = (
  //   label,
  //   name,
  //   placeholder,
  //   isDropdown = false,
  //   className = ""
  // ) => {
  //   return (
  //     <div
  //       className={`flex flex-col items-start justify-start gap-1 ${className}`}
  //     >
  //       <div className="text-gray-600 relative text-left  text-xs leading-[20px]">
  //         {label}
  //       </div>
  //       <div
  //         className={`self-stretch rounded-md ${
  //           displacedEditMode ? "bg-white" : "bg-gray-200"
  //         } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid border-gray-300 px-3 py-2`}
  //       >
  //         {displacedEditMode ? (
  //           isDropdown ? (
  //             <select
  //               className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
  //               name={name}
  //               value={displacedTempData[name] || ""}
  //               onChange={onChangeDisplacedDetails}
  //               onBlur={onSaveDisplacedDetails}
  //             >
  //               <option value="" disabled>
  //                 {placeholder}
  //               </option>
  //               {USStates.map((state) => (
  //                 <option key={state.stateID} value={state.abbreviation}>
  //                   {state.abbreviation}
  //                 </option>
  //               ))}
  //             </select>
  //           ) : (
  //             <input
  //               className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
  //               placeholder={placeholder}
  //               type="text"
  //               name={name}
  //               value={displacedTempData[name] || ""}
  //               onChange={onChangeDisplacedDetails}
  //               onBlur={onSaveDisplacedDetails}
  //             />
  //           )
  //         ) : (
  //           <div
  //             className={`flex-1  text-xs ${
  //               displacedTempData[name]
  //                 ? "font-semibold text-gray-800"
  //                 : "text-gray-500"
  //             } text-left`}
  //           >
  //             {displacedTempData[name] || placeholder}
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };

  // const validatePersonalInfo = (formData) => {
  //   const errors = {};
  //   const requiredFields = [
  //     "address1",
  //     "city",
  //     "state",
  //     "zip",
  //     "alternate_contact_name",
  //     "alternate_email",
  //     "alternate_mobile_number",
  //     "county",
  //   ];

  //   requiredFields.forEach((field) => {
  //     const value = formData[field];
  //     if (!value || (typeof value === "string" && value.trim() === "")) {
  //       errors[field] = "This field is required";
  //     }
  //   });

  //   return errors;
  // };

  // const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isNewlyRegistered = localStorage.getItem("newlyRegistered");
    if (isNewlyRegistered === "true") {
      // setShowPopup(true);
      localStorage.removeItem("newlyRegistered"); // Remove the flag after showing the popup
    }
  }, []);

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };

  // const dialogContent = () => {
  //   return (
  //     <div style={{ textAlign: "center", padding: "20px" }}>
  //       <h2
  //         style={{
  //           fontSize: "24px",
  //           fontWeight: "normal",
  //           color: "#1e1919",
  //           marginBottom: "10px",
  //         }}
  //       >
  //         Welcome to your Citizen Profile!
  //       </h2>
  //       <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
  //         Please finish your profile to get the most out of our services.
  //       </p>
  //       <button
  //         onClick={handleClosePopup}
  //         className="btn-default"
  //         style={{
  //           padding: "10px 20px",
  //           cursor: "pointer",
  //         }}
  //       >
  //         Got it!
  //       </button>
  //     </div>
  //   );
  // };

  /* Household Information Fields*/
  const [isHouseholdInfoVisible, setIsHouseholdInfoVisible] = useState(true);
  // const [householdFormData, setHouseholdFormData] = useState({
  //   annual_income: "",
  //   ssn: "",
  //   owner_or_renter: "",
  //   insurance: false,
  //   household_size: "",
  //   disability: false,
  //   military_vet: false,
  //   home_insurance: false,
  //   home_insurance_type: "",
  //   automotive_insurance: false,
  //   automotive_insurance_type: "",
  // });
  const [tempHouseholdFormData, setTempHouseholdFormData] = useState({});
  const [lastHouseholdEditedTime, setLastHouseholdEditedTime] = useState(null);

  const [isHouseholdEditMode, setIsHouseholdEditMode] = useState(false);

  // const toggleHouseholdInfo = (event) => {
  //   event.preventDefault();
  //   setIsHouseholdInfoVisible(!isHouseholdInfoVisible);
  // };

  // const startHouseholdEditing = (event) => {
  //   event.preventDefault();
  //   setIsHouseholdEditMode(true);
  // };

  // const validateHouseholdInfo = (formData) => {
  //   const errors = {};
  //   const requiredFields = ["owner_or_renter", "insurance", "household_size"];

  //   requiredFields.forEach((field) => {
  //     if (
  //       formData[field] === undefined ||
  //       formData[field] === "" ||
  //       formData[field] === null
  //     ) {
  //       errors[field] = "This field is required";
  //     }
  //   });

  //   return errors;
  // };

  // const saveHouseholdChanges = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const currentTime = new Date();

  //     // Ensure we're working with a clean object
  //     const updatedHouseholdData = {
  //       annual_income: String(tempHouseholdFormData.annual_income || ""),
  //       owner_or_renter: String(tempHouseholdFormData.owner_or_renter || ""),
  //       household_size: String(tempHouseholdFormData.household_size || ""),
  //       home_insurance: Boolean(tempHouseholdFormData.home_insurance),
  //       home_insurance_type: String(
  //         tempHouseholdFormData.home_insurance_type || ""
  //       ),
  //       automotive_insurance: Boolean(
  //         tempHouseholdFormData.automotive_insurance
  //       ),
  //       automotive_insurance_type: String(
  //         tempHouseholdFormData.automotive_insurance_type || ""
  //       ),
  //       updated_at: currentTime,
  //     };

  //     // Ensure we have a valid household_id
  //     if (!householdId) {
  //       throw new Error("No household ID found");
  //     }

  //     let result;

  //     if (selectedDisasters.length === 1) {
  //       // Update disaster-specific household info
  //       const disasterId = selectedDisasters[0];
  //       const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/household_info/details`;
  //       const householdInfoRef = doc(db, disasterHouseholdPath);
  //       await updateDoc(householdInfoRef, updatedHouseholdData);

  //       // Get the updated document
  //       const updatedDoc = await getDoc(householdInfoRef);
  //       result = updatedDoc.data();
  //     } else {
  //       // Update the main household info
  //       result = await citizenProfileService.updateHouseholdInfo(
  //         householdId,
  //         updatedHouseholdData
  //       );
  //     }

  //     // Update the frontend state with the clean data
  //     setHouseholdFormData(result);
  //     setTempHouseholdFormData(result);
  //     setLastHouseholdEditedTime(currentTime);
  //     setIsHouseholdEditMode(false);
  //     setValidationErrors({});
  //   } catch (error) {
  //     console.error("Error updating household profile: ", error);
  //   }
  // };

  // const cancelHouseholdEditing = (event) => {
  //   event.preventDefault();
  //   setTempHouseholdFormData({ ...householdFormData });
  //   setIsHouseholdEditMode(false);
  // };

  // const handleHouseholdInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setTempHouseholdFormData((prevData) => ({
  //     ...prevData,
  //     [name]:
  //       name === "disability" ||
  //       name === "military_vet" ||
  //       name === "insurance" ||
  //       name === "home_insurance" ||
  //       name === "automotive_insurance"
  //         ? value === "Yes"
  //         : value,
  //   }));
  // };

  const formatIncomeRange = (incomeString) => {
    if (!incomeString) return "";
    if (incomeString === "100000+") return "Greater than $100,000";

    // Remove the '$' prefix and split by '-'
    const [min, max] = incomeString.replace("$", "").split("-");

    // Format each number with commas
    const formattedMin = Number(min).toLocaleString();
    const formattedMax = Number(max).toLocaleString();

    return `$${formattedMin} - ${formattedMax}`;
  };

  // const renderHouseholdField = (
  //   label,
  //   name,
  //   placeholder,
  //   isDropdown = false,
  //   className = "",
  //   isOptional = false
  // ) => {
  //   const isRequired = !isOptional;

  //   const handleAnnualIncomeChange = (e) => {
  //     let inputValue = e.target.value;

  //     const numericValue = inputValue.replace(/[^0-9]/g, "");

  //     handleHouseholdInputChange({
  //       target: { name: name, value: numericValue ? `$${numericValue}` : "" },
  //     });
  //   };

  //   const validateAnnualIncome = () => {
  //     if (
  //       name === "annual_income" &&
  //       (!tempHouseholdFormData[name] || tempHouseholdFormData[name] === "$")
  //     ) {
  //       // Trigger validation error if the field is invalid
  //       setValidationErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [name]: "Annual income is required",
  //       }));
  //     } else {
  //       // Remove the error if it's valid
  //       setValidationErrors((prevErrors) => {
  //         const { [name]: _, ...newErrors } = prevErrors;
  //         return newErrors;
  //       });
  //     }
  //   };

  //   const handleHouseholdSizeChange = (e) => {
  //     let inputValue = e.target.value;

  //     // Remove non-numeric characters
  //     const numericValue = inputValue.replace(/[^0-9]/g, "");

  //     // Convert to number and limit to 20
  //     let validNumber = numericValue
  //       ? Math.min(parseInt(numericValue, 10), 20)
  //       : "";

  //     handleHouseholdInputChange({
  //       target: { name: "household_size", value: validNumber },
  //     });
  //   };

  //   const validateHouseholdSize = () => {
  //     if (!tempHouseholdFormData["household_size"]) {
  //       setValidationErrors((prevErrors) => ({
  //         ...prevErrors,
  //         household_size: "Household size is required",
  //       }));
  //     } else {
  //       setValidationErrors((prevErrors) => {
  //         const { household_size, ...newErrors } = prevErrors;
  //         return newErrors;
  //       });
  //     }
  //   };

  //   return (
  //     <div
  //       className={`flex flex-col items-start justify-start gap-1 hover:cursor-pointer ${className}`}
  //     >
  //       <div className="text-gray-600 relative text-left  text-xs leading-[20px] hover:cursor-pointer">
  //         {label}
  //         {isRequired ? (
  //           <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
  //             *
  //           </p>
  //         ) : (
  //           <span className="ml-1 text-xs text-gray-400">(Optional)</span>
  //         )}
  //       </div>
  //       <div
  //         className={`self-stretch rounded-md ${
  //           isHouseholdEditMode ? "bg-white" : "bg-gray-200"
  //         } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${
  //           validationErrors[name] ? "border-red-500" : "border-gray-300"
  //         } px-3 py-2`}
  //       >
  //         {isHouseholdEditMode ? (
  //           name === "household_size" ? (
  //             // Custom Input for Household Size (Integer Only, Max 20)
  //             <input
  //               type="text"
  //               className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
  //               placeholder={placeholder}
  //               name={name}
  //               value={tempHouseholdFormData[name] || ""}
  //               onChange={handleHouseholdSizeChange}
  //               onBlur={validateHouseholdSize}
  //             />
  //           ) : isDropdown ? (
  //             <select
  //               className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none] hover:cursor-pointer"
  //               name={name}
  //               value={
  //                 name === "insurance"
  //                   ? tempHouseholdFormData[name]
  //                     ? "Yes"
  //                     : "No"
  //                   : tempHouseholdFormData[name] || ""
  //               }
  //               onChange={handleHouseholdInputChange}
  //               // onBlur={handleHouseholdInputBlur}
  //             >
  //               <option value="" disabled>
  //                 {placeholder}
  //               </option>
  //               {name === "owner_or_renter" ? (
  //                 <>
  //                   <option value="Owner" className="hover:cursor-pointer">
  //                     Owner
  //                   </option>
  //                   <option value="Renter" className="hover:cursor-pointer">
  //                     Renter
  //                   </option>
  //                   <option
  //                     value="Rent to Own"
  //                     className="hover:cursor-pointer"
  //                   >
  //                     Rent to Own
  //                   </option>
  //                 </>
  //               ) : name === "annual_income" ? (
  //                 <>
  //                   <option value="0-20000" className="hover:cursor-pointer">
  //                     0-$20,000
  //                   </option>
  //                   <option
  //                     value="20001-40000"
  //                     className="hover:cursor-pointer"
  //                   >
  //                     $20,001-$40,000
  //                   </option>
  //                   <option
  //                     value="40001-60000"
  //                     className="hover:cursor-pointer"
  //                   >
  //                     $40,001-$60,000
  //                   </option>
  //                   <option
  //                     value="60001-80000"
  //                     className="hover:cursor-pointer"
  //                   >
  //                     $60,001-$80,000
  //                   </option>
  //                   <option
  //                     value="80001-100000"
  //                     className="hover:cursor-pointer"
  //                   >
  //                     $80,001-$100,000
  //                   </option>
  //                   <option value="100000+" className="hover:cursor-pointer">
  //                     Greater than $100,000
  //                   </option>
  //                 </>
  //               ) : name === "home_insurance_type" ? (
  //                 <>
  //                   <option value="Homeowners" className="hover:cursor-pointer">
  //                     Homeowners
  //                   </option>
  //                   <option value="Renter" className="hover:cursor-pointer">
  //                     Renter
  //                   </option>
  //                 </>
  //               ) : name === "automotive_insurance_type" ? (
  //                 <>
  //                   <option
  //                     value="Full Coverage"
  //                     className="hover:cursor-pointer"
  //                   >
  //                     Full Coverage
  //                   </option>
  //                   <option value="Liability" className="hover:cursor-pointer">
  //                     Liability
  //                   </option>
  //                 </>
  //               ) : (
  //                 <option value="" disabled>
  //                   {placeholder}
  //                 </option>
  //               )}
  //             </select>
  //           ) : (
  //             <input
  //               className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
  //               placeholder={placeholder}
  //               name={name}
  //               value={tempHouseholdFormData[name] || ""}
  //               onChange={
  //                 name === "annual_income"
  //                   ? handleAnnualIncomeChange
  //                   : handleHouseholdInputChange
  //               }
  //               onBlur={
  //                 name === "annual_income"
  //                   ? validateAnnualIncome
  //                   : handleHouseholdInputBlur
  //               }
  //             />
  //           )
  //         ) : (
  //           <div
  //             className={`flex-1  text-xs ${
  //               householdFormData[name] !== undefined &&
  //               householdFormData[name] !== ""
  //                 ? "font-semibold text-gray-800"
  //                 : "text-gray-500"
  //             } text-left`}
  //           >
  //             {name === "insurance"
  //               ? householdFormData[name]
  //                 ? "Yes"
  //                 : "No"
  //               : householdFormData[name] || placeholder}
  //           </div>
  //         )}
  //       </div>
  //       {isHouseholdEditMode && validationErrors[name] && (
  //         <div className="mt-1 text-xs text-red-500">
  //           {validationErrors[name]}
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  /* Household Members */
  const [isHouseholdMembersVisible, setIsHouseholdMembersVisible] =
    useState(true);
  // const [lastHouseholdMembersEditedTime, setLastHouseholdMembersEditedTime] =
  //   useState(null);

  // const toggleHouseholdMembers = (event) => {
  //   event.preventDefault();
  //   setIsHouseholdMembersVisible(!isHouseholdMembersVisible);
  // };

  const gridRef = useRef(null);

  // const handleAddClick = () => {
  //   if (gridRef.current) {
  //     gridRef.current.addRecord();
  //   }
  // };

  // const actionBegin = async (args) => {
  //   // Initialize data object if it doesn't exist
  //   if (!args.data) {
  //     args.data = {};
  //   }

  //   if (args.requestType === "add") {
  //     // Set default values for new records
  //     args.data = {
  //       ...args.data,
  //       disability: "N/A",
  //       military_vet: "No",
  //     };
  //   }

  //   if (args.requestType === "beginEdit") {
  //     // Get the existing data from the row
  //     const rowData = args.rowData;

  //     // Set the date of birth if it exists
  //     if (rowData?.date_of_birth) {
  //       setDob(rowData.date_of_birth);
  //     }

  //     // Safely set the values with defaults
  //     args.data = {
  //       ...args.data,
  //       ...rowData,
  //       disability: rowData?.disability || "N/A",
  //       military_vet: rowData?.military_vet || "No",
  //     };
  //   }

  //   if (args.requestType === "beginEdit" || args.requestType === "add") {
  //     gridRef.current.columns.forEach((col) => {
  //       if (col.validationRules && col.validationRules.required) {
  //         // Append * to required field headers
  //         col.headerText = col.headerText.replace(/\*$/, "") + " *";
  //       }
  //     });
  //   }
  // };

  // const actionComplete = async (args) => {
  //   if (args.requestType === "save") {
  //     if (args.action === "add") {
  //       if (!selectedDisasters.length || selectedDisasters[0] === "All") {
  //         DialogUtility.alert({
  //           title: "Disaster Required",
  //           content:
  //             "Please select a specific disaster before adding a household member.",
  //           showCloseIcon: true,
  //           closeOnEscape: true,
  //           position: { X: "center", Y: "center" },
  //         });
  //         return;
  //       }

  //       const isDuplicate = await checkForDuplicates(args.data);
  //       if (isDuplicate) {
  //         DialogUtility.alert({
  //           title: "Duplicate Entry",
  //           content: args.data.email
  //             ? "A household member with this email already exists."
  //             : "A household member with this name already exists.",
  //           showCloseIcon: true,
  //           closeOnEscape: true,
  //           position: { X: "center", Y: "center" },
  //         });
  //         return;
  //       }

  //       // Email validation check
  //       if (args.data.email) {
  //         const usersRef = collection(db, "users");
  //         const q = query(usersRef, where("email", "==", args.data.email));
  //         const querySnapshot = await getDocs(q);

  //         if (
  //           !querySnapshot.empty &&
  //           querySnapshot.docs[0].data().email !== data.email
  //         ) {
  //           const userData = querySnapshot.docs[0].data();

  //           if (
  //             userData.household_id &&
  //             userData.household_id !== householdId
  //           ) {
  //             DialogUtility.alert({
  //               title: "User Already in Household",
  //               content: "This user is already part of another household.",
  //               showCloseIcon: true,
  //               closeOnEscape: true,
  //               position: { X: "center", Y: "center" },
  //             });
  //             return;
  //           }
  //         }
  //       }

  //       const newMemberData = {
  //         ...args.data,
  //         household_member_id:
  //           args.data.household_member_id || generateUniqueID(),
  //         is_deleted: false,
  //         date_of_birth: dob
  //           ? new Date(dob).toLocaleDateString("en-US", {
  //               month: "2-digit",
  //               day: "2-digit",
  //               year: "numeric",
  //             })
  //           : null,
  //         disability: args.data.disability || "N/A",
  //         military_vet: args.data.military_vet || "No",
  //         email: args.data.email || "",
  //         acc_created: false,
  //         created_at: new Date(),
  //         updated_at: new Date(),
  //       };

  //       try {
  //         // Add to main household members
  //         await citizenProfileService.addHouseholdMember(
  //           householdId,
  //           newMemberData
  //         );

  //         // Add to disaster-specific collection
  //         const disasterId = selectedDisasters[0];
  //         const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/household_members`;
  //         const membersCollectionRef = collection(db, disasterHouseholdPath);
  //         await addDoc(membersCollectionRef, newMemberData);

  //         setDob(() => null);
  //       } catch (error) {
  //         console.error("Error adding household member:", error);
  //       }
  //     } else if (args.action === "edit") {
  //       const disasterId = selectedDisasters[0];
  //       const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/household_members`;
  //       const memberRef = doc(db, disasterHouseholdPath, args.data.id);

  //       const updatedMemberData = {
  //         ...args.data,
  //         is_deleted: false,
  //         date_of_birth: dob
  //           ? new Date(dob).toLocaleDateString("en-US", {
  //               month: "2-digit",
  //               day: "2-digit",
  //               year: "numeric",
  //             })
  //           : null,
  //         disability: args.data.disability || "N/A",
  //         military_vet: args.data.military_vet || "No",
  //         email: args.data.email || "",
  //         acc_created: false,
  //         updated_at: new Date(),
  //       };

  //       await updateDoc(memberRef, updatedMemberData);
  //       setDob(() => null);
  //     }

  //     setLastHouseholdMembersEditedTime(new Date());

  //     // Refresh the members data
  //     if (selectedDisasters.length === 1) {
  //       const disasterId = selectedDisasters[0];
  //       const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/household_members`;
  //       const membersCollectionRef = collection(db, disasterHouseholdPath);
  //       const membersSnapshot = await getDocs(membersCollectionRef);

  //       const updatedMembers = membersSnapshot.docs
  //         .map((doc) => ({ ...doc.data(), id: doc.id }))
  //         .filter((member) => !member.is_deleted)
  //         .map((member) => ({
  //           ...member,
  //           date_of_birth: member.date_of_birth || "mm/dd/yyyy",
  //         }));

  //       setHouseholdMembers(updatedMembers);
  //     }
  //   } else if (args.requestType === "delete") {
  //     if (selectedDisasters.length === 1) {
  //       const disasterId = selectedDisasters[0];
  //       const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/household_members`;
  //       const memberRef = doc(db, disasterHouseholdPath, args.data[0].id);

  //       await updateDoc(memberRef, {
  //         is_deleted: true,
  //         updated_at: new Date(),
  //       });
  //     }
  //     setLastHouseholdMembersEditedTime(new Date());
  //   } else if (args.requestType === "beginEdit" || args.requestType === "add") {
  //     if (args.dialog) {
  //       const dialog = args.dialog;
  //       dialog.header =
  //         args.requestType === "beginEdit"
  //           ? "Edit Household Member"
  //           : "Add New Household Member";

  //       // Set the header styles
  //       if (dialog.element) {
  //         const headerContent = dialog.element.querySelector(
  //           ".e-dlg-header-content"
  //         );
  //         const header = dialog.element.querySelector(".e-dlg-header");
  //         if (headerContent) {
  //           headerContent.style.backgroundColor = "#348BFF";
  //           headerContent.style.color = "#467BA4";
  //         }
  //         if (header) {
  //           header.style.color = "#467BA4";
  //         }

  //         // Style the save and cancel buttons
  //         setTimeout(() => {
  //           const saveButton = dialog.element.querySelector(".e-primary");
  //           const cancelButton = dialog.element.querySelector(
  //             ".e-btn:not(.e-primary)"
  //           );

  //           if (saveButton) {
  //             saveButton.style.backgroundColor = "#FFFFFF";
  //             saveButton.style.color = "#348BFF";
  //             saveButton.style.border = "none";
  //           }

  //           if (cancelButton) {
  //             cancelButton.style.backgroundColor = "#FFFFFF";
  //             cancelButton.style.color = "#348BFF";
  //             cancelButton.style.border = "1px solid #348BFF";
  //           }
  //         }, 0);
  //       }
  //     }
  //   }
  //   await fetchData();
  // };

  const checkForDuplicates = async (memberData) => {
    try {
      const existingMembers = await citizenProfileService.getHouseholdMembers(
        householdId
      );

      if (memberData.email) {
        return existingMembers.some(
          (member) =>
            !member.is_deleted &&
            member.email &&
            member.email.toLowerCase() === memberData.email.toLowerCase()
        );
      } else {
        return existingMembers.some(
          (member) =>
            !member.is_deleted &&
            member.first_name.toLowerCase() ===
              memberData.first_name.toLowerCase() &&
            member.last_name.toLowerCase() ===
              memberData.last_name.toLowerCase()
        );
      }
    } catch (error) {
      console.error("Error checking for duplicates:", error);
      return false;
    }
  };

  // const emptyRecordTemplate = useCallback(() => {
  //   return (
  //     <div className="p-4">
  //       <div
  //         className="cursor-pointer text-blue-600 hover:underline"
  //         onClick={handleAddClick}
  //       >
  //         + Add New Household Member
  //       </div>
  //     </div>
  //   );
  // }, []);

  // Filter household members to only include those with status true
  const filteredHouseholdMembers = Array.isArray(householdMembers)
    ? householdMembers
        .filter((member) => !member.is_deleted)
        .filter((member) => {
          // If member has no disaster associations, include them
          if (
            !member.associatedDisasters ||
            member.associatedDisasters.length === 0
          ) {
            return true;
          }

          // If "All" is selected, show members with any active disaster
          if (selectedDisasters.includes("All")) {
            return member.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster.disaster_id)
            );
          }

          // Show members associated with selected disasters
          return member.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster.disaster_id)
          );
        })
        .map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth
            ? new Date(member.date_of_birth).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "mm/dd/yyyy",
        }))
    : [];

  // const filteredHouseholdMembers = useMemo(() => {
  //   return Array.isArray(householdMembers)
  //     ? householdMembers
  //         .filter((member) => !member.is_deleted)
  //         .filter((member) => {
  //           // If member has no disaster associations, include them
  //           if (
  //             !member.associatedDisasters ||
  //             member.associatedDisasters.length === 0
  //           ) {
  //             return true;
  //           }

  //           // If "All" is selected, show members with any active disaster
  //           if (selectedDisasters.includes("All")) {
  //             return member.associatedDisasters.some((disaster) =>
  //               activeDisasters.includes(disaster.disaster_id)
  //             );
  //           }

  //           // Show members associated with selected disasters
  //           return member.associatedDisasters.some((disaster) =>
  //             selectedDisasters.includes(disaster.disaster_id)
  //           );
  //         })
  //         .map((member) => ({
  //           ...member,
  //           date_of_birth: member.date_of_birth
  //             ? new Date(member.date_of_birth).toLocaleDateString("en-US", {
  //                 month: "2-digit",
  //                 day: "2-digit",
  //                 year: "numeric",
  //               })
  //             : "mm/dd/yyyy",
  //         }))
  //     : [];
  // }, [householdMembers, selectedDisasters, activeDisasters]);

  /*Pets*/

  const [pets, setPets] = useState([]);
  const [isPetsVisible, setIsPetsVisible] = useState(true);
  // const [lastPetsEditedTime, setLastPetsEditedTime] = useState(null);
  const petsGridRef = useRef(null);

  // const togglePets = () => {
  //   setIsPetsVisible(!isPetsVisible);
  // };

  // const handleAddPet = () => {
  //   if (petsGridRef.current) {
  //     petsGridRef.current.addRecord();
  //   }
  // };

  // const actionBeginPets = (args) => {
  //   if (args.requestType === "beginEdit" || args.requestType === "add") {
  //     petsGridRef.current.columns.forEach((col) => {
  //       if (col.validationRules && col.validationRules.required) {
  //         // Append * to required field headers
  //         col.headerText = col.headerText.replace(/\*$/, "") + " *";
  //       }
  //     });
  //   }
  // };

  // const petsActionComplete = async (args) => {
  //   if (args.requestType === "save") {
  //     if (args.action === "add") {
  //       if (!selectedDisasters.length || selectedDisasters[0] === "All") {
  //         DialogUtility.alert({
  //           title: "Disaster Required",
  //           content: "Please select a specific disaster before adding a pet.",
  //           showCloseIcon: true,
  //           closeOnEscape: true,
  //           position: { X: "center", Y: "center" },
  //         });
  //         return;
  //       }

  //       const newPetData = {
  //         ...args.data,
  //         pet_id: generateUniqueID(),
  //         is_deleted: false,
  //         created_at: new Date(),
  //         updated_at: new Date(),
  //       };

  //       try {
  //         // Add to main household pets
  //         await citizenProfileService.addPet(householdId, newPetData);

  //         // Add to disaster-specific collection
  //         const disasterId = selectedDisasters[0];
  //         const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/pets`;
  //         const petsCollectionRef = collection(db, disasterHouseholdPath);
  //         await addDoc(petsCollectionRef, newPetData);
  //       } catch (error) {
  //         console.error("Error adding pet:", error);
  //       }
  //     } else if (args.action === "edit") {
  //       const disasterId = selectedDisasters[0];
  //       const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/pets`;
  //       const petRef = doc(db, disasterHouseholdPath, args.data.id);

  //       const updatedPetData = {
  //         ...args.data,
  //         is_deleted: false,
  //         updated_at: new Date(),
  //       };

  //       await updateDoc(petRef, updatedPetData);
  //     }

  //     setLastPetsEditedTime(new Date());

  //     // Refresh pets data from disaster-specific collection
  //     if (selectedDisasters.length === 1) {
  //       const disasterId = selectedDisasters[0];
  //       const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/pets`;
  //       const petsCollectionRef = collection(db, disasterHouseholdPath);
  //       const petsSnapshot = await getDocs(petsCollectionRef);

  //       const updatedPets = petsSnapshot.docs
  //         .map((doc) => ({ ...doc.data(), id: doc.id }))
  //         .filter((pet) => !pet.is_deleted);

  //       setPets(updatedPets);
  //     }
  //   } else if (args.requestType === "delete") {
  //     if (selectedDisasters.length === 1) {
  //       const disasterId = selectedDisasters[0];
  //       const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/pets`;
  //       const petRef = doc(db, disasterHouseholdPath, args.data[0].id);

  //       await updateDoc(petRef, {
  //         is_deleted: true,
  //         updated_at: new Date(),
  //       });
  //     }

  //     setLastPetsEditedTime(new Date());

  //     // Refresh pets data
  //     const disasterId = selectedDisasters[0];
  //     const disasterHouseholdPath = `users/${accessedUserId}/disaster_households/${disasterId}/pets`;
  //     const petsCollectionRef = collection(db, disasterHouseholdPath);
  //     const petsSnapshot = await getDocs(petsCollectionRef);

  //     const updatedPets = petsSnapshot.docs
  //       .map((doc) => ({ ...doc.data(), id: doc.id }))
  //       .filter((pet) => !pet.is_deleted);

  //     setPets(updatedPets);
  //   } else if (args.requestType === "beginEdit" || args.requestType === "add") {
  //     if (args.dialog) {
  //       const dialog = args.dialog;
  //       dialog.header =
  //         args.requestType === "beginEdit" ? "Edit Pet" : "Add New Pet";

  //       // Set the header styles
  //       if (dialog.element) {
  //         const headerContent = dialog.element.querySelector(
  //           ".e-dlg-header-content"
  //         );
  //         const header = dialog.element.querySelector(".e-dlg-header");
  //         if (headerContent) {
  //           headerContent.style.backgroundColor = "#348BFF";
  //           headerContent.style.color = "#FFFFFF";
  //         }
  //         if (header) {
  //           header.style.color = "#FFFFFF";
  //         }

  //         // Style the save and cancel buttons
  //         setTimeout(() => {
  //           const saveButton = dialog.element.querySelector(".e-primary");
  //           const cancelButton = dialog.element.querySelector(
  //             ".e-btn:not(.e-primary)"
  //           );

  //           if (saveButton) {
  //             saveButton.style.backgroundColor = "#FFFFFF";
  //             saveButton.style.color = "#348BFF";
  //             saveButton.style.border = "none";
  //           }

  //           if (cancelButton) {
  //             cancelButton.style.backgroundColor = "#FFFFFF";
  //             cancelButton.style.color = "#348BFF";
  //             cancelButton.style.border = "1px solid #348BFF";
  //           }
  //         }, 0);
  //       }
  //     }
  //   }
  // };

  // Filter pets to only include those with status true
  const filteredPets = useMemo(() => {
    return Array.isArray(pets)
      ? pets
          .filter((pet) => !pet.is_deleted)
          .filter((pet) => {
            // If pet has no disaster associations, include them
            if (
              !pet.associatedDisasters ||
              pet.associatedDisasters.length === 0
            ) {
              return true;
            }

            // If "All" is selected, show pets with any active disaster
            if (selectedDisasters.includes("All")) {
              return pet.associatedDisasters.some((disaster) =>
                activeDisasters.includes(disaster.disaster_id)
              );
            }

            // Show pets associated with selected disasters
            return pet.associatedDisasters.some((disaster) =>
              selectedDisasters.includes(disaster.disaster_id)
            );
          })
      : [];
  }, [selectedDisasters, pets, activeDisasters]);

  // console.log("filteredPets", filteredPets);
  // const [checkedItems, setCheckedItems] = useState({});

  const handleResponseChange = (question, response) => {
    // Check if the response is for the survey questions (object with answer/followUp)
    if (
      typeof response === "object" &&
      (response.answer !== undefined || response.followUp !== undefined)
    ) {
      setResponses((prevResponses) => {
        const newResponses = {
          ...prevResponses,
          [question]: response,
        };
        // Call handleSubmitSurvey after state update
        // setTimeout(() => {
        handleSubmitSurvey(newResponses);
        // }, 1000);
        return newResponses;
      });
    }
    // Handle user survey responses (direct values)
    else {
      setResponses((prevResponses) => {
        const newResponses = {
          ...prevResponses,
          [question]: response,
        };
        // Call handleSubmitSurvey after state update
        // setTimeout(() => {
        handleSubmitSurvey(newResponses);
        // }, 1000);
        return newResponses;
      });
    }
  };

  const generateCaseNumber = async () => {
    try {
      // Query for the document with type "CaseNo"
      const systemsCodeQuery = query(
        collection(db, "systems_code"),
        where("type", "==", "CaseNo")
      );
      const querySnapshot = await getDocs(systemsCodeQuery);

      if (!querySnapshot.empty) {
        const systemsCodeDoc = querySnapshot.docs[0]; // Get the first matching document

        // Get the current value and increment it
        const oldCaseNo = parseInt(systemsCodeDoc.data().value);
        const newCaseNo = oldCaseNo + 1;

        // Update the systems_code document with new value
        await updateDoc(doc(db, "systems_code", systemsCodeDoc.id), {
          value: newCaseNo.toString(),
        });

        return newCaseNo;
      } else {
        console.error("Case number document not found");
        throw new Error("Case number document not found");
      }
    } catch (error) {
      console.error("Error generating case number:", error);
      throw error;
    }
  };

  const [showCaseNumberPopup, setShowCaseNumberPopup] = useState(false);
  const [caseNumber, setCaseNumber] = useState("");
  const [disasters, setDisasters] = useState([]);

  // Fetch disasters
  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().disaster_name,
          status: doc.data().disaster_status,
          disaster_type: doc.data().disaster_type?.toLowerCase(),
          county: doc.data().disaster_county,
          state: doc.data().disaster_state,
          date: doc.data().disaster_date,
        }));

        setDisasters(fetchedDisasters);
        // console.log(fetchedDisasters, "fetchedDisasters");
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, []);

  // Fetch case number for current disaster
  useEffect(() => {
    const fetchCaseNumber = async () => {
      if (accessedUserId && selectedDisasters.length > 0) {
        const currentDisasterId =
          selectedDisasters.find((id) => id !== "All") || selectedDisasters[0];

        const surveyQuery = query(
          collection(db, "users/${accessedUserId}/survey_responses"),
          where("user_id", "==", accessedUserId),
          where("disaster_id", "==", currentDisasterId)
        );

        const querySnapshot = await getDocs(surveyQuery);
        querySnapshot.forEach((doc) => {
          if (doc.data().case_number) {
            setCaseNumber(doc.data().case_number);
          }
        });
      }
    };

    fetchCaseNumber();
  }, [accessedUserId, selectedDisasters]);

  const [existingSurveys, setExistingSurveys] = useState({});

  // const [currentDisasterType, setCurrentDisasterType] = useState(null);

  // console.log(currentDisasterType, "currentDisasterType");

  // Modify the effect that handles disaster selection to include type
  useEffect(() => {
    const fetchDisasterDetails = async () => {
      if (accessedUserId && selectedDisasters.length === 1) {
        const disasterId = selectedDisasters[0];
        const existingSurvey = existingSurveys[disasterId];

        // Find the selected disaster to get its type
        const selectedDisaster = disasters.find((d) => d.id === disasterId);
        // setCurrentDisasterType(selectedDisaster?.disaster_type || null);

        if (existingSurvey) {
          // If a survey exists for this disaster, load its responses
          setResponses(existingSurvey.responses);
          setCaseNumber(existingSurvey.case_number);
        } else {
          // Clear form for new survey
          setResponses({});
          setCaseNumber("");
        }
      } else {
        // Clear form when multiple disasters are selected or none
        setResponses({});
        setCaseNumber("");
        // setCurrentDisasterType(null);
      }
    };

    fetchDisasterDetails();
  }, [accessedUserId, selectedDisasters, existingSurveys, disasters]);

  // Add helper function to get appropriate questions

  // Modify the fetchUserSurveyResponses to include disaster type
  const fetchUserSurveyResponses = async (accessedUserId) => {
    try {
      const surveysRef = collection(
        db,
        `users/${accessedUserId}/survey_responses`
      );
      const querySnapshot = await getDocs(surveysRef);

      const surveys = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.disasters.forEach((disaster) => {
          surveys[disaster.disaster_id] = {
            responses: data.responses,
            case_number: data.case_number,
            submitted_at: data.submitted_at?.toDate() || new Date(),
            survey_id: doc.id,
            disaster_type: disaster.disaster_type, // Include disaster type
          };
        });
      });

      setExistingSurveys(surveys);
      return surveys;
    } catch (error) {
      console.error("Error fetching survey responses:", error);
      return {};
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isEditMode || isHouseholdEditMode) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
        return e.returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditMode, isHouseholdEditMode]);

  // Update the handleSubmitSurvey function to include disaster type
  const handleSubmitSurvey = useCallback(
    async (newResponses) => {
      if (!newResponses) return;

      // event.preventDefault();

      const selectedDisasterIds = selectedDisasters.includes("All")
        ? disasters.map((d) => d.id)
        : selectedDisasters.filter((id) => id !== "All");

      if (selectedDisasterIds.length > 1) {
        alert("Please select only one disaster per survey submission.");
        return;
      }

      if (selectedDisasterIds.length === 0) {
        alert("Please select a disaster before submitting the survey.");
        return;
      }

      let unansweredQuestions = [];

      // Validate standard survey questions
      surveyQuestions.forEach((section) => {
        section.questions.forEach((q) => {
          const response = newResponses?.[q.question];

          if (!response?.answer) {
            unansweredQuestions.push(q.question);
          } else if (
            q.followUp &&
            response.answer === q.followUp.condition &&
            !response.followUp
          ) {
            unansweredQuestions.push(`${q.question} - ${q.followUp.question}`);
          }
        });
      });

      // Validate fire questions only if disaster type is fire
      if (currentDisasterType === "fire") {
        fireQuestions.forEach((section) => {
          section.questions.forEach((q) => {
            const response = newResponses?.[q.question];

            if (!response?.answer) {
              unansweredQuestions.push(q.question);
            } else if (
              q.followUp &&
              response.answer === q.followUp.condition &&
              !response.followUp
            ) {
              unansweredQuestions.push(
                `${q.question} - ${q.followUp.question}`
              );
            }
          });
        });
      }

      if (currentDisasterType === "tornado") {
        tornadoQuestions.forEach((section) => {
          section.questions.forEach((q) => {
            const response = newResponses?.[q.question];

            if (!response?.answer) {
              unansweredQuestions.push(q.question);
            } else if (
              q.followUp &&
              response.answer === q.followUp.condition &&
              !response.followUp
            ) {
              unansweredQuestions.push(
                `${q.question} - ${q.followUp.question}`
              );
            }
          });
        });
      }

      if (currentDisasterType === "flood") {
        floodQuestions.forEach((section) => {
          section.questions.forEach((q) => {
            const response = newResponses?.[q.question];

            if (!response?.answer) {
              unansweredQuestions.push(q.question);
            } else if (
              q.followUp &&
              response.answer === q.followUp.condition &&
              !response.followUp
            ) {
              unansweredQuestions.push(
                `${q.question} - ${q.followUp.question}`
              );
            }
          });
        });
      }

      if (currentDisasterType === "hurricane") {
        hurricaneQuestions.forEach((section) => {
          section.questions.forEach((q) => {
            const response = newResponses?.[q.question];

            if (!response?.answer) {
              unansweredQuestions.push(q.question);
            } else if (
              q.followUp &&
              response.answer === q.followUp.condition &&
              !response.followUp
            ) {
              unansweredQuestions.push(
                `${q.question} - ${q.followUp.question}`
              );
            }
          });
        });
      }

      // if (unansweredQuestions.length > 0) {
      //   alert(
      //     `Please answer the following questions:\n\n${unansweredQuestions.join(
      //       "\n"
      //     )}`
      //   );
      //   return;
      // }

      try {
        const disasterId = selectedDisasterIds[0];
        const selectedDisaster = disasters.find((d) => d.id === disasterId);
        const existingSurvey = existingSurveys[disasterId];

        // Transform responses to include follow-ups
        const transformedResponses = {};
        Object.entries(newResponses)?.forEach(([question, response]) => {
          if (response?.answer) {
            transformedResponses[question] = {
              answer: response.answer,
              ...(response.followUp && { followUp: response.followUp }),
            };
          }
        });

        let surveyData;
        let docRef;

        if (existingSurvey) {
          // Update existing survey
          surveyData = {
            user_id: accessedUserId,
            disasters: [
              {
                disaster_id: disasterId,
                disaster_name: selectedDisaster?.name || "",
                disaster_type: selectedDisaster?.disaster_type || "",
              },
            ],
            case_number: existingSurvey.case_number,
            updated_at: serverTimestamp(),
            responses: transformedResponses,
          };
          docRef = doc(
            db,
            `users/${accessedUserId}/survey_responses`,
            existingSurvey.survey_id
          );

          // console.log("responses transform", transformedResponses);

          setExistingSurveys((prev) => ({
            ...prev,
            [disasterId]: {
              ...prev[disasterId],
              responses: transformedResponses,
              updated_at: new Date(),
            },
          }));

          setCaseNumber(existingSurvey.case_number);
          parentSetCaseNumber(existingSurvey.case_number);
        } else {
          // Create new survey
          const newCaseNumber = await generateCaseNumber();
          const surveyId = uuidv4();
          surveyData = {
            user_id: accessedUserId,
            disasters: [
              {
                disaster_id: disasterId,
                disaster_name: selectedDisaster?.name || "",
                disaster_type: selectedDisaster?.disaster_type || "",
              },
            ],
            survey_id: surveyId,
            case_number: newCaseNumber,
            submitted_at: serverTimestamp(),
            responses: transformedResponses,
          };
          docRef = doc(
            db,
            `users/${accessedUserId}/survey_responses/${surveyId}`
          );

          setExistingSurveys((prev) => ({
            ...prev,
            [disasterId]: {
              responses: transformedResponses,
              case_number: newCaseNumber,
              submitted_at: new Date(),
              survey_id: surveyData.survey_id,
              disaster_type: selectedDisaster?.disaster_type,
            },
          }));

          setCaseNumber(newCaseNumber);
          parentSetCaseNumber(newCaseNumber);
        }

        await setDoc(docRef, surveyData);
        // setShowCaseNumberPopup(true);
      } catch (error) {
        console.error("Error submitting survey:", error);
        // alert(`Error submitting survey: ${error.message}`);
      }
    },
    [
      accessedUserId,
      currentDisasterType,
      disasters,
      existingSurveys,
      parentSetCaseNumber,
      selectedDisasters,
    ]
  );

  // useEffect(() => {
  //   if (
  //     responses &&
  //     Object.values(responses).every(
  //       (response) =>
  //         response !== null && response !== undefined && response !== ""
  //     )
  //   ) {
  //     handleSubmitSurvey();
  //   }
  // }, [responses, handleSubmitSurvey]);

  // Add effect to handle disaster selection changes
  // useEffect(() => {
  //   if (selectedDisasters.length === 1) {
  //     const selectedDisasterId = selectedDisasters[0];
  //     const selectedDisaster = activeDisasters.find(
  //       (d) => d.id === selectedDisasterId
  //     );
  //     setCurrentDisasterType(selectedDisaster?.disaster_type || null);
  //   } else {
  //     setCurrentDisasterType(null);
  //   }
  // }, [selectedDisasters, activeDisasters]);

  // Modify the getQuestionsForDisasterType function to always return an array
  // const getQuestionsForDisasterType = () => {
  //   console.log("Current disaster type:", currentDisasterType);
  //   if (currentDisasterType === "fire") {
  //     // Exact match for "fire"
  //     return fireQuestions;
  //   }
  //   return surveyQuestions;
  // };

  // const renderSurveyStatus = () => {
  //   const selectedDisasterIds = selectedDisasters.filter((id) => id !== "All");

  //   if (selectedDisasterIds.length > 1) {
  //     return (
  //       <div className="text-xs font-semibold text-red-600">
  //         Please select only one disaster per survey
  //       </div>
  //     );
  //   }

  //   if (selectedDisasterIds.length === 1) {
  //     const disasterId = selectedDisasterIds[0];
  //     const existingSurvey = existingSurveys[disasterId];
  //     const disaster = disasters.find((d) => d.id === disasterId);

  //     if (existingSurvey) {
  //       return (
  //         <div className="text-gray-600 text-xs">
  //           <div className="font-semibold text-blue-600">
  //             Existing survey found for {disaster?.name}
  //           </div>
  //           Case #: {existingSurvey.case_number}
  //           <br />
  //           Submitted:{" "}
  //           {existingSurvey.submitted_at instanceof Date
  //             ? existingSurvey.submitted_at.toLocaleDateString()
  //             : new Date(existingSurvey.submitted_at).toLocaleDateString()}
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div className="text-gray-600 text-xs">
  //           No existing survey found for {disaster?.name}
  //         </div>
  //       );
  //     }
  //   }

  //   return (
  //     <div className="text-gray-600 text-xs">
  //       Please select a disaster to submit a survey
  //     </div>
  //   );
  // };

  // const closeCaseNumberPopup = () => {
  //   setShowCaseNumberPopup(false);
  // };

  // const toggleMenu = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   if (activeMenuAlternateContact) {
  //     setActiveMenuAlternateContact(false);
  //   } else {
  //     setActiveMenuAlternateContact(true);
  //   }
  // };

  const CaseNumberModal = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md rounded-lg bg-white p-8">
        <h2 className="mb-4 text-center text-xl font-bold">
          {existingSurveys[selectedDisasters[0]]
            ? "Survey Updated Successfully"
            : "Survey Submitted Successfully"}
        </h2>
        <p className="mb-4 text-center">Thank you for your responses.</p>
        <p className="text-lg mb-4 text-center font-semibold">
          Your case number is:
        </p>
        <p className="mb-6 text-center text-2xl font-bold text-blue-600">
          {caseNumber}
        </p>
        <div className="text-center">
          <button
            onClick={() => setShowCaseNumberPopup(false)}
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );

  // const AlternateContactModal = () => (

  // );
  // ALTERNATE CONTACT HANDLER
  const {
    register: alternateContactRegister,
    handleSubmit: alternateContactHandleSubmit,
    formState: { errors: alternateContactErrors },
    reset: alternateContactReset,
  } = useForm({
    defaultValues: {
      alternate_contact_name: data?.alternate_contact_name || "",
      alternate_email: data?.alternate_email || "",
      alternate_mobile_number: data?.alternate_mobile_number || "",
    },
  });

  const onSubmitAlternateContact = async (values) => {
    const disasterId = selectedDisasters[0];
    // UPDATE THE USER COLLECTION
    const updatedData = {
      alternate_contact_name: values.alternate_contact_name || "",
      alternate_email: values.alternate_email || "",
      alternate_mobile_number: values.alternate_mobile_number || "",
      updated_at: serverTimestamp(),
    };
    setIsSubmittingAlternateContact(true);
    await citizenProfileService.updateCitizenProfile(
      "users",
      accessedUserId,
      updatedData
    );

    const impactedAddressRef = doc(
      db,
      `users/${accessedUserId}/impacted-addresses`,
      disasterId
    );
    const impactedAddressRefData = await getDoc(impactedAddressRef);
    if (impactedAddressRefData.exists()) {
      await updateDoc(impactedAddressRef, updatedData);
    } else {
      await setDoc(impactedAddressRef, updatedData);
    }

    const impactedAddressDoc = await getDoc(impactedAddressRef);
    if (impactedAddressDoc.exists()) {
      const addressData = impactedAddressDoc.data();
      setData((prevData) => ({
        ...prevData,
        ...addressData,
        // Always use these fields from the user profile
        //  alternate_contact_name: citizenData.alternate_contact_name,
        //  alternate_email: citizenData.alternate_email,
        //  alternate_mobile_number: citizenData.alternate_mobile_number,
        //  disability: citizenData.disability,
        //  military_vet: citizenData.military_vet,
        //  is_displaced: citizenData.is_displaced,
      }));
      // setTempFormData({
      //   ...addressData,
      // });
    }
    setShowAlternateContactModal(false);
    setActiveMenuAlternateContact(false);
    alternateContactReset();
    setIsSubmittingAlternateContact(false);
  };
  // HOUSEHOLD INFO HANDLER
  const {
    register: householdInfoRegister,
    handleSubmit: householdInfoHandleSubmit,
    formState: { errors: householdInfoErrors },
    reset: householdInfoReset,
    control: householdInfoControl,
    watch: householdInfoWatch,
  } = useForm({
    defaultValues: {},
  });

  const onSubmitHouseholdInfo = async (values) => {
    const disasterId = selectedDisasters[0];
    // UPDATE THE USER COLLECTION
    const updatedData = {
      ...values,
      home_insurance_type: values.home_insurance
        ? values.home_insurance_type
        : "",
      automotive_insurance_type: values.automotive_insurance
        ? values.automotive_insurance_type
        : "",
    };
    setIsSubmittingHouseholdInfo(true);

    const householdInfoRef = doc(
      db,
      `household/${data?.household_id}/household_info/details`
    );
    const householdInfoRefData = await getDoc(householdInfoRef);

    if (householdInfoRefData.exists()) {
      await updateDoc(householdInfoRef, {
        ...updatedData,
        updated_at: serverTimestamp(),
      });
    } else {
      await setDoc(householdInfoRef, {
        ...updatedData,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });
    }

    const disasterHouseholdInfoRef = doc(
      db,
      `users/${accessedUserId}/disaster_households/${disasterId}/household_info/details`
    );

    const disasterHouseholdInfoRefData = await getDoc(disasterHouseholdInfoRef);
    if (disasterHouseholdInfoRefData.exists()) {
      await updateDoc(disasterHouseholdInfoRef, {
        ...updatedData,
        updated_at: serverTimestamp(),
      });
    } else {
      await setDoc(disasterHouseholdInfoRef, {
        ...updatedData,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });
    }

    const disasterHouseholdInfoRefDataLatest = await getDoc(
      disasterHouseholdInfoRef
    );

    if (disasterHouseholdInfoRefDataLatest.exists()) {
      const latestHouseholdDetails = disasterHouseholdInfoRefDataLatest.data();
      setTempHouseholdFormData((prevData) => ({
        ...prevData,
        ...latestHouseholdDetails,
      }));
    }

    setShowHouseholdInfoModal(false);
    setActiveMenuHouseholdInfo(false);
    householdInfoReset();
    setIsSubmittingHouseholdInfo(false);
  };

  // HOUSEHOLD MEMBERS HANDLER
  const {
    register: householdMemberRegister,
    handleSubmit: householdMemberHandleSubmit,
    formState: { errors: householdMemberErrors },
    reset: householdMemberReset,
    setError,
    clearErrors,
    control: householdMemberControl,
  } = useForm({
    defaultValues: {},
  });

  const onSubmitHouseholdMember = async (values) => {
    const disasterId = selectedDisasters[0];
    if (!isValidDate(values.date_of_birth)) {
      setError("date_of_birth", { message: "Invalid date." });
      return;
    }
    // UPDATE THE USER COLLECTION
    const { id, ...rest } = values;
    const updatedData = {
      ...rest,
      date_of_birth: values.date_of_birth.toLowerCase()
        ? values.date_of_birth.toLowerCase() == "invalid date"
          ? ""
          : values.date_of_birth
        : "",
      email: values.email ? values.email.toLowerCase() : "",
    };
    setIsSubmittingHouseholdMember(true);

    if (isEditingHouseholdMember) {
      const householdMemberRef = doc(
        db,
        `household/${data?.household_id}/household_members/${id}`
      );
      const householdMemberRefData = await getDoc(householdMemberRef);

      if (householdMemberRefData.exists()) {
        await updateDoc(householdMemberRef, {
          ...updatedData,
          updated_at: serverTimestamp(),
        });
      } else {
        await setDoc(householdMemberRef, {
          ...updatedData,
          is_deleted: false,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        });
      }

      const householdMemberDisasterRef = doc(
        db,
        `users/${accessedUserId}/disaster_households/${disasterId}/household_members/${id}`
      );

      const householdMemberDisasterRefData = await getDoc(
        householdMemberDisasterRef
      );

      if (householdMemberDisasterRefData.exists()) {
        const { is_deleted, ...rest } = householdMemberDisasterRefData.data();
        await updateDoc(householdMemberDisasterRef, {
          ...rest,
          ...updatedData,
          is_deleted: is_deleted || false,
          updated_at: serverTimestamp(),
        });
      } else {
        await setDoc(householdMemberDisasterRef, {
          ...updatedData,
          is_deleted: false,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        });
      }
    } else {
      const formattedData = {
        ...updatedData,
        email: updatedData.email.toLowerCase(),
      };
      const isDuplicate = await checkForDuplicates(formattedData);
      if (isDuplicate) {
        DialogUtility.alert({
          title: "Duplicate Entry",
          content: formattedData.email
            ? "A household member with this email already exists."
            : "A household member with this name already exists.",
          showCloseIcon: true,
          closeOnEscape: true,
          position: { X: "center", Y: "center" },
        });
        setIsSubmittingHouseholdMember(false);

        return;
      }
      if (formattedData.email) {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", formattedData.email));
        const querySnapshot = await getDocs(q);

        if (
          !querySnapshot.empty &&
          querySnapshot.docs[0].data().email !== data.email
        ) {
          const userData = querySnapshot.docs[0].data();

          if (userData.household_id && userData.household_id !== householdId) {
            DialogUtility.alert({
              title: "User Already in Household",
              content: "This user is already part of another household.",
              showCloseIcon: true,
              closeOnEscape: true,
              position: { X: "center", Y: "center" },
            });
            setIsSubmittingHouseholdMember(false);
            return;
          }
        }
      }

      // PROCEED TO ADD THE HOUSEHOLD MEMBER
      const household_member_id = uuidv4();
      const householdMemberPath = doc(
        db,
        `household/${data?.household_id}/household_members/${household_member_id}`
      );
      await setDoc(householdMemberPath, {
        ...formattedData,
        household_member_id,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        is_deleted: false,
      });

      const householdMemberDisasterRef = doc(
        db,
        `users/${accessedUserId}/disaster_households/${disasterId}/household_members/${household_member_id}`
      );

      await setDoc(householdMemberDisasterRef, {
        ...formattedData,
        household_member_id,
        is_deleted: false,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });
    }

    await fetchHouseholdMembers();

    setActiveMenuHouseholdMember(false);
    householdMemberReset();
    setIsSubmittingHouseholdMember(false);
    setIsEditingHouseholdMember(false);
    setShowHouseholdMemberModal(false);
    // setSelectedHouseholdMember(null);
  };

  const {
    register: petsRegister,
    handleSubmit: petsHandleSubmit,
    formState: { errors: petsErrors },
    reset: petsReset,
    control: petsControl,
    setValue: petsSetValue,
    watch: petsWatch,
  } = useForm({
    defaultValues: {},
  });
  const petsValues = petsWatch();
  const onSubmitPets = async (values) => {
    const disasterId = selectedDisasters[0];
    // UPDATE THE USER COLLECTION
    const { id, is_deleted, ...rest } = values;
    const updatedData = {
      ...rest,
    };
    setIsSubmittingPets(true);

    // console.log("values", values);

    if (isEditingPets) {
      const householdPetRef = doc(
        db,
        `household/${data?.household_id}/pets/${id}`
      );
      const householdPetRefData = await getDoc(householdPetRef);

      if (householdPetRefData.exists()) {
        await updateDoc(householdPetRef, {
          ...updatedData,
          updated_at: serverTimestamp(),
        });
      } else {
        await setDoc(householdPetRef, {
          ...updatedData,
          is_deleted: false,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        });
      }

      const householdPetDisasterRef = doc(
        db,
        `users/${accessedUserId}/disaster_households/${disasterId}/pets/${id}`
      );

      const householdPetDisasterRefData = await getDoc(householdPetDisasterRef);

      if (householdPetDisasterRefData.exists()) {
        const { is_deleted, ...rest } = householdPetDisasterRefData.data();
        await updateDoc(householdPetDisasterRef, {
          ...rest,
          ...updatedData,
          is_deleted: is_deleted || false,
          updated_at: serverTimestamp(),
        });
      } else {
        await setDoc(householdPetDisasterRef, {
          ...updatedData,
          is_deleted: false,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        });
      }
    } else {
      const formattedData = {
        ...updatedData,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        is_deleted: false,
      };
      // PROCEED TO ADD THE HOUSEHOLD MEMBER
      const householdPetId = uuidv4();
      const householdPetPath = doc(
        db,
        `household/${data?.household_id}/pets/${householdPetId}`
      );
      await setDoc(householdPetPath, {
        ...formattedData,
      });

      const householdPetDisasterRef = doc(
        db,
        `users/${accessedUserId}/disaster_households/${disasterId}/pets/${householdPetId}`
      );

      await setDoc(householdPetDisasterRef, {
        ...formattedData,
      });
    }

    await fetchPets();

    setActiveMenuPets(false);
    petsReset();
    setIsSubmittingPets(false);
    setIsEditingPets(false);
    setShowPetsModal(false);
  };

  const {
    register: impactedAddressRegister,
    handleSubmit: impactedAddressHandleSubmit,
    formState: { errors: impactedAddressErrors },
    setValue: impactedAddressSetValue,
  } = useForm({
    defaultValues: { option: data?.impacted_address_id || "" },
    mode: "onChange",
  });

  const onSubmitImpactedAddress = (data) => {
    // console.log("Selected Option:", data.option);
  };

  const {
    register: displacedAddressRegister,
    handleSubmit: displacedAddressHandleSubmit,
    formState: { errors: displacedAddressErrors },
    setValue: displacedAddressSetValue,
  } = useForm({
    defaultValues: { option: data?.d_address_id || "" },
    mode: "onChange",
  });

  useEffect(() => {
    if (data?.d_address_id) {
      displacedAddressSetValue("option", data?.d_address_id);
    }
    if (data?.impacted_address_id) {
      impactedAddressSetValue("option", data?.impacted_address_id);
    }
  }, [
    data?.d_address_id,
    data?.impacted_address_id,
    displacedAddressSetValue,
    impactedAddressSetValue,
  ]);

  const onSubmitDisplacedAddress = (data) => {
    // console.log("Selected Option:", data.option);
  };

  const {
    register: addressRegister,
    handleSubmit: addressHandleSubmit,
    formState: { errors: addressErrors },
    reset: addressReset,
    watch: addressWatch,
  } = useForm({
    defaultValues: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      id: "",
      type: "",
      address_type: "",
      address_condition: "",
      subsidized_by: "",
      share_housing_expenses: "",
      share_housing_expenses_followup: "",
      clear_title: "",
      clear_title_followup: "",
      mortgage: "",
      mortgage_followup: 0,
      payments_current: "",
      payments_current_followup: 0,
      property_taxes_current: "",
      property_taxes_current_followup: 0,
    },
    mode: "onChange",
  });

  const onSubmitAddress = async (val) => {
    const { id: collectionId, ...rest } = val;
    const formData = {
      ...rest,
    };
    setIsSubmittingAddress(true);
    const userAddressId = data?.address_id;
    if (userAddressId) {
      if (isEditingAddress) {
        const addressRef = doc(
          db,
          `address/${userAddressId}/addresses/${collectionId}`
        );
        const addressRefData = await getDoc(addressRef);
        if (addressRefData.exists()) {
          await updateDoc(addressRef, {
            ...formData,
            updated_at: serverTimestamp(),
          });
        } else {
          await setDoc(addressRef, {
            ...formData,
            is_deleted: false,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          });
        }
        await fetchAddresses();
      } else {
        // const newCollectionId = uuidv4();
        const addressCollectionRef = collection(
          db,
          `address/${userAddressId}/addresses`
        );
        await addDoc(addressCollectionRef, {
          ...formData,
          is_deleted: false,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        });
        await fetchAddresses();
      }
    }
    setIsSubmittingAddress(false);
    addressReset();
    setIsEditingAddress(false);
    setShowAddressModal(false);
  };

  const fetchUser = useCallback(async () => {
    const userId = accessedUserId;
    if (userId) {
      const userRef = doc(db, `users/${userId}`);
      const userRefData = await getDoc(userRef);
      if (userRefData.exists()) {
        const {
          d_address_id,
          d_address_1,
          d_address_2,
          d_city,
          d_state,
          d_zip,
          d_county,
        } = userRefData.data();
        setData((prev) => ({
          ...prev,
          d_address_id,
          d_address_1,
          d_address_2,
          d_city,
          d_state,
          d_zip,
          d_county,
        }));
      }
    }
  }, [accessedUserId]);

  const onUpdateDisplacedAddress = useCallback(
    async (val) => {
      const userId = accessedUserId;
      if (userId) {
        const userRef = doc(db, `users/${userId}`);
        const userRefData = await getDoc(userRef);
        if (userRefData.exists()) {
          const findAddressData = addresses?.filter(
            (address) => address?.id === val
          );
          if (findAddressData?.length > 0) {
            await updateDoc(userRef, {
              d_address_1: findAddressData[0]?.address1,
              d_address_2: findAddressData[0]?.address2,
              d_city: findAddressData[0]?.city,
              d_state: findAddressData[0]?.state,
              d_zip: findAddressData[0]?.zip,
              d_county: findAddressData[0]?.county || "",
              d_address_id: val,
              updated_at: serverTimestamp(),
            });
            await fetchUser();
          }
        }
      }
    },
    [accessedUserId, addresses, fetchUser]
  );

  const fetchImpactedAddress = useCallback(async () => {
    const disasterId = selectedDisasters[0];
    const userId = accessedUserId;
    if (userId && disasterId) {
      const impactedAddressRef = doc(
        db,
        `users/${userId}/impacted-addresses/${disasterId}`
      );
      const impactedAddressRefData = await getDoc(impactedAddressRef);
      if (impactedAddressRefData.exists()) {
        const {
          address1,
          address2,
          city,
          state,
          zip,
          county,
          impacted_address_id,
          alternate_contact_name,
          alternate_email,
          alternate_mobile_number,
          address_type,
          address_condition,
          subsidized_by,
          share_housing_expenses,
          share_housing_expenses_followup,
          clear_title,
          clear_title_followup,
          mortgage,
          mortgage_followup,
          payments_current,
          payments_current_followup,
          property_taxes_current,
          property_taxes_current_followup,
        } = impactedAddressRefData.data();
        setData((prev) => ({
          ...prev,
          address1,
          address2,
          city,
          state,
          zip,
          county,
          impacted_address_id: impacted_address_id || "",
          alternate_contact_name: alternate_contact_name || "",
          alternate_email: alternate_email || "",
          alternate_mobile_number: alternate_mobile_number || "",
          address_type,
          address_condition,
          subsidized_by,
          share_housing_expenses,
          share_housing_expenses_followup,
          clear_title,
          clear_title_followup,
          mortgage,
          mortgage_followup,
          payments_current,
          payments_current_followup,
          property_taxes_current,
          property_taxes_current_followup,
        }));
      } else {
        const findPrimaryAddressRef = query(
          collection(db, `address/${data?.address_id}/addresses`),
          where("is_primary_address", "==", true)
        );
        const findPrimaryAddressRefData = await getDocs(findPrimaryAddressRef);
        const primaryAddressData = findPrimaryAddressRefData.docs?.map(
          (doc) => ({
            ...doc.data(),
            id: doc.id,
          })
        );
        const primaryAddress = primaryAddressData[0];
        const citizenData = await citizenProfileService.getCitizenProfile(
          "users",
          accessedUserId
        );

        if (primaryAddress) {
          const addedImpactedAddressObj = {
            address1: primaryAddress?.address1,
            address2: primaryAddress?.address2,
            city: primaryAddress?.city,
            state: primaryAddress?.state,
            zip: primaryAddress?.zip,
            county: primaryAddress?.county || "",
            impacted_address_id: primaryAddress?.id,
            alternate_contact_name: citizenData?.alternate_contact_name || "",
            alternate_email: citizenData?.alternate_email || "",
            alternate_mobile_number: citizenData?.alternate_mobile_number || "",
          };
          await setDoc(impactedAddressRef, {
            ...addedImpactedAddressObj,
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            is_deleted: false,
          });
          await fetchImpactedAddress();
        }
      }
    }
  }, [accessedUserId, selectedDisasters, data?.address_id]);

  const onUpdateImpactedAddress = useCallback(
    async (val) => {
      const disasterId = selectedDisasters[0];
      const userId = accessedUserId;
      if (userId) {
        const impactedAddressRef = doc(
          db,
          `users/${userId}/impacted-addresses/${disasterId}`
        );
        const impactedAddressRefData = await getDoc(impactedAddressRef);
        const findAddressData = addresses?.filter(
          (address) => address?.id === val
        );
        if (impactedAddressRefData.exists()) {
          if (findAddressData?.length > 0) {
            await updateDoc(impactedAddressRef, {
              address1: findAddressData[0]?.address1,
              address2: findAddressData[0]?.address2,
              city: findAddressData[0]?.city,
              state: findAddressData[0]?.state,
              zip: findAddressData[0]?.zip,
              county: findAddressData[0]?.county || "",
              impacted_address_id: val,
              updated_at: serverTimestamp(),
              address_type: findAddressData.address_type,
              address_condition: findAddressData.address_condition,
              subsidized_by: findAddressData.subsidized_by,
              share_housing_expenses: findAddressData.share_housing_expenses,
              share_housing_expenses_followup:
                findAddressData.share_housing_expenses_followup,
              clear_title: findAddressData.clear_title,
              clear_title_followup: findAddressData.clear_title_followup,
              mortgage: findAddressData.mortgage,
              mortgage_followup: findAddressData.mortgage_followup,
              payments_current: findAddressData.payments_current,
              payments_current_followup:
                findAddressData.payments_current_followup,
              property_taxes_current: findAddressData.property_taxes_current,
              property_taxes_current_followup:
                findAddressData.property_taxes_current_followup,
            });

            await fetchImpactedAddress();
          }
        } else {
          if (findAddressData?.length > 0) {
            await setDoc(impactedAddressRef, {
              address1: findAddressData[0]?.address1,
              address2: findAddressData[0]?.address2,
              city: findAddressData[0]?.city,
              state: findAddressData[0]?.state,
              zip: findAddressData[0]?.zip,
              county: findAddressData[0]?.county || "",
              impacted_address_id: val,
              created_at: serverTimestamp(),
              updated_at: serverTimestamp(),
              is_deleted: false,
              address_type: findAddressData.address_type,
              address_condition: findAddressData.address_condition,
              subsidized_by: findAddressData.subsidized_by,
              share_housing_expenses: findAddressData.share_housing_expenses,
              share_housing_expenses_followup:
                findAddressData.share_housing_expenses_followup,
              clear_title: findAddressData.clear_title,
              clear_title_followup: findAddressData.clear_title_followup,
              mortgage: findAddressData.mortgage,
              mortgage_followup: findAddressData.mortgage_followup,
              payments_current: findAddressData.payments_current,
              payments_current_followup:
                findAddressData.payments_current_followup,
              property_taxes_current: findAddressData.property_taxes_current,
              property_taxes_current_followup:
                findAddressData.property_taxes_current_followup,
            });
          }
        }
      }
    },
    [accessedUserId, addresses, fetchImpactedAddress, selectedDisasters]
  );

  const onOwnOtherPropertiesChange = async ({ checked }) => {
    await citizenProfileService.toggleOwnOtherProperties(
      accessedUserId,
      checked
    );
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      accessedUserId
    );
    setData((prev) => ({
      ...prev,
      ...citizenData,
    }));
  };

  // const {
  //   register: sampleUploadRegister,
  //   handleSubmit: sampleUploadHandleSubmit,
  //   formState: { errors: sampleUploadErrors },
  //   reset: sampleUploadReset,
  //   control: sampleUploadControl,
  // } = useForm({
  //   defaultValues: {},
  // });

  // const onSubmitSampleUpload = (data) => {
  //   console.log("data", data);
  // };

  // const sampleUploadFormRef = useRef(null);

  const handlePetPhotosCollectionUpdate = useCallback(
    async (newFile) => {
      const disasterId = selectedDisasters[0];
      const householdPetRef = doc(
        db,
        `household/${data?.household_id}/pets/${selectedPetId}`
      );
      const householdPetDisasterRef = doc(
        db,
        `users/${accessedUserId}/disaster_households/${disasterId}/pets/${selectedPetId}`
      );
      const householdPetRefData = await getDoc(householdPetRef);
      const householdPetDisasterRefData = await getDoc(householdPetDisasterRef);

      if (householdPetDisasterRefData.exists()) {
        if (householdPetRefData.exists()) {
          const existingPetImages = householdPetRefData.data()?.pet_image || [];
          existingPetImages.push(newFile);
          const householdPetNewData = {
            pet_image: existingPetImages,
          };
          await updateDoc(householdPetRef, householdPetNewData);
          // console.log("householdPetRefData exists");
        }
        const existingPetImages =
          householdPetDisasterRefData.data()?.pet_image || [];
        existingPetImages.push(newFile);
        const householdPetNewData = {
          pet_image: existingPetImages,
        };
        await updateDoc(householdPetDisasterRef, householdPetNewData);
        // console.log("householdPetDisasterRefData exists");
      }
      await fetchPets();
    },
    [
      accessedUserId,
      data?.household_id,
      selectedDisasters,
      selectedPetId,
      fetchPets,
    ]
  );
  const onClosePetPhotosModal = () => {
    setShowPetPhotosModal(false);
    setSelectedPetId(null);
    setSelectedPet({});
  };
  const handlePetPhotosCollectionDelete = useCallback(
    async (newData) => {
      setIsSubmittingPets(true);
      setSelectedPet({
        pet_image: newData,
      });
      const disasterId = selectedDisasters[0];
      const householdPetRef = doc(
        db,
        `household/${data?.household_id}/pets/${selectedPetId}`
      );
      const householdPetDisasterRef = doc(
        db,
        `users/${accessedUserId}/disaster_households/${disasterId}/pets/${selectedPetId}`
      );
      const householdPetRefData = await getDoc(householdPetRef);
      const householdPetDisasterRefData = await getDoc(householdPetDisasterRef);
      if (householdPetRefData.exists()) {
        await updateDoc(householdPetRef, {
          pet_image: newData,
          updated_at: serverTimestamp(),
        });
      }
      if (householdPetDisasterRefData.exists()) {
        await updateDoc(householdPetDisasterRef, {
          pet_image: newData,
          updated_at: serverTimestamp(),
        });
      }
      await fetchPets();
      setIsSubmittingPets(false);
    },
    [
      selectedDisasters,
      data?.household_id,
      selectedPetId,
      accessedUserId,
      fetchPets,
    ]
  );

  const handleEditFilePetPhotos = useCallback(
    (newData) => {
      setIsEditingPets(true);
      setShowPetsModal(true);
      setActiveMenuPets(null);
      const latestPetData = pets?.filter((pet) => pet?.id === selectedPetId)[0];
      petsReset({
        ...latestPetData,
        pet_image: newData,
      });
    },
    [pets, petsReset, selectedPetId]
  );

  const renderPetModal = useMemo(() => {
    return (
      <ViewPhotosModal
        isOpen={showPetPhotosModal}
        onClose={() => {
          onClosePetPhotosModal();
        }}
        photosData={selectedPet?.pet_image || []}
        storagePath={`household/pets/${accessedUserId}`}
        handleCollectionUpdate={handlePetPhotosCollectionUpdate}
        dateKey="date_inserted"
        onEditClick={(newData) => {
          // console.log("edit clicked");
          onClosePetPhotosModal();
          handleEditFilePetPhotos(newData);
        }}
        isLoadingDelete={isSubmittingPets}
        handleDelete={handlePetPhotosCollectionDelete}
      />
    );
  }, [
    showPetPhotosModal,
    selectedPet,
    accessedUserId,
    handlePetPhotosCollectionUpdate,
    isSubmittingPets,
    handlePetPhotosCollectionDelete,
    handleEditFilePetPhotos,
  ]);

  useEffect(() => {
    if (selectedDisasters.length === 1) {
      fetchHouseholdMembers();
      fetchPets();
      fetchImpactedAddress();
      fetchAddresses();
    }
  }, [selectedDisasters]);

  return (
    <>
      {
        // <ViewPhotosModal
        //   isOpen={true}
        //   photosData={[
        //     {
        //       src: "https://firebasestorage.googleapis.com/v0/b/blue-sky-dev-7fed9.appspot.com/o/lockbox%2Fdocuments%2FqGhp3mszZNKXiLUUgDB3%2Fwarning-icon.png?alt=media&token=b110f54e-b532-4828-84e3-f1d1e505f515",
        //       details: "This is a details",
        //       dateAdded: "2025-03-17T18:41:05.409Z",
        //       type: "image",
        //       name: "Uploaded file",
        //       description: "This is a description",
        //     },
        //     {
        //       type: "application/pdf",
        //       src: "https://firebasestorage.googleapis.com/v0/b/blue-sky-dev-7fed9.appspot.com/o/lockbox%2Fdocuments%2FqGhp3mszZNKXiLUUgDB3%2FUnmetNeedsReport%20(6)_2025-03-17T18%3A41%3A16.817Z.pdf?alt=media&token=13b2a502-7639-4488-a02b-579d48255f1b",
        //       name: "UnmetNeedsReport (6).pdf",
        //       dateAdded: "2025-03-17T18:41:18.634Z",
        //       details: "This is a details",
        //       description: "This is a description",
        //     },
        //   ]}
        //   acceptedTypes="image/*,application/pdf"
        //   hasDescriptionDisplay={true}
        //   descriptionKey="details"
        // />
      }
      {/* PET PHOTOS MODAL */}
      {renderPetModal}
      {/* SAMPLE UPLOAD */}
      {/* <Modal
        isOpen={false}
        title="Sample Upload"
        onClose={() => {
          sampleUploadReset();
        }}
        formRef={sampleUploadFormRef}
        isLoading={false}
      >
        <form
          ref={sampleUploadFormRef}
          onSubmit={sampleUploadHandleSubmit(onSubmitSampleUpload)}
        >
          <div>
            <CustomUploader
              multiple={true}
              errors={sampleUploadErrors}
              control={sampleUploadControl}
              name="fileUpload"
            />
          </div>
        </form>
      </Modal> */}
      {/* HOUSEHOLD MEMBER DELETE MODAL */}
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDeleteModal(false);
          setHouseholdMemberToDeleteId(null);
        }}
        onDelete={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsSubmittingHouseholdMember(true);
          const disasterId = selectedDisasters[0];
          const householdMemberRef = doc(
            db,
            `household/${data?.household_id}/household_members/${householdMemberToDeleteId}`
          );
          const householdMemberDisasterRef = doc(
            db,
            `users/${accessedUserId}/disaster_households/${disasterId}/household_members/${householdMemberToDeleteId}`
          );
          const householdMemberRefData = await getDoc(householdMemberRef);
          const householdMemberDisasterRefData = await getDoc(
            householdMemberDisasterRef
          );
          if (householdMemberRefData.exists()) {
            await updateDoc(householdMemberRef, {
              is_deleted: true,
              updated_at: serverTimestamp(),
            });
          }
          if (householdMemberDisasterRefData.exists()) {
            await updateDoc(householdMemberDisasterRef, {
              is_deleted: true,
              updated_at: serverTimestamp(),
            });
          }
          setShowDeleteModal(false);
          setIsSubmittingHouseholdMember(false);
          await fetchHouseholdMembers();
        }}
        itemName="Household Member"
        loading={isSubmittingHouseholdMember}
      />

      {/* PET DELETE MODAL */}
      <DeleteModal
        isOpen={showDeletePetModal}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDeletePetModal(false);
          setPetsToDeleteId(null);
        }}
        onDelete={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsSubmittingPets(true);
          const disasterId = selectedDisasters[0];
          const householdPetRef = doc(
            db,
            `household/${data?.household_id}/pets/${petsToDeleteId}`
          );
          const householdPetDisasterRef = doc(
            db,
            `users/${accessedUserId}/disaster_households/${disasterId}/pets/${petsToDeleteId}`
          );
          const householdPetRefData = await getDoc(householdPetRef);
          const householdPetDisasterRefData = await getDoc(
            householdPetDisasterRef
          );
          if (householdPetRefData.exists()) {
            await updateDoc(householdPetRef, {
              is_deleted: true,
              updated_at: serverTimestamp(),
            });
          }
          if (householdPetDisasterRefData.exists()) {
            await updateDoc(householdPetDisasterRef, {
              is_deleted: true,
              updated_at: serverTimestamp(),
            });
          }
          setShowDeletePetModal(false);
          setIsSubmittingPets(false);
          setPetsToDeleteId(null);
          await fetchPets();
        }}
        itemName="Pet"
        loading={isSubmittingPets}
      />

      {/* ADDRESS DELETE MODAL */}
      <DeleteModal
        isOpen={showDeleteAddressModal}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDeleteAddressModal(false);
          setAddressToDeleteId(null);
        }}
        onDelete={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsSubmittingAddress(true);
          const addressRef = doc(
            db,
            `address/${data?.address_id}/addresses/${addressToDeleteId}`
          );
          const addressRefData = await getDoc(addressRef);
          if (addressRefData.exists()) {
            await updateDoc(addressRef, {
              is_deleted: true,
              updated_at: serverTimestamp(),
            });
          }
          await fetchAddresses();
          setShowDeleteAddressModal(false);
          setAddressToDeleteId(null);
          setIsSubmittingAddress(false);
        }}
        itemName="Address"
        loading={isSubmittingAddress}
      />

      {/* ALTERNATE CONTACT MODAL */}
      <Modal
        isOpen={showAlternateContactModal}
        title="Alternate Contact"
        onClose={() => {
          setShowAlternateContactModal(false);
          setActiveMenuAlternateContact(false);
          alternateContactReset();
        }}
        formRef={alternateContactFormRef}
        isLoading={isSubmittingAlternateContact}
      >
        <form
          onSubmit={alternateContactHandleSubmit(onSubmitAlternateContact)}
          className="grid grid-cols-4 gap-4"
          ref={alternateContactFormRef}
        >
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="alternate_contact_name"
              placeholder="Name"
              register={{
                ...alternateContactRegister("alternate_contact_name", {
                  // required: "Contact name is required",
                  pattern: {
                    value: alphabetRegex,
                    message: "Numbers are not allowed in name",
                  },
                }),
              }}
              errors={alternateContactErrors}
            />
          </div>

          <div className="col-span-2">
            <CustomInput
              type="text"
              name="alternate_mobile_number"
              placeholder="Contact No."
              register={{
                ...alternateContactRegister("alternate_mobile_number", {
                  // required: "Contact number is required",
                  pattern: {
                    value: usMobileNumberRegex,
                    message: "Invalid contact number",
                  },
                }),
              }}
              errors={alternateContactErrors}
            />
          </div>

          <div className="col-span-4">
            <CustomInput
              type="text"
              name="alternate_email"
              placeholder="Email Address"
              register={{
                ...alternateContactRegister("alternate_email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                  validate: {
                    validEmail: (value) => {
                      // Don't allow empty values
                      if (!value || value.trim() === "") {
                        return;
                      }
                      return emailRegex.test(value) || "Invalid email address";
                    },
                  },
                }),
              }}
              errors={alternateContactErrors}
            />
          </div>
        </form>
      </Modal>

      {/* HOUSEHOLD INFO MODAL */}
      <Modal
        isOpen={showHouseholdInfoModal}
        title="Household Information"
        onClose={() => {
          setShowHouseholdInfoModal(false);
          setActiveMenuHouseholdInfo(false);
          householdInfoReset();
        }}
        formRef={householdInfoFormRef}
        isLoading={isSubmittingHouseholdInfo}
        width="700px"
      >
        <form
          onSubmit={householdInfoHandleSubmit(onSubmitHouseholdInfo)}
          className="grid grid-cols-4 gap-4"
          ref={householdInfoFormRef}
        >
          <div className="col-span-2">
            <CustomSelect
              name="annual_income"
              placeholder="Annual Household Income"
              options={AnnualIncomeOptions}
              register={{
                ...householdInfoRegister("annual_income", {
                  required: "Annual Income is required",
                }),
              }}
              errors={householdInfoErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="owner_or_renter"
              placeholder="Are you an Owner or Renter?"
              options={OwnerOrRenterOptions}
              register={{
                ...householdInfoRegister("owner_or_renter", {
                  required: "Owner or Renter is required",
                }),
              }}
              errors={householdInfoErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="number"
              name="household_size"
              placeholder="Household Size"
              max={20}
              register={{
                ...householdInfoRegister("household_size", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid household size",
                  },
                  validate: {
                    validHouseholdSize: (value) => {
                      // Don't allow empty values
                      if (!value || value.trim() === "") {
                        return "Household size is required";
                      }
                      // Validate number between 1 and 20
                      const numValue = parseInt(value);
                      if (isNaN(numValue) || numValue < 1 || numValue > 20) {
                        return "Household size must be between 1 and 20";
                      }
                      return true;
                    },
                  },
                }),
              }}
              errors={householdInfoErrors}
            />
          </div>
          <div className=" col-span-2"></div>
          <div
            className={`${
              householdInfoWatch("home_insurance") ? "col-span-2" : "col-span-4"
            }`}
          >
            <CustomSwitch
              label="Do you have Home Insurance?"
              control={householdInfoControl}
              name="home_insurance"
              // rules={{
              //   required: "This field is required",
              //   validate: (value) => value === true || "Must be checked",
              // }}
              errors={householdInfoErrors}
            />
          </div>
          {householdInfoWatch("home_insurance") && (
            <div
              className={`${
                householdInfoWatch("home_insurance")
                  ? "col-span-2"
                  : "col-span-2"
              }`}
            >
              <CustomSelect
                name="home_insurance_type"
                placeholder="Home Insurance Type"
                register={{
                  ...householdInfoRegister("home_insurance_type", {
                    required: "Home Insurance Type is required",
                  }),
                }}
                options={HomeInsuranceTypeOptions}
                errors={householdInfoErrors}
              />
            </div>
          )}

          <div
            className={`${
              householdInfoWatch("automotive_insurance")
                ? "col-span-2"
                : "col-span-2"
            }`}
          >
            <CustomSwitch
              label="Do you have Automotive Insurance?"
              control={householdInfoControl}
              name="automotive_insurance"
              // rules={{
              //   required: "This field is required",
              //   validate: (value) => value === true || "Must be checked",
              // }}
              errors={householdInfoErrors}
            />
          </div>
          {householdInfoWatch("automotive_insurance") && (
            <div
              className={`${
                householdInfoWatch("automotive_insurance")
                  ? "col-span-2"
                  : "col-span-2"
              }`}
            >
              <CustomSelect
                name="automotive_insurance_type"
                placeholder="Automotive Insurance Type"
                register={{
                  ...householdInfoRegister("automotive_insurance_type", {
                    required: "Automotive Insurance Type is required",
                  }),
                }}
                options={AutomotiveInsuranceTypeOptions}
                errors={householdInfoErrors}
              />
            </div>
          )}
        </form>
      </Modal>

      {/* HOUSEHOLD MEMBERS MODAL */}
      <Modal
        isOpen={showHouseholdMemberModal}
        title={
          isEditingHouseholdMember
            ? "Edit Household Member"
            : "Add New Household Member"
        }
        onClose={() => {
          setShowHouseholdMemberModal(false);
          setActiveMenuHouseholdMember(false);
          householdMemberReset();
          // setSelectedHouseholdMember(null);
        }}
        formRef={householdMemberFormRef}
        isLoading={isSubmittingHouseholdMember}
        isEdit={isEditingHouseholdMember}
      >
        <form
          onSubmit={householdMemberHandleSubmit(onSubmitHouseholdMember)}
          className="grid grid-cols-4 gap-4"
          ref={householdMemberFormRef}
        >
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="first_name"
              placeholder="First Name"
              register={{
                ...householdMemberRegister("first_name", {
                  required: "First Name is required",
                  pattern: {
                    value: alphabetRegex,
                    message: "Numbers are not allowed in names",
                  },
                }),
              }}
              errors={householdMemberErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="last_name"
              placeholder="Last Name"
              register={{
                ...householdMemberRegister("last_name", {
                  required: "Last Name is required",
                  pattern: {
                    value: alphabetRegex,
                    message: "Numbers are not allowed in names",
                  },
                }),
              }}
              errors={householdMemberErrors}
            />
          </div>
          <div className="col-span-2">
            <Controller
              control={householdMemberControl}
              name="date_of_birth"
              render={({ field }) => (
                <DatePickerTemplate
                  label="Date of Birth"
                  labelClassName="text-sm"
                  className="px-2"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors("date_of_birth");
                  }}
                  errors={householdMemberErrors}
                  name="date_of_birth"
                />
              )}
            />
          </div>

          <div className="col-span-2">
            <CustomSelect
              name="relationship"
              placeholder="Relationship"
              options={RelationshipOptions}
              register={{
                ...householdMemberRegister("relationship", {
                  required: "Relationship is required",
                }),
              }}
              errors={householdMemberErrors}
            />
          </div>
          <div className="col-span-4">
            <CustomInput
              type="text"
              name="email"
              placeholder="Email Address"
              register={{
                ...householdMemberRegister("email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                  validate: {
                    validEmail: (value) => {
                      if (!value || value.trim() === "") {
                        return;
                      }
                      const emailRegex =
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                      return emailRegex.test(value) || "Invalid email address";
                    },
                  },
                }),
              }}
              errors={householdMemberErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="gender"
              placeholder="Gender"
              options={GenderOptions}
              register={{
                ...householdMemberRegister("gender", {
                  required: "Gender is required",
                }),
              }}
              errors={householdMemberErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="ethnicity"
              placeholder="Ethnicity"
              options={EthnicityOptions}
              register={{
                ...householdMemberRegister("ethnicity", {
                  required: "Ethnicity is required",
                }),
              }}
              errors={householdMemberErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSwitch
              rowType={true}
              label="Do you have a disability?"
              control={householdMemberControl}
              name="disability"
              // rules={{
              //   required: "This field is required",
              //   validate: (value) => value === true || "Must be checked",
              // }}
              errors={householdMemberErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSwitch
              rowType={true}
              label="Are you Active Military / Vet?"
              control={householdMemberControl}
              name="military_vet"
              // rules={{
              //   required: "This field is required",
              //   validate: (value) => value === true || "Must be checked",
              // }}
              errors={householdMemberErrors}
            />
          </div>
        </form>
      </Modal>

      {/* PETS MODAL */}
      <Modal
        isOpen={showPetsModal}
        title={isEditingPets ? "Edit Pet" : "Add New Pet"}
        onClose={() => {
          setShowPetsModal(false);
          setActiveMenuPets(false);
          petsReset();
          // setSelectedHouseholdMember(null);
        }}
        formRef={petsFormRef}
        isLoading={isSubmittingPets}
        isEdit={isEditingPets}
      >
        <form
          onSubmit={petsHandleSubmit(onSubmitPets)}
          className="grid grid-cols-4 gap-4"
          ref={petsFormRef}
        >
          <div className=" col-span-4 ">
            <SimpleFileUploader
              storagePath={`household/pets/${accessedUserId}`}
              onFileUpload={(photoUrls) => {
                if (photoUrls) {
                  petsSetValue("pet_image", photoUrls);
                }
              }}
              initialPhoto={petsValues.pet_image}
              acceptedTypes="image/*"
              className="mb-4"
              dateKey="date_inserted"
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="pet_name"
              placeholder="Name"
              register={{
                ...petsRegister("pet_name", {
                  required: "Name is required",
                  pattern: {
                    value: alphabetRegex,
                    message: "Numbers are not allowed in name",
                  },
                }),
              }}
              errors={petsErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="pet_breed"
              placeholder="Breed"
              register={{
                ...petsRegister("pet_breed", {
                  required: "Breed is required",
                  pattern: {
                    value: alphabetRegex,
                    message: "Numbers are not allowed in breed",
                  },
                }),
              }}
              errors={petsErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="pet_color"
              placeholder="Color"
              register={{
                ...petsRegister("pet_color", {
                  required: "Color is required",
                  pattern: {
                    value: alphabetRegex,
                    message: "Numbers are not allowed in color",
                  },
                }),
              }}
              errors={petsErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="pet_coat"
              placeholder="Coat"
              register={{
                ...petsRegister("pet_coat", {
                  required: "Coat is required",
                }),
              }}
              options={PetsCoatOptions}
              errors={petsErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="pet_size"
              placeholder="Size"
              register={{
                ...petsRegister("pet_size", {
                  required: "Size is required",
                }),
              }}
              options={PetsSizeOptions}
              errors={petsErrors}
            />
          </div>
        </form>
      </Modal>

      {/*  ADDRESS MODAL */}
      <Modal
        isOpen={showAddressModal}
        title={isEditingAddress ? "Edit Address" : "Add New Address"}
        onClose={() => {
          setShowAddressModal(false);
          setActiveMenuAddress(false);
          addressReset();
          // setSelectedHouseholdMember(null);
        }}
        formRef={addressFormRef}
        isLoading={isSubmittingAddress}
        isEdit={isEditingAddress}
      >
        <form
          onSubmit={addressHandleSubmit(onSubmitAddress)}
          className="grid grid-cols-4 gap-4"
          ref={addressFormRef}
        >
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="address1"
              placeholder="Address 1"
              register={{
                ...addressRegister("address1", {
                  required: "Address 1 is required",
                  pattern: {
                    value: REGEX.alphanumericOnlyRegexWithHashOrDash,
                    message: "Invalid address 1",
                  },
                }),
              }}
              errors={addressErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="address2"
              placeholder="Address 2"
              register={{
                ...addressRegister("address2", {
                  // required: "Address 2 is required",
                  pattern: {
                    value: REGEX.alphanumericOnlyRegexWithHashOrDash,
                    message: "Invalid address 2",
                  },
                }),
              }}
              errors={addressErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="city"
              placeholder="City"
              register={{
                ...addressRegister("city", {
                  required: "City is required",
                  pattern: {
                    value: REGEX.alphabetRegex,
                    message: "Numbers are not allowed in city",
                  },
                }),
              }}
              errors={addressErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="state"
              placeholder="State"
              register={{
                ...addressRegister("state", {
                  required: "State is required",
                }),
                onChange: (e) => {
                  addressReset({
                    county: "",
                    state: e.target.value,
                    id: addressWatch("id"),
                    type: addressWatch("type"),
                  });
                },
              }}
              options={USStatesOptions}
              errors={addressErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="text"
              name="zip"
              placeholder="ZIP Code"
              register={{
                ...addressRegister("zip", {
                  required: "Zip is required",
                  onChange: (e) => {
                    addressReset({
                      zip: String(e.target.value).toUpperCase(),
                      id: addressWatch("id"),
                      type: addressWatch("type"),
                    });
                  },
                }),
              }}
              errors={addressErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="county"
              placeholder="County"
              register={{
                ...addressRegister("county", {
                  required: "County is required",
                }),
              }}
              options={countyDropdownByState(addressWatch("state"))}
              errors={addressErrors}
            />
          </div>

          <div className="col-span-2">
            <CustomSelect
              name="address_type"
              placeholder="Address Type"
              register={addressRegister("address_type", {
                required: "Address Type is required",
              })}
              options={addressTypeDropdown}
              errors={addressErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="address_condition"
              placeholder="Address Condition"
              register={addressRegister("address_condition", {
                required: "Address Condition is required",
              })}
              options={addressConditionDropdown}
              errors={addressErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomSelect
              name="subsidized_by"
              placeholder="This housing is subsidized by"
              register={addressRegister("subsidized_by")}
              options={subsidizedByDropdown}
              errors={addressErrors}
            />
          </div>
          <div className="col-span-4 flex items-center space-x-3">
            <label htmlFor="share_housing_expenses">
              Does applicant share housing expenses?
            </label>
            <CustomSelect
              name="share_housing_expenses"
              register={addressRegister("share_housing_expenses", {
                required: "This field is required",
              })}
              options={yesNoDropdown}
              errors={addressErrors}
            />
            {addressWatch("share_housing_expenses") === "Yes" && (
              <CustomInput
                type="text"
                name="share_housing_expenses_followup"
                placeholder="If YES, describe"
                register={addressRegister("share_housing_expenses_followup")}
                errors={addressErrors}
              />
            )}
          </div>
          {addressWatch("address_condition") === "Own" && (
            <>
              <div className="col-span-2 space-y-2">
                <CustomSelect
                  name="clear_title"
                  placeholder="Clear Title"
                  register={addressRegister("clear_title")}
                  options={yesNoDropdown}
                  errors={addressErrors}
                />
                {addressWatch("clear_title") === "No" && (
                  <CustomInput
                    type="text"
                    name="clear_title_followup"
                    placeholder="If NO, please explain"
                    register={addressRegister("clear_title_followup")}
                    errors={addressErrors}
                  />
                )}
              </div>
              <div className="col-span-2 space-y-2">
                <CustomSelect
                  name="mortgage"
                  placeholder="Mortgage"
                  register={addressRegister("mortgage")}
                  options={yesNoDropdown}
                  errors={addressErrors}
                />
                {addressWatch("mortgage") === "Yes" && (
                  <CustomInput
                    type="text"
                    name="mortgage_followup"
                    placeholder="Mortgage Amount"
                    register={addressRegister("mortgage_followup", {
                      valueAsNumber: true,
                    })}
                    errors={addressErrors}
                  />
                )}
              </div>
              <div className="col-span-2 space-y-2">
                <CustomSelect
                  name="payments_current"
                  placeholder="Payments Current"
                  register={addressRegister("payments_current")}
                  options={yesNoDropdown}
                  errors={addressErrors}
                />
                {addressWatch("payments_current") === "No" && (
                  <CustomInput
                    type="text"
                    name="payments_current_followup"
                    placeholder="Amount in arrears"
                    register={addressRegister("payments_current_followup", {
                      valueAsNumber: true,
                    })}
                    errors={addressErrors}
                  />
                )}
              </div>
              <div className="col-span-2 space-y-2">
                <CustomSelect
                  name="property_taxes_current"
                  placeholder="Property Taxes Current"
                  register={addressRegister("property_taxes_current")}
                  options={yesNoDropdown}
                  errors={addressErrors}
                />
                {addressWatch("property_taxes_current") === "No" && (
                  <CustomInput
                    type="text"
                    name="property_taxes_current_followup"
                    placeholder="Amount in arrears"
                    register={addressRegister(
                      "property_taxes_current_followup",
                      {
                        valueAsNumber: true,
                      }
                    )}
                    errors={addressErrors}
                  />
                )}
              </div>
            </>
          )}
        </form>
      </Modal>

      <div className="relative flex w-full flex-col items-start justify-center leading-[normal] tracking-[normal]">
        {showCaseNumberPopup && <CaseNumberModal />}

        <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <HeaderTitle id="impactedaddress" title="Impacted Address" />
          {data && (
            <CustomAccordion
              classNameIcon=""
              title={
                <h3 className="text-sm font-medium">
                  Impacted Address:{" "}
                  <span className=" font-semibold">
                    {`${data?.address1 ? `${data?.address1}, ` : ""} ${
                      data?.address2 ? `${data?.address2}, ` : ""
                    } ${data?.city ? `${data?.city},` : ""} ${
                      data?.state ? `${data?.state} ` : ""
                    } ${data?.county ? `${data?.county},` : ""} ${
                      data?.zip ? `${data?.zip}` : ""
                    }`}
                  </span>
                </h3>
              }
            >
              <form
                onSubmit={impactedAddressHandleSubmit(onSubmitImpactedAddress)}
                onChange={(e) => {
                  // setSelectedImpactedAddress(e.target.value);
                  onUpdateImpactedAddress(e.target.value);
                }}
                className="flex flex-col"
              >
                {addresses
                  // ?.filter(
                  //   (obj) =>
                  //     obj.type === "regular" || obj?.is_primary_address == true
                  // )
                  ?.sort((a, b) => {
                    if (a.is_primary_address && !b.is_primary_address)
                      return -1;
                    if (!a.is_primary_address && b.is_primary_address) return 1;
                    return 0;
                  })
                  ?.map((obj, i) => {
                    return (
                      <label
                        key={i}
                        className={`${
                          data?.impacted_address_id == obj.id
                            ? "bg-[#D9EEFF]"
                            : ""
                        } flex flex-row items-center justify-between gap-2 rounded-[4px] px-2 py-1`}
                      >
                        <div className="flex flex-row items-center gap-2">
                          <input
                            type="radio"
                            value={obj.id}
                            {...impactedAddressRegister("option")}
                            className=""
                            style={{ border: "2px solid #770C0E" }}
                          />
                          <div className="flex items-center gap-4">
                            <p className="text-sm font-semibold">
                              {" "}
                              {`${obj?.address1 ? `${obj?.address1}, ` : ""} ${
                                obj?.address2 ? `${obj?.address2}, ` : ""
                              } ${obj?.city ? `${obj?.city}, ` : ""} ${
                                obj?.state ? `${obj?.state} ` : ""
                              } ${obj?.county ? `${obj?.county}, ` : ""} ${
                                obj?.zip ? `${obj?.zip}` : ""
                              } ${obj?.country ? `, ${obj?.country}` : ""}`}
                            </p>
                            {data?.impacted_address_id == obj.id && (
                              <CustomTag title="Default Address" />
                            )}
                          </div>
                        </div>
                        <ThreeDotMenu
                          menuId={obj.id}
                          activeMenuId={activeMenuAddress}
                          setActiveMenuId={setActiveMenuAddress}
                          clickOutside={true}
                          onEditClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setActiveMenuAddress(null);
                            setIsEditingAddress(true);
                            setShowAddressModal(true);
                            addressReset({
                              address1: obj?.address1,
                              address2: obj?.address2,
                              city: obj?.city,
                              state: obj?.state,
                              zip: obj?.zip,
                              county: obj?.county || "",
                              id: obj?.id,
                              type: obj?.is_primary_address
                                ? "primary"
                                : obj?.type || "regular",
                              address_type: obj?.address_type || "",
                              address_condition: obj?.address_condition || "",
                              subsidized_by: obj?.subsidized_by || "",
                              share_housing_expenses:
                                obj?.share_housing_expenses || "",
                              share_housing_expenses_followup:
                                obj?.share_housing_expenses_followup || "",
                              clear_title: obj?.clear_title || "",
                              clear_title_followup:
                                obj?.clear_title_followup || "",
                              mortgage: obj?.mortgage || "",
                              mortgage_followup: obj?.mortgage_followup || 0,
                              payments_current: obj?.payments_current || "",
                              payments_current_followup:
                                obj?.payments_current_followup || 0,
                              property_taxes_current:
                                obj?.property_taxes_current || "",
                              property_taxes_current_followup:
                                obj?.property_taxes_current_followup || 0,
                            });
                          }}
                          {...(!obj?.is_primary_address && {
                            onDeleteClick: (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setActiveMenuAddress(null);
                              setShowDeleteAddressModal(true);
                              setAddressToDeleteId(obj?.id);
                            },
                          })}
                        />
                      </label>
                    );
                  })}

                {impactedAddressErrors.option && (
                  <p style={{ color: "red" }}>
                    {impactedAddressErrors.option.message}
                  </p>
                )}
              </form>
              <AddNewButton
                name="Add New Address"
                onClick={() => {
                  setShowAddressModal(true);
                  setIsEditingAddress(false);
                  addressReset({
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zip: "",
                    county: "",
                    id: "",
                    type: "regular",
                  });
                }}
              />
            </CustomAccordion>
          )}

          <div className="mt-3 flex w-full flex-col space-y-2">
            <div className="px-2" id="displacedaddress">
              <CheckBoxComponent
                cssClass="custom-checkbox"
                checked={!!data?.own_other_properties}
                label="Own other properties?"
                change={onOwnOtherPropertiesChange}
              />
            </div>
            {!!data?.own_other_properties && (
              <div className="relative">
                <input
                  key={`followup-${data.id}`}
                  type="text"
                  name="own_other_properties_followup"
                  //placeholder="Please explain"
                  className={
                    "peer h-[50px] w-full rounded-[4px] border-gray-200 px-3 pb-1 pt-4 text-base outline-none transition-all hover:border-[#8F8F8F] focus:!border-darkslateblue"
                  }
                  style={{
                    border: "1px solid #CFCFCF",
                    borderColor: "#CFCFCF",
                    "&:hover": {
                      borderColor: "#8F8F8F",
                    },
                    "&:focus": {
                      borderColor: "#193861",
                    },
                  }}
                  value={data?.own_other_properties_followup || ""}
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      own_other_properties_followup: e.target.value,
                    }));
                  }}
                  onBlur={async () => {
                    try {
                      // Call the update function when the input loses focus
                      await citizenProfileService.ownOtherPropertiesFollowup(
                        accessedUserId,
                        data.own_other_properties_followup
                      );
                      // Optionally fetch and update the data again if needed
                      const citizenData =
                        await citizenProfileService.getCitizenProfile(
                          "users",
                          accessedUserId
                        );
                      setData((prev) => ({
                        ...prev,
                        ...citizenData,
                      }));
                    } catch (error) {
                      console.error(
                        "Error saving follow-up information: ",
                        error
                      );
                    }
                  }}
                />
                <label
                  className={
                    "absolute left-3 top-1 text-[14px] text-smokey-grey transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-xs peer-focus:text-[14px]"
                  }
                >
                  Please explain
                </label>
              </div>
            )}
          </div>

          <div className="mt-3 flex w-full flex-col space-y-2">
            <div className="px-2" id="displacedaddress">
              <CheckBoxComponent
                cssClass="custom-checkbox"
                checked={!!data?.is_displaced}
                label="Are you currently displaced?"
                change={onDisplaced}
                // disabled={selectedDisasters.some((id) => {
                //   const disaster = disasters.find((d) => d.id === id);
                //   return disaster?.status === "closed";
                // })}
              ></CheckBoxComponent>
            </div>
            {!!data?.is_displaced && (
              <CustomAccordion
                classNameIcon=""
                title={
                  <h3 className="text-sm font-medium">
                    Displaced Address:{" "}
                    <span className=" font-semibold">
                      {`${data?.d_address_1 ? `${data?.d_address_1}, ` : ""} ${
                        data?.d_address_2 ? `${data?.d_address_2}, ` : ""
                      } ${data?.d_city ? `${data?.d_city}, ` : ""} ${
                        data?.d_state ? `${data?.d_state} ` : ""
                      } ${data?.d_county ? `${data?.d_county}, ` : ""} ${
                        data?.d_zip ? `${data?.d_zip}` : ""
                      } ${data?.d_country ? `, ${data?.d_country}` : ""}`}
                    </span>
                  </h3>
                }
              >
                <form
                  onSubmit={displacedAddressHandleSubmit(
                    onSubmitDisplacedAddress
                  )}
                  onChange={(e) => {
                    // setSelectedDisplacedAddress(e.target.value);
                    onUpdateDisplacedAddress(e.target.value);
                  }}
                  className="flex flex-col"
                >
                  {addresses
                    // ?.filter(
                    //   (obj) =>
                    //     obj.type === "displaced" ||
                    //     obj?.is_primary_address == true
                    // )
                    ?.sort((a, b) => {
                      if (a.is_primary_address && !b.is_primary_address)
                        return -1;
                      if (!a.is_primary_address && b.is_primary_address)
                        return 1;
                      return 0;
                    })
                    ?.map((obj, i) => {
                      return (
                        <label
                          key={i}
                          className={`${
                            data?.d_address_id == obj.id ? "bg-[#D9EEFF]" : ""
                          } flex flex-row items-center justify-between gap-2 rounded-[4px] px-2 py-1`}
                        >
                          <div className="flex flex-row items-center gap-2">
                            <input
                              type="radio"
                              value={obj.id}
                              {...displacedAddressRegister("option")}
                              className=""
                              style={{ border: "2px solid #770C0E" }}
                            />
                            <div className="flex items-center gap-4">
                              <p className="text-sm font-semibold">
                                {" "}
                                {`${
                                  obj?.address1 ? `${obj?.address1}, ` : ""
                                } ${
                                  obj?.address2 ? `${obj?.address2}, ` : ""
                                } ${obj?.city ? `${obj?.city}, ` : ""} ${
                                  obj?.state ? `${obj?.state} ` : ""
                                } ${obj?.county ? `${obj?.county}, ` : ""} ${
                                  obj?.zip ? `${obj?.zip}` : ""
                                } ${obj?.country ? `, ${obj?.country}` : ""}`}
                              </p>
                              {data?.d_address_id == obj.id && (
                                <CustomTag title="Default Address" />
                              )}
                            </div>
                          </div>
                          <ThreeDotMenu
                            menuId={obj.id}
                            activeMenuId={activeMenuAddressDisplaced}
                            setActiveMenuId={setActiveMenuAddressDisplaced}
                            clickOutside={true}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setActiveMenuAddressDisplaced(null);
                              setShowAddressModal(true);
                              setIsEditingAddress(true);
                              addressReset({
                                address1: obj?.address1,
                                address2: obj?.address2,
                                city: obj?.city,
                                state: obj?.state,
                                zip: obj?.zip,
                                county: obj?.county || "",
                                id: obj?.id,
                                type: obj?.is_primary_address
                                  ? "primary"
                                  : obj?.type || "displaced",
                              });
                            }}
                            {...(!obj?.is_primary_address && {
                              onDeleteClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setActiveMenuAddressDisplaced(null);
                                setShowDeleteAddressModal(true);
                                setAddressToDeleteId(obj?.id);
                              },
                            })}
                          />
                        </label>
                      );
                    })}

                  {impactedAddressErrors.option && (
                    <p style={{ color: "red" }}>
                      {impactedAddressErrors.option.message}
                    </p>
                  )}
                </form>
                <AddNewButton
                  name="Add New Address"
                  onClick={() => {
                    setShowAddressModal(true);
                    setIsEditingAddress(false);
                    addressReset({
                      address1: "",
                      address2: "",
                      city: "",
                      state: "",
                      zip: "",
                      county: "",
                      id: "",
                      type: "displaced",
                    });
                  }}
                />
              </CustomAccordion>
            )}
          </div>
          {/* <div className="flex items-center gap-2">
          {isEditMode ? (
            <>
              <button
                type="button"
                className=" flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={saveChanges}
              >
                <FaSave className="h-3 w-3" />
                Save
              </button>
              <button
                type="button"
                className="btn-cancel flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={cancelEditing}
              >
                <FaTimes className="h-3 w-3" />
                Cancel
              </button>
            </>
          ) : (
            !selectedDisasters.some((id) => {
              const disaster = disasters.find((d) => d.id === id);
              return disaster?.status === "closed";
            }) && (
              <button
                type="button"
                className=" flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={startEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )
          )}
   
        </div> */}
        </section>

        <section className=" mt-4 flex w-full flex-col">
          <HeaderTitle title="Alternate Contact" id="alternatecontact" />
          <div className=" flex justify-between px-2">
            <div className="flex flex-row gap-4">
              <p className="text-sm">
                Name:{" "}
                <span className="font-semibold ">
                  {data?.alternate_contact_name}
                </span>
              </p>
              <p className="text-sm">
                Contact No.{" "}
                <span className="font-semibold">
                  {data?.alternate_mobile_number}
                </span>
              </p>
              <p className="text-sm">
                Email Address:{" "}
                <span className="font-semibold">{data?.alternate_email}</span>
              </p>
            </div>
            <ThreeDotMenu
              activeMenu={activeMenuAlternateContact}
              setActiveMenu={setActiveMenuAlternateContact}
              onEditClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowAlternateContactModal(true);
                setActiveMenuAlternateContact(false);
                if (data) {
                  alternateContactReset({
                    alternate_contact_name: data?.alternate_contact_name || "",
                    alternate_email: data?.alternate_email || "",
                    alternate_mobile_number:
                      data?.alternate_mobile_number || "",
                  });
                }
              }}
            />
          </div>

          {/* <div className="flex flex-row gap-4">
            {renderField(
              "Contact Name",
              "alternate_contact_name",
              "",
              false,
              "flex-1"
            )}
            {renderField("Email", "alternate_email", "", false, "flex-1")}
            {renderField(
              "Phone Number",
              "alternate_mobile_number",
              "",
              false,
              "flex-1"
            )}
          </div> */}
        </section>

        {/* Household Information */}
        <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
          <HeaderTitle
            title="Household Information"
            id="householdinformation"
          />
          {isHouseholdInfoVisible && (
            <>
              <div className=" flex w-full justify-between px-2">
                <div className="grid w-full grid-cols-3 gap-4">
                  <p className="text-sm">
                    What is your Annual Household Income?{" "}
                    <span className="font-semibold ">
                      {formatIncomeRange(tempHouseholdFormData?.annual_income)}
                    </span>
                  </p>
                  <p className="text-sm">
                    Are you an Owner or Renter?{" "}
                    <span className="font-semibold">
                      {tempHouseholdFormData?.owner_or_renter}
                    </span>
                  </p>
                  <p className="text-sm">
                    What is the size of your household?{" "}
                    <span className="font-semibold">
                      {tempHouseholdFormData?.household_size}
                    </span>
                  </p>
                  <p
                    className={`${
                      tempHouseholdFormData?.home_insurance
                        ? "col-span-1"
                        : "col-span-3"
                    } text-sm`}
                  >
                    Do you have Home Insurance?{" "}
                    <span className="font-semibold ">
                      {tempHouseholdFormData?.home_insurance ? "Yes" : "No"}
                    </span>
                  </p>
                  {tempHouseholdFormData?.home_insurance && (
                    <p
                      className={`${
                        tempHouseholdFormData?.home_insurance
                          ? "col-span-2"
                          : "col-span-3"
                      } text-sm`}
                    >
                      What type of Home Insurance?{" "}
                      <span className="font-semibold">
                        {tempHouseholdFormData?.home_insurance_type}
                      </span>
                    </p>
                  )}
                  <p className="text-sm">
                    Do you have Automotive Insurance?{" "}
                    <span className="font-semibold">
                      {tempHouseholdFormData?.automotive_insurance
                        ? "Yes"
                        : "No"}
                    </span>
                  </p>
                  {tempHouseholdFormData?.automotive_insurance && (
                    <p className="text-sm">
                      What type of Automotive Insurance?{" "}
                      <span className="font-semibold">
                        {tempHouseholdFormData?.automotive_insurance_type}
                      </span>
                    </p>
                  )}
                </div>
                <ThreeDotMenu
                  activeMenu={activeMenuHouseholdInfo}
                  setActiveMenu={setActiveMenuHouseholdInfo}
                  onEditClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowHouseholdInfoModal(true);
                    setActiveMenuHouseholdInfo(false);
                    if (data) {
                      householdInfoReset({
                        annual_income:
                          tempHouseholdFormData?.annual_income || "",
                        owner_or_renter:
                          tempHouseholdFormData?.owner_or_renter || "",
                        household_size:
                          tempHouseholdFormData?.household_size || "",
                        home_insurance: tempHouseholdFormData?.home_insurance
                          ? true
                          : false,
                        home_insurance_type:
                          tempHouseholdFormData?.home_insurance_type || "",
                        automotive_insurance:
                          tempHouseholdFormData?.automotive_insurance
                            ? true
                            : false,
                        automotive_insurance_type:
                          tempHouseholdFormData?.automotive_insurance_type ||
                          "",
                      });
                    }
                  }}
                />
              </div>
            </>
          )}
        </section>

        <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
          <HeaderTitle
            title="Additional Household Members"
            id="additionalhouseholdmembers"
          />
          <div className="flex items-center gap-2">
            {/* <button
              type="button"
              className="btn-default flex cursor-pointer  gap-1 px-3 py-1.5"
              onClick={handleAddClick}
            >
              <FaPlus className="h-3 w-3" />
              Add
            </button>

            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleHouseholdInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button> */}
          </div>
          {isHouseholdMembersVisible && (
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={gridRef}
                dataSource={filteredHouseholdMembers}
                allowSorting={true}
                sortSettings={sortSettings}
                // editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                // actionBegin={actionBegin}
                // actionComplete={actionComplete}
                allowPaging={true}
                pageSettings={pageSettings}
                showColumnMenu={true}
                columnMenuItems={columnMenuItems}
                cssClass="new-grid-color allow-overflow-grid"
                // emptyRecordTemplate={emptyRecordTemplate}
                // aggregates={[
                //   {
                //     columns: [
                //       {
                //         type: "Custom",
                //         customAggregate: () => "",
                //         footerTemplate: () => (
                //           <div
                //             className="cursor-pointer bg-white py-2 pl-2 text-blue-600 hover:underline"
                //             onClick={handleAddClick}
                //           >
                //             + Add New Household Member
                //           </div>
                //         ),
                //         field: "first_name",
                //       },
                //     ],
                //   },
                // ]}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="acc_created"
                    headerText="householdMemberID"
                    visible={false}
                  />
                  <ColumnDirective
                    field="household_member_id"
                    headerText="householdMemberID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="first_name"
                    headerText="First Name"
                    width="100"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="last_name"
                    headerText="Last Name"
                    width="100"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="email"
                    headerText="Email"
                    width="160"
                    // validationRules={{ required: true }}
                  />

                  <ColumnDirective
                    field="date_of_birth"
                    headerText="Date of Birth"
                    width="100"
                    // format="MM/dd/yyyy"
                    // template={(props) => {
                    //   if (
                    //     props?.date_of_birth == "" ||
                    //     props?.date_of_birth == null ||
                    //     props?.date_of_birth?.toLowerCase() == "invalid date"
                    //   )
                    //     return;
                    //   return (
                    //     <DatePickerTemplate
                    //       label="Date of Birth *"
                    //       onChange={(val) => {
                    //         setDob(() => val);
                    //       }}
                    //       value={props?.date_of_birth || ""}
                    //     />
                    //   );
                    // }}
                    template={(props) => {
                      const dob = props?.date_of_birth;
                      return (
                        <div>{dob && dob !== "Invalid Date" ? dob : ""}</div>
                      );
                    }}
                  />
                  <ColumnDirective
                    field="relationship"
                    headerText="Relationship"
                    width="100"
                    validationRules={{ required: true }}
                    // editType="dropdownedit"
                    // edit={relationshipParams}
                  />
                  <ColumnDirective
                    field="gender"
                    headerText="Gender"
                    width="100"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="ethnicity"
                    headerText="Ethnicity"
                    width="100"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="disability"
                    headerText="Person with disability?"
                    width="120"
                    validationRules={{ required: true }}
                    // editType="dropdownedit"
                    // edit={militaryvetParams}
                    template={(props) => (
                      <div>{props.disability ? "Yes" : "No"}</div>
                    )}
                  />
                  <ColumnDirective
                    field="military_vet"
                    headerText="Active Military/ Vet"
                    width="100"
                    // editType="dropdownedit"
                    // edit={militaryvetParams}
                    validationRules={{ required: true }}
                    template={(props) => (
                      <div>{props.military_vet ? "Yes" : "No"}</div>
                    )}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    textAlign="Center"
                    width="80"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.household_member_id}
                            activeMenuId={activeMenuHouseholdMember}
                            setActiveMenuId={setActiveMenuHouseholdMember}
                            clickOutside={false}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // setSelectedHouseholdMember(props);
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingHouseholdMember(true);
                              setShowHouseholdMemberModal(true);
                              setActiveMenuHouseholdMember(null);
                              householdMemberReset({
                                first_name: props?.first_name || "",
                                last_name: props?.last_name || "",
                                email: props?.email || "",
                                date_of_birth: props?.date_of_birth || "",
                                relationship: props?.relationship || "",
                                disability: props?.disability || "",
                                military_vet: props?.military_vet || "",
                                id: props?.id || "",
                                household_member_id:
                                  props?.household_member_id || "",
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteModal(true);
                              setActiveMenuHouseholdMember(null);
                              setHouseholdMemberToDeleteId(props?.id);
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    // Edit,
                    Sort,
                    Filter,
                    CommandColumn,
                    ColumnMenu,
                    Aggregate,
                  ]}
                />
              </GridComponent>
            </div>
          )}
          <AddNewButton
            onClick={() => {
              setIsEditingHouseholdMember(false);
              setShowHouseholdMemberModal(true);
              householdMemberReset({
                first_name: "",
                last_name: "",
                email: "",
                date_of_birth: "",
                relationship: "",
                disability: false,
                military_vet: false,
                household_member_id: "",
                id: "",
                created_at: "",
                updated_at: "",
                acc_created: "",
                is_deleted: false,
              });
            }}
            name="Add New Household Member"
          />
        </section>

        {/* Pets Section */}
        <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4 pt-4">
          <HeaderTitle title="Pets" id="pets" />
          {isPetsVisible && (
            <div className="mt-2 flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={petsGridRef}
                dataSource={filteredPets}
                keyField="pet_id"
                allowSorting={true}
                sortSettings={sortSettings}
                // editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={true}
                // actionBegin={actionBeginPets}
                // actionComplete={petsActionComplete}
                allowPaging={true}
                pageSettings={pageSettings}
                showColumnMenu={true}
                columnMenuItems={columnMenuItems}
                cssClass="new-grid-color allow-overflow-grid"
                // emptyRecordTemplate={() => (
                //   <div className="p-4">
                //     <div
                //       className="cursor-pointer text-blue-600 hover:underline"
                //       onClick={handleAddPet}
                //     >
                //       + Add New Pets
                //     </div>
                //   </div>
                // )}
                // aggregates={[
                //   {
                //     columns: [
                //       {
                //         type: "Custom",
                //         customAggregate: () => "",
                //         footerTemplate: () => (
                //           <div
                //             className="cursor-pointer bg-white py-2 pl-2 text-blue-600 hover:underline"
                //             onClick={handleAddPet}
                //           >
                //             + Add New Pets
                //           </div>
                //         ),
                //         field: "pet_name",
                //       },
                //     ],
                //   },
                // ]}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="pet_id"
                    headerText="Pet ID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="pet_name"
                    headerText="Pet Name"
                    width="100"
                    // textAlign="Center"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="pet_color"
                    headerText="Pet Color"
                    width="100"
                    // textAlign="Center"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="pet_coat"
                    headerText="Pet Coat"
                    width="100"
                    // textAlign="Center"
                    validationRules={{ required: true }}
                    editType="dropdownedit"
                    edit={petCoatParams}
                  />
                  <ColumnDirective
                    field="pet_breed"
                    headerText="Pet Breed"
                    width="100"
                    // textAlign="Center"
                    validationRules={{ required: true }}
                  />
                  <ColumnDirective
                    field="pet_size"
                    headerText="Pet Size"
                    width="100"
                    // textAlign="Center"
                    validationRules={{ required: true }}
                    editType="dropdownedit"
                    edit={petSizeParams}
                  />
                  <ColumnDirective
                    field="pet_image"
                    headerText="Photos"
                    width="100"
                    template={(props) => {
                      return (
                        <PhotoColumn
                          data={props.pet_image}
                          onClick={() => {
                            // if (!props.pet_image || !props.pet_image.length) {
                            //   return;
                            // }
                            const { column, index, foreignKeyData, ...rest } =
                              props;

                            setShowPetPhotosModal(true);
                            setSelectedPet(rest);
                            setSelectedPetId(props?.id || "");
                          }}
                        />
                      );
                    }}
                    // edit={PhotosEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    width="40"
                    textAlign="Center"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.id}
                            activeMenuId={activeMenuPets}
                            setActiveMenuId={setActiveMenuPets}
                            clickOutside={false}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // setSelectedHouseholdMember(props);
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingPets(true);
                              setShowPetsModal(true);
                              setActiveMenuPets(null);
                              petsReset({
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeletePetModal(true);
                              setActiveMenuPets(null);
                              setPetsToDeleteId(props?.id);
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    // Edit,
                    Sort,
                    Filter,
                    CommandColumn,
                    ColumnMenu,
                    Aggregate,
                  ]}
                />
              </GridComponent>
            </div>
          )}
          <AddNewButton
            onClick={() => {
              setIsEditingPets(false);
              setShowPetsModal(true);
              petsReset({
                pet_name: "",
                pet_color: "",
                pet_coat: "",
                pet_breed: "",
                pet_size: "",
                is_deleted: false,
              });
            }}
            name="Add New Pet"
          />
        </section>

        {/* Survey Section */}
        <section
          className={`mt-4 box-border flex w-full max-w-[1628px] flex-col items-start justify-start gap-2.5 overflow-hidden`}
        >
          {/* <header
          id="survey-questions-header"
          className="sticky top-0 flex w-full flex-row items-center justify-between bg-white"
        >
          <h2 className="mb-1  text-sm font-bold text-blue-500">
            SAMPLE SURVEY QUESTIONS
          </h2>
          {renderSurveyStatus()}
          <div className="flex items-center gap-2.5">
            <button
              className="btn-default-border flex cursor-pointer gap-2 px-[19px] py-1"
              onClick={scrollToBottom}
            >
              <FontAwesomeIcon icon={faArrowDown} className="h-4 w-4" />
              Jump to bottom
            </button>
            {!selectedDisasters.some((id) => {
              const disaster = disasters.find((d) => d.id === id);
              return disaster?.status === "closed";
            }) && (
              <button
                type="button"
                className="btn-default flex gap-2 px-5 py-1.5"
                onClick={handleSubmitSurvey} // Call the submit function
              >
                <FontAwesomeIcon icon={faCheck} className="h-4 w-4" />
                Submit Survey
              </button>
            )}
          </div>
        </header> */}
          <HeaderTitle
            title="General Survey Questions"
            id="generalsurveyquestion"
          />

          <main className="flex w-full flex-wrap gap-4 pb-8 text-xs text-darkslategray-100">
            {/* Always show survey questions */}
            {(surveyQuestions || []).map((section, sectionIndex) => (
              <div key={`survey-${sectionIndex}`} className="w-full">
                {/* <h2 className="text-xl mb-6 font-bold text-blue-900">
                {section.section}
              </h2> */}
                <div className="flex flex-wrap gap-4">
                  {(section.questions || []).map((question, questionIndex) => (
                    <div
                      key={`survey-q-${questionIndex}`}
                      className="flex w-[calc(33.333%-11px)] mq1125:w-[calc(50%-8px)] mq800:w-full"
                    >
                      <SurveyQuestionAccordion
                        handleSubmitSurvey={handleSubmitSurvey}
                        question={question.question}
                        type={question.type}
                        options={question.options || []}
                        followUp={question.followUp}
                        onResponseChange={handleResponseChange}
                        userResponse={
                          responses[question.question] || { answer: "" }
                        }
                        disabled={selectedDisasters.some((id) => {
                          const disaster = disasters.find((d) => d.id === id);
                          return disaster?.status === "closed";
                        })}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}

            {/* Show fire questions if disaster type is fire */}
            {currentDisasterType === "fire" &&
              (fireQuestions || []).map((section, sectionIndex) => (
                <div key={`fire-${sectionIndex}`} className="w-full">
                  {/* <h2 className="mb-6 text-xl font-bold text-blue-900">
                    {section.section}
                  </h2> */}
                  <header className="mb-0 flex flex-row items-center justify-between self-stretch rounded bg-[#003460] p-2">
                    <div>
                      <h2 className="text-sm font-bold text-white">
                        {section.section}
                      </h2>
                    </div>
                  </header>
                  <div className="mt-2 flex flex-wrap gap-4">
                    {(section.questions || []).map(
                      (question, questionIndex) => (
                        <div
                          key={`fire-q-${questionIndex}`}
                          className="flex w-[calc(33.333%-11px)] mq1125:w-[calc(50%-8px)] mq800:w-full"
                        >
                          <SurveyQuestionAccordion
                            question={question.question}
                            type={question.type}
                            options={question.options || []}
                            followUp={question.followUp}
                            onResponseChange={handleResponseChange}
                            userResponse={
                              responses[question.question] || { answer: "" }
                            }
                            disabled={selectedDisasters.some((id) => {
                              const disaster = disasters.find(
                                (d) => d.id === id
                              );
                              return disaster?.status === "closed";
                            })}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}

            {currentDisasterType === "tornado" &&
              (tornadoQuestions || []).map((section, sectionIndex) => (
                <div key={`tornado-${sectionIndex}`} className="w-full">
                  {/* <h2 className="mb-6 text-xl font-bold text-blue-900">
                    {section.section}
                  </h2> */}
                  <header className="mb-0 flex flex-row items-center justify-between self-stretch rounded bg-[#003460] p-2">
                    <div>
                      <h2 className="text-sm font-bold text-white">
                        {section.section}
                      </h2>
                    </div>
                  </header>
                  <div className="mt-2 flex flex-wrap gap-4">
                    {(section.questions || []).map(
                      (question, questionIndex) => (
                        <div
                          key={`tornado-q-${questionIndex}`}
                          className="flex w-[calc(33.333%-11px)] mq1125:w-[calc(50%-8px)] mq800:w-full"
                        >
                          <SurveyQuestionAccordion
                            question={question.question}
                            type={question.type}
                            options={question.options || []}
                            followUp={question.followUp}
                            onResponseChange={handleResponseChange}
                            userResponse={
                              responses[question.question] || { answer: "" }
                            }
                            disabled={selectedDisasters.some((id) => {
                              const disaster = disasters.find(
                                (d) => d.id === id
                              );
                              return disaster?.status === "closed";
                            })}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}

            {currentDisasterType === "flood" &&
              (floodQuestions || []).map((section, sectionIndex) => (
                <div key={`flood-${sectionIndex}`} className="w-full">
                  {/* <h2 className="mb-6 text-xl font-bold text-blue-900">
                    {section.section}
                  </h2> */}
                  <header className="mb-0 flex flex-row items-center justify-between self-stretch rounded bg-[#003460] p-2">
                    <div>
                      <h2 className="text-sm font-bold text-white">
                        {section.section}
                      </h2>
                    </div>
                  </header>
                  <div className="mt-2 flex flex-wrap gap-4">
                    {(section.questions || []).map(
                      (question, questionIndex) => (
                        <div
                          key={`flood-q-${questionIndex}`}
                          className="flex w-[calc(33.333%-11px)] mq1125:w-[calc(50%-8px)] mq800:w-full"
                        >
                          <SurveyQuestionAccordion
                            question={question.question}
                            type={question.type}
                            options={question.options || []}
                            followUp={question.followUp}
                            onResponseChange={handleResponseChange}
                            userResponse={
                              responses[question.question] || { answer: "" }
                            }
                            disabled={selectedDisasters.some((id) => {
                              const disaster = disasters.find(
                                (d) => d.id === id
                              );
                              return disaster?.status === "closed";
                            })}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}

            {currentDisasterType === "hurricane" &&
              (hurricaneQuestions || []).map((section, sectionIndex) => (
                <div key={`hurricane-${sectionIndex}`} className="w-full">
                  {/* <h2 className="mb-6 text-xl font-bold text-blue-900">
                    {section.section}
                  </h2> */}
                  <header className="mb-0 flex flex-row items-center justify-between self-stretch rounded bg-[#003460] p-2">
                    <div>
                      <h2 className="text-sm font-bold text-white">
                        {section.section}
                      </h2>
                    </div>
                  </header>
                  <div className="mt-2 flex flex-wrap gap-4">
                    {(section.questions || []).map(
                      (question, questionIndex) => (
                        <div
                          key={`hurricane-q-${questionIndex}`}
                          className="flex w-[calc(33.333%-11px)] mq1125:w-[calc(50%-8px)] mq800:w-full"
                        >
                          <SurveyQuestionAccordion
                            question={question.question}
                            type={question.type}
                            options={question.options || []}
                            followUp={question.followUp}
                            onResponseChange={handleResponseChange}
                            userResponse={
                              responses[question.question] || { answer: "" }
                            }
                            disabled={selectedDisasters.some((id) => {
                              const disaster = disasters.find(
                                (d) => d.id === id
                              );
                              return disaster?.status === "closed";
                            })}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
          </main>
          {/* <footer
          id="survey-questions-footer"
          className="sticky bottom-0 flex w-full flex-row items-center justify-end bg-white"
        >
          <div className="flex items-center gap-2.5">
            <button
              className="btn-default-border flex cursor-pointer gap-2 px-[19px] py-1"
              onClick={scrollToTop}
            >
              <FontAwesomeIcon icon={faArrowUp} className="h-4 w-4" />
              Jump to top
            </button>
            <button
              type="button"
              className="btn-default flex gap-2 px-5 py-1.5"
              onClick={handleSubmitSurvey} // Call the submit function
            >
              <FontAwesomeIcon icon={faCheck} className="h-4 w-4" />
              Submit Survey
            </button>
          </div>
        </footer> */}
        </section>
      </div>
    </>
  );
};

CaseManagementImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default CaseManagementImmediateResponseSurvey;
