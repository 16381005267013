import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { householdMembers, pets } from "./datasource";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import citizenProfileService from "../service/citizen/CitizenProfile";
import { USStates } from "./datasource";
import { Query } from "@syncfusion/ej2-data";
import DatePickerTemplate from "../components/DatePickerTemplate";
import { decrypt } from "../service/encryption/Encryption";
import mailer from "../service/api/mailer";
import { auth, db } from "../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
  query,
  where,
  getDocs,
  doc,
} from "firebase/firestore";
import countiesList from "../counties_list.json";
import { config } from "../../src/utils/config";
import toggleDown from "../assets/disaster-icons/toggle-down-white.svg";
import helpers from "../utils/helpers";

const sortSettings = {
  allowSorting: true,
};

const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  mode: "Dialog",
  showDeleteConfirmDialog: true,
  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "CheckBox",
};

const intl = new Internationalization();

const formatDate = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp); // Assuming timestamp is already a string
    return date.toLocaleDateString("en-US"); // Format as MM/DD/YYYY
  }
  return "";
};

const militaryVetTypes = [{ value: "Yes" }, { value: "No" }];
const relationshipTypes = [
  { value: "Husband" },
  { value: "Wife" },
  { value: "Daughter" },
  { value: "Son" },
  { value: "Other Relatives" },
];

const genderTypes = [{ value: "Male" }, { value: "Female" }];

const ethnicityTypes = [
  { value: "American Indian or Alaska Native" },
  { value: "Asian" },
  { value: "Hispanic/ Latino" },
  { value: "Native Hawaiian or Pacific Islander" },
  { value: "Tribal Affiliation" },
  { value: "Other" },
  { value: "White" },
  { value: "None of the Above" },
];

const petSizeTypes = [
  { value: "Small" },
  { value: "Medium" },
  { value: "Large" },
];

const petCoatTypes = [
  { value: "Wire" },
  { value: "Straight" },
  { value: "Shaggy" },
  { value: "Curly" },
  { value: "Wavy" },
];

const militaryvetParams = {
  params: {
    allowFiltering: true,
    dataSource: militaryVetTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const relationshipParams = {
  params: {
    allowFiltering: false,
    dataSource: relationshipTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const genderParams = {
  params: {
    allowFiltering: false,
    dataSource: genderTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const ethnicityParams = {
  params: {
    allowFiltering: false,
    dataSource: ethnicityTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const petSizeParams = {
  params: {
    allowFiltering: false,
    dataSource: petSizeTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

const petCoatParams = {
  params: {
    allowFiltering: true,
    dataSource: petCoatTypes,
    fields: { text: "value", value: "value" },
    query: new Query(),
  },
};

// Add this near the top with other constant definitions
const additionalInsuranceTypes = [
  { value: "Flood insurance" },
  { value: "Earthquake additional coverage" },
  { value: "Other disaster-related additional coverage" },
  { value: "I did not have additional insurance" },
];

const additionalInsuranceTypeParams = {
  params: {
    allowFiltering: true,
    dataSource: [
      { text: "Flood insurance", value: "Flood insurance" },
      {
        text: "Earthquake additional coverage",
        value: "Earthquake additional coverage",
      },
      {
        text: "Other disaster-related additional coverage",
        value: "Other disaster-related additional coverage",
      },
      {
        text: "I did not have additional insurance",
        value: "I did not have additional insurance",
      },
    ],
    fields: { text: "text", value: "value" },
    query: new Query(),
  },
};

const CitizenProfile = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempFormData, setTempFormData] = useState({});
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [householdMembers, setHouseholdMembers] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const errorTimeoutRef = useRef(null);
  const [dob, setDob] = useState(null);
  const [householdId, setHouseholdId] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(selectedUserId);
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (selectedUserId) {
      fetchData();
    }
  }, [selectedUserId]);

  const fetchData = async () => {
    const citizenData = await citizenProfileService.getCitizenProfile(
      "users",
      selectedUserId
    );
    if (citizenData) {
      setData(citizenData);
      setTempFormData(citizenData);
      if (citizenData.updatedData_at)
        setLastEditedTime(
          citizenData.updated_at instanceof Date
            ? citizenData.updated_at
            : new Date(citizenData.updated_at)
        );

      setHouseholdId(citizenData.household_id);
      const householdData = await citizenProfileService.getHouseholdInfo(
        citizenData.household_id
      );
      setHouseholdFormData({
        ...householdData,
        disability: citizenData.disability,
        military_vet: citizenData.military_vet,
      });
      setTempHouseholdFormData({
        ...householdData,
        disability: citizenData.disability,
        military_vet: citizenData.military_vet,
      });
      setLastHouseholdEditedTime(
        householdData.updated_at
          ? householdData.updated_at instanceof Date
            ? householdData.updated_at
            : new Date(householdData.updated_at)
          : null
      );

      // Fetch household members
      const householdMembersData = (
        await citizenProfileService.getHouseholdMembers(
          citizenData.household_id,
          citizenData.household_member_id
        )
      ).filter(
        (member) =>
          !member.is_deleted &&
          member.household_member_id !== citizenData.household_member_id
      );

      setHouseholdMembers(
        householdMembersData.map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth || "mm/dd/yyyy",
        }))
      );

      // Fetch pets - simplified filter
      const petsData = (
        await citizenProfileService.getPets(citizenData.household_id)
      ).filter((pet) => !pet.is_deleted);

      setPets(petsData);
    }
  };

  const togglePersonalInfo = (event) => {
    event.preventDefault();
    setIsPersonalInfoVisible(!isPersonalInfoVisible);
  };

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(true);
  };

  const saveChanges = async (event) => {
    event.preventDefault();
    await savePersonalInfo();
  };

  // Add this template function for the Full Name column
  const fullNameTemplate = (rowData) => {
    return `${rowData.first_name} ${rowData.last_name}`;
  };

  // Add this template function for editing the Full Name
  const fullNameEditTemplate = (props) => {
    return (
      <div className="flex gap-2">
        <input
          className="w-1/2 rounded border p-1"
          type="text"
          value={props.first_name || ""}
          onChange={(e) =>
            props.onChange({
              first_name: e.target.value,
              last_name: props.last_name,
            })
          }
          placeholder="First Name"
        />
        <input
          className="w-1/2 rounded border p-1"
          type="text"
          value={props.last_name || ""}
          onChange={(e) =>
            props.onChange({
              first_name: props.first_name,
              last_name: e.target.value,
            })
          }
          placeholder="Last Name"
        />
      </div>
    );
  };

  const savePersonalInfo = async () => {
    const errors = validatePersonalInfo(tempFormData);

    // Add validation for military and disability
    if (tempHouseholdFormData.military_vet === undefined) {
      errors.military_vet = "Please select if you are Active Military / Vet";
    }
    if (tempHouseholdFormData.disability === undefined) {
      errors.disability = "Please select if you have a disability";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);

      return;
    }

    try {
      const currentTime = new Date();
      const updatedData = {
        ...tempFormData,
        disability: tempHouseholdFormData.disability,
        military_vet: tempHouseholdFormData.military_vet,
        updated_at: currentTime,
      };

      // Check if address has changed - using safe null checks
      const addressChanged =
        data?.address1 !== updatedData.address1 ||
        data?.city !== updatedData.city ||
        data?.state !== updatedData.state ||
        data?.zip !== updatedData.zip ||
        data?.county !== updatedData.county;

      if (addressChanged) {
        alert("addressChanged");
        // Only proceed if we have all required address fields
        if (
          updatedData.address1 &&
          updatedData.city &&
          updatedData.state &&
          updatedData.zip
        ) {
          try {
            // Get new coordinates from Google Maps API
            const address = `${updatedData.address1}, ${updatedData.city}, ${updatedData.state}, ${updatedData.zip}`;
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                address
              )}&key=${config.google_maps}`
            );

            if (!response.ok) {
              throw new Error("Failed to fetch coordinates");
            }

            const geoData = await response.json();

            if (geoData.results && geoData.results.length > 0) {
              const { lat, lng } = geoData.results[0].geometry.location;
              updatedData.latitude = lat;
              updatedData.longitude = lng;
            }
          } catch (error) {
            console.error("Error getting coordinates:", error);
            // Continue with save even if coordinates update fails
          }
        }

        console.log("updatedData is here.address_id: ", updatedData);

        //Update the primary address in address collection
        const addressQuery = query(
          collection(db, "address", updatedData.address_id, "addresses"),
          where("is_primary_address", "==", true)
        );
        const addressSnapshot = await getDocs(addressQuery);
        if (!addressSnapshot.empty) {
          const addressRef = addressSnapshot.docs[0].ref;
          await updateDoc(addressRef, {
            address1: updatedData.address1,
            address2: updatedData.address2,
            city: updatedData.city,
            state: updatedData.state,
            zip: updatedData.zip,
            county: updatedData.county,
            updated_at: currentTime,
          });
        }

        // Check if county has changed and update center_id if necessary
        if (data?.county !== updatedData.county && updatedData.county) {
          try {
            // Query for centers in the new county
            const centersQuery = query(
              collection(db, "users"),
              where("user_role", "==", "center"),
              where("county", "==", updatedData.county)
            );

            const centersSnapshot = await getDocs(centersQuery);

            if (!centersSnapshot.empty) {
              // Get the first matching center's center_id
              const centerDoc = centersSnapshot.docs[0];
              const centerData = centerDoc.data();
              if (centerData?.center_id) {
                updatedData.center_id = centerData.center_id;
              }
            } else {
              alert("No centers found in the new county");
              updatedData.center_id = "";
            }
          } catch (error) {
            console.error("Error updating center assignment:", error);
            // Continue with save even if center reassignment fails
          }
        }
      }

      const requiredFields = [
        "first_name",
        "last_name",
        "mobile_number",
        "alternate_phone",
        "address1",
        "city",
        "state",
        "zip",
        "county",
        "alternate_contact_name",
        "alternate_email",
        "alternate_mobile_number",
        "birth_date",
        "citizenship",
        "ethnicity",
        "gender",
        "military_vet",
        "disability",
      ];

      const isProfileComplete = requiredFields.every((field) => {
        const value = updatedData[field];
        return value !== undefined && value !== null && value !== "";
      });

      if (isProfileComplete) {
        updatedData.status = "ACTIVE";
      }

      // Save the updated profile with the potentially updated status
      await citizenProfileService.updateCitizenProfile(
        "users",
        selectedUserId,
        updatedData
      );

      // Update local state
      setData(updatedData);
      setIsEditMode(false);
      setLastEditedTime(currentTime);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating profile:", error);
      // Optionally show error to user
      setValidationErrors({
        general: "Failed to update profile. Please try again.",
      });
    }
  };

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setIsEditMode(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Fields that should only contain letters, spaces, and hyphens
    const nameFields = ["first_name", "last_name", "alternate_contact_name"];
    // Fields that should only contain letters, numbers, spaces, and basic punctuation
    const addressFields = ["address1", "address2", "city", "county"];

    let sanitizedValue = value;
    let errors = { ...validationErrors };
    let shouldUpdateForm = true; // Flag to determine if we should update form

    if (nameFields.includes(name)) {
      // Only allow letters, spaces, and hyphens
      sanitizedValue = value.replace(/[^a-zA-Z\s-]/g, "");
    } else if (addressFields.includes(name)) {
      // Only allow letters, numbers, spaces, periods, commas, and hyphens
      sanitizedValue = value.replace(/[^a-zA-Z0-9\s.,#-]/g, "");
    } else if (
      name === "mobile_number" ||
      name === "alternate_phone" ||
      name === "alternate_mobile_number"
    ) {
      // Only allow numbers and hyphens for phone numbers
      sanitizedValue = value.replace(/[^0-9-]/g, "");
    } else if (name === "zip") {
      // Only allow numbers for zip code
      sanitizedValue = value.replace(/[^0-9]/g, "");
    } else if (name === "alternate_email" || name === "email") {
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Allow empty value or partial valid email
      if (value === "" || emailRegex.test(value)) {
        delete errors[name];
      } else {
        errors[name] = "Invalid Email";
        // Only update form if the email is empty or matches basic email pattern
        const basicEmailPattern = /^[^\s@]*@?[^\s@]*\.?[^\s@]*$/;
        shouldUpdateForm = basicEmailPattern.test(value);
      }
    }

    setValidationErrors(errors);

    if (shouldUpdateForm) {
      setTempFormData((prevData) => ({
        ...prevData,
        [name]: sanitizedValue,
      }));
    }
  };

  const handleInputBlur = async () => {
    const errors = validatePersonalInfo(tempFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);

      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);

      return;
    }

    const currentTime = new Date();
    const updatedData = {
      ...tempFormData,
      disability: tempHouseholdFormData.disability,
      military_vet: tempHouseholdFormData.military_vet,
      home_insurance: tempHouseholdFormData.home_insurance,
      automotive_insurance: tempHouseholdFormData.automotive_insurance,
      updated_at: currentTime,
    };

    try {
      await citizenProfileService.updateCitizenProfile(
        "users",
        selectedUserId,
        updatedData
      );
      setData(updatedData);
      setLastEditedTime(currentTime);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const handleHouseholdInputBlur = async () => {
    const errors = validateHouseholdInfo(tempHouseholdFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 5000);
      return;
    }

    try {
      const currentTime = new Date();

      // Remove nested household object and other unnecessary fields
      const { household, id, ...rawData } = tempHouseholdFormData;

      // Create a clean object with only the fields we want
      const updatedHouseholdData = {
        annual_income: rawData.annual_income || "",
        owner_or_renter: rawData.owner_or_renter || "",
        insurance: Boolean(rawData.insurance),
        household_size: rawData.household_size || "",
        home_insurance: Boolean(rawData.home_insurance),
        home_insurance_type: rawData.home_insurance_type || "",
        automotive_insurance: Boolean(rawData.automotive_insurance),
        automotive_insurance_type: rawData.automotive_insurance_type || "",
        additional_insurance_type: rawData.home_insurance_type || "",
        updated_at: currentTime,
      };

      if (!data.household_id) {
        console.error("No household ID found");
        return;
      }

      // Update the backend
      await citizenProfileService.updateHouseholdInfo(
        data.household_id,
        updatedHouseholdData
      );

      // Update the frontend state with the clean data
      setHouseholdFormData(updatedHouseholdData);
      setTempHouseholdFormData(updatedHouseholdData);
      setLastHouseholdEditedTime(currentTime);
      setIsHouseholdEditMode(false);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating household profile: ", error);
    }
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const renderField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = "",
    style = {} // Add style parameter
  ) => {
    const requiredFields = [
      "first_name",
      "last_name",
      "birth_date",
      "address1",
      "city",
      "state",
      "zip",
      "mobile_number",
      "alternate_phone",
      "email",
      "alternate_contact_name",
      "alternate_email",
      "alternate_mobile_number",
      "county",
      "gender",
      "citizenship",
      "ethnicity",
    ];
    const isRequired = requiredFields.includes(name);

    // Get counties for the selected state
    const getCountiesForState = (state) => {
      if (!state) return [];
      // Handle both full name and abbreviation
      const stateObj = USStates.find((s) => s.abbreviation === state);
      const stateName = stateObj ? stateObj.name : state;
      return countiesList
        .filter((county) => county.State === stateName)
        .map((county) => county.County);
    };

    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 ${className}`}
      >
        <div className="text-gray-600 relative flex items-center text-left text-xs leading-[20px]">
          {label}
          {isRequired && (
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          )}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${
            validationErrors[name] ? "border-red-500" : "border-gray-300"
          } px-3 py-2`}
          style={style}
        >
          {isEditMode ? (
            name === "birth_date" ? (
              <DatePickerTemplate
                value={tempFormData[name || ""]}
                onChange={(val) =>
                  handleInputChange({ target: { name, value: val } })
                }
                style={style}
              />
            ) : isDropdown ? (
              <select
                className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {name === "citizenship" ? (
                  <>
                    <option value="US Citizen">US Citizen</option>
                    <option value="Lawful Permanent Resident">
                      Lawful Permanent Resident
                    </option>
                    <option value="Alien Authorized to Work">
                      Alien Authorized to Work
                    </option>
                    <option value="Other">Other</option>
                  </>
                ) : name === "ethnicity" ? (
                  <>
                    <option value="African American or Black">
                      African American or Black
                    </option>
                    <option value="American Indian or Alaska Native">
                      American Indian or Alaska Native
                    </option>
                    <option value="Asian">Asian</option>
                    <option value="Hispanic/ Latino">Hispanic/ Latino</option>
                    <option value="Native Hawaiian or Pacific Islander">
                      Native Hawaiian or Pacific Islander
                    </option>
                    <option value="Tribal Affiliation">
                      Tribal Affiliation
                    </option>
                    <option value="Other">Other</option>
                    <option value="White">White</option>
                    <option value="None of the Above">None of the Above</option>
                  </>
                ) : name === "gender" ? (
                  <>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </>
                ) : name === "county" ? (
                  getCountiesForState(tempFormData.state).map((county) => (
                    <option key={county} value={county}>
                      {county}
                    </option>
                  ))
                ) : name === "state" ? (
                  USStates.map((state) => (
                    <option key={state.stateID} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  ))
                ) : null}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
                type="text"
                name={name}
                value={tempFormData[name] || ""}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                placeholder={placeholder}
                style={style}
              />
            )
          ) : (
            <div
              className={`flex-1 text-xs ${
                tempFormData[name]
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
            >
              {tempFormData[name] || placeholder}
            </div>
          )}
        </div>
        {isEditMode && validationErrors[name] && (
          <div className="mt-1 text-xs text-red-500">
            {validationErrors[name]}
          </div>
        )}
      </div>
    );
  };

  const validatePersonalInfo = (formData) => {
    const errors = {};
    const requiredFields = [
      "first_name",
      "last_name",
      "birth_date",
      "address1",
      "city",
      "state",
      "zip",
      "mobile_number",
      "alternate_phone",
      "email",
      "alternate_contact_name",
      "alternate_email",
      "alternate_mobile_number",
      "citizenship",
      "ethnicity",
      "gender",
    ];

    requiredFields.forEach((field) => {
      if (
        !formData[field] ||
        (typeof formData[field] === "string" && formData[field].trim() === "")
      ) {
        errors[field] = "This field is required";
      }
    });

    // Add email validation for alternate_email
    if (formData.alternate_email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.alternate_email)) {
        errors.alternate_email = "Invalid Email";
      }
    }
    if (formData.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        errors.email = "Invalid Email";
      }
    }

    return errors;
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isNewlyRegistered = localStorage.getItem("newlyRegistered");
    if (isNewlyRegistered === "true") {
      setShowPopup(true);
      localStorage.removeItem("newlyRegistered"); // Remove the flag after showing the popup
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            color: "#1e1919",
            marginBottom: "10px",
          }}
        >
          Welcome to your Citizen Profile!
        </h2>
        <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
          Please finish your profile to get the most out of our services.
        </p>
        <button
          onClick={handleClosePopup}
          className="btn-default"
          style={{
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  const [isHouseholdInfoVisible, setIsHouseholdInfoVisible] = useState(true);
  const [householdFormData, setHouseholdFormData] = useState({
    annual_income: "",
    ssn: "",
    owner_or_renter: "",
    insurance: false,
    household_size: "",
    disability: false,
    military_vet: false,
    home_insurance: false,
    home_insurance_type: "",
    automotive_insurance: false,
    automotive_insurance_type: "",
    additional_insurance: false,
    additional_insurance_type: "",
  });
  const [tempHouseholdFormData, setTempHouseholdFormData] = useState({
    ...householdFormData,
  });
  const [lastHouseholdEditedTime, setLastHouseholdEditedTime] = useState(null);

  const [isHouseholdEditMode, setIsHouseholdEditMode] = useState(false);

  const toggleHouseholdInfo = (event) => {
    event.preventDefault();
    setIsHouseholdInfoVisible(!isHouseholdInfoVisible);
  };

  const startHouseholdEditing = (event) => {
    event.preventDefault();
    setIsHouseholdEditMode(true);
  };

  const validateHouseholdInfo = (formData) => {
    const errors = {};
    const requiredFields = ["owner_or_renter", "insurance", "household_size"];

    requiredFields.forEach((field) => {
      if (
        formData[field] === undefined ||
        formData[field] === "" ||
        formData[field] === null
      ) {
        errors[field] = "This field is required";
      }
    });

    return errors;
  };

  const saveHouseholdChanges = async (event) => {
    event.preventDefault();

    try {
      const currentTime = new Date();

      const updatedHouseholdData = {
        annual_income: String(tempHouseholdFormData.annual_income || ""),
        owner_or_renter: String(tempHouseholdFormData.owner_or_renter || ""),
        insurance: Boolean(tempHouseholdFormData.insurance),
        household_size: String(tempHouseholdFormData.household_size || ""),
        home_insurance: Boolean(tempHouseholdFormData.home_insurance),
        home_insurance_type: String(
          tempHouseholdFormData.home_insurance_type || ""
        ),
        automotive_insurance: Boolean(
          tempHouseholdFormData.automotive_insurance
        ),
        automotive_insurance_type: String(
          tempHouseholdFormData.automotive_insurance_type || ""
        ),
        additional_insurance: Boolean(
          tempHouseholdFormData.additional_insurance
        ),
        additional_insurance_type: String(
          tempHouseholdFormData.additional_insurance_type || ""
        ),
        updated_at: currentTime,
      };

      if (!data.household_id) {
        throw new Error("No household ID found");
      }

      const result = await citizenProfileService.updateHouseholdInfo(
        data.household_id,
        updatedHouseholdData
      );

      setHouseholdFormData(result);
      setTempHouseholdFormData(result);
      setLastHouseholdEditedTime(currentTime);
      setIsHouseholdEditMode(false);
      setValidationErrors({});
    } catch (error) {
      console.error("Error updating household profile:", error);
    }
  };

  const cancelHouseholdEditing = (event) => {
    event.preventDefault();
    setTempHouseholdFormData({ ...householdFormData });
    setIsHouseholdEditMode(false);
  };

  const handleHouseholdInputChange = (event) => {
    const { name, value } = event.target;
    setTempHouseholdFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "disability" ||
        name === "military_vet" ||
        name === "insurance" ||
        name === "home_insurance" ||
        name === "automotive_insurance"
          ? value === "Yes"
          : value,
    }));
  };

  const renderHouseholdField = (
    label,
    name,
    placeholder,
    isDropdown = false,
    className = "",
    isOptional = false
  ) => {
    const isRequired = !isOptional;

    const handleAnnualIncomeChange = (e) => {
      let inputValue = e.target.value;

      const numericValue = inputValue.replace(/[^0-9]/g, "");

      handleHouseholdInputChange({
        target: { name: name, value: numericValue ? `$${numericValue}` : "" },
      });
    };

    const validateAnnualIncome = () => {
      if (
        name === "annual_income" &&
        (!tempHouseholdFormData[name] || tempHouseholdFormData[name] === "$")
      ) {
        // Trigger validation error if the field is invalid
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Annual income is required",
        }));
      } else {
        // Remove the error if it's valid
        setValidationErrors((prevErrors) => {
          const { [name]: _, ...newErrors } = prevErrors;
          return newErrors;
        });
      }
    };

    const handleHouseholdSizeChange = (e) => {
      let inputValue = e.target.value;

      // Remove non-numeric characters
      const numericValue = inputValue.replace(/[^0-9]/g, "");

      // Convert to number and limit to 20
      let validNumber = numericValue
        ? Math.min(parseInt(numericValue, 10), 20)
        : "";

      handleHouseholdInputChange({
        target: { name: "household_size", value: validNumber },
      });
    };

    const validateHouseholdSize = () => {
      if (!tempHouseholdFormData["household_size"]) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          household_size: "Household size is required",
        }));
      } else {
        setValidationErrors((prevErrors) => {
          const { household_size, ...newErrors } = prevErrors;
          return newErrors;
        });
      }
    };

    return (
      <div
        className={`flex flex-col items-start justify-start gap-1 hover:cursor-pointer ${className}`}
      >
        <div className="text-gray-600 relative text-left  text-xs leading-[20px] hover:cursor-pointer">
          {label}
          {isRequired ? (
            <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
              *
            </p>
          ) : (
            <span className="ml-1 text-xs text-gray-400">(Optional)</span>
          )}
        </div>
        <div
          className={`self-stretch rounded-md ${
            isHouseholdEditMode ? "bg-white" : "bg-gray-200"
          } box-border flex h-10 flex-row items-center justify-between border-[1px] border-solid ${
            validationErrors[name] ? "border-red-500" : "border-gray-300"
          } px-3 py-2`}
        >
          {isHouseholdEditMode ? (
            name === "household_size" ? (
              // Custom Input for Household Size (Integer Only, Max 20)
              <input
                type="text"
                className="w-full bg-white text-left text-xs text-black [border:none] [outline:none]"
                placeholder={placeholder}
                name={name}
                value={tempHouseholdFormData[name] || ""}
                onChange={handleHouseholdSizeChange}
                onBlur={validateHouseholdSize}
              />
            ) : isDropdown ? (
              <select
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none] hover:cursor-pointer"
                name={name}
                value={
                  name === "insurance"
                    ? tempHouseholdFormData[name]
                      ? "Yes"
                      : "No"
                    : tempHouseholdFormData[name] || ""
                }
                onChange={handleHouseholdInputChange}
                // onBlur={handleHouseholdInputBlur}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {name === "owner_or_renter" ? (
                  <>
                    <option value="Owner" className="hover:cursor-pointer">
                      Owner
                    </option>
                    <option value="Renter" className="hover:cursor-pointer">
                      Renter
                    </option>
                    <option
                      value="Rent to Own"
                      className="hover:cursor-pointer"
                    >
                      Rent to Own
                    </option>
                  </>
                ) : name === "annual_income" ? (
                  <>
                    <option value="0-20000" className="hover:cursor-pointer">
                      0-$20,000
                    </option>
                    <option
                      value="20001-40000"
                      className="hover:cursor-pointer"
                    >
                      $20,001-$40,000
                    </option>
                    <option
                      value="40001-60000"
                      className="hover:cursor-pointer"
                    >
                      $40,001-$60,000
                    </option>
                    <option
                      value="60001-80000"
                      className="hover:cursor-pointer"
                    >
                      $60,001-$80,000
                    </option>
                    <option
                      value="80001-100000"
                      className="hover:cursor-pointer"
                    >
                      $80,001-$100,000
                    </option>
                    <option value="100000+" className="hover:cursor-pointer">
                      Greater than $100,000
                    </option>
                  </>
                ) : name === "home_insurance_type" ? (
                  <>
                    <option value="Homeowners" className="hover:cursor-pointer">
                      Homeowners
                    </option>
                    <option value="Renter" className="hover:cursor-pointer">
                      Renter
                    </option>
                  </>
                ) : name === "additional_insurance_type" ? (
                  <>
                    <option
                      value="Flood insurance"
                      className="hover:cursor-pointer"
                    >
                      Flood insurance
                    </option>
                    <option
                      value="Earthquake additional coverage"
                      className="hover:cursor-pointer"
                    >
                      Earthquake additional coverage
                    </option>
                    <option
                      value="Other disaster-related additional coverage"
                      className="hover:cursor-pointer"
                    >
                      Other disaster-related additional coverage
                    </option>
                    <option
                      value="I did not have additional insurance"
                      className="hover:cursor-pointer"
                    >
                      I did not have additional insurance
                    </option>
                  </>
                ) : name === "automotive_insurance_type" ? (
                  <>
                    <option
                      value="Full Coverage"
                      className="hover:cursor-pointer"
                    >
                      Full Coverage
                    </option>
                    <option value="Liability" className="hover:cursor-pointer">
                      Liability
                    </option>
                  </>
                ) : (
                  <option value="" disabled>
                    {placeholder}
                  </option>
                )}
              </select>
            ) : (
              <input
                className="w-full bg-white text-left  text-xs text-black [border:none] [outline:none]"
                placeholder={placeholder}
                name={name}
                value={tempHouseholdFormData[name] || ""}
                onChange={
                  name === "annual_income"
                    ? handleAnnualIncomeChange
                    : handleHouseholdInputChange
                }
                onBlur={
                  name === "annual_income"
                    ? validateAnnualIncome
                    : handleHouseholdInputBlur
                }
              />
            )
          ) : (
            <div
              className={`flex-1  text-xs ${
                householdFormData[name] !== undefined &&
                householdFormData[name] !== ""
                  ? "font-semibold text-gray-800"
                  : "text-gray-500"
              } text-left`}
              additional_insurance_type
            >
              {name === "insurance"
                ? householdFormData[name]
                  ? "Yes"
                  : "No"
                : householdFormData[name] || placeholder}
            </div>
          )}
        </div>
        {isHouseholdEditMode && validationErrors[name] && (
          <div className="mt-1 text-xs text-red-500">
            {validationErrors[name]}
          </div>
        )}
      </div>
    );
  };

  {
    /* Household Members */
  }
  const [isHouseholdMembersVisible, setIsHouseholdMembersVisible] =
    useState(true);
  const [lastHouseholdMembersEditedTime, setLastHouseholdMembersEditedTime] =
    useState(null);

  const toggleHouseholdMembers = (event) => {
    event.preventDefault();
    setIsHouseholdMembersVisible(!isHouseholdMembersVisible);
  };

  const gridRef = useRef(null);

  const handleAddClick = (event) => {
    // Add event parameter and prevent default form submission
    event.preventDefault();
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const createConfirmDialog = (title, content, onConfirm) => {
    const dialog = new DialogComponent({
      header: title,
      content: content,
      buttons: [
        {
          click: () => {
            onConfirm();
            closeAndDestroyDialog(dialog);
          },
          buttonModel: { content: "Yes", isPrimary: true },
        },
        {
          click: () => {
            closeAndDestroyDialog(dialog);
          },
          buttonModel: { content: "No" },
        },
      ],
      width: "300px",
      isModal: true,
      visible: false,
      cssClass: "delete-confirmation-dialog",
      close: () => closeAndDestroyDialog(dialog),
    });
    dialog.appendTo("#dialog-container");

    // Apply custom styles to the dialog header
    dialog.created = () => {
      if (dialog.element) {
        const headerElement = dialog.element.querySelector(
          ".e-dlg-header-content"
        );
        if (headerElement) {
          headerElement.style.backgroundColor = "#348BFF";
          headerElement.style.color = "#FFFFFF";
          headerElement.style.padding = "10px";
        }
      }
    };

    dialog.show();
  };

  const closeAndDestroyDialog = (dialog) => {
    dialog.hide();
    setTimeout(() => {
      dialog.destroy();
      const dialogElement = document.querySelector(
        ".delete-confirmation-dialog"
      );
      if (dialogElement) {
        dialogElement.remove();
      }
    }, 300); // Wait for the hide animation to complete
  };

  const actionBegin = async (args) => {
    // Initialize data object if it doesn't exist
    if (!args.data) {
      args.data = {};
    }

    if (args.requestType === "add") {
      // Set default values for new records
      args.data = {
        ...args.data,
        disability: "No",
        military_vet: "No",
      };
    }

    if (args.requestType === "beginEdit") {
      // Get the existing data from the row
      const rowData = args.rowData;

      // Set the date of birth if it exists
      if (rowData?.date_of_birth) {
        setDob(rowData.date_of_birth);
      }

      // Safely set the values with defaults
      args.data = {
        ...args.data,
        ...rowData,
        disability: rowData?.disability || "No",
        military_vet: rowData?.military_vet || "No",
      };
    }

    if (args.requestType === "beginEdit" || args.requestType === "add") {
      gridRef.current.columns.forEach((col) => {
        if (col.validationRules && col.validationRules.required) {
          // Append * to required field headers
          col.headerText = col.headerText.replace(/\*$/, "") + " *";
        }
      });
    }
  };

  const actionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        const isDuplicate = await checkForDuplicates(args.data);
        if (isDuplicate) {
          DialogUtility.alert({
            title: "Duplicate Entry",
            content: args.data.email
              ? "A household member with this email already exists."
              : "A household member with this name already exists.",
            showCloseIcon: true,
            closeOnEscape: true,
            position: { X: "center", Y: "center" },
          });
          return;
        }

        if (args.data.email) {
          const usersRef = collection(db, "users");
          const q = query(usersRef, where("email", "==", args.data.email));
          const querySnapshot = await getDocs(q);

          if (
            !querySnapshot.empty &&
            querySnapshot.docs[0].data().email !== data.email
          ) {
            const userData = querySnapshot.docs[0].data();

            if (
              userData.household_id &&
              userData.household_id !== householdId
            ) {
              DialogUtility.alert({
                title: "User Already in Household",
                content: "This user is already part of another household.",
                showCloseIcon: true,
                closeOnEscape: true,
                position: { X: "center", Y: "center" },
              });
              return;
            }
          }
        }

        // Only check Firebase if email exists
        const newMemberData = {
          ...args.data,
          household_member_id:
            args.data.household_member_id || generateUniqueID(),
          is_deleted: false,
          date_of_birth: dob
            ? new Date(dob).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
          disability: args.data.disability || "No",
          military_vet: args.data.military_vet || "No",
          email: args.data.email || "",
          gender: args.data.gender || "",
          ethnicity: args.data.ethnicity || "",
          //fema_number: args.data.fema_number || "",
          acc_created: false,
        };

        await citizenProfileService.addHouseholdMember(
          householdId,
          newMemberData
        );
        setDob(() => null);
        await fetchData();
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedMemberData = {
          ...args.data,
          is_deleted: false,
          date_of_birth: dob
            ? new Date(dob).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : null,
          disability: args.data.disability || "No",
          military_vet: args.data.military_vet || "No",
          email: args.data.email || "",
          gender: args.data.gender || "",
          ethnicity: args.data.ethnicity || "",
          fema_number: args.data.fema_number || "",
          acc_created: false,
        };
        await citizenProfileService.updateHouseholdMember(
          householdId,
          updatedMemberData.id,
          updatedMemberData
        );
        setDob(() => null);
      }
      setLastHouseholdMembersEditedTime(new Date());
      await fetchData();
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedMemberData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updateHouseholdMember(
        householdId,
        updatedMemberData.id,
        updatedMemberData
      );
      setLastHouseholdMembersEditedTime(new Date());
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Household Member"
            : "Add New Household Member";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#467BA4";
          }
          if (header) {
            header.style.color = "#467BA4";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const checkForDuplicates = async (memberData) => {
    try {
      const existingMembers = await citizenProfileService.getHouseholdMembers(
        householdId
      );

      if (memberData.email) {
        return existingMembers.some(
          (member) =>
            !member.is_deleted &&
            member.email &&
            member.email.toLowerCase() === memberData.email.toLowerCase()
        );
      } else {
        return existingMembers.some(
          (member) =>
            !member.is_deleted &&
            member.first_name.toLowerCase() ===
              memberData.first_name.toLowerCase() &&
            member.last_name.toLowerCase() ===
              memberData.last_name.toLowerCase()
        );
      }
    } catch (error) {
      console.error("Error checking for duplicates:", error);
      return false;
    }
  };

  // Filter household members to only include those with status true
  const filteredHouseholdMembers = Array.isArray(householdMembers)
    ? householdMembers
        .filter((member) => !member.is_deleted)
        .map((member) => ({
          ...member,
          date_of_birth: member.date_of_birth
            ? new Date(member.date_of_birth).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "mm/dd/yyyy",
        }))
    : [];

  {
    /*Pets*/
  }
  const [pets, setPets] = useState([]);
  const [isPetsVisible, setIsPetsVisible] = useState(true);
  const [lastPetsEditedTime, setLastPetsEditedTime] = useState(null);
  const petsGridRef = useRef(null);

  const togglePets = () => {
    setIsPetsVisible(!isPetsVisible);
  };

  const handleAddPet = (event) => {
    if (petsGridRef.current) {
      petsGridRef.current.addRecord();
    }
  };

  const actionBeginPets = (args) => {
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      petsGridRef.current.columns.forEach((col) => {
        if (col.validationRules && col.validationRules.required) {
          // Append * to required field headers
          col.headerText = col.headerText.replace(/\*$/, "") + " *";
        }
      });
    }
  };

  const petsActionComplete = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure pet_id and status are set
        const newPetData = {
          ...args.data,
          pet_id: generateUniqueID(),
          is_deleted: false,
        };
        await citizenProfileService.addPet(householdId, newPetData);
      } else if (args.action === "edit") {
        // Handle status changes during edit
        const updatedPetData = {
          ...args.data,
          is_deleted: false,
        };
        // Use the id field for updating, as it corresponds to the Firestore document ID
        await citizenProfileService.updatePet(
          householdId,
          updatedPetData.id,
          updatedPetData
        );
      }
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(householdId);
      setPets(updatedPets);
    } else if (args.requestType === "delete") {
      // Set status to false instead of deleting
      const updatedPetData = {
        ...args.data[0],
        is_deleted: true,
      };
      await citizenProfileService.updatePet(
        householdId,
        updatedPetData.id, // Use id instead of pet_id
        updatedPetData
      );
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(householdId);
      setPets(updatedPets);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Pet" : "Add New Pet";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  // Filter pets to only include those with status true
  const filteredPets = Array.isArray(pets)
    ? pets.filter((pet) => !pet.is_deleted)
    : [];

  const deletePet = async (data) => {
    try {
      await citizenProfileService.deletePet(householdId, data.pet_id);
      setLastPetsEditedTime(new Date());
      // Refresh the grid data
      const updatedPets = await citizenProfileService.getPets(householdId);
      setPets(updatedPets);
    } catch (error) {
      console.error("Error deleting pet:", error);
    }
  };

  const handleCommandClick = async (args) => {
    if (args.commandColumn.type === "Invite") {
      if (args.rowData.email && !args.rowData.acc_created) {
        const docRef = await addDoc(collection(db, "invited_users"), {
          invited_by: data.id,
          sent_to: args.rowData.email,
          date_invited: serverTimestamp(),
          first_name: helpers.capitalizeFirstLetter(args.rowData.first_name),
          last_name: helpers.capitalizeFirstLetter(args.rowData.last_name),
          type: "household_member",
          acc_created: false,
        });

        // Update the document to include its ID
        await updateDoc(docRef, {
          id: docRef.id,
        });

        //const link = `${registrationLink}/${invitedUser.id}/${user.id}/${user?.household_id}/${householdData?.household_member_id}/${params?.id}`;

        const link = `${window.location.origin}/registration/${docRef.id}/${data.id}/${data?.household_id}/${data?.household_member_id}`;
        try {
          await mailer.sendHouseholdMemberInviteEmail(
            args.rowData.email,
            helpers.capitalizeFirstLetter(args.rowData.first_name),
            link,
            helpers.capitalizeFirstLetter(data.first_name)
          );
        } catch (error) {
          console.error("Error sending email:", error);
        }
      } else {
      }
    }
  };

  return (
    <form
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header
          className="mb-2 flex flex-row items-center justify-between self-stretch rounded bg-[#003460] p-2"
          id="personalinfo"
        >
          <div>
            <h2 className="text-xl font-bold text-white">
              Personal Information
            </h2>
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#003460] text-white transition-all duration-300 ease-in-out"
              onClick={togglePersonalInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPersonalInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <img src={toggleDown} alt="down" className="h-8 w-8" />
              </span>
            </button>
          </div>
        </header>
        {isPersonalInfoVisible && (
          <div className="flex flex-col gap-4 self-stretch px-2">
            <div className="flex flex-row gap-4">
              {renderField("First Name", "first_name", "", false, "flex-1")}
              {renderField("Last Name", "last_name", "", false, "flex-1")}
              {renderField(
                "Date of Birth",
                "birth_date",
                "MM/DD/YYYY",
                false,
                "flex-1",
                isEditMode ? { border: "none", paddingLeft: 0 } : {}
              )}
            </div>

            {renderField("Address 1", "address1", "")}
            {renderField("Address 2", "address2", "")}
            <div className="flex flex-row gap-4">
              {renderField("City", "city", "", false, "flex-1")}
              {renderField(
                "Select a state",
                "state",
                "Select a state",
                true,
                "flex-1"
              )}
              {renderField("Zip", "zip", "e.g., 62704", false, "flex-1")}
              {renderField(
                "County",
                "county",
                "Select a county",
                true,
                "flex-1"
              )}
            </div>
            <div className="flex flex-row gap-4">
              {renderField("Email", "email", "", false, "flex-1")}
              {renderField(
                "Phone Number",
                "mobile_number",
                "",
                false,
                "flex-1"
              )}
              {renderField(
                "Alternate Phone Number",
                "alternate_phone",
                "",
                false,
                "flex-1"
              )}
            </div>
            <div className="flex flex-row gap-4">
              {renderField(
                "Citizenship",
                "citizenship",
                "Citizenship",
                true,
                "flex-1"
              )}
              {renderField(
                "Ethnicity",
                "ethnicity",
                "Ethnicity",
                true,
                "flex-1"
              )}
              {renderField("Sex", "gender", "Sex", true, "flex-1")}
            </div>
            <header className="mt-2 flex flex-row items-center justify-between self-stretch">
              <div>
                <h2 className="font-Inter text-base font-bold text-[#003460]">
                  Alternate Contact
                </h2>
              </div>
            </header>
            <div className="flex flex-row gap-4">
              {renderField(
                "Contact Name",
                "alternate_contact_name",
                "",
                false,
                "flex-1"
              )}
              {renderField("Email", "alternate_email", "", false, "flex-1")}
              {renderField(
                "Phone Number",
                "alternate_mobile_number",
                "",
                false,
                "flex-1"
              )}
            </div>
            <div className="mb-2 flex flex-row gap-2">
              <div className="w-auto">
                <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                  Do you have a disability?
                  {isEditMode && (
                    <span
                      className="ml-1 text-red-500"
                      style={{ fontSize: "26px" }}
                    >
                      *
                    </span>
                  )}
                </p>
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="disability"
                      value="Yes"
                      checked={tempHouseholdFormData.disability === true}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">Yes</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="disability"
                      value="No"
                      checked={tempHouseholdFormData.disability === false}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">No</span>
                  </label>
                </div>
                {validationErrors.disability && (
                  <div className="mt-1 text-xs text-red-500">
                    {validationErrors.disability}
                  </div>
                )}
              </div>

              <div className="w-auto" style={{ marginLeft: "50px" }}>
                <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                  Are you Active Military / Vet?
                  {isEditMode && (
                    <span
                      className="ml-1 text-red-500"
                      style={{ fontSize: "26px" }}
                    >
                      *
                    </span>
                  )}
                </p>
                <div className="flex gap-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="military_vet"
                      value="Yes"
                      checked={tempHouseholdFormData.military_vet === true}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">Yes</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="military_vet"
                      value="No"
                      checked={tempHouseholdFormData.military_vet === false}
                      onChange={(e) => {
                        handleHouseholdInputChange(e);
                        handleInputBlur();
                      }}
                      disabled={!isEditMode}
                      className={`mr-2 ${!isEditMode ? "" : "cursor-pointer"}`}
                    />
                    <span className="text-xs">No</span>
                  </label>
                </div>
                {validationErrors.military_vet && (
                  <div className="mt-1 text-xs text-red-500">
                    {validationErrors.military_vet}
                  </div>
                )}
              </div>
            </div>
            {isEditMode ? (
              <>
                <div className="mt-2 flex w-full gap-2">
                  <button
                    type="button"
                    className="flex cursor-pointer gap-1 bg-transparent text-base text-dark-midnight-blue"
                    onClick={saveChanges}
                  >
                    <FaSave className="h-4 w-4" />
                    Save
                  </button>
                  <button
                    type="button"
                    className="flex cursor-pointer gap-1 bg-transparent text-base text-[#9c1717]"
                    onClick={cancelEditing}
                  >
                    <FaTimes className="h-4 w-4" />
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <div className="flex w-full">
                <button
                  type="button"
                  className=" flex cursor-pointer gap-1 bg-transparent text-base text-dark-midnight-blue"
                  onClick={startEditing}
                >
                  <FaEdit className="h-4 w-4" />
                  Edit Personal Information
                </button>
              </div>
            )}
          </div>
        )}
      </section>

      {/* Household Information */}
      <section className="mt-4 flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header
          className="mb-2 flex flex-row items-center justify-between self-stretch rounded bg-[#003460] p-2"
          id="householdinfo"
        >
          <div>
            <h2 className="text-xl font-bold text-white">
              Household Information
            </h2>
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#003460] text-white transition-all duration-300 ease-in-out"
              onClick={toggleHouseholdInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <img src={toggleDown} alt="down" className="h-8 w-8" />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdInfoVisible && (
          <div className="mt-2 flex flex-col gap-2 self-stretch px-2">
            <div className="flex flex-row gap-4 hover:cursor-pointer">
              {renderHouseholdField(
                "Annual Household Income",
                "annual_income",
                "Select Annual Income",
                true,
                "flex-1",
                "number",
                true // Mark as optional
              )}

              {renderHouseholdField(
                "Owner or Renter?",
                "owner_or_renter",
                "Select Owner or Renter",
                true,
                "flex-1"
              )}
              {/* {renderHouseholdField(
                "Do you have insurance?",
                "insurance",
                "Select Yes or No",
                true,
                "flex-1"
              )} */}
              {renderHouseholdField(
                "Household size",
                "household_size",
                "",
                false,
                "flex-1"
              )}
            </div>

            <div className="flex w-full flex-row gap-8 pt-3">
              {/* First Column - Home Insurance */}
              <div className="flex-1">
                <div className="w-full">
                  <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                    Do you have Home Insurance?
                    {isEditMode && (
                      <span
                        className="ml-1 text-red-500"
                        style={{ fontSize: "26px" }}
                      >
                        *
                      </span>
                    )}
                  </p>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="home_insurance"
                        value="Yes"
                        checked={tempHouseholdFormData.home_insurance === true}
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="home_insurance"
                        value="No"
                        checked={tempHouseholdFormData.home_insurance === false}
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">No</span>
                    </label>
                  </div>
                </div>
              </div>

              {/* Second Column - Automotive Insurance */}
              <div className="flex-1">
                <div className="w-full">
                  <p className="text-gray-600 mb-1 pb-3 pt-1 text-xs">
                    Do you have Automotive Insurance?
                    {isEditMode && (
                      <span
                        className="ml-1 text-red-500"
                        style={{ fontSize: "26px" }}
                      >
                        *
                      </span>
                    )}
                  </p>
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="automotive_insurance"
                        value="Yes"
                        checked={
                          tempHouseholdFormData.automotive_insurance === true
                        }
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="automotive_insurance"
                        value="No"
                        checked={
                          tempHouseholdFormData.automotive_insurance === false
                        }
                        onChange={(e) => {
                          handleHouseholdInputChange(e);
                          handleInputBlur();
                        }}
                        disabled={!isHouseholdEditMode}
                        className={`mr-2 ${
                          !isHouseholdEditMode ? "" : "cursor-pointer"
                        }`}
                      />
                      <span className="text-xs">No</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex w-full flex-row gap-8 pt-3">
              <div className="flex-1">
                {tempHouseholdFormData.home_insurance &&
                  renderHouseholdField(
                    "Home Insurance Type",
                    "home_insurance_type",
                    "Select Home Insurance Type",
                    true,
                    "w-full",
                    "dropdownedit",
                    false
                  )}
              </div>
              <div className="flex-1">
                {renderHouseholdField(
                  "Do you have additional insurance on your home or contents?",
                  "additional_insurance_type",
                  "Select Additional Insurance Type",
                  true,
                  "w-full"
                )}
              </div>
              <div className="flex-1" />
            </div>
            {isHouseholdEditMode ? (
              <>
                <div className="mt-4 flex w-full gap-2">
                  <button
                    type="button"
                    className=" flex cursor-pointer gap-1 bg-transparent text-base text-dark-midnight-blue"
                    onClick={saveHouseholdChanges}
                  >
                    <FaSave className="h-4 w-4" />
                    Save
                  </button>
                  <button
                    type="button"
                    className="flex cursor-pointer gap-1 bg-transparent text-base text-[#9c1717]"
                    onClick={cancelHouseholdEditing}
                  >
                    <FaTimes className="h-4 w-4" />
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <div className="mt-4 flex w-full">
                <button
                  type="button"
                  className=" flex cursor-pointer  gap-1 bg-transparent text-base text-dark-midnight-blue"
                  onClick={startHouseholdEditing}
                >
                  <FaEdit className="h-4 w-4" />
                  Edit Household Information
                </button>
              </div>
            )}
          </div>
        )}
      </section>

      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pt-4">
        <header
          className="mb-2 flex flex-row items-center justify-between self-stretch rounded bg-[#003460] p-2"
          id="householdinfo"
        >
          <div>
            <h2 className="text-xl font-bold text-white">
              Additional Household Members
            </h2>
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#003460] text-white transition-all duration-300 ease-in-out"
              onClick={toggleHouseholdMembers}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isHouseholdMembersVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <img src={toggleDown} alt="down" className="h-8 w-8" />
              </span>
            </button>
          </div>
        </header>
        {isHouseholdMembersVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={gridRef}
              dataSource={filteredHouseholdMembers}
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionBegin={actionBegin}
              actionComplete={actionComplete}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              commandClick={handleCommandClick}
              cssClass="new-grid-color"
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="acc_created"
                  headerText="householdMemberID"
                  visible={false}
                />
                <ColumnDirective
                  field="household_member_id"
                  headerText="householdMemberID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="first_name"
                  headerText="First Name"
                  validationRules={{ required: true }}
                  width="100"
                />
                <ColumnDirective
                  field="last_name"
                  headerText="Last Name"
                  validationRules={{ required: true }}
                  width="100"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email"
                  width="100"
                  // validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="date_of_birth"
                  headerText="Date of Birth"
                  width="100"
                  format="MM/dd/yyyy"
                  editTemplate={(props) => (
                    <DatePickerTemplate
                      label="Date of Birth *"
                      onChange={(val) => {
                        setDob(() => val);
                      }}
                      value={props.date_of_birth}
                    />
                  )}
                />
                <ColumnDirective
                  field="relationship"
                  headerText="Relationship"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={relationshipParams}
                />
                <ColumnDirective
                  field="gender"
                  headerText="Gender"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={genderParams}
                />
                <ColumnDirective
                  field="ethnicity"
                  headerText="Ethnicity"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={ethnicityParams}
                />
                {/* <ColumnDirective
                  field="fema_number"
                  headerText="FEMA Number"
                  width="100"
                  validationRules={{ required: true }}
                /> */}
                <ColumnDirective
                  field="disability"
                  headerText="Person with disability?"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={militaryvetParams}
                />
                <ColumnDirective
                  field="military_vet"
                  headerText="Active Military/ Vet"
                  width="120"
                  editType="dropdownedit"
                  edit={militaryvetParams}
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="180"
                  commands={[
                    {
                      type: "Invite",
                      buttonOption: {
                        content: "Invite",
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
            <div className="flex w-full">
              <button
                type="button"
                className=" flex cursor-pointer gap-1 bg-transparent text-base text-dark-midnight-blue"
                onClick={handleAddClick}
              >
                <FaPlus className="h-4 w-4" />
                Add Household Member
              </button>
            </div>
          </div>
        )}
      </section>

      {/* Pets Section */}
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4 pt-4">
        <header
          className="mb-2 flex flex-row items-center justify-between self-stretch rounded bg-[#003460] p-2"
          id="householdinfo"
        >
          <div>
            <h2 className="text-xl font-bold text-white">Pets</h2>
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#003460] text-white transition-all duration-300 ease-in-out"
              onClick={togglePets}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPetsVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <img src={toggleDown} alt="down" className="h-8 w-8" />
              </span>
            </button>
          </div>
        </header>
        {isPetsVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={petsGridRef}
              dataSource={filteredPets}
              keyField="pet_id"
              allowSorting={true}
              sortSettings={sortSettings}
              editSettings={editing}
              filterSettings={filterSettings}
              allowFiltering={true}
              actionBegin={actionBeginPets}
              actionComplete={petsActionComplete}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              cssClass="new-grid-color"
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="pet_id"
                  headerText="Pet ID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="pet_name"
                  headerText="Pet Name"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_color"
                  headerText="Pet Color"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_coat"
                  headerText="Pet Coat"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={petCoatParams}
                />
                <ColumnDirective
                  field="pet_breed"
                  headerText="Pet Breed"
                  width="100"
                  validationRules={{ required: true }}
                />
                <ColumnDirective
                  field="pet_size"
                  headerText="Pet Size"
                  width="100"
                  validationRules={{ required: true }}
                  editType="dropdownedit"
                  edit={petSizeParams}
                />
                <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
            <div className="flex w-full">
              <button
                type="button"
                className=" flex cursor-pointer gap-1 bg-transparent text-base text-dark-midnight-blue"
                onClick={handleAddPet}
              >
                <FaPlus className="h-4 w-4" />
                Add Pet
              </button>
            </div>
          </div>
        )}
      </section>
      <DialogComponent
        width="400px"
        isModal={true}
        visible={showPopup}
        close={handleClosePopup}
        header={null}
        content={dialogContent}
        showCloseIcon={true}
        closeOnEscape={true}
        target={document.body}
        cssClass="welcome-dialog"
      />
    </form>
  );
};

CitizenProfile.propTypes = {
  className: PropTypes.string,
};

export default CitizenProfile;
