import React, { useState, useEffect, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "./datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "./style.css";
import HeaderTitle from "../components/HeaderTitle";

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const LocalNonProfitCoalition = ({ className = "" }) => {
  const [localNPC, setlocalNPC] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const fetchContacts = async () => {
      if (!selectedUserId) return; // Ensure userId is not null

      // Fetch the current user document to get center_id
      const userDocRef = doc(db, "users", selectedUserId); // Create a DocumentReference
      const userDoc = await getDoc(userDocRef); // Use getDoc instead of getDocs
      const userData = userDoc.data();
      const centerId = userData.center_id;

      if (centerId) {
        // Fetch contacts from "center-local-npc" where user_id matches centerId
        // First get users with matching center_id
        const usersSnapshot = await getDocs(
          query(
            collection(db, "users"),
            where("center_id", "array-contains", centerId),
            where("user_role", "==", "partner")
          )
        );

        // Then fetch partner data for each user's partner_id
        const partnerPromises = usersSnapshot.docs.map(async (userDoc) => {
          const userData = userDoc.data();
          if (userData.partner_id) {
            const partnerDoc = await getDoc(
              doc(db, "partners", userData.partner_id)
            );
            if (partnerDoc.exists()) {
              return {
                id: partnerDoc.id,
                ...partnerDoc.data(),
              };
            }
          }
          return null;
        });

        const partnerData = (await Promise.all(partnerPromises)).filter(
          Boolean
        );
        setlocalNPC(preprocessData(partnerData));
        console.log("partnerData", partnerData);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(selectedUserId);
        fetchContacts();
      } else {
        setUserId(null);
        setlocalNPC([]);
      }
    });

    return () => unsubscribe();
  }, [selectedUserId]); // Add userId as a dependency

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  return (
    <>
      <div
        className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
      >
        <HeaderTitle id="localnonprofitcoalition" title="Local Non Profit Coalition" />
        <div className="relative text-sm font-medium leading-[21px] px-2">
          The contact information will automatically populate with the details
          provided by a certified Center. Additionally, it's important to know
          that the Center has identified specific nonprofits that are equipped
          to provide essential services in the aftermath of a disaster.
        </div>
        <section className="w-full rounded-3xs pb-2 text-left text-xs text-darkslateblue px-2">
          <div>
            <GridComponent
              dataSource={localNPC}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowSorting={true}
              ref={gridRef}
              sortSettings={sortSettings}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              cssClass="new-grid-color"
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText="npcID"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="name_of_org"
                  headerText="Name of Organization"
                  width="150"
                />
                <ColumnDirective
                  field="county"
                  headerText="County"
                  width="130"
                  visible={true}
                />
                <ColumnDirective
                  field="servicesProvided"
                  headerText="Services Provided"
                  width="150"
                />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="120"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="150"
                />
                <ColumnDirective
                  field="Address"
                  headerText="Address"
                  width="250"
                />
                <ColumnDirective
                  field="address1"
                  headerText="Address 1"
                  visible={false}
                />
                <ColumnDirective
                  field="address2"
                  headerText="Address 2"
                  visible={false}
                />
                <ColumnDirective
                  field="city"
                  headerText="City"
                  visible={false}
                />
                <ColumnDirective
                  field="state"
                  headerText="State"
                  visible={false}
                />
                <ColumnDirective
                  field="zip"
                  headerText="Zip Code"
                  visible={false}
                />
              </ColumnsDirective>
              <Inject services={[Filter, Sort, Page, CommandColumn]} />
            </GridComponent>
          </div>
        </section>
      </div>
    </>
  );
};

export default LocalNonProfitCoalition;
