import React, { useState } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { encrypt, decrypt } from "../../service/encryption/Encryption";
import mailer from "../../service/api/mailer";

const CaseManagementPassword = ({ isOpen, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [error, setError] = useState("");
  const selectedUserId = sessionStorage.getItem("userId");

  if (!isOpen) return null;

  const updateEmployeePassword = async () => {
    const employeeId = JSON.parse(
      sessionStorage.getItem("employeeDetails")
    ).darci_id;
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("darci_id", "==", employeeId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();

      // Verify current password
      const decryptedPassword = decrypt(userData.password);
      if (currentPassword !== decryptedPassword) {
        throw new Error("wrong-password");
      }

      await updateDoc(userDoc.ref, {
        password: encrypt(newPassword),
        password_changed: true,
      });
      sessionStorage.setItem("needToUpdatePassword", "false");
    }
  };

  const updateUserPassword = async () => {
    const userDocRef = doc(db, "users", selectedUserId);
    await updateDoc(userDocRef, {
      password: encrypt(newPassword),
    });

    const userDocSnap = await getDoc(userDocRef);
    const email = userDocSnap.data().email;

    console.log("Password changed successfully");
    await mailer.sendChangePasswordEmail(email, "Van");
  };

  const validatePasswords = () => {
    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password don't match.");
      return false;
    }

    // Check minimum length
    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long.");
      return false;
    }

    // Check for uppercase letter
    if (!/[A-Z]/.test(newPassword)) {
      setError("Password must contain at least one uppercase letter.");
      return false;
    }

    // Check for special character or number
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/.test(newPassword)) {
      setError(
        "Password must contain at least one special character or number."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!validatePasswords()) return;

    try {
      if (sessionStorage.getItem("employeeDetails")) {
        await updateEmployeePassword();
      } else {
        await updateUserPassword();
      }
      onClose();
    } catch (error) {
      if (error.message === "wrong-password") {
        setError("Current password is incorrect.");
      } else {
        setError("An error occurred. Please try again.");
        console.error("Error changing password:", error);
      }
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const renderPasswordField = (
    field,
    placeholder,
    value,
    setValue,
    isLast = false
  ) => (
    <div className={`relative ${isLast ? "mb-6" : "mb-4"}`}>
      <TextBoxComponent
        placeholder={placeholder}
        type={showPasswords[field] ? "text" : "password"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        cssClass="e-outline"
        floatLabelType="Auto"
        onPaste={(e) => e.preventDefault()}
      />
      <button
        type="button"
        onClick={() => togglePasswordVisibility(field)}
        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
      >
        {showPasswords[field] ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="w-[480px] rounded-lg bg-white shadow-xl">
        <div className="bg-[#F7CA41] text-[#242424] flex items-center justify-between rounded-t-lg p-4">
          <h2 className="font-semibold">Change Password</h2>
          {!sessionStorage.getItem("needToUpdatePassword") && (
            <button
              onClick={onClose}
              className="bg-[#F7CA41] text-[#242424] cursor-pointer rounded-full text-[24px]"
            >
              &times;
            </button>
          )}
        </div>
        <form onSubmit={handleSubmit} className="p-6">
          {error && <div className="mb-4 text-red-500">{error}</div>}
          {renderPasswordField(
            "current",
            "Current Password",
            currentPassword,
            setCurrentPassword
          )}
          {renderPasswordField(
            "new",
            "New Password",
            newPassword,
            setNewPassword
          )}
          {renderPasswordField(
            "confirm",
            "Confirm New Password",
            confirmPassword,
            setConfirmPassword,
            true
          )}
          <div className="flex items-center justify-end space-x-4">
            {!sessionStorage.getItem("needToUpdatePassword") && (
              <button
                type="button"
                onClick={onClose}
                className="cm-btn-cancel cursor-pointer rounded-full border px-4 py-2 transition duration-300 hover:bg-blue-50"
              >
                CANCEL
              </button>
            )}
            <button
              type="submit"
              className="cm-btn-default cursor-pointer rounded-full px-4 py-2 transition duration-300 hover:bg-blue-600"
            >
              SAVE CHANGES
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CaseManagementPassword;
