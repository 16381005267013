import CryptoJS from "crypto-js";

// const loadEnv = () => {
//   const value = process.env.REACT_APP_ENCRYPTION_KEY; // Access it directly
//   console.log("value", value);
//   if (!value) {
//     console.error("Environment variable REACT_APP_ENCRYPTION_KEY is not set.");
//   }
//   return value;
// };

// const ENCRYPTION_KEY = loadEnv();

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

const encrypt = (text) => {
  const encrypted = CryptoJS.AES.encrypt(text, ENCRYPTION_KEY, {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

const decrypt = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // console.log("Raw encryption key:", ENCRYPTION_KEY);
    // console.log("Length of key:", ENCRYPTION_KEY.length);
    // console.log("Key characters:", Array.from(ENCRYPTION_KEY).join(" "));
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Decryption failed:", error);
    return "";
  }
};
// Export the encrypt and decrypt functions
export { encrypt, decrypt };
