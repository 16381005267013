export const alphabetRegex = /^[a-zA-Z\s]*$/;
export const numberRegex = /^[0-9]+$/;
export const alphanumericRegex = /^[A-Za-z0-9]+$/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const usMobileNumberRegex =
  /^(\+?1)?[-. ]?(\([0-9]{3}\)|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/;
export const alphanumericOnlyRegexWithHashOrDash = /^[a-zA-Z0-9#\-\s]*$/;

export const REGEX = {
  alphabetRegex,
  numberRegex,
  alphanumericRegex,
  emailRegex,
  usMobileNumberRegex,
  alphanumericOnlyRegexWithHashOrDash,
};
