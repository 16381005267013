import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Toolbar,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "../style.css";
import dataService from "../../service/data/DataService";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FaPlus } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const editOptions = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 18 };

const validationRules = { required: true };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const formatLastEditedTime = (time) => {
  if (!time) return "Never";

  const now = new Date();
  const diffInSeconds = Math.floor((now - time) / 1000);

  if (diffInSeconds < 60) return "Just now";
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 604800)
    return `${Math.floor(diffInSeconds / 86400)} days ago`;

  return time.toLocaleDateString();
};

const generateRandomPassword = (length = 8) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const checkIfEmailExists = async (email) => {
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);

  return !querySnapshot.empty; // Returns true if email exists
};

const getCenterIdByZip = async (zipCode) => {
  const usersRef = collection(db, "users");
  const q = query(
    usersRef,
    where("zip", "==", zipCode),
    where("user_role", "==", "center")
  );
  const snapshot = await getDocs(q);

  if (!snapshot.empty) {
    return snapshot.docs[0].id;
  }

  return "";
};

const servicesProvided = [
  { value: "Funding", text: "Funding" },
  { value: "Debris Removal", text: "Debris Removal" },
  { value: "Case Management", text: "Case Management" },
  { value: "House Repair", text: "House Repair" },
  { value: "House Rebuilds", text: "House Rebuilds" },
  { value: "Volunteer Labor", text: "Volunteer Labor" },
  { value: "Spriritual/Mental Care", text: "Spriritual/Mental Care" },
  { value: "Donation of Goods", text: "Donation of Goods" },
];

const fields = { value: "value", text: "text" };

const Services = (props) => {
  const currentServices = props.servicesProvided || [];

  return (
    <div>
      <label
        htmlFor="servicesProvided"
        className="mb-2 block text-xs text-gray-500"
      >
        Primary Service Provided
      </label>
      <MultiSelectComponent
        id="servicesProvided"
        dataSource={servicesProvided}
        fields={fields}
        value={currentServices}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
        change={(e) => {
          if (props.onChange) {
            props.onChange({ value: e.value });
          }
        }}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const disaster = [
  { value: "Fire", text: "1" },
  { value: "Flood", text: "2" },
  { value: "Earthquake", text: "3" },
];

const fieldsDisaster = { value: "value", text: "value" };

const PasswordTemplate = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(props.password || "");

  useEffect(() => {
    setPassword(props.password || "");
  }, [props.password]);

  const handleChange = (e) => {
    setPassword(e.value);
    if (props.onChange) {
      props.onChange({ ...e, value: e.value });
    }
  };

  return (
    <div className="relative w-full">
      <TextBoxComponent
        placeholder="Password"
        floatLabelType="Always"
        type={showPassword ? "text" : "password"}
        value={password}
        change={handleChange}
        name="password" // Ensure the name attribute is set for validation
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
  );
};

const DisasterTemplate = (props) => {
  const currentAccess = (props && props.disaster) || [];

  return (
    <div>
      <label htmlFor="disaster" className="mb-2 block text-xs text-gray-500">
        Disaster
      </label>
      <MultiSelectComponent
        id="disaster"
        dataSource={disaster}
        fields={fieldsDisaster}
        value={currentAccess}
        mode="CheckBox"
        selectAllText="Select All"
        unSelectAllText="Unselect All"
        showSelectAll={true}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>
    </div>
  );
};

const DRPartner = () => {
  const [centerDisasterRecovery, setCenterDisasterRecovery] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      if (!userId) {
        return; // Skip fetching if userId is not set
      }

      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "users"),
            where("type", "==", "disaster-recovery")
          ) // Changed from user_id to center_id
        );
        const drpPartnerData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCenterDisasterRecovery(preprocessData(drpPartnerData));
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        fetchContacts(); // Fetch contacts after user is authenticated
        const userData = await dataService.getUserProfile("users", user.uid);
        if (userData) {
          setCurrentUserData(userData);
        }
      } else {
        setUserId(null);
        setCenterDisasterRecovery([]); // Clear contacts if no user is logged in
      }
    });

    return () => unsubscribe(); // Clean up subscription
  }, [userId]); // Add userId as a dependency

  const handleAdd = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const actionBegin = async (args) => {
    if (gridRef.current) {
      try {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "address1",
                "address2",
                "city",
                "state",
                "zip",
                "email",
                "password",
              ].includes(col.field)
            ) {
              col.visible = true;
            } else if (["Address"].includes(col.field)) {
              col.visible = false;
            }
          }
          if (args.requestType === "add") {
            args.data.password = generateRandomPassword();
          }
        }
        if (args.requestType === "save") {
          setLastEditedTime(new Date());
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "address1",
                "address2",
                "city",
                "state",
                "zip",
                "password",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          const data = args.data;
          data.Address = `${data.address1 || ""} ${data.address2 || ""} ${
            data.city || ""
          } ${data.state || ""} ${data.zip || ""}`;

          Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
              delete data[key];
            }
          });

          if (!data.email || !data.password) {
            console.error("Email and password must be provided.");
            return;
          }

          if (args.action === "add") {
            const emailExists = await checkIfEmailExists(data.email);

            if (emailExists) {
              alert(
                "Email already exists. Please use a different email or log in."
              );
              return;
            }

            const centerId = await getCenterIdByZip(data.zip);
            const userCredential = await createUserWithEmailAndPassword(
              auth,
              data.email,
              data.password
            );
            await signInWithEmailAndPassword(
              auth,
              currentUserData.email,
              currentUserData.password
            );
            const user = userCredential.user;
            await setDoc(doc(db, "users", user.uid), {
              ...data,
              center_id: centerId,
              last_updated: serverTimestamp(),
              inserted_at: serverTimestamp(),
              id: user.uid,
              type: "disaster-recovery",
              user_role: "partner",
            });
          } else if (args.action === "edit") {
            if (data.id) {
              const drpDoc = doc(db, "users", data.id);
              await updateDoc(drpDoc, {
                ...data,
                last_updated: serverTimestamp(),
              });
            } else {
              console.error("User ID is missing for update.");
            }
          }
        }
        if (args.requestType === "delete") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              ["address1", "address2", "city", "state", "zip"].includes(
                col.field
              )
            ) {
              col.visible = false;
            } else if (["Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          const data = args.data[0];
          if (data.id) {
            const drpDoc = doc(db, "users", data.id);
            await deleteDoc(drpDoc);
          } else {
            console.error("User ID is missing for delete.");
          }
        }
      } catch (error) {
        console.error("Error during actionBegin:", error);
      }
    }
  };

  return (
    <>
      <div className={`flex max-w-full flex-col gap-5 px-2.5 py-[15px]`}>
        <div className="flex items-center gap-2 pt-2 text-xs text-darkslateblue">
          <span className="font-medium text-blue-500">
            Disaster Recovery Partners
          </span>
        </div>
      </div>
      <header className=" flex w-full flex-row items-center  justify-between self-stretch rounded-3xs px-2.5 text-left text-xs text-darkslateblue">
        <div>
          <h2 className="mb-1 text-sm font-bold uppercase text-blue-500">
            List of Disaster Recovery Partners
          </h2>
          {/* <p className="font-poppins text-xs font-medium text-gray-500">
            Last Edited: {formatLastEditedTime(lastEditedTime)}
          </p> */}
        </div>
        {/* <div className="flex items-center gap-2">
          <button
            type="button"
            className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
            onClick={handleAdd}
          >
            <FaPlus className="h-3 w-3" />
            Add
          </button>
        </div> */}
      </header>
      <div className="mt-3 w-full rounded-3xs px-2.5  text-left text-xs text-darkslateblue">
        <GridComponent
          dataSource={centerDisasterRecovery}
          actionBegin={actionBegin}
          editSettings={editOptions}
          filterSettings={filterSettings}
          allowFiltering={true}
          allowSorting={true}
          ref={gridRef}
          sortSettings={sortSettings}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
          height="70vh"
        >
          <ColumnsDirective>
            <ColumnDirective
              field="county"
              headerText="Primary County"
              textAlign="Left"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="name_of_org"
              headerText="Organization"
              visible={true}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="servicesProvided"
              headerText="Primary Service Provided"
              editTemplate={Services}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="Address"
              headerText="Address"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="address1"
              headerText="Address 1"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="address2"
              headerText="Address 2"
              visible={false}
            />
            <ColumnDirective
              field="city"
              headerText="City"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="state"
              headerText="State"
              visible={false}
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: USStates,
                  fields: { text: "abbreviation", value: "abbreviation" },
                  query: new Query(),
                },
              }}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="zip"
              headerText="Zip"
              visible={false}
              validationRules={validationRules}
            />
            <ColumnDirective
              field="mobile_number"
              headerText="Mobile Number"
              textAlign="Left"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              textAlign="Left"
              validationRules={validationRules}
            />
            <ColumnDirective
              field="password"
              headerText="Password"
              textAlign="Left"
              visible={false}
              validationRules={validationRules}
              editTemplate={PasswordTemplate}
            ></ColumnDirective>
            {/* <ColumnDirective
              headerText="Commands"
              width="120"
              textAlign="Center"
              commands={[
                {
                  type: "Edit",
                  buttonOption: {
                    content: '<i class="fas fa-edit"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
                {
                  type: "Delete",
                  buttonOption: {
                    content: '<i class="fas fa-trash-alt"></i>',
                    cssClass: "e-outline custom-button",
                  },
                },
              ]}
            /> */}
          </ColumnsDirective>
          <Inject
            services={[Edit, Filter, Sort, Page, CommandColumn, ColumnMenu]}
          />
        </GridComponent>
      </div>
    </>
  );
};

export default DRPartner;
