import React, { useState, useRef, useEffect } from "react";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { FaTrash, FaEdit, FaUpload } from "react-icons/fa";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import ModalFileViewer from "../../../components/ModalFileViewer";
import { useSidebarContext } from "../../../context/SidebarContext";
import { formatDateMMDDYYYY } from "../../../utils/formatter";
import panZoomIcon from "../../../assets/disaster-icons/pan_zoom.svg";
import ViewPhotoOverlay from "../../../components/ViewPhotoOverlay";
import DeleteModal from "../../../components/DeleteModal";
const ImageDialog = ({
  isOpen,
  onClose,
  images,
  currentIndex,
  setCurrentIndex,
  setImages,
  onEditClick,
  onUploadClick,
}) => {
  const { toggleSidebar } = useSidebarContext();
  const [isFullViewOpen, setIsFullViewOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: "",
    description: "",
  });

  if (!isOpen || !Array.isArray(images) || images.length === 0) return null;

  const validImages = images
    .filter((img) => img && (img.src || typeof img === "string"))
    .map((img) => (typeof img === "string" ? { src: img } : img));

  if (validImages.length === 0) return null;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : validImages.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < validImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleDelete = () => {
    const updatedImages = images.filter((_, index) => index !== currentIndex);
  
    if (updatedImages.length === 0) {
      setImages([]);
      setIsDeleteModalOpen(false);
      onClose(); 
    } else {
      setImages(updatedImages);
      setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
      setIsDeleteModalOpen(false);
    }
  };

  const handleFullViewClick = () => {
    setIsFullViewOpen(true);
  };

  const handleEditClick = () => {
    onEditClick(currentImage);
  };

  const handleSaveEdit = (updatedImage) => {
    const updatedImages = images.map((img, index) =>
      index === currentIndex ? updatedImage : img
    );
    setImages(updatedImages);
    setShowEditModal(false);
  };

  const handleSaveUpload = (newImage) => {
    setImages([...images, newImage]);
    setShowUploadModal(false);
  };

  const handleUploadClick = () => {
    onUploadClick(); 
  };

  const onCloseModal = () => {
    setShowEditModal(false);
    setShowUploadModal(false);
    onClose();
  };

  const currentImage = validImages[currentIndex] || {};
  const imageName = currentImage.name || `Image ${currentIndex + 1}`;
  const imageSrc = currentImage.src || "";

  return (
    <div className={`fixed inset-0 z-9999 flex items-center justify-center mt-24 overflow-y-auto ${
      toggleSidebar ? "ml-[335px]" : "ml-[80px]"
    }`}>
      <div className="relative flex h-[80vh] w-full max-w-4xl flex-col border border-gray-300 bg-white shadow-lg">
        <div className="flex items-center justify-between border-b bg-[#C4DAF3] p-4">
          <h2 className="truncate text-xl font-bold text-[#04447A]">
            {imageName}
          </h2>
          <button onClick={onClose} className="bg-[#C4DAF3]">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.0013 15.8641L17.868 19.7307C18.1124 19.9752 18.4235 20.0974 18.8013 20.0974C19.1791 20.0974 19.4902 19.9752 19.7346 19.7307C19.9791 19.4863 20.1013 19.1752 20.1013 18.7974C20.1013 18.4196 19.9791 18.1085 19.7346 17.8641L15.868 13.9974L19.7346 10.1307C19.9791 9.88628 20.1013 9.57517 20.1013 9.1974C20.1013 8.81962 19.9791 8.50851 19.7346 8.26406C19.4902 8.01962 19.1791 7.8974 18.8013 7.8974C18.4235 7.8974 18.1124 8.01962 17.868 8.26406L14.0013 12.1307L10.1346 8.26406C9.89019 8.01962 9.57908 7.8974 9.2013 7.8974C8.82352 7.8974 8.51241 8.01962 8.26797 8.26406C8.02352 8.50851 7.9013 8.81962 7.9013 9.1974C7.9013 9.57517 8.02352 9.88628 8.26797 10.1307L12.1346 13.9974L8.26797 17.8641C8.02352 18.1085 7.9013 18.4196 7.9013 18.7974C7.9013 19.1752 8.02352 19.4863 8.26797 19.7307C8.51241 19.9752 8.82352 20.0974 9.2013 20.0974C9.57908 20.0974 9.89019 19.9752 10.1346 19.7307L14.0013 15.8641ZM14.0013 27.3307C12.1569 27.3307 10.4235 26.9807 8.8013 26.2807C7.17908 25.5807 5.76797 24.6307 4.56797 23.4307C3.36797 22.2307 2.41797 20.8196 1.71797 19.1974C1.01797 17.5752 0.667969 15.8418 0.667969 13.9974C0.667969 12.153 1.01797 10.4196 1.71797 8.79739C2.41797 7.17517 3.36797 5.76406 4.56797 4.56406C5.76797 3.36406 7.17908 2.41406 8.8013 1.71406C10.4235 1.01406 12.1569 0.664062 14.0013 0.664062C15.8457 0.664062 17.5791 1.01406 19.2013 1.71406C20.8235 2.41406 22.2346 3.36406 23.4346 4.56406C24.6346 5.76406 25.5846 7.17517 26.2846 8.79739C26.9846 10.4196 27.3346 12.153 27.3346 13.9974C27.3346 15.8418 26.9846 17.5752 26.2846 19.1974C25.5846 20.8196 24.6346 22.2307 23.4346 23.4307C22.2346 24.6307 20.8235 25.5807 19.2013 26.2807C17.5791 26.9807 15.8457 27.3307 14.0013 27.3307Z"
                fill="#E72528"
              />
            </svg>
          </button>
        </div>
        <div className="group relative m-4 flex flex-grow items-center justify-center overflow-hidden bg-gray-200">
          {imageSrc && (
            <>
              <img
                src={imageSrc}
                alt={imageName}
                className="h-full w-full rounded-md object-cover"
              />
              <ViewPhotoOverlay />
              <div className="absolute bottom-6 left-5 flex items-center gap-x-2">
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 19.9922C1.45 19.9922 0.979167 19.7964 0.5875 19.4047C0.195833 19.013 0 18.5422 0 17.9922V3.99219C0 3.44219 0.195833 2.97135 0.5875 2.57969C0.979167 2.18802 1.45 1.99219 2 1.99219H3V0.992188C3 0.708854 3.09583 0.471354 3.2875 0.279687C3.47917 0.0880208 3.71667 -0.0078125 4 -0.0078125C4.28333 -0.0078125 4.52083 0.0880208 4.7125 0.279687C4.90417 0.471354 5 0.708854 5 0.992188V1.99219H13V0.992188C13 0.708854 13.0958 0.471354 13.2875 0.279687C13.4792 0.0880208 13.7167 -0.0078125 14 -0.0078125C14.2833 -0.0078125 14.5208 0.0880208 14.7125 0.279687C14.9042 0.471354 15 0.708854 15 0.992188V1.99219H16C16.55 1.99219 17.0208 2.18802 17.4125 2.57969C17.8042 2.97135 18 3.44219 18 3.99219V17.9922C18 18.5422 17.8042 19.013 17.4125 19.4047C17.0208 19.7964 16.55 19.9922 16 19.9922H2Z"
                    fill="#FFFFFF"
                  />
                </svg>
                <p className="text-white">
                  {currentImage.uploaded_at
                    ? formatDateMMDDYYYY(currentImage.uploaded_at)
                    : ""}
                </p>
              </div>
              <div className="absolute bottom-6 left-0 right-0 flex w-full items-center justify-center">
                <div
                  onClick={() => {
                    setIsFullViewOpen(true);
                  }}
                  className="flex cursor-pointer items-center justify-center gap-x-2 text-base text-white"
                >
                  <img
                    draggable={false}
                    src={panZoomIcon}
                    alt="Full View Icon"
                  />
                  Click for Full View
                </div>
              </div>
            </>
          )}
          
          <button
            onClick={() => setIsDeleteModalOpen(true)}
            className="tansition-opacity absolute right-4 top-4 rounded bg-black bg-opacity-0 duration-300 group-hover:opacity-100"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="54" height="54" rx="4" fill="#E72528" />
              <path
                d="M19.5 40.5C18.675 40.5 17.9688 40.2063 17.3812 39.6188C16.7937 39.0312 16.5 38.325 16.5 37.5V18C16.075 18 15.7188 17.8563 15.4312 17.5688C15.1437 17.2813 15 16.925 15 16.5C15 16.075 15.1437 15.7188 15.4312 15.4313C15.7188 15.1438 16.075 15 16.5 15H22.5C22.5 14.575 22.6437 14.2188 22.9312 13.9312C23.2188 13.6437 23.575 13.5 24 13.5H30C30.425 13.5 30.7813 13.6437 31.0688 13.9312C31.3563 14.2188 31.5 14.575 31.5 15H37.5C37.925 15 38.2812 15.1438 38.5688 15.4313C38.8563 15.7188 39 16.075 39 16.5C39 16.925 38.8563 17.2813 38.5688 17.5688C38.2812 17.8563 37.925 18 37.5 18V37.5C37.5 38.325 37.2063 39.0312 36.6188 39.6188C36.0312 40.2063 35.325 40.5 34.5 40.5H19.5ZM24 34.5C24.425 34.5 24.7812 34.3563 25.0688 34.0688C25.3562 33.7812 25.5 33.425 25.5 33V22.5C25.5 22.075 25.3562 21.7188 25.0688 21.4312C24.7812 21.1437 24.425 21 24 21C23.575 21 23.2188 21.1437 22.9312 21.4312C22.6437 21.7188 22.5 22.075 22.5 22.5V33C22.5 33.425 22.6437 33.7812 22.9312 34.0688C23.2188 34.3563 23.575 34.5 24 34.5ZM30 34.5C30.425 34.5 30.7813 34.3563 31.0688 34.0688C31.3563 33.7812 31.5 33.425 31.5 33V22.5C31.5 22.075 31.3563 21.7188 31.0688 21.4312C30.7813 21.1437 30.425 21 30 21C29.575 21 29.2188 21.1437 28.9313 21.4312C28.6438 21.7188 28.5 22.075 28.5 22.5V33C28.5 33.425 28.6438 33.7812 28.9313 34.0688C29.2188 34.3563 29.575 34.5 30 34.5Z"
                fill="#FAFAFA"
              />
            </svg>
          </button>
          {validImages.length > 1 && (
            <>
              <button
                onClick={handlePrev}
                className="text-gray-700 absolute left-4 top-1/2 ml-2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-gray-300"
              >
                <svg
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.1974 26.9948L31.5307 33.3281C32.0196 33.817 32.2641 34.4281 32.2641 35.1615C32.2641 35.8948 32.0196 36.5281 31.5307 37.0615C30.9974 37.5948 30.3641 37.8615 29.6307 37.8615C28.8974 37.8615 28.2641 37.5948 27.7307 37.0615L19.5307 28.8615C18.9974 28.3281 18.7307 27.7059 18.7307 26.9948C18.7307 26.2837 18.9974 25.6615 19.5307 25.1281L27.7974 16.8615C28.3307 16.3281 28.953 16.0726 29.6641 16.0948C30.3752 16.117 30.9974 16.3948 31.5307 16.9281C32.0196 17.4615 32.2752 18.0837 32.2974 18.7948C32.3196 19.5059 32.0641 20.1281 31.5307 20.6615L25.1974 26.9948ZM26.9974 53.6615C30.6863 53.6615 34.153 52.9615 37.3974 51.5615C40.6418 50.1615 43.4641 48.2615 45.8641 45.8615C48.2641 43.4615 50.1641 40.6392 51.5641 37.3948C52.9641 34.1504 53.6641 30.6837 53.6641 26.9948C53.6641 23.3059 52.9641 19.8392 51.5641 16.5948C50.1641 13.3503 48.2641 10.5281 45.8641 8.12813C43.4641 5.72813 40.6418 3.82813 37.3974 2.42813C34.153 1.02813 30.6863 0.328125 26.9974 0.328125C23.3085 0.328125 19.8418 1.02813 16.5974 2.42813C13.3529 3.82813 10.5307 5.72813 8.13072 8.12813C5.73072 10.5281 3.83073 13.3503 2.43073 16.5948C1.03073 19.8392 0.330728 23.3059 0.330728 26.9948C0.330728 30.6837 1.03073 34.1504 2.43073 37.3948C3.83073 40.6392 5.73072 43.4615 8.13072 45.8615C10.5307 48.2615 13.3529 50.1615 16.5974 51.5615C19.8418 52.9615 23.3085 53.6615 26.9974 53.6615Z"
                    fill="#FAFAFA"
                  />
                </svg>
              </button>
              <button
                onClick={handleNext}
                className="text-gray-700 absolute right-4 top-1/2 mr-2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-gray-300"
              >
                <svg
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.8026 26.9948L22.4693 33.3281C21.9804 33.817 21.7359 34.4281 21.7359 35.1615C21.7359 35.8948 21.9804 36.5281 22.4693 37.0615C23.0026 37.5948 23.6359 37.8615 24.3693 37.8615C25.1026 37.8615 25.7359 37.5948 26.2693 37.0615L34.4693 28.8615C35.0026 28.3281 35.2693 27.7059 35.2693 26.9948C35.2693 26.2837 35.0026 25.6615 34.4693 25.1281L26.2026 16.8615C25.6693 16.3281 25.047 16.0726 24.3359 16.0948C23.6248 16.117 23.0026 16.3948 22.4693 16.9281C21.9804 17.4615 21.7248 18.0837 21.7026 18.7948C21.6804 19.5059 21.9359 20.1281 22.4693 20.6615L28.8026 26.9948ZM27.0026 53.6615C23.3137 53.6615 19.847 52.9615 16.6026 51.5615C13.3582 50.1615 10.5359 48.2615 8.13594 45.8615C5.73594 43.4615 3.83594 40.6392 2.43594 37.3948C1.03594 34.1504 0.335938 30.6837 0.335938 26.9948C0.335938 23.3059 1.03594 19.8392 2.43594 16.5948C3.83594 13.3503 5.73594 10.5281 8.13594 8.12813C10.5359 5.72813 13.3582 3.82813 16.6026 2.42813C19.847 1.02813 23.3137 0.328125 27.0026 0.328125C30.6915 0.328125 34.1582 1.02813 37.4026 2.42813C40.6471 3.82813 43.4693 5.72813 45.8693 8.12813C48.2693 10.5281 50.1693 13.3503 51.5693 16.5948C52.9693 19.8392 53.6693 23.3059 53.6693 26.9948C53.6693 30.6837 52.9693 34.1504 51.5693 37.3948C50.1693 40.6392 48.2693 43.4615 45.8693 45.8615C43.4693 48.2615 40.6471 50.1615 37.4026 51.5615C34.1582 52.9615 30.6915 53.6615 27.0026 53.6615Z"
                    fill="#FAFAFA"
                  />
                </svg>
              </button>
            </>
          )}
        </div>
        <div className=" px-4 pt-1">
          <p className="mt-1 text-sm text-[#525252]">
            {currentImage.description ? currentImage.description : ""}
          </p>
        </div>
        <div className="flex justify-between gap-4 p-4">
          <button
            className="w-full rounded border border-solid border-[#003460] bg-white px-4 py-4 text-[24px] text-[#003460]"
            onClick={handleEditClick}
          >
            Edit
          </button>
          <button
            className="w-full rounded bg-[#003460] px-4 py-4 text-[24px] text-[#F7CA41]"
            onClick={handleUploadClick}
          >
            Upload New Photo
          </button>
        </div>
      </div>
      {/* {showDeleteConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded bg-white p-6 shadow-lg">
            <p className="text-gray-700">
              Are you sure you want to delete this image?
            </p>
            <div className="mt-4 flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="text-gray-700 rounded border bg-gray-200 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="rounded bg-red-600 px-4 py-2 text-white"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )} */}
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete} 
        loading={false} 
      />
      {showEditModal && (
        <EditModal
          onCancel={() => setShowEditModal(false)}
          onClose={onCloseModal}
          image={editFormData}
          onSave={handleSaveEdit}
        />
      )}
      {showUploadModal && (
        <UploadModal
          onCancel={() => setShowUploadModal(false)}
          onClose={onCloseModal}
          onSave={handleSaveUpload}
        />
      )}
      
        <ModalFileViewer
         isOpen={isFullViewOpen}
         setIsOpen={setIsFullViewOpen}
         title={imageName}
         fileUrl={imageSrc}
         fileType="image" 
         height="600px"
       />
 
    </div>
  );
};

const EditModal = ({ image, onSave, onClose, onCancel }) => {
  const [updatedData, setUpdatedData] = useState(image);
  const [previewImage, setPreviewImage] = useState("");
  const fileInputRef = useRef(null);
  const storage = getStorage();

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      setUpdatedData({
        ...updatedData,
        src: downloadURL,
        uploaded_at: new Date().toISOString(),
      });

      setPreviewImage(URL.createObjectURL(file));
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="relative flex h-[80vh] w-full max-w-4xl flex-col rounded-lg border border-gray-300 bg-white shadow-lg">
        <div className="flex items-center justify-between rounded-t-lg border-b bg-[#C4DAF3] p-4">
          <h2 className="truncate text-xl font-bold text-[#04447A]">
            Edit Photo
          </h2>
          <button onClick={onClose} className="bg-[#C4DAF3]">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.0013 15.8641L17.868 19.7307C18.1124 19.9752 18.4235 20.0974 18.8013 20.0974C19.1791 20.0974 19.4902 19.9752 19.7346 19.7307C19.9791 19.4863 20.1013 19.1752 20.1013 18.7974C20.1013 18.4196 19.9791 18.1085 19.7346 17.8641L15.868 13.9974L19.7346 10.1307C19.9791 9.88628 20.1013 9.57517 20.1013 9.1974C20.1013 8.81962 19.9791 8.50851 19.7346 8.26406C19.4902 8.01962 19.1791 7.8974 18.8013 7.8974C18.4235 7.8974 18.1124 8.01962 17.868 8.26406L14.0013 12.1307L10.1346 8.26406C9.89019 8.01962 9.57908 7.8974 9.2013 7.8974C8.82352 7.8974 8.51241 8.01962 8.26797 8.26406C8.02352 8.50851 7.9013 8.81962 7.9013 9.1974C7.9013 9.57517 8.02352 9.88628 8.26797 10.1307L12.1346 13.9974L8.26797 17.8641C8.02352 18.1085 7.9013 18.4196 7.9013 18.7974C7.9013 19.1752 8.02352 19.4863 8.26797 19.7307C8.51241 19.9752 8.82352 20.0974 9.2013 20.0974C9.57908 20.0974 9.89019 19.9752 10.1346 19.7307L14.0013 15.8641ZM14.0013 27.3307C12.1569 27.3307 10.4235 26.9807 8.8013 26.2807C7.17908 25.5807 5.76797 24.6307 4.56797 23.4307C3.36797 22.2307 2.41797 20.8196 1.71797 19.1974C1.01797 17.5752 0.667969 15.8418 0.667969 13.9974C0.667969 12.153 1.01797 10.4196 1.71797 8.79739C2.41797 7.17517 3.36797 5.76406 4.56797 4.56406C5.76797 3.36406 7.17908 2.41406 8.8013 1.71406C10.4235 1.01406 12.1569 0.664062 14.0013 0.664062C15.8457 0.664062 17.5791 1.01406 19.2013 1.71406C20.8235 2.41406 22.2346 3.36406 23.4346 4.56406C24.6346 5.76406 25.5846 7.17517 26.2846 8.79739C26.9846 10.4196 27.3346 12.153 27.3346 13.9974C27.3346 15.8418 26.9846 17.5752 26.2846 19.1974C25.5846 20.8196 24.6346 22.2307 23.4346 23.4307C22.2346 24.6307 20.8235 25.5807 19.2013 26.2807C17.5791 26.9807 15.8457 27.3307 14.0013 27.3307Z"
                fill="#E72528"
              />
            </svg>
          </button>
        </div>
        <div className="flex h-full w-full grow flex-col gap-4 p-4">
          <div className="flex gap-4">
            {/* Old Image (Small Box) */}
            <div className="bg-gray-50 relative flex h-[200px] w-[30%] items-center justify-center rounded-md border-2 border-gray-400 p-2">
              {image.src ? (
                <img
                  src={image.src}
                  alt="Current Image"
                  className="h-full w-full rounded-md object-contain"
                />
              ) : (
                <p className="text-gray-500">No Image</p>
              )}
            </div>

            {/* New Image (Big Box) */}
            <div
              className="bg-gray-100 relative flex h-[250px] w-full cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-gray-400 p-2"
              onClick={() => fileInputRef.current.click()}
            >
              {previewImage ? (
                <div className="group relative flex h-full w-full items-center justify-center">
                  <img
                    src={previewImage}
                    alt="New Image"
                    className="h-full w-full rounded-md object-contain"
                  />
                  <div className="text-lg absolute inset-0 hidden items-center justify-center bg-black bg-opacity-50 text-white group-hover:flex">
                    Change Photo
                  </div>
                </div>
              ) : (
                <p className="text-gray-500">Click to Upload New Image</p>
              )}
              <input
                ref={fileInputRef}
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <TextBoxComponent
            type="text"
            className="mt-4 w-full border p-2"
            cssClass="e-outline"
            placeholder="Name"
            value={updatedData.name}
            change={(e) => setUpdatedData({ ...updatedData, name: e.value })}
          />
          <TextBoxComponent
            multiline={true}
            className="mt-2 w-full border p-2"
            cssClass="e-outline"
            placeholder="Description"
            value={updatedData.description}
            change={(e) =>
              setUpdatedData({ ...updatedData, description: e.value })
            }
          />
        </div>
        <div className="flex justify-between gap-4 border-t p-4">
          <button
            onClick={onCancel}
            className="w-full rounded border border-solid border-[#003460] bg-white px-4 py-4 text-[24px] text-[#003460]"
          >
            Cancel
          </button>
          <button
            onClick={() => onSave(updatedData)}
            className="w-full rounded bg-[#003460] px-4 py-4 text-[24px] text-[#F7CA41]"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const UploadModal = ({ onSave, onClose, onCancel }) => {
  const [newData, setNewData] = useState({
    name: "",
    description: "",
    src: "",
    uploaded_at: "",
  });

  const [previewImage, setPreviewImage] = useState("");
  const fileInputRef = useRef(null);
  const storage = getStorage();

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      setNewData({
        ...newData,
        src: downloadURL,
        uploaded_at: new Date().toISOString(),
      });

      setPreviewImage(URL.createObjectURL(file));
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="relative flex h-[80vh] w-full max-w-4xl flex-col rounded-lg border border-gray-300 bg-white shadow-lg">
        <div className="flex items-center justify-between rounded-t-lg border-b bg-[#C4DAF3] p-4">
          <h2 className="truncate text-xl font-bold text-[#04447A]">
            Uplaod New Photo
          </h2>
          <button onClick={onClose} className="bg-[#C4DAF3]">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.0013 15.8641L17.868 19.7307C18.1124 19.9752 18.4235 20.0974 18.8013 20.0974C19.1791 20.0974 19.4902 19.9752 19.7346 19.7307C19.9791 19.4863 20.1013 19.1752 20.1013 18.7974C20.1013 18.4196 19.9791 18.1085 19.7346 17.8641L15.868 13.9974L19.7346 10.1307C19.9791 9.88628 20.1013 9.57517 20.1013 9.1974C20.1013 8.81962 19.9791 8.50851 19.7346 8.26406C19.4902 8.01962 19.1791 7.8974 18.8013 7.8974C18.4235 7.8974 18.1124 8.01962 17.868 8.26406L14.0013 12.1307L10.1346 8.26406C9.89019 8.01962 9.57908 7.8974 9.2013 7.8974C8.82352 7.8974 8.51241 8.01962 8.26797 8.26406C8.02352 8.50851 7.9013 8.81962 7.9013 9.1974C7.9013 9.57517 8.02352 9.88628 8.26797 10.1307L12.1346 13.9974L8.26797 17.8641C8.02352 18.1085 7.9013 18.4196 7.9013 18.7974C7.9013 19.1752 8.02352 19.4863 8.26797 19.7307C8.51241 19.9752 8.82352 20.0974 9.2013 20.0974C9.57908 20.0974 9.89019 19.9752 10.1346 19.7307L14.0013 15.8641ZM14.0013 27.3307C12.1569 27.3307 10.4235 26.9807 8.8013 26.2807C7.17908 25.5807 5.76797 24.6307 4.56797 23.4307C3.36797 22.2307 2.41797 20.8196 1.71797 19.1974C1.01797 17.5752 0.667969 15.8418 0.667969 13.9974C0.667969 12.153 1.01797 10.4196 1.71797 8.79739C2.41797 7.17517 3.36797 5.76406 4.56797 4.56406C5.76797 3.36406 7.17908 2.41406 8.8013 1.71406C10.4235 1.01406 12.1569 0.664062 14.0013 0.664062C15.8457 0.664062 17.5791 1.01406 19.2013 1.71406C20.8235 2.41406 22.2346 3.36406 23.4346 4.56406C24.6346 5.76406 25.5846 7.17517 26.2846 8.79739C26.9846 10.4196 27.3346 12.153 27.3346 13.9974C27.3346 15.8418 26.9846 17.5752 26.2846 19.1974C25.5846 20.8196 24.6346 22.2307 23.4346 23.4307C22.2346 24.6307 20.8235 25.5807 19.2013 26.2807C17.5791 26.9807 15.8457 27.3307 14.0013 27.3307Z"
                fill="#E72528"
              />
            </svg>
          </button>
        </div>
        <div className="flex h-full w-full grow flex-col gap-4 p-4">
          <div
            className="bg-gray-100 relative flex h-[250px] w-full grow cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-gray-400 p-2"
            onClick={() => fileInputRef.current.click()}
          >
            {previewImage ? (
              <div className="group relative flex h-full w-full items-center justify-center">
                <img
                  src={previewImage}
                  alt="New Image"
                  className="h-full w-full rounded-md object-contain"
                />
                <div className="text-lg absolute inset-0 hidden items-center justify-center bg-black bg-opacity-50 text-white group-hover:flex">
                  Change Photo
                </div>
              </div>
            ) : (
              <p className="text-gray-500">Click to Upload New Image</p>
            )}
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
              required
            />
          </div>
          <TextBoxComponent
            type="text"
            className="mt-4 w-full border p-2"
            cssClass="e-outline"
            placeholder="Name"
            change={(e) => setNewData({ ...newData, name: e.value })}
            required
          />
          <TextBoxComponent
            multiline={true}
            className="mt-2 w-full border p-2"
            cssClass="e-outline"
            placeholder="Description"
            change={(e) => setNewData({ ...newData, description: e.value })}
            required
          />
        </div>
        <div className="flex justify-between gap-4 border-t p-4">
          <button
            onClick={onCancel}
            className="w-full rounded border border-solid border-[#003460] bg-white px-4 py-4 text-[24px] text-[#003460]"
          >
            Cancel
          </button>
          <button
            onClick={() => onSave(newData)}
            className="w-full rounded bg-[#003460] px-4 py-4 text-[24px] text-[#F7CA41]"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

// export const FullViewModal = ({ isOpen, onClose, imageSrc, imageAlt }) => {
//   if (!isOpen) return null;
//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
//       <div className="relative flex max-h-[90vh] w-auto flex-col p-4">
//         <img
//           src={imageSrc}
//           alt={imageAlt}
//           className="max-h-[70vh] w-auto object-contain"
//         />
//         <button onClick={onClose} className="mt-4 bg-black bg-opacity-0">
//           <svg
//             width="64"
//             height="64"
//             viewBox="0 0 64 64"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//             className="h-10 w-10 "
//           >
//             <rect width="64" height="64" rx="32" fill="#E72528" />
//             <path
//               d="M32 33.7034L26.038 39.6654C25.815 39.8885 25.5311 40 25.1863 40C24.8416 40 24.5577 39.8885 24.3346 39.6654C24.1115 39.4423 24 39.1584 24 38.8137C24 38.4689 24.1115 38.185 24.3346 37.962L30.2966 32L24.3346 26.038C24.1115 25.815 24 25.5311 24 25.1863C24 24.8416 24.1115 24.5577 24.3346 24.3346C24.5577 24.1115 24.8416 24 25.1863 24C25.5311 24 25.815 24.1115 26.038 24.3346L32 30.2966L37.962 24.3346C38.185 24.1115 38.4689 24 38.8137 24C39.1584 24 39.4423 24.1115 39.6654 24.3346C39.8885 24.5577 40 24.8416 40 25.1863C40 25.5311 39.8885 25.815 39.6654 26.038L33.7034 32L39.6654 37.962C39.8885 38.185 40 38.4689 40 38.8137C40 39.1584 39.8885 39.4423 39.6654 39.6654C39.4423 39.8885 39.1584 40 38.8137 40C38.4689 40 38.185 39.8885 37.962 39.6654L32 33.7034Z"
//               fill="white"
//             />
//           </svg>
//         </button>
//       </div>
//     </div>
//   );
// };

export default ImageDialog;
