import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import HeaderTitle from "../../../components/HeaderTitle";
import { useParams } from 'react-router-dom';
import CaseManagementDisasterLockbox from './CaseManagementDisasterLockbox';
import CaseManagementSurveyQuestions from './CaseManagementSurveyQuestions';

const CaseManagementDisaster = () => {
  const { subtab } = useParams();

  const [selectedDisasters, setSelectedDisasters] = useState([]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const [disasterStats, setDisasterStats] = useState([]);
  const [disasterOptions, setDisasterOptions] = useState([]);
  const [userData, setUserData] = useState(null);

  const selectedUserId = sessionStorage.getItem("userId");

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const filterSettings = {
    type: "CheckBox",
  };

  const sortSettings = {
    allowSorting: true,
  };

  const fetchDisasterStats = async (disasters) => {
    try {
      if (!disasters || disasters.length === 0) {
        console.log("No disasters provided to fetch stats");
        return [];
      }
      const stats = await Promise.all(
        disasters.map(async (disaster) => {
          // Get all cases from case_management for this disaster
          const casesQuery = query(
            collection(db, "case_management"),
            where("disaster_id", "==", disaster.id),
            where("center_id", "==", userData.center_id)
          );
          const casesSnapshot = await getDocs(casesQuery);

          // Get all survey responses that have this disaster
          const surveyResponses = new Map(); // Use Map to store case details
          const usersRef = collection(db, "users");
          const usersQuery = query(
            usersRef,
            where("center_id", "==", userData.center_id),
            where("user_role", "==", "citizen")
          );
          const usersSnapshot = await getDocs(usersQuery);

          // Collect all survey responses with case numbers
          for (const userDoc of usersSnapshot.docs) {
            const surveyQuery = query(
              collection(db, `users/${userDoc.id}/survey_responses`)
            );
            const surveySnapshot = await getDocs(surveyQuery);

            surveySnapshot.docs.forEach((doc) => {
              const surveyData = doc.data();
              if (
                surveyData.disasters?.some(
                  (d) => d.disaster_id === disaster.id
                ) &&
                surveyData.case_number
              ) {
                surveyResponses.set(surveyData.case_number, {
                  case_number: surveyData.case_number,
                  client_name: `${userDoc.data().first_name} ${
                    userDoc.data().last_name
                  }`,
                  client_id: userDoc.id,
                  email: userDoc.data().email,
                  phone: userDoc.data().mobile_number,
                  address: `${userDoc.data().address1}${
                    userDoc.data().address2
                      ? `, ${userDoc.data().address2}`
                      : ""
                  }, ${userDoc.data().city}, ${userDoc.data().state} ${
                    userDoc.data().zip
                  }`,
                  survey_date: surveyData.created_at || surveyData.timestamp,
                  status: "Unassigned",
                  assigned_to: "",
                  county: userDoc.data().county || "Unspecified", // Add county from user data
                });
              }
            });
          }

          // Update status for cases that exist in case_management
          casesSnapshot.docs.forEach((doc) => {
            const caseData = doc.data();
            if (surveyResponses.has(caseData.case_number)) {
              const existingCase = surveyResponses.get(caseData.case_number);
              surveyResponses.set(caseData.case_number, {
                ...existingCase,
                status: caseData.status || "Open",
                assigned_to: caseData.assigned_to || "",
                assigned_company: caseData.assigned_company || "",
                assignment_date: caseData.assignment_date || "",
              });
            }
          });

          const casesByCounty = new Map();
          surveyResponses.forEach((caseItem) => {
            const county = caseItem.county || "Unspecified";
            if (!casesByCounty.has(county)) {
              casesByCounty.set(county, {
                county: county,
                disaster_name: disaster.name,
                disaster_id: disaster.id,
                status: disaster.status,
                cases: [],
                open_cases: 0,
                unassigned_cases: 0,
                total_cases: 0,
              });
            }

            const countyStats = casesByCounty.get(county);
            countyStats.cases.push(caseItem);
            countyStats.total_cases++;
            if (caseItem.status === "Open") countyStats.open_cases++;
            if (caseItem.status === "Unassigned")
              countyStats.unassigned_cases++;
          });

          // Calculate overall disaster statistics
          const allCases = Array.from(surveyResponses.values());
          const partnerStats = new Map();
          allCases.forEach((caseItem) => {
            if (caseItem.assigned_company) {
              if (!partnerStats.has(caseItem.assigned_company)) {
                partnerStats.set(caseItem.assigned_company, {
                  company: caseItem.assigned_company,
                  assigned_cases: 0,
                });
              }
              partnerStats.get(caseItem.assigned_company).assigned_cases++;
            }
          });

          return {
            disaster_name: disaster.name,
            disaster_id: disaster.id,
            status: disaster.status,
            counties: Array.from(casesByCounty.values()),
            open_cases: allCases.filter((c) => c.status === "Open").length,
            unassigned_cases: allCases.filter((c) => c.status === "Unassigned")
              .length,
            total_cases: surveyResponses.size,
            partners: Array.from(partnerStats.values()),
          };
        })
      );

      const validStats = stats.filter((stat) => stat !== null);
      console.log({ validStats });
      setDisasterStats(validStats);
      return validStats;
    } catch (error) {
      console.error("Error fetching disaster statistics:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        if (!userData || !userData?.center_id) return;
        // First get the current user's center
        const auth = getAuth();
        const userDoc = await getDoc(doc(db, "centers", userData.center_id));
        const centerCounty = userDoc.data()?.county;

        if (!centerCounty) {
          console.warn("Center county not found");
          return;
        }

        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs
          ?.map((doc) => ({
            id: doc.id,
            name: doc.data().disaster_name,
            status: doc.data().disaster_status,
            disaster_type: doc.data().disaster_type,
            county: doc.data().disaster_county,
            state: doc.data().disaster_state,
            affected_counties: doc.data().affected_counties || [],
          }))
          .filter((disaster) =>
            disaster.affected_counties.includes(centerCounty)
          );

        // Sort disasters by status: active first, then inactive, then closed
        const activeDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "active"
        );
        const inactiveDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "inactive"
        );
        const closedDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "closed"
        );

        const sortedDisasters = [
          ...activeDisasters,
          ...inactiveDisasters,
          ...closedDisasters,
        ];

        setActiveDisasters(activeDisasters?.map((disaster) => disaster.id));
        setDisasterOptions(sortedDisasters);
        if (activeDisasters.length > 0) {
          setSelectedDisasters([activeDisasters[0].id]);
        }

        // Fetch statistics for the disasters
        await fetchDisasterStats(sortedDisasters);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, [userData]);

  const renderContent = () => {
    switch (subtab) {
      case 'disaster_lockbox':
        return <CaseManagementDisasterLockbox />;

      case 'survey_questions':
        return <CaseManagementSurveyQuestions />;

      case 'project':
        return (
          <div className="flex-1 p-[20px]">
            <HeaderTitle id="project" title="Project Management" />
            <div className="mt-4 grid grid-cols-1 gap-6">
              <div className="rounded-lg border bg-white p-6 shadow-sm">
                <h3 className="mb-4 text-xl font-semibold">Disaster Projects</h3>
                <div className="space-y-4">
                  {/* Placeholder for project content */}
                  <p className="text-gray-600">Manage and track disaster recovery projects.</p>
                </div>
              </div>
            </div>
          </div>
        );

      case 'overview':
      default:
        return (
          <div className="flex-1 p-[20px]">
            <HeaderTitle id="disaster" title="Disaster Overview" />
            <GridComponent
              dataSource={disasterStats}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowSorting={true}
              sortSettings={sortSettings}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
              cssClass="new-grid-color"
            >
              <ColumnsDirective>
                <ColumnDirective field="disaster_name" headerText="Disaster" />
                <ColumnDirective field="total_cases" headerText="Total Cases" />
                <ColumnDirective field="open_cases" headerText="Open Cases" />
                <ColumnDirective
                  field="unassigned_cases"
                  headerText="Unassigned Cases"
                />
                <ColumnDirective
                  field="counties"
                  headerText="Counties"
                  template={(props) => {
                    return (
                      <div className="flex flex-wrap gap-2">
                        {!!props?.counties &&
                          !!props?.counties.length &&
                          props.counties.map(({ county, total_cases }, i) => {
                            return (
                              <div
                                key={i}
                                className="rounded-full border border-solid border-[#9D0F0F] bg-[#FFF3F3] px-2 py-1"
                              >
                                <p className="text-sm text-[#9D0F0F]">
                                  {county || ""} ({total_cases || 0})
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    );
                  }}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        );
    }
  };

  return renderContent();
};

export default CaseManagementDisaster;
