import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import NotificationDetailsPopup from "./NotificationDetailsPopup";
import EmergencyPopup from "./EmergencyPopup";

const NotificationPanel = ({ isOpen, onClose, notifications }) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  if (!isOpen) return null;

  const sortedNotifications = [...notifications].sort((a, b) => {
    if (
      a.urgency === "emergency" &&
      a.active &&
      (!b.urgency === "emergency" || !b.active)
    )
      return -1;
    if (
      b.urgency === "emergency" &&
      b.active &&
      (!a.urgency === "emergency" || !a.active)
    )
      return 1;

    const timeA = a.timestamp?.toDate() || new Date(0);
    const timeB = b.timestamp?.toDate() || new Date(0);
    return timeB - timeA;
  });

  const formatDateTime = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const timeString = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    if (date.toDateString() === today.toDateString()) {
      return `Today at ${timeString}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${timeString}`;
    } else {
      return `${date.toLocaleDateString()} at ${timeString}`;
    }
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const truncateText = (text, maxLength = 100) => {
    if (!text) return "";
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const getNotificationClasses = (notification) => {
    let classes =
      "border-b p-4 hover:bg-gray-500 border-[1px] border-solid border-silver-700";

    if (notification.urgency === "emergency" && notification.active) {
      classes += " bg-red-50 border-red-200 hover:bg-red-100";
    } else if (!notification.read) {
      classes += " bg-blue-50";
    } else {
      classes += " bg-gray-400 text-gray-800 hover:bg-blue-500";
    }

    return classes;
  };

  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);

    if (!notification.read && auth.currentUser) {
      const selectedUserId = sessionStorage.getItem("userId");
      try {
        const notificationRef = doc(
          db,
          "notifications",
          selectedUserId,
          "notifications",
          notification.id
        );
        await updateDoc(notificationRef, {
          read: true,
        });
      } catch (error) {
        console.error("Error marking notification as read:", error);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-end">
      <div
        className="fixed inset-0 bg-black opacity-30"
        onClick={onClose}
      ></div>
      <div className="relative mr-4 mt-[6vh] w-96 rounded-lg shadow-xl">
        <div className="flex items-center justify-between rounded-t-lg border-b bg-[#467BA4] p-4 text-[#FDB841]">
          <h2 className="text-lg font-semibold">Notifications</h2>
          <button
            onClick={onClose}
            className="cursor-pointer bg-[#467BA4] text-gray-500 hover:text-[#FDB841]"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="max-h-[80vh] cursor-pointer overflow-y-auto rounded-b-lg text-black">
          {sortedNotifications.length > 0 ? (
            sortedNotifications.map((notification) => (
              <div
                key={notification.id}
                className={getNotificationClasses(notification)}
                onClick={() => handleNotificationClick(notification)}
              >
                <div>
                  {notification.urgency && (
                    <div className="mb-1">
                      {notification.urgency === "emergency" &&
                        notification.active && (
                          <span className="inline-flex items-center rounded-full bg-red-600 px-2 py-0.5 text-xs text-white">
                            EMERGENCY
                          </span>
                        )}
                      {notification.urgency === "warning" && (
                        <span className="inline-flex items-center rounded-full bg-orange-500 px-2 py-0.5 text-xs text-white">
                          WARNING
                        </span>
                      )}
                      {notification.urgency === "updates" && (
                        <span className="inline-flex items-center rounded-full bg-green-500 px-2 py-0.5 text-xs text-white">
                          UPDATES
                        </span>
                      )}
                      {notification.urgency === "general" && (
                        <span className="inline-flex items-center rounded-full bg-blue-500 px-2 py-0.5 text-xs text-white">
                          GENERAL NEWS
                        </span>
                      )}
                    </div>
                  )}
                  <div className="flex items-center justify-between">
                    <h3 className="font-semibold">
                      {truncateText(notification.title, 15)}
                    </h3>
                    <span className="ml-2 text-xs text-gray-500">
                      {formatDateTime(notification.timestamp)}
                    </span>
                  </div>
                </div>
                <p className="text-gray-600 mb-1 text-sm">
                  From: {notification.sender?.email || "System"}
                </p>
                <p
                  className={`text-sm ${
                    notification.urgency === "emergency" && notification.active
                      ? "text-red-800"
                      : "text-gray-700"
                  }`}
                >
                  {truncateText(stripHtmlTags(notification.content))}
                </p>
              </div>
            ))
          ) : (
            <div className="bg-slate-100 p-4 text-center text-gray-500">
              No notifications
            </div>
          )}
        </div>
      </div>
      {selectedNotification && (
        /*
                selectedNotification.urgency === 'emergency' ? (
                    <EmergencyPopup
                        notification={selectedNotification}
                        onClose={() => setSelectedNotification(null)}
                    />
                ) : (
                */
        <NotificationDetailsPopup
          notification={selectedNotification}
          onClose={() => setSelectedNotification(null)}
        />
        // )
      )}
    </div>
  );
};

export default NotificationPanel;
