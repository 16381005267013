import React, { useState, useRef, useEffect } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../../firebase";
import {
  getDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDocs,
  collection,
  where,
  query,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../../service/data/DataService";
import { useNavigate } from "react-router-dom";
import HeaderTitle from "../../../components/HeaderTitle";

const CaseManagementAgency = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [approvedData, setApprovedData] = useState([]);
  const [forCertifyGrid, setForCertifyGrid] = useState([]);
  const [certifiedrid, setCertifiedGrid] = useState([]);
  const [centers, setCenters] = useState([]);
  const [centerId, setCenterId] = useState(null);
  const [activeTab, setActiveTab] = useState("Certified");
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();

  const tabs = ["Certified", "For Certification"];
  const selectedUserId = sessionStorage.getItem("userId");

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const handleTabClick = (tab) => setActiveTab(tab);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (selectedUserId) {
        setUserId(selectedUserId);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setCenterId(userDoc.data().center_id);

        // const forApprovalPartners = await dataService.getPartnerForApproval(
        //   userDoc.data().center_id
        // );
        // console.log("For approval", forApprovalPartners);
        // setGridData(forApprovalPartners);

        // const approvedPartners = await dataService.getApprovedPartners(
        //   userDoc.data().center_id
        // );
        // console.log("approved partners", approvedPartners);
        // setApprovedData(approvedPartners);

        const caseManagementRef = collection(db, "case_management");
        const caseManagementSnapshot = await getDocs(caseManagementRef);
        
        // Create a map of org IDs to their case counts
        const caseCountMap = {};
        caseManagementSnapshot.docs.forEach(doc => {
          const caseData = doc.data();
          const orgId = caseData.assigned_to_org_uid;
          if (orgId) {
            if (!caseCountMap[orgId]) {
              caseCountMap[orgId] = { open: 0, closed: 0 };
            }
            if (caseData.status === 'closed') {
              caseCountMap[orgId].closed += 1;
            } else {
              caseCountMap[orgId].open += 1;
            }
          }
        });

        const forCertifyPartners = await dataService.getPartnerForCertify(
          userDoc.data().center_id
        );
        console.log("For certify", forCertifyPartners);
        setForCertifyGrid(forCertifyPartners);

        let certifiedPartners = await dataService.getCertifiedPartners(
          userDoc.data().center_id
        );

        // Filter out partners that provide "Case Management"
        certifiedPartners = certifiedPartners.filter(
          (partner) =>
            partner.servicesProvided &&
            partner.servicesProvided.includes("Case Management")
        ).map(partner => ({
          ...partner,
          openCases: caseCountMap[partner.id]?.open || 0,
          closedCases: caseCountMap[partner.id]?.closed || 0,
        }));

        console.log("Certified partners (filtered)", certifiedPartners);
        setCertifiedGrid(certifiedPartners);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const casesTemplate = ({ openCases = 0, closedCases = 0 }) => {
    return (
      <div className="flex flex-col space-y-2">
        {openCases > 0 && (
          <div className="bg-[#0A8525] text-white px-4 py-2 rounded-full text-center max-w-fit mx-auto">
            Open ({openCases}) 
          </div>
        )}
        {closedCases > 0 && (
          <div className="bg-red-600 text-white px-4 py-2 rounded-full text-center max-w-fit mx-auto">
            Closed ({closedCases}) 
          </div>
        )}
        {openCases === 0 && closedCases === 0 && (
          <div className="bg-gray-400 text-white px-4 py-2 rounded-full text-center max-w-fit mx-auto">
            No Cases
          </div>
        )}
      </div>
    );
  };
  
  
  const openModal = (partnerId, field, currentStatus, Id) => {
    // if (!partnerId) {
    //   console.error("Invalid data passed to modal:", {
    //     partnerId,
    //     field,
    //     currentStatus,
    //     Id,
    //   });

    //   return;
    // }
    if (!partnerId) {
      partnerId = Id;
      setModalData({ partnerId, field, currentStatus });
    } else {
      setModalData({ partnerId, field, currentStatus });
    }
  };

  const closeModal = () => setModalData(null);

  const handleAction = async () => {
    if (!modalData || !centerId || !modalData.partnerId) {
      console.error("Invalid modal data:", modalData);
      return;
    }

    const { partnerId, field, currentStatus } = modalData;
    const newStatus = !currentStatus;

    try {
      // **Fetch all documents using partnerId**
      const usersQuery = query(
        collection(db, "users"),
        where("partner_id", "==", partnerId)
      );
      const partnersForApprovalQuery = query(
        collection(db, "partner-for-approval"),
        where("partner_id", "==", partnerId)
      );
      const partnerRef = doc(db, "partners", partnerId); // "partners" collection uses ID directly

      const [usersSnapshot, partnersForApprovalSnapshot] = await Promise.all([
        getDocs(usersQuery),
        getDocs(partnersForApprovalQuery),
      ]);

      if (usersSnapshot.empty && partnersForApprovalSnapshot.empty) {
        console.error("No documents found with partner_id:", partnerId);
        return;
      }

      // **Update "users" collection**
      for (const userDoc of usersSnapshot.docs) {
        const userRef = doc(db, "users", userDoc.id);
        await updateDoc(userRef, { [field]: newStatus });

        if (field === "is_certified") {
          if (newStatus) {
            // **Approving: Add center_id if not already present**
            if (!userDoc.data().center_id.includes(centerId)) {
              await updateDoc(userRef, { center_id: arrayUnion(centerId) });
            }
          } else {
            // **Unapproving: Remove center_id**
            if (userDoc.data().center_id.includes(centerId)) {
              await updateDoc(userRef, { center_id: arrayRemove(centerId) });
            }
          }
        }
      }

      // **Update "partners-for-approval" collection**
      for (const docSnap of partnersForApprovalSnapshot.docs) {
        await updateDoc(docSnap.ref, { [field]: newStatus });
      }

      // **Update "partners" collection using partnerId**
      await updateDoc(partnerRef, { [field]: newStatus });

      // **Update local state**
      setGridData((prev) =>
        prev.map((item) =>
          item.partner_id === partnerId ? { ...item, [field]: newStatus } : item
        )
      );
      setApprovedData((prev) =>
        prev.map((item) =>
          item.partner_id === partnerId ? { ...item, [field]: newStatus } : item
        )
      );

      closeModal();
      fetchData();
    } catch (error) {
      console.error(`Error updating ${field} status:`, error);
    }
  };

  const getModalTitle = (field, currentStatus) => {
    if (field === "is_approved") {
      return currentStatus ? "Unapprove User" : "Approve User";
    } else if (field === "is_certified") {
      return currentStatus ? "Uncertify Partner" : "Certify Partner";
    }
    return "Update User Status";
  };

  const getModalMessage = (field, currentStatus) => {
    if (field === "is_approved") {
      return `Are you sure you want to ${
        currentStatus ? "unapprove" : "approve"
      } this user? This action will update their status accordingly.`;
    } else if (field === "is_certified") {
      return `Are you sure you want to ${
        currentStatus ? "uncertify" : "certify"
      } this user? This action will update their status accordingly.`;
    }
    return "Are you sure you want to update this user's status?";
  };

  const statusTemplate = (rowData) => (
    <div className="flex justify-between">
      {/* <button
        onClick={() =>
          openModal(
            rowData.partner_id,
            "is_approved",
            rowData.is_approved,
            rowData.id
          )
        }
        className={`rounded px-2 py-1 ${
          rowData.is_approved
            ? "bg-green-500 text-white"
            : "bg-red-500 text-white"
        }`}
      >
        {rowData.is_approved ? "Approved" : "Approve"}
      </button> */}
      <button
        // onClick={() =>
        //   openModal(
        //     rowData.partner_id,
        //     "is_certified",
        //     rowData.is_certified,
        //     rowData.id
        //   )
        // }
        className={`rounded px-2 py-1 ${
          rowData.is_certified
            ? "bg-green-500 text-white"
            : "bg-red-500 text-white"
        }`}
      >
        {rowData.is_certified ? "Certified" : "Certify"}
      </button>
    </div>
  );

  const handleViewDocuments = (rowData) => {
    console.log("rowData", rowData);
    navigate(`/partnershipdocuments`, {
      state: { partnerUserId: rowData.id },
    });
  };

  const handleViewDocumentsForApproval = (rowData) => {
    console.log("rowData", rowData);
    navigate(`/partnershipdocuments`, {
      state: { partnerUserId: rowData.partner_id },
    });
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start p-[20px] leading-[normal] tracking-[normal] ${className}`}
    >
      <HeaderTitle id="casemanagementagency" title="Case Management Agency" />

      <div className="mb-8 flex flex-col gap-4 self-stretch">
        <GridComponent
          dataSource={certifiedrid}
          allowSorting={false}
          sortSettings={sortSettings}
          filterSettings={filterSettings}
          allowFiltering={false}
          // toolbar={["Search"]}
          allowPaging={true}
          pageSettings={pageSettings}
          // showColumnMenu={true}
          // columnMenuItems={columnMenuItems}
          cssClass="new-grid-color"
        >
          <ColumnsDirective>
            <ColumnDirective
              field="name_of_org"
              headerText="Name of Organization"
              width="100"
            />
            <ColumnDirective
              field="address1"
              headerText="Address"
              width="100"
            />
            <ColumnDirective
              field="mobile_number"
              headerText="Mobile Number"
              width="100"
            />
            <ColumnDirective
              field="email"
              headerText="Email Address"
              width="100"
            />
            {/* <ColumnDirective
                    headerText="Documents"
                    width="100"
                    template={(rowData) => (
                      <button
                        onClick={() => handleViewDocuments(rowData)}
                        className="rounded bg-blue-500 px-3 py-1 text-white hover:bg-blue-700"
                      >
                        View Documents
                      </button>
                    )}
                  /> */}
            <ColumnDirective
              headerText="Status"
              width="80"
              template={statusTemplate}
            />
            <ColumnDirective 
              field="cases" 
              headerText="Cases" 
              template={casesTemplate}
              width="150"
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Edit, Toolbar, Sort, Filter, Search, ColumnMenu]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default CaseManagementAgency;
