import React, { useEffect, useMemo, useRef } from "react";

const ThreeDotMenu = ({
  menuId,
  activeMenuId,
  setActiveMenuId,
  activeMenu,
  setActiveMenu,
  onEditClick,
  onDeleteClick,
  clickOutside = true,
}) => {
  const menuRef = useRef(null);

  useEffect(() => {
    if (!clickOutside) return;
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest(".absolute")
      ) {
        if (setActiveMenuId && menuId) {
          setActiveMenuId(null);
        } else if (setActiveMenu) {
          setActiveMenu(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setActiveMenuId, menuId, setActiveMenu, clickOutside]);

  const toggleMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Handle both types of menu control
    if (setActiveMenuId && menuId) {
      // Use ID-based control when those props are provided
      setActiveMenuId(activeMenuId === menuId ? null : menuId);
    } else if (setActiveMenu) {
      // Fall back to boolean control
      setActiveMenu(!activeMenu);
    }
  };

  //   const isMenuOpen = menuId ? activeMenuId === menuId : activeMenu;
  const isMenuOpen = useMemo(
    () => (menuId ? activeMenuId === menuId : activeMenu),
    [menuId, activeMenuId, activeMenu]
  );

  return (
    <div className="relative overflow-visible" ref={menuRef}>
      <button
        onClick={(e) => toggleMenu(e)}
        className="cursor-pointer bg-transparent"
      >
        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
      </button>
      {isMenuOpen && (
        <div className="absolute right-0 top-6 z-[10000] rounded-[4px] bg-white px-2 py-2 shadow-lg">
          {onEditClick && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEditClick(e);
              }}
              className="flex w-full cursor-pointer items-center rounded-[4px] bg-white px-4 py-2 text-left text-sm hover:bg-barley-white"
            >
              {/* <svg
                className="mr-2 h-4 w-4 text-blue-600"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-10 10a2 2 0 01-1.414.586H3a1 1 0 01-1-1v-2a2 2 0 01.586-1.414l10-10z" />
              </svg> */}
              Edit
            </button>
          )}
          {onDeleteClick && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDeleteClick(e);
              }}
              className="flex w-full cursor-pointer items-center rounded-[4px] bg-white px-4 py-2 text-left text-sm text-dark-burgundy hover:bg-dark-burgundy hover:text-white"
            >
              {/* <svg
                className="mr-2 h-4 w-4 text-red-600"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" />
              </svg> */}
              Remove
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ThreeDotMenu;
