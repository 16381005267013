import React, { useState, useEffect, useRef } from "react";
import { FaEdit, FaTrash, FaEye, FaInfoCircle, FaTimes } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import { GoTriangleLeft } from "react-icons/go";
import { db, auth } from "../../../firebase";
import { ReactComponent as BeforeDisasterIcon } from "./../../../assets/journals-icons/before-disaster.svg";
import { ReactComponent as DuringDisasterIcon } from "./../../../assets/journals-icons/during-disaster.svg";
import { ReactComponent as ImmediateAftermathIcon } from "./../../../assets/journals-icons/immediate-aftermath.svg";
import { ReactComponent as OngoingImpactRecoveryIcon } from "./../../../assets/journals-icons/ongoing-impact-recovery.svg";
import { ReactComponent as ReflectionsLookingForward } from "./../../../assets/journals-icons/reflections-looking-forward.svg";
import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import "./../../style.css";
import journalpublic from "../../../assets/disaster-icons/journal-public.svg";
import journalprivate from "../../../assets/disaster-icons/journal-private.svg";
import verticalDot from "./../../../assets/vertical-dot.svg";
import { X } from "lucide-react";
import HeaderTitle from "../../../components/HeaderTitle";

const CaseManagementJournals = ({ className = "", disasterStage }) => {
  console.log("Disaster Stage in Journals:", disasterStage);

  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [data, setData] = useState([]);
  const [disasterJournals, setDisasterJournals] = useState([]);
  const gridRef = useRef(null);
  const [popupMessage, setPopupMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const now = new Date();
  const [editing, setEditing] = useState({
    allowEditing: false,
    allowAdding: true,
    allowDeleting: false,
    mode: "Dialog",
    dialog: { cssClass: "custom-dialog" },
    allowEditOnDblClick: false,
  });
  const [showGuidePopup, setShowGuidePopup] = useState(false);
  const [activeIndexSections, setActiveIndexSections] = useState([0]);

  const selectedUserId = sessionStorage.getItem("userId");
  const employeeDetails = JSON.parse(sessionStorage.getItem("employeeDetails"));
  const accessedUserId = sessionStorage.getItem("accessedUserId");

  const onToggleSection = (index) => {
    setActiveIndexSections((prev) => {
      if (prev.includes(index)) {
        return prev.filter((p) => p !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const GuidePopup = ({ onClose }) => {
    const popupRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          popupRef.current &&
          (popupRef.current.contains(event.target) ||
            event.target.closest("[data-ignore-close]"))
        ) {
          return; // Ignore clicks inside the popup or on toggle button
        }

        popupRef.current.classList.add("animate-popup-out");
        setTimeout(onClose, 500);
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onClose]);

    const phases = [
      {
        label: "Phase 1",
        questionsGroup: [
          {
            title: "Before the Disaster",
            icon: <BeforeDisasterIcon className=" h-5 w-5" />,
            questions: [
              "What was your daily life like before the disaster?",
              "Did you have any emergency plans or preparations in place? If so, what were they?",
              "What concerns, if any, did you have about disasters before this event?",
            ],
          },
          {
            title: "During the Disaster",
            icon: <DuringDisasterIcon className=" h-5 w-5" />,
            questions: [
              "How did you first realize the disaster was happening?",
              "Where were you when the disaster occurred, and what were your first actions?",
              "What immediate steps did you take to protect yourself and your loved ones?",
              "What emotions did you experience during the disaster?",
              "Were you able to communicate with anyone for help or information? If so, how?",
            ],
          },
          ,
          {
            title: "Immediate Aftermath",
            icon: <ImmediateAftermathIcon className=" h-5 w-5" />,
            questions: [
              "What was the first thing you noticed after the disaster had passed?",
              "What were the biggest challenges you faced in the first few hours or days?",
              "Did you receive any assistance? If so, from whom (family, neighbors, first responders, organizations, etc.)?",
              "What personal items, belongings, or spaces were lost or damaged?",
              "How did the disaster affect your ability to meet basic needs (food, water, shelter, medical care)?",
              "What has been the biggest impact to you personally?",
            ],
          },
        ],
      },
      {
        label: "Phase 2",
        questionsGroup: [
          {
            title: "Ongoing Impact & Recovery",
            icon: <OngoingImpactRecoveryIcon className=" h-5 w-5" />,
            questions: [
              "How has the disaster affected your daily life (work, school, home, relationships, etc.)?",
              "What are the biggest challenges you continue to face?",
              "Have you been able to rebuild or recover in any way? If so, how?",
              "What types of support have you received since the disaster, and from whom?",
              "How has your mental and emotional health been affected by this experience?",
            ],
          },
        ],
      },
      {
        label: "Phase 3",
        questionsGroup: [
          {
            title: "Reflections & Looking Forward",
            icon: <ReflectionsLookingForward className=" h-5 w-5" />,
            questions: [
              "What lessons have you learned from this experience?",
              "Has your perspective on disasters, preparedness, or community changed?",
              "What advice would you give to someone who might experience a similar disaster?",
              "What do you need most to fully recover?",
              "What are your hopes for the future?",
            ],
          },
        ],
      },
    ];

    return (
      <div
        ref={popupRef}
        className="absolute bottom-0 right-0 top-0 z-[99] w-[550px] bg-white shadow-2xl"
      >
        <div className=" flex flex-row items-center justify-between bg-[#C4DAF3] p-4">
          <p className=" text-[17px] font-semibold text-[#04447A]">
            Guide Questions
          </p>

          <button
            className="flex cursor-pointer items-center justify-center rounded-full bg-red-600 p-0.5 hover:brightness-110"
            onClick={onClose}
          >
            <X className=" h-4 w-4 text-white" strokeWidth={3} />
          </button>
        </div>
        <div className=" flex h-[95%] flex-col gap-2 overflow-y-auto p-4">
          {phases.map((phase, index) => {
            return (
              <div className=" flex flex-col">
                <div className=" flex flex-row items-center justify-between rounded-sm bg-[#003460] px-4 py-3">
                  <p className=" text-xl font-semibold text-[#F7CA41]">
                    {phase.label}
                  </p>
                  <div
                    onClick={() => onToggleSection(index)}
                    className={`flex cursor-pointer items-center justify-center transition-transform duration-300 ease-in-out ${
                      !activeIndexSections.includes(index)
                        ? "-rotate-90"
                        : "rotate-90"
                    }`}
                  >
                    <GoTriangleLeft className=" h-6 w-6" color="#F7CA41" />
                  </div>
                </div>
                {activeIndexSections.includes(index) && (
                  <div className=" flex flex-col gap-3 pt-4">
                    {phase.questionsGroup.map((group) => {
                      return (
                        <div className=" flex flex-col text-[14px]">
                          <div className=" flex flex-row items-center gap-1 text-[#188AEB]">
                            {group.icon}
                            <p>{group.title}</p>
                          </div>
                          <div className=" flex flex-col gap-3 px-1 py-3 text-[#242424]">
                            {group.questions.map((question, index) => (
                              <div className=" flex flex-row">
                                <div className=" w-4 flex-none">
                                  {index + 1}.
                                </div>
                                <p>{question}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const [isMounted, setIsMounted] = useState(true);

  const fetchJournals = async () => {
    if (!accessedUserId) return;

    try {
      const querySnapshot = await getDocs(collection(db, "disaster-journals"));

      // Only update state if component is still mounted and accessedUserId hasn't changed
      if (isMounted) {
        const journalsData = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (journal) =>
              !journal.is_deleted &&
              journal.userId === accessedUserId &&
              journal.is_public
          );
        setDisasterJournals(journalsData);
      }
    } catch (error) {
      console.error("Error fetching Journals:", error);
    }
  };

  useEffect(() => {
    if (!accessedUserId) return;

    fetchJournals();

    return () => {
      setIsMounted(false);
    };
  }, [accessedUserId, selectedUserId]); // Keep both dependencies

  const fetchUserRole = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const role = userDoc.data().user_role;
        setUserRole(role);

        if (role === "citizen" || role === "partner") {
          setEditing((prevEditing) => ({
            ...prevEditing,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: false,
          }));
        } else if (role === "center") {
          setEditing((prevEditing) => ({
            ...prevEditing,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const formattedTime = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = now.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
  });

  const generateJournalId = () => {
    return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleAddJournal = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const getEditorTypeColor = (editorType) => {
    switch (editorType) {
      case "center":
        return "green-500";
      case "partner":
        return "yellow-500";
      case "citizen":
        return "blue-500";
      default:
        return "gray-500";
    }
  };

  const getEditorTypeLetter = (editorType) => {
    switch (editorType) {
      case "center":
        return "C";
      case "partner":
        return "P";
      case "citizen":
        return "S";
      default:
        return "?";
    }
  };

  const getEditorTypeLabel = (editorType) => {
    switch (editorType) {
      case "center":
        return "Center";
      case "partner":
        return "Partner";
      case "citizen":
        return "Survivor";
      default:
        return "Unknown";
    }
  };

  const privacyOptions = [
    { value: "Public" },
    { value: "Private" },
    { value: "Restricted" },
  ];

  const showPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);
  };

  const actionBegin = async (args) => {
    if (args.requestType === "add") {
      const currentDate = new Date();
      const formattedDate = `${
        currentDate.getMonth() + 1
      }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
      const formattedTime = currentDate.toTimeString().split(" ")[0];

      args.data.journalDate = formattedDate;
      args.data.journalTime = formattedTime;
    }

    if (args.requestType === "save") {
      const data = args.data;

      const disaster = data.disaster || "";
      const description = data.description || "";
      const date = data.journalDate || formattedDate;
      const time = data.journalTime || formattedTime;

      if (!disaster.trim() || !description.trim()) {
        args.cancel = true;
        alert("Please fill in disaster and description before saving.");
        return;
      }

      data.journalId = generateJournalId();
      data.userId = userId;
      data.userRole = userRole;
      data.editorId = userId;
      data.editorType = userRole;
      data.journalDate = date;
      data.journalTime = time;

      try {
        if (args.action === "add") {
          data.inserted_at = serverTimestamp();
          const docRef = await addDoc(
            collection(db, "disaster-journals"),
            data
          );
          data.id = docRef.id;
        } else if (args.action === "edit") {
          if (userRole === "citizen" || userRole === "partner") {
            if (
              args.rowData.editor_ID !== userId ||
              args.rowData.editor_type !== userRole
            ) {
              args.cancel = true;
              showPopup("You don't have permission to edit this record.");
              return;
            }
          }
          args.rowData = { ...args.rowData };
        }

        setDisasterJournals((prevJournals) => {
          const index = prevJournals.findIndex(
            (journal) => journal.id === data.id
          );
          if (index !== -1) {
            return prevJournals.map((journal) =>
              journal.id === data.id ? { ...journal, ...data } : journal
            );
          } else {
            return [...prevJournals, data];
          }
        });
      } catch (error) {
        console.error("Error saving document:", error);
      }
    } else if (args.requestType === "delete") {
      try {
        const deleteData = args.data[0];
        const docRef = doc(db, "disaster-journals", deleteData.id);

        const confirmDelete = window.confirm(
          `Are you sure you want to delete the journal entry for disaster "${deleteData.disaster}"?`
        );

        if (confirmDelete) {
          await updateDoc(docRef, { is_deleted: true });

          setDisasterJournals((prevJournals) =>
            prevJournals.filter((journal) => journal.id !== deleteData.id)
          );
        } else {
          args.cancel = true;
        }
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const handleSaveJournal = async (e) => {
    if (e.key === "Enter") {
      const description = e.target.value.trim();
      if (!description) return;

      const now = new Date();

      const journalEntry = {
        description,
        journalId: generateJournalId(),
        userId: accessedUserId,
        userRole: "citizen",
        editorId: accessedUserId,
        editorType: "citizen",
        timestamp: serverTimestamp(),
        inserted_at: serverTimestamp(),
        is_public: false,
      };

      try {
        const docRef = await addDoc(
          collection(db, "disaster-journals"),
          journalEntry
        );
        setDisasterJournals((prevJournals) => [
          ...prevJournals,
          { ...journalEntry, id: docRef.id },
        ]);
        e.target.value = "";
      } catch (error) {
        console.error("Error saving journal entry:", error);
      }
    }
  };

  const dateTemplate = (props) => {
    if (!props.timestamp) return null;

    const dateObj = props.timestamp.toDate();
    const day = dateObj.getDate();
    const month = dateObj
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    const year = dateObj.getFullYear();

    return (
      <div className="flex">
        <div className="flex items-center space-x-1">
          <span
            className={`h-6 w-6 rounded-full bg-${getEditorTypeColor(
              props.editorType
            )} flex items-center justify-center text-xs font-bold text-white`}
            title={`Editor Type: ${getEditorTypeLabel(props.editorType)}`}
          >
            {getEditorTypeLetter(props.editorType)}
          </span>
        </div>
        <div className="ml-2 flex w-[35px] flex-col items-start justify-start">
          <a className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]">
            {day}
          </a>
          <b className="relative flex w-[34px] items-center text-xs">{month}</b>
          <a
            className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]"
            style={{ fontSize: "10px" }}
          >
            {year}
          </a>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (showGuidePopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showGuidePopup]);

  useEffect(() => {
    // Set initial open sections based on disasterStage
    if (disasterStage === "Relief" || disasterStage === "Response") {
      setActiveIndexSections([0, 1]);
    } else if (disasterStage === "Recovery") {
      setActiveIndexSections([2]);
    } else {
      setActiveIndexSections([0]);
    }
  }, [disasterStage]);

  return (
    <div
      className={` mb-0 flex w-[1628px] max-w-full flex-col items-start justify-start bg-white text-left leading-[normal] tracking-[normal] text-heading-title-color ${className}`}
    >
      <div className="absolute right-6 top-[155px]">
        {/* <button
          className=" text-base flex items-center gap-1 bg-white text-[#003460] font-medium hover:cursor-pointer"
          onClick={() => {
            setShowGuidePopup(!showGuidePopup);
          }}
        >
          <FaInfoCircle className="text-base text-[#003460]" size={18} />
          Show Guide
        </button> */}
      </div>
      <div className="flex h-[calc(100vh-190px)] w-full flex-col">
        <div className="mb-2 flex-1 overflow-y-auto">
          <DisasterJournalList
            disasterJournals={disasterJournals}
            setDisasterJournals={setDisasterJournals}
          />
        </div>
        {/* <div className="journal-textarea-cont mt-auto flex w-full">
          <div className="w-full">
            <textarea
              placeholder="Write your journal entry here."
              className="font-Inter h-[100px] w-full resize-none p-2 outline-none"
              onKeyDown={handleSaveJournal}
            />
          </div>
        </div> */}
      </div>
      {showGuidePopup && (
        <GuidePopup onClose={() => setShowGuidePopup(false)} />
      )}
    </div>
  );
};

const DisasterJournalList = ({ disasterJournals, setDisasterJournals }) => {
  const [editingId, setEditingId] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [editedIsPublic, setEditedIsPublic] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [journalToDelete, setJournalToDelete] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "";
    const date = timestamp.toDate();
    return `${date.toLocaleTimeString()}`;
  };

  const formatDateHeader = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "";
    const date = timestamp.toDate();
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const groupJournalsByDate = (journals) => {
    const groups = {};
    journals.forEach((journal) => {
      if (!journal.timestamp) return;
      const dateStr = formatDateHeader(journal.timestamp);
      if (!groups[dateStr]) {
        groups[dateStr] = [];
      }
      groups[dateStr].push(journal);
    });
    return groups;
  };

  const groupedJournals = groupJournalsByDate(
    [...disasterJournals].sort((a, b) => {
      if (!a.timestamp || !b.timestamp) return 0;
      return b.timestamp.seconds - a.timestamp.seconds;
    })
  );

  const handleEditClick = (entry) => {
    setEditingId(entry.id);
    setEditedDescription(entry.description);
    setEditedIsPublic(entry.is_public !== false); // Default to true if undefined
    setActiveMenu(null); // Close the menu
  };

  const handleSaveEdit = async (entry) => {
    try {
      const docRef = doc(db, "disaster-journals", entry.id);
      await updateDoc(docRef, {
        description: editedDescription,
        is_public: editedIsPublic,
      });

      setDisasterJournals((prevJournals) =>
        prevJournals.map((journal) =>
          journal.id === entry.id
            ? {
                ...journal,
                description: editedDescription,
                is_public: editedIsPublic,
              }
            : journal
        )
      );

      setEditingId(null);
    } catch (error) {
      console.error("Error updating journal:", error);
    }
  };

  const handleToggleVisibility = async (entry) => {
    try {
      const newIsPublic = !(entry.is_public !== false); // Toggle the current value
      const docRef = doc(db, "disaster-journals", entry.id);
      await updateDoc(docRef, {
        is_public: newIsPublic,
      });

      setDisasterJournals((prevJournals) =>
        prevJournals.map((journal) =>
          journal.id === entry.id
            ? { ...journal, is_public: newIsPublic }
            : journal
        )
      );
      setActiveMenu(null); // Close the menu
    } catch (error) {
      console.error("Error updating journal visibility:", error);
    }
  };

  const handleDeleteClick = (entry) => {
    setJournalToDelete(entry);
    setShowDeleteDialog(true);
    setActiveMenu(null); // Close the menu
  };

  const handleConfirmDelete = async () => {
    try {
      const docRef = doc(db, "disaster-journals", journalToDelete.id);
      await updateDoc(docRef, {
        is_deleted: true,
      });

      setDisasterJournals((prevJournals) =>
        prevJournals.filter((journal) => journal.id !== journalToDelete.id)
      );

      setShowDeleteDialog(false);
      setJournalToDelete(null);
    } catch (error) {
      console.error("Error deleting journal:", error);
    }
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeMenu && !event.target.closest(".menu-container")) {
        setActiveMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeMenu]);

  const toggleMenu = (entryId, e) => {
    e.stopPropagation();
    setActiveMenu(activeMenu === entryId ? null : entryId);
  };

  return (
    <div className="disaster-journals">
      <HeaderTitle id="journals" title="Journals"/>
      {Object.entries(groupedJournals).map(([dateStr, journals]) => (
        <div key={dateStr}>
          <div className="mb-2 mt-4 text-base font-semibold text-[#003460]">
            {dateStr}
          </div>
          {journals.map((entry) => (
            <div key={entry.id} className="relative mb-4">
              <div
                className={`inline-block w-full gap-[12px] rounded bg-[#F5F5F5] p-4 ${
                  editingId === entry.id ? "w-full" : "min-w-[200px]"
                }`}
              >
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <span className="text-xs text-gray-500">
                      {formatTimestamp(entry.timestamp)}
                    </span>
                    <span
                      className={`ml-2 rounded-full bg-red-100 px-2 py-0.5 text-xs text-red-600`}
                    >
                      {entry.is_public !== false ? (
                        <>
                          <img
                            src={journalpublic}
                            alt="journalpublic"
                            className="mr-1 inline h-3 w-4"
                          />{" "}
                          Public
                        </>
                      ) : (
                        <>
                          <img
                            src={journalprivate}
                            alt="journalprivate"
                            className="mr-1 inline h-3 w-3"
                          />{" "}
                          Private
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className="mb-2 text-left">
                  {editingId === entry.id ? (
                    <div className="mt-2 flex flex-col text-base">
                      <textarea
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                        className="mb-2 w-full rounded p-2 text-base"
                        rows="3"
                      />
                      -T
                      <div className="mt-auto flex justify-end">
                        <button
                          onClick={() => handleSaveEdit(entry)}
                          className="cm-btn-default mr-2 px-3 py-2 font-medium"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setEditingId(null)}
                          className="cm-btn-cancel px-2 py-2"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p className="mr-3 mt-1 whitespace-pre-wrap text-base">
                      {entry.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}

      {showDeleteDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-4">
            <p>Are you sure you want to delete this journal entry?</p>
            <div className="mt-4 flex justify-end gap-2">
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-600 px-4 py-2 text-white"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setShowDeleteDialog(false);
                  setJournalToDelete(null);
                }}
                className="rounded bg-gray-300 px-4 py-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseManagementJournals;
