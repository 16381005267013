import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  CommandColumn,
  Inject,
  ColumnMenu,
  query,
  where,
} from "@syncfusion/ej2-react-grids";

import { USStates } from "./../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "./../style.css";

const ContactsDisaster = ({
  className = "",
  selectedDisasters,
  activeDisasters,
  caseStatus
}) => {
  const [disasterContacts, setDisasterContacts] = useState([]);
  const gridRef = useRef(null);
  const [userId, setUserId] = useState(null);

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };
  const selectedUserId = sessionStorage.getItem("userId");

  const fetchContacts = async () => {
    try {
      if (!selectedDisasters[0]) {
        setDisasterContacts([]); // Clear contacts if no disaster selected
        return;
      }

      const querySnapshot = await getDocs(collection(db, "disaster-contacts"));
      const contactsData = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (contact) =>
            !contact.is_deleted &&
            contact.user_id === userId &&
            contact.disasterId === selectedDisasters[0] // Only get contacts for selected disaster
        );
      setDisasterContacts(contactsData);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && selectedUserId) {
        setUserId(selectedUserId);
        fetchContacts();
      } else {
        setUserId(null);
        setDisasterContacts([]);
      }
    });

    return () => unsubscribe();
  }, [selectedDisasters]);

  useEffect(() => {
    if (userId && selectedDisasters[0]) {
      // Only fetch if both userId and disaster are available
      fetchContacts();
    } else {
      setDisasterContacts([]); // Clear contacts if either is missing
    }
  }, [userId, selectedDisasters]);

  const handleAddContactsDisaster = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const generateContactID = () => {
    return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      const data = args.data;

      if (
        !data.name ||
        !data.email ||
        !data.phone ||
        !data.description ||
        !data.department ||
        !selectedDisasters[0]
      ) {
        args.cancel = true;
        alert("Please fill in all fields before saving.");
        return;
      }

      data.contactID = data.contactID || generateContactID();
      data.last_updated = serverTimestamp();
      data.user_id = userId;
      data.is_deleted = false;
      data.disasterId = selectedDisasters[0];

      try {
        if (args.action === "add") {
          data.inserted_at = serverTimestamp();
          const docRef = await addDoc(
            collection(db, "disaster-contacts"),
            data
          );
          data.id = docRef.id; // Add the new document ID to the data
        } else if (args.action === "edit") {
          const contactDoc = doc(db, "disaster-contacts", data.id);
          await updateDoc(contactDoc, data);
        }

        // Update the local state
        setDisasterContacts((prevContacts) => {
          const index = prevContacts.findIndex(
            (contact) => contact.id === data.id
          );
          if (index !== -1) {
            // Update existing contact
            return prevContacts.map((contact) =>
              contact.id === data.id ? { ...contact, ...data } : contact
            );
          } else {
            // Add new contact
            return [...prevContacts, data];
          }
        });
      } catch (error) {
        console.error("Error saving document:", error);
      }
    } else if (args.requestType === "delete") {
      args.cancel = true; // Prevent actual deletion
      const data = args.data[0];

      try {
        if (data.id) {
          const contactDoc = doc(db, "disaster-contacts", data.id);
          await updateDoc(contactDoc, { is_deleted: true });
          console.log("Contact marked as deleted:", data.id);

          // Remove the deleted contact from the local state
          setDisasterContacts((prevContacts) =>
            prevContacts.filter((contact) => contact.id !== data.id)
          );
        }
      } catch (error) {
        console.error("Error marking contact as deleted:", error);
        alert("Error marking the contact as deleted in the database.");
      }
    }
  };

  return (
    <div className="relative flex w-full flex-col items-start justify-center leading-[normal] tracking-[normal]">
      <div className="relative mb-2 inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        <p>
          The Contacts listed here are those related to your recovery. Establish
          a clear communication network by providing key contacts for disaster
          response and recovery. Accurate and complete contact information is
          essential for coordinating efforts.
        </p>
      </div>
      <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
            <div className="flex w-[159px] flex-col items-start justify-start">
              <h2 className="mt-2 text-sm font-bold text-blue-500">CONTACTS</h2>
            </div>
            <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[7px]">
              <div className="flex flex-row items-start justify-start gap-2.5">
                {selectedDisasters.length > 0 &&
                  !selectedDisasters.some((id) => {
                    const disaster = activeDisasters.find((d) => d.id === id);
                    return disaster?.status === "closed";
                  }) && caseStatus !== "Closed" && (
                    <>
                      <button
                        type="button"
                        className="btn-default flex cursor-pointer flex-row gap-2 px-[9px] py-[5px] [border:none]"
                        onClick={handleAddContactsDisaster}
                      >
                        {" "}
                        <div className="flex flex-col items-start justify-start px-0 pb-0 pt-px">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.4"
                              d="M14.0672 6.39191H13.2651V5.60774C13.2651 5.27296 12.9966 5 12.6658 5C12.3357 5 12.0665 5.27296 12.0665 5.60774V6.39191H11.2658C10.935 6.39191 10.6665 6.66487 10.6665 6.99965C10.6665 7.33443 10.935 7.6074 11.2658 7.6074H12.0665V8.39226C12.0665 8.72704 12.3357 9 12.6658 9C12.9966 9 13.2651 8.72704 13.2651 8.39226V7.6074H14.0672C14.3973 7.6074 14.6665 7.33443 14.6665 6.99965C14.6665 6.66487 14.3973 6.39191 14.0672 6.39191Z"
                              fill="#FDB841"
                            />
                            <path
                              d="M6.33325 10.0107C3.63607 10.0107 1.33325 10.442 1.33325 12.1648C1.33325 13.887 3.62205 14.3337 6.33325 14.3337C9.02977 14.3337 11.3333 13.9025 11.3333 12.1797C11.3333 10.4568 9.04445 10.0107 6.33325 10.0107Z"
                              fill="#FDB841"
                            />
                            <path
                              opacity="0.4"
                              d="M6.33332 8.3698C8.1697 8.3698 9.64174 6.87879 9.64174 5.01873C9.64174 3.15868 8.1697 1.66699 6.33332 1.66699C4.49694 1.66699 3.0249 3.15868 3.0249 5.01873C3.0249 6.87879 4.49694 8.3698 6.33332 8.3698Z"
                              fill="#FDB841"
                            />
                          </svg>
                        </div>
                        <a className="relative inline-block text-left text-xs font-semibold [text-decoration:none]">
                          Add New
                        </a>
                      </button>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <GridComponent
            dataSource={disasterContacts}
            editSettings={{
              allowEditing: true,
              allowAdding: true,
              allowDeleting: true,
              showDeleteConfirmDialog: true,
              mode: "Dialog",
            }}
            ref={gridRef}
            actionBegin={actionBegin}
            filterSettings={filterSettings}
            allowFiltering={true}
            allowSorting={true}
            sortSettings={sortSettings}
            allowPaging={true}
            pageSettings={pageSettings}
            showColumnMenu={true}
            columnMenuItems={columnMenuItems}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="contactID"
                headerText="Contact ID"
                isPrimaryKey={true}
                isIdentity={true}
                visible={false}
              />
              <ColumnDirective
                field="name"
                headerText="Name"
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field="email"
                headerText="Email"
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field="phone"
                headerText="Phone"
                validationRules={{ required: true }}
              />

              <ColumnDirective
                field="department"
                headerText="Department"
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field="description"
                headerText="Description"
                validationRules={{ required: true }}
              />
              <ColumnDirective
                headerText="Commands"
                visible={
                  selectedDisasters.length > 0 &&
                  !selectedDisasters.some((id) => {
                    const disaster = activeDisasters.find((d) => d.id === id);
                    return disaster?.status === "closed";
                  }) && 
                  caseStatus !== "Closed"
                }
                commands={[
                  {
                    type: "Edit",
                    buttonOption: {
                      content: '<i class="fas fa-edit"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                  {
                    type: "Delete",
                    buttonOption: {
                      content: '<i class="fas fa-trash-alt"></i>',
                      cssClass: "e-outline custom-button",
                    },
                  },
                ]}
              />
            </ColumnsDirective>
            <Inject
              services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default ContactsDisaster;
