import React from "react";

const HeaderTitle = ({ title, id, rightComponent, isRed = false }) => {
  return (
    <>
      <header
        id={id}
        className={`mb-2 flex flex-row items-center justify-between self-stretch rounded p-2 ${
          isRed ? "bg-dark-burgundy" : "bg-dark-midnight-blue"
        }`}
      >
        <div>
          <h2 className="text-xl font-bold text-white">{title}</h2>
        </div>
        {rightComponent && rightComponent}
      </header>
    </>
  );
};

export default HeaderTitle;
