import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { FaEdit, FaTrash, FaEye, FaInfoCircle, FaTimes } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { USStates } from "./../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import "./../style.css";
import verticalDot from "./../../assets/vertical-dot.svg";
import journalpublic from "../../assets/disaster-icons/journal-public.svg";
import journalprivate from "../../assets/disaster-icons/journal-private.svg";
import SendButton from "../../components/SendButton";
import HeaderTitle from "../../components/HeaderTitle";

const Journals = ({
  className = "",
  disasterStage,
  selectedDisaster,
  selectedDisasters,
  activeDisasters,
  caseStatus,
}) => {
  console.log("Disaster Stage in Journals:", disasterStage);

  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [data, setData] = useState([]);
  const [disasterJournals, setDisasterJournals] = useState([]);
  const gridRef = useRef(null);
  const [popupMessage, setPopupMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const now = new Date();
  const [editing, setEditing] = useState({
    allowEditing: false,
    allowAdding: true,
    allowDeleting: false,
    mode: "Dialog",
    dialog: { cssClass: "custom-dialog" },
    allowEditOnDblClick: false,
  });
  const [showGuidePopup, setShowGuidePopup] = useState(false);

  const [openSections, setOpenSections] = useState({
    phase1: true,
    phase3: true,
  });
  const selectedUserId = sessionStorage.getItem("userId");

  const toggleSection = (section, event) => {
    event.stopPropagation();
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const GuidePopup = ({ onClose }) => {
    const popupRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          popupRef.current &&
          (popupRef.current.contains(event.target) ||
            event.target.closest("[data-ignore-close]"))
        ) {
          return; // Ignore clicks inside the popup or on toggle button
        }

        popupRef.current.classList.add("animate-popup-out");
        setTimeout(onClose, 500);
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onClose]);

    return (
      <div
        ref={popupRef}
        className="absolute right-0 top-[50px] z-[9999] h-[calc(100vh-50px)] w-[500px] border border-gray-200 bg-white p-4 shadow-2xl"
      >
        <div className="mb-2 flex items-center justify-between border-b pb-2">
          <h3 className="text-lg font-bold">Journal Writing Guide</h3>
          <button
            className="hover:text-gray-700 text-26xl bg-white font-bold text-red-600"
            onClick={onClose}
          >
            <FaTimes />
          </button>
        </div>

        <div className="font-Inter mb-4 flex max-h-[calc(100vh-120px)] flex-col overflow-y-auto">
          <div className="font-Inter flex w-full items-center justify-between gap-1 bg-[#349bf1] px-3 text-white">
            <p className="font-Inter mb-2 mt-2 text-xl font-bold">PHASE 1</p>
            <svg
              className={`h-6 w-6 transform cursor-pointer transition-transform ${
                openSections.phase1 ? "rotate-90" : "rotate-180"
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
              onClick={(event) => toggleSection("phase1", event)}
              data-ignore-close="true"
            >
              <path d="M6 6L14 10L6 14V6Z" />
            </svg>
          </div>
          {openSections.phase1 && (
            <>
              <div className="mt-2 flex px-2">
                <img
                  src={
                    require("./../../assets/journals-icons/before-disaster.svg")
                      .default
                  }
                  alt="Before Disaster"
                  width="20"
                  height="20"
                />
                <p className="font-base px-1 text-[#188AEB]">
                  Before the Disaster
                </p>
              </div>
              <ol className="font-sm mt-2 list-decimal pl-[30px]">
                <li className="mb-1">
                  What was your daily life like before the disaster?
                </li>
                <li className="mb-1">
                  Did you have any emergency plans or preparations in place? If
                  so, what were they?
                </li>
                <li className="mb-1">
                  What concerns, if any, did you have about disasters before
                  this event?
                </li>
              </ol>
              <div className="mt-2 flex px-2">
                <img
                  src={
                    require("./../../assets/journals-icons/during-disaster.svg")
                      .default
                  }
                  alt="Before Disaster"
                  width="20"
                  height="20"
                />
                <p className="font-base px-1 text-[#188AEB]">
                  During the Disaster
                </p>
              </div>
              <ol className="font-sm mt-2 list-decimal pl-[30px]">
                <li className="mb-1">
                  How did you first realize the disaster was happening?
                </li>
                <li className="mb-1">
                  Where were you when the disaster occurred, and what were your
                  first actions?
                </li>
                <li className="mb-1">
                  What immediate steps did you take to protect yourself and your
                  loved ones?
                </li>
                <li className="mb-1">
                  What emotions did you experience during the disaster?
                </li>
                <li className="mb-1">
                  Were you able to communicate with anyone for help or
                  information? If so, how?
                </li>
              </ol>
              <div className="mt-2 flex px-2">
                <img
                  src={
                    require("./../../assets/journals-icons/immediate-aftermath.svg")
                      .default
                  }
                  alt="Before Disaster"
                  width="20"
                  height="20"
                />
                <p className="font-base px-1 text-[#188AEB]">
                  Immediate Aftermath
                </p>
              </div>
              <ol className="font-sm mt-2 list-decimal pl-[30px]">
                <li className="mb-1">
                  What was the first thing you noticed after the disaster had
                  passed?
                </li>
                <li className="mb-1">
                  What were the biggest challenges you faced in the first few
                  hours or days?
                </li>
                <li className="mb-1">
                  Did you receive any assistance? If so, from whom (family,
                  neighbors, first responders, organizations, etc.)?
                </li>
                <li className="mb-1">
                  What personal items, belongings, or spaces were lost or
                  damaged?
                </li>
                <li className="mb-1">
                  How did the disaster affect your ability to meet basic needs
                  (food, water, shelter, medical care)?
                </li>
                <li className="mb-1">
                  What has been the biggest impact to you personally?
                </li>
              </ol>
            </>
          )}
          <div className="font-Inter mt-3 flex w-full items-center justify-between gap-1 bg-[#349bf1] px-3 text-white">
            <p className="font-Inter mb-2 mt-2 text-xl font-bold">PHASE 2</p>
            <svg
              className={`h-6 w-6 transform cursor-pointer transition-transform ${
                openSections.phase2 ? "rotate-90" : "rotate-180"
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
              onClick={(event) => toggleSection("phase2", event)}
              data-ignore-close="true"
            >
              <path d="M6 6L14 10L6 14V6Z" />
            </svg>
          </div>
          {openSections.phase2 && (
            <>
              <div className="mt-2 flex px-2">
                <img
                  src={
                    require("./../../assets/journals-icons/ongoing-impact-recovery.svg")
                      .default
                  }
                  alt="Before Disaster"
                  width="20"
                  height="20"
                />
                <p className="font-base px-1 text-[#188AEB]">
                  Ongoing Impact & Recovery
                </p>
              </div>
              <ol className="font-sm mt-2 list-decimal pl-[30px]">
                <li className="mb-1">
                  How has the disaster affected your daily life (work, school,
                  home, relationships, etc.)?
                </li>
                <li className="mb-1">
                  What are the biggest challenges you continue to face?
                </li>
                <li className="mb-1">
                  Have you been able to rebuild or recover in any way? If so,
                  how?
                </li>
                <li className="mb-1">
                  What types of support have you received since the disaster,
                  and from whom?
                </li>
                <li className="mb-1">
                  How has your mental and emotional health been affected by this
                  experience?
                </li>
              </ol>
            </>
          )}
          <div className="font-Inter mt-3 flex w-full items-center justify-between gap-1 bg-[#349bf1] px-3 text-white">
            <p className="font-Inter mb-2 mt-2 text-xl font-bold">PHASE 3</p>
            <svg
              className={`h-6 w-6 transform cursor-pointer transition-transform ${
                openSections.phase3 ? "rotate-90" : "rotate-180"
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
              onClick={(event) => toggleSection("phase3", event)}
              data-ignore-close="true"
            >
              <path d="M6 6L14 10L6 14V6Z" />
            </svg>
          </div>
          {openSections.phase3 && (
            <>
              <div className="mt-2 flex px-2">
                <img
                  src={
                    require("./../../assets/journals-icons/reflections-looking-forward.svg")
                      .default
                  }
                  alt="Before Disaster"
                  width="20"
                  height="20"
                />
                <p className="font-base px-1 text-[#188AEB]">
                  Reflections & Looking Forward
                </p>
              </div>
              <ol className="font-sm mt-2 list-decimal pl-[30px]">
                <li className="mb-1">
                  What lessons have you learned from this experience?
                </li>
                <li className="mb-1">
                  Has your perspective on disasters, preparedness, or community
                  changed?
                </li>
                <li className="mb-1">
                  What advice would you give to someone who might experience a
                  similar disaster?
                </li>
                <li className="mb-1">
                  What do you need most to fully recover?
                </li>
                <li className="mb-4">What are your hopes for the future?</li>
              </ol>
            </>
          )}
        </div>
      </div>
    );
  };

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const fetchJournals = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "disaster-journals"));
      const journalsData = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (journal) =>
            !journal.is_deleted &&
            journal.userId === userId &&
            journal.disasterId === selectedDisaster.id
        );

      setDisasterJournals(journalsData);

      setEditing((prevEditing) => ({
        ...prevEditing,
        allowAdding: journalsData.length > 0,
      }));
    } catch (error) {
      console.error("Error fetching Journals:", error);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);
        fetchUserRole(selectedUserId);
      } else {
        setData(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchJournals();
    }
  }, [userId]);

  useEffect(() => {
    if (selectedDisaster) {
      fetchJournals();
    }
  }, [selectedDisaster]);

  const fetchUserRole = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", selectedUserId));
      if (userDoc.exists()) {
        const role = userDoc.data().user_role;
        setUserRole(role);

        if (role === "citizen" || role === "partner") {
          setEditing((prevEditing) => ({
            ...prevEditing,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: false,
          }));
        } else if (role === "center") {
          setEditing((prevEditing) => ({
            ...prevEditing,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const formattedTime = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = now.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
  });

  const generateJournalId = () => {
    return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleAddJournal = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const getEditorTypeColor = (editorType) => {
    switch (editorType) {
      case "center":
        return "green-500";
      case "partner":
        return "yellow-500";
      case "citizen":
        return "blue-500";
      default:
        return "gray-500";
    }
  };

  const getEditorTypeLetter = (editorType) => {
    switch (editorType) {
      case "center":
        return "C";
      case "partner":
        return "P";
      case "citizen":
        return "S";
      default:
        return "?";
    }
  };

  const getEditorTypeLabel = (editorType) => {
    switch (editorType) {
      case "center":
        return "Center";
      case "partner":
        return "Partner";
      case "citizen":
        return "Survivor";
      default:
        return "Unknown";
    }
  };

  const privacyOptions = [
    { value: "Public" },
    { value: "Private" },
    { value: "Restricted" },
  ];

  const showPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);
  };

  const actionBegin = async (args) => {
    if (args.requestType === "add") {
      const currentDate = new Date();
      const formattedDate = `${
        currentDate.getMonth() + 1
      }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
      const formattedTime = currentDate.toTimeString().split(" ")[0];

      args.data.journalDate = formattedDate;
      args.data.journalTime = formattedTime;
    }

    if (args.requestType === "save") {
      const data = args.data;

      const disaster = data.disaster || "";
      const description = data.description || "";
      const date = data.journalDate || formattedDate;
      const time = data.journalTime || formattedTime;

      if (!disaster.trim() || !description.trim()) {
        args.cancel = true;
        alert("Please fill in disaster and description before saving.");
        return;
      }

      data.journalId = generateJournalId();
      data.userId = userId;
      data.userRole = userRole;
      data.editorId = userId;
      data.editorType = userRole;
      data.journalDate = date;
      data.journalTime = time;

      try {
        if (args.action === "add") {
          data.inserted_at = serverTimestamp();
          const docRef = await addDoc(
            collection(db, "disaster-journals"),
            data
          );
          data.id = docRef.id;
        } else if (args.action === "edit") {
          if (userRole === "citizen" || userRole === "partner") {
            if (
              args.rowData.editor_ID !== userId ||
              args.rowData.editor_type !== userRole
            ) {
              args.cancel = true;
              showPopup("You don't have permission to edit this record.");
              return;
            }
          }
          args.rowData = { ...args.rowData };
        }

        setDisasterJournals((prevJournals) => {
          const index = prevJournals.findIndex(
            (journal) => journal.id === data.id
          );
          if (index !== -1) {
            return prevJournals.map((journal) =>
              journal.id === data.id ? { ...journal, ...data } : journal
            );
          } else {
            return [...prevJournals, data];
          }
        });
      } catch (error) {
        console.error("Error saving document:", error);
      }
    } else if (args.requestType === "delete") {
      try {
        const deleteData = args.data[0];
        const docRef = doc(db, "disaster-journals", deleteData.id);

        const confirmDelete = window.confirm(
          `Are you sure you want to delete the journal entry for disaster "${deleteData.disaster}"?`
        );

        if (confirmDelete) {
          await updateDoc(docRef, { is_deleted: true });

          setDisasterJournals((prevJournals) =>
            prevJournals.filter((journal) => journal.id !== deleteData.id)
          );

          alert("Journal entry successfully deleted.");
        } else {
          args.cancel = true;
        }
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("An error occurred while deleting the journal entry.");
      }
    }
  };

  const handleSaveJournal = async (e) => {
    if (e.key === "Enter") {
      const description = e.target.value.trim();
      if (!description) return;

      const now = new Date();

      const journalEntry = {
        description,
        journalId: generateJournalId(),
        userId,
        userRole,
        disasterId: selectedDisaster.id,
        editorId: userId,
        editorType: userRole,
        timestamp: serverTimestamp(),
        inserted_at: serverTimestamp(),
        is_public: false,
      };

      try {
        const docRef = await addDoc(
          collection(db, "disaster-journals"),
          journalEntry
        );
        setDisasterJournals((prevJournals) => [
          ...prevJournals,
          { ...journalEntry, id: docRef.id },
        ]);
        fetchJournals();
        e.target.value = "";
      } catch (error) {
        console.error("Error saving journal entry:", error);
      }
    }
  };

  const dateTemplate = (props) => {
    if (!props.timestamp) return null;

    const dateObj = props.timestamp.toDate();
    const day = dateObj.getDate();
    const month = dateObj
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    const year = dateObj.getFullYear();

    return (
      <div className="flex">
        <div className="flex items-center space-x-1">
          <span
            className={`h-6 w-6 rounded-full bg-${getEditorTypeColor(
              props.editorType
            )} flex items-center justify-center text-xs font-bold text-white`}
            title={`Editor Type: ${getEditorTypeLabel(props.editorType)}`}
          >
            {getEditorTypeLetter(props.editorType)}
          </span>
        </div>
        <div className="ml-2 flex w-[35px] flex-col items-start justify-start">
          <a className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]">
            {day}
          </a>
          <b className="relative flex w-[34px] items-center text-xs">{month}</b>
          <a
            className="mq450:text-lgi relative self-stretch font-bold text-[inherit] [text-decoration:none]"
            style={{ fontSize: "10px" }}
          >
            {year}
          </a>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (showGuidePopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showGuidePopup]);

  useEffect(() => {
    // Set initial open sections based on disasterStage
    if (disasterStage === "Relief" || disasterStage === "Response") {
      setOpenSections({
        phase1: true,
        phase2: true,
        phase3: false,
      });
    } else if (disasterStage === "Recovery") {
      setOpenSections({
        phase1: false,
        phase2: false,
        phase3: true,
      });
    } else {
      setOpenSections({
        phase1: true,
        phase2: false,
        phase3: false,
      });
    }
  }, [disasterStage]);

  return (
    <div
      className={`mb-0 flex w-[1628px] max-w-full flex-col items-start justify-start bg-white text-left leading-[normal] tracking-[normal] text-heading-title-color ${className}`}
    >
      <div className="relative mb-2 inline-block max-w-full font-poppins text-[15px] leading-[21px] text-darkslategray-200">
        <p>
          The Disaster Journal provides a space for you to share your personal
          experiences and reflections throughout the recovery process. Your
          story is a vital part of understanding the full impact of the disaster
          and will help shape future recovery efforts. Please take the time to
          document your journey and insights.
        </p>
      </div>
      <div className="flex-end mb-2 flex w-full justify-end">
            <button
              className="btn-show-guide flex items-center gap-1 hover:cursor-pointer"
              onClick={() => {
                setShowGuidePopup(!showGuidePopup);
                console.log("Guide Popup State:", !showGuidePopup);
              }}
            >
              <FaInfoCircle className="text-base text-[#003460]" />
              Show Guide
            </button>
      </div>
      <HeaderTitle id="citizenjournal" title="Journal" />
      <div className="flex h-[calc(100vh-325px)] w-full flex-col">
        {selectedDisaster ? (
          <>
            <div className="mb-2 flex-1 overflow-y-auto">
              <DisasterJournalList
                disasterJournals={disasterJournals}
                setDisasterJournals={setDisasterJournals}
                selectedDisasters={selectedDisasters}
                activeDisasters={activeDisasters}
                caseStatus={caseStatus}
              />
            </div>
            {selectedDisasters.length > 0 &&
              !selectedDisasters.some((id) => {
                const disaster = activeDisasters.find((d) => d.id === id);
                return disaster?.status === "closed";
              }) &&
              caseStatus !== "Closed" && (
                <div className="journal-textarea-cont mt-auto flex w-full">
                  <div className="flex w-full">
                    <div className="flex-grow">
                      <textarea
                        placeholder="Recording your experiences can be a powerful way to document what you've been through. Write it here."
                        className="font-Inter h-[100px] w-full resize-none p-2 outline-none"
                        onKeyDown={handleSaveJournal}
                      />
                    </div>
                    <SendButton onClick={handleSaveJournal} />
                  </div>
                </div>
              )}
          </>
        ) : (
          <div className="flex h-full items-center justify-center text-gray-500">
            Please select a disaster to view and add journal entries.
          </div>
        )}
      </div>
      {showGuidePopup && (
        <GuidePopup onClose={() => setShowGuidePopup(false)} />
      )}
    </div>
  );
};

const DisasterJournalList = ({
  disasterJournals,
  setDisasterJournals,
  selectedDisasters,
  activeDisasters,
  caseStatus,
}) => {
  const [editingId, setEditingId] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [journalToDelete, setJournalToDelete] = useState(null);
  const textareaRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [editedIsPublic, setEditedIsPublic] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeMenu && !event.target.closest(".menu-container")) {
        setActiveMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeMenu]);

  const toggleMenu = (entryId, e) => {
    e.stopPropagation();
    setActiveMenu(activeMenu === entryId ? null : entryId);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "";
    const date = timestamp.toDate();
    return `${date.toLocaleTimeString()}`;
  };

  const formatDateHeader = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "";
    const date = timestamp.toDate();
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const groupJournalsByDate = (journals) => {
    const groups = {};
    journals.forEach((journal) => {
      if (!journal.timestamp) return;
      const dateStr = formatDateHeader(journal.timestamp);
      if (!groups[dateStr]) {
        groups[dateStr] = [];
      }
      groups[dateStr].push(journal);
    });
    return groups;
  };

  const groupedJournals = groupJournalsByDate(
    [...disasterJournals].sort((a, b) => {
      if (!a.timestamp || !b.timestamp) return 0;
      return b.timestamp.seconds - a.timestamp.seconds;
    })
  );

  const handleEditClick = (entry) => {
    setEditingId(entry.id);
    setEditedDescription(entry.description);
  };

  const handleSaveEdit = async (entry) => {
    try {
      const docRef = doc(db, "disaster-journals", entry.id);
      await updateDoc(docRef, {
        description: editedDescription,
      });

      setDisasterJournals((prevJournals) =>
        prevJournals.map((journal) =>
          journal.id === entry.id
            ? { ...journal, description: editedDescription }
            : journal
        )
      );

      setEditingId(null);
    } catch (error) {
      console.error("Error updating journal:", error);
      alert("Failed to update journal entry");
    }
  };

  const handleDeleteClick = (entry) => {
    setJournalToDelete(entry);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const docRef = doc(db, "disaster-journals", journalToDelete.id);
      await updateDoc(docRef, {
        is_deleted: true,
      });

      setDisasterJournals((prevJournals) =>
        prevJournals.filter((journal) => journal.id !== journalToDelete.id)
      );

      setShowDeleteDialog(false);
      setJournalToDelete(null);
    } catch (error) {
      console.error("Error deleting journal:", error);
      alert("Failed to delete journal entry");
    }
  };

  const handleToggleVisibility = async (entry) => {
    try {
      const newIsPublic = !(entry.is_public !== false);
      const docRef = doc(db, "disaster-journals", entry.id);
      await updateDoc(docRef, {
        is_public: newIsPublic,
      });

      setDisasterJournals((prevJournals) =>
        prevJournals.map((journal) =>
          journal.id === entry.id
            ? { ...journal, is_public: newIsPublic }
            : journal
        )
      );
      setActiveMenu(null);
    } catch (error) {
      console.error("Error updating journal visibility:", error);
      alert("Failed to update journal visibility");
    }
  };

  return (
    <div className="disaster-journals">
      {Object.entries(groupedJournals).map(([dateStr, journals]) => (
        <div key={dateStr}>
          <div className="mb-2 text-base font-semibold text-[#003460]">
            {dateStr}
          </div>
          {journals.map((entry) => (
            <div key={entry.id} className="mb-4">
              <div className={`inline-block w-full rounded bg-[#F5F5F5] p-4`}>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <span className="text-xs text-gray-500">
                      {formatTimestamp(entry.timestamp)}
                    </span>
                    <span
                      className={`ml-2 rounded-full bg-red-100 px-2 py-0.5 text-xs text-red-600`}
                    >
                      {entry.is_public !== false ? (
                        <>
                          <img
                            src={journalpublic}
                            alt="journalpublic"
                            className="mr-1 inline h-3 w-4"
                          />{" "}
                          Public
                        </>
                      ) : (
                        <>
                          <img
                            src={journalprivate}
                            alt="journalprivate"
                            className="mr-1 inline h-3 w-3"
                          />{" "}
                          Private
                        </>
                      )}
                    </span>
                  </div>

                  {!editingId &&
                    selectedDisasters.length > 0 &&
                    !selectedDisasters.some((id) => {
                      const disaster = activeDisasters.find((d) => d.id === id);
                      return disaster?.status === "closed";
                    }) &&
                    caseStatus !== "Closed" && (
                      <div className="menu-container relative">
                        <div
                          onClick={(e) => toggleMenu(entry.id, e)}
                          className="hover:text-gray-700 z-100 text-gray-500 hover:cursor-pointer"
                        >
                          <img
                            src={verticalDot}
                            alt="verticalDot"
                            className="h-5 w-5"
                          />
                        </div>
                        {activeMenu === entry.id && (
                          <div className="absolute right-0 top-6 z-10 w-40 rounded-md bg-white shadow-lg">
                            <button
                              onClick={() => handleEditClick(entry)}
                              className="hover:bg-gray-100 flex w-full items-center px-4 py-2 text-left text-sm hover:cursor-pointer"
                            >
                              <svg
                                className="mr-2 h-4 w-4 text-blue-600"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-10 10a2 2 0 01-1.414.586H3a1 1 0 01-1-1v-2a2 2 0 01.586-1.414l10-10z" />
                              </svg>
                              Edit
                            </button>
                            <button
                              onClick={() => handleToggleVisibility(entry)}
                              className="hover:bg-gray-100 flex w-full items-center px-4 py-2 text-left text-sm hover:cursor-pointer"
                            >
                              {entry.is_public !== false ? (
                                <>
                                  <img
                                    src={journalprivate}
                                    alt="make private"
                                    className="mr-2 h-4 w-4"
                                  />
                                  Make Private
                                </>
                              ) : (
                                <>
                                  <img
                                    src={journalpublic}
                                    alt="make public"
                                    className="mr-2 h-4 w-4"
                                  />
                                  Make Public
                                </>
                              )}
                            </button>
                            <button
                              onClick={() => handleDeleteClick(entry)}
                              className="hover:bg-gray-100 flex w-full items-center px-4 py-2 text-left text-sm hover:cursor-pointer"
                            >
                              <svg
                                className="mr-2 h-4 w-4 text-red-600"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                </div>
                <div className="mb-2 text-left">
                  {editingId === entry.id ? (
                    <div className="mt-2 flex w-full flex-col">
                      <textarea
                        ref={textareaRef}
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                        className="font-Inter w-full rounded p-2 text-[12px]"
                        rows="3"
                      />
                      <div className="mt-2 flex justify-end">
                        <button
                          onClick={() => handleSaveEdit(entry)}
                          className="ml-2 text-green-600"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setEditingId(null)}
                          className="text-gray-600 ml-2"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p className="mr-3 mt-1 whitespace-pre-wrap">
                      {entry.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
      {showDeleteDialog && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-4">
            <p>Are you sure you want to delete this journal entry?</p>
            <div className="mt-4 flex justify-end gap-2">
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-600 px-4 py-2 text-white"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setShowDeleteDialog(false);
                  setJournalToDelete(null);
                }}
                className="rounded bg-gray-300 px-4 py-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Journals;
