import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { getAuth } from "firebase/auth";
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import HeaderTitle from "../../../components/HeaderTitle";

const CaseManager = () => {
  const [userData, setUserData] = useState(null);
  const [employees, setEmployees] = useState([]);

  const selectedUserId = sessionStorage.getItem("userId");

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const filterSettings = {
    type: "CheckBox",
  };

  const sortSettings = {
    allowSorting: true,
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        if (!userData?.center_id) {
          console.log("No center ID available");
          return;
        }
    
        const employeesRef = collection(db, "centers", userData.center_id, "employees");
        const employeesSnapshot = await getDocs(employeesRef);
        
        const caseManagementRef = collection(db, "case_management");
        const caseManagementSnapshot = await getDocs(caseManagementRef);
        
        const caseCountMap = {};
        caseManagementSnapshot.docs.forEach(doc => {
          const caseData = doc.data();
          const managerId = caseData.assigned_to_case_manager_uid;
          if (managerId) {
            if (!caseCountMap[managerId]) {
              caseCountMap[managerId] = { open: 0, closed: 0 };
            }
            if (caseData.status === 'closed') {
              caseCountMap[managerId].closed += 1;
            } else {
              caseCountMap[managerId].open += 1;
            }
          }
        });
    
        const employeesData = employeesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          fullName: `${doc.data().first_name} ${doc.data().last_name}`,
          openCases: caseCountMap[doc.id]?.open || 0,
          closedCases: caseCountMap[doc.id]?.closed || 0,
        }));
    
        setEmployees(
          employeesData?.filter(({ role }) => 
            role === "Case Manager" || role === "Case Supervisor"
          ) || []
        );
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [userData?.center_id]);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };

    fetchUserData();
  }, []);

  const casesTemplate = ({ openCases = 0, closedCases = 0 }) => {
    return (
      <div className="flex flex-col space-y-2">
        {openCases > 0 && (
          <div className="bg-[#0A8525] text-white px-4 py-2 rounded-full text-center max-w-fit mx-auto">
            Open ({openCases}) 
          </div>
        )}
        {closedCases > 0 && (
          <div className="bg-red-600 text-white px-4 py-2 rounded-full text-center max-w-fit mx-auto">
            Closed ({closedCases}) 
          </div>
        )}
        {openCases === 0 && closedCases === 0 && (
          <div className="bg-gray-400 text-white px-4 py-2 rounded-full text-center max-w-fit mx-auto">
            No Cases
          </div>
        )}
      </div>
    );
  };

  const firstNameTemplate = (props) => {
    return (
      <div style={{ 
        fontWeight: props.role === "Case Supervisor" ? "bold" : "normal", 
        color: "#333", 
        padding: "4px", 
        display: "flex", 
        alignItems: "center" 
      }}>
        {props.role === "Case Supervisor" && (
          <span 
            style={{ 
              backgroundColor: "#4169E1", 
              color: "white", 
              width: "24px", 
              height: "24px", 
              display: "flex", 
              justifyContent: "center", 
              alignItems: "center", 
              borderRadius: "50%", 
              marginRight: "8px", 
              fontSize: "12px",
              fontWeight: "bold",
              position: "relative",
              cursor: "pointer"
            }}
            title="Supervisor"
          >
            S
          </span>
        )}
        {props.first_name}
      </div>
    );
  };
  
  

  return (
    <div className="flex-1 p-[20px]">
      <HeaderTitle id="casemanagers" title="Case Managers" />
      <GridComponent
        dataSource={employees}
        filterSettings={filterSettings}
        allowFiltering={false}
        allowSorting={true}
        sortSettings={sortSettings}
        allowPaging={true}
        pageSettings={pageSettings}
        // showColumnMenu={true}
        columnMenuItems={columnMenuItems}
        cssClass="new-grid-color"
      >
        <ColumnsDirective>
          <ColumnDirective field="first_name" headerText="First Name"  template={firstNameTemplate} />
          <ColumnDirective field="last_name" headerText="Last Name" />
          <ColumnDirective field="email" headerText="Email" />
          <ColumnDirective field="cases" headerText="Cases" template={casesTemplate} />
        </ColumnsDirective>
        <Inject
          services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
        />
      </GridComponent>
    </div>
  );
};

export default CaseManager;
