import React, { useState, useEffect, useRef } from "react";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import ContactProfilePopup from "./Communication/ContactProfilePopup";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-richtexteditor/styles/material.css";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  doc,
  addDoc,
  serverTimestamp,
  orderBy,
  onSnapshot,
  Timestamp,
  writeBatch,
  limit,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import CreateGroupChatModal from "../components/CreateGroupChatModal";

const Communication = ({ className = "" }) => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentChatId, setCurrentChatId] = useState("");
  const [chats, setChats] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [isCreateGroupChatModalOpen, setIsCreateGroupChatModalOpen] =
    useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [suggestedContacts, setSuggestedContacts] = useState([]);
  const [loadingSuggested, setLoadingSuggested] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dialogRef = useRef(null); // Create a ref for the dialog
  const selectedUserId = sessionStorage.getItem("userId");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef(null);

  const handleClickOutside = (event) => {
    // Existing dialog check
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      setIsCreateGroupChatModalOpen(false);
    }

    // Add search suggestions check
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSuggestions(false);
      setSuggestedContacts([]);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dialog
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleContactClick = () => {
    setShowProfilePopup(true);
  };

  const handleEditorChange = (args) => {
    setEditorContent(args.value);
  };

  const handleSend = () => {
    if (editorContent.trim() !== "") {
      sendMessage(currentChatId, selectedUserId, editorContent);
      setEditorContent("");
    }
  };

  // Function to strip HTML tags for preview in contacts list
  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "|",
      "Undo",
      "Redo",
    ],
  };

  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
  };

  const ContactAvatar = ({ contact }) => {
    // Check if the contact is a center or partner and set initials accordingly
    const initials =
      contact.user_role === "center" && contact.name_of_hub
        ? contact.name_of_hub
            .split(" ")
            .map((name) => name[0])
            .join("")
            .toUpperCase()
            .slice(0, 1)
        : (contact.user_role === "partner" || contact.userType === "Partner") &&
          contact.name_of_org
        ? contact.name_of_org
            .split(" ")
            .map((name) => name[0])
            .join("")
            .toUpperCase()
            .slice(0, 1)
        : (contact.first_name + " " + contact.last_name)
            .split(" ")
            .map((name) => name[0])
            .join("")
            .toUpperCase()
            .slice(0, 2);

    if (contact.image) {
      return (
        <img
          src={contact.image}
          alt={`${contact.first_name}'s profile`}
          className="h-12 w-12 flex-shrink-0 rounded-full object-cover"
        />
      );
    } else {
      return (
        <div className="chat-user-thumbnail flex h-[44px] w-[44px] flex-shrink-0 items-center justify-center rounded-full">
          {initials}
        </div>
      );
    }
  };

  const HeaderContactAvatar = ({ contact }) => {
    const initials =
      contact.user_role === "center" && contact.name_of_hub
        ? contact.name_of_hub
            .split(" ")
            .map((name) => name[0])
            .join("")
            .toUpperCase()
            .slice(0, 1)
        : (contact.user_role === "partner" || contact.userType === "Partner") &&
          contact.name_of_org
        ? contact.name_of_org
            .split(" ")
            .map((name) => name[0])
            .join("")
            .toUpperCase()
            .slice(0, 1)
        : (contact.first_name + " " + contact.last_name)
            .split(" ")
            .map((name) => name[0])
            .join("")
            .toUpperCase()
            .slice(0, 2);

    if (contact.image) {
      return (
        <img
          src={contact.image}
          alt={`${contact.first_name}'s profile`}
          className="h-12 w-12 flex-shrink-0 rounded-full object-cover"
        />
      );
    } else {
      return (
        <div className="chat-user-thumbnail flex h-[64px] w-[64px] flex-shrink-0 items-center justify-center rounded-full">
          {initials}
        </div>
      );
    }
  };

  useEffect(() => {
    const fetchChats = async () => {
      if (auth && auth.currentUser) {
        setIsLoading(true); // Set loading to true when starting to fetch
        const userId = selectedUserId;
        const chatsRef = collection(db, "chats");
        const q = query(chatsRef, where("users", "array-contains", userId));

        try {
          const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const chatsData = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const contactsWithLastMessage = [];

            for (const chat of chatsData) {
              // Get the last message for this chat
              const messagesRef = collection(db, "chats", chat.id, "messages");
              const messagesQuery = query(
                messagesRef,
                orderBy("timestamp", "desc"),
                limit(1)
              );
              const messageSnapshot = await getDocs(messagesQuery);
              const lastMessage = messageSnapshot.docs[0]?.data() || null; // Get the last message data

              // Extract user IDs from the chat ID
              const userIds = chat.id.split("_"); // Assuming the format is "userId1_userId2"
              const otherUserId = userIds.find((uid) => uid !== userId); // Find the other user ID

              if (otherUserId) {
                // First, try to fetch from the users collection
                const userDocRef = doc(db, "users", otherUserId);
                const userDoc = await getDoc(userDocRef);

                let contactData;

                if (userDoc.exists()) {
                  contactData = {
                    id: userDoc.id,
                    ...userDoc.data(),
                    lastMessage: lastMessage ? lastMessage.message : "", // Store last message
                    lastMessageTime: lastMessage
                      ? lastMessage.timestamp
                      : new Timestamp(0, 0), // Store timestamp
                  };
                } else {
                  // If not found in users, check the partners collection
                  const partnerDocRef = doc(db, "partners", otherUserId);
                  const partnerDoc = await getDoc(partnerDocRef);

                  if (partnerDoc.exists()) {
                    contactData = {
                      id: partnerDoc.id,
                      ...partnerDoc.data(),
                      lastMessage: lastMessage ? lastMessage.message : "", // Store last message
                      lastMessageTime: lastMessage
                        ? lastMessage.timestamp
                        : new Timestamp(0, 0), // Store timestamp
                    };
                  }
                }
                // If contactData is populated, adjust the display name based on user role
                if (contactData) {
                  if (contactData.user_role === "partner") {
                    // Get partner details from partners collection using partner_id
                    if (contactData.partner_id) {
                      const partnerOrgRef = doc(
                        db,
                        "partners",
                        contactData.partner_id
                      );
                      const partnerOrgDoc = await getDoc(partnerOrgRef);
                      if (partnerOrgDoc.exists()) {
                        contactData.displayName =
                          partnerOrgDoc.data().name_of_org;
                      }
                    }
                  } else if (contactData.user_role === "center") {
                    // Get partner details from partners collection using partner_id
                    if (contactData.center_id) {
                      const centerOrgRef = doc(
                        db,
                        "centers",
                        contactData.center_id
                      );
                      const centerOrgDoc = await getDoc(centerOrgRef);
                      if (centerOrgDoc.exists()) {
                        contactData.displayName =
                          centerOrgDoc.data().name_of_hub;
                      }
                    }
                  } else {
                    contactData.displayName = `${contactData.first_name} ${contactData.last_name}`; // Use first and last name for others
                  }

                  // Check if the contact already exists before adding
                  if (
                    !contactsWithLastMessage.some(
                      (contact) => contact.id === contactData.id
                    )
                  ) {
                    contactsWithLastMessage.push(contactData);
                  }
                }
              }
            }

            // Sort contacts by last message timestamp
            const sortedContacts = contactsWithLastMessage.sort((a, b) => {
              return (
                (b.lastMessageTime.seconds || 0) -
                (a.lastMessageTime.seconds || 0)
              );
            });

            setChats(chatsData);
            setContacts(sortedContacts);
            setIsLoading(false); // Set loading to false when done
          });

          return () => {
            unsubscribe();
            setIsLoading(false); // Ensure loading is set to false on cleanup
          };
        } catch (error) {
          console.error("Error fetching chats:", error);
          setIsLoading(false); // Set loading to false on error
        }
      }
    };

    fetchChats();
  }, [auth.currentUser]);

  const searchUsers = async (term) => {
    setSearchTerm(term);
    setLoadingSuggested(true);

    try {
      const usersRef = collection(db, "users");
      let users = []; // Declare users array

      if (currentUser.user_role === "citizen") {
        // Get centers with matching center_id
        const centerUsersQuery = query(
          usersRef,
          where("user_role", "==", "center"),
          where("center_id", "==", currentUser.center_id)
        );

        // Get partners that have the center_id in their array
        const partnerUsersQuery = query(
          usersRef,
          where("user_role", "==", "partner"),
          where("center_id", "array-contains", currentUser.center_id)
        );

        const [centerUsersDocs, partnerUsersDocs] = await Promise.all([
          getDocs(centerUsersQuery),
          getDocs(partnerUsersQuery),
        ]);

        users = [
          ...centerUsersDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...partnerUsersDocs.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
        ];
      } else if (currentUser.user_role === "center") {
        // Get citizens with matching center_id
        const citizenUsersQuery = query(
          usersRef,
          where("user_role", "==", "citizen"),
          where("center_id", "==", currentUser.center_id)
        );

        // Get partners where center_id array contains this center's id
        const partnerUsersQuery = query(
          usersRef,
          where("user_role", "==", "partner"),
          where("center_id", "array-contains", currentUser.center_id)
        );

        const [citizenUsersDocs, partnerUsersDocs] = await Promise.all([
          getDocs(citizenUsersQuery),
          getDocs(partnerUsersQuery),
        ]);

        users = [
          ...citizenUsersDocs.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
          ...partnerUsersDocs.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
        ];
      } else if (currentUser.user_role === "partner") {
        // Get centers that this partner is connected to
        const centerUsersQuery = query(
          usersRef,
          where("user_role", "==", "center"),
          where("center_id", "in", currentUser.center_id)
        );

        // Get citizens from connected centers
        const citizenUsersQuery = query(
          usersRef,
          where("user_role", "==", "citizen"),
          where("center_id", "in", currentUser.center_id)
        );

        const [centerUsersDocs, citizenUsersDocs] = await Promise.all([
          getDocs(centerUsersQuery),
          getDocs(citizenUsersQuery),
        ]);

        users = [
          ...centerUsersDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          ...citizenUsersDocs.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
        ];
      }

      // Filter and enrich users with data from centers/partners collections
      const filteredUsers = await Promise.all(
        users.map(async (user) => {
          // Handle center users - get data from centers collection
          if (user.user_role === "center") {
            const centerDocRef = doc(db, "centers", user.center_id);
            const centerDoc = await getDoc(centerDocRef);

            if (centerDoc.exists()) {
              const centerData = centerDoc.data();
              // Only include centers that are certified
              if (centerData.is_certified) {
                const enrichedData = {
                  ...user,
                  ...centerData,
                  docId: user.id,
                  name_of_hub: centerData.name_of_hub,
                  userType: "Center",
                };
                return enrichedData;
              } else {
                return null;
              }
            }
          }

          // Handle partner users - get data from partners collection
          if (user.user_role === "partner") {
            const partnerDocRef = doc(db, "partners", user.partner_id);
            const partnerDoc = await getDoc(partnerDocRef);

            if (partnerDoc.exists()) {
              const partnerData = partnerDoc.data();
              // Only include partners that are certified
              if (partnerData.is_certified) {
                const enrichedData = {
                  ...user,
                  ...partnerData,
                  docId: user.id,
                  name_of_org: partnerData.name_of_org,
                  userType: "Partner",
                };
                return enrichedData;
              } else {
                return null;
              }
            }
          }

          // Handle citizen users (no certification needed)
          if (user.user_role === "citizen") {
            return {
              ...user,
              userType: "Citizen",
            };
          }

          return null;
        })
      );

      const validUsers = filteredUsers.filter(Boolean);

      // If search term is empty, show all filtered contacts
      if (!term.trim()) {
        setSuggestedContacts(validUsers);
        setLoadingSuggested(false);
        return;
      }

      // Filter by search term
      const searchFiltered = validUsers.filter((user) => {
        const searchableFields = [
          user.name_of_hub?.toLowerCase(),
          user.name_of_org?.toLowerCase(),
          user.email?.toLowerCase(),
          user.first_name?.toLowerCase(),
          user.last_name?.toLowerCase(),
          `${user.first_name} ${user.last_name}`.toLowerCase(),
        ];

        const matches = searchableFields.some(
          (field) => field && field.includes(term.toLowerCase())
        );

        return matches;
      });

      setSuggestedContacts(searchFiltered);
    } catch (error) {
      console.error("🚨 Error searching for users:", error);
      setSuggestedContacts([]);
    } finally {
      setLoadingSuggested(false);
    }
  };

  const handleInputFocus = async () => {
    if (!currentUser) {
      return;
    }

    setLoadingSuggested(true);

    try {
      const usersRef = collection(db, "users");

      if (currentUser.user_role === "citizen") {
        // Fetch centers first
        const centersQuery = query(
          usersRef,
          where("user_role", "==", "center"),
          where("center_id", "==", currentUser.center_id)
        );

        const centersDocs = await getDocs(centersQuery);
        const centers = centersDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch partners from users collection
        const partnersQuery = query(
          usersRef,
          where("user_role", "==", "partner"),
          where("center_id", "array-contains", currentUser.center_id)
        );

        const partnersDocs = await getDocs(partnersQuery);

        // Get partner details from partners collection
        const partnersPromises = partnersDocs.docs.map(async (doc) => {
          const userData = doc.data();

          // First check if this user has a partner_id
          if (!userData.partner_id) {
            return null;
          }

          try {
            // Get partner details from partners collection
            const partnerDocRef = doc(db, "partners", userData.partner_id);
            const partnerDoc = await getDoc(partnerDocRef);

            if (!partnerDoc.exists()) {
              return null;
            }

            const partnerData = partnerDoc.data();

            const mergedData = {
              id: doc.id,
              ...userData,
              ...partnerData,
              name_of_org: partnerData.name_of_org,
              userType: "Partner",
              user_role: "partner",
              email: userData.email,
            };

            return mergedData;
          } catch (error) {
            console.error(
              `🚨 Error fetching partner details for ${userData.partner_id}:`,
              error
            );
            return null;
          }
        });

        const partners = (await Promise.all(partnersPromises)).filter(
          (partner) => {
            if (!partner) {
              return false;
            }
            if (!partner.name_of_org) {
              return false;
            }
            return true;
          }
        );

        const finalSuggestedContacts = [...centers, ...partners];

        setSuggestedContacts(finalSuggestedContacts);
      }
    } catch (error) {
      console.error("🚨 Error fetching suggested contacts:", error);
      setSuggestedContacts([]);
    } finally {
      setLoadingSuggested(false);
    }
  };

  const initializeChat = async (currentUser, otherUser) => {
    try {
      // Check if the current user is a citizen and the other user is also a citizen
      if (
        currentUser.user_role === "citizen" &&
        otherUser.user_role === "citizen"
      ) {
        alert("Citizens cannot chat with other citizens.");
        return null; // Prevent chat initialization
      }

      if (otherUser.docId === undefined) {
        // Get user doc ID from email
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", otherUser.email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          alert("User not found");
          return null;
        }

        otherUser.docId = querySnapshot.docs[0].id;
      }

      // Check both combinations at once
      const chatsRef = collection(db, "chats");
      const chatId1 = [selectedUserId, otherUser.docId].join("_");
      const chatId2 = [otherUser.docId, selectedUserId].join("_");

      // Check both possible documents
      const [chat1Doc, chat2Doc] = await Promise.all([
        getDoc(doc(chatsRef, chatId1)),
        getDoc(doc(chatsRef, chatId2)),
      ]);

      let existingChatDoc = null;
      let chatId = null;

      // Use the existing document if found
      if (chat1Doc.exists()) {
        existingChatDoc = chat1Doc;
        chatId = chatId1;
      } else if (chat2Doc.exists()) {
        existingChatDoc = chat2Doc;
        chatId = chatId2;
      } else {
        // If no existing chat, create with first combination
        chatId = chatId1;
      }

      setCurrentChatId(chatId);

      let chat = null;

      if (existingChatDoc) {
        chat = { id: existingChatDoc.id, ...existingChatDoc.data() };
      } else {
        const newChat = {
          users: [selectedUserId, otherUser.docId],
          createdAt: serverTimestamp(),
        };
        await setDoc(doc(chatsRef, chatId), newChat);
        chat = { id: chatId, ...newChat };
      }

      return chat;
    } catch (error) {
      console.error("Error initializing chat:", error);
      return null;
    }
  };

  const sendMessage = async (chatId, senderId, messageContent) => {
    try {
      const messagesRef = collection(db, "chats", chatId, "messages");
      const newMessage = {
        senderId,
        message: messageContent,
        timestamp: serverTimestamp(),
        seen: false,
      };
      await addDoc(messagesRef, newMessage);

      // Update the contacts order and last message
      setContacts((prevContacts) => {
        const updatedContacts = prevContacts.map((contact) => {
          if (
            (contact.groupName && contact.id === chatId) ||
            (!contact.groupName &&
              [selectedUserId, contact.id].sort().join("_") === chatId)
          ) {
            return {
              ...contact,
              lastMessage: messageContent, // Update last message
              lastMessageTime: Timestamp.now(), // Use current timestamp for immediate sorting
            };
          }
          return contact;
        });

        return updatedContacts.sort(
          (a, b) =>
            (b.lastMessageTime?.seconds || 0) -
            (a.lastMessageTime?.seconds || 0)
        );
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    if (!currentChatId) return;

    const messagesRef = collection(db, "chats", currentChatId, "messages");
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const msgs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(msgs);

      // Update the contacts order when new messages arrive
      if (msgs.length > 0) {
        const lastMessage = msgs[msgs.length - 1];
        setContacts((prevContacts) => {
          const updatedContacts = prevContacts.map((contact) => {
            if (
              (contact.groupName && contact.id === currentChatId) ||
              (!contact.groupName &&
                [selectedUserId, contact.id].sort().join("_") === currentChatId)
            ) {
              return {
                ...contact,
                lastMessageTime: lastMessage.timestamp || Timestamp.now(),
              };
            }
            return contact;
          });

          return updatedContacts.sort(
            (a, b) =>
              (b.lastMessageTime?.seconds || 0) -
              (a.lastMessageTime?.seconds || 0)
          );
        });
      }
    });

    return () => unsubscribe();
  }, [currentChatId]);

  const handleSearch = async () => {
    const user = await searchUsers(searchTerm);
    if (user) {
      const chat = await initializeChat(auth.currentUser, user);
      const chatExists = chats.some(
        (existingChat) => existingChat.id === chat.id
      );
      const contactExists = contacts.some(
        (existingContact) => existingContact.id === user.id
      );

      if (!chatExists) {
        setChats((prevChats) => [...prevChats, chat]);
      }

      if (!contactExists) {
        setContacts((prevContacts) => [...prevContacts, user]);
      }

      selectContact(user);
    } else {
      alert("User not found!");
    }
  };

  const handleSuggestedContactClick = async (contact) => {
    try {
      // Check if a chat already exists with this contact
      const existingChat = chats.find(
        (chat) =>
          chat.users.includes(contact.id) || chat.users.includes(contact.docId)
      );

      if (existingChat) {
        // If chat exists, find and select the existing contact
        const existingContact = contacts.find(
          (existingContact) =>
            existingContact.id === contact.id ||
            existingContact.id === contact.docId
        );

        if (existingContact) {
          selectContact(existingContact);
          setSuggestedContacts([]);
          setShowSuggestions(false);
          setSearchTerm("");
          return;
        }
      }

      // If no existing chat, initialize a new one
      const chat = await initializeChat(auth.currentUser, contact);

      if (chat) {
        selectContact(contact);
        setSuggestedContacts([]);
        setShowSuggestions(false);
        setSearchTerm("");
      }
    } catch (error) {
      console.error("Error initializing chat:", error);
      alert("Failed to start chat with contact");
    }
  };

  const selectContact = async (contact) => {
    try {
      let chat;

      // Clear suggested contacts when selecting a contact
      setSuggestedContacts([]);

      // Check if the contact already exists in the current chats
      const existingChat = chats.find(
        (existingChat) =>
          existingChat.users.includes(contact.id) ||
          existingChat.users.includes(contact.docId)
      );

      if (existingChat) {
        setCurrentChatId(existingChat.id);
        setSelectedContact(contact);

        // Check if there are unread messages for this chat
        if (unreadMessages[contact.id] > 0) {
          await markMessagesAsSeen(existingChat.id);
        }
        return;
      }

      // Create a new chat if it doesn't exist
      setSelectedContact(contact);
      if (contact.groupName) {
        chat = await initializeGroupChat(
          auth.currentUser,
          contact.selectedUsers,
          contact.groupName
        );
      } else {
        chat = await initializeChat(auth.currentUser, contact);
      }

      if (chat) {
        setCurrentChatId(chat.id);
        await markMessagesAsSeen(chat.id);
      }
    } catch (error) {
      console.error("Error selecting contact:", error);
    }
  };

  const markMessagesAsSeen = async (chatId) => {
    try {
      const messagesRef = collection(db, "chats", chatId, "messages");
      const q = query(messagesRef, where("seen", "==", false));
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const messageData = doc.data();

        if (messageData.senderId !== selectedUserId) {
          batch.update(doc.ref, { seen: true });
        }
      });

      await batch.commit();

      // Update the unread messages state
      setUnreadMessages((prev) => ({
        ...prev,
        [chatId]: 0, // Set unread count to 0 for this chat
      }));
    } catch (error) {
      console.error("Error marking messages as seen:", error);
    }
  };

  const [unreadMessages, setUnreadMessages] = useState({});

  // Add this useEffect to track unread messages
  useEffect(() => {
    if (!selectedUserId) return;

    const trackUnreadMessages = async () => {
      contacts.forEach(async (contact) => {
        const chatId = contact.groupName
          ? contact.id
          : [selectedUserId, contact.id].sort().join("_");

        const messagesRef = collection(db, "chats", chatId, "messages");
        const q = query(
          messagesRef,
          where("seen", "==", false),
          where("senderId", "!=", selectedUserId)
        );

        onSnapshot(q, (snapshot) => {
          setUnreadMessages((prev) => ({
            ...prev,
            [contact.id]: snapshot.docs.length,
          }));
        });
      });
    };

    trackUnreadMessages();
  }, [contacts]);

  const convertTimestampToDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      const date = timestamp.toDate();
      const formattedDate = date.toLocaleString();
      return formattedDate;
    } else {
      return "";
    }
  };

  const now = new Date().toLocaleString();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const initializeGroupChat = async (currentUser, otherUsers, groupName) => {
    try {
      const chatsRef = collection(db, "chats");

      // Check if a group chat with the same name already exists
      const q = query(chatsRef, where("groupName", "==", groupName));
      const querySnapshot = await getDocs(q);

      let chat = null;

      if (!querySnapshot.empty) {
        // Group chat with the same name already exists
        const existingChatDoc = querySnapshot.docs[0]; // Assuming you take the first match if there are multiple
        chat = { id: existingChatDoc.id, ...existingChatDoc.data() };
      } else {
        // Create a new group chat if it doesn't exist
        const chatDocRef = doc(chatsRef); // Generates a random ID
        const chatId = chatDocRef.id;
        const newChat = {
          groupName: groupName,
          users: [selectedUserId, ...otherUsers.map((user) => user.id)],
          createdAt: serverTimestamp(),
        };
        await setDoc(chatDocRef, newChat);
        chat = { id: chatDocRef.id, ...newChat };
      }

      setCurrentChatId(chat.id);
      return chat;
    } catch (error) {
      console.error("Error initializing group chat:", error);
      return null;
    }
  };

  const searchUsersByEmail = async (email) => {
    try {
      const user = await searchUsers(email);

      return [user].filter((user) => user.email.includes(email));
    } catch (err) {
      alert("User not found.");
    }
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = auth.currentUser; // Get the current user from Firebase Auth
      if (user) {
        const userDocRef = doc(db, "users", selectedUserId); // Reference to the user document
        const userDoc = await getDoc(userDocRef); // Fetch the user document
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser(userData);
        } else {
          console.log("No such user document!");
        }
      } else {
        console.log("No user is currently logged in.");
      }
    };

    fetchCurrentUser();
  }, []); // Empty dependency array to run only on mount

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start px-2.5 pb-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <CreateGroupChatModal
        ref={dialogRef} // Attach the ref to the dialog
        isOpen={isCreateGroupChatModalOpen}
        onClose={() => setIsCreateGroupChatModalOpen(false)}
        searchUsersByEmail={searchUsersByEmail}
      />
      <div id="dialog-container"></div>
      <section className="flex max-w-full flex-row items-start justify-start self-stretch text-left text-sm text-darkslateblue">
        {/* Sidebar */}
        <div className="commu-left-panel rounded-lg pl-0 pr-2">
          <h2 className="commu-title mb-2 mt-4">Communication</h2>
          <div ref={searchRef} className="relative w-full">
            <input
              type="text"
              placeholder="Search email or name..."
              className="mb-2 w-full rounded border p-2"
              value={searchTerm}
              onChange={(e) => {
                const newSearchTerm = e.target.value;
                setSearchTerm(newSearchTerm);
                setShowSuggestions(true);
                searchUsers(newSearchTerm);
              }}
              onFocus={() => {
                setShowSuggestions(true);
                if (!searchTerm.trim()) {
                  searchUsers("");
                }
              }}
            />
            {showSuggestions && (
              <div className="suggested-contacts absolute z-10 w-full rounded-lg border bg-white p-2 shadow-lg">
                {loadingSuggested ? (
                  <div className="p-2 text-gray-500">Loading...</div>
                ) : suggestedContacts.length > 0 ? (
                  <>
                    <label className="font-Inter text-xs text-gray-500">
                      {searchTerm.trim()
                        ? "Search Results"
                        : "Suggested Contacts"}
                    </label>
                    {suggestedContacts.map((contact) => (
                      <div
                        key={contact.id}
                        className="suggested-contact hover:bg-gray-100 cursor-pointer rounded p-2"
                        onClick={() => handleSuggestedContactClick(contact)}
                      >
                        <div className="flex items-center">
                          <ContactAvatar contact={contact} />
                          <div className="ml-2 flex w-full flex-col">
                            <div className="font-semibold">
                              {contact.user_role === "center"
                                ? contact.name_of_hub
                                : contact.user_role === "partner"
                                ? contact.name_of_org
                                : `${contact.first_name} ${contact.last_name}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  searchTerm.trim() && (
                    <div className="p-2 text-gray-500">No users found</div>
                  )
                )}
              </div>
            )}
          </div>
          <div className="max-h-[calc(100vh-180px)] space-y-2 overflow-y-auto">
            {isLoading ? (
              <div className="flex h-full w-full items-center justify-center p-4">
                <div className="flex flex-col items-center space-y-2">
                  <div className="animate-spin h-8 w-8 rounded-full border-b-2 border-t-2 border-blue-500"></div>
                  <p className="text-sm text-gray-500">Loading chats...</p>
                </div>
              </div>
            ) : contacts.length === 0 ? (
              <div className="flex h-full w-full items-center justify-center p-4">
                {/* <p className="text-gray-500">No chats yet</p> */}
              </div>
            ) : (
              contacts.map((contact) => (
                <div
                  key={contact.id + "qwerty"}
                  className={`flex cursor-pointer items-center rounded p-1 transition-colors duration-200  ${
                    selectedContact?.id === contact.id
                      ? "bg-blue-100"
                      : "hover:bg-gray-200"
                  }`}
                  onClick={() => {
                    selectContact(contact);
                    // Reset unread messages count for this contact
                    setUnreadMessages((prev) => ({
                      ...prev,
                      [contact.id]: 0,
                    }));
                  }}
                >
                  <ContactAvatar contact={contact} />
                  <div className="ml-2 min-w-0 flex-grow ">
                    <h3
                      className={`convo-title truncate ${
                        unreadMessages[contact.id] > 0
                          ? "convo-title-bold"
                          : "convo-title"
                      }`}
                    >
                      {contact.displayName}
                    </h3>
                    <div className="flex items-center justify-between">
                      <p
                        className={`text-gray-600 truncate text-sm ${
                          unreadMessages[contact.id] > 0
                            ? "convo-title-bold"
                            : "convo-title"
                        }`}
                      >
                        {contact.lastMessage && contact.lastMessage.length > 0 // Check if lastMessage exists
                          ? contact.lastMessage.length > 18
                            ? contact.lastMessage.slice(0, 18) + "..."
                            : contact.lastMessage
                          : ""}
                      </p>
                      <div className="flex items-center">
                        <span className="text-xs text-gray-400">
                          {
                            convertTimestampToDate(
                              contact.lastMessageTime
                            ).split(", ")[1]
                          }
                        </span>{" "}
                        {/* Use lastMessageTime from contact */}
                        {unreadMessages[contact.id] > 0 && (
                          <span className="text-Inter rounded-full bg-[#A2211A] px-2 py-1 text-white">
                            {unreadMessages[contact.id]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Messages */}
        <div className="commu-right-panel flex h-[calc(100vh-70px)] flex-1 flex-col self-stretch overflow-hidden">
          {isLoading ? (
            <div className="flex h-full w-full items-center justify-center">
              <div className="flex flex-col items-center space-y-2">
                <div className="animate-spin h-8 w-8 rounded-full border-b-2 border-t-2 border-blue-500"></div>
                <p className="text-sm text-gray-500">Loading messages...</p>
              </div>
            </div>
          ) : selectedContact ? (
            <div className="flex h-full w-full flex-col">
              <div className="flex items-center justify-between bg-[#F3F3F3] p-3">
                <div className="flex items-center">
                  <HeaderContactAvatar contact={selectedContact} />
                  <div
                    className="hover:bg-gray-100 cursor-pointer rounded p-2 transition-all duration-200"
                    //onClick={handleContactClick}
                  >
                    <h2 className="text-xl font-bold">
                      {selectedContact.user_role === "center" ||
                      selectedContact.user_role === "partner"
                        ? selectedContact.displayName
                        : selectedContact.user_role === "citizen"
                        ? `${selectedContact.first_name} ${selectedContact.last_name}`
                        : selectedContact.displayName}
                    </h2>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  {/* <button className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                  </button>
                  <button className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </button> */}
                </div>
              </div>
              <div className="flex-1 overflow-y-auto p-4">
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`mb-4 ${
                      message.senderId === selectedUserId ? "text-right" : ""
                    }`}
                  >
                    <div
                      className={`inline-block rounded-lg p-3 ${
                        message.senderId === selectedUserId
                          ? "commu-sender-chat"
                          : "bg-gray-200"
                      }`}
                    >
                      <div
                        className="text-left"
                        dangerouslySetInnerHTML={{ __html: message.message }}
                      ></div>
                    </div>
                    {/* New div for the timestamp outside the blue div */}
                    <div className="mt-1 text-xs text-gray-500">
                      {convertTimestampToDate(message.timestamp)}
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div className="flex items-center border-t border-gray-300 bg-white p-4">
                <textarea
                  rows={3}
                  placeholder="Type your message..."
                  className="chat-textarea flex-grow resize-none p-2" // Adjust styles as needed
                  value={editorContent}
                  onChange={(e) => setEditorContent(e.target.value)} // Update editorContent on input change
                />
                <button
                  onClick={handleSend}
                  className="ml-2 rounded-full p-2 transition duration-150 hover:bg-blue-600" // Add padding and hover effect
                  aria-label="Send Message" // Accessibility label
                >
                  <svg
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.8173 21.8081C29.8173 21.8081 29.8107 21.8147 29.8074 21.818L13.2253 30.6838C12.9602 30.8321 12.6561 30.8957 12.3539 30.8662C12.0516 30.8367 11.7656 30.7155 11.5342 30.5187C11.501 30.4909 11.4692 30.4617 11.4387 30.4312C11.2375 30.2312 11.0977 29.9779 11.0358 29.701C10.9739 29.4242 10.9925 29.1354 11.0894 28.8687L13.7198 21.5038C13.7459 21.431 13.7938 21.368 13.8571 21.3235C13.9203 21.279 13.9958 21.2551 14.0731 21.2552L21.6655 21.2545C21.861 21.2495 22.0468 21.1683 22.1833 21.0283C22.3199 20.8882 22.3963 20.7004 22.3963 20.5048C22.3963 20.3092 22.3199 20.1213 22.1833 19.9813C22.0468 19.8412 21.861 19.76 21.6655 19.755L14.0678 19.7491C13.9905 19.7491 13.915 19.7253 13.8518 19.6807C13.7885 19.6362 13.7406 19.5733 13.7145 19.5005L11.098 12.1733C10.9922 11.8886 10.9732 11.5788 11.0436 11.2833C11.1139 10.9879 11.2703 10.7199 11.4931 10.5134C11.7245 10.3036 12.016 10.1718 12.3263 10.1367C12.6367 10.1016 12.9502 10.165 13.2226 10.3178L29.8047 19.1836L29.8147 19.1935C30.0467 19.3238 30.2399 19.5135 30.3745 19.743C30.5091 19.9726 30.5801 20.2339 30.5804 20.5C30.5807 20.7661 30.5101 21.0275 30.376 21.2574C30.2419 21.4873 30.0491 21.6773 29.8173 21.8081Z"
                      fill="#003461"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              {/* <p className="text-center text-gray-500">
                Select a contact to start messaging
              </p> */}
            </div>
          )}
        </div>
      </section>
      {showProfilePopup && (
        <ContactProfilePopup
          contact={selectedContact}
          onClose={() => setShowProfilePopup(false)}
        />
      )}
    </div>
  );
};

export default Communication;
