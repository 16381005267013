import React from 'react';

const NotificationDetailsPopup = ({ notification, onClose }) => {
    if (!notification) return null;

    const formatDateTime = (timestamp) => {
        if (!timestamp) return '';
        const date = timestamp.toDate();
        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    const stripHtmlTags = (html) => {
        const tmp = document.createElement('div');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    const getColorClasses = (urgency) => {
        switch (urgency) {
            case 'emergency':
                return {
                    iconColor: 'text-red-600',
                    titleColor: 'text-red-600',
                    buttonColor: 'bg-red-600 hover:bg-red-700'
                };
            case 'warning':
                return {
                    iconColor: 'text-orange-600',
                    titleColor: 'text-orange-600',
                    buttonColor: 'bg-orange-600 hover:bg-orange-700'
                };
            case 'updates':
                return {
                    iconColor: 'text-green-600',
                    titleColor: 'text-green-600',
                    buttonColor: 'bg-green-600 hover:bg-green-700'
                };
            default:
                return {
                    iconColor: 'text-blue-600',
                    titleColor: 'text-blue-600',
                    buttonColor: 'bg-blue-600 hover:bg-blue-700'
                };
        }
    };

    const getUrgencyLabel = (urgency) => {
        switch (urgency) {
            case 'emergency':
                return 'Emergency';
            case 'warning':
                return 'Warning';
            case 'updates':
                return 'Updates';
            default:
                return 'General News';
        }
    };

    const { iconColor, titleColor, buttonColor } = getColorClasses(notification.urgency);
    const urgencyLabel = getUrgencyLabel(notification.urgency);

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-xl">
                <div className="mb-6 flex flex-col items-center justify-center">
                    <svg 
                        className={`h-12 w-12 ${iconColor}`} 
                        fill="currentColor" 
                        viewBox="0 0 20 20"
                    >
                        <path 
                            fillRule="evenodd" 
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v4a1 1 0 002 0V7zm-1 6a1 1 0 100 2 1 1 0 000-2z" 
                            clipRule="evenodd" 
                        />
                    </svg>
                    <h2 className={`mt-2 text-3xl font-bold ${titleColor}`}>{urgencyLabel}</h2>
                </div>

                <div className="mb-4 text-center">
                    <h3 className="text-xl font-semibold text-black">{notification.title}</h3>
                </div>

                <div className="mb-8 rounded-lg bg-gray-50 p-4 text-black text-center">
                    {stripHtmlTags(notification.content)}
                </div>

                <div className="mb-4 text-center">
                    <p className="text-black text-sm">
                        {formatDateTime(notification.timestamp)}
                    </p>
                </div>

                <div className="mb-4 text-center">
                    <p className="text-black text-sm">
                        From: {notification.sender?.name || 'System'}
                    </p>
                </div>
                
                <div className="flex justify-center">
                    <button
                        onClick={onClose}
                        className={`rounded px-8 py-3 text-lg font-semibold text-white transition-colors duration-200 ${buttonColor}`}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotificationDetailsPopup;