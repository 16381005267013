import React, { useState, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import AdminService from "../service/admin/adminService";
import mailer from "../service/api/mailer";
import dataService from "../service/data/DataService";
import CustomInput from "./CustomInput";
import { encrypt } from "../service/encryption/Encryption";

export const CitizenForm = ({
  onSubmitSuccess,
  centerState,
  centerCounty,
  centerId,
}) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    address1: "",
    address2: "",
    city: "",
    state: centerState,
    zip: "",
    county: centerCounty,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [emailExists, setEmailExists] = useState(false);
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [isDuplicateAddress, setIsDuplicateAddress] = useState(false);
  const [dupAddressData, setDupAddressData] = useState(null);
  const [dupAddressBackupData, setDupAddressBackupData] = useState(null);
  const [selectedDisasters, setSelectedDisasters] = useState([]);
  const [disasterOptions, setDisasterOptions] = useState([]);
  const [popupMessage, setPopupMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [touchedFields, setTouchedFields] = useState({});

  // Consolidate required fields definition
  const requiredFields = [
    "first_name",
    "last_name",
    "email",
    "mobile_number",
    "address1",
    "city",
    "zip",
  ];

  useEffect(() => {
    const storedDisasters = localStorage.getItem("selectedDisasters");
    if (storedDisasters) {
      const disasters = JSON.parse(storedDisasters);
      setSelectedDisasters(disasters);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Helper function to update errors and touched fields
  const updateFieldStatus = (field, error = null) => {
    setErrors((prev) =>
      error
        ? {
            ...prev,
            [field]: error,
          }
        : {
            ...prev,
            [field]: undefined,
          }
    );

    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  // Helper function to clear field status
  const clearFieldStatus = (field) => {
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
    setTouchedFields((prev) => {
      const newTouched = { ...prev };
      delete newTouched[field];
      return newTouched;
    });
  };

  // Consolidate validation logic
  const validateForm = () => {
    const missing = requiredFields.filter((field) => !formData[field]);

    // Mark all required fields as touched
    const newTouchedFields = {};
    requiredFields.forEach((field) => {
      newTouchedFields[field] = true;
    });
    setTouchedFields(newTouchedFields);

    if (missing.length > 0) {
      setMissingFields(missing);
      setErrors(
        missing.reduce(
          (acc, field) => ({
            ...acc,
            [field]: "This field is required",
          }),
          {}
        )
      );
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields first
    const isValid = validateForm();

    // Check for duplicate email
    const emailCheck = await checkEmailExistence();
    if (emailCheck) {
      updateFieldStatus("email", { message: "Email already in use" });
      setTimeout(() => clearFieldStatus("email"), 3000);
    }

    // If either validation failed or email exists, don't proceed
    if (!isValid || emailCheck) {
      return;
    }

    try {
      setIsSubmitting(true);

      // Check for duplicates
      const addressCheck = await checkDupAddress();
      if (addressCheck) {
        setIsDuplicateAddress(true);
        return;
      }

      // Get next DARCI ID
      const darciNo = (await AdminService.getDarciNo()) + 1;
      const tempPassword = generateOtp();

      // Create user document
      const userId = doc(collection(db, "users")).id; // Generate unique Firebase document ID
      const userData = {
        ...formData,
        darci_id: darciNo.toString(),
        user_role: "citizen",
        created_date: serverTimestamp(),
        created_by: "center",
        status: "active",
        state: centerState,
        county: centerCounty,
        otp: encrypt(generateOtp()),
        password: encrypt(tempPassword),
        center_id: centerId,
        pin: encrypt("7777"),
        profile_completed: false,
        is_address_complete: true,
        id: userId,
      };

      console.log("formData email: ", formData.email);
      console.log("darciNo: ", darciNo);
      console.log("formData first_name: ", formData.first_name);
      console.log("tempPassword is here: ", tempPassword);

      // Save user to Firestore
      await setDoc(doc(db, "users", userId), userData);

      await setDoc(doc(db, "users-list", userId), {
        created_date: serverTimestamp(),
        created_by: "center",
        email: formData.email,
        user_id: userId,
      });

      // Send OTP to citizen email
      await mailer.sendCaseMgtEmailInvite(
        formData.email,
        formData.first_name,
        darciNo.toString(),
        tempPassword
      );

      // Increment DARCI number
      await AdminService.incrementDarciNo();

      // Generate case number
      const newCaseNumber = await dataService.generateCaseNumber();

      // Create case management entry
      const caseManagementRef = collection(db, "case_management");
      const caseData = {
        case_number: newCaseNumber,
        citizen_id: userId,
        citizen_name: `${formData.first_name} ${formData.last_name}`,
        citizen_email: formData.email,
        citizen_address: formData.address1,
        citizen_mobile: formData.mobile_number,
        disaster_id: selectedDisasters[0],
        disaster_name: localStorage.getItem("disasterName"),
        center_id: centerId,
        status: "Unassigned",
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      };

      await addDoc(caseManagementRef, caseData);

      // Create survey response entry
      const surveyData = {
        user_id: userId,
        disasters: [
          {
            disaster_id: selectedDisasters[0],
            disaster_name: localStorage.getItem("disasterName"),
            disaster_type:
              disasterOptions.find((d) => d.id === selectedDisasters[0])
                ?.disaster_type || "",
          },
        ],
        survey_id: uuidv4(),
        case_number: newCaseNumber,
        submitted_at: serverTimestamp(),
        responses: {}, // Empty responses object
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      };

      const surveyDocRef = doc(
        db,
        `users/${userId}/survey_responses`,
        surveyData.survey_id
      );

      await setDoc(surveyDocRef, surveyData);

      // Show success message
      setPopupMessage(
        `Citizen successfully registered with case number: ${newCaseNumber}`
      );

      onSubmitSuccess();

      // Clear form
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
      });
    } catch (error) {
      console.error("Error creating citizen:", error);
      setPopupMessage("Error creating citizen. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkDupAddress = async () => {
    // Get all users and convert their addresses to lowercase for comparison
    const usersRef = collection(db, "users");
    const citizenQuery = query(usersRef, where("user_role", "==", "citizen"));
    const snapshot = await getDocs(citizenQuery);

    // Check each document manually with case-insensitive comparison
    const matchingDoc = snapshot.docs.find((doc) => {
      const userData = doc.data();
      return (
        userData.address1?.toLowerCase() ===
          formData.address1.trim().toLowerCase() &&
        userData.city?.toLowerCase() === formData.city.trim().toLowerCase() &&
        userData.state?.toLowerCase() === formData.state.toLowerCase() &&
        userData.zip?.toLowerCase() === formData.zip.trim().toLowerCase()
      );
    });

    if (matchingDoc) {
      // Set the duplicate address flag
      setIsDuplicateAddress(true);
      try {
        const existingUser = matchingDoc.data();
        const firstName = existingUser.first_name;
        const email = existingUser.email;
        const otp = generateOtp();

        // Generate a secure verification token
        const verificationToken =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);

        setDupAddressBackupData({
          dup_address_otp: otp,
          dup_address_token: verificationToken,
          dup_address_original_email: email,
          dup_address_fraud_email: formData.email,
        });

        // Store the verification data
        const verificationData = {
          original_email: email,
          fraud_email: formData.email,
          created_at: new Date().toISOString(),
          otp: otp,
          token: verificationToken,
        };

        setDupAddressData(verificationData);

        // Create a base64 encoded string of the verification data
        const encodedData = btoa(JSON.stringify(verificationData));
        const loginLink = `${window.location.origin}/login?dupAddress=true&token=${verificationToken}&data=${encodedData}`;

        // Send OTP to duplicate email
        if (!emailExists) {
          await mailer.sendOtpDuplicateEmail(email, otp, firstName, loginLink);
        }
        return true;
      } catch (error) {
        console.error("Error in checkDupAddress:", error);
        return false;
      }
    }
    return false;
  };

  const checkEmailExistence = async () => {
    const email = formData.email;
    const snapshot = await getDocs(
      query(collection(db, "users"), where("email", "==", email))
    );
    if (snapshot.empty) {
      setEmailExists(false);
      setIsDuplicateEmail(false);
      return false;
    } else {
      setErrors((prev) => ({
        ...prev,
        email: { message: "Email already in use" },
      }));
      setEmailExists(true);
      setIsDuplicateEmail(true);
      return true;
    }
  };

  const generateOtp = () => {
    return Math.floor(Math.random() * 900000 + 100000).toString();
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-4 p-4">
        {/* First row */}
        <div className="flex gap-4">
          <div className="w-1/3">
            <CustomInput
              placeholder="First Name *"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              errors={errors}
              register={{
                name: "first_name",
                onChange: handleChange,
              }}
            />
          </div>

          <div className="w-1/3">
            <CustomInput
              placeholder="Last Name *"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              errors={errors}
              register={{
                name: "last_name",
                onChange: handleChange,
              }}
            />
          </div>

          <div className="w-1/3">
            <CustomInput
              placeholder="Phone No. *"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={handleChange}
              errors={errors}
              register={{
                name: "mobile_number",
                onChange: handleChange,
              }}
            />
          </div>
        </div>

        {/* Email row */}
        <div className="w-full">
          <CustomInput
            placeholder="Email Address *"
            name="email"
            value={formData.email}
            onChange={handleChange}
            errors={errors}
            register={{
              name: "email",
              onChange: handleChange,
            }}
          />
        </div>

        {/* Address rows */}
        <div className="w-full">
          <CustomInput
            placeholder="Address 1 *"
            name="address1"
            value={formData.address1}
            onChange={handleChange}
            errors={errors}
            register={{
              name: "address1",
              onChange: handleChange,
            }}
          />
        </div>

        <div className="w-full">
          <CustomInput
            placeholder="Address 2"
            name="address2"
            value={formData.address2}
            onChange={handleChange}
            errors={errors}
            register={{
              name: "address2",
              onChange: handleChange,
            }}
          />
        </div>

        {/* Last row */}
        <div className="flex gap-4">
          <div className="w-1/4">
            <CustomInput
              placeholder="City *"
              name="city"
              value={formData.city}
              onChange={handleChange}
              errors={errors}
              register={{
                name: "city",
                onChange: handleChange,
              }}
            />
          </div>

          <div className="w-1/4">
            <CustomInput
              placeholder="State"
              name="state"
              value={centerState}
              disabled={true}
              errors={errors}
              register={{
                name: "state",
                onChange: handleChange,
              }}
            />
          </div>

          <div className="w-1/4">
            <CustomInput
              placeholder="ZIP Code *"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              errors={errors}
              register={{
                name: "zip",
                onChange: handleChange,
              }}
            />
          </div>

          <div className="w-1/4">
            <CustomInput
              placeholder="County"
              name="county"
              value={centerCounty}
              disabled={true}
              errors={errors}
              register={{
                name: "county",
                onChange: handleChange,
              }}
            />
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="flex justify-between  gap-x-4 border-t px-5">
        <button
          onClick={() => onSubmitSuccess()}
          className="text-gray-700 hover:bg-gray-50 w-full cursor-pointer rounded-[4px] border border-[#0a2558] bg-white px-4 py-4 text-xl font-medium"
        >
          Cancel
        </button>

        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={`w-full cursor-pointer rounded-[4px] px-4 py-4 text-xl font-medium text-[#F7CA41] ${
            isSubmitting
              ? "bg-[#0a2558]/90"
              : "bg-[#0a2558] hover:bg-[#0a2558]/90"
          }`}
        >
          {isSubmitting ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};
