import React from "react";
import { useSidebarContext } from "../context/SidebarContext";
import PdfViewerMultiplePage from "./PDFViewerMultiplePage";

const ModalFileViewer = ({
  isOpen = false,
  setIsOpen = () => {},
  title = "Modal",
  fileUrl,
  fileType,
  width = "1200px",
  height = "781px",
}) => {
  const { toggleSidebar } = useSidebarContext();
  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 z-50 mt-[150px] flex items-center justify-center overflow-y-auto ${
        toggleSidebar ? "ml-[335px]" : "ml-[80px]"
      }`}
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-0 transition-opacity"
        onClick={() => {
          setIsOpen(false);
        }}
      />

      {/* Modal */}
      <div
        className={`relative z-50 flex flex-col overflow-hidden rounded-[10px] bg-white shadow-preset`}
        style={{ width, height }}
      >
        {/* Header - Fixed at top */}
        <div className="flex items-center justify-between border-b bg-tropical-blue px-4 py-3">
          <h2 className="text-xl font-semibold text-dark-cerulean">{title}</h2>
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className="cursor-pointer bg-transparent"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_10134_3366"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="32"
                height="33"
              >
                <rect y="0.5" width="32" height="32" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_10134_3366)">
                <circle cx="16.0013" cy="16.4974" r="13.3333" fill="#FAFAFA" />
                <path
                  d="M16.0013 18.3641L19.868 22.2307C20.1124 22.4752 20.4235 22.5974 20.8013 22.5974C21.1791 22.5974 21.4902 22.4752 21.7346 22.2307C21.9791 21.9863 22.1013 21.6752 22.1013 21.2974C22.1013 20.9196 21.9791 20.6085 21.7346 20.3641L17.868 16.4974L21.7346 12.6307C21.9791 12.3863 22.1013 12.0752 22.1013 11.6974C22.1013 11.3196 21.9791 11.0085 21.7346 10.7641C21.4902 10.5196 21.1791 10.3974 20.8013 10.3974C20.4235 10.3974 20.1124 10.5196 19.868 10.7641L16.0013 14.6307L12.1346 10.7641C11.8902 10.5196 11.5791 10.3974 11.2013 10.3974C10.8235 10.3974 10.5124 10.5196 10.268 10.7641C10.0235 11.0085 9.9013 11.3196 9.9013 11.6974C9.9013 12.0752 10.0235 12.3863 10.268 12.6307L14.1346 16.4974L10.268 20.3641C10.0235 20.6085 9.9013 20.9196 9.9013 21.2974C9.9013 21.6752 10.0235 21.9863 10.268 22.2307C10.5124 22.4752 10.8235 22.5974 11.2013 22.5974C11.5791 22.5974 11.8902 22.4752 12.1346 22.2307L16.0013 18.3641ZM16.0013 29.8307C14.1569 29.8307 12.4235 29.4807 10.8013 28.7807C9.17908 28.0807 7.76797 27.1307 6.56797 25.9307C5.36797 24.7307 4.41797 23.3196 3.71797 21.6974C3.01797 20.0752 2.66797 18.3418 2.66797 16.4974C2.66797 14.653 3.01797 12.9196 3.71797 11.2974C4.41797 9.67517 5.36797 8.26406 6.56797 7.06406C7.76797 5.86406 9.17908 4.91406 10.8013 4.21406C12.4235 3.51406 14.1569 3.16406 16.0013 3.16406C17.8457 3.16406 19.5791 3.51406 21.2013 4.21406C22.8235 4.91406 24.2346 5.86406 25.4346 7.06406C26.6346 8.26406 27.5846 9.67517 28.2846 11.2974C28.9846 12.9196 29.3346 14.653 29.3346 16.4974C29.3346 18.3418 28.9846 20.0752 28.2846 21.6974C27.5846 23.3196 26.6346 24.7307 25.4346 25.9307C24.2346 27.1307 22.8235 28.0807 21.2013 28.7807C19.5791 29.4807 17.8457 29.8307 16.0013 29.8307Z"
                  fill="#E72528"
                />
              </g>
            </svg>
          </button>
        </div>

        <div className="h-full w-full flex-1 overflow-hidden">
          <div className="h-full p-5">
            {fileType?.includes("pdf") ? (
              <PdfViewerMultiplePage fileUrl={fileUrl} />
            ) : (
              <div className="flex h-full w-full flex-col items-center overflow-y-auto">
                <img
                  src={fileUrl}
                  alt="imageAlt"
                  className="h-full w-full object-contain"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFileViewer;
