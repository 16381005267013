import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import userIcon from "../../src/icons/Profile/userIcon.png";
import { auth, db } from "../firebase";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../components/AuthContext";
import { useGeneralContext } from "../context/GeneralContext";
import Popup from "../components/Popup";
import { encrypt, decrypt } from "../service/encryption/Encryption";
import { config } from "../utils/config";
import { UserStatus } from "../utils/enums";

const Login1 = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordSection, setShowPasswordSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [attempts, setAttempts] = useState(3);
  const navigate = useNavigate();
  const { login, setRoleBasedRoute, setUserIdToUse } = useAuth();
  const [searchParams] = useSearchParams();
  const { dupAddressData, clearDupAddress, setDupAddress, setEmailfunc } =
    useGeneralContext();
  const verificationToken = searchParams.get("token");
  const isDupAddress = searchParams.get("dupAddress") === "true";
  const encodedData = searchParams.get("data");

  const getUserByEmailorDarciIdorMobile = async (searchTerm) => {
    try {
      // Convert to lowercase once
      const lowercaseSearchTerm = searchTerm.toLowerCase();

      // Create an array of query conditions
      const queries = [
        where("email", "==", lowercaseSearchTerm),
        where("darci_id", "==", lowercaseSearchTerm),
        //where("mobile_number", "==", searchTerm), // Keep original case for mobile
      ];

      // Execute queries in parallel and flatten results
      const results = await Promise.all(
        queries.map((condition) =>
          getDocs(query(collection(db, "users"), condition)).then((snapshot) =>
            snapshot.docs.map((doc) => ({
              id: doc.id,
              docId: doc.id,
              ...doc.data(),
            }))
          )
        )
      );

      // Find first non-deleted matching document from any query
      const firstMatch = results.flat().find((doc) => !doc.is_deleted);

      if (!firstMatch) {
        console.log("No matching documents.");
        return null;
      }

      return firstMatch;
    } catch (error) {
      console.error("Error getting user:", error);
      return null;
    }
  };

  const getEmployeeInEmployeesCollection = async (role, refId, userId) => {
    let employeeDoc;
    if (role === "center") {
      const employeeRef = doc(db, "centers", refId, "employees", userId);
      employeeDoc = await getDoc(employeeRef);
      console.log("employeeDoc", employeeDoc.data());
      return { ...employeeDoc.data(), id: employeeDoc.id };
    } else {
      const employeeRef = doc(db, "partners", refId, "employees", userId);
      employeeDoc = await getDoc(employeeRef);
      return { ...employeeDoc.data(), id: employeeDoc.id };
    }
  };

  const getUserbyId = async (id) => {
    const userRef = doc(db, "users", id);
    const userDoc = await getDoc(userRef);
    return userDoc.data();
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      if (!validatePassword()) return;

      const userData = await getUserData();
      if (!userData) return;

      // Move status check before authentication
      if (userData.status === "DUPLICATE_ADDRESS_UNVERIFIED") {
        setEmailfunc(email.toLowerCase());
        navigate("/addressverify", { state: { email: email } });
        return;
      }

      if (await checkStatus(userData)) return;

      const authResult = await authenticateUser(userData);
      if (!authResult) return;

      // Update user password if it's not set
      await updateUserPassword(userData);

      const { user, userCredential } = authResult;

      if (await handleDuplicateAddressScenario(user)) return;

      await handleNavigation(user);
    } catch (err) {
      handleLoginError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper functions
  const validatePassword = () => {
    if (password.length === 0) {
      setPopupMessage("Password cannot be blank");
      return false;
    }
    return true;
  };

  const getUserData = async () => {
    const userData = await getUserByEmailorDarciIdorMobile(email);
    if (!userData) {
      setPopupMessage(
        "Account not found. Please make sure you have registered."
      );
      setIsLoading(false);
      return null;
    }
    return userData;
  };

  const checkStatus = async (userData) => {
    if (userData.status === UserStatus.EMAIL_UNVERIFIED) {
      setEmailfunc(email.toLowerCase());
      navigate("/register2", { state: { email: email } });
      return true;
    } else if (
      userData.status === UserStatus.EMAIL_VERIFIED &&
      userData.user_role === "citizen"
    ) {
      navigate("/completeyourprofile", { state: { userData: userData } });
      return true;
    }
  };

  // Helper function for email/ID validation
  const validateEmailOrId = (input) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const darciIdPattern = /^\d{10}$/;
    //const mobilePattern = /^\d{3}$/;

    return {
      isValid: emailPattern.test(input) || darciIdPattern.test(input),
      //mobilePattern.test(input),
      errorMessage: "Please enter a valid email address or 10-digit DARCI ID",
    };
  };

  // Helper function for employee authentication
  const authenticateEmployee = async (userData, userCredential) => {
    const employee = await getUserByEmailorDarciIdorMobile(email);
    const orgData = await getUserbyId(employee.organization_user_id);

    if (decrypt(employee.password) !== password) {
      setPopupMessage("Incorrect password please try again");
      return null;
    }

    if (userData.darci_id !== email) {
      setPopupMessage(
        "Invalid credentials. For employee login, please use your DARCI ID and password."
      );
      return null;
    }

    setRoleBasedRoute(orgData.user_role);
    login(userCredential.user.accessToken);
    setUserIdToUse(employee.organization_user_id);
    sessionStorage.setItem("userId", employee.organization_user_id);
    sessionStorage.setItem("employeeDetails", JSON.stringify(employee));

    return { user: orgData, userCredential };
  };

  // Helper function for regular user authentication
  const authenticateRegularUser = async (user, userCredential) => {
    if (decrypt(user.password) !== password) {
      setPopupMessage("Incorrect password please try again");
      return null;
    }

    console.log("userrr", user);

    if (user.user_role === "center" || user.user_role === "partner") {
      const employee = await getEmployeeInEmployeesCollection(
        user.user_role,
        user[user.user_role === "center" ? "center_id" : "partner_id"],
        user.docId
      );

      sessionStorage.setItem("employeeDetails", JSON.stringify(employee));
      console.log(
        "employeeee details",
        JSON.parse(sessionStorage.getItem("employeeDetails"))
      );
    }

    setRoleBasedRoute(user.user_role);
    login(userCredential.user.accessToken);
    setUserIdToUse(user.docId);
    sessionStorage.setItem("userId", user.docId);

    return { user, userCredential };
  };

  // Update the authenticateUser function to use the new helpers
  const authenticateUser = async (userData) => {
    try {
      // Check for DUPLICATE_ADDRESS_UNVERIFIED status before authentication
      if (userData.status === "DUPLICATE_ADDRESS_UNVERIFIED") {
        setEmailfunc(email.toLowerCase());
        navigate("/addressverify", { state: { email: email } });
        return null;
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        config.bluesky_email_auth,
        config.bluesky_password
      );

      return userData.user_role === "employee"
        ? authenticateEmployee(userData, userCredential)
        : authenticateRegularUser(
            await getUserByEmailorDarciIdorMobile(email),
            userCredential
          );
    } catch (error) {
      setPopupMessage("Incorrect password please try again");
      console.log("error", error);
      return null;
    }
  };

  const updateUserPassword = async (user) => {
    const userRef = doc(db, "users", user.docId);
    const userDoc = await getDoc(userRef);
    if (!userDoc.data().password) {
      await updateDoc(userRef, { password: encrypt(password) });
    }
  };

  const handleDuplicateAddressScenario = async (user) => {
    if (isDupAddress && encodedData) {
      const verificationData = JSON.parse(atob(encodedData));
      if (verificationData.original_email === email) {
        await handleDupAddressLogin();
      } else if (
        verificationData.fraud_email === email &&
        user.dup_address_verified
      ) {
        setEmailfunc(email.toLowerCase());
        navigate("/addressverify", { state: { email: email } });
        return true;
      }
    } else if (user.status === "DUPLICATE_ADDRESS_UNVERIFIED") {
      setEmailfunc(email.toLowerCase());
      navigate("/addressverify", { state: { email: email } });
      return true;
    }
    return false;
  };

  const handleNavigation = async (user) => {
    try {
      // If status is ACTIVE, go to home
      if (user.status === "ACTIVE" && user.status !== "admin") {
        return navigate("/home");
      }

      if (user.status === "DUPLICATE_ADDRESS_UNVERIFIED") {
        setEmailfunc(email.toLowerCase());
        navigate("/addressverify", { state: { email: email } });
        return true;
      }

      // If status is ADDRESS_VERIFIED or DUPLICATE_ADDRESS_VERIFIED,
      // route to respective profile pages based on user role
      if (
        (user.status === "ADDRESS_VERIFIED" ||
          user.status === "DUPLICATE_ADDRESS_VERIFIED") &&
        user.user_role === "citizen"
      ) {
        const roleRoutes = {
          partner: "/partnerprofile",
          citizen: "/citizenprofile",
          center: "/centerprofile",
          admin: "/center",
          employee: "/home",
        };
        return navigate(roleRoutes[user.user_role] || "/home");
      }

      // Handle certification checks for partner and center roles
      if (user.user_role === "partner" && user.partner_id) {
        const partnerDoc = await getDoc(doc(db, "partners", user.partner_id));
        if (!partnerDoc.exists()) {
          console.error("Partner document not found");
          return navigate("/partnerprofile");
        }
        return navigate(
          partnerDoc.data().is_certified ? "/home" : "/partnerprofile"
        );
      }

      if (user.user_role === "center" && user.center_id) {
        const centerDoc = await getDoc(doc(db, "centers", user.center_id));
        if (!centerDoc.exists()) {
          console.error("Center document not found");
          return navigate("/centerprofile");
        }
        return navigate(
          centerDoc.data().is_certified ? "/home" : "/centerprofile"
        );
      }

      // Default navigation
      return navigate("/home");
    } catch (error) {
      console.error("Navigation error:", error);
      navigate("/home");
    }
  };

  const handleLoginError = (err) => {
    console.error("Login error:", err);
    const attemptsLeft = attempts - 1;
    setAttempts(attemptsLeft);

    const errorMessages = {
      "auth/invalid-email": `Invalid email format. You have ${attemptsLeft} attempts remaining before your account is disabled.`,
      "auth/user-not-found": `User not found. Please make sure you have registered.`,
      "auth/wrong-password": `Incorrect password. You have ${attemptsLeft} attempts remaining before your account is disabled.`,
      "auth/too-many-requests":
        "Account has been disabled due to too many failed attempts. Please contact support.",
      "auth/missing-password": "Password cannot be blank",
      "auth/invalid-credential": `Invalid credentials provided. You have ${attemptsLeft} attempts remaining before your account is disabled.`,
    };

    setPopupMessage(
      errorMessages[err.code] ||
        "Account not found. Please make sure you have registered."
    );
    setIsLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePopupClose = () => {
    setPopupMessage("");
    setIsLoading(false);
  };

  // Update the onContinue function to use the new validator
  const onContinue = () => {
    const { isValid, errorMessage } = validateEmailOrId(email);

    if (email && isValid) {
      setShowPasswordSection(true);
    } else {
      setPopupMessage(errorMessage);
    }
  };

  const handleEditEmail = () => {
    setShowPasswordSection(false);
  };

  const handleDupAddressLogin = async () => {
    console.log("handleDupAddressLogin - Current state:", {
      isDupAddress,
      dupAddressData,
      email,
    });

    if (isDupAddress && dupAddressData && email) {
      try {
        console.log(
          "Processing duplicate address login with data:",
          dupAddressData
        );

        if (dupAddressData.original_email !== email) {
          console.log("User is not authorized to verify this address");
          setPopupMessage("You are not authorized to verify this address");
          return;
        }

        // Update original user
        const userQuery = query(
          collection(db, "users"),
          where("email", "==", email)
        );
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
          const userDoc = userSnapshot.docs[0];
          await updateDoc(userDoc.ref, {
            dup_address: true,
            dup_address_owner: true,
            dup_address_token: dupAddressData.token,
          });
          console.log("Updated original user:", email);
        }

        // Update fraud user if they exist
        const fraudUserQuery = query(
          collection(db, "users"),
          where("email", "==", dupAddressData.fraud_email)
        );

        const fraudUserSnapshot = await getDocs(fraudUserQuery);
        if (!fraudUserSnapshot.empty) {
          const fraudUserDoc = fraudUserSnapshot.docs[0];
          await updateDoc(fraudUserDoc.ref, {
            disabled: true,
            status: "DUPLICATE_ADDRESS_UNVERIFIED",
            dup_address: true,
            dup_address_owner: false,
            dup_address_token: dupAddressData.token,
            dup_address_otp: dupAddressData.otp,
          });
          console.log("Updated fraud user:", dupAddressData.fraud_email);
        }

        // Clear the context data after processing
        clearDupAddress();
      } catch (error) {
        console.error("Error in handleDupAddressLogin:", error);
        setPopupMessage("An error occurred during address verification");
      }
    } else {
      console.log("Missing required data:", {
        isDupAddress,
        hasDupAddressData: !!dupAddressData,
        hasEmail: !!email,
      });
    }
  };

  return (
    <div className="viewport h-screen w-full overflow-hidden ">
      {popupMessage && (
        <Popup
          title={"Notification"}
          message={popupMessage}
          onClose={handlePopupClose}
        />
      )}
      <div className="viewport-bg flex h-full w-full flex-col items-center justify-center bg-[rgb(225,190,116)]/[0.13]">
        <div className="flex rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)]">
          <div className="w-[350px] rounded-l-lg bg-white">
            <h5 className="mt-12 flex flex-col items-center justify-center">
              <img
                loading="lazy"
                src={blueSkyLogo}
                className="aspect-[0.99] w-[90px] object-contain"
                alt="DARCI logo"
              />
              <label className="login-logo-label mt-2.5">DARCI</label>
            </h5>
            <div className="z-0 mt-1 flex w-[483px] max-w-full flex-col items-center justify-center">
              <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                <div className="text-xl leading-none">
                  <span className="text-sm text-blue-500">
                    Disaster Aware & Ready Communities
                  </span>
                  {/* <span className="text-base text-blue-500">BlueSky</span> */}
                </div>
              </div>
            </div>

            <div className="z-0 flex w-[483px] max-w-full flex-col items-center justify-center px-5">
              <div className="flex w-full flex-col gap-[5px]">
                <p className="my-[10px] text-center text-[20px] font-bold text-[#3D435A]">
                  Sign In
                </p>

                {showPasswordSection ? (
                  <>
                    <div className="relative -mt-2">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <img
                            loading="lazy"
                            src={userIcon}
                            className="aspect-[0.99] w-[25px] object-contain"
                          />
                          <div className="ml-1">
                            <p className="max-md:max-w-full whitespace-nowrap text-xs font-bold text-blue-600">
                              {email}
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={handleEditEmail}
                          className="text-bold bg-white text-xs text-blue-500"
                        >
                          <FaEdit className="h-3 w-3" />
                        </button>
                      </div>
                    </div>
                    <div className="relative mt-2">
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] font-[Inter] focus:outline-none"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleLogin();
                          }
                        }}
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer !font-[Inter] text-blue-500"
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="relative">
                    <input
                      placeholder="Enter username or email"
                      type="text"
                      className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] font-[Inter] focus:outline-none"
                      value={email}
                      onChange={(e) => {
                        console.log(decrypt(e.target.value));
                        setEmail(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onContinue();
                        }
                      }}
                    />
                  </div>
                )}

                <div className="mt-2 flex w-full flex-col gap-[10px]">
                  <button
                    className="bg-login-btn h-10 w-full rounded-[4px] py-[10px] font-[Inter] hover:cursor-pointer"
                    onClick={showPasswordSection ? handleLogin : onContinue}
                  >
                    {isLoading
                      ? "Please wait..."
                      : showPasswordSection
                      ? "Continue"
                      : "Continue"}
                  </button>
                  <div className="flexw-full text-center">
                    <p className="w-full text-center ">
                      Not yet registered?
                      <Link
                        className="ml-2  text-base text-blue-500 no-underline hover:cursor-pointer"
                        to="/registration"
                      >
                        Register
                      </Link>
                    </p>
                  </div>
                  <div className="flex w-full gap-0.5 text-center">
                    <p className="w-full text-center ">
                      <Link
                        to="/forgotpassword"
                        className="text-[#348BFF] no-underline hover:cursor-pointer"
                      >
                        Forgot Password
                      </Link>
                    </p>
                  </div>

                  <div
                    className="my-5 justify-center text-center "
                    style={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    <a
                      className="text-[inherit] text-blue-500 [text-decoration:none]"
                      href="/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    <a>&nbsp;and&nbsp;</a>
                    <a
                      className="text-[inherit] text-blue-500 [text-decoration:none]"
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms & Conditions
                    </a>
                  </div>

                  {/*<div className="flex">
                          <button className="mr-2 h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
                              onClick={onLoginWithGoogle} > <
                              img alt="" src={google} />
                          </button>
                          <button className="h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer">
                              <img alt="" src={microsoft} />
                          </button>
                      </div>*/}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-login-right-panel block w-[350px] rounded-r-lg p-4 leading-4 mq700:hidden">
            <h3 className="pt-10 leading-relaxed text-white">
              Welcome to the DARCI Program, a disaster aware and community ready
              application.
              <br />
              <br />
              We are here to support your efforts in strengthening community
              preparedness and recovery.
              <br />
              <br />
              Access comprehensive tools, resources, and insights to enhance
              your leadership, response, and recovery capabilities.
              <br />
              <br />
            </h3>
          </div>
        </div>

        <div className="mt-5 text-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.blueskymobile"
            className="login-download-mobile text-blue-500 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Download DARCI on Android
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login1;
// ************************************THIS IS THE OLD CODE LOG IN PAGE****************************************
// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { auth } from "../firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { useAuth } from "../components/AuthContext";
// import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
// import note from "../../src/assets/note.png";
// import check from "../../src/assets/check.png";
// import umbrella from "../../src/assets/umbrella.png";
// import lightning from "../../src/assets/lightning.png";
// import fire from "../../src/assets/fire.png";
// import google from "../icons/login/google-icon.svg";
// import microsoft from "../icons/login/microsoft-icon.svg";

// const Login1 = ({ email, onEmailChange, onContinue, onLoginWithGoogle }) => {
//   return (
//     <div className="flex min-h-screen w-full items-center justify-center overflow-y-auto bg-zinc-50 px-8 !font-[Inter]">
//       <div className="!item-center login-form relative flex h-[80vh] w-[70vw] !justify-center overflow-hidden rounded-xl bg-white shadow-[0px_4px_9px_rgba(0,0,0,0.25)]">
//         <div className="flex w-2/3 flex-col items-center justify-center px-11 py-5 md:px-0">
//           <header className="text-3xl text-gray-700 z-0 flex w-[483px] max-w-full flex-col items-center justify-center whitespace-nowrap bg-white px-2 py-1.5 text-center font-bold leading-none tracking-wide">
//             <img
//               loading="lazy"
//               src={blueSkyLogo}
//               className="aspect-[0.99] w-[90px] object-contain"
//               alt="DARCI logo"
//             />
//             <label
//               className="mt-2.5 "
//               style={{ fontSize: "32px", fontWeight: 700 }}
//             >
//               DARCI
//             </label>
//           </header>
//           <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
//             <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
//               <div className="text-xl  leading-none">
//                 <span className="text-base">Powered by </span>
//                 <span className="text-base text-blue-500">BlueSky</span>
//               </div>
//             </div>
//           </div>

//           <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
//             <div className="flex w-full flex-col gap-[5px] pt-3">
//               <p className="my-[20px] text-center  text-[20px] font-bold text-[#3D435A]">
//                 Sign In
//               </p>
//               <input
//                 placeholder="Enter Email"
//                 type="text"
//                 className="h-[40px]  w-full border border-[#D5D7DB] p-[10px] pr-[40px] font-[Inter] focus:outline-none"
//                 name="email"
//                 value={email}
//                 onChange={(e) => onEmailChange(e.target.value)}
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter") {
//                     onContinue();
//                   }
//                 }}
//               />
//               {/* <div className="mt-[10px] flex items-center gap-[10px]">
//                       <input
//                           type="checkbox"
//                           id="rememberMe"
//                           className="w-5 h-5 rounded border border-solid border-zinc-300 min-h-[20px]"
//                       />
//                         <p className=" text-base text-[#D5D7DB]">Remember me</p>
//                     </div> */}
//               <div className="mt-2 flex w-full flex-col gap-[10px]">
//                 <button
//                   className="h-10 w-full rounded-[4px] bg-[#348BFF] py-[10px] font-[Inter]  text-base font-bold text-white hover:cursor-pointer"
//                   onClick={onContinue}
//                 >
//                   Continue
//                 </button>
//                 <div className="flexw-full text-center">
//                   <p className="w-full text-center ">
//                     Not yet registered?
//                     <Link
//                       className="ml-2  text-base text-blue-500 no-underline hover:cursor-pointer"
//                       to="/registerv2"
//                     >
//                       Register
//                     </Link>
//                   </p>
//                 </div>
//                 <div className="flex w-full gap-0.5 text-center">
//                   <p className="w-full text-center ">
//                     <Link
//                       to="/forgotpassword"
//                       className="text-[#348BFF] no-underline hover:cursor-pointer"
//                     >
//                       Forgot Password
//                     </Link>
//                   </p>
//                 </div>

//                 <div
//                   className="my-5 justify-center text-center "
//                   style={{ fontSize: "12px", fontWeight: 400 }}
//                 >
//                   <a
//                     className="text-[inherit] text-blue-500 [text-decoration:none]"
//                     href="/privacy"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     Privacy Policy
//                   </a>
//                   <a>&nbsp;and&nbsp;</a>
//                   <a
//                     className="text-[inherit] text-blue-500 [text-decoration:none]"
//                     href="/terms"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     Terms & Conditions
//                   </a>
//                 </div>
// {/*
//                 {/*<div className="flex">
//                           <button className="mr-2 h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
//                               onClick={onLoginWithGoogle} > <
//                               img alt="" src={google} />
//                           </button>
//                           <button className="h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer">
//                               <img alt="" src={microsoft} />
//                           </button>
//                       </div>*/} */}
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="block h-full w-1/3 bg-blue-500 px-5 py-5 md:hidden ">
//           <div
//             className="text-xl login-desc  text-white"
//             style={{ fontSize: "20px", fontWeight: 400 }}
//           >
//             Welcome to the DARCI Program, powered by Blue Sky.
//             <br />
//             <br />
//             We are here to support your efforts in strengthening community
//             preparedness and recovery.
//             <br />
//             <br />
//             Access comprehensive tools, resources, and insights to enhance your
//             leadership, response, and recovery capabilities.
//             <br />
//             <br />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Login1;
