import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  getDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDocs,
  collection,
  where,
  query,
  onSnapshot,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../service/data/DataService";

const DRCCenterPartnerships = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [centers, setCenters] = useState([]);
  const gridRef = useRef(null); // Add a reference to the grid
  const [showModal, setShowModal] = useState(false); // State for modal
  const [selectedCenter, setSelectedCenter] = useState(null); // Track selected center for modal
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const preprocessData = (data) => {
    return data.map((item) => ({
      ...item,
      name: `${item.first_name} ${item.last_name}`,
      address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip} ${item.county}`,
    }));
  };

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      console.log("User Doc 1:", userDocSnap.data());

      if (userDocSnap.exists()) {
        //get data of partner
        const userDocRef2 = doc(db, "partners", userDocSnap.data().partner_id);
        const userDocSnap2 = await getDoc(userDocRef2);
        console.log("User Doc 2:", userDocSnap2.data());
        setData({ id: userDocSnap2.id, ...userDocSnap2.data() });
        console.log("User Doccc:", userDocSnap2.data());
        if (userDocSnap2.data()) {
          console.log("Center IDd:", userDocSnap.data().center_id);
          const resp = await dataService.getUsersByCenterIdAndRole(
            userDocSnap.data().center_id
          );
          console.log("Fetched Response:", resp); // Log the fetched response

          if (Array.isArray(resp) && resp.length > 0) {
            setGridData(preprocessData(resp));
          } else {
            console.warn("No data found in response.");
          }

          const centers = await dataService.getAllCenters();

          const userCenterIds = userDocSnap.data().center_id || [];
          console.log("User Center IDs:", userCenterIds);
          const filteredCenters = centers.filter(
            (center) => !userCenterIds.includes(center.id)
          );

          setCenters(preprocessData(filteredCenters));
          console.log("Filtered Centers:", filteredCenters);
        } else {
          const centers = await dataService.getAllCenters();
          setCenters(preprocessData(centers));
          console.log("Centers:", centers);
        }
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "CheckBox",
  };

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };
  const [isGridVisible, setIsGridVisible] = useState(true);
  const toggleGrid = (event) => {
    event.preventDefault();
    setIsGridVisible(!isGridVisible);
  };

  const [isGridVisible2, setIsGridVisible2] = useState(true);
  const toggleGrid2 = (event) => {
    event.preventDefault();
    setIsGridVisible2(!isGridVisible2);
  };

  const generateUniqueID = () => {
    return (
      "_" + Date.now().toString(36) + Math.random().toString(36).substr(2, 12)
    );
  };

  // Fetch statuses from Firestore when component is mounted
  const [statuses, setStatuses] = useState({}); // Initially empty

  // Fetch statuses from Firestore when the component is mounted
  useEffect(() => {
    const fetchStatusesFromFirestore = () => {
      const unsubscribe = onSnapshot(
        collection(db, "partner-for-approval"),
        (snapshot) => {
          const fetchedStatuses = {};
          snapshot.forEach((doc) => {
            const data = doc.data();
            fetchedStatuses[data.center_id] = {
              partner_id: data.partner_id,
              status: data.status,
              center_id: data.center_id,
              is_approved: data.is_approved || false,
            };
          });
          setStatuses(fetchedStatuses); // Update the state

          if (gridRef.current) {
            gridRef.current.refresh(); // Refresh the grid after statuses are updated
          }
        },
        (error) => {
          console.error("Error fetching statuses from Firestore: ", error);
        }
      );
      return () => unsubscribe();
    };

    fetchStatusesFromFirestore(); // Fetch the statuses
  }, []);

  // This effect is now responsible only for listening to Firestore writes
  const updateFirestore = async (centerId, centerData) => {
    const { status, center_id } = centerData;
    console.log("centerid ", centerData);

    try {
      // First, check if a document exists for the given center_id
      const q = query(
        collection(db, "partner-for-approval"),
        where("center_id", "==", center_id),
        where("partner_id", "==", data.id) // Ensure data.id is defined
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Document exists, get the document ID and update it
        const existingDoc = querySnapshot.docs[0];
        const docId = existingDoc.id;

        if (status === "Applied") {
          // Update the status to "Applied" using the existing document ID
          await updateDoc(doc(db, "partner-for-approval", docId), {
            ...centerData,
            partner_id: data.id, // Make sure to keep data.id as the partner_id
            status: "Applied",
          });
        } else if (status === "Apply") {
          // If status is "Apply", update the document using the existing docId
          await updateDoc(doc(db, "partner-for-approval", docId), {
            status: "Apply",
          });
        }
      } else {
        // If document does not exist, create a new entry with random generated ID
        const newDocId = generateUniqueID(); // Generate a random document ID
        await setDoc(doc(db, "partner-for-approval", newDocId), {
          ...centerData,
          partner_id: data.id, // Store data.id as the partner_id in Firestore
          status: "Applied",
        });
      }
    } catch (error) {
      console.error("Error updating Firestore: ", error);
    }
  };

  const handleStatusToggle = async () => {
    if (!selectedCenter) return;
    const currentStatus = statuses[selectedCenter.id]?.status || "Apply";
    const newStatus = currentStatus === "Applied" ? "Apply" : "Applied";
    const updatedCenterData = {
      partner_id: statuses[selectedCenter.id]?.partner_id || generateUniqueID(),
      status: newStatus,
      center_id: selectedCenter.id,
      name_of_org: data.name_of_org,
      address: `${data.address1 || ""} ${data.address2 || ""} ${
        data.city || ""
      } ${data.state || ""} ${data.zip || ""} ${data.county || ""}`,
      email: data.email,
      mobile_number: data.mobile_number,
      name_of_hub: selectedCenter.name_of_hub,
      date_of_application:
        newStatus === "Applied"
          ? new Date()
          : statuses[selectedCenter.id]?.date_of_application || null,
    };
    await updateFirestore(selectedCenter.id, updatedCenterData);
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [selectedCenter.id]: updatedCenterData,
    }));
    setShowModal(false);
    setSelectedCenter(null);
  };

  // ... existing code ...
  const statusTemplate = useCallback(
    (rowData) => {
      const currentStatus = statuses[rowData.id];
      return (
        <div>
          {currentStatus?.status === "Applied" ? (
            <button
              className="e-btn e-flat e-success"
              onClick={() => confirmStatusToggle(rowData)}
            >
              Cancel
            </button>
          ) : (
            <button
              className="e-btn e-flat e-primary"
              onClick={() => confirmStatusToggle(rowData)}
            >
              Apply
            </button>
          )}
        </div>
      );
    },
    [statuses]
  );

  const confirmStatusToggle = (center) => {
    setSelectedCenter(center);
    setShowModal(true);
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          DRC Center Partnerships
        </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <h2 className="mb-1 text-sm font-bold text-blue-500">
            CURRENT CENTER PARTNERSHIPS
          </h2>
          {/* <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div> */}
        </div>

        {isGridVisible && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              ref={gridRef}
              key={JSON.stringify(statuses)} // This will force a re-render when gridData changes
              dataSource={gridData}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective field="id" headerText="" visible={false} />
                <ColumnDirective
                  field="name_of_hub"
                  headerText="DRC Center Partners"
                  width="200"
                />
                <ColumnDirective field="address" headerText="Address" />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="180"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="300"
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Edit, Toolbar, Sort, Filter, ColumnMenu]}
              />
            </GridComponent>
          </div>
        )}

        <div id="dialog-container"></div>
        <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <h2 className="mb-1 mt-4 text-sm font-bold text-blue-500">
            APPLY FOR AVAILABLE CENTERS
          </h2>
          {/* <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid2}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible2 ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div> */}
        </div>

        {isGridVisible2 && (
          <div className="flex flex-col gap-4 self-stretch">
            <GridComponent
              dataSource={centers}
              allowSorting={true}
              sortSettings={sortSettings}
              filterSettings={filterSettings}
              allowFiltering={true}
              toolbar={["Search"]}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name_of_hub"
                  headerText="Name of Center"
                  width="250"
                />
                <ColumnDirective field="address" headerText="Address" />
                <ColumnDirective
                  field="mobile_number"
                  headerText="Mobile Number"
                  width="180"
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  width="250"
                />
                <ColumnDirective
                  headerText="Status"
                  headerTextAlign="Center"
                  textAlign="Center"
                  width="120"
                  template={statusTemplate}
                />
              </ColumnsDirective>

              <Inject
                services={[
                  Page,
                  Edit,
                  Toolbar,
                  Sort,
                  Filter,
                  Search,
                  ColumnMenu,
                ]}
              />
            </GridComponent>
          </div>
        )}
      </section>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[440px] rounded-lg bg-white shadow-lg">
            <div className="flex items-center justify-between rounded-t-lg bg-[#467ba4] p-4">
              <p className="font-semibold text-[#fdb841]">
                {statuses[selectedCenter?.id]?.status === "Applied"
                  ? "Cancel"
                  : "Apply"}
              </p>
              <button
                className="cursor-pointer bg-[#467ba4] text-[20px] font-semibold text-[#fdb841]"
                onClick={() => setShowModal(false)}
              >
                ×
              </button>
            </div>
            <div className="space-y-2 p-4">
              Are you sure you want to{" "}
              {statuses[selectedCenter?.id]?.status === "Applied"
                ? "Cancel"
                : "Apply"}
              ?
            </div>
            <div className="my-4 flex justify-center space-x-5">
              <button
                onClick={handleStatusToggle}
                className="btn-default justify-center rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
              >
                Yes
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="btn-cancel hover:bg-gray-700 justify-center rounded bg-gray-500 px-4 py-2 text-white"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DRCCenterPartnerships;
