import React from "react";

const CustomTag = ({ title = "Default Address" }) => {
  return (
    <div
      className="rounded-full bg-very-light-pink px-3 py-1 text-red-wine"
      style={{ border: "1px solid #9D0F0F" }}
    >
      <p className="text-[12px] font-medium">{title}</p>
    </div>
  );
};

export default CustomTag;
