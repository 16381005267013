export const formatDate = (date) => {
  if (!date) return null;
  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatDateMMDDYYYY = (date) => {
  if (!date) return null;
  return new Date(date).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

export const imageNameFormatToDate = (name) => {
  if (!name) return null;
  const date = new Date().toISOString();
  const nameWithoutExtension = name.split(".")[0];
  const extension = name.split(".")[1];
  return `${nameWithoutExtension}_${date}.${extension}`;
};

export function isValidDate(dateString) {
  const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    
  if (!regex.test(dateString)) return false; // Check format M/D/YYYY

  const [month, day, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
  );
}
