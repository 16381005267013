import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import userIcon from "../../src/icons/Profile/userIcon.png";
import { decrypt, encrypt } from "../service/encryption/Encryption";

const Login2 = ({
  password,
  email,
  onPasswordChange,
  onLogin,
  onLoginWithGoogle,
  isLoading,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="viewport h-screen w-full overflow-hidden ">
      <div className="viewport-bg flex h-full w-full items-center justify-center bg-[rgb(225,190,116)]/[0.13]">
        <div className="flex  rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)] ">
          <div className="  w-[350px] rounded-l-lg bg-white  ">
            <h5 className="mt-12 flex flex-col items-center justify-center">
              <img
                loading="lazy"
                src={blueSkyLogo}
                className="aspect-[0.99] w-[90px] object-contain"
                alt="DARCI logo"
              />
              <label className="login-logo-label mt-2.5">DARCI</label>
            </h5>
            <div className="z-0 mt-1 flex w-[483px] max-w-full flex-col items-center justify-center">
              <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                <div className="text-xl  leading-none">
                  <span className="text-sm text-blue-500">
                    Disaster Aware & Ready Communities
                  </span>
                  {/* <span className="text-base text-blue-500">
                    Disaster Aware and Ready Communities
                  </span> */}
                </div>
              </div>
            </div>

            <div className="z-0 flex w-[483px] max-w-full flex-col items-center justify-center px-5">
              <div className="flex w-full flex-col gap-[5px]">
                <p className="my-[10px] text-center  text-[20px] font-bold text-[#3D435A]">
                  Sign In
                </p>
                <div className="-mt-2 mb-2">
                  <div className="flex items-center justify-center">
                    <img
                      loading="lazy"
                      src={userIcon}
                      className="aspect-[0.99] w-[40px] object-contain"
                    />
                    <div className="ml-3">
                      <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                        {email}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <input
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    className="h-[40px] w-full border border-[#D5D7DB] p-[10px]  pr-[40px] font-[Inter] focus:outline-none"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      onPasswordChange(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onLogin();
                      }
                    }}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer !font-[Inter] text-blue-500"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {/* <div className="mt-[10px] flex items-center gap-[10px]">
                    <input
                        type="checkbox"
                        id="rememberMe"
                        className="w-5 h-5 rounded border border-solid border-zinc-300 min-h-[20px]"
                    />
                      <p className=" text-base text-[#D5D7DB]">Remember me</p>
                  </div> */}
                <div className="mt-2 flex w-full flex-col gap-[10px]">
                  <button
                    className="bg-login-btn h-10 w-full rounded-[4px] py-[10px] font-[Inter] hover:cursor-pointer"
                    onClick={onLogin}
                  >
                    {isLoading ? "Please wait..." : "Continue"}
                  </button>
                  <div className="flexw-full text-center">
                    <p className="w-full text-center ">
                      Not yet registered?
                      <Link
                        className="ml-2  text-base text-blue-500 no-underline hover:cursor-pointer"
                        to="/registration"
                      >
                        Register
                      </Link>
                    </p>
                  </div>
                  <div className="flex w-full gap-0.5 text-center">
                    <p className="w-full text-center ">
                      <Link
                        to="/forgotpassword"
                        className="text-[#348BFF] no-underline hover:cursor-pointer"
                      >
                        Forgot Password
                      </Link>
                    </p>
                  </div>

                  <div
                    className="my-5 justify-center text-center "
                    style={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    <a
                      className="text-[inherit] text-blue-500 [text-decoration:none]"
                      href="/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    <a>&nbsp;and&nbsp;</a>
                    <a
                      className="text-[inherit] text-blue-500 [text-decoration:none]"
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms & Conditions
                    </a>
                  </div>

                  {/*<div className="flex">
                        <button className="mr-2 h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer"
                            onClick={onLoginWithGoogle} > <
                            img alt="" src={google} />
                        </button>
                        <button className="h-10 rounded-[4px] border border-[#D5D7DB] bg-[#FFFFFF] p-[10px] text-base font-bold text-white hover:cursor-pointer">
                            <img alt="" src={microsoft} />
                        </button>
                    </div>*/}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-login-right-panel block w-[350px] rounded-r-lg p-4 leading-4 mq700:hidden  ">
            <h3 className="pt-10 leading-relaxed text-white">
              Welcome to the DARCI Program, powered by Disaster Aware and Ready
              Communities.
              <br />
              <br />
              We are here to support your efforts in strengthening community
              preparedness and recovery.
              <br />
              <br />
              Access comprehensive tools, resources, and insights to enhance
              your leadership, response, and recovery capabilities.
              <br />
              <br />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login2;
