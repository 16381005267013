import React, { useState, useEffect, useRef } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "./datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "./style.css";
import citizenProfileService from "../service/citizen/CitizenProfile";
import HeaderTitle from "../components/HeaderTitle";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const editOptions = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const sortSettings = {
  allowSorting: true,
};
const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 15 };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Name: `${item.first_name} ${item.last_name}`,
    Address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip}`,
  }));
};

const CommunityContacts = ({ className = "" }) => {
  const [commContacts, setCommContacts] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userCenterId, setUserCenterId] = useState(null); // New state for center_id
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true); // Set loading to true before fetching
      if (userCenterId) {
        // Fetch center user document
        const centerUserQuery = query(
          collection(db, "users"),
          where("user_role", "==", "center"),
          where("center_id", "==", userCenterId)
        );
        const centerUserSnapshot = await getDocs(centerUserQuery);
        let centerUserDocId = null;
        if (!centerUserSnapshot.empty) {
          const centerUser = centerUserSnapshot.docs[0];
          centerUserDocId = centerUser.id;
        }
        // Only fetch contacts if centerId is available
        const querySnapshot = await getDocs(
          collection(db, "community-contacts")
        );
        const contactsData = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (contact) =>
              (contact.contactID === centerUserDocId ||
                contact.user_id === centerUserDocId) &&
              contact.is_deleted === false
          ); // Filter by centerId
        setCommContacts(preprocessData(contactsData));
        console.log("contactsData", contactsData);
      }
      setLoading(false); // Set loading to false after fetching
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && selectedUserId) {
        setUserId(selectedUserId);
        console.log(selectedUserId);
        const citizenProfile = await citizenProfileService.getCitizenProfile(
          "users",
          selectedUserId
        );
        console.log("citizenProfile", citizenProfile);
        // Fetch user data to get center_id
        if (citizenProfile && citizenProfile.center_id) {
          const userDoc = await getDoc(
            doc(db, "centers", citizenProfile.center_id)
          );
          if (userDoc.exists()) {
            setUserCenterId(userDoc.id); // Store the document ID directly
          } else {
            console.log("User document does not exist");
            setUserCenterId(null);
          }
        }

        fetchContacts(); // Fetch contacts after setting centerId
      } else {
        setUserId(null);
        setCommContacts([]);
        setUserCenterId(null); // Reset centerId on logout
      }
    });

    return () => unsubscribe();
  }, [userCenterId]); // Dependency on centerId

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const actionBegin = async (args) => {
    if (gridRef.current) {
      try {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = true;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = false;
            }
          }
        }
        if (args.requestType === "save") {
          setLastEditedTime(new Date());
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = true;
            }
          }

          // Update Name and Address fields
          const data = args.data;
          data.Name = `${data.first_name} ${data.last_name}`;
          data.Address = `${data.address1 || ""} ${data.address2 || ""} ${
            data.city || ""
          } ${data.state || ""} ${data.zip || ""}`;

          // Remove undefined fields
          Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
              delete data[key];
            }
          });

          if (args.action === "add") {
            // Adding new document
            await addDoc(collection(db, "community-contacts"), {
              ...data,
              contactID: data.contactID || generateContactID(), // Ensure contactID is included
              last_updated: serverTimestamp(),
              inserted_at: serverTimestamp(),
              user_id: userId,
            });
          } else if (args.action === "edit") {
            // Updating existing document
            if (data.id) {
              const contactDoc = doc(db, "community-contacts", data.id);
              await updateDoc(contactDoc, {
                ...data,
                contactID: data.contactID, // Ensure contactID is included
                last_updated: serverTimestamp(),
              });
            } else {
              console.error("Document ID is missing for update.");
            }
          }
        }
        if (args.requestType === "delete") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "first_name",
                "last_name",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = false;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = true;
            }
          }
          const data = args.data[0];
          if (data.id) {
            const contactDoc = doc(db, "community-contacts", data.id);
            await deleteDoc(contactDoc);
          } else {
            console.error("Document ID is missing for delete.");
          }
        }
      } catch (error) {
        console.error("Error during actionBegin:", error);
      }
    }
  };

  // Helper function to generate a unique contactID if not provided
  const generateContactID = () => {
    return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  return (
    <>
      <div
        className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
      >
        <HeaderTitle id="communitycontacts" title="Community Contacts" />
        <section className="w-full rounded-3xs pb-2 text-left text-xs text-darkslateblue">
          <div>
            {loading ? ( // Conditional rendering for loading state
              <div className="text-center">Loading...</div> // Loading message or spinner
            ) : (
              <GridComponent
                dataSource={commContacts}
                filterSettings={filterSettings}
                allowFiltering={true}
                allowSorting={true}
                ref={gridRef}
                sortSettings={sortSettings}
                allowPaging={true}
                pageSettings={pageSettings}
                showColumnMenu={true}
                columnMenuItems={columnMenuItems}
                cssClass="new-grid-color"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="contactID"
                    headerText="Contact ID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="first_name"
                    headerText="First Name"
                    visible={false}
                  />
                  <ColumnDirective
                    field="last_name"
                    headerText="Last Name"
                    visible={false}
                  />
                  <ColumnDirective field="Name" headerText="Name" width="150" />
                  <ColumnDirective
                    field="occupation"
                    headerText="Occupation"
                    width="100"
                  />
                  <ColumnDirective
                    field="mobileNumber"
                    headerText="Mobile Number"
                    width="100"
                  />
                  <ColumnDirective
                    field="email"
                    headerText="Email Address"
                    width="150"
                  />

                  <ColumnDirective
                    field="state"
                    headerText="State"
                    visible={false}
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: USStates,
                        fields: { text: "abbreviation", value: "abbreviation" },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="zip"
                    headerText="Zip"
                    visible={false}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Filter, Sort, Page, CommandColumn, ColumnMenu]}
                />
              </GridComponent>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default CommunityContacts;
