
const PdfViewer = ({ fileUrl }) => {

  return (
    <div className="flex flex-col items-center w-full h-full">
      {/* PDF Viewer */}
      <iframe
        src={`${fileUrl}#page=${1}&toolbar=0&view=FitH&navpanes=0`}
        className="flex-1 h-full e-scroll-hidden w-[105%]"
        style={{ overflow: "hidden", border: "none" }}
      />
    </div>
  );
};

export default PdfViewer;
