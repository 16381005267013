import React, { useState, useRef, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Page,
  Sort,
  Filter,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import CenterService from "../../service/center/CenterService";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import dataService from "../../service/data/DataService";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FaPlus } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";
import { deleteDoc } from "firebase/firestore";
import { IoMdClose } from "react-icons/io";
import calendar from "../../assets/calendar.svg";
import recommend from "../../assets/recommend.svg";
import mail from "../../assets/mail.svg";
import membership from "../../assets/membership.svg";
import ConfirmationModal from "../../components/ConfirmationModal";

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 18 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "CheckBox",
};

const generateRandomPassword = (length = 8) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

// Create a new component for the number display
const NumberDisplay = ({ value, column, center_id, name_of_hub }) => {
  const [showPartners, setShowPartners] = useState(false);

  if (column.field === "number_of_partners" && value > 0) {
    return (
      <>
        <div
          className="cursor-pointer text-blue-600 hover:text-blue-800 hover:underline"
          onClick={() => setShowPartners(true)}
        >
          {value}
        </div>
        <PartnersList
          isOpen={showPartners}
          onClose={() => setShowPartners(false)}
          centerId={center_id}
          name_of_hub={name_of_hub}
        />
      </>
    );
  }
  return value || 0;
};

// Modify the numberTemplate to use the new component
const numberTemplate = (props) => {
  return (
    <NumberDisplay
      value={props[props.column.field]}
      column={props.column}
      center_id={props.id}
      name_of_hub={props.name_of_hub}
    />
  );
};

const preprocessData = async (data) => {
  return data.map((item) => ({
    ...item,
    name: `${item.first_name} ${item.last_name}`,
    address: `${item.address1} ${item.address2} ${item.city} ${item.state} ${item.zip} ${item.county}`,
    is_approved: item.is_approved || false,
    is_certified: item.is_certified || false,
  }));
};

const checkIfEmailExists = async (email) => {
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);

  return !querySnapshot.empty;
};

const checkboxTemplate = (props) => {
  return <input type="checkbox" checked={props.is_approved} disabled />;
};

const PasswordTemplate = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(props.password || "");

  useEffect(() => {
    setPassword(props.password || "");
  }, [props.password]);

  const handleChange = (e) => {
    setPassword(e.value);
    if (props.onChange) {
      props.onChange({ ...e, value: e.value });
    }
  };

  return (
    <div className="relative w-full">
      <TextBoxComponent
        placeholder="Password"
        floatLabelType="Always"
        type={showPassword ? "text" : "password"}
        value={password}
        change={handleChange}
        name="password"
      />
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
  );
};

const certifiedTemplate = (props) => {
  return props.is_certified ? "Yes" : "No";
};

const dateTemplate = (props) => {
  if (props.created_date) {
    const date = props.created_date.toDate();
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }
  return "";
};

const ActionButton = (props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleStatusUpdate = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    try {
      const docRef = doc(db, "centers", props.id);
      await updateDoc(docRef, {
        is_certified: !props.is_certified, // Toggle the certification status
      });

      // Get the grid instance
      const gridInstance = document.querySelector(".e-grid").ej2_instances[0];

      // Update the local data - only modify the specific row
      const updatedData = gridInstance.getCurrentViewRecords().map((item) => {
        if (item.id === props.id) {
          return {
            ...item,
            is_certified: !props.is_certified,
          };
        }
        return item;
      });

      // Update the grid's data source and refresh
      gridInstance.dataSource = updatedData;
      gridInstance.refresh();
    } catch (error) {
      console.error("Error updating certification status:", error);
    } finally {
      setShowConfirmation(false);
    }
  };

  return (
    <>
      <div className="flex gap-2">
        <button
          onClick={handleStatusUpdate}
          className={`text-lg rounded px-2 py-1 text-white ${
            props.is_certified
              ? "bg-red-500 hover:cursor-pointer hover:bg-red-600"
              : "bg-green-500 hover:cursor-pointer hover:bg-green-600"
          }`}
        >
          {props.is_certified ? "Uncertify" : "Certify"}
        </button>
      </div>
      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirm}
        title={props.is_certified ? "Uncertify Center" : "Certify Center"}
        message={`Are you sure you want to ${
          props.is_certified ? "uncertify" : "certify"
        } this center?`}
      />
    </>
  );
};

const PartnersList = ({ isOpen, onClose, centerId, name_of_hub }) => {
  const [partners, setPartners] = useState([]);
  const panelRef = useRef(null);

  useEffect(() => {
    // Add click outside handler
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    const fetchPartners = async () => {
      if (centerId) {
        try {
          // Get all partners from users collection where their center_id array contains this center's ID
          const partnersQuery = query(
            collection(db, "users"),
            where("user_role", "==", "partner"),
            where("center_id", "array-contains", centerId)
          );
          const partnerSnapshot = await getDocs(partnersQuery);

          // Get partner details from both collections
          const partnerDetailsPromises = partnerSnapshot.docs.map(
            async (userDoc) => {
              const userData = userDoc.data();

              // Get additional details from partners collection using partner_id
              const partnerDocRef = doc(db, "partners", userData.partner_id);
              const partnerDoc = await getDoc(partnerDocRef);

              return {
                ...userData, // base user data (including created_date)
                ...(partnerDoc.exists() ? partnerDoc.data() : {}), // additional partner details
              };
            }
          );

          const partnersWithDetails = await Promise.all(partnerDetailsPromises);
          setPartners(partnersWithDetails);
        } catch (error) {
          console.error("Error fetching partners:", error);
        }
      }
    };

    if (isOpen) {
      fetchPartners();
    }
  }, [centerId, isOpen]);

  if (!isOpen) return null;

  return (
    <div
      ref={panelRef}
      className="fixed inset-y-0 right-0 z-40 w-[450px] transform bg-white shadow-2xl transition-transform duration-500 ease-in-out"
      style={{ top: "6vh" }} // Add top offset to match navbar height
    >
      <div className="flex h-full flex-col">
        <div className="flex items-center justify-between border-b bg-[#EAEAEA] p-4">
          <h3 className="text-lg flex-1 truncate pr-8 text-start font-semibold">
            Partnerships of {name_of_hub}
          </h3>
          <div
            className="hover:text-gray-700 flex-shrink-0 text-[#6D1818] hover:cursor-pointer"
            onClick={onClose}
          >
            <IoMdClose className="h-5 w-5" />
          </div>
        </div>
        <div className="flex-1 overflow-y-auto px-4">
          {partners.length > 0 ? (
            <div className="space-y-3 py-2">
              {partners.map((partner, index) => (
                <div
                  key={index}
                  className="hover:bg-gray-50 flex flex-col rounded-lg border bg-[#FAFAFA] px-4 py-2 text-base shadow-[2px_2px_4px_2px_rgba(0,0,0,0.2)]"
                >
                  <div className="mb-1 flex items-start">
                    <span className="flex-1 break-words text-left font-medium ">
                      {partner.name_of_org}
                    </span>
                  </div>
                  <div className="flex flex-col gap-1 text-sm text-[#525252]">
                    <div className="flex items-center break-words text-center">
                      <img
                        src={mail}
                        alt="mail"
                        className="mr-2 inline-block h-[24px] w-[24px]"
                      />
                      {partner.email}
                    </div>

                    <div className="text-center text-sm">
                      <div className="flex items-center break-words">
                        <img
                          src={membership}
                          alt="membership"
                          className="mr-2 inline-block h-[24px] w-[24px]"
                        />
                        Certified:&nbsp;
                        <span
                          style={{
                            color: partner.is_certified ? "#0A8525" : "#770C0E",
                          }}
                        >
                          {partner.is_certified ? "Yes" : "No"}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center break-words">
                      <img
                        src={calendar}
                        alt="calendar"
                        className="mr-2 inline-block h-[25px] w-[25px]"
                      />
                      Date Created:&nbsp;
                      {partner.created_date
                        ? partner.created_date
                            .toDate()
                            .toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .split("/")
                            .reverse()
                            .join("-")
                        : ""}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="mt-4 text-center text-gray-500">No partners found</p>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = ``;

const Center = () => {
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  const [center, setCenter] = useState([]);
  const gridRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const selectedUserId = sessionStorage.getItem("userId");
        setUserId(selectedUserId);
        const fetchCenters = async () => {
          try {
            const centers = await CenterService.getAllCenters();
            const processedCenters = await preprocessData(centers);
            setCenter(processedCenters);
          } catch (error) {
            console.error("Error fetching centers: ", error);
          }
        };

        fetchCenters();
      } else {
        setCurrentUserData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const userData = await dataService.getUserProfile("users", userId);
    if (userData) {
      setCurrentUserData(userData);
    }
  };

  return (
    <>
      <style>{styles}</style>
      <div className={`flex max-w-full flex-col gap-5 px-2.5 py-[15px]`}>
        <div className="flex items-center gap-2 pt-2 text-xs text-darkslateblue">
          <span className="font-medium text-blue-500">Centers</span>
        </div>
      </div>
      <header className=" flex w-full flex-row items-center  justify-between self-stretch rounded-3xs px-2.5 text-left text-xs text-darkslateblue">
        <div>
          <h2 className="mb-1 text-sm font-bold uppercase text-blue-500">
            List of Centers
          </h2>
          {/* <p className="font-poppins text-xs font-medium text-gray-500">
            Last Edited: {formatLastEditedTime(lastEditedTime)}
          </p> */}
        </div>
        {/* <div className="flex items-center gap-2">
          <button
            type="button"
            className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
            onClick={handleAdd}
          >
            <FaPlus className="h-3 w-3" />
            Add
          </button>
        </div> */}
      </header>
      <div className="mt-3 w-full rounded-3xs px-2.5  text-left text-xs text-darkslateblue">
        <GridComponent
          ref={gridRef}
          dataSource={center.filter((item) => item.is_deleted !== true)}
          allowSorting={true}
          sortSettings={sortSettings}
          // editSettings={editing}
          filterSettings={filterSettings}
          allowFiltering={true}
          // actionBegin={actionBegin}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
          height="70vh"
        >
          <ColumnsDirective>
            <ColumnDirective
              field="center_id"
              headerText="Center ID"
              isPrimaryKey={true}
              visible={false}
            />
            {/* Basic Information */}
            <ColumnDirective
              field="name_of_hub"
              headerText="Name of Center"
              width="150"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="email"
              headerText="Email"
              width="180"
              validationRules={{ required: true }}
            />

            <ColumnDirective
              field="is_certified"
              headerText="Certified"
              width="100"
              template={certifiedTemplate}
            />

            {/* Statistics */}
            <ColumnDirective
              field="number_of_partners"
              headerText="Partners"
              width="100"
              template={numberTemplate}
              textAlign="Right"
              headerTextAlign="Left"
              style={{ marginRight: "5px" }}
            />
            <ColumnDirective
              field="number_of_citizens"
              headerText="Citizens"
              width="100"
              template={numberTemplate}
              textAlign="Right"
              headerTextAlign="Left"
              style={{ marginRight: "5px" }}
            />

            {/* Metadata - at the end */}
            <ColumnDirective
              field="created_date"
              headerText="Date Created"
              width="120"
              template={dateTemplate}
            />

            {/* Add this new column at the end */}
            <ColumnDirective
              field="actions"
              headerText="Actions"
              width="200"
              template={ActionButton}
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Edit, Sort, Filter, CommandColumn, ColumnMenu]}
          />
        </GridComponent>
      </div>
    </>
  );
};

export default Center;
