import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  deleteUser,
} from "firebase/auth";

const auth = getAuth();

const AdminDataService = {
  getEmployeeByEmail: async (adminId, email) => {
    try {
      const employeesRef = collection(db, `users/${adminId}/employees`);
      const q = query(employeesRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const employeeDoc = querySnapshot.docs[0];
        return { id: employeeDoc.id, ...employeeDoc.data() };
      } else {
        // No employee found with this email
        return null;
      }
    } catch (error) {
      console.error("Error getting employee by email:", error);
      return null;
    }
  },

  getEmployees: async (adminId) => {
    try {
      const employeesRef = collection(db, `users/${adminId}/employees`);
      const q = query(employeesRef, where("is_deleted", "==", false));
      const querySnapshot = await getDocs(q);
      const employees = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return employees;
    } catch (error) {
      return [];
    }
  },

  createEmployeeWithUserIdAdmin: async (userId, adminId, employeeData) => {
    try {
      const employeeRef = doc(db, `users/${adminId}/employees`, userId);
      await setDoc(employeeRef, employeeData);
      return userId;
    } catch (error) {
      console.error("Error creating employee:", error);
    }
  },

  updateEmployee: async (userId, adminId, updatedData) => {
    try {
      const employeeRef = doc(db, `users/${adminId}/employees`, userId);
      await updateDoc(employeeRef, updatedData);
    } catch (error) {
      console.error("Error updating employee:", error);
    }
  },

  // updateUsersCreatedAt: async () => {
  //   try {
  //     const usersRef = collection(db, "users");
  //     const userDates = {
  //       "5eZ6cALhYta5TkIGEN7uHyNBGUE3": new Date("2025-02-18"),
  //       kVvZ15p1Z7STZ2SasrjP67M1RUu1: new Date("2025-02-18"),
  //       HxIWRqf0XoN79jIhByeaFaB2A3D3: new Date("2025-02-17"),
  //       "7tzPfWHqzSRmB46ZA88ydrPXpzI2": new Date("2025-02-17"),
  //       i3Ei5Dl6CVQnEFpWgVtbakBFxof2: new Date("2025-02-17"),
  //       "5tuTAZEvYAfvkbzWQiNCgVpiGWq1": new Date("2025-02-14"),
  //       LeZewQjYovYi89Ew6pxBWD5s2br1: new Date("2025-02-14"),
  //       gnbH1y3Tzsev7SGzwYFfmSvbuAN2: new Date("2025-02-14"),
  //       oiM4EgcdFcfBcBqit87PeWVHkCQ2: new Date("2025-02-14"),
  //       MddtYomN4sZyjVrOkrkgviC0Saj1: new Date("2025-02-14"),
  //       u7vPSD3Yggf1EJUDe77zTr8pPyR2: new Date("2025-02-14"),
  //       sCSE8sqepuRxKEcXrjBjQziNzOJ3: new Date("2025-02-14"),
  //       MNQ2erf80jcNumDMHWFDqy5pZD22: new Date("2025-02-14"),
  //       Z8nJUFoOy9cEgP7NY6XFvp3mKA13: new Date("2025-02-14"),
  //       a9L4Z3pirkbMACMEGJ8mkstCYPd2: new Date("2025-02-14"),
  //       "0etKaKJssPf1q6boPCfjgDxG4T72": new Date("2025-02-12"),
  //       "9q8OFopbfwYX8x4DykOKG8CQPet2": new Date("2025-02-11"),
  //       z9E1EP1VKUQv2W8OtziD4YJFPrr1: new Date("2025-02-10"),
  //       euelSno7vcdaBPRy3ENwTNqjFAq1: new Date("2025-02-07"),
  //       W9gWVK7TnshtSn2VFMJgBfkwOHf1: new Date("2025-02-06"),
  //       Z6A2l8MLVNTbqLKaANXCz3w5Plb2: new Date("2025-02-05"),
  //       V7CoYPNQn7XJDS8n2KYpUYs9iGu1: new Date("2025-02-04"),
  //       RjvWtZKC0Lhop83G2xOblqcJmvi1: new Date("2025-02-04"),
  //       IJvA9S1tZuWsADQyxqr6quqSrEl1: new Date("2025-02-04"),
  //       nUfd5dGJFzZ9UlfxpoNdH3LMu8y1: new Date("2025-01-31"),
  //       SjNLe1vHa2TkgiacSno7LwCI4vj1: new Date("2025-01-31"),
  //       "6fembuoVFWVJMBLz2449rB0zrgf1": new Date("2025-01-31"),
  //       nKs30vKHofMAbjj14HsJI7uY1Xg2: new Date("2025-01-31"),
  //       s3fsjcCtbvUdL0zjJXFhA3aWGaN2: new Date("2025-01-31"),
  //       "3nlSjkabZ1g5uIqFyhjvdNXRNz42": new Date("2025-01-30"),
  //       kjBEDgLtQOZ1qXZp5GOdnZsejrQ2: new Date("2025-01-30"),
  //       mFon6kCXnLPOdmfK75x6H5ZVxfB3: new Date("2025-01-30"),
  //       d0R80nVBnJcZNHlQMRJ9AP6GhRw2: new Date("2025-01-30"),
  //       b1HrpDYPz3ZKyvIv4E4WX9B6cEk2: new Date("2025-01-25"),
  //       ILisIBUhcZVGMvZJnkA9v8F7Tqc2: new Date("2025-01-23"),
  //       t6fOLiv1L4TCJZIbe1cQ7CoHCGC2: new Date("2025-01-21"),
  //       bGp2G1dLHWWi7MkrBTP9LxZ9KhX2: new Date("2025-01-16"),
  //       Zns7W9IJT4bTFv05aqEzqeiHDwE2: new Date("2025-01-16"),
  //       lSxkfoVUfxVEm4IkfoTw9aMNDBZ2: new Date("2025-01-15"),
  //       roObKAXcrqOuPgB7PMzG9069hTq1: new Date("2025-01-15"),
  //       RYXxmXsvaWQgxxPgbtiKp8hFDX13: new Date("2025-01-15"),
  //       JSJ94rzlgrQU3zip0u7JzPUVzJk1: new Date("2025-01-14"),
  //       wGQWuWyxkoU6DozvXzqlyPteYWb2: new Date("2025-01-14"),
  //       wfJWom2Kj4frv6xuKqdVorgvDc52: new Date("2025-01-14"),
  //       "4q4TiPPHJ4OuTBgo0prjzAogd9t2": new Date("2025-01-13"),
  //       WNmrPZgqTlPlSJSqKBSFmfWZy4v2: new Date("2025-01-10"),
  //       PMSMGMv9fZPaBmoRPfSoJogCnhG2: new Date("2025-01-10"),
  //       "4UVrltYooregEpdtZ7M3T2C4IZp2": new Date("2025-01-10"),
  //       TJd5bpxTpWb8ELtf0IlYwM2I2JA2: new Date("2025-01-10"),
  //       lbLiYsmDC1gb0uKlHCzJkwJZb323: new Date("2025-01-10"),
  //       "86K9c0vD6oXDZYSuSKBWoG6EAL72": new Date("2024-11-19"),
  //       "0GoI1sDz79eedc8cC8Yg9p6s2KM2": new Date("2024-11-19"),
  //       eDCjWesuPqQKDLluVnc9FC1ovY22: new Date("2024-11-19"),
  //       E9X3MpeKxIWzuQvXP88BxBGYDqA3: new Date("2024-11-19"),
  //       Z1uZnsoszgbvhiNKvFnIzz4z1DS2: new Date("2024-11-19"),
  //       m06VdBi1mTcEGWypvzLAKS5f9IN2: new Date("2024-11-19"),
  //       L59SJvQ2EoMonCK8tKN7yztTgol2: new Date("2024-11-19"),
  //       lDcZgnWFt0PKTMZESwIkOZeW2h02: new Date("2024-11-19"),
  //       MVvw1UiRb9OQVRBU2rVhMIK0Kqk1: new Date("2024-11-19"),
  //       LMu6TJuBuvPSt7mh6Bi7G9PEpiP2: new Date("2024-11-15"),
  //       CozTZHKjVlS8v2fRBaC3amVX9OD2: new Date("2024-11-14"),
  //       cTZX1a8TDeOhq80wemv1luhCpsq1: new Date("2024-11-14"),
  //       x4yOvsIStoa7l3hEst94I3Zykwj1: new Date("2024-11-14"),
  //       zp7Q45xUSsdXCDXPorSkeW44cqE2: new Date("2024-11-14"),
  //       "81RQTSR5u1OtVJbCjK2X5Jgz6Fx1": new Date("2024-11-14"),
  //       XjFt4yaw4Ug462ekaoaBifCdecJ2: new Date("2024-11-13"),
  //       Rc21Aw9BH8byi4KStlTmrQPDVSG2: new Date("2024-11-13"),
  //       qLXBpe5YosRkRCAabVOhjHf3TxX2: new Date("2024-11-08"),
  //       sscXp0Knevd3ggaXZLTHzKr1pff1: new Date("2024-11-05"),
  //       SO1RpVviOOVJ3HXRsXdVYZHPly72: new Date("2024-10-28"),
  //       b0uOsdpsi9eyM5EMvoiakjhNyMa2: new Date("2024-10-21"),
  //       "6Xc7NiAmIhbn9cBHQyrce7DRboL2": new Date("2024-10-18"),
  //       BbOxo4mXmrT2D12J5xT12ild8kg1: new Date("2024-10-11"),
  //       r44hWysCl1M3IRaYALCwuMXBom22: new Date("2024-10-11"),
  //       QF1jVxon6tOoZJxvmOpx6WspnlE2: new Date("2024-10-11"),
  //       iQOIzLFTGuXhSAtZERCxPnTWvez1: new Date("2024-10-11"),
  //       Swm4XhKi7IdJj2xHBh7ffs3squy1: new Date("2024-10-11"),
  //       W8VzXDisIrbPAF3O06v09COxQBo2: new Date("2024-10-10"),
  //       r5fg2kGFjEXmaPXALdbMpXJz3wy2: new Date("2024-10-10"),
  //       "29MD6spuojOeWPFoN2ocdMIu9ZO2": new Date("2024-10-10"),
  //       aXmbziaQaNeXDccTGxQAf1tx0ys1: new Date("2024-10-10"),
  //       vbDWCWdyAoYpK0qjxnuGk3GzvHi2: new Date("2024-10-10"),
  //       RCt9Utp9JGVc38ZYiOTIwtQZtRz2: new Date("2024-10-09"),
  //       VxdryXqbYHOumrFD5EsIpAMP9Hp2: new Date("2024-10-09"),
  //       "7CbWzzHNcYWtLBhGmOVoWN4JUpI2": new Date("2024-10-09"),
  //       "3CEoL432IvVBM2GyCYE17ISoEzI2": new Date("2024-10-09"),
  //       "5ALSD6YzT2YMPRpZanafDSzlLYx1": new Date("2024-10-09"),
  //       pqU69Pl4CTcEzc6L3PUGuHlsX7f2: new Date("2024-10-09"),
  //       a0Z1YhP1LBhwNTbw4iGCo4SQOk82: new Date("2024-10-08"),
  //       Ohd16fL1YuWLDqdd5nQDK44L9lW2: new Date("2024-10-08"),
  //       P68vVuB214Xo16bquhlBn1NaDui2: new Date("2024-10-08"),
  //       P5gMiX2GF0bhAkUINwyapyRN4Ay1: new Date("2024-10-08"),
  //       IsRNckrviTbUHiXQE1TyYmMfXC12: new Date("2024-10-08"),
  //       g3zZwTD9iNbLyZB3N3Kf4KTrTDi1: new Date("2024-10-08"),
  //       rm6BMhRmXcdlYifLDVroXVYIgEI2: new Date("2024-10-08"),
  //       t9EXdSV7ANOlvP9tG9IFhJqk9z82: new Date("2024-10-08"),
  //       vmDqk4YnMsX4bKkCDIEZg0LVk7n2: new Date("2024-10-08"),
  //       "7yKyiegmZuOcQ3GjT0nXZEqA5a02": new Date("2024-10-08"),
  //       utM4C90cTFTNnFWaKumSf4FpzHw1: new Date("2024-10-08"),
  //       HQICR8tdYmbUITOPVlc5Kqt5gfo1: new Date("2024-10-08"),
  //       t3wC9jsWfVa6nZGKnjRHy6rO5PI3: new Date("2024-10-08"),
  //       JBLENSiJgdRxA3PEaybE3dFUvdS2: new Date("2024-10-08"),
  //       chctZA8ANIVSTzAI4EuopMJUG6r1: new Date("2024-10-08"),
  //       Qguo63WmR5S55dSFKGk5Q9QRh792: new Date("2024-10-07"),
  //       EWst5sxFDoRzOOqf28dTPHO1S9O2: new Date("2024-10-07"),
  //       DlbmhECnPMUV72iBIVQLm88EHeH2: new Date("2024-10-07"),
  //       J7AvNOFMxReyNKlgVICxHlEFq692: new Date("2024-10-07"),
  //       "07DPJ6NBARYfiS4NOU7b9UhXOY73": new Date("2024-10-07"),
  //       G28cNj5ca0TowQdKhlKbnvU5wDX2: new Date("2024-10-06"),
  //       kEpzoO2zEKYAb72SxlvauBR8uCt1: new Date("2024-10-06"),
  //       wcAGvWFwY5Mop4eOOaxn5VsCFlD2: new Date("2024-10-06"),
  //       HNAF2yP5C3ceETPpEweNlDGPPRO2: new Date("2024-10-02"),
  //       wjpgWb1bvuSyxINvd6lSwrSuxL12: new Date("2024-10-02"),
  //       "5Br6mF4rwwQNmIUXYQm3HyW7u7W2": new Date("2024-10-01"),
  //       "5xrTuXRQvdQzjOJOvRbxzfvWpmF3": new Date("2024-10-01"),
  //       vVergT6xiLTLwRF5Me4x6IhWymc2: new Date("2024-10-01"),
  //       XIw6JEeDFUcs8JpO2WEOQqWsiSU2: new Date("2024-09-30"),
  //       "6qCxeJzNs2YLm2KiRUHrigRLSvb2": new Date("2024-09-30"),
  //       q5iOEGaMdZSguZEiN0b70j0l9ZK2: new Date("2024-09-30"),
  //       WhN3d9aWzicMhNjBjDQ6i1kRM773: new Date("2024-09-30"),
  //       GPCVqZ6eLZbRCZY5gRxk3lQWaOa2: new Date("2024-09-30"),
  //       DhbZPBjujxSOxFuloFl9D4CUA0H2: new Date("2024-09-30"),
  //       Hzmzhu5jRLUP6CLxQxXQ9VzWdgE2: new Date("2024-09-30"),
  //       HXAOLHukFzdLxP2AW3A0ytJu6Kk2: new Date("2024-09-30"),
  //       swG4BVCapwXFuleSjQvZWcNIIQq1: new Date("2024-09-30"),
  //       "0bmhHN3TBAYjH72FGSxgxCONRF53": new Date("2024-09-30"),
  //       CLdakGJfDCNFGu9NXbuGZbseOiC3: new Date("2024-09-30"),
  //       "2VVR1vfGkEfLBfjCBV6HKGqUCRJ3": new Date("2024-09-30"),
  //       "24jtWSZcdEQIRpHQCgv761q38VU2": new Date("2024-09-30"),
  //       zZ0Z9FOpCubAMQED1rI5EUyJckF2: new Date("2024-09-30"),
  //       VUjOQmexpjYczaOBao1QGX8ELEX2: new Date("2024-09-28"),
  //       jptEGERmSoRLBo2H4Rxd1Ddxj0J2: new Date("2024-09-28"),
  //       tKdgBYHb5xbB9s8TExKDzkQptvA2: new Date("2024-09-23"),
  //       iraHkxZmmuP1oMH6r9Nrmh1OpLA2: new Date("2024-09-22"),
  //       UluTI2TdmmfJ3Gw3Qwyd8hE4bI52: new Date("2024-09-20"),
  //       YOaRd6GRdUPqO5nbvBFerGmSEOk1: new Date("2024-09-20"),
  //       rRWB11eeLXXxzeZ6jWGyXMxxWCD2: new Date("2024-09-20"),
  //       niNE2u167BZlCV8PBF2PwTIlYT23: new Date("2024-09-19"),
  //       ZAMqk2l44lgToQZ4JyquWtn44bk1: new Date("2024-09-19"),
  //       mjJhuXLAeIVy3rgCzbjwNsTYCCz1: new Date("2024-09-19"),
  //       W0tLLlbNWNR9RWkknHJFc9PoRpG3: new Date("2024-09-18"),
  //       "7cDq5F9kJ4YfGFSIF0C5pTMI1ZM2": new Date("2024-09-18"),
  //       "0oERWc2j8Fh6x44ahcKF6eU1I4F2": new Date("2024-09-18"),
  //     };

  //     // Process in batches of 500 to avoid overloading
  //     const batchSize = 500;
  //     const userEntries = Object.entries(userDates);

  //     for (let i = 0; i < userEntries.length; i += batchSize) {
  //       const batch = userEntries.slice(i, i + batchSize);
  //       const updatePromises = batch.map(([userId, date]) => {
  //         const userRef = doc(db, "users", userId);
  //         return updateDoc(userRef, {
  //           created_date: date, // using Date object directly since Timestamp is not defined
  //         });
  //       });

  //       await Promise.all(updatePromises);
  //       console.log(`Processed batch ${Math.floor(i / batchSize) + 1}`);
  //     }

  //     return true;
  //   } catch (error) {
  //     console.error("Error updating users created_at:", error);
  //     return false;
  //   }
  // },

  getDarciNo: async () => {
    try {
      const systemsCodeRef = collection(db, "systems_code");
      const q = query(systemsCodeRef, where("type", "==", "DarciNo"));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        return doc.data().value;
      }

      return null;
    } catch (error) {
      console.error("Error getting DarciNo:", error);
      return null;
    }
  },

  incrementDarciNo: async () => {
    try {
      const systemsCodeRef = collection(db, "systems_code");
      const q = query(systemsCodeRef, where("type", "==", "DarciNo"));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const currentValue = doc.data().value;

        await updateDoc(doc.ref, {
          value: currentValue + 1,
        });

        return currentValue + 1;
      }

      return null;
    } catch (error) {
      console.error("Error incrementing DarciNo:", error);
      return null;
    }
  },

  assignDarciIdsToUsers: async () => {
    try {
      // Get all users
      const usersRef = collection(db, "users");
      const usersSnapshot = await getDocs(usersRef);

      // Get current DarciNo
      const systemsCodeRef = collection(db, "systems_code");
      const q = query(systemsCodeRef, where("type", "==", "DarciNo"));
      const systemSnapshot = await getDocs(q);

      if (systemSnapshot.empty) {
        throw new Error("DarciNo not found in systems_code");
      }

      const systemDoc = systemSnapshot.docs[0];
      let currentDarciNo = systemDoc.data().value;

      // Process in batches to avoid overloading
      const batchSize = 500;
      const users = usersSnapshot.docs;

      for (let i = 0; i < users.length; i += batchSize) {
        const batch = users.slice(i, i + batchSize);
        const updatePromises = batch.map(async (userDoc) => {
          // Only update if darci_id doesn't exist
          if (!userDoc.data().darci_id) {
            currentDarciNo++;
            const userRef = doc(db, "users", userDoc.id);
            return updateDoc(userRef, {
              darci_id: currentDarciNo.toString(), // Convert to string
            });
          }
        });

        await Promise.all(updatePromises);

        // Update the systems_code after each batch
        await updateDoc(systemDoc.ref, {
          value: currentDarciNo,
        });

        console.log(`Processed batch ${Math.floor(i / batchSize) + 1}`);
      }

      return true;
    } catch (error) {
      console.error("Error assigning darci_ids:", error);
      return false;
    }
  },

  generateRandomStringForUid: (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  },

  createEmployeeInAdmin: async (userId, docId, employeeData) => {
    try {
      const employeeRef = doc(db, `users/${userId}/employees`, docId);
      await setDoc(employeeRef, employeeData);
      return docId;
    } catch (error) {
      console.error("Error creating employee in users:", error);
    }
  },

  updateEmployeeInAdmin: async (adminId, docId, updatedData) => {
    try {
      const employeeRef = doc(db, `users/${adminId}/employees`, docId);
      await updateDoc(employeeRef, updatedData);
    } catch (error) {
      console.error("Error updating employee in admin:", error);
    }
  },
  updateEmployeeInUsersCollection: async (docId, updatedData) => {
    try {
      const employeeRef = doc(db, "users", docId);

      await updateDoc(employeeRef, updatedData);
    } catch (error) {
      console.error("Error updating employee in users:", error);
    }
  },

  deleteEmployeeInAdmin: async (darciId, userId) => {
    try {
      const employeesRef = collection(db, `users/${userId}/employees`);
      const q = query(employeesRef, where("darci_id", "==", darciId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const employeeDoc = querySnapshot.docs[0];
        await updateDoc(employeeDoc.ref, { is_deleted: true });
      } else {
        console.error("Employee not found with darci_id:", darciId);
      }
    } catch (error) {
      console.error("Error deleting employee in admin:", error);
    }
  },
};

export default AdminDataService;
