import React from "react";
import { FaPlus } from "react-icons/fa";

const AddNewButton = ({ onClick, name }) => {
  return (
    <div className="mt-2 flex items-center gap-2">
      <button
        type="button"
        className=" flex cursor-pointer  gap-1 bg-transparent px-3 py-1.5 text-sm text-dark-midnight-blue"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}
      >
        <FaPlus className="h-3 w-3 text-dark-midnight-blue" />
        {name}
      </button>
    </div>
  );
};

export default AddNewButton;
