import React, { useRef, useEffect, useState } from "react";
import {
  AccumulationChartComponent,
  AccumulationDataLabel,
  AccumulationLegend,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTooltip,
  Inject,
  PieSeries,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  StackingColumnSeries,
  Category,
  Legend,
  Tooltip,
  DataLabel,
  Highlight,
  AccumulationSelection,
  AccumulationAnnotation,
  ChartAnnotation,
  AccumulationAnnotationDirective,
  AccumulationAnnotationsDirective,
  ColumnSeries,
  BarSeries,
} from "@syncfusion/ej2-react-charts";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const chartColorPalette = [
  "#EF4444",
  "#F59E0B",
  "#3B82F6",
  "#10B981",
  "#F97316",
  "#8B5CF6",
];

const selectedUserId = sessionStorage.getItem("userId");
// Doughnut Component
const Doughnut = ({ title, chartData, total }) => {
  return (
    <>
      <style>
        {`
          #${title}_chart_legend_navigation {
            display: none !important;
          }
        `}
      </style>
      <div className="flex h-[370px] w-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
        <div className="flex items-center justify-between">
          <p className="text-[16px] font-[500]">{title || "No Title"}</p>
        </div>
        <div>
          <p className="flex gap-2 text-[32px] text-[#165BAA]">
            <p>Total: </p>
            {new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(total)}
          </p>
        </div>
        <div className="flex-1 pt-2">
          <AccumulationChartComponent
            id={title}
            legendSettings={{
              visible: true,
              position: "Bottom",
              height: "20%",
              width: "100%",
              textWrap: "Wrap",
              maximumLabelWidth: 75,
              shapeHeight: 12,
              shapeWidth: 12,
              itemPadding: 4,
              enablePages: false,
              enableSmartLegend: false,
              toggleVisibility: false,
            }}
            enableSmartLabels={true}
            enableAnimation={false}
            tooltip={{ enable: true }}
            height="280px"
            width="100%"
            center={{ x: "50%", y: "42%" }}
          >
            <Inject
              services={[
                AccumulationLegend,
                PieSeries,
                AccumulationDataLabel,
                AccumulationTooltip,
              ]}
            />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                dataSource={chartData}
                xName="x"
                yName="y"
                innerRadius="45%"
                dataLabel={{
                  visible: true,
                  position: "Inside",
                  name: "text",
                  font: { fontWeight: "600", color: "#ffffff" },
                }}
                radius="88%"
                palettes={chartColorPalette}
              />
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        </div>
      </div>
    </>
  );
};

// OwnerChart Component
const OwnerChart = ({ title, chartData, total }) => {
  return (
    <div className="flex h-[370px] w-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="flex gap-2 text-[32px] text-[#165BAA]">
          <p>Total: </p>
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(total)}
        </p>
      </div>
      <div className="flex-1 pt-2">
        <AccumulationChartComponent
          id={title}
          legendSettings={{
            visible: true,
            toggleVisibility: false,
            position: "Right",
            height: "50%",
            width: "20%",
            textWrap: "Wrap",
            maximumLabelWidth: 66,
          }}
          enableSmartLabels={true}
          enableAnimation={false}
          tooltip={{
            enable: true,
          }}
          height="230px"
          width="100%"
        >
          <Inject
            services={[
              AccumulationLegend,
              PieSeries,
              AccumulationDataLabel,
              AccumulationTooltip,
            ]}
          />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y"
              innerRadius="40%"
              dataLabel={{
                visible: true,
                position: "Inside",
                name: "text",
                font: { fontWeight: "600", color: "#ffffff" },
              }}
              radius="80%"
              palettes={chartColorPalette}
            />
          </AccumulationSeriesCollectionDirective>
        </AccumulationChartComponent>
      </div>
    </div>
  );
};

// ColumnChart Component
const ColumnChart = ({ title, chartData, total }) => {
  return (
    <div className="flex h-[370px] w-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="flex gap-2 text-[32px] text-[#165BAA]">
          <p>Total: </p>
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(total)}
        </p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          primaryXAxis={{
            labelIntersectAction: "Rotate45",
            valueType: "Category",
            majorGridLines: { width: 0 },
          }}
          primaryYAxis={{
            title: "Number of People",
            interval: 10,
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
          }}
          tooltip={{
            enable: true,
          }}
          width="100%"
          height="230px"
          palettes={chartColorPalette}
        >
          <Inject services={[ColumnSeries, Tooltip, Category, DataLabel]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y"
              type="Column"
              name={title}
              dataLabel={{
                visible: true,
                position: "Outside",
                font: { fontWeight: "600", color: "#000" },
              }}
              palettes={chartColorPalette}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

const IncomeChart = ({ title, chartData, total }) => {
  return (
    <div className="flex h-[370px] w-[747px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="flex gap-2 text-[32px] text-[#165BAA]">
          <p>Total: </p>
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(total)}
        </p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          primaryXAxis={{
            labelIntersectAction: "Rotate45",
            valueType: "Category",
            majorGridLines: { width: 0 },
            title: "Income Ranges",
          }}
          primaryYAxis={{
            title: "Number of People",
            interval: 10,
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
          }}
          tooltip={{
            enable: true,
          }}
          width="100%"
          height="250px"
          palettes={chartColorPalette}
        >
          <Inject services={[ColumnSeries, Tooltip, Category, DataLabel]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y"
              type="Column"
              palettes={chartColorPalette}
              name="Income Levels"
              dataLabel={{
                visible: true,
                position: "Outside",
                font: { fontWeight: "600", color: "#000" },
              }}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

// StackingChart Component
const StackingChart = ({ title, chartData, total }) => {
  return (
    <div className="flex h-[370px] w-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      <div>
        <p className="flex gap-2 text-[32px] text-[#165BAA]">
          <p>Total: </p>
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(total)}
        </p>
      </div>
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{
            valueType: "Category",
            labelIntersectAction: "Rotate45",
            majorGridLines: { width: 0 },
            minorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
          primaryYAxis={{
            rangePadding: "None",
            interval: 20000,
            majorTickLines: { width: 0 },
            majorGridLines: { width: 1 },
            minorGridLines: { width: 1 },
            minorTickLines: { width: 0 },
            lineStyle: { width: 0 },
          }}
          width="100%"
          height="250px"
          chartArea={{ border: { width: 0 } }}
          tooltip={{
            enable: true,
          }}
        >
          <Inject
            services={[
              StackingColumnSeries,
              Legend,
              Tooltip,
              DataLabel,
              Category,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            {/* Render each income range dynamically */}
            {chartData.map(({ x, y }) => (
              <SeriesDirective
                key={x}
                dataSource={[{ x, y }]}
                xName="x"
                yName="y"
                name={x}
                columnWidth={0.5}
                border={{ width: 1, color: "white" }}
                type="StackingColumn100"
                palettes={chartColorPalette}
              />
            ))}
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

// BarChart Component
const BarChart = ({ title, chartData, total }) => {
  return (
    <div className="flex h-[370px] w-[370px] flex-col space-y-2 rounded-md bg-white px-[24px] py-[16px] shadow-xl">
      <div className="flex items-center justify-between">
        <p className="text-[16px] font-[500]">{title || "No Title"}</p>
      </div>
      {/* <div>
        <p className="flex gap-2 text-[32px] text-[#165BAA]">
          <p>Total: </p>
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(total)}
        </p>
      </div> */}
      <div className="flex-1 pt-2">
        <ChartComponent
          id={title}
          style={{ textAlign: "center" }}
          legendSettings={{ enableHighlight: true }}
          primaryXAxis={{ valueType: "Category", majorGridLines: { width: 0 } }}
          primaryYAxis={{
            labelFormat: "{value}%",
            edgeLabelPlacement: "Shift",
            majorTickLines: { width: 0 },
            lineStyle: { width: 0 },
          }}
          chartArea={{ border: { width: 0 } }}
          width="100%"
          height="230px"
          tooltip={{ enable: true }}
        >
          <Inject
            services={[
              BarSeries,
              DataLabel,
              Category,
              Legend,
              Tooltip,
              Highlight,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={chartData}
              xName="x"
              yName="y"
              type="Bar"
              palettes={chartColorPalette}
              columnSpacing={0.1}
              name={title}
              width={2}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

//Calculate Age
const calculateAgeGroup = (birthDate) => {
  const birthYear = new Date(birthDate).getFullYear();
  const currentYear = new Date().getFullYear();
  const age = currentYear - birthYear;

  if (age <= 18) return "0-18";
  if (age <= 29) return "19-29";
  if (age <= 44) return "30-44";
  if (age <= 60) return "45-60";
  return "60+";
};

// Demographics Component
const Demographics = ({ className = "" }) => {
  const [userData, setUserData] = useState(null);
  const [demographicData, setDemographicData] = useState({
    gender: {},
    age: {},
    income: {},
    insured: {},
    disability: {},
    military: {},
    homeOwnership: {},
  });
  const [totalUsers, setTotalUsers] = useState(0);
  const { role } = useAuth();
  const navigate = useNavigate();
  const db = getFirestore();
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const auth = getAuth();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", selectedUserId);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserData(userData);

            if (userData.center_id) {
              fetchDemographicsData(userData.center_id);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchDemographicsData = async (centerId) => {
      console.log(centerId);
      try {
        // Get all users for the center
        let usersQuery;
        if (role === "center") {
          usersQuery = query(
            collection(db, "users"),
            where("center_id", "==", centerId),
            where("user_role", "==", "citizen"),
            where("center_id", "!=", "")
          );
        } else {
          usersQuery = query(
            collection(db, "users"),
            where("center_id", "in", centerId),
            where("user_role", "==", "citizen"),
            where("center_id", "!=", "")
          );
        }
        const querySnapshot = await getDocs(usersQuery);

        const totalUsers = querySnapshot.size;
        setTotalUsers(totalUsers);

        // Initialize demographic data structure
        const demoData = {
          gender: { Male: 0, Female: 0, "Did not answer": 0 },
          age: {
            "0-18": 0,
            "19-29": 0,
            "30-44": 0,
            "45-60": 0,
            "60+": 0,
            "Did not answer": 0,
          },
          disability: {
            "Has Disability": 0,
            "No Disability": 0,
            "Did not answer": 0,
          },
          military: {
            "Military Veteran": 0,
            "Non-Military": 0,
            "Did not answer": 0,
          },
          income: {
            "0-20000": 0,
            "20001-40000": 0,
            "40001-60000": 0,
            "60001-80000": 0,
            "80001-100000": 0,
            "100000+": 0,
            "Did not answer": 0,
          },
          homeOwnership: { Owner: 0, Renter: 0, "Did not answer": 0 },
          insured: { Insured: 0, "Non-Insured": 0, "Did not answer": 0 },
        };

        // Collect all household IDs
        const householdIds = new Set();
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          if (userData.household_id) householdIds.add(userData.household_id);
        });

        // Batch fetch household data
        const householdPromises = Array.from(householdIds).map(
          async (householdId) => {
            const householdRef = doc(
              db,
              "household",
              householdId,
              "household_info",
              "details"
            );
            return getDoc(householdRef);
          }
        );

        // Wait for all household data to be fetched
        const householdSnapshots = await Promise.all(householdPromises);
        const householdData = new Map(
          householdSnapshots
            .filter((snap) => snap.exists())
            .map((snap) => [snap.ref.path.split("/")[1], snap.data()])
        );

        // Process user data
        querySnapshot.forEach((docSnapshot) => {
          const userData = docSnapshot.data();

          // Process basic user demographics
          if (userData.gender && userData.gender !== "") {
            demoData.gender[userData.gender] =
              (demoData.gender[userData.gender] || 0) + 1;
          } else {
            demoData.gender["Did not answer"]++;
          }

          if (userData.birth_date) {
            demoData.age[calculateAgeGroup(userData.birth_date)]++;
          } else {
            demoData.age["Did not answer"]++;
          }

          if (userData.disability !== undefined) {
            demoData.disability[
              userData.disability ? "Has Disability" : "No Disability"
            ]++;
          } else {
            demoData.disability["Did not answer"]++;
          }

          if (userData.military_vet !== undefined) {
            demoData.military[
              userData.military_vet ? "Military Veteran" : "Non-Military"
            ]++;
          } else {
            demoData.military["Did not answer"]++;
          }

          // Process household data if available
          if (userData.household_id) {
            const household = householdData.get(userData.household_id);
            if (household) {
              if (household.annual_income) {
                demoData.income[household.annual_income]++;
              } else {
                demoData.income["Did not answer"]++;
              }

              if (household.owner_or_renter) {
                demoData.homeOwnership[household.owner_or_renter]++;
              } else {
                demoData.homeOwnership["Did not answer"]++;
              }

              if (household.home_insurance !== undefined) {
                demoData.insured[
                  household.home_insurance ? "Insured" : "Non-Insured"
                ]++;
              } else {
                demoData.insured["Did not answer"]++;
              }
            } else {
              // If household data is not found, count as "Did not answer"
              demoData.income["Did not answer"]++;
              demoData.homeOwnership["Did not answer"]++;
              demoData.insured["Did not answer"]++;
            }
          } else {
            // If no household_id, count as "Did not answer"
            demoData.income["Did not answer"]++;
            demoData.homeOwnership["Did not answer"]++;
            demoData.insured["Did not answer"]++;
          }
        });

        setDemographicData(demoData);
        console.log(demoData);
      } catch (error) {
        console.error("Error fetching demographic data:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, fetchUserData);
    return () => unsubscribe();
  }, []);

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
        <a className="relative inline-block min-w-[60px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Recovery Tracker
        </a>
        <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
          /
        </div>
        <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Demographics
        </a>
      </div>

      {/* Title */}
      <div>
        <h2 className="mb-1 text-sm font-bold text-blue-500">DEMOGRAPHICS</h2>
      </div>

      {/* Charts Grid */}
      <div className="flex flex-col justify-center space-y-2 rounded-3xs border-[1px] border-solid border-[#C9C9C9] p-[15px]">
        <div className="flex w-full flex-wrap justify-center gap-x-2 gap-y-2">
          {/* Gender */}
          <Doughnut
            title="Gender"
            chartData={Object.entries(demographicData.gender).map(([x, y]) => ({
              x,
              y,
            }))}
            total={totalUsers}
          />

          {/* Age */}
          <ColumnChart
            title="Age"
            chartData={Object.entries(demographicData.age).map(([x, y]) => ({
              x,
              y,
            }))}
            total={totalUsers}
          />

          {/* Income Levels */}
          <IncomeChart
            title="Income Levels"
            chartData={Object.entries(demographicData.income).map(([x, y]) => ({
              x,
              y,
            }))}
            total={totalUsers}
          />

          {/* Homeowners vs Renters */}
          <Doughnut
            title="Homeowners vs Renters"
            chartData={Object.entries(demographicData.homeOwnership).map(
              ([x, y]) => ({ x, y })
            )}
            total={totalUsers}
          />

          {/* Insured vs Non-Insured Homeowners */}
          <Doughnut
            title="Insured vs Non-Insured Homeowners"
            chartData={Object.entries(demographicData.insured).map(
              ([x, y]) => ({ x, y })
            )}
            total={totalUsers}
          />

          {/* Disability */}
          <Doughnut
            title="Disability"
            chartData={Object.entries(demographicData.disability).map(
              ([x, y]) => ({ x, y })
            )}
            total={totalUsers}
          />

          {/* Military in Household */}
          <Doughnut
            title="Military in Household"
            chartData={Object.entries(demographicData.military).map(
              ([x, y]) => ({ x, y })
            )}
            total={totalUsers}
          />

          {/* Commented out charts for missing fields */}
          {/*
          <Doughnut
            title="Gender"
            chartData={Object.entries(demographicData.gender).map(([x, y]) => ({ x, y }))}
            total={totalUsers}
          />

          <BarChart
            title="Educational Attainment"
            chartData={Object.entries(demographicData.education).map(([x, y]) => ({ x, y }))}
            total={totalUsers}
            
          />

          <Doughnut
            title="Ethnicity"
            chartData={Object.entries(demographicData.ethnicity).map(([x, y]) => ({ x, y }))}
            total={totalUsers}
            
          />
          */}
        </div>
      </div>
    </div>
  );
};

export default Demographics;
