import React from "react";

const PhotoColumn = ({ data, onClick, type = "photo", showImage = false }) => {
  // const isPdf = data[0]?.src?.includes(".pdf");
  return (
    <div className="flex cursor-pointer items-center gap-2" onClick={onClick}>
      {/* {showImage && data[0]?.src ? (
        isPdf ? (
          <h1>PDF</h1>
        ) : (
          <img
            src={data[0]?.src}
            alt="photo"
            className="h-6 w-6 rounded-full"
          />
        )
      ) : (
        <img src={data[0]?.src} alt="photo" className="h-6 w-6 rounded-full" />
      )} */}
      {!showImage && (
        <>
          {type === "photo" ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 10.5L10.1 9C10 8.86667 9.86667 8.8 9.7 8.8C9.53333 8.8 9.4 8.86667 9.3 9L7.625 11.2C7.49167 11.3667 7.47083 11.5417 7.5625 11.725C7.65417 11.9083 7.80833 12 8.025 12H15.975C16.1917 12 16.3458 11.9083 16.4375 11.725C16.5292 11.5417 16.5083 11.3667 16.375 11.2L13.95 8.025C13.85 7.89167 13.7167 7.825 13.55 7.825C13.3833 7.825 13.25 7.89167 13.15 8.025L11.25 10.5ZM6 16C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H6ZM6 14H18V2H6V14ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V5C0 4.71667 0.0958333 4.47917 0.2875 4.2875C0.479167 4.09583 0.716667 4 1 4C1.28333 4 1.52083 4.09583 1.7125 4.2875C1.90417 4.47917 2 4.71667 2 5V18H15C15.2833 18 15.5208 18.0958 15.7125 18.2875C15.9042 18.4792 16 18.7167 16 19C16 19.2833 15.9042 19.5208 15.7125 19.7125C15.5208 19.9042 15.2833 20 15 20H2Z"
                fill="#003460"
              />
            </svg>
          ) : (
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 16H11C11.2833 16 11.5208 15.9042 11.7125 15.7125C11.9042 15.5208 12 15.2833 12 15C12 14.7167 11.9042 14.4792 11.7125 14.2875C11.5208 14.0958 11.2833 14 11 14H5C4.71667 14 4.47917 14.0958 4.2875 14.2875C4.09583 14.4792 4 14.7167 4 15C4 15.2833 4.09583 15.5208 4.2875 15.7125C4.47917 15.9042 4.71667 16 5 16ZM5 12H11C11.2833 12 11.5208 11.9042 11.7125 11.7125C11.9042 11.5208 12 11.2833 12 11C12 10.7167 11.9042 10.4792 11.7125 10.2875C11.5208 10.0958 11.2833 10 11 10H5C4.71667 10 4.47917 10.0958 4.2875 10.2875C4.09583 10.4792 4 10.7167 4 11C4 11.2833 4.09583 11.5208 4.2875 11.7125C4.47917 11.9042 4.71667 12 5 12ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H9.175C9.44167 0 9.69583 0.05 9.9375 0.15C10.1792 0.25 10.3917 0.391667 10.575 0.575L15.425 5.425C15.6083 5.60833 15.75 5.82083 15.85 6.0625C15.95 6.30417 16 6.55833 16 6.825V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2ZM9 6V2H2V18H14V7H10C9.71667 7 9.47917 6.90417 9.2875 6.7125C9.09583 6.52083 9 6.28333 9 6Z"
                fill="#003460"
              />
            </svg>
          )}
        </>
      )}
      <p className=" text-sm text-dark-midnight-blue">
        {type === "photo" ? "Photos" : "Documents"} ({data ? data?.length : 0})
      </p>
    </div>
  );
};

export default PhotoColumn;
