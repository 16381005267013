import { useState, useEffect } from 'react';

const EmergencyBanner = ({ notification }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (!notification || !notification.length) return;

        const timer = setInterval(() => {
            setCurrentIndex(prevIndex => 
                prevIndex === notification.length - 1 ? 0 : prevIndex + 1
            );
        }, 4000);

        return () => clearInterval(timer);
    }, [notification]);

    if (!notification || !notification.length) return null;

    const emergency = notification[currentIndex];

    if (!emergency) return null;

    return (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <div 
                key={emergency.id}
                className="flex items-center gap-2 rounded-full bg-red-600 px-4 py-1 text-white animate-pulse"
            >
                <svg 
                    className="h-5 w-5" 
                    fill="currentColor" 
                    viewBox="0 0 20 20"
                >
                    <path 
                        fillRule="evenodd" 
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" 
                        clipRule="evenodd" 
                    />
                </svg>
                <span className="font-semibold">
                    Emergency Warning: {emergency.title}
                </span>
            </div>
        </div>
    );
};

export default EmergencyBanner;