import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import daArrowIcon from "../../../assets/caseManagement/da-arrow-icon.png";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { Toolbar } from "@syncfusion/ej2-navigations";
import { useDisasterObjContext } from "../../../context/DisasterObjContext";
import { getAuth } from "firebase/auth";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import DisasterMgtService from "../../../service/citizen/DisasterMgtService";
import DeleteModal from "../../../components/DeleteModal";
import addIcon from "../../../assets/caseManagement/add-icon.png";
import Modal from "../../../components/Modal";
import { useForm } from "react-hook-form";
import CustomSelect from "../../../components/CustomSelect";
import CustomTextArea from "../../../components/CustomTextArea";
import CustomInput from "../../../components/CustomInput";
import FileUploader from "../../../components/FileUploader";
import disasterCategories from "../../../disaster_categories.json";
import ThreeDotMenu from "../../../components/ThreeDotMenu";
import previewPhotoIcon from "../../../assets/caseManagement/preview-photo-icon.png";
import PhotoColumn from "../../../components/PhotoColumn";
import ViewPhotosModal from "../../../components/ViewPhotosModal";
import HeaderTitle from "../../../components/HeaderTitle";

const FormActionTypeEnum = {
  ADD: "add",
  EDIT: "edit",
};

const sortSettings = {
  allowSorting: true,
};

const renderSubCategoryTag = {
  center: {
    bg: "#0EDC1C",
    color: "black",
    tag: "C",
  },
  citizen: {
    bg: "#00008B",
    color: "white",
    tag: "S",
  },
  partner: {
    bg: "#FE9D01",
    color: "black",
    tag: "P",
  },
  "Case Manager": {
    bg: "#A020F0",
    color: "white",
    tag: "CM",
  },
};

const RenderGridComponent = memo(
  ({
    data,
    setSelectedItem,
    setToggleForm,
    setToggleDeleteModal,
    setActiveMenu,
    activeMenu,
    setViewFiles,
    setFiles,
    setFilesId,
    setValues,
  }) => {
    const { dataSource, title, collectionkey, collectionidkey } = data || {};
    return (
      <div className="flex flex-col">
        <GridComponent
          dataSource={dataSource}
          allowSorting={true}
          sortSettings={sortSettings}
          cssClass="new-grid-color allow-overflow-grid"
        >
          <ColumnsDirective>
            <ColumnDirective
              field="generalID"
              headerText="generalID"
              isPrimaryKey={true}
              visible={false}
            />
            <ColumnDirective
              field="category"
              headerText="Category"
              visible={false}
            />
            <ColumnDirective
              field="sub_category"
              headerText="Category"
              template={(props) => {
                const { editor_type, sub_category } = props || {};
                return (
                  <div className="flex items-center space-x-2">
                    <p>{sub_category || ""}</p>
                    <div
                      className="flex h-[24px] w-[24px] items-center justify-center rounded-full"
                      style={{
                        backgroundColor: renderSubCategoryTag[editor_type]?.bg,
                        color: renderSubCategoryTag[editor_type]?.color,
                      }}
                    >
                      {renderSubCategoryTag[editor_type]?.tag}
                    </div>
                  </div>
                );
              }}
              width="250"
              editType="dropdownedit"
              textAlign="Left"
              headerTextAlign="Left"
            />
            <ColumnDirective
              field="damage"
              headerText="Description"
              textAlign="Left"
              headerTextAlign="Left"
            />
            <ColumnDirective
              field="date_entered"
              headerText="Date Entered"
              format="MM/dd/yyyy"
              width="150"
              allowEditing={false}
              type="date"
              textAlign="Left"
              headerTextAlign="Left"
            />
            <ColumnDirective
              field={`photo_${collectionkey}`}
              headerText="Photos"
              width="160"
              textAlign="Left"
              headerTextAlign="Left"
              template={(props) => {
                return (
                  <PhotoColumn
                    data={props[`photo_${collectionkey}`]}
                    onClick={() => {
                      setFilesId(() => props.id);
                      setFiles(() => props[`photo_${collectionkey}`]);
                      setToggleForm((prev) => ({
                        ...prev,
                        id: props?.id,
                        collectionkey,
                        collectionidkey,
                      }));
                      setViewFiles(() => true);
                    }}
                  />
                );
              }}
            />
            <ColumnDirective
              field="estimate"
              headerText="Estimate to Repair"
              width="180"
              format="C"
              editType="numericedit"
              textAlign="Right"
              headerTextAlign="Right"
            />
            <ColumnDirective
              field="Actions"
              headerText="Actions"
              width="100"
              textAlign="Center"
              template={(props) => {
                return (
                  <div className="flex w-full justify-center overflow-visible px-4">
                    <ThreeDotMenu
                      menuId={props?.id}
                      activeMenuId={activeMenu}
                      setActiveMenuId={setActiveMenu}
                      clickOutside={true}
                      onEditClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedItem(() => props);

                        setValues("sub_category", props.sub_category);
                        setValues("damage", props.damage);
                        setValues("estimate", props.estimate);
                        setValues("generalID", props.generalID);
                        setValues("editor_ID", props.editor_ID);
                        setValues("editor_type", props.editor_type);
                        setValues("files", props[`photo_${collectionkey}`]);

                        setFiles(() => props[`photo_${collectionkey}`]);

                        setToggleForm((prev) => ({
                          ...prev,
                          id: props?.id,
                          type: FormActionTypeEnum.EDIT,
                          open: true,
                          title,
                          collectionkey,
                          collectionidkey,
                        }));
                      }}
                      onDeleteClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedItem(() => props);
                        setToggleForm((prev) => ({
                          ...prev,
                          collectionkey,
                          collectionidkey,
                          id: props.id,
                        }));
                        setToggleDeleteModal(() => true);
                      }}
                    />
                  </div>
                );
              }}
            />
          </ColumnsDirective>
          <Inject
            services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
          />
        </GridComponent>
        <div className="mt-2">
          <button
            className="flex cursor-pointer items-center space-x-1 bg-transparent"
            onClick={() =>
              setToggleForm((prev) => ({
                ...prev,
                type: FormActionTypeEnum.ADD,
                open: true,
                title,
                collectionkey,
                collectionidkey,
              }))
            }
          >
            <div className="h-[24px] w-[24px]">
              <img src={addIcon} className="h-full w-full object-contain" />
            </div>
            <p className="text-[#003460]">Add New {title}</p>
          </button>
        </div>
      </div>
    );
  }
);

const CaseManagementDA = () => {
  const { disaster: selectedDisaster } = useDisasterObjContext();
  const formRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [toggleForm, setToggleForm] = useState({
    id: null,
    title: "",
    type: FormActionTypeEnum.ADD,
    open: false,
    collectionkey: null,
    collectionidkey: null,
    editor_ID: null,
    editor_type: null,
  });
  const [user, setUser] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [viewFiles, setViewFiles] = useState(false);
  const [filesId, setFilesId] = useState(null);
  const [files, setFiles] = useState([]);
  const [accessedUserId, setAccessedUserId] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const generalCategory = disasterCategories.subcategories.filter(
    ({ categoryId }) => categoryId === "1"
  );
  const homeAndReplacementCategory = disasterCategories.subcategories.filter(
    ({ categoryId }) => categoryId === "2"
  );
  const landscapingCategory = disasterCategories.subcategories.filter(
    ({ categoryId }) => categoryId === "3"
  );
  const vehicleCategory = disasterCategories.subcategories.filter(
    ({ categoryId }) => categoryId === "4"
  );
  const contentsCategory = disasterCategories.subcategories.filter(
    ({ categoryId }) => categoryId === "5"
  );

  const renderSelectOptions = {
    general: generalCategory,
    home_repair: homeAndReplacementCategory,
    landscaping: landscapingCategory,
    vehicle: vehicleCategory,
    contents: contentsCategory,
  };

  const {
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sub_category: "",
      damage: "",
      estimate: 0,
      generalID: "",
      editor_ID: "",
      editor_type: "",
      files: [],
    },
  });

  const [daComponents, setDaComponents] = useState([
    {
      id: "general",
      collectionkey: "general",
      expanded: true,
      amount: 0,
      title: "General",
      dataSource: [],
      collectionidkey: "generalID",
    },
    {
      id: "homerepairandreplacement",
      collectionkey: "home_repair",
      expanded: true,
      amount: 0,
      title: "Home Repair and Replacement",
      dataSource: [],
      collectionidkey: "homeRepairID",
    },
    {
      id: "landscaping",
      collectionkey: "landscaping",
      expanded: true,
      amount: 0,
      title: "Landscaping",
      dataSource: [],
      collectionidkey: "landscapingID",
    },
    {
      id: "vehicles",
      collectionkey: "vehicle",
      expanded: true,
      amount: 0,
      title: "Vehicle(s)",
      dataSource: [],
      collectionidkey: "vehicleID",
    },
    {
      id: "contents",
      collectionkey: "contents",
      expanded: true,
      amount: 0,
      title: "Contents",
      dataSource: [],
      collectionidkey: "contentsID",
    },
  ]);

  const onGetDaData = useCallback(async () => {
    const auth = getAuth();
    const selectedUserId = sessionStorage.getItem("userId");
    if (!auth.currentUser || !selectedDisaster) return;

    const userDoc = await getDoc(doc(db, "users", selectedUserId));
    setUser(() => userDoc.data());

    try {
      const currentUserDoc = await getDoc(doc(db, "users", selectedUserId));
      const currentUserData = currentUserDoc.data();
      const accessedUserId = currentUserData.accessed_userId;

      if (!accessedUserId) {
        throw new Error("No accessed user found");
      }

      setAccessedUserId(() => accessedUserId);

      await Promise.all(
        daComponents.map(async ({ id, collectionkey }) => {
          const records = await DisasterMgtService.fetchRecords(
            accessedUserId,
            collectionkey
          );

          const filteredRecords = records.filter(
            (item) =>
              item.associatedDisasters?.some(
                (disaster) => disaster === selectedDisaster.id
              ) && !item.is_deleted
          );

          const getFiles = filteredRecords.find((da) => da.id === filesId);

          if (!!getFiles) {
            setFiles(() => getFiles[`photo_${collectionkey}`]);
          }

          setDaComponents((prev) => {
            const daComponentIndex = prev.findIndex((da) => da.id === id);

            if (
              daComponentIndex >= 0 &&
              JSON.stringify(filteredRecords) !==
                JSON.stringify(prev[daComponentIndex]?.dataSource)
            ) {
              prev[daComponentIndex] = {
                ...prev[daComponentIndex],
                amount: filteredRecords.reduce(
                  (acc, c) => (acc += parseInt(c.estimate)),
                  0
                ),
                dataSource: filteredRecords,
              };

              return (prev = [...prev]);
            }
            return prev;
          });
        })
      );
    } catch (error) {
      throw new Error(error);
    }
  }, [selectedDisaster, daComponents, filesId]);

  const generateUniqueId = (length = 28) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const onExpandComponent = useCallback((id, expanded) => {
    setDaComponents((prev) => {
      const daComponentIndex = prev.findIndex((da) => da.id === id);
      if (daComponentIndex >= 0) {
        prev[daComponentIndex] = {
          ...prev[daComponentIndex],
          expanded: !expanded,
        };
      }

      return (prev = [...prev]);
    });
  }, []);

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = data[key] || "";
      }
    }
    return sanitizedData;
  };

  const onFormSubmit = useCallback(
    async (values) => {
      const { type, collectionkey, collectionidkey, editor_ID, editor_type } =
        toggleForm || {};
      const selectedUserId = sessionStorage.getItem("userId");

      const { files, ...restValues } = values;

      const currentDate = new Date();
      currentDate.toLocaleDateString("en-US");
      const formattedCurrentDate = `${
        currentDate.getMonth() + 1
      }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

      if (!user || !selectedDisaster || !accessedUserId) return;

      const { user_role } = user || {};

      if (type === FormActionTypeEnum.EDIT) {
        // Handle edit case
        if (user_role === "citizen" || user_role === "partner") {
          if (editor_ID !== selectedUserId || editor_type !== user_role) {
            return;
          }
        }

        const updatedData = {
          ...restValues,
          [`photo_${collectionkey}`]: files,
          last_edited: formattedCurrentDate,
          date_declared: formattedCurrentDate,
          associatedDisasters: [selectedDisaster.id],
          is_deleted: false,
        };

        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          accessedUserId,
          restValues.generalID,
          sanitizedData,
          collectionkey
        );
      } else {
        const newData = {
          ...restValues,
          [`photo_${collectionkey}`]: files,
          [collectionidkey]: generateUniqueId(),
          editor_ID: selectedUserId,
          editor_type: user_role,
          is_deleted: false,
          last_edited: formattedCurrentDate,
          date_declared: formattedCurrentDate,
          date_entered: formattedCurrentDate,
          associatedDisasters: [selectedDisaster.id],
        };

        const sanitizedData = sanitizeData(newData);

        await DisasterMgtService.addRecord(
          accessedUserId,
          sanitizedData,
          collectionkey
        );
      }

      setToggleForm((prev) => ({ ...prev, open: false }));
      reset();
      onGetDaData();
    },
    [toggleForm, user, selectedDisaster, accessedUserId]
  );

  const onUploadNewFiles = useCallback(
    async (props) => {
      const { collectionkey, id } = toggleForm || {};
      try {
        const docRef = doc(
          db,
          `disaster-mgt/${accessedUserId}/${collectionkey}/${id}`
        );
        await updateDoc(docRef, {
          [`photo_${collectionkey}`]: [...(files || []), props],
          updated_at: serverTimestamp(),
        });
        onGetDaData();
      } catch (error) {
        throw new Error(error);
      }
    },
    [toggleForm, files]
  );

  const onEditFile = useCallback(
    async (index, newFile) => {
      const { collectionkey, id } = toggleForm || {};
      try {
        const docRef = doc(
          db,
          `disaster-mgt/${accessedUserId}/${collectionkey}/${id}`
        );
        files.splice(index, 1, newFile);
        await updateDoc(docRef, {
          [`photo_${collectionkey}`]: [...(files || [])],
          updated_at: serverTimestamp(),
        });
        onGetDaData();
      } catch (error) {
        throw new Error(error);
      }
    },
    [files, toggleForm, accessedUserId, files]
  );

  const onDeleteFiles = useCallback(
    async (newFiles) => {
      const { collectionkey, id } = toggleForm || {};
      try {
        const docRef = doc(
          db,
          `disaster-mgt/${accessedUserId}/${collectionkey}/${id}`
        );
        await updateDoc(docRef, {
          [`photo_${collectionkey}`]: [...newFiles],
          updated_at: serverTimestamp(),
        });
        onGetDaData();
      } catch (error) {
        throw new Error(error);
      }
    },
    [toggleForm]
  );

  const onDeleteItem = useCallback(async () => {
    const { collectionkey, id } = toggleForm || {};
    try {
      const docRef = doc(
        db,
        `disaster-mgt/${accessedUserId}/${collectionkey}/${id}`
      );
      await updateDoc(docRef, {
        is_deleted: true,
      });
      onGetDaData();
    } catch (error) {
      throw new Error(error);
    }
  }, [selectedItem, accessedUserId, toggleForm]);

  const renderDeleteModal = useMemo(() => {
    return (
      <DeleteModal
        isOpen={toggleDeleteModal}
        itemName="This Item"
        onClose={() => setToggleDeleteModal(() => false)}
        onDelete={() => {
          onDeleteItem();
          setToggleDeleteModal(() => false);
        }}
      />
    );
  }, [toggleDeleteModal]);

  const renderFormModal = useMemo(() => {
    const { type, open, title, collectionkey } = toggleForm;

    return (
      <Modal
        isOpen={open}
        formRef={formRef}
        onClose={() => {
          reset();
          setToggleForm((prev) => ({ ...prev, open: false }));
        }}
        title={`${type === "edit" ? "Edit" : "Add New"} ${title || ""}`}
      >
        <form
          ref={formRef}
          onSubmit={handleSubmit(onFormSubmit)}
          className="flex flex-col gap-4"
        >
          <div className="col-span-2">
            <CustomSelect
              name="sub_category"
              placeholder="Category"
              options={
                renderSelectOptions?.[collectionkey || "general"]?.map(
                  ({ subcategoryName }) => ({
                    label: subcategoryName,
                    value: subcategoryName,
                  })
                ) || []
              }
              register={{
                ...register("sub_category", {
                  required: "Category is required",
                }),
              }}
              errors={errors}
            />
          </div>
          <div className="w-full">
            <CustomTextArea
              type="text"
              name="damage"
              placeholder="Description"
              register={{
                ...register("damage", {
                  required: "Description is required",
                }),
              }}
              errors={errors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="number"
              name="estimate"
              placeholder="Estimate to Repair"
              register={{
                ...register("estimate", {
                  required: "Estimate is required",
                  valueAsNumber: true,
                  validate: {
                    positive: (value) =>
                      value >= 0 || "Amount must be positive",
                  },
                }),
              }}
              errors={errors}
            />
          </div>
        </form>
        <div className="mt-5">
          {!!files.length ? (
            <button
              className={`w-full cursor-pointer rounded-[4px] bg-[#0a2558] px-4 py-4 text-xl font-medium text-[#F7CA41] hover:bg-[#0a2558]/90`}
              onClick={() => setViewFiles(() => true)}
            >
              View Photos ({files.length})
            </button>
          ) : (
            <FileUploader
              title="Upload New Photo"
              storagePath={`disaster-mgt/general/${accessedUserId}`}
              onSave={(e) => setValue("files", [e])}
              className="w-full"
            />
          )}
        </div>
      </Modal>
    );
  }, [toggleForm, errors, files]);

  const handlePhotosCollectionUpdate = useCallback(
    async (newFile) => {
      const { id, collectionkey } = toggleForm || {};
      try {
        const daRef = doc(db, `disaster-mgt/${collectionkey}/${id}`);

        const daData = await getDoc(daRef);

        if (daData.exists()) {
          const existingFiles = daData.data()?.[`photo_${collectionkey}`] || [];
          const formattedNewFile = {
            uploaded_at: new Date().toISOString(),
            details: "",
            name: newFile.name,
            src: newFile.src,
            type: newFile.type || "image/jpeg",
          };

          existingFiles.push(formattedNewFile);

          await updateDoc(daRef, {
            [`photo_${collectionkey}`]: existingFiles,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });
        }

        onGetDaData();
      } catch (error) {
        console.error("Error updating photos:", error);
      }
    },
    [accessedUserId]
  );

  const renderPhotosModal = useMemo(() => {
    const { collectionkey } = toggleForm || {};
    return (
      <ViewPhotosModal
        isOpen={viewFiles}
        onClose={() => {
          setViewFiles(() => false);
        }}
        photosData={files || []}
        storagePath={`disaster-mgt/${collectionkey}/${accessedUserId}`}
        handleCollectionUpdate={handlePhotosCollectionUpdate}
        dateKey="uploaded_at"
        onEditClick={(newData) => {
          setViewFiles(() => true);
        }}
        onGetCurrentIndex={(props) => {
          setCurrentItemIndex(() => props);
        }}
        hasVerifiedDisplay
        hasDescriptionDisplay
        leftContent={
          <FileUploader
            title="Edit Photo"
            defaultValues={files?.[currentItemIndex]}
            index={currentItemIndex}
            storagePath={`disaster-mgt/general/${accessedUserId}`}
            onSave={(props, index) => onEditFile(index, props)}
            className={`borde-[#0a2558] w-full cursor-pointer rounded-[4px] border border-solid px-4 py-4 text-xl font-medium text-[#0a2558]`}
          >
            Edit File
          </FileUploader>
        }
        rightContent={
          <FileUploader
            title="Upload New Photo"
            storagePath={`disaster-mgt/general/${accessedUserId}`}
            onSave={(props) => onUploadNewFiles(props)}
            className={`w-full cursor-pointer rounded-[4px] px-4 py-4 text-xl font-medium text-[#F7CA41] hover:bg-[#0a2558]/90`}
            style={{ backgroundColor: "#0a2558" }}
          >
            Upload New Photo
          </FileUploader>
        }
        handleDelete={(props) => onDeleteFiles(props)}
      />
    );
  }, [viewFiles, files, accessedUserId, toggleForm, currentItemIndex]);

  useEffect(() => {
    onGetDaData();
  }, [selectedDisaster]);

  return (
    <>
      <div className="flex flex-col space-y-2 pb-5">
        {daComponents.map((props) => {
          const { id, title, expanded, amount } = props || {};
          return (
            <div id={id} key={id} className="flex flex-col">
              <HeaderTitle
                isRed
                title={title}
                rightComponent={
                  <div className="flex items-center space-x-3">
                    <p className="text-white">Total Amount: ${amount}</p>
                    <button
                      className="h-[24px] w-[24px] cursor-pointer bg-transparent"
                      onClick={() => onExpandComponent(id, expanded)}
                    >
                      <img
                        src={daArrowIcon}
                        className={`h-full w-full object-contain transition-all duration-200 ease-in-out ${
                          expanded ? "rotate-180" : "rotate-0"
                        }`}
                      />
                    </button>
                  </div>
                }
              />
              {!!expanded && (
                <RenderGridComponent
                  data={props}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  setSelectedItem={setSelectedItem}
                  setFiles={setFiles}
                  setViewFiles={setViewFiles}
                  setFilesId={setFilesId}
                  setToggleDeleteModal={setToggleDeleteModal}
                  setToggleForm={setToggleForm}
                  setValues={setValue}
                />
              )}
            </div>
          );
        })}
      </div>
      {renderDeleteModal}
      {renderFormModal}
      {renderPhotosModal}
    </>
  );
};

export default CaseManagementDA;
