import React, { useState, useEffect, useRef, useMemo } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";

import { db, auth } from "../../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "./../../style.css";
import HeaderTitle from "../../../components/HeaderTitle";
import { useDisasterObjContext } from "../../../context/DisasterObjContext";

const CommunityContacts = ({className = "",} ) => {
  const [disasterContacts, setDisasterContacts] = useState([]);
  const gridRef = useRef(null);
  const [userId, setUserId] = useState(null);
  const [accessedUserId, setAccessedUserId] = useState(null);

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const selectedUserId = sessionStorage.getItem("userId");
  const storedAccessedUserId = sessionStorage.getItem("accessedUserId");
  const { disaster } = useDisasterObjContext();

  const selectedDisasters = useMemo(() => {
    if (disaster) {
      return [disaster?.id];
    }
    return [];
  }, [disaster]);

  const fetchContacts = async () => {
    try {
        const querySnapshot = await getDocs(
            query(
                collection(db, "community-contacts"),
                where("user_id", "==", userId)
            )
        );
        const contactsData = querySnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((contact) => !contact.is_deleted);
        setDisasterContacts(contactsData);
    } catch (error) {
        console.error("Error fetching contacts:", error);
    }
};

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && selectedUserId) {
        setUserId(selectedUserId);
        setAccessedUserId(storedAccessedUserId);
        fetchContacts();
      } else {
        setUserId(null);
        setAccessedUserId(null);
        setDisasterContacts([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAddContactsDisaster = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const generateContactID = () => {
    return `CID-${Math.random().toString(36).substr(2, 9)}`;
  };

  useEffect(() => {
    if (accessedUserId && selectedDisasters[0]) {
      fetchContacts();
    } else {
      setDisasterContacts([]); 
    }
  }, [accessedUserId, selectedDisasters]);


  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      const data = args.data;

      if (
        !data.name ||
        !data.email ||
        !data.phone ||
        !data.description ||
        !data.department
      ) {
        args.cancel = true;
        alert("Please fill in all fields before saving.");
        return;
      }

      data.contactID = data.contactID || generateContactID();
      data.last_updated = serverTimestamp();
      data.user_id = userId;
      data.is_deleted = false;

      try {
        if (args.action === "add") {
          data.inserted_at = serverTimestamp();
          const docRef = await addDoc(
            collection(db, "disaster-contacts"),
            data
          );
          data.id = docRef.id; // Add the new document ID to the data
        } else if (args.action === "edit") {
          const contactDoc = doc(db, "disaster-contacts", data.id);
          await updateDoc(contactDoc, data);
        }

        // Update the local state
        setDisasterContacts((prevContacts) => {
          const index = prevContacts.findIndex(
            (contact) => contact.id === data.id
          );
          if (index !== -1) {
            // Update existing contact
            return prevContacts.map((contact) =>
              contact.id === data.id ? { ...contact, ...data } : contact
            );
          } else {
            // Add new contact
            return [...prevContacts, data];
          }
        });
      } catch (error) {
        console.error("Error saving document:", error);
      }
    } else if (args.requestType === "delete") {
      args.cancel = true; // Prevent actual deletion
      const data = args.data[0];

      try {
        if (data.id) {
          const contactDoc = doc(db, "disaster-contacts", data.id);
          await updateDoc(contactDoc, { is_deleted: true });
          console.log("Contact marked as deleted:", data.id);

          // Remove the deleted contact from the local state
          setDisasterContacts((prevContacts) =>
            prevContacts.filter((contact) => contact.id !== data.id)
          );
        }
      } catch (error) {
        console.error("Error marking contact as deleted:", error);
        alert("Error marking the contact as deleted in the database.");
      }
    }
  };

  return (
    <div
      className={`relative flex w-full flex-col items-start justify-center leading-[normal] tracking-[normal] ${className}`}
    >
      <HeaderTitle id="communitycontacts" title="Community Contacts" />
      <div className="">
        {/* <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap flex w-[1588px] max-w-full flex-row items-start justify-between gap-5 text-left text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
            <div className="flex w-[159px] flex-col items-start justify-start">
              <p className="relative inline-block min-w-[52px] font-bold leading-[21px] text-[#003460]">
                {title}
              </p>
              <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
                Last Edited: 4 minutes ago
              </div>
            </div>
            <div className="flex flex-col items-start justify-start px-0 pb-0 pt-[7px]">
              <div className="flex flex-row items-start justify-start gap-2.5"></div>
            </div>
          </div>
        </div> */}
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <GridComponent
            dataSource={disasterContacts}
            editSettings={{
              allowEditing: true,
              allowAdding: true,
              allowDeleting: true,
              showDeleteConfirmDialog: true,
              mode: "Dialog",
              allowEditOnDblClick: false,
            }}
            ref={gridRef}
            actionBegin={actionBegin}
            filterSettings={filterSettings}
            allowFiltering={true}
            allowSorting={true}
            sortSettings={sortSettings}
            allowPaging={true}
            pageSettings={pageSettings}
            showColumnMenu={true}
            columnMenuItems={columnMenuItems}
            cssClass="new-grid-color"
          >
            <ColumnsDirective>
              <ColumnDirective
                field="contactID"
                headerText="Contact ID"
                isPrimaryKey={true}
                isIdentity={true}
                visible={false}
              />
              <ColumnDirective
                field="name"
                headerText="Name"
                validationRules={{ required: true }}
              />
              <ColumnDirective
                  field="occupation"
                  headerText="Title"
                  
                />
              <ColumnDirective
                  field="mobileNumber"
                  headerText="Mobile Number"
                  
                />
                <ColumnDirective
                  field="email"
                  headerText="Email Address"
                  
                />
              {/* <ColumnDirective
                field="phone"
                headerText="Phone"
                validationRules={{ required: true }}
              />

              <ColumnDirective
                field="department"
                headerText="Department"
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field="description"
                headerText="Description"
                validationRules={{ required: true }}
              /> */}
            </ColumnsDirective>
            <Inject
              services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default CommunityContacts;
