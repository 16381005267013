import React, { useState, useRef } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import fileUploadIcon from "../assets/imageUploader/file-upload-icon.png";
import { useForm } from 'react-hook-form';
import CustomInput from './CustomInput';
import { useSidebarContext } from "../context/SidebarContext";

const FormPhotoUpload = ({ 
    storagePath, 
    photoDetails, 
    onPhotoUpdate,
    className 
  }) => {
    const { toggleSidebar } = useSidebarContext();
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(photoDetails?.[0] || null);
    const fileInputRef = useRef(null);
    const storage = getStorage();

    const handleAddNewPhoto = (newImage) => {
        const updatedPhotos = [...(photoDetails || []), {
          ...newImage,
          details: newImage.description || "",
          dateAdded: new Date().toISOString(),
          id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
        }];
        onPhotoUpdate(updatedPhotos);
        setCurrentImage(newImage);
        setShowUploadModal(false);
      };
      
      const handleEditPhoto = (editedImage, originalImage) => {
        const updatedPhotos = photoDetails.map(photo => 
          photo.src === originalImage.src && photo.dateAdded === originalImage.dateAdded
            ? {
                ...editedImage,
                details: editedImage.description || "",
              }
            : photo
        );
        onPhotoUpdate(updatedPhotos);
        setCurrentImage(editedImage);
      };
  
    return (
      <>
        <div 
          className={`relative w-full cursor-pointer ${className}`}
          onClick={() => currentImage ? setShowPhotoModal(true) : setShowUploadModal(true)}
        >
          {currentImage ? (
            <div className="group relative flex flex-col gap-2 w-full">
              <div 
                className="w-full py-3 bg-[#002B5B] hover:bg-[#001F40] rounded-md cursor-pointer flex items-center justify-center"
              >
                <p className="text-base font-semibold text-yellow-400">
                  View Photo ({photoDetails?.length || 1})
                </p>
              </div>
            </div>
          ) : (
            <div className="flex h-[300px] cursor-pointer flex-col items-center justify-center space-y-3 rounded-md border border-dashed border-[#003460]">
              <div className="h-[30px] w-[30px]">
                <img src={fileUploadIcon} className="h-full w-full object-contain" alt="upload" />
              </div>
              <p className="text-sm text-[#525252]">
                Click to Upload Photo
              </p>
            </div>
          )}
        </div>
  
        {/* View/Edit Modal */}
        {showPhotoModal && (
          <ImageViewModal
            isOpen={showPhotoModal}
            onClose={() => setShowPhotoModal(false)}
            image={currentImage}
            photoDetails={photoDetails}
            onEdit={handleEditPhoto}
            onUploadNew={handleAddNewPhoto}
            storagePath={storagePath}
          />
        )}

        {/* Upload Modal */}
        {showUploadModal && (
          <div className={`fixed inset-0 z-50 flex items-center justify-center mt-24 ${
            toggleSidebar ? "ml-[335px]" : "ml-[80px]"
          }`}>
            <div className="relative flex h-[80vh] w-full max-w-4xl flex-col rounded-lg border border-gray-300 bg-white shadow-lg">
              <div className="flex items-center justify-between rounded-t-lg border-b bg-[#C4DAF3] p-4">
                <h2 className="truncate text-xl font-bold text-[#04447A]">
                  Upload New Photo
                </h2>
                <button onClick={() => setShowUploadModal(false)} className="bg-[#C4DAF3]">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.0013 15.8641L17.868 19.7307C18.1124 19.9752 18.4235 20.0974 18.8013 20.0974C19.1791 20.0974 19.4902 19.9752 19.7346 19.7307C19.9791 19.4863 20.1013 19.1752 20.1013 18.7974C20.1013 18.4196 19.9791 18.1085 19.7346 17.8641L15.868 13.9974L19.7346 10.1307C19.9791 9.88628 20.1013 9.57517 20.1013 9.1974C20.1013 8.81962 19.9791 8.50851 19.7346 8.26406C19.4902 8.01962 19.1791 7.8974 18.8013 7.8974C18.4235 7.8974 18.1124 8.01962 17.868 8.26406L14.0013 12.1307L10.1346 8.26406C9.89019 8.01962 9.57908 7.8974 9.2013 7.8974C8.82352 7.8974 8.51241 8.01962 8.26797 8.26406C8.02352 8.50851 7.9013 8.81962 7.9013 9.1974C7.9013 9.57517 8.02352 9.88628 8.26797 10.1307L12.1346 13.9974L8.26797 17.8641C8.02352 18.1085 7.9013 18.4196 7.9013 18.7974C7.9013 19.1752 8.02352 19.4863 8.26797 19.7307C8.51241 19.9752 8.82352 20.0974 9.2013 20.0974C9.57908 20.0974 9.89019 19.9752 10.1346 19.7307L14.0013 15.8641Z" fill="#E72528"/>
                  </svg>
                </button>
              </div>

              <UploadModal
                onSave={handleAddNewPhoto}
                onCancel={() => setShowUploadModal(false)}
                storagePath={storagePath}
                isEdit={false}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  export const ImageViewModal = ({ isOpen, onClose, image, onEdit, onUploadNew, storagePath, photoDetails = [] }) => {
    const { toggleSidebar } = useSidebarContext();
    const [isEditing, setIsEditing] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(
      photoDetails.findIndex(photo => 
        photo.src === image.src && photo.dateAdded === image.dateAdded
      )
    );
  
    const validImages = photoDetails.filter(img => img && img.src);
    const currentImage = validImages[currentIndex] || image;
  
    const handlePrev = () => {
      setCurrentIndex(prev => 
        prev > 0 ? prev - 1 : validImages.length - 1
      );
    };
  
    const handleNext = () => {
      setCurrentIndex(prev => 
        prev < validImages.length - 1 ? prev + 1 : 0
      );
    };
  
    return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center mt-24 ${
            toggleSidebar ? "ml-[335px]" : "ml-[80px]"
          }`}>
        <div className="relative flex h-[80vh] w-full max-w-4xl flex-col rounded-lg border border-gray-300 bg-white shadow-lg">
          <div className="flex items-center justify-between rounded-t-lg border-b bg-[#C4DAF3] p-4">
            <h2 className="truncate text-xl font-bold text-[#04447A]">
              {isEditing ? "Edit Photo" : isUploading ? "Upload New Photo" : currentImage.name || "View Photo"}
            </h2>
            <button onClick={onClose} className="bg-[#C4DAF3]">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.0013 15.8641L17.868 19.7307C18.1124 19.9752 18.4235 20.0974 18.8013 20.0974C19.1791 20.0974 19.4902 19.9752 19.7346 19.7307C19.9791 19.4863 20.1013 19.1752 20.1013 18.7974C20.1013 18.4196 19.9791 18.1085 19.7346 17.8641L15.868 13.9974L19.7346 10.1307C19.9791 9.88628 20.1013 9.57517 20.1013 9.1974C20.1013 8.81962 19.9791 8.50851 19.7346 8.26406C19.4902 8.01962 19.1791 7.8974 18.8013 7.8974C18.4235 7.8974 18.1124 8.01962 17.868 8.26406L14.0013 12.1307L10.1346 8.26406C9.89019 8.01962 9.57908 7.8974 9.2013 7.8974C8.82352 7.8974 8.51241 8.01962 8.26797 8.26406C8.02352 8.50851 7.9013 8.81962 7.9013 9.1974C7.9013 9.57517 8.02352 9.88628 8.26797 10.1307L12.1346 13.9974L8.26797 17.8641C8.02352 18.1085 7.9013 18.4196 7.9013 18.7974C7.9013 19.1752 8.02352 19.4863 8.26797 19.7307C8.51241 19.9752 8.82352 20.0974 9.2013 20.0974C9.57908 20.0974 9.89019 19.9752 10.1346 19.7307L14.0013 15.8641Z" fill="#E72528"/>
              </svg>
            </button>
          </div>
  
          {!isEditing && !isUploading ? (
            <>
              <div className="group relative m-4 flex flex-grow items-center justify-center overflow-hidden bg-gray-200">
                <img 
                  src={currentImage.src} 
                  alt={currentImage.name || 'Uploaded photo'} 
                  className="h-full w-full rounded-md object-cover"
                />
                {validImages.length > 1 && (
                  <>
                    <button
                    type='button'
                      onClick={handlePrev}
                      className="text-gray-700 absolute left-4 top-1/2 ml-2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-gray-300"
                    >
                      <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.1974 26.9948L31.5307 33.3281C32.0196 33.817 32.2641 34.4281 32.2641 35.1615C32.2641 35.8948 32.0196 36.5281 31.5307 37.0615C30.9974 37.5948 30.3641 37.8615 29.6307 37.8615C28.8974 37.8615 28.2641 37.5948 27.7307 37.0615L19.5307 28.8615C18.9974 28.3281 18.7307 27.7059 18.7307 26.9948C18.7307 26.2837 18.9974 25.6615 19.5307 25.1281L27.7974 16.8615C28.3307 16.3281 28.953 16.0726 29.6641 16.0948C30.3752 16.117 30.9974 16.3948 31.5307 16.9281C32.0196 17.4615 32.2752 18.0837 32.2974 18.7948C32.3196 19.5059 32.0641 20.1281 31.5307 20.6615L25.1974 26.9948Z" fill="#FAFAFA"/>
                      </svg>
                    </button>
                    <button
                    type='button'
                      onClick={handleNext}
                      className="text-gray-700 absolute right-4 top-1/2 mr-2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-gray-300"
                    >
                      <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.8026 26.9948L22.4693 33.3281C21.9804 33.817 21.7359 34.4281 21.7359 35.1615C21.7359 35.8948 21.9804 36.5281 22.4693 37.0615C23.0026 37.5948 23.6359 37.8615 24.3693 37.8615C25.1026 37.8615 25.7359 37.5948 26.2693 37.0615L34.4693 28.8615C35.0026 28.3281 35.2693 27.7059 35.2693 26.9948C35.2693 26.2837 35.0026 25.6615 34.4693 25.1281L26.2026 16.8615C25.6693 16.3281 25.047 16.0726 24.3359 16.0948C23.6248 16.117 23.0026 16.3948 22.4693 16.9281C21.9804 17.4615 21.7248 18.0837 21.7026 18.7948C21.6804 19.5059 21.9359 20.1281 22.4693 20.6615L28.8026 26.9948Z" fill="#FAFAFA"/>
                      </svg>
                    </button>
                  </>
                )}
              </div>
              <div className="p-4">
                <p className="flex items-center gap-1 text-sm text-[#525252]">
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-3 w-3">
                    <path d="M2 19.9922C1.45 19.9922 0.979167 19.7964 0.5875 19.4047C0.195833 19.013 0 18.5422 0 17.9922V3.99219C0 3.44219 0.195833 2.97135 0.5875 2.57969C0.979167 2.18802 1.45 1.99219 2 1.99219H3V0.992188C3 0.708854 3.09583 0.471354 3.2875 0.279687C3.47917 0.0880208 3.71667 -0.0078125 4 -0.0078125C4.28333 -0.0078125 4.52083 0.0880208 4.7125 0.279687C4.90417 0.471354 5 0.708854 5 0.992188V1.99219H13V0.992188C13 0.708854 13.0958 0.471354 13.2875 0.279687C13.4792 0.0880208 13.7167 -0.0078125 14 -0.0078125C14.2833 -0.0078125 14.5208 0.0880208 14.7125 0.279687C14.9042 0.471354 15 0.708854 15 0.992188V1.99219H16C16.55 1.99219 17.0208 2.18802 17.4125 2.57969C17.8042 2.97135 18 3.44219 18 3.99219V17.9922C18 18.5422 17.8042 19.013 17.4125 19.4047C17.0208 19.7964 16.55 19.9922 16 19.9922H2Z" fill="#525252"/>
                  </svg>
                  Date Uploaded: {currentImage.dateAdded ? new Date(currentImage.dateAdded).toLocaleString() : ""}
                </p>
                <p className="mt-1 text-sm text-[#525252]">
                  {currentImage.description}
                </p>
              </div>
              <div className="flex justify-between gap-4 p-4">
                <button
                  className="w-full rounded border border-solid border-[#003460] bg-white px-4 py-4 text-[24px] text-[#003460]"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </button>
                <button
                  className="w-full rounded bg-[#003460] px-4 py-4 text-[24px] text-[#F7CA41]"
                  onClick={() => setIsUploading(true)}
                >
                  Upload New Photo
                </button>
              </div>
            </>
          ) : (
            <UploadModal
          onSave={(newImage) => {
            if (isEditing) {
              onEdit(newImage, currentImage);
            } else {
              onUploadNew(newImage);
              setCurrentIndex(validImages.length);
            }
            setIsEditing(false);
            setIsUploading(false);
          }}
          onCancel={() => {
            setIsEditing(false);
            setIsUploading(false);
          }}
          initialData={isEditing ? currentImage : null}
          storagePath={storagePath}
          isEdit={isEditing}
        />
          )}
        </div>
      </div>
    );
  };

  const UploadModal = ({ onSave, onCancel, initialData, storagePath, isEdit = false }) => {
    const [previewImage, setPreviewImage] = useState(initialData?.src || "");
    const fileInputRef = useRef(null);
    const storage = getStorage();
  
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: {
        name: initialData?.name || "",
        details: initialData?.details || "",
        src: initialData?.src || "",
        dateAdded: initialData?.dateAdded || "",
        id: initialData?.id || ""
      }
    });
  
    const handleFileChange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const uniqueId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        const uniqueFileName = `${uniqueId}-${file.name}`;
        const storageRef = ref(storage, `${storagePath}/${uniqueFileName}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
  
        setValue('src', downloadURL);
        setValue('name', file.name);
        setValue('dateAdded', new Date().toISOString());
        setValue('id', uniqueId);
        setPreviewImage(URL.createObjectURL(file));
      }
    };
  
    const onSubmit = (data, e) => {
        e.preventDefault(); 
        if (isEdit && !data.name) return;
        if (!isEdit && !data.src) return;
        onSave({
          ...data,
          src: data.src || previewImage,
          description: data.details 
        });
      };
    
  
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="flex h-full w-full grow flex-col gap-4 p-4">
        <div
          className="flex h-[300px] cursor-pointer flex-col items-center justify-center space-y-3 rounded-md border border-dashed border-[#003460]"
          onClick={() => fileInputRef.current.click()}
        >
          {previewImage ? (
            <div className="group relative h-full w-full">
              <img
                src={previewImage}
                alt="Preview"
                className="h-full w-full object-cover"
              />
              <div 
                className="absolute inset-0 bg-black/50 hidden items-center justify-center cursor-pointer transition-all duration-200 group-hover:flex"
                onClick={() => fileInputRef.current.click()}
              >
                <div className="flex flex-col items-center text-white gap-2">
                  <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                  </svg>
                  <span className="text-sm font-medium">Change Photo</span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="h-[30px] w-[30px]">
                <img src={fileUploadIcon} className="h-full w-full object-contain" alt="upload" />
              </div>
              <p className="text-sm text-[#525252]">
                Click to Upload Photo
              </p>
            </>
          )}
        </div>
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
          required={!isEdit}
        />
        
        <div className="flex flex-col gap-4">
          <CustomInput
            type="text"
            name="name"
            placeholder="Name"
            register={register("name", {
              required: "Name is required"
            })}
            errors={errors}
          />
          <CustomInput
            type="text"
            name="details"
            placeholder="Description"
            register={register("details", {
              required: "Description is required"
            })}
            errors={errors}
          />
        </div>
  
        <div className="flex justify-between gap-4 mt-auto pt-4">
          <button
            type="button"
            onClick={onCancel}
            className="w-full rounded border border-solid border-[#003460] bg-white px-4 py-4 text-[24px] text-[#003460]"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="w-full rounded bg-[#003460] px-4 py-4 text-[24px] text-[#F7CA41]"
            disabled={!previewImage}
          >
            Save
          </button>
        </div>
      </form>
    );
  };

export default FormPhotoUpload;