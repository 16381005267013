import React from "react";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title = "Confirm Action",
  message = "Are you sure you want to proceed?",
  confirmText = "Yes",
  cancelText = "No",
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="w-[440px] rounded-lg bg-white shadow-lg">
        <div className="flex items-center justify-between rounded-t-lg bg-[#467ba4] p-4">
          <p className="text-[24px] font-semibold text-[#fdb841]">{title}</p>
          <button
            className="cursor-pointer bg-[#467ba4] text-[24px] font-semibold text-[#fdb841]"
            onClick={onClose}
          >
            ×
          </button>
        </div>
        <div className="space-y-2 p-4 text-sm">
          <p>{message}</p>
        </div>
        <div className="my-4 flex justify-end gap-2 pr-4">
          <button
            onClick={onConfirm}
            className="btn-default flex items-center justify-center px-4 py-2 hover:cursor-pointer"
          >
            {confirmText}
          </button>
          <button
            onClick={onClose}
            className="btn-cancel px-4 py-2 hover:cursor-pointer"
          >
            {cancelText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
