import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";
import {
  updateDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import mailer from "../service/api/mailer";
import userIcon from "../../src/icons/Profile/userIcon.png";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import { useAuth } from "../components/AuthContext";
import { useSearchParams } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useGeneralContext } from "../context/GeneralContext";
import { decrypt } from "../service/encryption/Encryption";
import { useLocation } from "react-router-dom";
import { config } from "../utils/config";
import { UserStatus } from "../utils/enums";

const AddressVerify = () => {
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [code, setCode] = useState("");
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const navigate = useNavigate();
  const { setRoleBasedRoute, login } = useAuth();
  const { email, dupAddressData } = useGeneralContext();
  const location = useLocation();
  const [emailToUse, setEmailToUse] = useState(location.state?.email);

  // Initialize verification data from URL or context
  useEffect(() => {
    const checkOriginalUserVerification = async () => {
      // Try to get user data from localStorage first
      const storedUser = localStorage.getItem("addressVerifyUser");
      let user;

      if (storedUser) {
        user = JSON.parse(storedUser);
      } else {
        if (emailToUse) {
          user = await getUserByEmail(emailToUse);
        }
        // Store user data in localStorage
        localStorage.setItem("addressVerifyUser", JSON.stringify(user));
      }

      setUserDetails({
        firstName: user?.first_name,
        lastName: user?.last_name,
        email: user?.email,
      });
      console.log("user: ", user);
    };

    checkOriginalUserVerification();
  }, [dupAddressData]);

  const verifyDuplicateAddressCode = async (user, code) => {
    if (decrypt(user.dup_address_otp) !== code) {
      throw new Error("Invalid verification code");
    }
  };

  const getOriginalHouseholdUser = async (userData) => {
    const originalUserQuery = query(
      collection(db, "users"),
      where("dup_address_token", "==", userData.dup_address_token),
      where("dup_address_owner", "==", true)
    );
    const originalUserSnapshot = await getDocs(originalUserQuery);
    const originalUser = !originalUserSnapshot.empty
      ? originalUserSnapshot.docs[0].data()
      : null;

    if (!originalUser) {
      throw new Error("Original user not found");
    }
    return originalUser;
  };

  const addToHousehold = async (userData, originalUser) => {
    const householdMembersRef = collection(
      db,
      "household",
      originalUser.household_id,
      "household_members"
    );
    const memberQuery = query(
      householdMembersRef,
      where("email", "==", userData.email)
    );
    const memberSnapshot = await getDocs(memberQuery);

    if (memberSnapshot.empty) {
      const householdMemberRef = doc(householdMembersRef);
      await setDoc(householdMemberRef, {
        date_of_birth: "",
        disability: "",
        first_name: userData.first_name,
        last_name: userData.last_name,
        household_member_id: userData.household_member_id,
        military_vet: "",
        relationship: "",
        acc_created: true,
        email: userData.email,
      });
    }

    const userRef = doc(db, "users", userData.id);
    await updateDoc(userRef, {
      status: UserStatus.DUPLICATE_ADDRESS_VERIFIED,
      household_id: originalUser.household_id,
    });
  };

  const updateAddressCollection = async (userData) => {
    if (userData.address_id) {
      const addressesCollectionRef = collection(
        db,
        "address",
        userData.address_id,
        "addresses"
      );
      const q = query(
        addressesCollectionRef,
        where("is_primary_address", "==", true)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (document) => {
        await updateDoc(document.ref, {
          address1: userData.address1,
          address2: userData.address2,
          city: userData.city,
          state: userData.state,
          zip: userData.zip,
          county: userData.county,
          updated_at: serverTimestamp(),
        });
      });
    }
  };

  const handleNavigation = (userData) => {
    if (userData.profile_completed) {
      return "/home";
    }

    const routes = {
      center: "/centerprofile",
      partner: "/partnerprofile",
      citizen: "/citizenprofile",
    };

    return routes[userData.user_role] || "/home";
  };

  const onContinue = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        config.bluesky_email_auth,
        config.bluesky_password
      );

      const user = await getUserByEmail(emailToUse);
      await verifyDuplicateAddressCode(user, code);

      const userData = await getUserByEmail(userDetails.email);
      const originalUser = await getOriginalHouseholdUser(userData);

      await addToHousehold(userData, originalUser);
      await updateAddressCollection(userData);

      // Set session data and login
      sessionStorage.setItem("userId", userData.id);
      sessionStorage.setItem(userData.user_role, JSON.stringify(userData));
      setRoleBasedRoute(userData.user_role);
      login(userCredential.user.accessToken);

      // Navigate to appropriate route
      navigate(handleNavigation(userData));
    } catch (err) {
      console.error(err);
      if (err.message === "Invalid verification code") {
        setIsInvalidCode(true);
        setTimeout(() => setIsInvalidCode(false), 3000);
      } else {
        alert(err.message);
      }
    }
  };

  const getUserByEmail = async (email) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      }

      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });

      return userData.length > 0 ? userData[0] : null;
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  };

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const [showPopup, setShowPopup] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const resendCode = async () => {
    if (resendTimer > 0) return;

    try {
      const newOtp = generateOtp();

      const user = await getUserByEmail(emailToUse);
      if (!user) {
        throw new Error("User not found");
      }

      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, {
        otp: newOtp,
      });

      await mailer.sendOtpEmail(emailToUse, newOtp, user.first_name);

      setResendTimer(60);
      setTimeout(() => setShowPopup(true), 2000);

      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (err) {
      console.error("Error in resendCode:", err);
      alert("Failed to resend code. Please try again.");
    }
  };

  return (
    <>
      <div className="flex min-h-screen w-full items-center justify-center overflow-y-auto   bg-[rgb(225,190,116)]/[0.13]">
        <div className="viewport-bg flex min-h-screen w-full items-center justify-center bg-[rgb(225,190,116)]/[0.13] pb-2 pt-2">
          <div className="flex rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)] ">
            <div className="w-[450px] rounded-l-lg bg-white pb-2">
              <header className="text-3xl text-gray-700 z-0 flex w-[483px] max-w-full flex-col items-center justify-center whitespace-nowrap bg-white px-2 py-1.5 text-center font-bold leading-none tracking-wide">
                <img
                  loading="lazy"
                  src={blueSkyLogo}
                  className="aspect-[0.99] w-[90px] object-contain"
                  alt="DARCI logo"
                />
                <label className="login-logo-label mt-2.5">DARCI</label>
              </header>
              <div className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center ">
                <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                  <div className="text-xl leading-none">
                    {/* <span className="text-base">Powered by </span> */}
                    <span className="text-sm text-blue-500">
                      Disaster Aware and Ready Communities
                    </span>
                  </div>
                  {/* <div className="mt-1.5 text-xs">Step 3 of 3</div>
                <div className="ml-1 mt-1.5 text-base leading-none">
                  Create account as a
                  <span className="ml-1 text-blue-500">{userType}</span>
                </div> */}
                </div>
              </div>
              <div className="max-md:px-5 flex max-w-[483px] flex-col justify-center rounded-2xl bg-white p-8">
                <h2 className="max-md:max-w-full text-xl font-semibold leading-tight text-slate-600">
                  Duplicate Address Verification
                </h2>

                <div className="mt-4 flex">
                  <div>
                    <img
                      loading="lazy"
                      src={userIcon}
                      className="aspect-[0.99] w-[40px] object-contain"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="max-md:max-w-full whitespace-nowrap text-base font-bold text-blue-600">
                      {userDetails.email}
                    </p>
                    <p
                      className="max-md:max-w-full mt-2 whitespace-nowrap"
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {userDetails.lastName
                        ? userDetails.lastName
                        : "Last Name not provided"}
                      ,
                      {userDetails.firstName
                        ? userDetails.firstName
                        : "First Name not provided"}
                    </p>
                  </div>
                </div>

                <section className="max-md:max-w-full mt-2.5  flex w-full flex-col justify-center">
                  <p className="font-small max-md:max-w-full text-slate-600">
                    Your address has been flagged as a duplicate address. Please
                    enter the confirmation code sent to your household member's
                    email.
                  </p>
                  <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-7">
                    <input
                      type="text"
                      aria-label="Confirmation code"
                      className="h-[40px] w-full border border-[#D5D7DB] p-[10px] pr-[40px] focus:outline-none"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                  <div className="max-md:max-w-full mt-2.5 flex w-full items-center justify-center gap-2.5 text-base font-medium">
                    {isInvalidCode ? (
                      <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                        You have entered an invalid code!
                      </div>
                    ) : (
                      <div className="my-auto flex-1 shrink basis-0 self-stretch text-red-800">
                        {" "}
                      </div>
                    )}
                  </div>
                  <div
                    className="flex max-w-[483px] flex-col justify-center tracking-wide"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="max-md:max-w-full mt-3 flex w-full flex-col justify-center text-center">
                      <button
                        className="max-md:max-w-full bg-login-btn w-full gap-2.5 self-stretch whitespace-nowrap rounded-md p-2.5 hover:cursor-pointer"
                        onClick={onContinue}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="bg-login-right-panel block w-[350px] rounded-r-lg p-4 leading-4 mq700:hidden">
              <h3 className="pt-10 leading-relaxed text-white">
                Welcome to the DARCI Program, powered by Blue Sky.
                <br />
                <br />
                We are here to support your efforts in strengthening community
                preparedness and recovery.
                <br />
                <br />
                Access comprehensive tools, resources, and insights to enhance
                your leadership, response, and recovery capabilities.
                <br />
                <br />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddressVerify.propTypes = {
  className: PropTypes.string,
};

export default AddressVerify;
