import React, { useState, useEffect, useCallback } from "react";
import {
  Map as VISGoogleMap,
  APIProvider,
  Marker,
} from "@vis.gl/react-google-maps";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { config } from "../../../utils/config";

const DisasterAffectedCounty = ({ propId }) => {
  const DEFAULT_CENTER = { lat: 39.8, lng: -98.57 };
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);

  useEffect(() => {
    const checkGoogleMaps = setInterval(() => {
      if (window.google && window.google.maps) {
        setGoogleLoaded(true);
        clearInterval(checkGoogleMaps);
      }
    }, 100);

    return () => clearInterval(checkGoogleMaps);
  }, []);

  useEffect(() => {
    const fetchDisaster = async () => {
      const id = Array.isArray(propId) ? propId[0] : propId;
      if (!id) {
        console.error(
          "No valid ID provided to DisasterAffectedCounty component."
        );
        return;
      }

      try {
        const docRef = doc(db, "declared-disaster", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (
            data.affected_counties &&
            Array.isArray(data.affected_counties) &&
            data.affected_counties.length > 0 &&
            data.disaster_state &&
            Array.isArray(data.disaster_state)
          ) {
            const countyCoordinates = await Promise.all(
              data.affected_counties.map(async (county, index) => {
                try {
                  const state = data.disaster_state[index] || "";
                  const formattedCounty = `${county}, ${state}, USA`;

                  const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                      formattedCounty
                    )}&key=${config.google_maps}`
                  );

                  const geocodeData = await response.json();

                  if (geocodeData.results.length > 0) {
                    const { lat, lng } =
                      geocodeData.results[0].geometry.location;
                    return { lat, lng, county };
                  } else {
                    console.error(
                      `No coordinates found for: ${formattedCounty}`
                    );
                    return null;
                  }
                } catch (error) {
                  console.error(
                    `Error fetching coordinates for ${county}:`,
                    error
                  );
                  return null;
                }
              })
            );

            const validMarkers = countyCoordinates.filter(
              (item) => item !== null
            );
            setMarkers(validMarkers);

            if (validMarkers.length > 0) {
              const newCenter = {
                lat: validMarkers[0].lat,
                lng: validMarkers[0].lng,
              };
              setCenter(newCenter);
              setIsMapReady(true);
            } else {
              setCenter(DEFAULT_CENTER);
              setIsMapReady(true);
            }
          } else {
            setCenter(DEFAULT_CENTER);
            setIsMapReady(true);
          }
        }
      } catch (error) {
        console.error("Error fetching disaster data:", error);
      }
    };

    fetchDisaster();
  }, [propId]);

  const getMarkerImage = useCallback(() => {
    if (!googleLoaded) return null;
    return {
      url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
      scaledSize: new window.google.maps.Size(35, 35),
      labelOrigin: new window.google.maps.Point(17, -10),
    };
  }, [googleLoaded]);

  return (
    <div className="relative h-[calc(100vh-64px)] w-full">
      <APIProvider apiKey={config.google_maps}>
        {isMapReady ? (
          <VISGoogleMap
            key={`${center.lat}-${center.lng}`}
            defaultZoom={10}
            defaultCenter={center}
            options={{
              disableDefaultUI: true,
              gestureHandling: "greedy",
              styles: [
                { featureType: "poi", stylers: [{ visibility: "off" }] },
                { featureType: "transit", stylers: [{ visibility: "off" }] },
                {
                  featureType: "road",
                  elementType: "labels",
                  stylers: [{ visibility: "off" }],
                },
              ],
            }}
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={getMarkerImage() || undefined}
                label={
                  googleLoaded
                    ? {
                        text: marker.county,
                        fontSize: "14px",
                        fontWeight: "bold",
                      }
                    : undefined
                }
              />
            ))}
          </VISGoogleMap>
        ) : (
          <div className="text-gray-600 mt-10 text-center">Loading map...</div>
        )}
      </APIProvider>
    </div>
  );
};

export default DisasterAffectedCounty;
