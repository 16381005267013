import React, { useState, useRef, useEffect, useCallback } from "react";

import PropTypes from "prop-types";
import { useDisasterContext } from "../pages/Disasters/DisasterContext";
import DisasterManagement from "./Disasters/DisasterManagement";
import AssistanceLog from "./Disasters/AssistanceLog";
import RecoveryPlanDisasters from "./Disasters/RecoveryPlan";
import Journals from "./Disasters/Journals";
import ContactsDisaster from "./Disasters/ContactsDisaster";
import ImmediateResponseSurvey from "./Disasters/ImmediateResponseSurvey";
import Lockbox from "./Lockbox";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ChevronDown } from "lucide-react";
import { disasterAvatar } from "../components/CaseManagementNavbar";
import activeIcon from "../assets/caseManagement/header/active-icon.png";
import inActiveIcon from "../assets/caseManagement/header/in-active-icon.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Disasters = ({ className = "" }) => {
  const [activeTab, setActiveTab] = useState("Immediate Response Survey");
  const { activeDisasterTab, setActiveDisasterTab } = useDisasterContext();
  const [selectedDisaster, setSelectedDisaster] = useState("All");
  const [selectedDisasters, setSelectedDisasters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);
  const [showInactive, setShowInactive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();
  const selectedUserId = sessionStorage.getItem("userId");
  const [userData, setUserData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        setIsLoading(true);

        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        setUserData(userDoc.data());
        const userCounty = userDoc.data()?.county;

        if (!userCounty) {
          console.warn("User county not found");
          setIsLoading(false);
          return;
        }

        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            name: doc.data().disaster_name,
            status: doc.data().disaster_status,
            disaster_type: doc.data().disaster_type,
            county: doc.data().disaster_county,
            state: doc.data().disaster_state,
            affected_counties: doc.data().affected_counties || [],
            disaster_stage: doc.data().disaster_stage,
          }))
          .filter((disaster) => {
            return disaster.affected_counties.includes(userCounty);
          });

        const activeDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "active"
        );
        const inactiveDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "inactive"
        );
        const closedDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "closed"
        );

        const sortedDisasters = [
          ...activeDisasters,
          ...inactiveDisasters,
          ...closedDisasters,
        ];

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setActiveDisasters(activeDisasters.map((disaster) => disaster.id));
        setDisasterOptions(sortedDisasters);
        if (activeDisasters.length > 0) {
          setSelectedDisasters([activeDisasters[0].id]);
        }
      } catch (error) {
        console.error("Error fetching disasters:", error);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };

    fetchDisasters();
  }, [selectedUserId]);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDisasterChange = (disaster) => {
    setSelectedDisasters((prev) => {
      if (disaster === "All") {
        return ["All"];
      }
      const newSelection = prev.includes(disaster)
        ? prev.filter((d) => d !== disaster && d !== "All")
        : [...prev.filter((d) => d !== "All"), disaster];
      return newSelection.length === 0 ? ["All"] : newSelection;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [caseNumber, setCaseNumber] = useState("");
  const [femaNumber, setFemaNumber] = useState("");
  const [disasterNumber, setDisasterNumber] = useState("");

  useEffect(() => {
    const fetchCaseNumber = async () => {
      if (selectedUserId && selectedDisasters.length > 0) {
        // Clear case number if "All" is selected
        if (selectedDisasters.includes("All")) {
          setCaseNumber("");
          setFemaNumber("");
          setDisasterNumber("");
          return;
        }

        try {
          // Get all survey responses for the user
          const surveyQuery = query(
            collection(db, `users/${selectedUserId}/survey_responses`)
          );

          const querySnapshot = await getDocs(surveyQuery);
          let foundCaseNumber = "";
          let foundFemaNumber = "";
          let foundDisasterNumber = "";

          // Look for a survey that contains any of the selected disasters
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.disasters) {
              const surveyDisasterIds = data.disasters.map(
                (d) => d.disaster_id
              );

              // Check if any of the selected disasters are in this survey
              const selectedDisastersInSurvey = selectedDisasters.filter((id) =>
                surveyDisasterIds.includes(id)
              );

              // If all selected disasters are in this survey response
              if (
                selectedDisastersInSurvey.length === selectedDisasters.length
              ) {
                foundCaseNumber = data.case_number;
                console.log(
                  "Found case number that covers all selected disasters:",
                  foundCaseNumber
                );
                foundFemaNumber = data.fema_number;
                foundDisasterNumber = data.disaster_number;
              }
            }
          });

          setCaseNumber(foundCaseNumber);
          setFemaNumber(foundFemaNumber);
          setDisasterNumber(foundDisasterNumber);
        } catch (error) {
          console.error("Error fetching case number:", error);
          setCaseNumber("");
        }
      } else {
        setCaseNumber("");
      }
    };

    fetchCaseNumber();
  }, [selectedUserId, selectedDisasters]);

  const [caseStatus, setCaseStatus] = useState(null);

  // Add this useEffect after your existing case number useEffect
  useEffect(() => {
    const fetchCaseStatus = async () => {
      if (selectedUserId && selectedDisasters.length === 1 && caseNumber) {
        try {
          const caseManagementRef = collection(db, "case_management");
          const caseQuery = query(
            caseManagementRef,
            where("case_number", "==", caseNumber)
          );

          const caseSnapshot = await getDocs(caseQuery);

          if (!caseSnapshot.empty) {
            const caseData = caseSnapshot.docs[0].data();
            setCaseStatus(caseData.status);
            console.log("Case Status:", caseData.status);
          } else {
            console.log(
              "No case management record found for case number:",
              caseNumber
            );
            setCaseStatus(null);
          }
        } catch (error) {
          console.error("Error fetching case status:", error);
          setCaseStatus(null);
        }
      }
    };

    fetchCaseStatus();
  }, [selectedUserId, selectedDisasters, caseNumber]);

  const tabs = [
    "Immediate Response Survey",
    "Damage Assessment",
    "Assistance Log",
    "Unmet Needs",
    "Contacts",
    "Journals",
  ];
  const handleTabClick = (tab) => {
    setActiveDisasterTab(tab);
  };

  const onVisitSurvivors = useCallback(() => {
    const urlQueryParams = new URLSearchParams();
    urlQueryParams.set("tab", 2);

    navigate(`/citizenprofile`);
  }, []);

  if (isLoading) {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
        <div className="text-2xl font-bold text-blue-500">Loading...</div>
      </div>
    );
  }

  const disasterToDisplay = disasterOptions.find(
    (disaster) => disaster.id === selectedDisasters[0]
  );

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      {/* <div className="mb-4 flex w-full flex-row items-center justify-between">
        <div className="mt-3 flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
          <a className="relative inline-block min-w-[80px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Recovery Tracker
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[55px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Disasters
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[120px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            {activeDisasterTab}
          </a>
        </div>

        <div className="flex items-center space-x-4 text-xs">
          <div className="flex items-center">
            <span className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-green-500 font-bold text-white">
              C
            </span>
            <span>Center</span>
          </div>
          <div className="flex items-center">
            <span className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-yellow-500 font-bold text-white">
              P
            </span>
            <span>Partner</span>
          </div>
          <div className="flex items-center">
            <span className="mr-1 flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 font-bold text-white">
              S
            </span>
            <span>Survivor</span>
          </div>
        </div>
      </div> */}

      {/* Disaster selector and tabs */}
      <div className="flex max-w-full flex-row items-center justify-between gap-5 self-stretch mq1600:flex-wrap">
        {/* Disaster selector */}
        {/* <div className="relative flex items-center gap-2" ref={dropdownRef}>
          <span className="text-sm font-bold text-red-500">DISASTERS</span>
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center justify-between rounded border border-dodgerblue px-4 py-1 text-sm w-[450px] relative"
            >
              <span className="absolute inset-0 flex items-center justify-center">
                <span className="truncate px-6">
                  {selectedDisasters.includes("All") 
                    ? "All Disasters" 
                    : selectedDisasters.length > 0
                      ? disasterOptions
                          .filter(disaster => selectedDisasters.includes(disaster.id))
                          .map(disaster => disaster.name)
                          .join(", ")
                      : "Select Disasters"}
                </span>
              </span>
              <span className="ml-auto">▼</span>
            </button>
            {isDropdownOpen && (
              <div className="absolute left-0 right-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-10">
                {disasterOptions.map((disaster) => (
                  <label key={disaster.id} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedDisasters.includes(disaster.id)}
                      onChange={() => handleDisasterChange(disaster.id)}
                      className="mr-2"
                    />
                    {disaster.name}
                  </label>
                ))}
              </div>
            )}
          </div>
        </div> */}
        <div
          className="fixed left-0 top-0 z-20 ml-[90vh] flex items-center justify-center gap-1 p-1"
          ref={dropdownRef}
        >
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="relative flex items-center justify-between gap-1.5 bg-transparent px-0 py-2 text-[17px] text-white"
            >
              {/* Disaster Name - Aligned to Left */}
              <span className="truncate">
                {selectedDisasters.length > 0 ? (
                  <div className=" flex flex-row items-center gap-2">
                    <div
                      className="h-[28px] w-[28px] overflow-hidden rounded-full p-1"
                      style={{
                        backgroundColor:
                          disasterAvatar?.[disasterToDisplay.disaster_type]
                            ?.color,
                      }}
                    >
                      <img
                        src={
                          disasterAvatar?.[disasterToDisplay.disaster_type]
                            ?.icon
                        }
                        className="h-full w-full object-contain"
                      />
                    </div>
                    <div>{`${disasterToDisplay?.name} - ${disasterToDisplay?.disaster_type}`}</div>
                  </div>
                ) : (
                  "Select a Disaster"
                )}
              </span>

              {/* Right Section - Status and Dropdown Arrow */}
              {/* <div className="flex items-center gap-3">
                {selectedDisasters.length > 0 &&
                  (() => {
                    const disaster = disasterOptions.find(
                      (d) => d.id === selectedDisasters[0]
                    );
                    return (
                      disaster && (
                        <div
                          className={`
                            flex items-center gap-2 rounded-md px-3 py-1
                            ${
                              disaster.status === "active"
                                ? "bg-[#E6FEEB] text-[#0A8525]"
                                : disaster.status === "inactive"
                                ? "bg-[#FFEAEA] text-[#CE2222]"
                                : "bg-[#E3E3E3] text-[#7B7B7B]"
                            }
                          `}
                        >
                          <span className=" capitalize text-xs">{disaster.status}</span>
                          <span
                            className={`h-2 w-2 rounded-full ${
                              disaster.status === "active"
                                ? "bg-[#0A8525]"
                                : disaster.status === "inactive"
                                ? "bg-[#CE2222]"
                                : "bg-[#7B7B7B]"
                            }`}
                          ></span>
                        </div>
                      )
                    );
                  })()}
              </div> */}
              <ChevronDown className=" h-4 w-4" strokeWidth={2} />

              <div className="h-[28px] w-[28px] brightness-125">
                <img
                  src={
                    disasterToDisplay?.status === "active"
                      ? activeIcon
                      : inActiveIcon
                  }
                  className="h-full w-full object-contain"
                />
              </div>
            </button>

            {isDropdownOpen && (
              <div className="absolute left-1/2 z-10 mt-1 min-w-[400px] -translate-x-1/2 rounded border border-gray-300 bg-white shadow-lg">
                <div className="border-b border-gray-200 p-2">
                  <label className="text-gray-600 flex cursor-pointer items-center text-sm">
                    <input
                      type="checkbox"
                      checked={showInactive}
                      onChange={(e) => setShowInactive(e.target.checked)}
                      className="mr-2"
                    />
                    Show Inactive/Closed Disasters
                  </label>
                </div>

                <div className="max-h-60 overflow-y-auto">
                  {disasterOptions
                    .filter((disaster) =>
                      showInactive ? true : disaster.status === "active"
                    )
                    .map((disaster) => (
                      <div
                        key={disaster.id}
                        className={`hover:bg-gray-100 cursor-pointer px-4 py-2 ${
                          selectedDisasters[0] === disaster.id
                            ? "bg-blue-50"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedDisasters([disaster.id]);
                          setIsDropdownOpen(false);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <span>{disaster.name}</span>
                          <div
                            className={`flex items-center gap-1 ${
                              disaster.status === "active"
                                ? "text-[#0A8525]"
                                : disaster.status === "inactive"
                                ? "text-[#CE2222]"
                                : "text-[#7B7B7B]"
                            }`}
                          >
                            <span className="text-xs">
                              {disaster.status === "active"
                                ? "Active"
                                : disaster.status === "inactive"
                                ? "Inactive"
                                : "Closed"}
                            </span>
                            <span
                              className={`h-2 w-2 rounded-full ${
                                disaster.status === "active"
                                  ? "bg-[#0A8525]"
                                  : disaster.status === "inactive"
                                  ? "bg-[#CE2222]"
                                  : "bg-[#7B7B7B]"
                              }`}
                            ></span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Tabs */}
        {/* <div className=" flex w-full flex-row items-center justify-between">
          <div className="font-Inter relative flex-1">
            {caseNumber && (
              <div className="flex w-full flex-col">
                <p className="text-gray-600 text-sm font-semibold">
                  Case Number:
                  <span className="text-[16px]">{caseNumber}</span>
                </p>
                <p className="mt-1 text-sm text-blue-600">
                  {selectedDisasters.length > 1
                    ? "This case number covers all selected disasters"
                    : ""}
                </p>
              </div>
            )}
            {femaNumber}
            {disasterNumber}
          </div>
          <div className="flex flex-wrap gap-2 text-xs">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => handleTabClick(tab)}
                className={`rounded-full px-4 py-1 ${
                  activeDisasterTab === tab
                    ? "btn-default"
                    : "btn-default-border hover:bg-blue-100"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div> */}
      </div>

      {/* <div className="relative inline-block max-w-full font-poppins font-medium leading-[21px] text-darkslategray-200">
        <p className="m-0 text-sm font-medium">
          Below are the essential tools at your disposal to effectively track
          your disaster recovery journey.
        </p>
        <p className="m-0 text-sm font-medium">
          Utilize the Damage Assessment tool to record losses, document
          assistance received in the Assistance Log, and track your progress
          towards recovery by monitoring your remaining deficit in the Recovery
          Plan.
        </p>
      </div> */}
      <div className="-mt-2 mb-2 flex w-full items-center justify-between">
        <div className="text-3xl">
          <button
            className="cursor-pointer bg-transparent"
            onClick={onVisitSurvivors}
          >
            <p className="space-x-1 text-[16px] font-bold">
              {userData.first_name || ""} {userData.last_name || ""}
            </p>
          </button>
          {caseNumber && (
            <span className="text-[#525252]">
              {" "}
              (Case No. {caseNumber || ""})
            </span>
          )}
        </div>
        <div className="mr-3 flex items-center space-x-3">
          <div>
            <div className="flex gap-1">
              <p className="w-24 text-right text-[14px] text-[#525252]">
                Disaster No.
              </p>
              <p className="text-[14px] text-[#525252]">
                {disasterNumber || ""}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="w-24 text-right text-[14px] text-[#525252]">
                FEMA No.
              </p>
              <p className="text-[14px] text-[#525252]">{femaNumber || ""}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="-mt-2 mb-2 flex w-full flex-row items-center gap-4">
        <div className="flex items-center gap-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_9807_680"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_9807_680)">
              <path
                d="M4 22.0031C3.71667 22.0031 3.47917 21.9073 3.2875 21.7156C3.09583 21.524 3 21.2865 3 21.0031V17.0031C3 16.4531 3.19583 15.9823 3.5875 15.5906C3.97917 15.199 4.45 15.0031 5 15.0031H19C19.55 15.0031 20.0208 15.199 20.4125 15.5906C20.8042 15.9823 21 16.4531 21 17.0031V21.0031C21 21.2865 20.9042 21.524 20.7125 21.7156C20.5208 21.9073 20.2833 22.0031 20 22.0031H4ZM5 13.0031V10.0031C5 9.45313 5.19583 8.98229 5.5875 8.59063C5.97917 8.19896 6.45 8.00313 7 8.00313H11V6.55313C10.7 6.35313 10.4583 6.11146 10.275 5.82813C10.0917 5.54479 10 5.20313 10 4.80313C10 4.55313 10.05 4.30729 10.15 4.06563C10.25 3.82396 10.4 3.60313 10.6 3.40313L11.65 2.35313C11.6833 2.31979 11.8 2.26979 12 2.20312C12.0333 2.20312 12.15 2.25312 12.35 2.35313L13.4 3.40313C13.6 3.60313 13.75 3.82396 13.85 4.06563C13.95 4.30729 14 4.55313 14 4.80313C14 5.20313 13.9083 5.54479 13.725 5.82813C13.5417 6.11146 13.3 6.35313 13 6.55313V8.00313H17C17.55 8.00313 18.0208 8.19896 18.4125 8.59063C18.8042 8.98229 19 9.45313 19 10.0031V13.0031H5Z"
                fill="#770C0E"
              />
            </g>
          </svg>

          {userData.birth_date && (
            <span>
              {new Date(userData.birth_date).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </span>
          )}
        </div>
        <div className="flex items-center gap-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_9807_685"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_9807_685)">
              <path
                d="M10.5 20H13.5C13.6333 20 13.75 19.95 13.85 19.85C13.95 19.75 14 19.6333 14 19.5C14 19.3667 13.95 19.25 13.85 19.15C13.75 19.05 13.6333 19 13.5 19H10.5C10.3667 19 10.25 19.05 10.15 19.15C10.05 19.25 10 19.3667 10 19.5C10 19.6333 10.05 19.75 10.15 19.85C10.25 19.95 10.3667 20 10.5 20ZM7 23C6.45 23 5.97917 22.8042 5.5875 22.4125C5.19583 22.0208 5 21.55 5 21V3C5 2.45 5.19583 1.97917 5.5875 1.5875C5.97917 1.19583 6.45 1 7 1H17C17.55 1 18.0208 1.19583 18.4125 1.5875C18.8042 1.97917 19 2.45 19 3V21C19 21.55 18.8042 22.0208 18.4125 22.4125C18.0208 22.8042 17.55 23 17 23H7ZM7 16H17V6H7V16Z"
                fill="#770C0E"
              />
            </g>
          </svg>

          <span>{userData.mobile_number || ""}</span>
        </div>
        <div className="flex items-center gap-2">
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM10 8.825C10.0833 8.825 10.1708 8.8125 10.2625 8.7875C10.3542 8.7625 10.4417 8.725 10.525 8.675L17.6 4.25C17.7333 4.16667 17.8333 4.0625 17.9 3.9375C17.9667 3.8125 18 3.675 18 3.525C18 3.19167 17.8583 2.94167 17.575 2.775C17.2917 2.60833 17 2.61667 16.7 2.8L10 7L3.3 2.8C3 2.61667 2.70833 2.6125 2.425 2.7875C2.14167 2.9625 2 3.20833 2 3.525C2 3.69167 2.03333 3.8375 2.1 3.9625C2.16667 4.0875 2.26667 4.18333 2.4 4.25L9.475 8.675C9.55833 8.725 9.64583 8.7625 9.7375 8.7875C9.82917 8.8125 9.91667 8.825 10 8.825Z"
              fill="#770C0E"
            />
          </svg>
          <span>{userData.email || ""}</span>
        </div>
      </div>

      {activeDisasterTab === "Immediate Response Survey" && (
        <ImmediateResponseSurvey
          selectedDisasters={selectedDisasters}
          activeDisasters={disasterOptions}
          parentSetCaseNumber={setCaseNumber}
          caseStatus={caseStatus}
        />
      )}
      {activeDisasterTab === "Damage Assessment" && (
        <DisasterManagement
          selectedDisasters={selectedDisasters}
          activeDisasters={activeDisasters}
          disasterOptions={disasterOptions.filter((d) => d.id !== "All")}
          caseStatus={caseStatus}
        />
      )}
      {activeDisasterTab === "Assistance Log" && (
        <AssistanceLog
          selectedDisasters={selectedDisasters}
          activeDisasters={activeDisasters}
          caseStatus={caseStatus}
        />
      )}
      {activeDisasterTab === "Unmet Needs" && (
        <RecoveryPlanDisasters
          selectedDisasters={selectedDisasters}
          activeDisasters={disasterOptions}
          caseStatus={caseStatus}
          caseNumber={caseNumber}
          femaNumber={femaNumber}
          disasterNumber={disasterNumber}
        />
      )}
      {activeDisasterTab === "Contacts" && (
        <ContactsDisaster
          selectedDisasters={selectedDisasters}
          activeDisasters={disasterOptions}
          caseStatus={caseStatus}
        />
      )}
      {activeDisasterTab === "Journals" && (
        <Journals
          disasterStage={
            disasterOptions.find(
              (disaster) => disaster.id === selectedDisasters[0]
            )?.disaster_stage
          }
          selectedDisasters={selectedDisasters}
          activeDisasters={disasterOptions}
          selectedDisaster={disasterOptions.find(
            // This should be an object
            (disaster) => disaster.id === selectedDisasters[0]
          )}
          caseStatus={caseStatus}
        />
      )}
      {activeDisasterTab === "Lockbox" && <Lockbox />}
    </div>
  );
};

ImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default Disasters;
