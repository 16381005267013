import React, { useState, useRef, useEffect } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
  ColumnMenu,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  getDoc,
  doc,
  collection,
  where,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import DatePickerTemplate from "../../components/DatePickerTemplate";

// Helper functions
const formatPhoneNumber = (number) => {
  if (!number) return "";
  const numberStr = number.toString();
  return (
    <div>
      <span style={{ letterSpacing: "0.01em" }}>
        {"*".repeat(numberStr.length - 4)}
      </span>
      {numberStr.slice(-4)}
    </div>
  );
};

const formatEmail = (email) => {
  if (!email) return "";
  const [localPart, domain] = email.split("@");
  if (!localPart || !domain) return email;
  return (
    <div>
      {localPart[0]}
      <span style={{ letterSpacing: "0.01em" }}>
        {"*".repeat(localPart.length - 2)}
      </span>
      {localPart[localPart.length - 1]}@{domain}
    </div>
  );
};

const fetchPartnerData = async (userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docc = await getDoc(docRef);

    if (!docc.exists()) {
      console.error("No such document!");
      return null;
    }

    return { id: docc.id, ...docc.data() };
  } catch (error) {
    console.error("Error fetching data: ", error);
    return null;
  }
};

const searchCitizens = async (searchParams) => {
  const { firstName, lastName, birthDate, centerId } = searchParams;

  if (!firstName || !lastName || !birthDate || !centerId) {
    console.error("Missing required search parameters");
    return [];
  }

  const usersRef = collection(db, "users");
  const queryRef = query(
    usersRef,
    where("center_id", "in", centerId),
    where("user_role", "==", "citizen"),
    where("first_name", "==", firstName),
    where("last_name", "==", lastName),
    where("birth_date", "==", birthDate)
  );

  const querySnapshot = await getDocs(queryRef);

  return querySnapshot.docs.map((doc) => ({
    citizen_id: doc.id,
    name: `${doc.data().first_name} ${doc.data().last_name}`,
    address: `${doc.data().city}, ${doc.data().state}`,
    mobile_number: doc.data().mobile_number,
    email: doc.data().email,
    birth_date: doc.data().birth_date,
  }));
};

const updateAccessedUserId = async (userId, citizenId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, { accessed_userId: citizenId });
    const updatedDoc = await getDoc(userDocRef);
    return updatedDoc.data();
  } catch (error) {
    console.error("Error updating 'accessed_userId': ", error);
    throw error;
  }
};

const validateFields = (firstName, lastName, birthDate) => {
  const errors = {};

  if (!firstName.trim()) errors.firstName = "First name is required";
  if (!lastName.trim()) errors.lastName = "Last name is required";
  if (!birthDate) errors.birthDate = "Birth date is required";

  return errors;
};

const PartnerCitizenSearch = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(true); // NEW: Loading state
  const gridRef = useRef(null);
  const selectedUserId = sessionStorage.getItem("userId");

  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchLastName, setSearchLastName] = useState("");
  const [searchBirthDate, setSearchBirthDate] = useState("");

  const [validationErrors, setValidationErrors] = useState({});
  const [centerId, setCenterId] = useState(null);

  // Add ref for the timeout
  const errorTimeoutRef = useRef(null);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, []);

  // Monitor authentication status
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
      setLoading(false); // End loading after auth state is checked
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const mapCitizenData = (citizens) => {
    return citizens.map((citizen) => ({
      name: `${citizen.first_name} ${citizen.last_name}`,
      address: `${citizen.city},${" "} ${citizen.state}`,
      mobile_number: citizen.mobile_number,
      email: citizen.email,
      citizen_id: citizen.citizen_id,
    }));
  };

  const fetchData = async () => {
    const data = await fetchPartnerData(userId);
    if (data) {
      setData(data);
      setCenterId(data.center_id);
    }
  };

  const navigate = useNavigate();

  const handleViewAssistanceLog = async (args) => {
    try {
      if (loading || !userId || !gridRef.current) return;

      const rowData = args.rowData;
      if (!rowData?.citizen_id) return;

      const updatedData = await updateAccessedUserId(
        userId,
        rowData.citizen_id
      );
      navigate("/partnerassistancelog");
    } catch (error) {
      console.error("Error in handleViewAssistanceLog: ", error);
    }
  };

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };
  const [isGridVisible, setIsGridVisible] = useState(true);
  const toggleGrid = (event) => {
    event.preventDefault();
    setIsGridVisible(!isGridVisible);
  };

  const handleCommandClick = (args) => {
    if (args.commandColumn.type === "Access") {
      const citizenData = args.rowData;
      localStorage.setItem("accessAccountEmail", citizenData.email);
      localStorage.setItem("orgUserId", userId);
      localStorage.setItem("orgType", "partner");
      window.open(window.location.origin + "/accessaccount", "_blank");
    } else if (args.commandColumn.type === "View") {
      handleViewAssistanceLog(args);
    }
  };

  const mobileNumberTemplate = (props) =>
    formatPhoneNumber(props.mobile_number);
  const emailTemplate = (props) => formatEmail(props.email);

  const validateSearchFields = () => {
    const errors = validateFields(
      searchFirstName,
      searchLastName,
      searchBirthDate
    );
    setValidationErrors(errors);

    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }

    if (Object.keys(errors).length > 0) {
      errorTimeoutRef.current = setTimeout(() => {
        setValidationErrors({});
      }, 3000);
    }

    return Object.keys(errors).length === 0;
  };

  const handleSearch = async () => {
    if (!validateSearchFields()) return;

    try {
      const searchParams = {
        firstName: searchFirstName.trim(),
        lastName: searchLastName.trim(),
        birthDate: searchBirthDate,
        centerId,
      };

      const results = await searchCitizens(searchParams);
      setGridData(results);
    } catch (error) {
      console.error("Error searching users: ", error);
      setGridData([]);
    }
  };

  const handleClearSearch = () => {
    setSearchFirstName("");
    setSearchLastName("");
    setSearchBirthDate("");
    setValidationErrors({});
    setGridData([]);

    // Clear any existing timeout
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
  };

  const renderSearchFields = () => {
    return (
      <div className=" flex flex-wrap items-start gap-4">
        <div className="flex flex-col">
          <label className="text-gray-600 mb-1 text-xs">
            First Name
            <span className="ml-1 text-red-500">*</span>
          </label>
          <TextBoxComponent
            placeholder="Search first name"
            value={searchFirstName}
            change={(e) => {
              if (errorTimeoutRef.current) {
                clearTimeout(errorTimeoutRef.current);
              }
              setSearchFirstName(e.value);
              setValidationErrors((prev) => ({ ...prev, firstName: "" }));
            }}
            cssClass={`e-outline ${
              validationErrors.firstName ? "e-error" : ""
            }`}
            floatLabelType="Never"
            width="200px"
            keyUp={(e) => {
              if (e.keyCode === 13) handleSearch();
            }}
          />
          {validationErrors.firstName && (
            <div className="mt-1 text-xs text-red-500">
              {validationErrors.firstName}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 mb-1 text-xs">
            Last Name
            <span className="ml-1 text-red-500">*</span>
          </label>
          <TextBoxComponent
            placeholder="Search last name"
            value={searchLastName}
            change={(e) => {
              if (errorTimeoutRef.current) {
                clearTimeout(errorTimeoutRef.current);
              }
              setSearchLastName(e.value);
              setValidationErrors((prev) => ({ ...prev, lastName: "" }));
            }}
            cssClass={`e-outline ${validationErrors.lastName ? "e-error" : ""}`}
            floatLabelType="Never"
            width="200px"
            keyUp={(e) => {
              if (e.keyCode === 13) handleSearch();
            }}
          />
          {validationErrors.lastName && (
            <div className="mt-1 text-xs text-red-500">
              {validationErrors.lastName}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600 text-xs">
            Birth Date
            <span className="ml-1 text-red-500">*</span>
          </label>
          <div className="-mt-1">
            <DatePickerTemplate
              value={searchBirthDate}
              onChange={(val) => {
                setSearchBirthDate(val);
                setValidationErrors((prev) => ({ ...prev, birthDate: "" }));
              }}
              key={searchBirthDate}
            />
            {validationErrors.birthDate && (
              <div className="mt-1 text-xs text-red-500">
                {validationErrors.birthDate}
              </div>
            )}
          </div>
          <div className="absolute ml-[270px] mt-[20px] flex items-start gap-2">
            <button
              type="button"
              className="btn-default h-[32px] px-4 hover:cursor-pointer"
              onClick={handleSearch}
            >
              Search
            </button>
            <button
              type="button"
              className="btn-cancel h-[32px] px-4 hover:cursor-pointer"
              onClick={handleClearSearch}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div className="mt-2 flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Citizen Search
        </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        {/*
        <header className="flex w-full justify-between items-baseline">
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
              onClick={toggleGrid}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isGridVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        */}
        <p className="relative text-sm font-medium leading-[21px]">
          Partners only have access to citizens that have authorized their
          information to be shared with a partner.
        </p>

        {isGridVisible &&
          !loading && ( // Show the grid only when it's visible and not loading
            <div className="mt-2 flex flex-col gap-4 self-stretch">
              {renderSearchFields()}
              <GridComponent
                ref={gridRef}
                dataSource={gridData}
                allowSorting={true}
                sortSettings={sortSettings}
                filterSettings={filterSettings}
                allowFiltering={true}
                allowPaging={true}
                pageSettings={pageSettings}
                showColumnMenu={true}
                columnMenuItems={columnMenuItems}
                commandClick={handleCommandClick}
                allowSelection={true}
                selectionSettings={{ type: "Single" }}
              >
                <ColumnsDirective>
                  <ColumnDirective field="name" headerText="Name" width="150" />
                  <ColumnDirective
                    field="mobile_number"
                    headerText="Mobile Number"
                    width="150"
                    template={mobileNumberTemplate}
                  />
                  <ColumnDirective
                    field="email"
                    headerText="Email Address"
                    width="200"
                    template={emailTemplate}
                  />
                  <ColumnDirective
                    field="address"
                    headerText="Location"
                    width="150"
                  />
                  <ColumnDirective field="accessed_userId" visible={false} />
                  <ColumnDirective
                    headerText="Actions"
                    textAlign="Center"
                    width="250"
                    commands={[
                      {
                        type: "View",
                        buttonOption: {
                          content:
                            '<div style="line-height: 1.2">View<br>Assistance Log</div>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      {
                        type: "Access",
                        buttonOption: {
                          content:
                            '<div style="line-height: 1.2">Access<br>Account</div>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                    ]}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    Sort,
                    Filter,
                    ColumnMenu,
                    CommandColumn,
                  ]}
                />
              </GridComponent>
            </div>
          )}
      </section>
    </div>
  );
};

export default PartnerCitizenSearch;
