import React, { useState, useEffect, useRef } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import { registerLicense } from "@syncfusion/ej2-base";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Toolbar,
  Filter,
  Sort,
  Page,
  CommandColumn,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../firebase"; // Import Firebase config
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "../style.css";
import { config } from "../../utils/config";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const editOptions = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
  dialog: {
    cssClass: "donorpartner-dialog",
    style: { maxHeight: "900px", height: "auto" },
  },
};

const filterSettings = {
  type: "CheckBox",
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const preprocessData = (data) => {
  return data.map((item) => ({
    ...item,
    Name: item.company_name,
    Address: item.address,
  }));
};

const DonorPartners = ({ className = "" }) => {
  const [donorPartners, setDonorPartners] = useState([]);
  const gridRef = useRef(null);
  const [lastEditedTime, setLastEditedTime] = useState(null);
  const [userId, setUserId] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && selectedUserId) {
        setUserId(selectedUserId);
        console.log("User ID:", selectedUserId);
        fetchDonorPartners();
      } else {
        setUserId(null);
        setDonorPartners([]);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const fetchDonorPartners = async () => {
    if (!userId) {
      console.log("No user ID available, skipping fetch.");
      return;
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(db, "donor-partners"), where("centerId", "==", userId))
      );
      const donorPartnersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("Fetched donor partners:", donorPartnersData);
      setDonorPartners(preprocessData(donorPartnersData));

      // Set column visibility after fetching contacts
      if (gridRef.current) {
        const cols = gridRef.current.columns;
        for (const col of cols) {
          if (
            ["address1", "address2", "city", "state", "zip"].includes(col.field)
          ) {
            col.visible = false; // Set to false for columns you want hidden
          } else {
            col.visible = true; // Set to true for columns you want visible
          }
        }
        gridRef.current.refresh(); // Refresh the grid to apply changes
      }
    } catch (error) {
      console.error("Error fetching donor partners:", error);
    }
  };

  const handleAdd = () => {
    if (gridRef.current) {
      gridRef.current.addRecord();
    }
  };

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  const actionBegin = async (args) => {
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      setTimeout(() => {
        if (args.dialog) {
          // Set the dialog header based on the action
          if (args.rowData && args.rowData.company_name) {
            args.dialog.header = "Edit Donor Details"; // Set the desired header for edit
          } else {
            args.dialog.header = "Add Donor"; // Set the desired header for add
          }
          // Set the dialog height
          args.dialog.element.style.maxHeight = "900px"; // Set max height
          args.dialog.element.style.height = "auto"; // Set height to auto
        }
      }, 0); // Delay execution to allow dialog to initialize
    }
    if (gridRef.current) {
      try {
        if (args.requestType === "beginEdit" || args.requestType === "add") {
          const cols = gridRef.current.columns;
          for (const col of cols) {
            if (
              [
                "company_name",
                "email",
                "phone",
                "address1",
                "address2",
                "city",
                "state",
                "zip",
              ].includes(col.field)
            ) {
              col.visible = true;
            } else if (["Name", "Address"].includes(col.field)) {
              col.visible = false;
            }
          }
        }

        if (args.requestType === "save") {
          setLastEditedTime(new Date());

          // Update Name and Address fields
          const data = args.data;
          data.Name = data.company_name; // Ensure Name is set correctly
          data.Address = `${data.address1 || ""} ${data.address2 || ""} ${
            data.city || ""
          } ${data.state || ""} ${data.zip || ""}`;

          // Only attempt geocoding if we have the minimum required address fields
          let lat = null,
            lng = null;
          if (data.address1 && data.city && data.state && data.zip) {
            const address = `${data.address1}, ${data.city}, ${data.state}, ${data.zip}`;
            try {
              const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                  address
                )}&key=${config.google_maps}`
              );
              const geoData = await response.json();

              if (geoData.results && geoData.results.length > 0) {
                lat = geoData.results[0].geometry.location.lat;
                lng = geoData.results[0].geometry.location.lng;
              } else {
                console.warn(
                  "No geocoding results found for address:",
                  address
                );
              }
            } catch (err) {
              console.error("Error fetching geolocation:", err);
            }
          }

          // Remove undefined fields and ensure lat/lng are null if not found
          const cleanedData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== undefined)
          );

          if (args.action === "add") {
            console.log("Adding new donor partner:", cleanedData);
            await addDoc(collection(db, "donor-partners"), {
              ...cleanedData,
              centerId: userId,
              last_updated: serverTimestamp(),
              inserted_at: serverTimestamp(),
              is_deleted: false,
              latitude: lat, // Ensure latitude is saved
              longitude: lng, // Ensure longitude is saved
            });
            await fetchDonorPartners();
          } else if (args.action === "edit") {
            if (data.id) {
              // Log the data being sent to Firestore
              console.log("Updating donor partner with ID:", data.id);
              console.log("Data being sent:", {
                ...data,
                latitude: lat,
                longitude: lng,
              });

              const contactDoc = doc(db, "donor-partners", data.id);
              await updateDoc(contactDoc, {
                ...data,
                centerId: userId,
                last_updated: serverTimestamp(),
                is_deleted: false,
                latitude: lat, // Ensure latitude is updated
                longitude: lng, // Ensure longitude is updated
              });
              console.log("Successfully updated donor partner:", data);
            } else {
              console.error("Document ID is missing for update.");
            }
            await fetchDonorPartners();
          }
        }

        if (args.requestType === "delete") {
          const data = args.data[0];
          if (data.id) {
            const contactDoc = doc(db, "donor-partners", data.id);
            await updateDoc(contactDoc, {
              is_deleted: true,
              last_updated: serverTimestamp(),
            });
          } else {
            console.error("Document ID is missing for delete.");
          }
          await fetchDonorPartners();
        }
      } catch (error) {
        console.error("Error during actionBegin:", error);
      }
    }
  };

  return (
    <>
      <div
        className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
      >
        <div id="dialog-container"></div>
        <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
          <a className="relative inline-block min-w-[40px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Database
          </a>
          <div className="relative inline-block min-w-[5px] text-left font-mulish text-base font-semibold leading-[16px] text-gray-400">
            /
          </div>
          <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
            Donor Partners
          </a>
        </div>
        <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4">
          <header className="mb-2 flex flex-row items-center justify-between self-stretch">
            <div>
              <h2 className="mb-1 text-sm font-bold text-blue-500">
                Donor Partners
              </h2>
            </div>
            <div className="flex items-center gap-2">
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-2 px-3 py-1.5"
                onClick={handleAdd}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>
            </div>
          </header>
          <div>
            <GridComponent
              dataSource={donorPartners.filter(
                (contact) => contact.is_deleted !== true
              )}
              actionBegin={actionBegin}
              editSettings={editOptions}
              filterSettings={filterSettings}
              allowFiltering={true}
              allowSorting={true}
              ref={gridRef}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="company_name"
                  headerText="Company Name"
                  width="150"
                />
                <ColumnDirective field="email" headerText="Email" width="150" />
                <ColumnDirective field="phone" headerText="Phone" width="100" />
                <ColumnDirective
                  field="address1"
                  headerText="Address 1"
                  visible={false}
                />
                <ColumnDirective
                  field="address2"
                  headerText="Address 2"
                  visible={false}
                />
                <ColumnDirective
                  field="city"
                  headerText="City"
                  visible={false}
                />
                <ColumnDirective
                  field="state"
                  headerText="State"
                  visible={false}
                  editType="dropdownedit"
                  edit={{
                    params: {
                      dataSource: USStates,
                      fields: { text: "abbreviation", value: "abbreviation" },
                      query: new Query(),
                    },
                  }}
                />
                <ColumnDirective field="zip" headerText="Zip" visible={false} />
                <ColumnDirective
                  field="website_url"
                  headerText="Website"
                  width="150"
                />
                <ColumnDirective
                  headerText="Commands"
                  width="120"
                  textAlign="Center"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Edit, Filter, Sort, Page, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          </div>
        </section>
      </div>
    </>
  );
};

export default DonorPartners;
