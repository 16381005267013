import React, { useState, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import icon from "../assets/how_to_reg.svg";
import { useGeneralContext } from "../context/GeneralContext";
import { useLocation } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import mailer from "../service/api/mailer";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { USStates } from "./datasource";
import { encrypt } from "../service/encryption/Encryption";
import countiesData from "../counties_list.json";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { config } from "../utils/config";
import helpers from "../utils/helpers";
import { UserStatus } from "../utils/enums";
const CompleteYourProfile = () => {
  const [formData, setFormData] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    phone: "",
  });
  const { setDupAddress, dupAddressData, setEmailfunc } = useGeneralContext();
  const location = useLocation();
  const [inviteId, setInviteId] = useState(null);
  const [inviteData, setInviteData] = useState(null);
  const [isHouseholdMember, setIsHouseholdMember] = useState(false);
  const [inviterData, setInviterData] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [isDuplicateAddress, setIsDuplicateAddress] = useState(false);
  const [dupAddressBackupData, setDupAddressBackupData] = useState({
    dup_address_otp: "",
    dup_address_token: "",
    dup_address_original_email: "",
    dup_address_fraud_email: "",
  });
  const [selectedState, setSelectedState] = useState("");
  const [counties, setCounties] = useState([]);

  const { login, setRoleBasedRoute, setUserIdToUse } = useAuth();

  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState(location.state?.userData);
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const initializeUserData = async () => {
      try {
        const userData = location.state?.userData;

        if (!userData) {
          throw new Error("User data is required to proceed");
        }

        setUserData(userData);
      } catch (error) {
        console.error("Error in AddressScreen initialization:", error);
      }
    };

    initializeUserData();
  }, [location.state]);

  const clearErrorWithTimeout = (field) => {
    setTimeout(() => {
      setErrors((prev) => ({
        ...prev,
        [field]: "",
      }));
    }, 3000);
  };

  const handleChange = (e, field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: e.value,
    }));
    // Clear error when user starts typing
    if (errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
  };

  const handleDone = async () => {
    // Prevent multiple clicks while processing
    if (buttonDisabled) {
      return;
    }
    setButtonDisabled(true);

    try {
      console.log("userData: ", userData);
      const requiredFields = [
        "address1",
        "city",
        "state",
        "zip",
        "county",
        "mobile_number",
      ];
      const newErrors = {};

      requiredFields.forEach((field) => {
        if (!formData[field]?.trim()) {
          newErrors[field] = "Required";
          clearErrorWithTimeout(field);
        }
      });

      setErrors(newErrors);

      if (Object.keys(newErrors).length > 0) {
        setButtonDisabled(false);
        return;
      }

      const householdId =
        isHouseholdMember && inviterData
          ? inviterData.household_id
          : userData.household_id;

      const userCredential = await signInWithEmailAndPassword(
        auth,
        config.bluesky_email_auth,
        config.bluesky_password
      );

      const isDuplicateAddress = await checkDupAddress();
      if (isDuplicateAddress) {
        // Update user status and navigate to address verification
        await updateDoc(doc(db, "users", userData.id), {
          status: UserStatus.DUPLICATE_ADDRESS_UNVERIFIED,
          address1: formData.address1,
          address2: formData.address2,
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          county: formData.county,
        });
        return navigate("/addressverify", {
          state: { email: userData.email },
        });
      }

      // Only add to household_members if not a duplicate address case
      if (!isDuplicateAddress) {
        const householdMemberRef = doc(
          collection(db, "household", householdId, "household_members")
        );
        await setDoc(householdMemberRef, {
          date_of_birth: "",
          disability: "",
          first_name: userData.first_name,
          last_name: userData.last_name,
          household_member_id: userData.household_member_id,
          military_vet: "",
          relationship: "",
          acc_created: true,
          email: userData.email,
        });
      }

      if (isDuplicateAddress && !isHouseholdMember) {
        await handleDuplicateAddress(userData.id, dupAddressBackupData);
      }

      const centerId = await getCenterIdByCounty(formData.county);
      if (centerId) {
        console.log("centerId is: ", centerId);
      }
      // Update the user document with the center_id
      await updateDoc(doc(db, "users", userData.id), {
        center_id: centerId ? centerId : "",
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        county: formData.county,
        is_address_complete: true,
        status: UserStatus.ADDRESS_VERIFIED,
      });

      // Update primary address in address collection
      if (userData.address_id) {
        const addressesCollectionRef = collection(
          db,
          "address",
          userData.address_id,
          "addresses"
        );
        const q = query(
          addressesCollectionRef,
          where("is_primary_address", "==", true)
        );
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (document) => {
          await updateDoc(document.ref, {
            address1: formData.address1,
            address2: formData.address2,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            county: formData.county,
            updated_at: serverTimestamp(),
          });
        });
      }

      setRoleBasedRoute(userData.user_role);
      login(userCredential.user.accessToken);
      setUserIdToUse(userData.docId);
      sessionStorage.setItem("userId", userData.docId);

      // If status is ADDRESS_VERIFIED or DUPLICATE_ADDRESS_VERIFIED,
      // route to respective profile pages based on user role

      const roleRoutes = {
        partner: "/partnerprofile",
        citizen: "/citizenprofile",
        center: "/centerprofile",
        admin: "/center",
        employee: "/home",
      };
      return navigate(roleRoutes[userData.user_role] || "/home");
    } catch (error) {
      console.error("Error in handleDone:", error);
    } finally {
      setButtonDisabled(false);
    }
  };

  const generateOtp = () => {
    return Math.floor(Math.random() * 900000 + 100000).toString();
  };

  const handleDuplicateAddress = async (docIdToUse, dupAddressBackupData) => {
    // For fraud user
    const userRef = doc(db, "users", docIdToUse);
    await updateDoc(userRef, {
      dup_address: true,
      dup_address_owner: false,
      dup_address_otp: encrypt(dupAddressBackupData.dup_address_otp),
      dup_address_token: dupAddressBackupData.dup_address_token,
    });

    // const originalUserQuery = query(
    //   collection(db, "users"),
    //   where("email", "==", dupAddressBackupData.dup_address_original_email)
    // );
    // const originalUserSnapshot = await getDocs(originalUserQuery);
    // if (!originalUserSnapshot.empty) {
    //   // For original user
    //   const originalUserDoc = originalUserSnapshot.docs[0];
    //   await updateDoc(doc(db, "users", originalUserDoc.id), {
    //     dup_address: true,
    //     dup_address_owner: true,
    //     dup_address_token: dupAddressBackupData.dup_address_token,
    //   });
    // }
  };

  const checkDupAddress = async () => {
    if (!isHouseholdMember) {
      try {
        // First, check for users with dup_address_owner = true
        const ownerQuery = query(
          collection(db, "users"),
          where("address1", "==", formData.address1),
          where("city", "==", helpers.capitalizeFirstLetter(formData.city)),
          where("state", "==", formData.state),
          where("zip", "==", helpers.upperCaseString(formData.zip)),
          where("dup_address_owner", "==", true)
        );

        let snapshot = await getDocs(ownerQuery);

        let dataFirebase = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // If no owner found, check for any matching addresses
        if (dataFirebase.length < 1) {
          const addressQuery = query(
            collection(db, "users"),
            where("address1", "==", formData.address1),
            where("city", "==", helpers.capitalizeFirstLetter(formData.city)),
            where("state", "==", formData.state),
            where("zip", "==", helpers.upperCaseString(formData.zip))
          );

          snapshot = await getDocs(addressQuery);
          dataFirebase = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
        }

        if (dataFirebase.length > 0) {
          setIsDuplicateAddress(true);
          const existingUser = dataFirebase[0];
          console.log("existing user found in 2nd check", existingUser);
          const dupAddressToken =
            existingUser.dup_address_token || doc(collection(db, "users")).id;

          // If the first user isn't marked as owner, mark them as owner
          if (!existingUser.dup_address_owner) {
            await updateDoc(doc(db, "users", existingUser.id), {
              dup_address_owner: true,
              dup_address: true,
              dup_address_token: dupAddressToken,
            });
          }

          const otp = generateOtp();

          await updateDoc(doc(db, "users", userData.id), {
            dup_address: true,
            dup_address_owner: false,
            dup_address_otp: encrypt(otp),
            dup_address_token: dupAddressToken,
          });

          setDupAddressBackupData({
            dup_address_otp: otp,
            dup_address_token: dupAddressToken,
            dup_address_original_email: existingUser.email,
            dup_address_fraud_email: userData.email,
          });

          // Store the verification data
          const verificationData = {
            original_email: existingUser.email,
            fraud_email: userData.email,
            created_at: new Date().toISOString(),
            otp: otp,
            token: dupAddressToken,
          };

          setDupAddress(verificationData);
          console.log("verificationData: ", verificationData);
          // Create a base64 encoded string of the verification data
          const encodedData = btoa(JSON.stringify(verificationData));
          const loginLink = `${window.location.origin}/login?dupAddress=true&token=${dupAddressToken}&data=${encodedData}`;

          console.log("Generated login link:", loginLink);

          await mailer.sendOtpDuplicateEmail(
            existingUser.email,
            otp,
            existingUser.first_name,
            loginLink
          );

          return true;
        }
      } catch (error) {
        console.error("Error in checkDupAddress:", error);
      }
    }
    return false;
  };

  const getCenterIdByCounty = async (county) => {
    const centersRef = collection(db, "centers");
    const q = query(centersRef, where("county", "==", county));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      return snapshot.docs[0].id;
    }

    return "";
  };

  const handleStateChange = async (e) => {
    const stateAbbreviation = e.target.value;
    const stateObject = USStates.find(
      (state) => state.abbreviation === stateAbbreviation
    );
    const stateName = stateObject ? stateObject.name : "";

    setSelectedState(stateAbbreviation);
    setFormData((prevFormData) => ({
      ...prevFormData,
      state: stateAbbreviation,
      county: "", // Reset county when state changes
    }));

    // For other user types, use the original county filtering
    const filteredCounties = countiesData.filter(
      (county) => county.State === stateName
    );
    setCounties(filteredCounties);
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="flex w-[773px] flex-col items-center gap-8 rounded-lg bg-[#FAFAFA] p-4 shadow-[0_0_25px_rgba(0,0,0,0.25)]">
        <div className=" text-center">
          <div className="mx-auto mb-[32px] flex aspect-square h-[121px] w-[121px] flex-col items-center justify-center gap-2.5 rounded-full bg-[#770C0E] p-[19px_9px]">
            <img src={icon} alt="how_to_reg" className="m-auto h-16 w-16" />
          </div>
          <h2 className="font-inter text-[24px] font-medium text-[#242424]">
            Finish Your Profile to Continue
          </h2>
        </div>

        <div className="flex w-full flex-col gap-[10px]">
          <div>
            <TextBoxComponent
              placeholder="Address 1"
              floatLabelType="Auto"
              cssClass="e-outline"
              value={formData.address1}
              change={(e) => handleChange(e, "address1")}
            />
            {errors.address1 && (
              <div className="mt-1 text-sm text-red-500">{errors.address1}</div>
            )}
          </div>

          <TextBoxComponent
            placeholder="Address 2"
            floatLabelType="Auto"
            cssClass="e-outline"
            value={formData.address2}
            change={(e) => handleChange(e, "address2")}
          />

          <div className="grid grid-cols-2 gap-4">
            <div>
              <TextBoxComponent
                placeholder="City"
                floatLabelType="Auto"
                cssClass="e-outline"
                value={formData.city}
                change={(e) => handleChange(e, "city")}
              />
              {errors.city && (
                <div className="mt-1 text-sm text-red-500">{errors.city}</div>
              )}
            </div>

            <div>
              <DropDownListComponent
                dataSource={USStates}
                fields={{
                  text: "abbreviation",
                  value: "abbreviation",
                }}
                value={selectedState}
                change={(e) =>
                  handleStateChange({
                    target: { value: e.value },
                  })
                }
                cssClass="e-outline"
                floatLabelType="Auto"
                placeholder="State"
              />
              {errors.state && (
                <div className="mt-1 text-sm text-red-500">{errors.state}</div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <TextBoxComponent
                placeholder="ZIP Code"
                floatLabelType="Auto"
                cssClass="e-outline"
                value={formData.zip}
                change={(e) => handleChange(e, "zip")}
              />
              {errors.zip && (
                <div className="mt-1 text-sm text-red-500">{errors.zip}</div>
              )}
            </div>

            <div>
              <DropDownListComponent
                dataSource={counties}
                fields={{
                  text: "County",
                  value: "County",
                }}
                placeholder="County"
                floatLabelType="Auto"
                cssClass="e-outline"
                value={formData.county}
                change={(e) => handleChange(e, "county")}
              />
              {errors.county && (
                <div className="mt-1 text-sm text-red-500">{errors.county}</div>
              )}
            </div>

            <div>
              <TextBoxComponent
                placeholder="Mobile Number"
                floatLabelType="Auto"
                cssClass="e-outline"
                value={formData.mobile_number}
                change={(e) => handleChange(e, "mobile_number")}
              />
              {errors.mobile_number && (
                <div className="mt-1 text-sm text-red-500">
                  {errors.mobile_number}
                </div>
              )}
            </div>
          </div>

          <button
            className="btn-default h- w-full rounded-[4px] bg-blue-600 p-4 text-white transition-colors hover:cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-400"
            onClick={handleDone}
            disabled={buttonDisabled}
          >
            {buttonDisabled ? "Please wait..." : "Done"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteYourProfile;
