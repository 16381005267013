import React, { useState, useRef, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { countries, USStates } from "../datasource";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../components/AuthContext";
import countiesData from "../../counties_list.json";

const Profile = ({ isOpen, onClose, data, onUpdate }) => {
  const [formData, setFormData] = useState(data);
  const [counties, setCounties] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const fileInputRef = useRef(null);
  const { role } = useAuth();
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    if (data && data.state) {
      const stateObject = USStates.find(
        (state) => state.abbreviation === data.state
      );
      const stateName = stateObject ? stateObject.name : "";
      const filteredCounties = countiesData.filter(
        (county) => county.State === stateName
      );
      setCounties(filteredCounties);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setFormData(data);
      setPreviewURL(data.profile_image || null);
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "state") {
      const stateObject = USStates.find(
        (state) => state.abbreviation === value
      );
      const stateName = stateObject ? stateObject.name : "";

      const filteredCounties = countiesData.filter(
        (county) => county.State === stateName
      );
      setCounties(filteredCounties);

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        county: "",
      }));
    } else if (name === "address1" || name === "address2") {
      // Allow alphanumeric, spaces, commas, periods, hyphens and hash for addresses
      const sanitizedValue = value.replace(/[^a-zA-Z0-9\s,.\-#0-9]/g, "");
      setFormData((prevData) => ({
        ...prevData,
        [name]: sanitizedValue,
      }));
    } else {
      // Remove any special characters except spaces for other fields
      const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
      setFormData((prevData) => ({
        ...prevData,
        [name]: sanitizedValue,
      }));
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "Enter",
      "Shift",
      "ArrowLeft",
      "ArrowRight",
      "Space",
    ];

    if (!/^[a-zA-Z\s]$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyDownAddress = (e) => {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "Enter",
      "Shift",
      "ArrowLeft",
      "ArrowRight",
      "Space",
      "-",
      "#",
      ".",
      ",",
    ];

    if (!/^[a-zA-Z0-9\s,.\-#]$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const resizeImage = (
    file,
    maxWidth = 300,
    maxHeight = 300,
    quality = 0.7
  ) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(
                new File([blob], file.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                })
              );
            },
            "image/jpeg",
            quality
          );
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewURL(URL.createObjectURL(file));
    }
  };

  const handleEditPhotoClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;

    if (user) {
      try {
        let updatedData = { ...formData };

        if (selectedFile) {
          const resizedFile = await resizeImage(selectedFile);
          const storage = getStorage();
          const storageRef = ref(storage, `profile_images/${selectedUserId}`);
          await uploadBytes(storageRef, resizedFile);
          const downloadURL = await getDownloadURL(storageRef);
          updatedData.profile_image = downloadURL;
        }

        let userDocRef;
        if (role === "center") {
          userDocRef = doc(db, "centers", data.id);
          await updateDoc(userDocRef, updatedData);
        } else if (role === "partner") {
          userDocRef = doc(db, "partners", data.id);
          await updateDoc(userDocRef, updatedData);
        } else {
          userDocRef = doc(db, "users", selectedUserId);
        }

        await updateDoc(userDocRef, updatedData);
        onUpdate(updatedData);
        onClose();
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    } else {
      console.error("No user is signed in");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="w-[780px] overflow-hidden rounded-lg bg-white">
        <div className="bg-blue flex items-center justify-between p-4">
          <h2 className="text-l">Profile</h2>
          <button
            onClick={onClose}
            className="bg-blue cursor-pointer rounded-full text-[24px] text-white"
          >
            &times;
          </button>
        </div>
        <div className="p-6">
          <div className="mb-6 flex justify-between">
            <div className="flex">
              {previewURL ? (
                <img
                  src={previewURL}
                  alt="Profile"
                  className="mr-4 h-24 w-24 rounded-full"
                />
              ) : (
                <div className="text-2xl user-profile-thumbnail mr-4 flex h-24 w-24 items-center justify-center rounded-full">
                  {formData.first_name
                    ? formData.first_name.charAt(0).toUpperCase()
                    : "?"}
                </div>
              )}
              <div className="flex flex-col items-end justify-end">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*"
                  style={{ display: "none" }}
                />
                <button
                  onClick={handleEditPhotoClick}
                  className="btn-default cursor-pointer rounded-full px-4 py-2"
                >
                  Edit Photo
                </button>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center border-b pr-8">
              <h3 className="mb-1 text-[24px] font-bold text-gray-800">
                {formData.first_name || ""} {formData.last_name || ""}
              </h3>
              <p className="mb-2 text-gray-800">{formData.email || ""}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 grid grid-cols-2 gap-4">
              <TextBoxComponent
                placeholder="First Name"
                floatLabelType="Auto"
                value={formData.first_name}
                name="first_name"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                cssClass="e-outline"
              />
              <TextBoxComponent
                placeholder="Last Name"
                floatLabelType="Auto"
                value={formData.last_name}
                name="last_name"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                cssClass="e-outline"
              />
              <TextBoxComponent
                placeholder="Phone Number"
                floatLabelType="Auto"
                value={formData.mobile_number}
                name="mobile_number"
                onChange={handleInputChange}
                onKeyDown={(e) => {
                  if (
                    !/^[0-9+()-]$/.test(e.key) &&
                    ![
                      "Backspace",
                      "Tab",
                      "Enter",
                      "ArrowLeft",
                      "ArrowRight",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                cssClass="e-outline"
              />
              <DropDownListComponent
                placeholder="Select a country"
                floatLabelType="Auto"
                dataSource={countries}
                fields={{ text: "name", value: "abbreviation" }}
                value={formData.country}
                name="country"
                change={(e) =>
                  handleInputChange({
                    target: { name: "country", value: e.value },
                  })
                }
                cssClass="e-outline"
              />
            </div>
            <div className="mb-4">
              <TextBoxComponent
                placeholder="Address 1"
                floatLabelType="Auto"
                value={formData.address1}
                name="address1"
                onChange={handleInputChange}
                onKeyDown={handleKeyDownAddress}
                cssClass="e-outline"
              />
            </div>
            <div className="mb-4">
              <TextBoxComponent
                placeholder="Address 2"
                floatLabelType="Auto"
                value={formData.address2}
                name="address2"
                onChange={handleInputChange}
                onKeyDown={handleKeyDownAddress}
                cssClass="e-outline"
              />
            </div>
            <div className="mb-6 grid grid-cols-4 gap-4">
              <TextBoxComponent
                placeholder="City"
                floatLabelType="Auto"
                value={formData.city}
                name="city"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                cssClass="e-outline"
              />
              <DropDownListComponent
                placeholder="State"
                floatLabelType="Auto"
                dataSource={USStates}
                fields={{ text: "name", value: "abbreviation" }}
                value={formData.state}
                name="state"
                change={(e) =>
                  handleInputChange({
                    target: { name: "state", value: e.value },
                  })
                }
                cssClass="e-outline"
              />
              <TextBoxComponent
                placeholder="Zip Code"
                floatLabelType="Auto"
                value={formData.zip}
                name="zip"
                onChange={handleInputChange}
                cssClass="e-outline"
              />
              <DropDownListComponent
                placeholder="County"
                floatLabelType="Auto"
                dataSource={counties}
                fields={{ text: "County", value: "County" }}
                value={formData.county}
                name="county"
                change={(e) =>
                  handleInputChange({
                    target: { name: "county", value: e.value },
                  })
                }
                cssClass="e-outline"
              />
            </div>
            <div className="flex justify-between">
              <p className="pt-2 text-sm text-gray-800">
                *Quick Note: Fields with asterisks are required
              </p>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={onClose}
                  className="btn-cancel mr-4 cursor-pointer px-6 py-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-default cursor-pointer px-6 py-2"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
