const CustomSelect = ({
  errors,
  name,
  placeholder,
  register,
  options,
  ...props
}) => {
  return (
    <div className="relative">
      <select
        className={`peer h-[56px] w-full appearance-none rounded-[4px] border-[1px] bg-white px-4 pb-1 pt-6 text-[15px] font-normal outline-none transition-all
          ${
            errors[name]
              ? "border-red-500 focus:border-red-500"
              : "border-gray-200 hover:border-[#8F8F8F] focus:border-darkslateblue"
          }
          [&>option]:text-gray-700 
          [&>option:hover]:bg-gray-50 
          [&>option:checked]:bg-blue-50 
          [&>option:not(:checked)]:bg-white
          [&>option]:cursor-pointer 
          [&>option]:px-4
          [&>option]:py-3
        `}
        {...props}
        {...register}
      >
        {/* <option value="" disabled selected></option> */}
        {options.map((option) => (
          <option key={option.value} value={option.value} className="px-4 py-3">
            {option.label}
          </option>
        ))}
      </select>
      <label
        htmlFor={name}
        className={`absolute left-4 text-[14px] transition-all
          ${errors[name] ? "text-red-500" : "text-smokey-grey"}
          peer-focus:[#8F8F8F] top-2 
          peer-placeholder-shown:top-4 
          peer-placeholder-shown:text-base 
          peer-focus:top-2
          peer-focus:text-[14px]
        `}
      >
        {placeholder}
      </label>

      {/* Improved arrow icon */}
      <div className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2">
        <svg
          className="h-5 w-5 fill-gray-400 transition-transform peer-focus:fill-blue-500"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      {errors[name] && (
        <p className="mt-1.5 text-xs font-medium text-red-500">
          {errors[name]?.message}
        </p>
      )}
    </div>
  );
};

export default CustomSelect;
