import { createContext, useContext, useState } from "react";

const SidebarContext = createContext(null);

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebarContext must be used within a SidebarProvider");
  }
  return context;
};

export const SidebarProvider = ({ children }) => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  return (
    <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
