import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { auth, db } from "../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import mailer from "../service/api/mailer";

const InviteFriendDialog = ({ onClose }) => {
  const [inviteForm, setInviteForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const selectedUserId = sessionStorage.getItem("userId");

  const LoadingSpinner = () => (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );

  const validateForm = () => {
    const newErrors = {};

    if (!inviteForm.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }

    if (!inviteForm.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    }

    if (!inviteForm.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!inviteForm.email.toLowerCase().endsWith("@gmail.com")) {
      newErrors.email = "Please enter a valid Gmail address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInviteSubmit = async () => {
    const textboxElements = document.querySelectorAll(
      ".e-input-group .e-control"
    );
    let isValid = true;

    // Clear all previous error messages first
    document.querySelectorAll(".error-message").forEach((el) => el.remove());

    // Trigger validation on all textboxes
    textboxElements.forEach((element) => {
      if (element.ej2_instances && element.ej2_instances[0]) {
        const instance = element.ej2_instances[0];
        const inputGroup = element.closest(".e-input-group");

        if (!instance.value) {
          // Required field validation for all fields
          inputGroup.classList.add("e-error");
          const errorDiv = document.createElement("div");
          errorDiv.className = "error-message text-red-500 text-sm -mt-4";
          errorDiv.textContent = "This field is required";
          inputGroup.insertAdjacentElement("afterend", errorDiv);
          isValid = false;
        } else {
          inputGroup.classList.remove("e-error");
        }
      }
    });

    // Email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(inviteForm.email) && inviteForm.email.trim() !== "") {
      const inputGroup = textboxElements[2].closest(".e-input-group");
      inputGroup.classList.add("e-error");
      const errorDiv = document.createElement("div");
      errorDiv.className = "error-message text-red-500 text-sm -mt-4";
      errorDiv.textContent = "Please enter a valid email address";
      inputGroup.insertAdjacentElement("afterend", errorDiv);
      return;
    }

    if (!isValid) return;

    try {
      setIsSubmitting(true);

      // Get the current user's UID
      const currentUserUid = selectedUserId;
      if (!currentUserUid) {
        console.error("No user is logged in");
        return;
      }

      // Add document to invited_users collection
      const docRef = await addDoc(collection(db, "invited_users"), {
        invited_by: currentUserUid,
        sent_to: inviteForm.email,
        date_invited: serverTimestamp(),
        first_name:
          inviteForm.firstName.charAt(0).toUpperCase() +
          inviteForm.firstName.slice(1),
        last_name:
          inviteForm.lastName.charAt(0).toUpperCase() +
          inviteForm.lastName.slice(1),
        type: "friend",
        acc_created: false,
        acc_created_date: null,
      });

      // Update the document to include its ID
      await updateDoc(docRef, {
        id: docRef.id,
      });

      const link = `${window.location.origin}/registration/${docRef.id}`;
      const senderName = `${inviteForm.firstName} ${inviteForm.lastName}`;

      await mailer.sendFriendInviteEmail(
        inviteForm.email,
        inviteForm.firstName,
        link,
        senderName
      );

      console.log("Invitation sent successfully! ", docRef.id);
      setInviteForm({ firstName: "", lastName: "", email: "" });
      onClose();
    } catch (error) {
      console.error("Error sending invitation:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[450px] rounded-lg bg-white shadow-lg">
        <div className="bg-blue flex items-center justify-between rounded-t-lg p-4 text-white">
          <h2 className="!important text-[24px]">Invite Friend</h2>
          <button
            onClick={onClose}
            className="bg-blue cursor-pointer rounded-full text-[24px] text-white"
            disabled={isSubmitting}
          >
            &times;
          </button>
        </div>
        <div className="flex flex-col justify-between p-4">
          <div className="flex flex-col gap-4">
            <TextBoxComponent
              placeholder="First Name"
              floatLabelType="Auto"
              cssClass="e-outline"
              required={true}
              value={inviteForm.firstName}
              change={(e) =>
                setInviteForm({ ...inviteForm, firstName: e.value })
              }
            />
            {/* {errors.firstName && (
              <p className="text-red-500">{errors.firstName}</p>
            )} */}
            <TextBoxComponent
              placeholder="Last Name"
              floatLabelType="Auto"
              cssClass="e-outline"
              required={true}
              value={inviteForm.lastName}
              change={(e) =>
                setInviteForm({ ...inviteForm, lastName: e.value })
              }
            />
            {/* {errors.lastName && (
              <p className="text-red-500">{errors.lastName}</p>
            )} */}
            <TextBoxComponent
              placeholder="Email"
              floatLabelType="Auto"
              type="email"
              cssClass="e-outline"
              required={true}
              value={inviteForm.email}
              change={(e) => setInviteForm({ ...inviteForm, email: e.value })}
            />
            {/* {errors.email && <p className="text-red-500">{errors.email}</p>} */}
          </div>
          <div className="mt-4 flex justify-end gap-2">
            <button
              onClick={onClose}
              className="btn-cancel px-4 py-2 hover:cursor-pointer"
            >
              Cancel
            </button>
            <button
              onClick={handleInviteSubmit}
              className="btn-default flex min-w-[120px] items-center justify-center px-4 py-2 hover:cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <LoadingSpinner />
                  <span>Sending...</span>
                </>
              ) : (
                "Send Invite"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

InviteFriendDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default InviteFriendDialog;
