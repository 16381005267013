import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import { useEffect, useState } from "react";

const PdfViewerMultiplePage = ({ fileUrl }) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchPageCount = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(fileUrl);
        const pdf = await loadingTask.promise;
        setTotalPages(pdf.numPages);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    fetchPageCount();
  }, [fileUrl]);

  const nextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const prevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  return (
    <div className="flex h-full max-h-[calc(90vh-150px)] min-h-[50vh] w-full flex-col items-center border-2 border-solid">
      {/* PDF Viewer */}
      <iframe
        src={`${fileUrl}#page=${page}&toolbar=0&view=FitH&navpanes=0`}
        className="h-full w-full flex-1"
      />
    </div>
  );
};

export default PdfViewerMultiplePage;
