import React from 'react';

const NotificationButton = ({ unreadNotifications, onClick }) => {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className="cursor-pointer"
        >
            {/* Background rectangle */}
            <path
                d="M19.6104 1H6.61035C3.29664 1 0.610352 3.68629 0.610352 7V20C0.610352 23.3137 3.29664 26 6.61035 26H19.6104C22.9241 26 25.6104 23.3137 25.6104 20V7C25.6104 3.68629 22.9241 1 19.6104 1Z"
                fill="#FBBB4C"
            />
            {/* Bell icon */}
            <path
                d="M13 21C14.1 21 15 20.1 15 19H11C11 20.1 11.9 21 13 21ZM19 15V10C19 6.93 17.37 4.36 14.5 3.68V3C14.5 2.17 13.83 1.5 13 1.5C12.17 1.5 11.5 2.17 11.5 3V3.68C8.64 4.36 7 6.92 7 10V15L5 17V18H21V17L19 15ZM17 16H9V10C9 7.52 10.51 5.5 13 5.5C15.49 5.5 17 7.52 17 10V16Z"
                fill="#9C1717"
            />
            {/* Notification dot */}
            {unreadNotifications > 0 && (
                <circle
                    cx="19"
                    cy="6"
                    r="4"
                    fill="#9C1717"
                    className="animate-pulse"
                />
            )}
        </svg>
    );
};

export default NotificationButton;