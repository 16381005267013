import React, { useState, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  getAuth,
  onAuthStateChanged,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../components/AuthContext";
import { encrypt, decrypt } from "../../service/encryption/Encryption";

const Pin = ({ isOpen, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [showPins, setShowPins] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [hasCurrentPin, setHasCurrentPin] = useState(false);
  const { role } = useAuth();
  const [docId, setDocId] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocc = doc(db, "users", selectedUserId);
        const userDocSnap = await getDoc(userDocc);

        setUserId(selectedUserId);
        // Check if the user has a PIN set
        let docId = selectedUserId;

        if (role === "partner") {
          docId = userDocSnap.data().partner_id;
          console.log("Partner ID: " + docId);
          setDocId(docId);
        } else {
          setDocId(docId);
        }
        const userDocRef = doc(db, role, docId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().pin) {
          setHasCurrentPin(true);
        } else {
          setHasCurrentPin(false);
        }
      }
    });
    return () => unsubscribe();
  }, [role]);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (newPin !== confirmPin) {
      setError("New PIN and confirm PIN don't match.");
      return;
    }

    if (newPin.length !== 4 || !/^\d+$/.test(newPin)) {
      setError("PIN must be 4 digits.");
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        setError("User not logged in.");
        return;
      }

      // Reauthenticate the user
      try {
        const credential = EmailAuthProvider.credential(
          user.email,
          currentPassword
        );
        await reauthenticateWithCredential(user, credential);
      } catch (error) {
        console.error("Error reauthenticating:", error);
        setError("Incorrect password. Please try again.");
        return;
      }

      // If reauthentication succeeds, proceed with PIN update
      const collectionName = role === "partner" ? "partners" : "users";
      const userDocRef = doc(db, collectionName, docId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const pin = encrypt(newPin);
        console.log("PIN updated successfully: ", pin);
        // Update PIN logic here
        await updateDoc(userDocRef, { pin: pin });
        onClose();
      } else {
        setError("User data not found.");
      }
    } catch (error) {
      console.error("Error updating PIN:", error);
      setError("An error occurred. Please try again.");
    }
  };

  const togglePinVisibility = (field) => {
    setShowPins((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="w-[480px] rounded-lg bg-white shadow-xl">
        <div className="flex items-center justify-between rounded-t-lg bg-blue-500 p-4 font-normal text-white">
          <h2 className="text-[24px] font-semibold">Change Lockbox PIN</h2>
          <button
            onClick={onClose}
            className="cursor-pointer rounded-full bg-blue-500 text-[24px] text-white"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-4">
          {error && <div className="mb-4 text-red-500">{error}</div>}
          <div className="mb-4 text-red-500">
            <strong>Note:</strong> Please ensure you remember your new PIN.
          </div>

          <div className="relative mb-4">
            <TextBoxComponent
              placeholder="Password"
              type={showPins.current ? "text" : "password"}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
            />
            <button
              type="button"
              onClick={() => togglePinVisibility("current")}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
            >
              {showPins.current ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>

          <div className="relative mb-4">
            <TextBoxComponent
              placeholder="New PIN"
              type={showPins.new ? "text" : "password"}
              value={newPin}
              onChange={(e) => setNewPin(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
              maxLength={4}
            />
            <button
              type="button"
              onClick={() => togglePinVisibility("new")}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
            >
              {showPins.new ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="relative mb-6">
            <TextBoxComponent
              placeholder="Confirm New PIN"
              type={showPins.confirm ? "text" : "password"}
              value={confirmPin}
              onChange={(e) => setConfirmPin(e.target.value)}
              cssClass="e-outline"
              floatLabelType="Auto"
              maxLength={4}
            />
            <button
              type="button"
              onClick={() => togglePinVisibility("confirm")}
              className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-blue-500"
            >
              {showPins.confirm ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="flex items-center justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="cursor-pointer rounded-full border border-blue-500 px-4 py-2 text-blue-500 transition duration-300 hover:bg-blue-50"
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="cursor-pointer rounded-full bg-blue-500 px-4 py-2 text-white transition duration-300 hover:bg-blue-600"
            >
              SAVE CHANGES
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Pin;
