import { registerLicense, Internationalization } from "@syncfusion/ej2-base";
import React, { useState, useEffect, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort, Edit } from "@syncfusion/ej2-react-grids";
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { getAuth } from "firebase/auth";
import { doc, getDoc, Query, updateDoc, setDoc } from "firebase/firestore";
import DisasterDashboard from "./../../center-pov/DisasterDeclaration/DisasterDashboard.js";
import { useAuth } from "./../../../components/AuthContext.js";
import Demographics from "./../../center-pov/Demographics.js";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const CaseManagementDashboard = ({ className = "", disasterId }) => {
  const [userData, setUserData] = useState("");
  const [activeData, setActiveData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [disasterNames, setDisasterNames] = useState([]);
  const [selectedDisaster, setSelectedDisaster] = useState("");
  const [selectedDisasterId, setSelectedDisasterId] = useState("");
  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };
  const datepickerparams = {
    params: {
      value: new Date(),
      format: "MM/dd/yyyy",
      showClearButton: false,
      showTodayButton: true,
    },
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];
  const pageSettings = { pageSize: 10 };
  const selectedUserId = sessionStorage.getItem("userId");
  const [activeDisasterId, setActiveDisasterId] = useState(
    sessionStorage.getItem("activeDisasterId") || null
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setActiveDisasterId(sessionStorage.getItem("activeDisasterId"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user && selectedUserId) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));

        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          const userData = userDoc.data();
          setUserRole(role);

          let updatedUserData = userData;

          if (role === "center") {
            const centerDoc = await getDoc(
              doc(db, "centers", userData.center_id)
            );
            if (centerDoc.exists()) {
              updatedUserData = centerDoc.data();
            }
          } else if (role === "partner") {
            const partnerDoc = await getDoc(
              doc(db, "partners", userData?.partner_id)
            );
            if (partnerDoc.exists()) {
              updatedUserData = partnerDoc.data();
            }
          }

          setUserData(updatedUserData);

          // if (!userData?.center_id || !updatedUserData?.county) {
          //   console.error("Error: center_id or county is undefined");
          //   return;
          // }

          try {
            let q;

            if (role === "partner") {
              // Only proceed if we have a valid center_id
              if (!userData?.center_id) {
                console.log("No center_id available for partner");
                setLoading(false);
                return;
              }

              // Convert center_id to array if it's not already
              const centerIds = Array.isArray(userData.center_id)
                ? userData.center_id
                : [userData.center_id];

              // Ensure we have a non-empty array
              if (centerIds.length === 0) {
                console.log("No center IDs available");
                setLoading(false);
                return;
              }

              q = query(
                collection(db, "declared-disaster"),
                where("disaster_status", "==", "active"),
                where("is_deleted", "==", false),
                where("center_id", "in", centerIds),
                where(
                  "affected_counties",
                  "array-contains",
                  updatedUserData?.county
                )
              );
            } else if (role === "center") {
              // Validate center_id exists for center role
              if (!userData?.center_id) {
                console.log("No center_id available for center");
                setLoading(false);
                return;
              }

              q = query(
                collection(db, "declared-disaster"),
                where("disaster_status", "==", "active"),
                where("is_deleted", "==", false),
                where("center_id", "==", userData.center_id),
                where(
                  "affected_counties",
                  "array-contains",
                  updatedUserData?.county
                )
              );
            }

            // Only proceed with query if q was set
            if (!q) {
              setLoading(false);
              return;
            }

            const querySnapshot = await getDocs(q);
            const disasters = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              name: doc.data().disaster_name,
            }));

            setDisasterNames(disasters);

            const activeData = querySnapshot.docs.map((doc) => ({
              disaster_id: doc.id,
              disaster_name: doc.data().disaster_name,
              disaster_status: doc.data().disaster_status,
              start_date: doc.data().start_date
                ? doc.data().start_date.toDate().toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  })
                : null,
              county: doc.data().affected_counties,
            }));

            setActiveData(activeData);
            if (disasters.length > 0) {
              setSelectedDisaster(disasters[0].name);
              setSelectedDisasterId(disasters[0].id);
            }
            setLoading(false);
          } catch (error) {
            console.error("Error fetching disaster names:", error);
          }
        }
      }
    };
    fetchUserData();
  }, []);

  const handleDisasterChange = (e) => {
    const selectedName = e.target.value;
    setSelectedDisaster(selectedName);

    const selectedDisaster = disasterNames.find(
      (disaster) => disaster.name === selectedName
    );

    if (selectedDisaster) {
      setSelectedDisasterId(selectedDisaster.id);
    }
  };

  if (loading) {
    return <div className=""></div>;
  }

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      {/* <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[100px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Dashboard
        </a>
      </div>{" "} */}
      {/* Report Generator Start */}
      {disasterNames.length > 0 ? (
        <div className="w-full flex-1 rounded-mini bg-white">
          {/* <div className="flex w-full items-center justify-between gap-2.5">
            <select
              value={selectedDisaster}
              onChange={handleDisasterChange}
              className="relative text-[20px] font-extrabold leading-normal text-blue-500 [text-decoration:none]"
            >
              {disasterNames.map((disaster) => (
                <option key={disaster.id} value={disaster.name}>
                  {disaster.name}
                </option>
              ))}
            </select>
          </div> */}
          <h2 className=" text-[28px] font-semibold text-[#242424]">
            Dashboard
          </h2>
          <div className="-mt-10">
            <DisasterDashboard propId={activeDisasterId} />
          </div>
        </div>
      ) : userRole === "center" || userRole === "partner" ? (
        <div className="relative -mx-2.5 -my-[15px] overflow-hidden">
          <div className="-mt-10">
            <Demographics />
          </div>
        </div>
      ) : null}
      {/* Report Generator End */}
      {/* Active Disaster Grid Start */}
      <section className="mt-4 w-full rounded-md border border-gray-200 bg-white pr-4">
        <h1 className="mb-3 text-base font-bold text-blue-500">
          ACTIVE DISASTERS
        </h1>

        <GridComponent
          dataSource={activeData}
          allowSorting={true}
          sortSettings={sortSettings}
          filterSettings={filterSettings}
          allowFiltering={true}
          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
          cssClass="new-grid-color"
        >
          <ColumnsDirective>
            <ColumnDirective
              field="disaster_id"
              headerText="Disaster ID"
              isPrimaryKey={true}
              visible={false}
            />
            <ColumnDirective
              field="disaster_name"
              headerText="Disaster"
              width="150"
              textAlign="Left"
              editType="stringedit"
            />
            <ColumnDirective
              field="disaster_status"
              headerText="Status"
              width="100"
              textAlign="Left"
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: [
                    { text: "Active", value: "Active" },
                    { text: "Inactive", value: "Inactive" },
                  ],
                  fields: { text: "text", value: "value" },
                  query: new Query(),
                },
              }}
            />
            <ColumnDirective
              field="date_declared"
              headerText="Date"
              width="100"
              format="MM/dd/yyyy"
              editType="datepickeredit"
              edit={datepickerparams}
            />
            <ColumnDirective
              field="county"
              headerText="Affected Counties"
              width="100"
              editType="stringedit"
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Edit,
              CommandColumn,
              Toolbar,
              Filter,
              ColumnMenu,
            ]}
          />
        </GridComponent>
      </section>
      {/* Active Disaster Grid End */}
    </div>
  );
};

export default CaseManagementDashboard;
