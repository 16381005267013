import CountiesList from "../counties_list.json";
import { USStates } from "../pages/datasource";

export const Months = [
  { label: "Jan", value: 1 },
  { label: "Feb", value: 2 },
  { label: "Mar", value: 3 },
  { label: "Apr", value: 4 },
  { label: "May", value: 5 },
  { label: "Jun", value: 6 },
  { label: "Jul", value: 7 },
  { label: "Aug", value: 8 },
  { label: "Sep", value: 9 },
  { label: "Oct", value: 10 },
  { label: "Nov", value: 11 },
  { label: "Dec", value: 12 },
];

export const MonthsObj = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const OwnerOrRenterOptions = [
  { label: "Owner", value: "Owner" },
  { label: "Renter", value: "Renter" },
  { label: "Rent to Own", value: "Rent to Own" },
];

export const AnnualIncomeOptions = [
  { label: "$0-$20,000", value: "0-20000" },
  { label: "$20,001-$40,000", value: "20001-40000" },
  { label: "$40,001-$60,000", value: "40001-60000" },
  { label: "$60,001-$80,000", value: "60001-80000" },
  { label: "$80,001-$100,000", value: "80001-100000" },
  { label: "Greater than $100,000", value: "100000+" },
];

export const HomeInsuranceOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const HomeInsuranceTypeOptions = [
  { label: "Homeowners", value: "Homeowners" },
  { label: "Renter", value: "Renter" },
];

export const AutomotiveInsuranceTypeOptions = [
  { label: "Full Coverage", value: "Full Coverage" },
  { label: "Liability", value: "Liability" },
];

export const RelationshipOptions = [
  { label: "Husband", value: "Husband" },
  { label: "Wife", value: "Wife" },
  { label: "Daughter", value: "Daughter" },
  { label: "Son", value: "Son" },
  { label: "Other Relatives", value: "Other Relatives" },
];

export const GenderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const EthnicityOptions = [
  {
    label: "American Indian or Alaska Native",
    value: "American Indian or Alaska Native",
  },
  { label: "Asian", value: "Asian" },
  { label: "Hispanic/Latino", value: "Hispanic/Latino" },
  {
    label: "Native Hawaiian or Pacific Islander",
    value: "Native Hawaiian or Pacific Islander",
  },
  { label: "Tribal Affiliation", value: "Tribal Affiliation" },
  { label: "Other", value: "Other" },
  { label: "White", value: "White" },
  { label: "None of the Above", value: "None of the Above" },
];

export const PetsCoatOptions = [
  { label: "Curly", value: "Curly" },
  { label: "Shaggy", value: "Shaggy" },
  { label: "Straight", value: "Straight" },
  { label: "Wavy", value: "Wavy" },
  { label: "Wire", value: "Wire" },
];

export const PetsSizeOptions = [
  { label: "Large", value: "Large" },
  { label: "Medium", value: "Medium" },
  { label: "Small", value: "Small" },
];

export const USStatesOptions = USStates.map((state) => ({
  label: state.name,
  value: state.abbreviation,
}));

export const countyDropdownByState = (state) => {
  const filteredCounty = CountiesList.filter(
    (county) =>
      county.State === USStatesOptions.find((s) => s.value === state)?.label
  );
  return filteredCounty.map((county) => ({
    label: county.County,
    value: county.County,
  }));
};

export const addressTypeDropdown = [
  { label: "Apt", value: "Apt" },
  { label: "Hotel / Motel", value: "Hotel / Motel" },
  { label: "Mobile Home / Trailer", value: "Mobile Home / Trailer" },
  { label: "Single Family Dwelling", value: "Single Family Dwelling" },
  { label: "Other", value: "Other" },
];

export const addressConditionDropdown = [
  { label: "Own", value: "Own" },
  { label: "Rent", value: "Rent" },
  { label: "Live with Family / Friends", value: "Live with Family / Friends" },
  { label: "Shelter", value: "Shelter" },
  { label: "Other", value: "Other" },
];

export const subsidizedByDropdown = [
  { label: "USDA", value: "USDA" },
  { label: "FEMA", value: "FEMA" },
  { label: "HUD / Section 8", value: "HUD / Section 8" },
  { label: "HUD / Grant or Loan", value: "HUD / Grant or Loan" },
  { label: "HUD / Public Housing", value: "HUD / Public Housing" },
  { label: "None", value: "None" },
];

export const yesNoDropdown = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
