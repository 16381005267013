const helpers = {
  capitalizeFirstLetter: (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },

  lowerCaseString: (string) => {
    if (!string) return string;
    return string.toLowerCase();
  },

  upperCaseString: (string) => {
    if (!string) return string;
    return string.toUpperCase();
  },
};

export default helpers;
