import React from "react";

const SendButton = ({ onClick }) => {
  const handleClick = (e) => {
    // Create a synthetic event that mimics pressing Enter in the textarea
    const textArea = e.target
      .closest(".journal-textarea-cont")
      .querySelector("textarea");
    const syntheticEvent = {
      key: "Enter",
      target: textArea,
      preventDefault: () => {},
      shiftKey: false,
    };

    onClick(syntheticEvent);
  };

  return (
    <button
      className="my-auto mr-2 flex h-[72px] w-[72px] flex-col items-center justify-center gap-[10px] rounded bg-[#003460] p-4 hover:cursor-pointer"
      onClick={handleClick}
    >
      <svg
        width="32"
        height="28"
        viewBox="0 0 32 28"
        className="aspect-[8/7]"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.57102 27.847C1.95887 28.0956 1.37733 28.0412 0.826399 27.6838C0.275466 27.3264 0 26.8057 0 26.1219V17.7298L14.6915 14L0 10.2702V1.87806C0 1.19426 0.275466 0.67364 0.826399 0.316198C1.37733 -0.0412433 1.95887 -0.0956366 2.57102 0.153019L30.8522 12.275C31.6174 12.6169 32 13.1919 32 14C32 14.8081 31.6174 15.3831 30.8522 15.725L2.57102 27.847Z"
          fill="#F7CA41"
        />
      </svg>
    </button>
  );
};

export default SendButton;
