import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import AdminService from "../../../service/admin/adminService";
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  addDoc,
  getDocs,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { serverTimestamp } from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import citizenProfileService from "../../../service/citizen/CitizenProfile";
import { USStates } from "../../datasource";
import mailer from "../../../service/api/mailer";
import { auth, db } from "../../../firebase";
import StatusOpenIcon from "../../../icons/CaseManagement/status-closed.svg";
import countiesList from "../../../counties_list.json";
import { useNavigate } from "react-router-dom";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

const CaseManagementAddCitizen = ({ className = "" }) => {
  const navigate = useNavigate();
  const [selectedDisasters, setSelectedDisasters] = useState([]);
  const [activeSection, setActiveSection] = useState("personal");
  const selectedUserId = sessionStorage.getItem("userId");

  // Add form state
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    country: "US",
  });
  const [selectedState, setSelectedState] = useState("");
  const [counties, setCounties] = useState([]);
  const [missingFields, setMissingFields] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState(false);

  // Add handlers
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Check email format and existence when email field changes
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setInvalidEmail(!emailRegex.test(value));
      if (emailRegex.test(value)) {
        const exists = await checkEmailExistence();
        setIsDuplicateEmail(exists);
      }
    }
  };

  const handleStateChange = (e) => {
    const stateAbbreviation = e.target.value;
    const stateObject = USStates.find(
      (state) => state.abbreviation === stateAbbreviation
    );
    const stateName = stateObject ? stateObject.name : "";

    setSelectedState(stateAbbreviation);
    setFormData((prevFormData) => ({
      ...prevFormData,
      state: stateAbbreviation,
    }));

    // Filter counties based on selected state
    const filteredCounties = countiesList.filter(
      (county) => county.State === stateName
    );
    setCounties(filteredCounties);
  };

  const handleCountyChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      county: e.target.value,
    }));
  };

  useEffect(() => {
    const storedDisasters = localStorage.getItem("selectedDisasters");
    if (storedDisasters) {
      const disasters = JSON.parse(storedDisasters);
      setSelectedDisasters(disasters);
      console.log("Retrieved selected disasters:", disasters); // Debug log
    }
  }, []);

  const [emailExists, setEmailExists] = useState(false);
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isDuplicateAddress, setIsDuplicateAddress] = useState(false);
  const [dupAddressData, setDupAddressData] = useState(null);
  const [dupAddressBackupData, setDupAddressBackupData] = useState({
    dup_address_otp: "",
    dup_address_token: "",
    dup_address_original_email: "",
    dup_address_fraud_email: "",
  });

  // Add helper function for OTP generation
  const generateOtp = () => {
    return Math.floor(Math.random() * 900000 + 100000).toString();
  };

  // Add email existence checker
  const checkEmailExistence = async () => {
    const email = formData.email;
    const snapshot = await getDocs(
      query(collection(db, "users"), where("email", "==", email))
    );
    if (snapshot.empty) {
      setEmailExists(false);
      setIsDuplicateEmail(false);
    } else {
      setPopupMessage(
        "This email address is already registered. Please use a different email address or log in to your existing account."
      );
      setEmailExists(true);
      setIsDuplicateEmail(true);
      return true;
    }
    return false;
  };

  // Add duplicate address checker
  const checkDupAddress = async () => {
    // Get all users and convert their addresses to lowercase for comparison
    const usersRef = collection(db, "users");
    const citizenQuery = query(usersRef, where("user_role", "==", "citizen"));
    const snapshot = await getDocs(citizenQuery);

    // Check each document manually with case-insensitive comparison
    const matchingDoc = snapshot.docs.find((doc) => {
      const userData = doc.data();
      return (
        userData.address1?.toLowerCase() ===
          formData.address1.trim().toLowerCase() &&
        userData.city?.toLowerCase() === formData.city.trim().toLowerCase() &&
        userData.state?.toLowerCase() === formData.state.toLowerCase() &&
        userData.zip?.toLowerCase() === formData.zip.trim().toLowerCase()
      );
    });

    if (matchingDoc) {
      // Set the duplicate address flag
      setIsDuplicateAddress(true);
      try {
        const existingUser = matchingDoc.data();
        const firstName = existingUser.first_name;
        const email = existingUser.email;
        const otp = generateOtp();

        console.log("existingUser: ", existingUser);

        // Generate a secure verification token
        const verificationToken =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);

        setDupAddressBackupData({
          dup_address_otp: otp,
          dup_address_token: verificationToken,
          dup_address_original_email: email,
          dup_address_fraud_email: formData.email,
        });

        // Store the verification data
        const verificationData = {
          original_email: email,
          fraud_email: formData.email,
          created_at: new Date().toISOString(),
          otp: otp,
          token: verificationToken,
        };

        setDupAddressData(verificationData);

        // Create a base64 encoded string of the verification data
        const encodedData = btoa(JSON.stringify(verificationData));
        const loginLink = `${window.location.origin}/login?dupAddress=true&token=${verificationToken}&data=${encodedData}`;

        console.log("Generated login link:", loginLink);

        await mailer.sendOtpDuplicateEmail(email, otp, firstName, loginLink);
        return true;
      } catch (error) {
        console.error("Error in checkDupAddress:", error);
        return false;
      }
    }
    return false;
  };

  const [centerState, setCenterState] = useState("");
  const [centerCounty, setCenterCounty] = useState("");

  // Add useEffect to load center data
  useEffect(() => {
    // Get center location data from localStorage
    const state = localStorage.getItem("centerState");
    const county = localStorage.getItem("centerCounty");

    if (state) {
      setCenterState(state);
      setSelectedState(state);
      setFormData((prev) => ({ ...prev, state }));

      // Filter counties based on center's state
      const stateObject = USStates.find((s) => s.abbreviation === state);
      const stateName = stateObject ? stateObject.name : "";
      const filteredCounties = countiesList.filter(
        (county) => county.State === stateName
      );
      setCounties(filteredCounties);
    }

    if (county) {
      setCenterCounty(county);
      setFormData((prev) => ({ ...prev, county }));
    }
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      // Validate required fields
      const requiredFields = [
        "first_name",
        "last_name",
        "email",
        "mobile_number",
        "address1",
        "city",
        "zip",
      ];
      const missing = requiredFields.filter((field) => !formData[field]);

      if (missing.length > 0) {
        setMissingFields(missing);
        return;
      }

      // Check for duplicate email and address
      const isEmailDuplicate = await checkEmailExistence();
      const isAddressDuplicate = await checkDupAddress();

      if (isEmailDuplicate || isAddressDuplicate) {
        return;
      }

      // Get next DARCI ID
      const darciNo = (await AdminService.getDarciNo()) + 1;

      // Create user document
      const userId = `citizen_${Date.now()}`; // Generate a unique ID
      const userData = {
        ...formData,
        darci_id: darciNo.toString(),
        user_role: "citizen",
        created_at: new Date().toISOString(),
        created_by: "center",
        status: "active",
        state: centerState,
        county: centerCounty,
      };

      // Save user to Firestore
      await setDoc(doc(db, "users", userId), userData);

      // Increment DARCI number
      await AdminService.incrementDarciNo();

      // Generate case number
      const newCaseNumber = generateCaseNumber();

      // Create case management entry
      const caseManagementRef = collection(db, "case_management");
      const caseData = {
        case_number: newCaseNumber,
        citizen_id: userId,
        citizen_name: `${formData.first_name} ${formData.last_name}`,
        citizen_email: formData.email,
        citizen_address: formData.address1,
        citizen_mobile: formData.mobile_number,
        disaster_id: selectedDisasters[0],
        disaster_name: localStorage.getItem("disasterName"),
        center_id: userData?.center_id,
        status: "Unassigned",
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      };

      await addDoc(caseManagementRef, caseData);

      // Create survey response entry
      const surveyData = {
        user_id: userId,
        disasters: [
          {
            disaster_id: selectedDisasters[0],
            disaster_name: localStorage.getItem("disasterName"),
            disaster_type:
              disasterOptions.find((d) => d.id === selectedDisasters[0])
                ?.disaster_type || "",
          },
        ],
        survey_id: uuidv4(),
        case_number: newCaseNumber,
        submitted_at: serverTimestamp(),
        responses: {}, // Empty responses object
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      };

      const surveyDocRef = doc(
        db,
        `users/${userId}/survey_responses`,
        surveyData.survey_id
      );

      await setDoc(surveyDocRef, surveyData);

      // Show success message
      setPopupMessage(
        `Citizen successfully registered with case number: ${newCaseNumber}`
      );

      // Clear form
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
      });

      // Navigate back to case management
      navigate("/cencasemanagement");
    } catch (error) {
      console.error("Error creating citizen:", error);
      setPopupMessage("Error creating citizen. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const generateCaseNumber = () => {
    return Math.floor(100000000 + Math.random() * 900000000).toString();
  };
  const [disasterOptions, setDisasterOptions] = useState([]);

  const PopupMessage = () => {
    if (!popupMessage) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="w-[350px] rounded-lg bg-white shadow-lg">
          <div className="text-lg bg-blue rounded-t-lg p-4 font-bold text-white">
            {emailExists
              ? "Duplicate Email"
              : invalidEmail
              ? "Invalid Email"
              : "Notification"}
          </div>
          <div className="flex flex-col justify-between bg-white p-4">
            <p>{popupMessage}</p>
            <div className={`mt-4 flex w-full justify-center`}>
              <button
                onClick={() => setPopupMessage("")}
                className="btn-default mt-2 w-full rounded px-4 py-2 hover:cursor-pointer"
              >
                Try another email
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  {
    /* Household Members */
  }

  return (
    <>
      <div className="flex flex-col">
        {/* Back button and disaster info */}
        <div className="mb-2 flex items-center justify-between px-4 pt-4 ">
          <button
            onClick={() => navigate("/cencasemanagement")}
            className="flex cursor-pointer items-center gap-2 bg-white font-medium text-[#003461] hover:text-[#002d56]"
          >
            <svg
              className="h-4 w-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            Return to Case Management
          </button>

          {/* Disaster indicator */}
          {selectedDisasters && selectedDisasters.length > 0 && (
            <div className="flex items-center gap-2">
              <div className="relative">
                <div className="flex items-center justify-between rounded border bg-white px-4 py-2">
                  <span className="font-medium text-[#003461]">
                    {localStorage.getItem("disasterName") ||
                      "Selected Disaster"}
                  </span>
                  <div className="ml-4 inline-flex items-center gap-1 rounded-full bg-[#4CAF50] px-8 py-2 text-xs font-medium text-white">
                    <img src={StatusOpenIcon} alt="" className="h-4 w-4" />
                    Open
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Main content area */}
        <div className="flex-1 px-6">
          <h1 className="mb-3 text-[24px] font-bold text-blue-500">
            Personal Information
          </h1>

          {activeSection === "personal" && (
            <div className="flex w-full flex-col gap-[5px]">
              <div className="flex gap-2">
                <div className="w-full">
                  <label className="text-gray-700 mb-1 block text-sm font-medium">
                    First Name
                  </label>
                  <TextBoxComponent
                    placeholder="Enter first name"
                    cssClass="e-outline"
                    name="first_name"
                    value={formData.first_name}
                    change={(e) =>
                      handleChange({
                        target: {
                          name: "first_name",
                          value: e.value,
                        },
                      })
                    }
                  />
                  {missingFields.includes("first_name") && (
                    <span className="text-xs text-red-500">Required</span>
                  )}
                </div>
                <div className="w-full">
                  <label className="text-gray-700 mb-1 block text-sm font-medium">
                    Last Name
                  </label>
                  <TextBoxComponent
                    placeholder="Enter last name"
                    cssClass="e-outline"
                    name="last_name"
                    value={formData.last_name}
                    change={(e) =>
                      handleChange({
                        target: {
                          name: "last_name",
                          value: e.value,
                        },
                      })
                    }
                  />
                  {missingFields.includes("last_name") && (
                    <span className="text-xs text-red-500">Required</span>
                  )}
                </div>

                <div className="w-full">
                  <label className="text-gray-700 mb-1 block text-sm font-medium">
                    Contact No.
                  </label>
                  <TextBoxComponent
                    placeholder="Enter contact number"
                    name="mobile_number"
                    cssClass="e-outline"
                    value={formData.mobile_number}
                    change={(e) =>
                      handleChange({
                        target: {
                          name: "mobile_number",
                          value: e.value,
                        },
                      })
                    }
                  />
                  {missingFields.includes("mobile_number") && (
                    <span className="text-xs text-red-500">Required</span>
                  )}
                </div>

                <div className="w-full">
                  <label className="text-gray-700 mb-1 block text-sm font-medium">
                    Email Address
                  </label>
                  <TextBoxComponent
                    placeholder="Enter email address"
                    cssClass={`e-outline ${
                      isDuplicateEmail ? "border-red-500" : ""
                    }`}
                    name="email"
                    value={formData.email}
                    change={(e) =>
                      handleChange({
                        target: { name: "email", value: e.value },
                      })
                    }
                  />
                  {isDuplicateEmail && (
                    <span className="text-xs text-red-500">
                      This email is already registered
                    </span>
                  )}
                  {missingFields.includes("email") && (
                    <span className="text-xs text-red-500">
                      {invalidEmail && formData.email !== ""
                        ? "Invalid email format"
                        : "Required"}
                    </span>
                  )}
                </div>
              </div>

              <div className="w-full">
                <label className="text-gray-700 mb-1 block text-sm font-medium">
                  Address 1
                </label>
                <TextBoxComponent
                  placeholder="Enter address"
                  cssClass={`e-outline ${
                    isDuplicateAddress ? "border-red-500" : ""
                  }`}
                  name="address1"
                  value={formData.address1}
                  change={(e) =>
                    handleChange({
                      target: { name: "address1", value: e.value },
                    })
                  }
                />
                {isDuplicateAddress && (
                  <span className="text-xs text-red-500">
                    This address is already registered
                  </span>
                )}
                {missingFields.includes("address1") && (
                  <span className="text-xs text-red-500">Required</span>
                )}
              </div>

              <div className="w-full">
                <label className="text-gray-700 mb-1 block text-sm font-medium">
                  Address 2
                </label>
                <TextBoxComponent
                  placeholder="Enter address"
                  cssClass="e-outline"
                  name="address2"
                  value={formData.address2}
                  change={(e) =>
                    handleChange({
                      target: { name: "address2", value: e.value },
                    })
                  }
                />
              </div>

              <div className="flex gap-2">
                <div className="w-full">
                  <label className="text-gray-700 mb-1 block text-sm font-medium">
                    City
                  </label>
                  <TextBoxComponent
                    placeholder="Enter city"
                    name="city"
                    cssClass="e-outline"
                    value={formData.city}
                    change={(e) =>
                      handleChange({
                        target: { name: "city", value: e.value },
                      })
                    }
                  />
                  {missingFields.includes("city") && (
                    <span className="text-xs text-red-500">Required</span>
                  )}
                </div>
                <div className="w-full">
                  <label className="text-gray-700 mb-1 block text-sm font-medium">
                    State
                  </label>
                  <DropDownListComponent
                    dataSource={USStates}
                    fields={{
                      text: "abbreviation",
                      value: "abbreviation",
                    }}
                    value={centerState}
                    cssClass="e-outline"
                    placeholder="Select a state"
                    enabled={false}
                  />
                </div>
                <div className="w-full">
                  <label className="text-gray-700 mb-1 block text-sm font-medium">
                    ZIP Code
                  </label>
                  <TextBoxComponent
                    placeholder="Enter ZIP Code"
                    name="zip"
                    cssClass="e-outline"
                    value={formData.zip}
                    change={(e) =>
                      handleChange({
                        target: { name: "zip", value: e.value },
                      })
                    }
                  />
                  {missingFields.includes("zip") && (
                    <span className="text-xs text-red-500">Required</span>
                  )}
                </div>

                <div className="w-full">
                  <label className="text-gray-700 mb-1 block text-sm font-medium">
                    County
                  </label>
                  <DropDownListComponent
                    dataSource={counties}
                    fields={{ text: "County", value: "County" }}
                    value={centerCounty}
                    cssClass="e-outline"
                    placeholder="Select a county"
                    enabled={false}
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className={`btn-default my-auto flex cursor-pointer items-center gap-1 px-5 py-3 text-center
                    ${
                      isSubmitting
                        ? "cursor-not-allowed bg-gray-400"
                        : "bg-[#003461] hover:bg-[#002d56]"
                    }`}
                >
                  {isSubmitting ? (
                    <>
                      <svg
                        className="animate-spin mr-3 h-5 w-5"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Creating...
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M19 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx="12" cy="7" r="4" />
                      </svg>
                      Create Citizen
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {PopupMessage()}
    </>
  );
};

CaseManagementAddCitizen.propTypes = {
  className: PropTypes.string,
};

export default CaseManagementAddCitizen;
