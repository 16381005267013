import React, { useState, useEffect, useRef } from "react";
import {
  FaEdit,
  FaTrash,
  FaEye,
  FaInfoCircle,
  FaTimes,
  FaCheck,
} from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

import { db, auth } from "../../../firebase";
import {
  collection,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import "./../../style.css";
import CaseManagementContactsDisaster from "./CaseManagementContacts";
import verticalDot from "./../../../assets/vertical-dot.svg";
import SendButton from "../../../components/SendButton";
import DeleteCaseNoteModal from "../../../components/DeleteCaseNoteModal";
import HeaderTitle from "../../../components/HeaderTitle";

const CaseManagementCaseNotes = ({ className = "", disasterStage }) => {
  const [disasterJournals, setDisasterJournals] = useState([]);
  const [showGuidePopup, setShowGuidePopup] = useState(false);
  const [openSections, setOpenSections] = useState({
    phase1: true,
    phase3: true,
  });
  const selectedUserId = sessionStorage.getItem("userId");
  const employeeDetails = JSON.parse(sessionStorage.getItem("employeeDetails"));
  const accessedUserId = sessionStorage.getItem("accessedUserId");

  useEffect(() => {
    if (accessedUserId && employeeDetails) {
      const employeeId = employeeDetails.id;
      if (employeeId) {
        // Only fetch if we have an employeeId
        fetchJournals();
      }
    }
  }, [accessedUserId, employeeDetails?.id]); // Only depend on the IDs

  const handleSaveCaseNote = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      const description = e.target.value.trim();
      if (!description) return;

      try {
        const noteId = uuidv4();
        const currentTimestamp = serverTimestamp();

        const noteData = {
          description,
          userId: selectedUserId,
          accessedUserId: accessedUserId,
          employeeId: employeeDetails?.id,
          editorType: employeeDetails?.role || "unknown",
          editorName:
            employeeDetails?.first_name && employeeDetails?.last_name
              ? `${employeeDetails.first_name} ${employeeDetails.last_name}`
              : employeeDetails?.role,
          timestamp: currentTimestamp,
          inserted_at: currentTimestamp,
          isMainNote: true,
          noteId: noteId,
          isApproved: employeeDetails?.role === "Case Supervisor",
        };

        const docRef = await addDoc(
          collection(db, "disaster-case-notes"),
          noteData
        );

        // Create a local timestamp for immediate display
        const localTimestamp = {
          seconds: Math.floor(Date.now() / 1000),
          nanoseconds: 0,
          toDate: function () {
            return new Date(this.seconds * 1000);
          },
        };

        // Update local state with the new note
        setDisasterJournals((prev) => [
          {
            id: docRef.id,
            ...noteData,
            timestamp: localTimestamp,
            inserted_at: localTimestamp,
          },
          ...prev,
        ]);

        // Clear the textarea
        e.target.value = "";
      } catch (error) {
        console.error("Error adding case note:", error);
      }
    }
  };

  // Modify the fetchJournals query to only show approved notes or notes by the current user
  const fetchJournals = async () => {
    const employeeId = employeeDetails?.id;
    if (!employeeId) {
      console.log("No employee ID available yet");
      return;
    }

    try {
      const journalQuery = query(
        collection(db, "disaster-case-notes"),
        where("userId", "==", selectedUserId),
        where("isMainNote", "==", true),
        where("accessedUserId", "==", accessedUserId)
      );
      const journalSnapshot = await getDocs(journalQuery);
      const currentEmployeeId = employeeDetails?.id;

      const journalEntries = journalSnapshot.docs
        .filter((doc) => {
          const data = doc.data();
          const isSupervisor = employeeDetails?.role === "Case Supervisor";
          const isOwnEntry = data.employeeId === currentEmployeeId;

          // Don't show deleted entries
          if (data.is_deleted) return false;

          // Supervisors can see all entries
          if (isSupervisor) return true;

          // Case Managers can only see approved entries or their own entries
          return data.isApproved || isOwnEntry;
        })
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      setDisasterJournals(journalEntries);
    } catch (error) {
      console.error("Error fetching Journal Entries:", error);
    }
  };

  useEffect(() => {
    if (showGuidePopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showGuidePopup]);

  useEffect(() => {
    // Set initial open sections based on disasterStage
    if (disasterStage === "Relief" || disasterStage === "Response") {
      setOpenSections({
        phase1: true,
        phase2: true,
        phase3: false,
      });
    } else if (disasterStage === "Recovery") {
      setOpenSections({
        phase1: false,
        phase2: false,
        phase3: true,
      });
    } else {
      setOpenSections({
        phase1: true,
        phase2: false,
        phase3: false,
      });
    }
  }, [disasterStage]);

  return (
    <div
      className={`flex flex-col items-start justify-start bg-white text-left leading-[normal] tracking-[normal] text-heading-title-color ${className}`}
    >
      <div className="flex h-full w-full flex-col">
        <div
          className="scrollbar-hide flex-1 overflow-y-auto"
          style={{
            height: "calc(90vh - 200px)", // Adjusted height calculation
            overflowY: "auto",
            position: "relative",
          }}
        >
          <DisasterJournalList
            disasterJournals={disasterJournals}
            setDisasterJournals={setDisasterJournals}
          />
        </div>

        <div className="journal-textarea-cont sticky bottom-0 mb-4 w-full bg-white pb-4">
          <div className="flex w-full items-end gap-2">
            <textarea
              placeholder="Document case progress, interventions, or supervision notes. Include relevant details, observations, and next steps. Write it here."
              className="font-Inter h-[100px] w-full resize-none rounded p-2 outline-none"
              onKeyDown={handleSaveCaseNote}
            />
            <SendButton onClick={(e) => handleSaveCaseNote(e)} />
          </div>
        </div>
      </div>
    </div>
  );
};

const VerticalDotsIcon = () => <img src={verticalDot} alt="Vertical Dots" />;

const DisasterJournalList = ({ disasterJournals, setDisasterJournals }) => {
  const [editingId, setEditingId] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [journalToDelete, setJournalToDelete] = useState(null);
  const [caseNotes, setCaseNotes] = useState({});
  const [newComment, setNewComment] = useState({});
  const selectedUserId = sessionStorage.getItem("userId");
  const employeeDetails = JSON.parse(sessionStorage.getItem("employeeDetails"));
  const [visibleComments, setVisibleComments] = useState({});
  // Add new state for tracking menu visibility
  const [menuVisible, setMenuVisible] = useState(null);
  const [isWithin24Hours, setIsWithin24Hours] = useState({});

  const CommentIcon = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_9874_1367"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9874_1367)">
        <path
          d="M4.99935 15.0052L3.08268 16.9219C2.81879 17.1858 2.51671 17.2448 2.17643 17.099C1.83615 16.9531 1.66602 16.6927 1.66602 16.3177V3.33854C1.66602 2.88021 1.82921 2.48785 2.1556 2.16146C2.48199 1.83507 2.87435 1.67188 3.33268 1.67188H16.666C17.1243 1.67188 17.5167 1.83507 17.8431 2.16146C18.1695 2.48785 18.3327 2.88021 18.3327 3.33854V13.3385C18.3327 13.7969 18.1695 14.1892 17.8431 14.5156C17.5167 14.842 17.1243 15.0052 16.666 15.0052H4.99935ZM4.29102 13.3385H16.666V3.33854H3.33268V14.276L4.29102 13.3385Z"
          fill="#003460"
        />
      </g>
    </svg>
  );

  const toggleComments = (noteId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [noteId]: !prev[noteId],
    }));
  };

  useEffect(() => {
    // Fetch case notes for all entries
    const fetchCaseNotes = async () => {
      try {
        const promises = disasterJournals.map(async (journal) => {
          // Query comments using noteId of the main note
          const q = query(
            collection(db, "disaster-case-notes"),
            where("parentNoteId", "==", journal.noteId), // Use parentNoteId to link to main note
            where("isComment", "==", true)
          );
          const querySnapshot = await getDocs(q);

          return {
            noteId: journal.noteId,
            notes: querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })),
          };
        });

        const results = await Promise.all(promises);
        const notesMap = {};
        results.forEach(({ noteId, notes }) => {
          notesMap[noteId] = notes.sort(
            (a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0)
          );
        });

        setCaseNotes(notesMap);
      } catch (error) {
        console.error("Error fetching case notes:", error);
      }
    };

    if (disasterJournals.length > 0) {
      fetchCaseNotes();
    }
  }, [disasterJournals]);

  const handleAddComment = async (noteId) => {
    if (!newComment[noteId]?.trim()) return;

    try {
      const noteData = {
        description: newComment[noteId],
        userId: selectedUserId,
        parentNoteId: noteId,
        employeeId: employeeDetails?.id,
        editorType: employeeDetails?.role || "unknown",
        editorName: (() => {
          if (employeeDetails?.first_name && employeeDetails?.last_name) {
            return `${employeeDetails.first_name} ${employeeDetails.last_name}`;
          }
          return employeeDetails?.role;
        })(),
        timestamp: serverTimestamp(),
        isComment: true,
      };

      const docRef = await addDoc(
        collection(db, "disaster-case-notes"),
        noteData
      );

      // Add noteId to the comment
      await updateDoc(doc(db, "disaster-case-notes", docRef.id), {
        noteId: docRef.id,
      });

      // Create a local timestamp for immediate display
      const localTimestamp = {
        seconds: Math.floor(Date.now() / 1000),
        nanoseconds: 0,
        toDate: function () {
          return new Date(this.seconds * 1000);
        },
      };

      // Update local state with the new comment
      setCaseNotes((prev) => ({
        ...prev,
        [noteId]: [
          ...(prev[noteId] || []),
          {
            id: docRef.id,
            noteId: docRef.id,
            ...noteData,
            timestamp: localTimestamp,
          },
        ].sort((a, b) => a.timestamp.seconds - b.timestamp.seconds), // Sort chronologically (oldest to newest)
      }));

      // Clear the input for this specific note
      setNewComment((prev) => ({ ...prev, [noteId]: "" }));
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "";

    const date = timestamp.toDate();
    return `${date.toLocaleTimeString()}`;
  };

  const formatDateHeader = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "";
    const date = timestamp.toDate();
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const formatCommentTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return "";
    const date = timestamp.toDate();
    return {
      date: date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
      time: date.toLocaleTimeString(),
    };
  };

  const groupJournalsByDate = (journals) => {
    const groups = {};
    journals.forEach((journal) => {
      if (!journal.timestamp) return;
      const dateStr = formatDateHeader(journal.timestamp);
      if (!groups[dateStr]) {
        groups[dateStr] = [];
      }
      groups[dateStr].push(journal);
    });
    return groups;
  };

  const groupedJournals = groupJournalsByDate(
    [...disasterJournals].sort((a, b) => {
      if (!a.timestamp || !b.timestamp) return 0;
      return b.timestamp.seconds - a.timestamp.seconds; // Changed to reverse chronological order
    })
  );

  const handleEditClick = (entry) => {
    setEditingId(entry.id);
    setEditedDescription(entry.description);
    setMenuVisible(null); // Close menu
  };

  const handleSaveEdit = async (entry) => {
    try {
      const docRef = doc(db, "disaster-case-notes", entry.id);
      const currentTimestamp = serverTimestamp();

      await updateDoc(docRef, {
        description: editedDescription,
        timestamp: currentTimestamp,
      });

      // Create a new timestamp for immediate local state update
      const localTimestamp = {
        seconds: Math.floor(Date.now() / 1000),
        nanoseconds: 0,
        toDate: function () {
          return new Date(this.seconds * 1000);
        },
      };

      // Update local state with new timestamp
      setDisasterJournals(
        (prevJournals) =>
          prevJournals
            .map((journal) =>
              journal.id === entry.id
                ? {
                    ...journal,
                    description: editedDescription,
                    timestamp: localTimestamp,
                  }
                : journal
            )
            .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds) // Re-sort after update
      );

      setEditingId(null);
      setEditedDescription("");
    } catch (error) {
      console.error("Error updating journal:", error);
    }
  };

  const handleDeleteClick = (entry) => {
    setJournalToDelete(entry);
    setShowDeleteDialog(true);
    setMenuVisible(null); // Close menu
  };

  const getCommentText = (noteId) => {
    const commentCount = caseNotes[noteId]?.length || 0;
    if (commentCount === 0) {
      return "Add Comment";
    }
    if (visibleComments[noteId]) {
      return `Hide ${commentCount} Comment${commentCount !== 1 ? "s" : ""}`;
    }
    return `Show ${commentCount} Comment${commentCount !== 1 ? "s" : ""}`;
  };

  // Add this function to check if a note is within 24 hours
  const checkIfWithin24Hours = (timestamp) => {
    if (!timestamp) return false;

    // Convert the timestamp to a Date object if it's a Firestore timestamp
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);

    const now = new Date();
    const diffInHours = (now - date) / (1000 * 60 * 60);
    return diffInHours <= 24;
  };

  // Add approval handling functions
  const handleApproveNote = async (noteId) => {
    try {
      // First, find the document with the matching noteId
      const q = query(
        collection(db, "disaster-case-notes"),
        where("noteId", "==", noteId)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.error("No document found with noteId:", noteId);
        return;
      }

      // Get the first document that matches (should be only one)
      const docToUpdate = querySnapshot.docs[0];

      await updateDoc(doc(db, "disaster-case-notes", docToUpdate.id), {
        isApproved: true,
        approvedBy: employeeDetails?.id,
        approvedAt: serverTimestamp(),
      });

      // Update local state
      setDisasterJournals((prev) =>
        prev.map((journal) =>
          journal.noteId === noteId
            ? {
                ...journal,
                isApproved: true,
                approvedBy: employeeDetails?.id,
                approvedAt: new Date(),
              }
            : journal
        )
      );
      setMenuVisible(null); // Close menu
    } catch (error) {
      console.error("Error approving note:", error);
    }
  };

  const handleRejectNote = async (noteId) => {
    try {
      // First, find the document with the matching noteId
      const q = query(
        collection(db, "disaster-case-notes"),
        where("noteId", "==", noteId)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.error("No document found with noteId:", noteId);
        return;
      }

      // Get the first document that matches (should be only one)
      const docToUpdate = querySnapshot.docs[0];

      await updateDoc(doc(db, "disaster-case-notes", docToUpdate.id), {
        isApproved: false,
        approvedBy: null,
        approvedAt: null,
      });

      // Update local state
      setDisasterJournals((prev) =>
        prev.map((journal) =>
          journal.noteId === noteId
            ? {
                ...journal,
                isApproved: false,
                approvedBy: null,
                approvedAt: null,
              }
            : journal
        )
      );
      setMenuVisible(null); // Close menu
    } catch (error) {
      console.error("Error rejecting note:", error);
    }
  };
  // Update the menu rendering in the DisasterJournalList component
  const renderMenu = (journal) => {
    const isCurrentUserNote = journal.employeeId === employeeDetails?.id;
    const isSupervisor = employeeDetails?.role === "Case Supervisor";
    const isManagerNote = journal.editorType === "Case Manager";
    const within24Hours = checkIfWithin24Hours(journal.inserted_at);

    if (!menuVisible || menuVisible !== journal.noteId) return null;

    return (
      <div className="absolute right-0 top-6 z-10 w-40 rounded-md bg-white shadow-lg">
        {/* Show edit/delete only for own notes (within 24h for non-supervisors) */}
        {isCurrentUserNote && (within24Hours || isSupervisor) && (
          <>
            <button
              onClick={() => handleEditClick(journal)}
              className="hover:bg-gray-100 flex w-full items-center px-4 py-2 text-left text-sm hover:cursor-pointer"
            >
              <svg
                className="mr-2 h-4 w-4 text-blue-600"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M13.586 3.586a2 2 0 112.828 2.828l-10 10a2 2 0 01-1.414.586H3a1 1 0 01-1-1v-2a2 2 0 01.586-1.414l10-10z" />
              </svg>
              Edit
            </button>
            <button
              onClick={() => handleDeleteClick(journal)}
              className="hover:bg-gray-100 flex w-full items-center px-4 py-2 text-left text-sm hover:cursor-pointer"
            >
              <svg
                className="mr-2 h-4 w-4 text-red-600"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              Delete
            </button>
          </>
        )}
        {/* Show approve/unapprove only for supervisors viewing manager notes */}
        {isSupervisor &&
          isManagerNote &&
          !isCurrentUserNote &&
          (journal.isApproved ? (
            <button
              onClick={() => handleRejectNote(journal.noteId)}
              className="hover:bg-gray-100 flex w-full items-center px-4 py-2 text-left text-sm hover:cursor-pointer"
            >
              <FaTimes className="mr-2 h-4 w-4 text-orange-600" />
              Unapprove
            </button>
          ) : (
            <button
              onClick={() => handleApproveNote(journal.noteId)}
              className="hover:bg-gray-100 flex w-full items-center px-4 py-2 text-left text-sm hover:cursor-pointer"
            >
              <FaCheck className="mr-2 h-4 w-4 text-green-600" />
              Approve
            </button>
          ))}
      </div>
    );
  };

  const shouldShowTripleDot = (journal) => {
    const isSupervisor = employeeDetails?.role === "Case Supervisor";
    const isOwnNote = journal.employeeId === employeeDetails?.id;
    const isManagerNote = journal.editorType === "Case Manager";

    if (!journal.inserted_at || !journal.inserted_at.toDate) {
      return false;
    }

    const within24Hours = checkIfWithin24Hours(journal.inserted_at);

    // Show triple dot for:
    // 1. Own notes (within 24h for non-supervisors)
    // 2. Supervisors viewing manager notes (for approval only)
    return (
      (isOwnNote && (within24Hours || isSupervisor)) ||
      (isSupervisor && isManagerNote && !isOwnNote)
    );
  };

  return (
    <div className="disaster-case-notes">
      <HeaderTitle id="casenotes" title="Case Notes"/>
      {Object.entries(groupedJournals).map(([dateStr, journals]) => (
        <div key={dateStr}>
          <div className="mb-2 mt-4 text-base font-semibold text-[#003460] ">
            {dateStr}
          </div>
          {journals.map((journal) => (
            <div
              key={journal.id}
              className="mb-4 rounded border border-gray-200"
            >
              <div className="relative rounded-[4px] bg-[#F5F5F5] p-4">
                <div className="mb-2 text-left">
                  <div className="flex items-center justify-between ">
                    <span className="text-xs text-gray-500">
                      <span className="font-semibold text-black">
                        {journal.editorName || "Unknown"}
                      </span>{" "}
                      - {formatTimestamp(journal.timestamp)}
                      {journal.isApproved ? (
                        <span className="ml-2 text-green-600">(Approved)</span>
                      ) : (
                        <span className="ml-2 text-orange-600">
                          (Unapproved)
                        </span>
                      )}
                    </span>

                    {shouldShowTripleDot(journal) && (
                      <div className="menu-container relative">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setMenuVisible(
                              menuVisible === journal.noteId
                                ? null
                                : journal.noteId
                            );
                          }}
                          className="hover:text-gray-700 z-100 text-gray-500 hover:cursor-pointer"
                        >
                          <img
                            src={verticalDot}
                            alt="verticalDot"
                            className="h-5 w-5"
                          />
                        </div>
                        {renderMenu(journal)}
                      </div>
                    )}
                  </div>

                  {editingId === journal.id ? (
                    <div className="mt-2">
                      <textarea
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                        className="w-full rounded border border-gray-300 p-2 text-sm"
                        rows={4}
                      />
                      <div className="mt-2 flex justify-end gap-2">
                        <button
                          onClick={() => handleSaveEdit(journal)}
                          className="rounded bg-blue-500 px-3 py-1 text-sm text-white hover:bg-blue-600"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setEditingId(null)}
                          className="rounded bg-gray-300 px-3 py-1 text-sm hover:bg-gray-400"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p className="mt-2 whitespace-pre-wrap text-base">
                      {journal.description}
                    </p>
                  )}
                </div>
                <div
                  onClick={() => toggleComments(journal.noteId)}
                  className="mt-2 flex items-center gap-2 rounded-[4px] text-sm text-[#003460] hover:cursor-pointer hover:text-[#004d8c]"
                >
                  <CommentIcon />
                  {getCommentText(journal.noteId)}
                </div>
              </div>

              {visibleComments[journal.noteId] && (
                <div className="mt-2 rounded-[4px] border-t-2 bg-[#D9EEFF] p-4">
                  <h4 className="mb-2 text-sm font-semibold">Comments</h4>

                  <div className="mb-4 space-y-2">
                    {caseNotes[journal.noteId]?.map((note) => (
                      <div
                        key={note.id}
                        className="rounded bg-white p-2 text-sm"
                      >
                        <div className="mt-1 flex flex-col gap-1 text-xs text-gray-500">
                          <div className="flex items-center gap-1">
                            <span className="text-xs font-medium text-[#242424]">
                              {note.editorName || "Unknown"}
                            </span>
                            {" - "}
                            <span className="text-xs font-normal text-[#525252]">
                              {formatCommentTimestamp(note.timestamp).date} at{" "}
                              {formatCommentTimestamp(note.timestamp).time}
                            </span>
                          </div>
                          <p className="text-base font-normal text-[#242424]">
                            {note.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={newComment[journal.noteId] || ""}
                      onChange={(e) =>
                        setNewComment((prev) => ({
                          ...prev,
                          [journal.noteId]: e.target.value,
                        }))
                      }
                      placeholder="Add a comment..."
                      className="flex-1 rounded border p-2 text-sm"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddComment(journal.noteId);
                        }
                      }}
                    />
                    <button
                      onClick={() => handleAddComment(journal.noteId)}
                      className="rounded bg-blue-500 px-4 py-2 text-sm text-white"
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}

      <DeleteCaseNoteModal
        isOpen={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
          setJournalToDelete(null);
        }}
        onDelete={async () => {
          try {
            // First, find the document with the matching noteId
            const q = query(
              collection(db, "disaster-case-notes"),
              where("noteId", "==", journalToDelete.noteId)
            );
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
              console.error(
                "No document found with noteId:",
                journalToDelete.noteId
              );
              return;
            }

            // Get the first document that matches (should be only one)
            const docToDelete = querySnapshot.docs[0];

            await deleteDoc(doc(db, "disaster-case-notes", docToDelete.id));

            // Update local state
            setDisasterJournals((prev) =>
              prev.filter(
                (journal) => journal.noteId !== journalToDelete.noteId
              )
            );

            // Close the dialog
            setShowDeleteDialog(false);
            setJournalToDelete(null);
          } catch (error) {
            console.error("Error deleting note:", error);
          }
        }}
        loading={false} // Add loading state if needed
      />
    </div>
  );
};

export default CaseManagementCaseNotes;
