import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext";
import Profile from "./Profile";
import Password from "./Password";
import Pin from "./Pin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  addDoc,
} from "firebase/firestore";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  RichTextEditorComponent,
  HtmlEditor,
  Toolbar,
  Image,
  Link,
  QuickToolbar,
  Inject,
} from "@syncfusion/ej2-react-richtexteditor";

const ContactSupport = ({ isOpen, onClose, initialData }) => {
  const [userData, setUserData] = useState(initialData || {});
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const supportRef = useRef(null);
  const { logout, role, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [isClickable, setIsClickable] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const selectedUserId = sessionStorage.getItem("userId");

  const db = getFirestore();

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const fetchUserData = async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "users", selectedUserId);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            if (role === "center") {
              const centerDocRef = doc(db, "centers", userData.center_id);
              console.log("Center");
              const centerDocSnap = await getDoc(centerDocRef);
              if (centerDocSnap.exists()) {
                setUserData(centerDocSnap.data());

                console.log("Center data:", centerDocSnap.data());
              }
            } else if (role === "partner") {
              const partnerDocRef = doc(db, "partners", userData.partner_id);
              const partnerDocSnap = await getDoc(partnerDocRef);
              if (partnerDocSnap.exists()) {
                setUserData(partnerDocSnap.data());

                console.log("Partner data:", partnerDocSnap.data());
              }
            } else {
              setUserData(userData);
              setUserRole(userData.user_role);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setUserData({});
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (supportRef.current && !supportRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    setIsClickable(title.trim() !== "" && description.trim() !== "");
  }, [title, description]);

  const handleSend = async () => {
    let valid = true;

    if (!title.trim()) {
      setTitleError("Please enter a subject");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!description.trim()) {
      setDescriptionError("Please provide the necessary details");
      valid = false;
    } else {
      setDescriptionError("");
    }

    if (valid) {
      try {
        const sanitizedDescription = description
          .replace(/<[^>]*>?/gm, "")
          .replace(/&nbsp;/g, " ");

        const supportDoc = {
          user_id: userData.id,
          first_name: userData.first_name,
          last_name: userData.last_name,
          date: new Date(),
          title: title,
          description: sanitizedDescription,
          assigned_to: "Admin",
          assigned_by: `${userData.first_name} ${userData.last_name}`,
          assigned_date: new Date(),
          status: "active",
          comments: "",
        };

        await addDoc(collection(db, "contact-support"), supportDoc);
        console.log("Support request sent successfully:", supportDoc);
        onClose();
        setTitle("");
        setDescription("");
      } catch (error) {
        console.error("Error adding support request:", error);
      }
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-start justify-end overflow-auto bg-black bg-opacity-50 pt-14">
          <div
            ref={supportRef}
            className="mr-2 w-[500px] overflow-hidden rounded-b-lg bg-white shadow-xl"
          >
            <div>
              <h2 className="bg-blue px-4 py-6">Contact Support</h2>
            </div>
            <nav className="p-5">
              <div className="mb-2">
                <TextBoxComponent
                  placeholder="Subject"
                  cssClass="e-outline"
                  floatLabelType="Always"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {titleError && (
                  <p className="mt-1 text-xs text-red-500">{titleError}</p>
                )}
              </div>
              <div className="mb-4">
                <RichTextEditorComponent
                  toolbarSettings={{
                    items: [
                      "Undo",
                      "Redo",
                      "|",
                      "Bold",
                      "Italic",
                      "Underline",
                      "StrikeThrough",
                      "|",
                      "Image",
                      "SubScript",
                      "SuperScript",
                      "|",
                      "LowerCase",
                      "UpperCase",
                      "|",
                      "Formats",
                      "Alignments",
                      "|",
                      "Indent",
                      "Outdent",
                      "|",
                      "CreateLink",
                    ],
                  }}
                  value={description}
                  cssClass="e-outline"
                  height={300}
                  change={(e) => setDescription(e.value)}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
                {descriptionError && (
                  <p className="mt-1 text-xs text-red-500">
                    {descriptionError}
                  </p>
                )}
              </div>
              <div className="mt-4 flex">
                <div className="flex w-full items-center justify-end gap-2">
                  <button
                    onClick={onClose}
                    className="btn-cancel flex h-8 items-center rounded-full px-8 py-2 text-center hover:cursor-pointer"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSend}
                    className="phx-submit-loading:opacity-75 btn-default h-8 rounded-full px-8 py-2 hover:bg-zinc-700 active:text-white/80"
                  >
                    Send
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactSupport;
