import React, { useState, useRef, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useDisasterContext } from "../../Disasters/DisasterContext";
import CaseManagementImmediateResponseSurvey from "./CaseManagementImmediateResponseSurvey";
import CaseManagementDamageAssessment from "./CaseManagementDamageAssessment";
import CaseManagementAssistanceLog from "./CaseManagementAssistanceLog";
import CaseManagementRecoveryPlanDisasters from "./CaseManagementRecoveryPlan";
import CaseManagementContactsDisaster from "./CaseManagementContacts";
import CaseManagementJournals from "./CaseManagementJournals";
import CaseManagementCaseNotes from "./CaseManagementCaseNotes";
import CaseManagementLockbox from "./CaseManagementLockbox";
import citizenProfileService from "../../../service/citizen/CitizenProfile";

import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import CaseManagementDA from "./CaseManagementDA";
import { useDisasterObjContext } from "../../../context/DisasterObjContext";

const CenterDisasters = ({ className = "" }) => {
  const [activeTab, setActiveTab] = useState("Immediate Response Survey");
  const { activeDisasterTab, setActiveDisasterTab } = useDisasterContext();
  const [selectedDisaster, setSelectedDisaster] = useState("All");
  const [userRole, setUserRole] = useState("");

  const [setSelectedDisasters] = useState(["All"]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([
    { id: "All", name: "All Disasters" },
  ]);
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);

  const [showBlur, setShowBlur] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeView, setActiveView] = useState("survey");
  const [sidebarOpen, setSidebarOpen] = useState(true);

  // Add these states
  const [accessedCaseNumber, setAccessedCaseNumber] = useState(null);
  const [accessedDisasterId, setAccessedDisasterId] = useState(null);
  const [contractNo, setContractNo] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");
  const { disaster } = useDisasterObjContext();

  const selectedDisasters = useMemo(() => {
    if (disaster) {
      return [disaster?.id];
    }
    return [];
  }, [disaster]);
  const { subtab } = useParams();

  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth();
      if (auth.currentUser && selectedUserId) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      }
    };
    fetchUserRole();
  }, []);

  const [clientName, setClientName] = useState("");

  // Add this new useEffect to fetch the name
  useEffect(() => {
    const fetchName = async () => {
      try {
        const auth = getAuth();
        if (!auth.currentUser?.uid || !selectedDisasters.length) return;

        // Get the accessed user ID from current user's document
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        const userData = userDoc.data();
        const accessedUserId = userData.accessed_userId;

        if (!accessedUserId) {
          console.log("No accessed user found");
          return;
        }

        // First fetch the default profile data
        const citizenData = await citizenProfileService.getCitizenProfile(
          "users",
          accessedUserId
        );

        if (citizenData) {
          // If we have selected disasters, try to fetch disaster-specific address
          if (selectedDisasters.length === 1) {
            const disasterId = selectedDisasters[0];
            const impactedAddressRef = doc(
              db,
              `users/${accessedUserId}/impacted-addresses/${disasterId}`
            );
            const impactedAddressDoc = await getDoc(impactedAddressRef);

            if (impactedAddressDoc.exists()) {
              // Use disaster-specific address data
              const addressData = impactedAddressDoc.data();
              console.log(addressData);
              setContractNo(addressData.mobile_number);
              setAddressData(addressData);
              setBirthDate(addressData.birth_date);
              setMobileNumber(addressData.mobile_number);
              setEmail(addressData.email);
              const fullName = `${addressData.first_name} ${addressData.last_name}`;
              setClientName(fullName);
            } else {
              // Use default profile data for new disaster entry
              const fullName = `${citizenData.first_name} ${citizenData.last_name}`;
              setClientName(fullName);
            }
          } else {
            // Use default profile data when no disaster is selected
            const fullName = `${citizenData.first_name} ${citizenData.last_name}`;
            setClientName(fullName);
          }
        } else {
          setClientName("");
        }
      } catch (error) {
        console.error("Error fetching name:", error);
        setClientName("");
      }
    };

    fetchName();
  }, [selectedDisasters]);

  // Modify the fetchDisasters useEffect
  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        setIsLoading(true);

        // First get the accessed case number and disaster ID
        const auth = getAuth();
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        const userData = userDoc.data();
        const accessedUserId = userData.accessed_userId;
        const caseNum = userData.accessed_case_number;

        setAccessedCaseNumber(caseNum);

        // Get the survey response for this case number
        const surveyQuery = query(
          collection(db, `users/${accessedUserId}/survey_responses`),
          where("case_number", "==", caseNum)
        );

        const surveySnapshot = await getDocs(surveyQuery);
        let associatedDisasters = [];

        if (!surveySnapshot.empty) {
          const surveyData = surveySnapshot.docs[0].data();
          if (surveyData.disasters) {
            associatedDisasters = surveyData.disasters.map((d) => ({
              id: d.disaster_id,
              name: d.disaster_name,
              status: "active",
            }));
          }
        }
        // Set the disasters options to only show associated disasters
        setDisasterOptions([...associatedDisasters]);
        setActiveDisasters(associatedDisasters);
        setSelectedDisasters(associatedDisasters.map((d) => d.id));

        setShowBlur(associatedDisasters.length === 0);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching disasters:", error);
        setShowBlur(true);
        setIsLoading(false);
      }
    };

    fetchDisasters();
  }, []);

  // Remove or modify the All option from disaster selection
  const handleDisasterChange = (disaster) => {
    setSelectedDisasters((prev) => {
      const newSelection = prev.includes(disaster)
        ? prev.filter((d) => d !== disaster)
        : [...prev, disaster];
      return newSelection;
    });
  };

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const auth = getAuth();
  const [caseNumber, setCaseNumber] = useState("");
  const [femaNumber, setFemaNumber] = useState("");
  const [disasterNumber, setDisasterNumber] = useState("");


  useEffect(() => {
    const fetchCaseNumber = async () => {
      try {
        if (!selectedUserId) return;

        // Get the accessed user ID and case number from current user's document
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        const userData = userDoc.data();
        const accessedUserId = userData.accessed_userId;
        const accessedCaseNum = userData.accessed_case_number;

        if (!accessedUserId || !accessedCaseNum) {
          console.log("No accessed user or case number found");
          return;
        }

        // Get survey responses for the accessed user
        const surveyQuery = query(
          collection(db, `users/${accessedUserId}/survey_responses`),
          where("case_number", "==", accessedCaseNum)
        );

        const querySnapshot = await getDocs(surveyQuery);

        if (!querySnapshot.empty) {
          const surveyData = querySnapshot.docs[0].data();
          console.log("SURVEY DATA", surveyData);

          // Set the case number
          setCaseNumber(accessedCaseNum);
          setFemaNumber(surveyData.fema_number)
          setDisasterNumber(surveyData.disaster_number)

          // If there are disasters in the survey, update the selected disasters
          if (surveyData.disasters) {
            const surveyDisasterIds = surveyData.disasters.map(
              (d) => d.disaster_id
            );
            setSelectedDisasters(surveyDisasterIds);

            // Also update disaster options if needed
            const disasterDetails = surveyData.disasters.map((d) => ({
              id: d.disaster_id,
              name: d.disaster_name,
              status: "active",
            }));
            setDisasterOptions(disasterDetails);
          }

          console.log("Found case number:", accessedCaseNum);
          console.log("Associated disasters:", surveyData.disasters);
        }
      } catch (error) {
        console.error("Error fetching case number:", error);
        setCaseNumber("");
      }
    };

    fetchCaseNumber();
  }, []); // Remove dependencies since this should only run once on mount

  const tabs = [
    "Immediate Response Survey",
    "Damage Assessment",
    "Assistance Log",
    "Recovery Plan",
    "Contacts",
    "Journals",
    "Lockbox",
    "Case Notes",
  ];
  const handleTabClick = (tab) => {
    setActiveDisasterTab(tab);
  };

  const tabComponent = {
    irs: (
      <CaseManagementImmediateResponseSurvey
        selectedDisasters={selectedDisasters}
        activeDisasters={disasterOptions}
        parentSetCaseNumber={setCaseNumber}
      />
    ),
    damageassessment: (
      <CaseManagementDA />
      // <CaseManagementDamageAssessment
      //   selectedDisasters={selectedDisasters}
      //   activeDisasters={activeDisasters}
      // />
    ),
    assistancelog: (
      <CaseManagementAssistanceLog
        selectedDisasters={selectedDisasters}
        activeDisasters={activeDisasters}
      />
    ),
    recoverytracker: (
      <CaseManagementRecoveryPlanDisasters
        selectedDisasters={selectedDisasters}
        activeDisasters={activeDisasters}
        caseNumber={caseNumber}
        femaNumber={femaNumber}
        disasterNumber={disasterNumber}
      />
    ),
    contacts: <CaseManagementContactsDisaster  className=""/>,
    journals: <CaseManagementJournals />,
    "case-notes": <CaseManagementCaseNotes />,
    lockbox: <CaseManagementLockbox />,
  };

  const renderTabComponent = useMemo(() => {
    return tabComponent[subtab] || <></>;
  }, [subtab, tabComponent]);

  // if (showBlur) {
  //   return (
  //     <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
  //       <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
  //         <div className="flex w-full flex-col items-center justify-center gap-2 space-x-4">
  //           <p className="text-[40px] font-bold text-[#348BFF]">
  //             This section will be activated when a disaster is declared
  //           </p>
  //           <button
  //             onClick={handleGoBack}
  //             className="mx-auto flex w-24 cursor-pointer items-center justify-center rounded-lg border border-blue-500 bg-white px-4 py-2 text-center text-blue-500"
  //           >
  //             Okay
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="flex-1">
      <div className="mt-2">{renderTabComponent}</div>
    </div>
  );
};

CaseManagementImmediateResponseSurvey.propTypes = {
  className: PropTypes.string,
};

export default CenterDisasters;
