import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import CustomInput from '../../../components/CustomInput';
import CustomSelect from '../../../components/CustomSelect';
import { useForm } from 'react-hook-form';
import ThreeDotMenu from '../../../components/ThreeDotMenu';
import CustomTextArea from '../../../components/CustomTextArea';
import HeaderTitle from '../../../components/HeaderTitle';


const validateSequenceNumber = (value, questions, currentId = null, category = 'General') => {
  if (!value) return "Sequence number is required";
  if (value < 1) return "Sequence number must be positive";
  
  const questionsInCategory = questions.filter(q => q.category === category);
  
  const isDuplicate = questionsInCategory.some(q => 
    q.sequenceNumber === value && q.id !== currentId
  );
  if (isDuplicate) return "This sequence number is already in use";
  
  return true;
};

const CaseManagementSurveyQuestions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [activeMenuId, setActiveMenuId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [draggedQuestion, setDraggedQuestion] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      status: 'active',
      questionDisplay: 'no',
      severity: 'medium',
      questionType: 'radio',
      optionsLayout: 'right'
    }
  });

  const questionTypeValue = watch('questionType');

  const disasterTypeOptions = [
    { value: 'general', label: 'General' },
    { value: 'earthquake', label: 'Earthquake' },
    { value: 'flood', label: 'Flood' },
    { value: 'hurricane', label: 'Hurricane' },
    { value: 'tornado', label: 'Tornado' },
    { value: 'fire', label: 'Fire' }
  ];

  const questionTypeOptions = [
    { value: 'radio', label: 'Radio Buttons' },
    { value: 'checkbox', label: 'Checkboxes' },
    { value: 'textbox', label: 'Text Box' },
    { value: 'number', label: 'Number' }
  ];

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ];

  const displayOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ];

  const severityOptions = [
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
    { value: 'critical', label: 'Critical' }
  ];

  const optionsLayoutChoices = [
    { value: 'right', label: 'Right Side' },
    { value: 'bottom', label: 'Bottom' }
  ];

  const disasterTypeColors = {
    general: "bg-gray-200 text-gray-900",
    earthquake: "bg-yellow-300 text-yellow-900",
    flood: "bg-blue-300 text-blue-900",
    hurricane: "bg-green-300 text-green-900",
    tornado: "bg-purple-300 text-purple-900",
    fire: "bg-red-300 text-red-900",
  };

  const severityColors = {
    low: "bg-green-200 text-green-900",
    medium: "bg-yellow-300 text-yellow-900",
    high: "bg-orange-400 text-white font-semibold shadow-sm",
    critical: "bg-red-500 text-white font-bold shadow-md",
  };

  const handleDragStart = (e, question) => {
    setDraggedQuestion(question);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragOver = (e) => {
    e.preventDefault(); 
  };

  const handleDrop = (e, targetQuestion) => {
    e.preventDefault();
    
    if (!draggedQuestion || draggedQuestion.id === targetQuestion.id) return;

    setSurveyQuestions(prev => {
      const filteredQuestions = prev.filter(q => q.id !== draggedQuestion.id);
      
      const targetIndex = filteredQuestions.findIndex(q => q.id === targetQuestion.id);
      
      filteredQuestions.splice(targetIndex, 0, {
        ...draggedQuestion,
        sequenceNumber: targetQuestion.sequenceNumber
      });

      return filteredQuestions.map((question, index) => ({
        ...question,
        sequenceNumber: index + 1
      }));
    });

    setDraggedQuestion(null);
  };

  const handleEditQuestion = (question) => {
    setEditingQuestion(question);
    setIsEditMode(true);
    setIsModalOpen(true);
  
    reset({
      ...question,
      options: question.options?.join('\n')
    });
  };

  const onSubmit = (data) => {
    const category = data.category || 'General';
    const questionsInCategory = surveyQuestions.filter(q => q.category === category);
    
    // Find the latest sequence number in the specific category
    const latestSequenceInCategory = Math.max(
      ...questionsInCategory.map(q => q.sequenceNumber),
      0
    );
    
    const newSequenceNumber = isEditMode 
      ? (parseInt(data.sequenceNumber) || editingQuestion.sequenceNumber)
      : (latestSequenceInCategory + 1);
    
    const processedData = {
      id: isEditMode ? editingQuestion.id : Date.now(),
      ...data,
      sequenceNumber: newSequenceNumber,
      options: data.options ? 
        data.options
          .split(/[,\n]/)
          .map(opt => opt.trim())
          .filter(opt => opt.length > 0)
      : [],
      category: category
    };
  
    if (isEditMode) {
      setSurveyQuestions(prev => {
        const otherQuestions = prev.filter(q => q.id !== editingQuestion.id);
        
        // Reorder questions within the same category
        const reorderedQuestions = [...otherQuestions, processedData]
          .map(q => {
            if (q.category === category) {
              if (q.id !== processedData.id && q.sequenceNumber >= newSequenceNumber) {
                return { ...q, sequenceNumber: q.sequenceNumber + 1 };
              }
            }
            return q;
          });
  
        return reorderedQuestions.sort((a, b) => {
          // First sort by category
          if (a.category < b.category) return -1;
          if (a.category > b.category) return 1;
          // Then by sequence number within category
          return a.sequenceNumber - b.sequenceNumber;
        });
      });
    } else {
      setSurveyQuestions(prev => {
        // Update sequence numbers within the same category
        const updatedQuestions = prev.map(q => 
          q.category === category && q.sequenceNumber >= newSequenceNumber 
            ? { ...q, sequenceNumber: q.sequenceNumber + 1 }
            : q
        );
  
        return [...updatedQuestions, processedData]
          .sort((a, b) => {
            // First sort by category
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;
            // Then by sequence number within category
            return a.sequenceNumber - b.sequenceNumber;
          });
      });
    }
  
    setIsModalOpen(false);
    setIsEditMode(false);
    setEditingQuestion(null);
    reset();
  };

  const handleDeleteQuestion = (id) => {
    setSurveyQuestions(prev => prev.filter(q => q.id !== id));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setEditingQuestion(null);
    reset();
  };

  const handleAddQuestion = () => {
    const category = watch('category') || 'General';
    const questionsInCategory = surveyQuestions.filter(q => q.category === category);
    
    const latestSequenceInCategory = Math.max(
      ...questionsInCategory.map(q => q.sequenceNumber),
      0
    );

    setIsEditMode(false);
    setEditingQuestion(null);
    setIsModalOpen(true);
    
    reset({
      status: 'active',
      questionDisplay: 'no',
      severity: 'medium',
      questionType: 'radio',
      sequenceNumber: latestSequenceInCategory + 1,
      optionsLayout: 'right',
      category: category
    });
  };

  const groupQuestionsByCategory = (questions) => {
    return questions.reduce((groups, question) => {
      const category = question.category || 'Uncategorized';
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(question);
      return groups;
    }, {});
  };

  return (
    <div className="p-4">
      <button
        onClick={handleAddQuestion}
        className="bg-[#0a2558] text-[#F7CA41] px-4 py-2 rounded-md hover:bg-[#0a2558]/90"
      >
        Add Survey Question
      </button>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Survey Questions</h2>
        
        {surveyQuestions.length === 0 ? (
          <p className="text-gray-500 italic">No questions added yet</p>
        ) : (
          <div className="space-y-6">
            {Object.entries(groupQuestionsByCategory(surveyQuestions)).map(([category, questions]) => (
              <div key={category} className="space-y-4">
                <HeaderTitle
                  title={category}
                  isRed={true}
                />
                {questions.map((question) => (
                  <div 
                    key={question.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, question)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, question)}
                    className={`border rounded-lg p-4 bg-white shadow-sm 
                      hover:shadow-md transition-shadow cursor-move
                      ${draggedQuestion?.id === question.id ? 'opacity-50' : ''}`}
                  >
                <div className="flex flex-wrap items-center justify-between mb-2">
                  <div className="flex flex-wrap items-center gap-2">
                    <span className="text-sm font-medium text-gray-500">
                      Question {question.sequenceNumber || '(unsequenced)'}
                    </span>

                    <span className={`px-2 py-1 rounded-full text-xs ${
                      question.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {question.status === 'active' ? 'Active' : 'Inactive'}
                    </span>

                    {question.disasterType && (
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        disasterTypeColors[question.disasterType] || "bg-gray-100 text-gray-800"
                      }`}>
                        {disasterTypeOptions.find(opt => opt.value === question.disasterType)?.label}
                      </span>
                    )}

                    {/* {question.category && (
                      <span className="px-2 py-1 bg-purple-100 text-purple-800 rounded-full text-xs">
                        {question.category}
                      </span>
                    )} */}

                    {question.severity && (
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        severityColors[question.severity] || "bg-gray-100 text-gray-800"
                      }`}>
                        Severity: {question.severity}
                      </span>
                    )}
                  </div>

                  <ThreeDotMenu
                    menuId={question.id}
                    activeMenuId={activeMenuId}
                    setActiveMenuId={setActiveMenuId}
                    onDeleteClick={() => handleDeleteQuestion(question.id)}
                    onEditClick={() => handleEditQuestion(question)}
                  />
                </div>

    
                          
                <div className="flex items-start justify-between gap-4">
                  <div className="flex-1">
                    <p className="text-lg font-medium mb-2">{question.question}</p>
                    {question.description && (
                      <p className="text-sm text-gray-600 italic mb-3">{question.description}</p>
                    )}
                  </div>

                  {question.optionsLayout !== 'bottom' && (
                    <div className="min-w-[400px] flex-shrink-0">
                      {(question.questionType === 'radio' || question.questionType === 'checkbox') && (
                        <div>
                          <div className="grid grid-cols-3 gap-x-4 gap-y-2">
                            {question.options.map((option, optIndex) => (
                              <div key={optIndex} className="flex items-center gap-2">
                                {question.questionType === 'radio' ? (
                                  <input 
                                    type="radio" 
                                    disabled 
                                    className="w-4 h-4 text-blue-600 border-gray-300"
                                  />
                                ) : (
                                  <input 
                                    type="checkbox" 
                                    disabled 
                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded"
                                  />
                                )}
                                <span className="text-sm truncate">{option}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {question.questionType === 'textbox' && (
                        <input
                          type="text"
                          disabled
                          placeholder="Text input"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50"
                        />
                      )}

                      {question.questionType === 'number' && (
                        <input
                          type="number"
                          disabled
                          placeholder="0"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50"
                        />
                      )}
                    </div>
                  )}
                </div>

                {question.optionsLayout === 'bottom' && (
                  <div className="mt-4 w-full">
                    {(question.questionType === 'radio' || question.questionType === 'checkbox') && (
                      <div>
                        <div className="flex flex-col space-y-2">
                          {question.options.map((option, optIndex) => (
                            <div key={optIndex} className="flex items-center gap-2">
                              {question.questionType === 'radio' ? (
                                <input 
                                  type="radio" 
                                  disabled 
                                  className="w-4 h-4 text-blue-600 border-gray-300"
                                />
                              ) : (
                                <input 
                                  type="checkbox" 
                                  disabled 
                                  className="w-4 h-4 text-blue-600 border-gray-300 rounded"
                                />
                              )}
                              <span className="text-sm">{option}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {question.questionType === 'textbox' && (
                      <input
                        type="text"
                        disabled
                        placeholder="Text input"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50"
                      />
                    )}

                    {question.questionType === 'number' && (
                      <input
                        type="number"
                        disabled
                        placeholder="0"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-50"
                      />
                    )}
                  </div>
                )}

                {/* Question Type and Field Name (moved below) */}
                <div className="mt-4 flex flex-wrap items-center gap-4">
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-600">Type:</span>
                    <span className="px-2 py-1 bg-gray-100 rounded-md text-sm">
                      {questionTypeOptions.find(opt => opt.value === question.questionType)?.label}
                    </span>
                  </div>

                  {question.fieldName && (
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray-600">Field Name:</span>
                      <span className="px-2 py-1 bg-gray-100 rounded-md text-sm">
                        {question.fieldName}
                      </span>
                    </div>
                  )}
                </div>
                </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={isEditMode ? "Edit Survey Question" : "Add Survey Question"}
        customRightButtonText={isEditMode ? "Save Changes" : "Add Question"}
        customOnRightButtonClick={handleSubmit(onSubmit)}
      >
        <form className="space-y-4">
          <div>
            <CustomInput
              label="Question (Field Name)"
              name="fieldName"
              placeholder="Enter field name for database"
              register={register("fieldName", {
                required: "Please enter a field name"
              })}
              errors={errors}
            />
          </div>

          <div>
            <CustomSelect
              label="Disaster Type"
              name="disasterType"
              placeholder="Select disaster type"
              options={disasterTypeOptions}
              register={register("disasterType", {
                required: "Please select a disaster type"
              })}
              errors={errors}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Question Type
            </label>
            <div className="space-y-2">
              {questionTypeOptions.map((option) => (
                <div key={option.value} className="flex items-center">
                  <input
                    type="radio"
                    id={`questionType-${option.value}`}
                    value={option.value}
                    className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                    {...register("questionType", {
                      required: "Please select a question type"
                    })}
                  />
                  <label
                    htmlFor={`questionType-${option.value}`}
                    className="ml-2 block text-sm text-gray-700"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {errors.questionType && (
              <p className="mt-1 text-sm text-red-600">
                {errors.questionType.message}
              </p>
            )}
          </div>

          <div>
            <CustomInput
              label="Description (Question Text)"
              name="question"
              placeholder="Enter the question text"
              register={register("question", {
                required: "Please enter your question"
              })}
              errors={errors}
            />
          </div>

          {(questionTypeValue === 'radio' || questionTypeValue === 'checkbox') && (
            <div>
              <CustomTextArea
                label="Answer Options"
                name="options"
                placeholder="Enter options (separate by comma or new line)"
                register={register("options", {
                  required: "Please enter options for radio/checkbox questions"
                })}
                errors={errors}
                rows={5}
              />
            </div>
          )}
          <div>
            <CustomSelect
              label="Display Question"
              name="questionDisplay"
              placeholder="Display Question"
              options={displayOptions}
              register={register("questionDisplay")}
              errors={errors}
            />
          </div>

          <div>
            <CustomSelect
              label="Status"
              name="status"
              placeholder="Status"
              options={statusOptions}
              register={register("status", {
                required: "Please select a status"
              })}
              errors={errors}
            />
          </div>

          <div>
            <CustomSelect
              label="Severity"
              name="severity"
              placeholder="Severity Level"
              options={severityOptions}
              register={register("severity")}
              errors={errors}
            />
          </div>

          <div>
            <CustomSelect
              label="Options Layout"
              name="optionsLayout"
              placeholder="Select options layout"
              options={optionsLayoutChoices}
              register={register("optionsLayout")}
              errors={errors}
            />
          </div>

          <div>
          <CustomInput
            label="Sequence Number"
            name="sequenceNumber"
            type="number"
            min="1"
            placeholder="Enter sequence number"
            register={register("sequenceNumber", {
              valueAsNumber: true,
              required: "Sequence number is required",
              validate: (value) => validateSequenceNumber(
                value, 
                surveyQuestions, 
                isEditMode ? editingQuestion.id : null,
                watch('category') || 'General'
              )
            })}
            errors={errors}
          />
          </div>

          <div>
            <CustomInput
              label="Category"
              name="category"
              placeholder="Category (optional)"
              register={register("category")}
              errors={errors}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CaseManagementSurveyQuestions;