import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaPlus, FaImage, FaImages } from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  actionBegin,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { Query } from "@syncfusion/ej2-data";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import DisasterMgtService from "../../service/citizen/DisasterMgtService";
import disasterCategories from "../../disaster_categories.json";
import ImageDialog from "../center-pov/CaseManagement/ImageModal";
import GridPhotoIcon from "../../assets/disaster-icons/grid-photo.svg";
import toggleDown from "../../assets/disaster-icons/toggle-down.svg";
import ThreeDotMenu from "../../components/ThreeDotMenu";
import { useForm } from "react-hook-form";
import Modal from "../../components/Modal";
import CustomSelect from "../../components/CustomSelect";
import CustomTextArea from "../../components/CustomTextArea";
import CustomInput from "../../components/CustomInput";
import DeleteModal from "../../components/DeleteModal";
import AddNewButton from "../../components/AddNewButton";
import FileUploader from "../../components/FileUploader";

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  showDeleteConfirmDialog: true,
};

const filterSettings = {
  type: "CheckBox",
};

const DisasterManagement = ({
  selectedDisasters,
  activeDisasters,
}) => {
  const [userRole, setUserRole] = useState(null);
  const [editing, setEditing] = useState({
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Dialog",
    dialog: { cssClass: "custom-dialog" },
  });

  const [currentUserData, setCurrentUserData] = useState(null);

  const [toggleVehiclesModal, setToggleVehiclesModal] = useState(false);
  const [isVehiclesEditing, setIsVehiclesEditing] = useState(false);
  const [toggleContentsModal, setToggleContentsModal] = useState(false);
  const [isContentsEditing, setIsContentsEditing] = useState(false);

  const accessedUserId = currentUserData?.accessed_userId;
  const selectedUserId = sessionStorage.getItem("userId");
  // Add useEffect to fetch current user data
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const auth = getAuth();
      if (auth.currentUser && selectedUserId) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          setCurrentUserData(userDoc.data());
          // Set the userRole and userId from the logged-in user (center/partner)
          setUserRole(userDoc.data().user_role);
          setUserId(selectedUserId); // This is the center/partner's ID
        }
      }
    };
    fetchCurrentUser();
  }, []);

  const createImageEditTemplate = (category) => ({
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
          <div id="imagesPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
            <!-- Images will be dynamically inserted here -->
          </div>
          <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4 mb-4" style="display: none;">
            <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
          </div>
          <div id="dropArea">
            <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
              <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
              </svg>
              Upload Images
            </label>
            <input type="file" id="defaultUpload" style="display:none;" multiple accept="image/*" />
          </div>

        </div>
      `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagesPreviews = elem.querySelector("#imagesPreviews");
      const uploadProgressContainer = elem.querySelector("#uploadProgress");
      const uploadProgressBar = uploadProgressContainer.querySelector("div");

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);

            const filePath = `disaster-mgt/${category}/${accessedUserId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );

            strm = strm
              ? [...strm, { src: downloadURL, name: file.name }]
              : [{ src: downloadURL, name: file.name }];
            displayUploadedImages(strm);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function onUploadProgress(args) {
        const progress = Math.round((args.e.loaded / args.e.total) * 100);
        uploadProgressContainer.style.display = "block";
        uploadProgressBar.style.width = `${progress}%`;
        if (progress === 100) {
          setTimeout(() => {
            uploadProgressContainer.style.display = "none";
          }, 1000);
        }
      }

      function displayUploadedImages(images) {
        if (!Array.isArray(images)) {
          console.error("Images is not an array:", images);
          return;
        }

        imagesPreviews.innerHTML = images
          .map(
            (image, index) => `
          <div class="relative group">
            <img src="${image.src}" alt="${image.name}" class="w-20 h-20 object-cover rounded-md" />
            <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <button onclick="removeImage(${index})" class="text-white hover:text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        `
          )
          .join("");
      }

      // Function to remove an image
      window.removeImage = (index) => {
        strm.splice(index, 1);
        displayUploadedImages(strm);
      };

      // Display the initial images if available
      const photoField = `photo_${category}`;
      if (args.rowData[photoField]) {
        strm = Array.isArray(args.rowData[photoField])
          ? args.rowData[photoField]
          : [args.rowData[photoField]];
        displayUploadedImages(strm);
      } else {
        imagesPreviews.innerHTML = "";
      }
    },
  });

  // Use the function for both general and landscaping
  const generalEdit = createImageEditTemplate("general");
  const landscapingEdit = createImageEditTemplate("landscaping");
  const contentsEdit = createImageEditTemplate("contents");
  const vehicleEdit = createImageEditTemplate("vehicle");
  const homeRepairEdit = createImageEditTemplate("home_repair");

  // Update the grid templates
  const gridTemplate = (props) => {
    if (props.photo_general) {
      const images = Array.isArray(props.photo_general)
        ? props.photo_general
        : [props.photo_general];

      return (
        <div
          className="flex cursor-pointer gap-1"
          onClick={() => handleImageClick(images)}
        >
          <img src={GridPhotoIcon} alt="photos" className="h-6 w-6" />
          <label className="font-Inter text-[#003460]">
            Photos({images.length})
          </label>
        </div>
      );
    }
    return (
      <div className="flex  gap-1">
        <img
          src={GridPhotoIcon}
          alt="no photos"
          className="h-6 w-6 opacity-50"
        />
        <label className="font-Inter text-[#003460]">Photos(0)</label>
      </div>
    );
  };

  const landscapingTemplate = (props) => {
    if (props.photo_landscaping) {
      const images = Array.isArray(props.photo_landscaping)
        ? props.photo_landscaping
        : [props.photo_landscaping];

      return (
        <div
          className="flex cursor-pointer gap-1"
          onClick={() => handleImageClick(images)}
        >
          <img src={GridPhotoIcon} alt="photos" className="h-6 w-6" />
          <label className="font-Inter text-[#003460]">
            Photos({images.length})
          </label>
        </div>
      );
    }
    return (
      <div className="flex  gap-1">
        <img src={GridPhotoIcon} alt="no photos" className="h-6 w-6" />
        <label className="font-Inter text-[#003460]">Photos(0)</label>
      </div>
    );
  };

  const contentsTemplate = (props) => {
    if (props.photo_contents) {
      const images = Array.isArray(props.photo_contents)
        ? props.photo_contents
        : [props.photo_contents];

      return (
        <div
          className="flex cursor-pointer gap-1"
          onClick={() => handleImageClick(images)}
        >
          <img src={GridPhotoIcon} alt="photos" className="h-6 w-6" />
          <label className="font-Inter text-[#003460]">
            Photos({images.length})
          </label>
        </div>
      );
    }
    return (
      <div className="flex  gap-1">
        <img
          src={GridPhotoIcon}
          alt="no photos"
          className="h-6 w-6 opacity-50"
        />
        <label className="font-Inter text-[#003460]">Photos(0)</label>
      </div>
    );
  };

  const vehicleTemplate = (props) => {
    if (props.photo_vehicle) {
      const images = Array.isArray(props.photo_vehicle)
        ? props.photo_vehicle
        : [props.photo_vehicle];

      return (
        <div
          className="flex cursor-pointer gap-1"
          onClick={() => handleImageClick(images)}
        >
          <img src={GridPhotoIcon} alt="photos" className="h-6 w-6" />
          <label className="font-Inter text-[#003460]">
            Photos({images.length})
          </label>
        </div>
      );
    }
    return (
      <div className="flex  gap-1">
        <img
          src={GridPhotoIcon}
          alt="no photos"
          className="h-6 w-6 opacity-50"
        />
        <label className="font-Inter text-[#003460]">Photos(0)</label>
      </div>
    );
  };

  const homeRepairTemplate = (props) => {
    if (props.photo_home_repair) {
      const images = Array.isArray(props.photo_home_repair)
        ? props.photo_home_repair
        : [props.photo_home_repair];

      return (
        <div
          className="flex cursor-pointer gap-1"
          onClick={() => handleImageClick(images)}
        >
          <img src={GridPhotoIcon} alt="photos" className="h-6 w-6" />
          <label className="font-Inter text-[#003460]">
            Photos({images.length})
          </label>
        </div>
      );
    }
    return (
      <div className="flex  gap-1">
        <img
          src={GridPhotoIcon}
          alt="no photos"
          className="h-6 w-6 opacity-50"
        />
        <label className="font-Inter text-[#003460]">Photos(0)</label>
      </div>
    );
  };

  // Helper function to render image preview
  const renderImagePreview = (images) => {
    return (
      <div
        className="image-name-container"
        onClick={() => handleImageClick(images)}
        style={{
          cursor: "pointer",
          textAlign: "center",
          minWidth: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3B82F6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px" }}
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <circle cx="8.5" cy="8.5" r="1.5" />
          <polyline points="21 15 16 10 5 21" />
        </svg>
        <p className="text-sm">
          {images.length > 0
            ? `${images.length} image${images.length > 1 ? "s" : ""}`
            : "0"}
        </p>
      </div>
    );
  };

  const { categories, subcategories } = disasterCategories;
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [homeRepairData, setHomeRepairData] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        fetchData();
      } else {
        setData(null);
        setGeneralData([]);
        setHomeRepairData([]);
        setLandscapingData([]);
        setVehicleData([]);
        setContentsData([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserRole = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const role = userDoc.data().user_role;
        setUserRole(role);

        // Set editing permissions based on role
        if (role === "citizen" || role === "partner") {
          setEditing((prevEditing) => ({
            ...prevEditing,
            allowEditing: true, // Allow editing for citizens and partners
            allowAdding: true, // Always allow adding
            allowDeleting: false, // Disallow deleting
          }));
        } else if (role === "center") {
          setEditing((prevEditing) => ({
            ...prevEditing,
            allowEditing: true, // Allow editing for center role
            allowAdding: true, // Always allow adding
            allowDeleting: true, // Allow deleting for center role
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const fetchData = async () => {
    try {
      const auth = getAuth();
      if (!auth.currentUser) return;

      // For DisasterManagement.js, we should use selectedUserId directly
      // instead of accessing accessed_userId from currentUserData
      if (!selectedUserId) {
        console.error("No user ID found");
        return;
      }

      // Only proceed if there are selected disasters
      if (!selectedDisasters.length) {
        setGeneralData([]);
        setHomeRepairData([]);
        setLandscapingData([]);
        setVehicleData([]);
        setContentsData([]);
        return;
      }

      // Fetch and process data for each category
      const categories = [
        "general",
        "home_repair",
        "landscaping",
        "vehicle",
        "contents",
      ];

      for (const category of categories) {
        const records = await DisasterMgtService.fetchRecords(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          category
        );

        // Find the most recent edit time
        const lastEditTime = records.reduce((latest, record) => {
          const recordTime =
            record.last_edited?.toDate?.() || record.last_edited;
          return latest
            ? recordTime > latest
              ? recordTime
              : latest
            : recordTime;
        }, null);

        // Filter records based on selected disasters
        const filteredRecords = records.filter(
          (item) =>
            item.associatedDisasters?.some((disaster) =>
              selectedDisasters.includes(disaster)
            ) && !item.is_deleted
        );

        // Update both data and last edited time based on category
        switch (category) {
          case "general":
            setGeneralData(filteredRecords);
            setLasGeneralEditedTime(lastEditTime);
            break;
          case "home_repair":
            setHomeRepairData(filteredRecords);
            setLastHomeRepairEditedTime(lastEditTime);
            break;
          case "landscaping":
            setLandscapingData(filteredRecords);
            setLastLandscapingEditedTime(lastEditTime);
            break;
          case "vehicle":
            setVehicleData(filteredRecords);
            setLastVehicleEditedTime(lastEditTime);
            break;
          case "contents":
            setContentsData(filteredRecords);
            setLastContentsEditedTime(lastEditTime);
            break;
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generalOptions = [
    { label: "General debris removal", value: "General debris removal" },
    { label: "Mold/Mildew remediation", value: "Mold/Mildew remediation" },
    { label: "Others", value: "Others" },
    { label: "Tree removal", value: "Tree removal" },
  ];

  const vehicleOptions = disasterCategories.subcategories.filter(
    ({ categoryId }) => categoryId === "4"
  );

  const contentsOptions = disasterCategories.subcategories.filter(
    ({ categoryId }) => categoryId === "5"
  );

  // Update the useEffect to depend on selectedDisasters
  useEffect(() => {
    if (auth.currentUser) {
      fetchData();
    }
  }, [selectedDisasters]); // Add selectedDisasters as a dependency

  let elem;
  let uploadObj;
  let strm;
  let selectedRow;

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  // State for General section

  const [isGeneralVisible, setIsGeneralVisible] = useState(true);
  const [lastGeneralEditedTime, setLasGeneralEditedTime] = useState(null);

  const toggleGeneral = (event) => {
    event.preventDefault();
    setIsGeneralVisible(!isGeneralVisible);
  };

  const generalGridRef = useRef(null);

  const handleAddClickGeneral = () => {
    if (generalGridRef.current) {
      generalGridRef.current.addRecord();
    }
  };

  function compressImage(file, maxSizeMB) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = img.width;
          const maxHeight = img.height;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.floor((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.floor((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.9; // Start with high quality
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
            quality -= 0.1;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(dataUrl);
        };

        img.onerror = function (error) {
          reject(error);
        };
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  // Upload image to Firebase Storage
  async function uploadImageToStorage(filePath, dataUrl) {
    const storage = getStorage();
    // Make sure filePath uses selectedUserId in the path
    const updatedFilePath = filePath.replace(
      /(disaster-mgt\/[^\/]+\/)([^\/]+)/,
      `$1${selectedUserId}`
    );
    const storageRef = ref(storage, updatedFilePath);

    await uploadString(storageRef, dataUrl, "data_url");

    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  }

  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleImageClick = (images) => {
    setSelectedImages(images);
    setCurrentImageIndex(0);
    setIsImageDialogOpen(true);
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = data[key] || "";
      }
    }
    return sanitizedData;
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [currentGridRef, setCurrentGridRef] = useState(null);
  const updateColumnVisibility = (gridRef, visible) => {
    if (gridRef.current) {
      const cols = gridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = visible;
        }
      }
      gridRef.current.refreshColumns();
    }
  };

  // Update the showPopup function
  const showPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);

    // Hide the "value" column in all grids
    updateColumnVisibility(generalGridRef, true);
    updateColumnVisibility(homeRepairGridRef, true);
    updateColumnVisibility(vehicleGridRef, true);
    updateColumnVisibility(contentsGridRef, true);
    updateColumnVisibility(landscapingGridRef, true);
  };

  const actionBeginGeneral = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
      args.data.date_declared = new Date();
    } else if (args.requestType === "beginEdit") {
      if (
        args.rowData.editor_ID !== userId ||
        args.rowData.editor_type !== userRole
      ) {
        args.cancel = true;
        showPopup("You don't have permission to edit this record.");
        return;
      }

      selectedRow = { ...args.rowData };
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.generalID = generateUniqueId();
        args.data.editor_ID = userId;
        args.data.editor_type = userRole;
        args.data.is_deleted = false;
        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "general"
        );

        // Update the last edited time
        setLasGeneralEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "citizen" || userRole === "partner") {
          if (
            args.rowData.editor_ID !== userId ||
            args.rowData.editor_type !== userRole
          ) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        // Update the last edited time
        setLasGeneralEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.generalID;
          const fileUrl = docData.photoGeneral;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            selectedUserId, // Use selectedUserId instead of accessedUserId
            documentId,
            { is_deleted: true, last_edited: new Date() },
            "general"
          );
        }

        // Update the last edited time after deletion
        setLasGeneralEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }

    // Handle date column visibility during editing
    if (generalGridRef.current && args.requestType === "beginEdit") {
      const cols = generalGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_entered") {
          col.visible = false; // Hide date_entered when editing
        }
        if (col.field === "date_entered") {
          col.allowEditing = false; // Disable editing for date_entered
        }
      }
    }

    if (generalGridRef.current && args.requestType === "add") {
      const cols = generalGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_entered") {
          col.visible = false; // Hide date_entered when adding
        }
      }
    }

    if (generalGridRef.current && args.requestType === "save") {
      const cols = generalGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_entered") {
          col.visible = true; // Show date_entered after saving
        }
      }
    }

    if (args.requestType === "save") {
      if (args.action === "add") {
        // ... existing code ...

        // Add date_entered field for new records
        const currentDate = new Date();
        args.data.date_entered = currentDate.toLocaleDateString("en-US");

        // ... existing code ...
      }
    }
  };

  const actionCompleteGeneral = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.generalID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        sanitizedData.associatedDisasters = selectedDisasters.includes("All")
          ? ["All"]
          : selectedDisasters;

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          documentId,
          sanitizedData,
          "general"
        );
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getGeneral(selectedUserId); // Use selectedUserId
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setGeneralData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  //Home Repair
  const [isHomeRepairVisible, setIsHomeRepairVisible] = useState(true);
  const [lastHomeRepairEditedTime, setLastHomeRepairEditedTime] =
    useState(null);

  const toggleHomeRepair = (event) => {
    event.preventDefault();
    setIsHomeRepairVisible(!isHomeRepairVisible);
  };

  const homeRepairGridRef = useRef(null);

  // Add these state variables for Home Repair
  const [activeMenuHomeRepair, setActiveMenuHomeRepair] = useState(null);
  const [isEditingHomeRepair, setIsEditingHomeRepair] = useState(false);
  const [showHomeRepairModal, setShowHomeRepairModal] = useState(false);
  const homeRepairFormRef = useRef(null);
  const [isSubmittingHomeRepair, setIsSubmittingHomeRepair] = useState(false);
  const [homeRepairToDeleteId, setHomeRepairToDeleteId] = useState(null);
  const [showDeleteHomeRepairModal, setShowDeleteHomeRepairModal] =
    useState(false);
  const [editingHomeRepairRecord, setEditingHomeRepairRecord] = useState(null);

  // Setup React Hook Form for Home Repair
  const {
    register: homeRepairRegister,
    handleSubmit: homeRepairHandleSubmit,
    formState: { errors: homeRepairErrors },
    reset: homeRepairReset,
    setValue: homeRepairSetValue,
    control: homeRepairControl,
  } = useForm({
    defaultValues: {},
  });

  // Handle form submission for Home Repair
  const onSubmitHomeRepair = async (values) => {
    setIsSubmittingHomeRepair(true);
    try {
      const currentDate = new Date();

      // Ensure that the values being sent do not include unsupported types
      const sanitizedData = {
        ...values,
        last_edited: currentDate,
        date_declared: currentDate,
        associatedDisasters: selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters,
        is_deleted: false,
      };

      // Check for unsupported fields before saving
      if (sanitizedData.column) {
        delete sanitizedData.column; // Remove unsupported field
      }

      if (isEditingHomeRepair) {
        // Handle edit case
        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          editingHomeRepairRecord.homeRepairID,
          sanitizedData,
          "home_repair"
        );
      } else {
        // Handle add case
        sanitizedData.homeRepairID = generateUniqueId();
        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "home_repair"
        );
      }

      // Update the last edited time
      setLastHomeRepairEditedTime(currentDate);

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getHomeRepair(selectedUserId); // Use selectedUserId
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setHomeRepairData(filteredData.filter((item) => !item.is_deleted));
        fetchData();
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }

      // Reset form and close modal
      setShowHomeRepairModal(false);
      setIsEditingHomeRepair(false);
      setEditingHomeRepairRecord(null);
      homeRepairReset();
      setIsSubmittingHomeRepair(false);
    } catch (error) {
      console.error("Error saving home repair record:", error);
      setIsSubmittingHomeRepair(false);
    }
  };

  // Update the Add Home Repair handler to use the modal
  const handleAddClickHomeRepair = () => {
    setIsEditingHomeRepair(false);
    setEditingHomeRepairRecord(null);
    homeRepairReset();
    setShowHomeRepairModal(true);
  };

  // Add handler for edit action
  const handleEditHomeRepair = (record) => {
    setIsEditingHomeRepair(true);
    setEditingHomeRepairRecord(record);

    // Set form values
    homeRepairReset();
    homeRepairSetValue("sub_category", record.sub_category);
    homeRepairSetValue("damage", record.damage);
    homeRepairSetValue("estimate", record.estimate);

    setShowHomeRepairModal(true);
  };

  // Add handler for delete action
  const handleDeleteHomeRepair = (id) => {
    setHomeRepairToDeleteId(id);
    setShowDeleteHomeRepairModal(true);
  };

  // Update the homeRepairCommandTemplate to use the new handlers
  const homeRepairCommandTemplate = (props) => {
    return (
      <div className="flex w-full justify-center overflow-visible px-4">
        <SimpleThreeDotMenu
          onEdit={() => handleEditHomeRepair(props)}
          onDelete={
            userRole === "center"
              ? () => handleDeleteHomeRepair(props.homeRepairID)
              : undefined
          }
        />
      </div>
    );
  };

  const actionBeginHomeRepair = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
      args.data.date_declared = new Date();
    } else if (args.requestType === "beginEdit") {
      if (
        args.rowData.editor_ID !== userId ||
        args.rowData.editor_type !== userRole
      ) {
        args.cancel = true;
        showPopup("You don't have permission to edit this record.");
        return;
      }

      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.homeRepairID = generateUniqueId();
        args.data.editor_ID = userId;
        args.data.editor_type = userRole;
        args.data.is_deleted = false;
        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "home_repair"
        );

        // Update the last edited time
        setLastHomeRepairEditedTime(new Date());
      } else if (args.action === "edit") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }

        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        // Update the last edited time
        setLastHomeRepairEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.homeRepairID;
          const fileUrl = docData.photo_home_repair;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          await DisasterMgtService.updateRecord(
            selectedUserId, // Use selectedUserId instead of accessedUserId
            documentId,
            { is_deleted: true, last_edited: new Date() },
            "home_repair"
          );
        }

        // Update the last edited time after deletion
        setLastHomeRepairEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteHomeRepair = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.homeRepairID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        sanitizedData.associatedDisasters = selectedDisasters.includes("All")
          ? ["All"]
          : selectedDisasters;

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          documentId,
          sanitizedData,
          "home_repair"
        );
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getHomeRepair(selectedUserId); // Use selectedUserId
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setHomeRepairData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header = args.requestType === "beginEdit" ? "Edit" : "Add";
    }
  };

  const [activeMenuGenerals, setActiveMenuGenerals] = useState(null);
  const [activeMenuVehicles, setActiveMenuVehicles] = useState(false);
  const [activeMenuContents, setActiveMenuContents] = useState(false);
  const [isEditingGenerals, setIsEditingGenerals] = useState(false);
  const [showGeneralsModal, setShowGeneralsModal] = useState(false);
  const generalsFormRef = useRef(null);
  const [isSubmittingGenerals, setIsSubmittingGenerals] = useState(false);
  const [isSubmittingVehicles, setIsSubmittingVehicles] = useState(false);
  const [isSubmittingContents, setIsSubmittingContents] = useState(false);
  const [generalsToDeleteId, setGeneralsToDeleteId] = useState(null);
  const [vehicleToDeleteId, setVehicleToDeleteId] = useState(null);
  const [contentsToDeleteId, setContentsToDeleteId] = useState(null);
  const [showDeleteGeneralModal, setShowDeleteGeneralModal] = useState(false);
  const [showDeleteVehiclesModal, setShowDeleteVehiclesModal] = useState(false);
  const [showDeleteContentsModal, setShowDeleteContentsModal] = useState(false);

  const vehicleFormRef = useRef(null);
  const contentsFormRef = useRef(null);

  const {
    register: generalsRegister,
    handleSubmit: generalsHandleSubmit,
    setValue: setValueGenerals,
    formState: { errors: generalsErrors },
    reset: generalsReset,
    control: generalsControl,
  } = useForm({
    defaultValues: {},
  });

  const {
    register: vehicleRegister,
    handleSubmit: vehicleHandleSubmit,
    setValue: setValueVehicle,
    formState: { errors: vehicleErrors },
    reset: vehicleReset,
    control: vehicleControl,
  } = useForm({ defaultValues: {} });

  const {
    register: contentsRegister,
    handleSubmit: contentsHandleSubmit,
    setValue: setValueContents,
    formState: { errors: contentsErrors },
    reset: contentsReset,
    control: contentsControl,
  } = useForm({ defaultValues: {} });

  const onSubmitVehicles = async (values) => {
    setIsSubmittingVehicles(true);
    try {
      const currentDate = new Date();

      if (isEditingGenerals) {
        // Handle edit case
        if (userRole === "citizen" || userRole === "partner") {
          if (values.editor_ID !== userId || values.editor_type !== userRole) {
            showPopup("You don't have permission to edit this record.");
            setIsSubmittingVehicles(false);
            return;
          }
        }

        const updatedData = {
          ...values,
          last_edited: currentDate,
          date_declared: currentDate,
          associatedDisasters: selectedDisasters.includes("All")
            ? activeDisasters
            : selectedDisasters,
          is_deleted: false,
        };

        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          values.vehicleID,
          sanitizedData,
          "vehicle"
        );

        // Update the last edited time
        setLasGeneralEditedTime(currentDate);
      } else {
        // Handle add case
        const newData = {
          ...values,
          vehicleID: generateUniqueId(),
          editor_ID: userId,
          editor_type: userRole,
          is_deleted: false,
          last_edited: currentDate,
          date_declared: currentDate,
          date_entered: currentDate.toLocaleDateString("en-US"),
          associatedDisasters: selectedDisasters.includes("All")
            ? activeDisasters
            : selectedDisasters,
        };

        const sanitizedData = sanitizeData(newData);

        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "vehicle"
        );

        // Update the last edited time
        setLasGeneralEditedTime(currentDate);
      }

      // Close modal and reset form
      setToggleVehiclesModal(false);
      setIsSubmittingVehicles(false);
      vehicleReset();

      // Refresh the grid data immediately after saving
      fetchData();
    } catch (error) {
      console.error("Error submitting general data:", error);
      setIsSubmittingVehicles(false);
      showPopup("Error saving data. Please try again.");
    }
  };

  const onSubmitContents = async (values) => {
    setIsSubmittingContents(true);
    try {
      const currentDate = new Date();

      if (isEditingGenerals) {
        // Handle edit case
        if (userRole === "citizen" || userRole === "partner") {
          if (values.editor_ID !== userId || values.editor_type !== userRole) {
            showPopup("You don't have permission to edit this record.");
            setIsSubmittingContents(false);
            return;
          }
        }

        const updatedData = {
          ...values,
          last_edited: currentDate,
          date_declared: currentDate,
          associatedDisasters: selectedDisasters.includes("All")
            ? activeDisasters
            : selectedDisasters,
          is_deleted: false,
        };

        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          values.generalID,
          sanitizedData,
          "contents"
        );

        // Update the last edited time
        setLasGeneralEditedTime(currentDate);
      } else {
        // Handle add case
        const newData = {
          ...values,
          contentsID: generateUniqueId(),
          editor_ID: userId,
          editor_type: userRole,
          is_deleted: false,
          last_edited: currentDate,
          date_declared: currentDate,
          date_entered: currentDate.toLocaleDateString("en-US"),
          associatedDisasters: selectedDisasters.includes("All")
            ? activeDisasters
            : selectedDisasters,
        };

        const sanitizedData = sanitizeData(newData);

        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "contents"
        );

        // Update the last edited time
        setLasGeneralEditedTime(currentDate);
        fetchData();
      }

      // Close modal and reset form
      setToggleContentsModal(false);
      setIsSubmittingContents(false);
      contentsReset();

      // Refresh the grid data immediately after saving
      fetchData();
    } catch (error) {
      console.error("Error submitting general data:", error);
      setIsSubmittingContents(false);
      showPopup("Error saving data. Please try again.");
    }
  };

  const onSubmitGenerals = async (values) => {
    setIsSubmittingGenerals(true);
    try {
      const currentDate = new Date();

      if (isEditingGenerals) {
        // Handle edit case
        if (userRole === "citizen" || userRole === "partner") {
          if (values.editor_ID !== userId || values.editor_type !== userRole) {
            showPopup("You don't have permission to edit this record.");
            setIsSubmittingGenerals(false);
            return;
          }
        }

        const updatedData = {
          ...values,
          last_edited: currentDate,
          date_declared: currentDate,
          associatedDisasters: selectedDisasters.includes("All")
            ? activeDisasters
            : selectedDisasters,
          is_deleted: false,
        };

        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          values.generalID,
          sanitizedData,
          "general"
        );

        // Update the last edited time
        setLasGeneralEditedTime(currentDate);
        fetchData();
      } else {
        // Handle add case
        const newData = {
          ...values,
          generalID: generateUniqueId(),
          editor_ID: userId,
          editor_type: userRole,
          is_deleted: false,
          last_edited: currentDate,
          date_declared: currentDate,
          date_entered: currentDate.toLocaleDateString("en-US"),
          associatedDisasters: selectedDisasters.includes("All")
            ? activeDisasters
            : selectedDisasters,
        };

        const sanitizedData = sanitizeData(newData);

        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "general"
        );

        // Update the last edited time
        setLasGeneralEditedTime(currentDate);
        fetchData();
      }

      // Close modal and reset form
      setShowGeneralsModal(false);
      setIsSubmittingGenerals(false);
      generalsReset();

      // Refresh the grid data immediately after saving
      try {
        const allData = await DisasterMgtService.getGeneral(selectedUserId); // Use selectedUserId
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setGeneralData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }
    } catch (error) {
      console.error("Error submitting general data:", error);
      setIsSubmittingGenerals(false);
      showPopup("Error saving data. Please try again.");
    }
  };

  //Landscaping
  const landscapingOptions = disasterCategories.subcategories.filter(
    ({ categoryId }) => categoryId === "3"
  );

  const [activeMenuLandscaping, setActiveMenuLandscaping] = useState(null);
  const [isEditingLandscaping, setIsEditingLandscaping] = useState(false);
  const [showLandscapingModal, setShowLandscapingModal] = useState(false);
  const landscapingFormRef = useRef(null);
  const [isSubmittingLandscaping, setIsSubmittingLandscaping] = useState(false);
  const [landscapingToDeleteId, setLandscapingToDeleteId] = useState(null);
  const [showDeleteLandscapingModal, setShowDeleteLandscapingModal] =
    useState(false);

  const {
    register: landscapingRegister,
    handleSubmit: landscapingHandleSubmit,
    setValue: setValueLandscaping,
    formState: { errors: landscapingErrors },
    reset: landscapingReset,
    control: landscapingControl,
  } = useForm({
    defaultValues: {},
  });

  const onSubmitLandscaping = async (values) => {
    setIsSubmittingLandscaping(true);
    try {
      const currentDate = new Date();

      if (isEditingLandscaping) {
        // Handle edit case
        if (userRole === "citizen" || userRole === "partner") {
          if (values.editor_ID !== userId || values.editor_type !== userRole) {
            showPopup("You don't have permission to edit this record.");
            setIsSubmittingLandscaping(false);
            return;
          }
        }

        const updatedData = {
          ...values,
          last_edited: currentDate,
          date_declared: currentDate,
          associatedDisasters: selectedDisasters.includes("All")
            ? activeDisasters
            : selectedDisasters,
          is_deleted: false,
        };

        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          values.landscapingID,
          sanitizedData,
          "landscaping"
        );

        // Update the last edited time
        setLastLandscapingEditedTime(currentDate);
      } else {
        // Handle add case
        const newData = {
          ...values,
          landscapingID: generateUniqueId(),
          editor_ID: userId,
          editor_type: userRole,
          is_deleted: false,
          last_edited: currentDate,
          date_declared: currentDate,
          associatedDisasters: selectedDisasters.includes("All")
            ? activeDisasters
            : selectedDisasters,
        };

        const sanitizedData = sanitizeData(newData);

        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "landscaping"
        );

        // Update the last edited time
        setLastLandscapingEditedTime(currentDate);
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getLandscaping(selectedUserId); // Use selectedUserId
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setLandscapingData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }

      // Reset form and close modal
      setShowLandscapingModal(false);
      setIsEditingLandscaping(false);
      landscapingReset();
      setIsSubmittingLandscaping(false);
    } catch (error) {
      console.error("Error saving landscaping record:", error);
      setIsSubmittingLandscaping(false);
    }
  };
  const [landscapingData, setLandscapingData] = useState([]);
  const [isLandscapingVisible, setIsLandscapingVisible] = useState(true);
  const [lastLandscapingEditedTime, setLastLandscapingEditedTime] =
    useState(null);

  const toggleLandscaping = (event) => {
    event.preventDefault();
    setIsLandscapingVisible(!isLandscapingVisible);
  };

  const landscapingGridRef = useRef(null);

  const handleAddClickLandscaping = () => {
    if (landscapingGridRef.current) {
      landscapingGridRef.current.addRecord();
    }
  };

  const actionBeginLandscaping = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
      args.data.date_declared = new Date();
    } else if (args.requestType === "beginEdit") {
      if (
        args.rowData.editor_ID !== userId ||
        args.rowData.editor_type !== userRole
      ) {
        args.cancel = true;
        showPopup("You don't have permission to edit this record.");
        return;
      }
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.landscapingID = generateUniqueId();
        args.data.is_deleted = false;
        args.data.editor_ID = userId;
        args.data.editor_type = userRole;
        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "landscaping"
        );

        // Update the last edited time
        setLastLandscapingEditedTime(new Date());
      } else if (args.action === "edit") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        // Update the last edited time
        setLastLandscapingEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.landscapingID;
          const fileUrl = docData.photo_landscaping;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            selectedUserId, // Use selectedUserId instead of accessedUserId
            documentId,
            { is_deleted: true, last_edited: new Date() },
            "landscaping"
          );
        }

        // Update the last edited time after deletion
        setLastLandscapingEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteLandscaping = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.landscapingID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        sanitizedData.associatedDisasters = selectedDisasters.includes("All")
          ? ["All"]
          : selectedDisasters;

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          documentId,
          sanitizedData,
          "landscaping"
        );
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getLandscaping(selectedUserId); // Use selectedUserId
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setLandscapingData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header = args.requestType === "beginEdit" ? "Edit" : "Add";
    }
  };

  //Vehicle
  const [vehicleData, setVehicleData] = useState([]);
  const [isVehicleVisible, setIsVehicleVisible] = useState(true);
  const [lastVehicleEditedTime, setLastVehicleEditedTime] = useState(null);

  const toggleVehicle = (event) => {
    event.preventDefault();
    setIsVehicleVisible(!isVehicleVisible);
  };

  const vehicleGridRef = useRef(null);

  const handleAddClickVehicle = () => {
    if (vehicleGridRef.current) {
      vehicleGridRef.current.addRecord();
    }
  };

  const actionBeginVehicle = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
      args.data.date_declared = new Date();
    } else if (args.requestType === "beginEdit") {
      if (
        args.rowData.editor_ID !== userId ||
        args.rowData.editor_type !== userRole
      ) {
        args.cancel = true;
        showPopup("You don't have permission to edit this record.");
        return;
      }
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.vehicleID = generateUniqueId();
        args.data.is_deleted = false;
        args.data.editor_ID = userId;
        args.data.editor_type = userRole;
        args.data.last_edited = serverTimestamp();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "vehicle"
        );

        // Update the last edited time
        setLastVehicleEditedTime(new Date());
      } else if (args.action === "edit") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = args.data.vehicleID;
        const sanitizedData = sanitizeData(args.data);

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          documentId,
          sanitizedData,
          "vehicle"
        );

        // Update the last edited time
        setLastVehicleEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.vehicleID;
          const fileUrl = docData.photo_vehicle;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            selectedUserId, // Use selectedUserId instead of accessedUserId
            documentId,
            { is_deleted: true, last_edited: new Date() },
            "vehicle"
          );
        }

        // Update the last edited time after deletion
        setLastVehicleEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteVehicle = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.vehicleID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        sanitizedData.associatedDisasters = selectedDisasters.includes("All")
          ? ["All"]
          : selectedDisasters;
        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          documentId,
          sanitizedData,
          "vehicle"
        );
      }

      // Refresh the grid data
      try {
        // Assuming there's a method called getGeneral. Replace with the correct method name if different.
        const allData = await DisasterMgtService.getVehicle(selectedUserId); // Use selectedUserId
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setVehicleData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header = args.requestType === "beginEdit" ? "Edit" : "Add";
    }
  };

  //Contents
  const [contentsData, setContentsData] = useState([]);
  const [isContentsVisible, setIsContentsVisible] = useState(true);
  const [lastContentsEditedTime, setLastContentsEditedTime] = useState(null);

  const toggleContents = (event) => {
    event.preventDefault();
    setIsContentsVisible(!isContentsVisible);
  };

  const contentsGridRef = useRef(null);

  const handleAddClickContents = () => {
    if (contentsGridRef.current) {
      contentsGridRef.current.addRecord();
    }
  };

  const actionBeginContents = async (args) => {
    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = userId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
      args.data.date_declared = new Date();
    } else if (args.requestType === "beginEdit") {
      if (
        args.rowData.editor_ID !== userId ||
        args.rowData.editor_type !== userRole
      ) {
        args.cancel = true;
        showPopup("You don't have permission to edit this record.");
        return;
      }
      args.rowData = { ...args.rowData };
    }
    if (args.requestType === "save") {
      if (args.action === "add") {
        args.data.contentsID = generateUniqueId();
        args.data.is_deleted = false;
        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(args.data);
        await DisasterMgtService.addRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          sanitizedData,
          "contents"
        );

        // Update the last edited time
        setLastContentsEditedTime(new Date());
      } else if (args.action === "edit") {
        if (
          args.rowData.editor_ID !== userId ||
          args.rowData.editor_type !== userRole
        ) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
        args.data.last_edited = new Date();
        args.data.date_declared = new Date();

        args.data.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = args.data.contentsID;
        const sanitizedData = sanitizeData(args.data);

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          documentId,
          sanitizedData,
          "contents"
        );

        // Update the last edited time
        setLastContentsEditedTime(new Date());
      }
    }

    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = docData.contentsID;
          const fileUrl = docData.photo_contents;

          if (fileUrl) {
            const storage = getStorage();
            const fileRef = ref(storage, fileUrl);

            await deleteObject(fileRef);
          }

          // Instead of deleting, update the status to false
          await DisasterMgtService.updateRecord(
            selectedUserId, // Use selectedUserId instead of accessedUserId
            documentId,
            { is_deleted: true, last_edited: new Date() },
            "contents"
          );
        }

        // Update the last edited time after deletion
        setLastContentsEditedTime(new Date());
      } catch (error) {
        console.error("Error updating document status:", error);
      }
    }
  };

  const actionCompleteContents = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "edit") {
        const documentId = args.data.contentsID;

        const sanitizedData = sanitizeData(args.data);
        sanitizedData.is_deleted = false;

        await DisasterMgtService.updateRecord(
          selectedUserId, // Use selectedUserId instead of accessedUserId
          documentId,
          sanitizedData,
          "contents"
        );
      }

      // Refresh the grid data
      try {
        const allData = await DisasterMgtService.getContents(selectedUserId); // Use selectedUserId
        const filteredData = allData.filter((item) => {
          if (selectedDisasters.includes("All")) return true;
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setContentsData(filteredData.filter((item) => !item.is_deleted));
      } catch (error) {
        console.error("Error fetching updated data:", error);
        // Optionally, you can add some user feedback here about the error
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header = args.requestType === "beginEdit" ? "Edit" : "Add";
    }
  };

  const [disasterMap, setDisasterMap] = useState({});
  const [dialogContent, setDialogContent] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.reduce((acc, doc) => {
          if (doc.data().disaster_status === "active") {
            acc[doc.id] = doc.data().disaster_name;
          }
          return acc;
        }, {});

        setDisasterMap(fetchedDisasters);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, []);

  const categoryTemplate = (props) => {
    const disasterCount = props.associatedDisasters
      ? props.associatedDisasters.length
      : 0;

    let editorTypeColor = "";
    let editorTypeLetter = "";
    switch (props.editor_type) {
      case "center":
        editorTypeColor = "bg-green-500";
        editorTypeLetter = "C";
        break;
      case "partner":
        editorTypeColor = "bg-yellow-500";
        editorTypeLetter = "P";
        break;
      case "citizen":
        editorTypeColor = "bg-blue-500";
        editorTypeLetter = "S";
        break;
      default:
        editorTypeColor = "bg-gray-500";
        editorTypeLetter = "?";
    }

    return (
      <div className="flex items-center">
        <span className="mr-2">{props.sub_category}</span>
        <div className="flex items-center space-x-1">
          <span
            className={`h-6 w-6 rounded-full ${editorTypeColor} flex items-center justify-center text-xs font-bold text-white`}
            title={`Editor Type: ${props.editor_type}`}
          >
            {editorTypeLetter}
          </span>
          {/* {disasterCount > 0 && (
            <span
              className="bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded cursor-pointer hover:bg-red-600"
              onClick={() => handleDisasterClick(props.associatedDisasters)}
              title={`${disasterCount} Associated Disaster${
                disasterCount > 1 ? "s" : ""
              }`}
            >
              {disasterCount}
            </span>
          )} */}
        </div>
      </div>
    );
  };

  const handleDisasterClick = (disasterIds) => {
    if (disasterIds && disasterIds.length > 0) {
      const disasterList = disasterIds
        .map(
          (id, index) =>
            `${index + 1}. ${disasterMap[id] || "Unknown Disaster"}`
        )
        .join("\n");
      setDialogContent(disasterList);
      setIsDialogOpen(true);
    } else {
      setDialogContent("No associated disasters.");
      setIsDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // Add these new state variables for totals
  const [generalTotal, setGeneralTotal] = useState(0);
  const [homeRepairTotal, setHomeRepairTotal] = useState(0);
  const [landscapingTotal, setLandscapingTotal] = useState(0);
  const [vehicleTotal, setVehicleTotal] = useState(0);
  const [contentsTotal, setContentsTotal] = useState(0);

  // Add this function to calculate totals
  const calculateTotal = (data) => {
    return data.reduce((sum, item) => sum + (Number(item.estimate) || 0), 0);
  };

  // Update useEffect that fetches data to include total calculations
  useEffect(() => {
    if (generalData) setGeneralTotal(calculateTotal(generalData));
    if (homeRepairData) setHomeRepairTotal(calculateTotal(homeRepairData));
    if (landscapingData) setLandscapingTotal(calculateTotal(landscapingData));
    if (vehicleData) setVehicleTotal(calculateTotal(vehicleData));
    if (contentsData) setContentsTotal(calculateTotal(contentsData));
  }, [generalData, homeRepairData, landscapingData, vehicleData, contentsData]);

  // Add this state at the component level (near your other state variables)
  const [activeMenu, setActiveMenu] = useState(null);

  // State for menu management
  const [activeMenuId, setActiveMenuId] = useState(null);

  // Create separate menu state for each grid type instead of one shared state
  const [activeGeneralMenuId, setActiveGeneralMenuId] = useState(null);
  const [activeHomeRepairMenuId, setActiveHomeRepairMenuId] = useState(null);
  const [activeLandscapingMenuId, setActiveLandscapingMenuId] = useState(null);
  const [activeVehicleMenuId, setActiveVehicleMenuId] = useState(null);
  const [activeContentsMenuId, setActiveContentsMenuId] = useState(null);

  // Simple local ThreeDotMenu implementation
  const SimpleThreeDotMenu = ({ onEdit, onDelete }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div className="relative overflow-visible" ref={menuRef}>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className="cursor-pointer bg-transparent"
        >
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
          </svg>
        </button>
        {isOpen && (
          <div className="absolute right-0 top-6 z-[999] rounded-[4px] bg-white px-2 py-2 shadow-lg">
            {onEdit && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsOpen(false);
                  onEdit();
                }}
                className="flex w-full cursor-pointer items-center rounded-[4px] bg-white px-4 py-2 text-left text-sm hover:bg-barley-white"
              >
                Edit
              </button>
            )}
            {onDelete && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsOpen(false);
                  onDelete();
                }}
                className="flex w-full cursor-pointer items-center rounded-[4px] bg-white px-4 py-2 text-left text-sm text-dark-burgundy hover:bg-dark-burgundy hover:text-white"
              >
                Remove
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  // Then use the simpler menu in your command templates
  const generalCommandTemplate = (props) => {
    return (
      <div className="flex w-full justify-center overflow-visible px-4">
        <SimpleThreeDotMenu
          onEdit={() => {
            console.log("Edit clicked, grid ref:", generalGridRef.current);

            if (generalGridRef.current) {
              // Find the row index by primary key
              const rowIndex = generalGridRef.current.getRowIndexByPrimaryKey(
                props.generalID
              );
              console.log(
                "Row index found:",
                rowIndex,
                "for ID:",
                props.generalID
              );

              if (rowIndex !== undefined && rowIndex !== -1) {
                console.log("Attempting to select row and start edit");
                generalGridRef.current.selectRow(rowIndex);
                generalGridRef.current.startEdit();
              }
            }
          }}
          onDelete={
            userRole === "center"
              ? () => {
                  if (generalGridRef.current) {
                    generalGridRef.current.deleteRecord(null, props);
                  }
                }
              : undefined
          }
        />
      </div>
    );
  };

  const landscapingCommandTemplate = (props) => {
    return (
      <div className="flex w-full justify-center overflow-visible px-4">
        <SimpleThreeDotMenu
          onEdit={() => {
            console.log("Edit clicked, grid ref:", landscapingGridRef.current);

            if (landscapingGridRef.current) {
              // Find the row index by primary key
              const rowIndex =
                landscapingGridRef.current.getRowIndexByPrimaryKey(
                  props.landscapingID
                );
              console.log(
                "Row index found:",
                rowIndex,
                "for ID:",
                props.landscapingID
              );

              if (rowIndex !== undefined && rowIndex !== -1) {
                console.log("Attempting to select row and start edit");
                landscapingGridRef.current.selectRow(rowIndex);
                landscapingGridRef.current.startEdit();
              }
            }
          }}
          onDelete={
            userRole === "center"
              ? () => {
                  if (landscapingGridRef.current) {
                    landscapingGridRef.current.deleteRecord(props.rowIndex);
                  }
                }
              : undefined
          }
        />
      </div>
    );
  };

  const vehicleCommandTemplate = (props) => {
    return (
      <div className="flex w-full justify-center overflow-visible px-4">
        <SimpleThreeDotMenu
          onEdit={() => {
            console.log("Edit clicked, grid ref:", vehicleGridRef.current);

            if (vehicleGridRef.current) {
              // Find the row index by primary key
              const rowIndex = vehicleGridRef.current.getRowIndexByPrimaryKey(
                props.vehicleID
              );
              console.log(
                "Row index found:",
                rowIndex,
                "for ID:",
                props.vehicleID
              );

              if (rowIndex !== undefined && rowIndex !== -1) {
                console.log("Attempting to select row and start edit");
                vehicleGridRef.current.selectRow(rowIndex);
                vehicleGridRef.current.startEdit();
              }
            }
          }}
          onDelete={
            userRole === "center"
              ? () => {
                  if (vehicleGridRef.current) {
                    vehicleGridRef.current.deleteRecord(props.rowIndex);
                  }
                }
              : undefined
          }
        />
      </div>
    );
  };

  const contentsCommandTemplate = (props) => {
    return (
      <div className="flex w-full justify-center overflow-visible px-4">
        <SimpleThreeDotMenu
          onEdit={() => {
            console.log("Edit clicked, grid ref:", contentsGridRef.current);

            if (contentsGridRef.current) {
              // Find the row index by primary key
              const rowIndex = contentsGridRef.current.getRowIndexByPrimaryKey(
                props.contentsID
              );
              console.log(
                "Row index found:",
                rowIndex,
                "for ID:",
                props.contentsID
              );

              if (rowIndex !== undefined && rowIndex !== -1) {
                console.log("Attempting to select row and start edit");
                contentsGridRef.current.selectRow(rowIndex);
                contentsGridRef.current.startEdit();
              }
            }
          }}
          onDelete={
            userRole === "center"
              ? () => {
                  if (contentsGridRef.current) {
                    contentsGridRef.current.deleteRecord(props.rowIndex);
                  }
                }
              : undefined
          }
        />
      </div>
    );
  };

  // For debugging purposes, add a log for grid references
  useEffect(() => {
    console.log("Grid References:", {
      generalGridRef: generalGridRef?.current,
      homeRepairGridRef: homeRepairGridRef?.current,
      landscapingGridRef: landscapingGridRef?.current,
      vehicleGridRef: vehicleGridRef?.current,
      contentsGridRef: contentsGridRef?.current,
    });
  }, [
    generalGridRef,
    homeRepairGridRef,
    landscapingGridRef,
    vehicleGridRef,
    contentsGridRef,
  ]);

  // We no longer need the click outside handler since ThreeDotMenu handles that internally

  // Update the section headers to include totals
  return (
    <div
      className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start pb-[15px]`}
    >
      {isDialogOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
            <h2 className="bg-blue-500 p-4 text-xl font-bold text-white">
              Associated Disasters
            </h2>
            <div className="px-6 py-4">
              <pre className="whitespace-pre-wrap text-base">
                {dialogContent}
              </pre>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={closeDialog}
                  className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative mb-2 inline-block max-w-full">
        <label className="font-Inter text-[24px] font-semibold leading-normal text-[#770C0E]">
          Damage Assessment
        </label>
      </div>
      <div className="w-full">
        <section id="damage-assessment-general" className="h-auto self-stretch">
          <header
            className="mb-2 flex flex-row items-center justify-between self-stretch bg-[#770C0E] px-3 py-2"
            id="general"
          >
            <h2 className="text-sm font-semibold leading-normal text-white">
              General
            </h2>
            <div className="flex items-center gap-4">
              <span className="text-sm text-white">
                Total Amount: ${generalTotal.toLocaleString()}
              </span>
              <button
                type="button"
                className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#770C0E] text-white transition-all duration-300 ease-in-out"
                onClick={toggleGeneral}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isGeneralVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <img src={toggleDown} alt="down" className="h-8 w-8" />
                </span>
              </button>
            </div>
          </header>
          {isGeneralVisible && (
            <div className="cm-grid flex flex-col self-stretch">
              <GridComponent
                ref={generalGridRef}
                dataSource={generalData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={false}
                actionBegin={actionBeginGeneral}
                actionComplete={actionCompleteGeneral}
                headerClass="cm-grid-header"
                cssClass="new-grid-color allow-overflow-grid"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="generalID"
                    headerText="generalID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "1")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="250"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="date_entered"
                    headerText="Date Entered"
                    format="MM/dd/yyyy"
                    width="150"
                    allowEditing={false}
                    type="date"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="photo_general"
                    headerText="Photos"
                    width="160"
                    template={gridTemplate}
                    edit={generalEdit}
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="180"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    headerTextAlign="Right"
                  />
                  <ColumnDirective
                    field="Actions"
                    headerText="Actions"
                    width="100"
                    textAlign="Center"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.generalID}
                            activeMenuId={activeMenuGenerals}
                            setActiveMenuId={setActiveMenuGenerals}
                            clickOutside={true}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingGenerals(true);
                              setShowGeneralsModal(true);
                              setActiveMenuGenerals(null);
                              generalsReset({
                                generalID: props?.generalID || "",
                                sub_category: props?.sub_category || "",
                                damage: props?.damage || "",
                                estimate: props?.estimate || 0,
                                photo_general: props?.photo_general || "",
                                editor_ID: props?.editor_ID || "",
                                editor_type: props?.editor_type || "",
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteGeneralModal(true);
                              setActiveMenuGenerals(null);
                              setGeneralsToDeleteId(props?.generalID);
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
              <div>
                <AddNewButton
                  className="cm-btn-default-borderless flex cursor-pointer gap-1"
                  onClick={() => {
                    setIsEditingGenerals(false);
                    setShowGeneralsModal(true);
                    generalsReset({
                      generalID: "",
                      sub_category: "",
                      damage: "",
                      estimate: "",
                      photo_general: "",
                      is_deleted: false,
                    });
                  }}
                  name="Add New General"
                />
              </div>
            </div>
          )}
        </section>

        <Modal
          isOpen={showGeneralsModal}
          title={
            isEditingGenerals ? "Edit General Record" : "Add New General Record"
          }
          onClose={() => {
            setShowGeneralsModal(false);
            generalsReset();
          }}
          formRef={generalsFormRef}
          isLoading={isSubmittingGenerals}
          isEdit={isEditingGenerals}
        >
          <form
            onSubmit={generalsHandleSubmit(onSubmitGenerals)}
            className="flex flex-col gap-4"
            ref={generalsFormRef}
          >
            <div className="col-span-2">
              <CustomSelect
                name="sub_category"
                placeholder="Category"
                options={generalOptions}
                register={{
                  ...generalsRegister("sub_category", {
                    required: "Category is required",
                  }),
                }}
                errors={generalsErrors}
              />
            </div>
            <div className="w-full">
              <CustomTextArea
                type="text"
                name="damage"
                placeholder="Description"
                register={{
                  ...generalsRegister("damage", {
                    required: "Description is required",
                  }),
                }}
                errors={generalsErrors}
              />
            </div>
            <div className="col-span-2">
              <CustomInput
                type="number"
                name="estimate"
                placeholder="Estimate to Repair"
                register={{
                  ...generalsRegister("estimate", {
                    required: "Estimate is required",
                    valueAsNumber: true,
                    validate: {
                      positive: (value) =>
                        value >= 0 || "Amount must be positive",
                    },
                  }),
                }}
                errors={generalsErrors}
              />
            </div>
            <input type="hidden" {...generalsRegister("generalID")} />
            <input type="hidden" {...generalsRegister("editor_ID")} />
            <input type="hidden" {...generalsRegister("editor_type")} />
          </form>
          <div className="mt-5">
            <FileUploader
              title="Upload New Photo"
              storagePath={`disaster-mgt/general/${selectedUserId}`} // Use selectedUserId
              onSave={(e) => setValueGenerals("photo_general", [e])}
              className="w-full"
            />
          </div>
        </Modal>

        <DeleteModal
          isOpen={showDeleteGeneralModal}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeleteGeneralModal(false);
            setGeneralsToDeleteId(null);
          }}
          onDelete={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsSubmittingGenerals(true);

            try {
              if (generalsToDeleteId) {
                // Soft delete by updating the is_deleted flag
                await DisasterMgtService.updateRecord(
                  selectedUserId, // Use selectedUserId instead of accessedUserId
                  generalsToDeleteId,
                  { is_deleted: true, last_edited: new Date() },
                  "general"
                );
              }

              // Close modal and refresh data
              setShowDeleteGeneralModal(false);
              setGeneralsToDeleteId(null);
              setIsSubmittingGenerals(false);

              // Refresh the data
              try {
                const allData = await DisasterMgtService.getGeneral(
                  selectedUserId
                );
                const filteredData = allData.filter((item) => {
                  if (selectedDisasters.includes("All")) return true;
                  return item.associatedDisasters.some((disaster) =>
                    selectedDisasters.includes(disaster)
                  );
                });
                setGeneralData(filteredData.filter((item) => !item.is_deleted));
              } catch (error) {
                console.error("Error fetching updated data:", error);
              }
            } catch (error) {
              console.error("Error deleting General record:", error);
              setIsSubmittingGenerals(false);
            }
          }}
          itemName="General Record"
          loading={isSubmittingGenerals}
        />

        <section
          id="damage-assessment-home-repair"
          className="mt-4 h-auto self-stretch"
        >
          <header
            id="homerepairandreplacement"
            className="flex flex-row items-center justify-between self-stretch bg-[#770C0E] px-3 py-2"
          >
            <h2 className="text-sm font-semibold leading-normal text-white">
              Home Repair and Replacement
            </h2>
            <div className="flex items-center gap-4">
              <span className="text-sm text-white">
                Total Amount: ${homeRepairTotal.toLocaleString()}
              </span>
              <button
                type="button"
                className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#770C0E] text-white transition-all duration-300 ease-in-out"
                onClick={toggleHomeRepair}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isHomeRepairVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <img src={toggleDown} alt="down" className="h-8 w-8" />
                </span>
              </button>
            </div>
          </header>
          {isHomeRepairVisible && (
            <div className="cm-grid flex flex-col self-stretch">
              <GridComponent
                ref={homeRepairGridRef}
                dataSource={homeRepairData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={false}
                actionBegin={actionBeginHomeRepair}
                actionComplete={actionCompleteHomeRepair}
                headerClass="cm-grid-header"
                cssClass="new-grid-color allow-overflow-grid"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="homeRepairID"
                    headerText="homeRepairID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "2")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="250"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="date_entered"
                    headerText="Date Entered"
                    format="MM/dd/yyyy"
                    width="150"
                    allowEditing={false}
                    type="date"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="photo_home_repair"
                    headerText="Photos"
                    width="160"
                    template={homeRepairTemplate}
                    edit={homeRepairEdit}
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="180"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    headerTextAlign="Right"
                  />
                  <ColumnDirective
                    field="Actions"
                    headerText="Actions"
                    width="100"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.homeRepairID}
                            activeMenuId={activeMenuHomeRepair}
                            setActiveMenuId={setActiveMenuHomeRepair}
                            clickOutside={true}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingHomeRepair(true);
                              setShowHomeRepairModal(true);
                              setActiveMenuHomeRepair(null);
                              homeRepairReset({
                                homeRepairID: props?.homeRepairID || "",
                                sub_category: props?.sub_category || "",
                                damage: props?.damage || "",
                                estimate: props?.estimate || 0,
                                photo_home_repair:
                                  props?.photo_home_repair || "",
                                editor_ID: props?.editor_ID || "",
                                editor_type: props?.editor_type || "",
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteHomeRepairModal(true);
                              setActiveMenuHomeRepair(null);
                              setHomeRepairToDeleteId(props?.homeRepairID);
                            }}
                          />
                        </div>
                      );
                    }}
                    textAlign="Center"
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
              <div>
                <div>
                  <AddNewButton
                    className="cm-btn-default-borderless flex cursor-pointer gap-1"
                    onClick={() => {
                      setIsEditingHomeRepair(false);
                      setShowHomeRepairModal(true);
                      generalsReset({
                        generalID: "",
                        sub_category: "",
                        damage: "",
                        estimate: "",
                        photo_general: "",
                        is_deleted: false,
                      });
                    }}
                    name="Add New Home Repair and Replacement"
                  />
                </div>
              </div>
            </div>
          )}
        </section>

        <section
          id="damage-assessment-landscaping"
          className="mt-4 h-auto self-stretch"
        >
          <header
            id="landscaping"
            className="flex flex-row items-center justify-between self-stretch bg-[#770C0E] px-3 py-2"
          >
            <h2 className="text-sm font-semibold leading-normal text-white">
              Landscaping
            </h2>
            <div className="flex items-center gap-4">
              <span className="text-sm text-white">
                Total Amount: ${landscapingTotal.toLocaleString()}
              </span>
              <button
                type="button"
                className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#770C0E] text-white transition-all duration-300 ease-in-out"
                onClick={toggleLandscaping}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isLandscapingVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <img src={toggleDown} alt="down" className="h-8 w-8" />
                </span>
              </button>
            </div>
          </header>
          {isLandscapingVisible && (
            <div className="cm-grid flex flex-col self-stretch">
              <GridComponent
                ref={landscapingGridRef}
                dataSource={landscapingData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={false}
                actionBegin={actionBeginLandscaping}
                actionComplete={actionCompleteLandscaping}
                headerClass="cm-grid-header"
                cssClass="new-grid-color allow-overflow-grid"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="landscapingID"
                    headerText="landscapingID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "3")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="250"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="date_entered"
                    headerText="Date Entered"
                    format="MM/dd/yyyy"
                    width="150"
                    allowEditing={false}
                    type="date"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="photo_landscaping"
                    headerText="Photos"
                    width="160"
                    template={landscapingTemplate}
                    edit={landscapingEdit}
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="180"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    headerTextAlign="Right"
                  />
                  <ColumnDirective
                    headerText="Actions"
                    textAlign="Center"
                    width="120"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.landscapingID}
                            activeMenuId={activeMenuLandscaping}
                            setActiveMenuId={setActiveMenuLandscaping}
                            clickOutside={false}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingLandscaping(true);
                              setShowLandscapingModal(true);
                              setActiveMenuLandscaping(null);
                              landscapingReset({
                                damage: props?.damage || "",
                                sub_category: props?.sub_category || "",
                                estimate: props?.estimate || "",
                                photo_landscaping:
                                  props?.photo_landscaping || "",
                                landscapingID: props?.landscapingID || "",
                                editor_ID: props?.editor_ID || "",
                                editor_type: props?.editor_type || "",
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteLandscapingModal(true);
                              setActiveMenuLandscaping(null);
                              setLandscapingToDeleteId(props?.landscapingID);
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
              <div>
                <AddNewButton
                  onClick={() => {
                    setIsEditingLandscaping(false);
                    setShowLandscapingModal(true);
                    landscapingReset({
                      landscapingID: "",
                      sub_category: "",
                      damage: "",
                      estimate: "",
                      photo_landscaping: "",
                      is_deleted: false,
                    });
                  }}
                  name="Add New Landscaping"
                />
              </div>
            </div>
          )}
        </section>

        <Modal
          isOpen={showLandscapingModal}
          title={
            isEditingLandscaping ? "Edit Landscaping" : "Add New Landscaping"
          }
          onClose={() => {
            setShowLandscapingModal(false);
            setActiveMenuLandscaping(null);
            landscapingReset();
          }}
          formRef={landscapingFormRef}
          isLoading={isSubmittingLandscaping}
          isEdit={isEditingLandscaping}
        >
          <form
            onSubmit={landscapingHandleSubmit(onSubmitLandscaping)}
            className="flex flex-col gap-4"
            ref={landscapingFormRef}
          >
            <div className="col-span-2">
              <CustomSelect
                name="sub_category"
                placeholder="Category"
                options={landscapingOptions.map(({ subcategoryName }) => ({
                  label: subcategoryName,
                  value: subcategoryName,
                }))}
                register={{
                  ...landscapingRegister("sub_category", {
                    required: "Category is required",
                  }),
                }}
                errors={landscapingErrors}
              />
            </div>
            <div className="w-full">
              <CustomTextArea
                type="text"
                name="damage"
                placeholder="Description"
                register={{
                  ...landscapingRegister("damage", {
                    required: "Description is required",
                  }),
                }}
                errors={landscapingErrors}
              />
            </div>
            <div className="col-span-2">
              <CustomInput
                type="number"
                name="estimate"
                placeholder="Estimate to Repair"
                register={{
                  ...landscapingRegister("estimate", {
                    required: "Estimate is required",
                    valueAsNumber: true,
                    validate: {
                      positive: (value) =>
                        value >= 0 || "Amount must be positive",
                    },
                  }),
                }}
                errors={landscapingErrors}
              />
            </div>
          </form>{" "}
          <div className="mt-5">
            <FileUploader
              title="Upload New Photo"
              storagePath={`disaster-mgt/landscaping/${selectedUserId}`} // Use selectedUserId
              onSave={(e) => setValueLandscaping("photo_landscaping", [e])}
            />
          </div>
        </Modal>

        <DeleteModal
          isOpen={showDeleteLandscapingModal}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeleteLandscapingModal(false);
            setLandscapingToDeleteId(null);
          }}
          onDelete={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsSubmittingLandscaping(true);

            try {
              if (landscapingToDeleteId) {
                // Soft delete by updating the is_deleted flag
                await DisasterMgtService.updateRecord(
                  selectedUserId, // Use selectedUserId instead of accessedUserId
                  landscapingToDeleteId,
                  { is_deleted: true, last_edited: new Date() },
                  "landscaping"
                );
              }

              // Close modal and refresh data
              setShowDeleteLandscapingModal(false);
              setLandscapingToDeleteId(null);
              setIsSubmittingLandscaping(false);

              // Refresh the data
              try {
                const allData = await DisasterMgtService.getLandscaping(
                  selectedUserId
                );
                const filteredData = allData.filter((item) => {
                  if (selectedDisasters.includes("All")) return true;
                  return item.associatedDisasters.some((disaster) =>
                    selectedDisasters.includes(disaster)
                  );
                });
                setLandscapingData(
                  filteredData.filter((item) => !item.is_deleted)
                );
              } catch (error) {
                console.error("Error fetching updated data:", error);
              }
            } catch (error) {
              console.error("Error deleting Landscaping record:", error);
              setIsSubmittingLandscaping(false);
            }
          }}
          itemName="Landscaping Record"
          loading={isSubmittingLandscaping}
        />

        <section
          id="damage-assessment-vehicles"
          className="mt-4 h-auto self-stretch"
        >
          <header
            id="vehicles"
            className="flex flex-row items-center justify-between self-stretch bg-[#770C0E] px-3 py-2"
          >
            <h2 className="text-sm font-semibold leading-normal text-white">
              Vehicles
            </h2>
            <div className="flex items-center gap-4">
              <span className="text-sm text-white">
                Total Amount: ${vehicleTotal.toLocaleString()}
              </span>
              <button
                type="button"
                className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#770C0E] text-white transition-all duration-300 ease-in-out"
                onClick={toggleVehicle}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isVehicleVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <img src={toggleDown} alt="down" className="h-8 w-8" />
                </span>
              </button>
            </div>
          </header>
          {isVehicleVisible && (
            <div className="cm-grid flex flex-col gap-2 self-stretch">
              <GridComponent
                ref={vehicleGridRef}
                dataSource={vehicleData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={false}
                actionBegin={actionBeginVehicle}
                actionComplete={actionCompleteVehicle}
                headerClass="cm-grid-header"
                cssClass="new-grid-color allow-overflow-grid"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="vehicleID"
                    headerText="vehicleID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "4")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="250"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "4")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="date_entered"
                    headerText="Date Entered"
                    format="MM/dd/yyyy"
                    width="150"
                    allowEditing={false}
                    type="date"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="photo_vehicle"
                    headerText="Photos"
                    width="160"
                    template={vehicleTemplate}
                    edit={vehicleEdit}
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="180"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    headerTextAlign="Right"
                  />
                  <ColumnDirective
                    field="Actions"
                    headerText="Actions"
                    width="100"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.vehicleID}
                            activeMenuId={activeMenuVehicles}
                            setActiveMenuId={setActiveMenuVehicles}
                            clickOutside={true}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingGenerals(true);
                              setToggleVehiclesModal(true);
                              setActiveMenuVehicles(null);
                              generalsReset({
                                vehicleID: props?.vehicleID || "",
                                sub_category: props?.sub_category || "",
                                damage: props?.damage || "",
                                estimate: props?.estimate || 0,
                                photo_vehicles: props?.photo_vehicles || "",
                                editor_ID: props?.editor_ID || "",
                                editor_type: props?.editor_type || "",
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteVehiclesModal(true);
                              setActiveMenuVehicles(null);
                              setVehicleToDeleteId(props?.vehicleID);
                            }}
                          />
                        </div>
                      );
                    }}
                    textAlign="Center"
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
              <div>
                <button
                  type="button"
                  className="cm-btn-default-borderless flex cursor-pointer gap-1"
                  onClick={() => {
                    setIsVehiclesEditing(() => false);
                    setToggleVehiclesModal(() => true);
                  }}
                >
                  <FaPlus className="h-3 w-3" />
                  Add New Vehicles
                </button>
              </div>
            </div>
          )}
        </section>

        <Modal
          isOpen={toggleVehiclesModal}
          title={
            isVehiclesEditing ? "Edit Vehicle Record" : "Add New Vehicle Record"
          }
          onClose={() => {
            setToggleVehiclesModal(() => false);
            vehicleReset();
          }}
          formRef={vehicleFormRef}
          isLoading={isSubmittingVehicles}
          isEdit={isVehiclesEditing}
        >
          <form
            onSubmit={vehicleHandleSubmit(onSubmitVehicles)}
            className="flex flex-col gap-4"
            ref={vehicleFormRef}
          >
            <div className="col-span-2">
              <CustomSelect
                name="sub_category"
                placeholder="Category"
                options={vehicleOptions.map(({ subcategoryName }) => ({
                  label: subcategoryName,
                  value: subcategoryName,
                }))}
                register={{
                  ...vehicleRegister("sub_category", {
                    required: "Category is required",
                  }),
                }}
                errors={vehicleErrors}
              />
            </div>
            <div className="w-full">
              <CustomTextArea
                type="text"
                name="damage"
                placeholder="Description"
                register={{
                  ...vehicleRegister("damage", {
                    required: "Description is required",
                  }),
                }}
                errors={vehicleErrors}
              />
            </div>
            <div className="col-span-2">
              <CustomInput
                type="number"
                name="estimate"
                placeholder="Estimate to Repair"
                register={{
                  ...vehicleRegister("estimate", {
                    required: "Estimate is required",
                    valueAsNumber: true,
                    validate: {
                      positive: (value) =>
                        value >= 0 || "Amount must be positive",
                    },
                  }),
                }}
                errors={vehicleErrors}
              />
            </div>
            <input type="hidden" {...generalsRegister("generalID")} />
            <input type="hidden" {...generalsRegister("editor_ID")} />
            <input type="hidden" {...generalsRegister("editor_type")} />
          </form>
          <div className="mt-5">
            <FileUploader
              title="Upload New Photo"
              storagePath={`disaster-mgt/vehicle/${selectedUserId}`} // Use selectedUserId
              onSave={(e) => setValueVehicle("photo_vehicle", [e])}
              className="w-full"
            />
          </div>
        </Modal>

        <section
          id="damage-assessment-contents"
          className="mt-4 h-auto self-stretch"
        >
          <header
            id="contents"
            className="flex flex-row items-center justify-between self-stretch bg-[#770C0E] px-3 py-2"
          >
            <h2 className="text-sm font-semibold leading-normal text-white">
              Contents
            </h2>
            <div className="flex items-center gap-4">
              <span className="text-sm text-white">
                Total Amount: ${contentsTotal.toLocaleString()}
              </span>
              <button
                type="button"
                className="flex h-4 w-4 cursor-pointer items-center justify-center  bg-[#770C0E] text-white transition-all duration-300 ease-in-out"
                onClick={toggleContents}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isContentsVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <img src={toggleDown} alt="down" className="h-8 w-8" />
                </span>
              </button>
            </div>
          </header>
          {isContentsVisible && (
            <div className="cm-grid flex flex-col gap-2 self-stretch">
              <GridComponent
                ref={contentsGridRef}
                dataSource={contentsData}
                allowSorting={true}
                sortSettings={sortSettings}
                editSettings={editing}
                filterSettings={filterSettings}
                allowFiltering={false}
                actionBegin={actionBeginContents}
                actionComplete={actionCompleteContents}
                headerClass="cm-grid-header"
                cssClass="new-grid-color allow-overflow-grid"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="contentsID"
                    headerText="contentsID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="category"
                    headerText="Category"
                    visible={false}
                    defaultValue={
                      categories.find((cat) => cat.categoryId === "5")
                        .categoryName
                    }
                  />
                  <ColumnDirective
                    field="sub_category"
                    headerText="Category"
                    width="250"
                    template={categoryTemplate}
                    editType="dropdownedit"
                    textAlign="Left"
                    headerTextAlign="Left"
                    edit={{
                      params: {
                        dataSource: subcategories
                          .filter((sub) => sub.categoryId === "5")
                          .map((sub) => sub.subcategoryName),
                        fields: {
                          text: "subcategoryName",
                          value: "subcategoryName",
                        },
                        query: new Query(),
                      },
                    }}
                  />
                  <ColumnDirective
                    field="damage"
                    headerText="Description"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="date_entered"
                    headerText="Date Entered"
                    format="MM/dd/yyyy"
                    width="150"
                    allowEditing={false}
                    type="date"
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="photo_contents"
                    headerText="Photos"
                    width="160"
                    template={contentsTemplate}
                    edit={contentsEdit}
                    textAlign="Left"
                    headerTextAlign="Left"
                  />
                  <ColumnDirective
                    field="estimate"
                    headerText="Estimate to Repair"
                    width="180"
                    format="C"
                    editType="numericedit"
                    textAlign="Right"
                    headerTextAlign="Right"
                  />
                  <ColumnDirective
                    field="Actions"
                    headerText="Actions"
                    width="100"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.contentsID}
                            activeMenuId={activeMenuContents}
                            setActiveMenuId={setActiveMenuContents}
                            clickOutside={true}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsContentsEditing(true);
                              setShowGeneralsModal(true);
                              setActiveMenuContents(null);
                              generalsReset({
                                contentsID: props?.contentsID || "",
                                sub_category: props?.sub_category || "",
                                damage: props?.damage || "",
                                estimate: props?.estimate || 0,
                                photo_general: props?.photo_general || "",
                                editor_ID: props?.editor_ID || "",
                                editor_type: props?.editor_type || "",
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteGeneralModal(true);
                              setActiveMenuContents(null);
                              setGeneralsToDeleteId(props?.contentsID);
                            }}
                          />
                        </div>
                      );
                    }}
                    textAlign="Center"
                  />
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, CommandColumn, Sort, Filter]}
                />
              </GridComponent>
              <div>
                <button
                  type="button"
                  className="cm-btn-default-borderless flex cursor-pointer gap-1"
                  onClick={() => {
                    setIsContentsEditing(() => false);
                    setToggleContentsModal(() => true);
                  }}
                >
                  <FaPlus className="h-3 w-3" />
                  Add New Contents
                </button>
              </div>
            </div>
          )}

          <Modal
            isOpen={toggleContentsModal}
            title={
              isContentsEditing
                ? "Edit Contents Record"
                : "Add New Contents Record"
            }
            onClose={() => {
              setToggleContentsModal(() => false);
              contentsReset();
            }}
            formRef={contentsFormRef}
            isLoading={isSubmittingContents}
            isEdit={isContentsEditing}
          >
            <form
              onSubmit={contentsHandleSubmit(onSubmitContents)}
              className="flex flex-col gap-4"
              ref={contentsFormRef}
            >
              <div className="col-span-2">
                <CustomSelect
                  name="sub_category"
                  placeholder="Category"
                  options={contentsOptions.map(({ subcategoryName }) => ({
                    label: subcategoryName,
                    value: subcategoryName,
                  }))}
                  register={{
                    ...contentsRegister("sub_category", {
                      required: "Category is required",
                    }),
                  }}
                  errors={generalsErrors}
                />
              </div>
              <div className="w-full">
                <CustomTextArea
                  type="text"
                  name="damage"
                  placeholder="Description"
                  register={{
                    ...contentsRegister("damage", {
                      required: "Description is required",
                    }),
                  }}
                  errors={generalsErrors}
                />
              </div>
              <div className="col-span-2">
                <CustomInput
                  type="number"
                  name="estimate"
                  placeholder="Estimate to Repair"
                  register={{
                    ...contentsRegister("estimate", {
                      required: "Estimate is required",
                      valueAsNumber: true,
                      validate: {
                        positive: (value) =>
                          value >= 0 || "Amount must be positive",
                      },
                    }),
                  }}
                  errors={generalsErrors}
                />
              </div>
              <input type="hidden" {...generalsRegister("generalID")} />
              <input type="hidden" {...generalsRegister("editor_ID")} />
              <input type="hidden" {...generalsRegister("editor_type")} />
            </form>
            <div className="mt-5">
              <FileUploader
                title="Upload New Photo"
                storagePath={`disaster-mgt/contents/${selectedUserId}`} // Use selectedUserId
                onSave={(e) => setValueContents("photo_contents", [e])}
                className="w-full"
              />
            </div>
          </Modal>
        </section>
      </div>
      <ImageDialog
        isOpen={isImageDialogOpen}
        onClose={() => setIsImageDialogOpen(false)}
        images={selectedImages}
        currentIndex={currentImageIndex}
        setCurrentIndex={setCurrentImageIndex}
        setImages={setSelectedImages}
      />
      <CustomPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        message={popupMessage}
      />
      {/* Home Repair Modal */}
      <Modal
        isOpen={showHomeRepairModal}
        title={isEditingHomeRepair ? "Edit Home Repair" : "Add New Home Repair"}
        onClose={() => {
          setShowHomeRepairModal(false);
          setIsEditingHomeRepair(false);
          setEditingHomeRepairRecord(null);
          homeRepairReset();
        }}
        formRef={homeRepairFormRef}
        isLoading={isSubmittingHomeRepair}
        isEdit={isEditingHomeRepair}
      >
        <form
          onSubmit={homeRepairHandleSubmit(onSubmitHomeRepair)}
          className="flex flex-col gap-4"
          ref={homeRepairFormRef}
        >
          <div className="col-span-2">
            <CustomSelect
              name="sub_category"
              placeholder="Category"
              options={subcategories
                .filter((sub) => sub.categoryId === "2")
                .map((sub) => ({
                  value: sub.subcategoryName,
                  label: sub.subcategoryName,
                }))}
              register={{
                ...homeRepairRegister("sub_category", {
                  required: "Category is required",
                }),
              }}
              errors={homeRepairErrors}
            />
          </div>
          <div className="w-full">
            <CustomTextArea
              type="text"
              name="damage"
              placeholder="Description"
              register={{
                ...homeRepairRegister("damage", {
                  required: "Description is required",
                }),
              }}
              errors={homeRepairErrors}
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type="number"
              name="estimate"
              placeholder="Estimate to Repair"
              register={{
                ...homeRepairRegister("estimate", {
                  required: "Estimate is required",
                  valueAsNumber: true,
                  validate: {
                    positive: (value) =>
                      value >= 0 || "Amount must be positive",
                  },
                }),
              }}
              errors={homeRepairErrors}
            />
          </div>
        </form>
        <div className="mt-5">
          <FileUploader
            title="Upload New Photo"
            storagePath={`disaster-mgt/home_repair/${selectedUserId}`} // Use selectedUserId
            onSave={(e) => homeRepairSetValue("photo_home_repair", [e])}
            className="w-full"
          />
        </div>
      </Modal>

      {/* Home Repair Delete Modal */}
      <DeleteModal
        isOpen={showDeleteHomeRepairModal}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDeleteHomeRepairModal(false);
          setHomeRepairToDeleteId(null);
        }}
        onDelete={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsSubmittingHomeRepair(true);

          try {
            if (homeRepairToDeleteId) {
              // Soft delete by updating the is_deleted flag
              await DisasterMgtService.updateRecord(
                selectedUserId, // Use selectedUserId instead of accessedUserId
                homeRepairToDeleteId,
                { is_deleted: true, last_edited: new Date() },
                "home_repair"
              );
            }

            // Close modal and refresh data
            setShowDeleteHomeRepairModal(false);
            setHomeRepairToDeleteId(null);
            setIsSubmittingHomeRepair(false);

            // Refresh the data
            try {
              const allData = await DisasterMgtService.getHomeRepair(
                selectedUserId
              );
              const filteredData = allData.filter((item) => {
                if (selectedDisasters.includes("All")) return true;
                return item.associatedDisasters.some((disaster) =>
                  selectedDisasters.includes(disaster)
                );
              });
              setHomeRepairData(
                filteredData.filter((item) => !item.is_deleted)
              );
            } catch (error) {
              console.error("Error fetching updated data:", error);
            }
          } catch (error) {
            console.error("Error deleting Home Repair:", error);
            setIsSubmittingHomeRepair(false);
          }
        }}
        itemName="Home Repair Record"
        loading={isSubmittingHomeRepair}
      />
    </div>
  );
};

const CustomPopup = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
        <h2 className="bg-blue-500 p-4 text-xl font-bold text-white">Error</h2>
        <div className="px-6 py-4">
          <p className="text-lg">{message}</p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DisasterManagement;
