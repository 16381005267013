import React, { useState, useEffect, useRef, useMemo } from "react";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Sort,
  Filter,
  Edit,
  CommandColumn,
  Inject,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";

import { USStates } from "./../datasource";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import partnerProfileService from "../../service/partner/PartnerProfileService";
import "./../style.css";
import { FaEdit, FaChevronDown, FaSave, FaTimes, FaPlus } from "react-icons/fa";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DropDownListComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import userService from "../../service/UserService";
import countiesData from "../../counties_list.json";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";

const PartnerProfile = ({ className = "" }) => {
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [partnerDisasters, setPartnerDisasters] = useState([]);
  const gridRefMembers = useRef(null);
  const gridRefDisasters = useRef(null);
  const [tempFormData, setTempFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [partner, setPartner] = useState({});
  const [isPersonalInfoVisible, setIsPersonalInfoVisible] = useState(true);
  const [counties, setCounties] = useState([]);
  const [countiesAreaServiced, setCountiesAreaServiced] = useState([]);
  const [selectedStateAreaServiced, setSelectedStateAreaServiced] =
    useState("");
  const [requiredFields, setRequiredFields] = useState([]);
  const [isDisasterVisible, setIsDisasterVisible] = useState(true);
  const [workingHours, setWorkingHours] = useState({
    monday: {
      enabled: false,
      startTime: "9:00",
      startPeriod: "AM",
      endTime: "5:00",
      endPeriod: "PM",
    },
    tuesday: {
      enabled: false,
      startTime: "9:00",
      startPeriod: "AM",
      endTime: "5:00",
      endPeriod: "PM",
    },
    wednesday: {
      enabled: false,
      startTime: "9:00",
      startPeriod: "AM",
      endTime: "5:00",
      endPeriod: "PM",
    },
    thursday: {
      enabled: false,
      startTime: "9:00",
      startPeriod: "AM",
      endTime: "5:00",
      endPeriod: "PM",
    },
    friday: {
      enabled: false,
      startTime: "9:00",
      startPeriod: "AM",
      endTime: "5:00",
      endPeriod: "PM",
    },
    saturday: {
      enabled: false,
      startTime: "9:00",
      startPeriod: "AM",
      endTime: "5:00",
      endPeriod: "PM",
    },
    sunday: {
      enabled: false,
      startTime: "9:00",
      startPeriod: "AM",
      endTime: "5:00",
      endPeriod: "PM",
    },
  });
  const [savedWorkingHours, setSavedWorkingHours] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const multiSelectRef = useRef(null);
  const [isEmergencyMode, setIsEmergencyMode] = useState(false);
  const [backupData, setBackupData] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");
  const [userData, setUserData] = useState(null);

  const services = [
    { id: 1, name: "Funding" },
    { id: 2, name: "Debris Removal" },
    { id: 3, name: "Case Management" },
    { id: 5, name: "House Repair" },
    { id: 6, name: "House Rebuilds" },
    { id: 7, name: "Volunteer Labor" },
    { id: 8, name: "Spriritual/Mental Care" },
    { id: 6, name: "Donation of Goods" },
  ];

  const fields = { text: "name", value: "name" };

  const yesNoOptions = [
    { text: "Yes", value: "Yes" },
    { text: "No", value: "No" },
  ];

  const donationTypeOptions = [
    { text: "Monetary", value: "monetary" },
    { text: "Food", value: "food" },
    { text: "Water", value: "water" },
    { text: "Hygiene Products", value: "hygiene" },
    { text: "Sanitation Products", value: "sanitation" },
    { text: "Clothing", value: "clothing" },
    { text: "Furniture", value: "furniture" },
    { text: "Medical Supplies", value: "medical" },
    { text: "Household Supplies", value: "household" },
    { text: "Childcare Items", value: "childcare" },
    { text: "Pet Supplies", value: "pet" },
    { text: "Tools and Equipment", value: "tools" },
    { text: "Personal Comfort Items", value: "comfort" },
    { text: "School Supplies", value: "school" },
    { text: "Other", value: "other" },
  ];

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (selectedUserId) {
      fetchData();
    }
  }, [selectedUserId]);

  const getPartnerByUserId = async () => {
    const p = await partnerProfileService.getPartnerByUserId(selectedUserId);
    setPartner(p);
  };

  const fetchData = async () => {
    try {
      // Fetch partner profile data
      const userData = await userService.getUserById(selectedUserId);

      let partnerData = await partnerProfileService.getPartnerProfile(
        "partners",
        userData.partner_id
      );
      setUserData(partnerData);

      if (!partnerData) {
        const partnerRef = doc(db, "partners", userData.partner_id);
        const partnerSnap = await getDoc(partnerRef);
        partnerData = partnerSnap.exists()
          ? { id: partnerSnap.id, ...partnerSnap.data() }
          : null;
      }

      if (partnerData) {
        // Convert text back to values for donation_types and available_items
        const getValueFromText = (text) => {
          if (text === "other") return "other";
          const option = donationTypeOptions.find((opt) => opt.text === text);
          return option?.value || text;
        };

        const formattedPartnerData = {
          ...partnerData,
          donation_types:
            partnerData.donation_types?.map(getValueFromText) || [],
          available_items:
            partnerData.available_items?.map(getValueFromText) || [],
          other_donation_types: partnerData.other_donation_types || "",
        };

        setData(formattedPartnerData);
        setTempFormData(formattedPartnerData);

        // Set the selected state to filter counties
        if (partnerData.state) {
          const selectedState = partnerData.state;
          const getState = USStates.find(
            (s) => s.abbreviation === selectedState
          ).name;
          const filteredCounties = countiesData
            .filter((cd) => cd.State === getState)
            .map((cd) => cd.County); // Just map to county names directly
          setCounties(filteredCounties);
          setSelectedState(selectedState);
        }

        if (partnerData.area_serviced_state) {
          const selectedState = partnerData.area_serviced_state;
          const getState = USStates.find(
            (s) => s.abbreviation === selectedState
          ).name;
          const filteredCounties = countiesData
            .filter((cd) => cd.State === getState)
            .map((cd) => cd.County); // Just map to county names directly
          setCountiesAreaServiced(filteredCounties);
          setSelectedStateAreaServiced(selectedState);
        }

        // Set the area serviced counties
        if (
          partnerData.area_serviced_county &&
          Array.isArray(partnerData.area_serviced_county)
        ) {
          setTempFormData((prev) => ({
            ...prev,
            area_serviced_county: partnerData.area_serviced_county,
          }));
        }

        if (partnerData.county) {
          setTempFormData((prev) => ({
            ...prev,
            county: partnerData.county,
          }));
        }

        // Initialize default working hours structure
        const defaultHours = {
          enabled: false,
          startTime: "9:00",
          startPeriod: "AM",
          endTime: "5:00",
          endPeriod: "PM",
        };

        // Set working hours based on fetched data
        const loadedHours = {
          monday: { ...defaultHours },
          tuesday: { ...defaultHours },
          wednesday: { ...defaultHours },
          thursday: { ...defaultHours },
          friday: { ...defaultHours },
          saturday: { ...defaultHours },
          sunday: { ...defaultHours },
        };

        Object.entries(partnerData.service_sched).forEach(([day, schedule]) => {
          if (schedule) {
            loadedHours[day] = {
              enabled: schedule.enabled || false,
              startTime: schedule.start?.time || "9:00",
              startPeriod: schedule.start?.period || "AM",
              endTime: schedule.end?.time || "5:00",
              endPeriod: schedule.end?.period || "PM",
            };
          }
        });

        // Store the loaded hours in both states
        setSavedWorkingHours(loadedHours);
        setWorkingHours(loadedHours);

        // Set the hours_of_service in tempFormData
        setTempFormData((prev) => ({
          ...prev,
          hours_of_service: partnerData.hours_of_service || "24x7",
        }));

        // Set members data
        const membersData = partnerData.members || {};

        // Fetch partner disasters data
        const partnerDisastersData = await partnerProfileService.getDisasters(
          userId
        );
        setPartnerDisasters(partnerDisastersData);

        // get data from partners table instead of user
        await getPartnerByUserId();

        // Set donations_needed and emergency_flags
        setTempFormData((prev) => ({
          ...prev,
          donations_needed: partnerData.donations_needed || [],
          emergency_flags: partnerData.emergency_flags || {},
        }));

        setData((prev) => ({
          ...prev,
          donations_needed: partnerData.donations_needed || [],
          emergency_flags: partnerData.emergency_flags || {},
        }));
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const startEditing = (event) => {
    event.preventDefault();
    setTempFormData({ ...data });
    setBackupData({ ...data });
    setIsEditMode(true);
  };

  const saveChanges = async (event) => {
    event.preventDefault();

    // Format working hours data into nested structure
    const service_sched = {};
    Object.entries(workingHours).forEach(([day, hours]) => {
      if (hours.enabled) {
        service_sched[day] = {
          enabled: hours.enabled,
          start: {
            time: hours.startTime,
            period: hours.startPeriod,
          },
          end: {
            time: hours.endTime,
            period: hours.endPeriod,
          },
        };
      } else {
        service_sched[day] = {
          enabled: false,
          start: null,
          end: null,
        };
      }
    });

    // Create a copy of the form data to validate
    const formDataToValidate = { ...tempFormData };

    // Define required fields
    const requiredFields = [
      "name_of_org",
      "first_name",
      "last_name",
      "mobile_number",
      "address1",
      "city",
      "state",
      "zip",
      "county",
      "area_serviced_county",
      "servicesProvided",
      "a501c3",
    ];

    // Check for missing required fields
    const missingFields = requiredFields.filter((field) => {
      // Special handling for arrays
      if (field === "area_serviced_county" || field === "servicesProvided") {
        return !formDataToValidate[field] || !formDataToValidate[field].length;
      }

      // Special handling for EIN
      if (formDataToValidate.a501c3 === "Yes" && field === "ein") {
        return !formDataToValidate.ein;
      }

      // For county field, check if it exists in tempFormData
      if (field === "county") {
        return !formDataToValidate.county;
      }

      // Default check for other fields
      return !formDataToValidate[field];
    });

    // If there are missing fields, show the error
    if (missingFields.length > 0) {
      setRequiredFields(missingFields);
      return;
    }

    try {
      // Check if address fields have changed
      const addressChanged =
        data.address1 !== tempFormData.address1 ||
        data.city !== tempFormData.city ||
        data.state !== tempFormData.state ||
        data.zip !== tempFormData.zip;

      // Prepare final data for saving
      const currentTime = new Date();
      let updatedData = {
        ...formDataToValidate,
        hours_of_service: tempFormData.hours_of_service || "24x7",
        service_sched: service_sched,
        donations_needed: tempFormData.donations_needed || [],
        emergency_flags: tempFormData.emergency_flags || {},
        updated_at: currentTime,
      };

      // Only attempt geocoding if address has changed
      if (addressChanged) {
        // Construct address string
        const addressString = `${tempFormData.address1}, ${tempFormData.city}, ${tempFormData.state} ${tempFormData.zip}`;

        try {
          // Get coordinates from Google Geocoding API
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              addressString
            )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
          );

          const geocodeData = await response.json();

          if (
            geocodeData.status === "OK" &&
            geocodeData.results &&
            geocodeData.results[0]
          ) {
            const { lat, lng } = geocodeData.results[0].geometry.location;
            updatedData = {
              ...updatedData,
              latitude: lat,
              longitude: lng,
            };
            console.log(`Updated coordinates: ${lat}, ${lng}`);
          } else {
            console.warn(
              "Geocoding response not as expected:",
              geocodeData.status
            );
          }
        } catch (geocodeError) {
          console.error("Error geocoding address:", geocodeError);
          // Continue with save even if geocoding fails
        }
      } else {
        // Preserve existing coordinates if address hasn't changed
        if (data.latitude && data.longitude) {
          updatedData = {
            ...updatedData,
            latitude: data.latitude,
            longitude: data.longitude,
          };
        }
      }

      // Update in users collection
      if (missingFields.length === 0) {
        await partnerProfileService.updateProfileComplete(
          "users",
          selectedUserId
        );
      }

      if (data.id) {
        // Update in partners collection
        await updateDoc(doc(db, "partners", data.id), updatedData);
      }

      // Update local state
      setData(updatedData);
      console.log("DAtaa: ", updatedData);
      setTempFormData(updatedData);
      setIsEditMode(false);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const cancelEditing = (event) => {
    event.preventDefault();
    setTempFormData(backupData);
    setIsEditMode(false);
    setRequiredFields(() => []);
  };

  const onShowRequiredFields = useMemo(
    () => (fields) => {
      return !!requiredFields.find((rf) => rf === fields);
    },
    [requiredFields]
  );

  const generateUniqueID = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  const handleAddDisaster = () => {
    if (gridRefDisasters.current) {
      gridRefDisasters.current.addRecord();
    }
  };

  const actionBegin = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure memberID is set and status is true
        const newMemberData = {
          ...args.data,
          memberId: args.data.memberId || generateUniqueID(),
          is_deleted: false, // Set status to true when adding
        };
        await partnerProfileService.addMember(userId, newMemberData);
      } else if (args.action === "edit") {
        await partnerProfileService.updateMember(
          userId,
          args.data.id,
          args.data
        );
      }
      fetchData(); // Refresh the data after adding or editing
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      const updatedData = {
        ...args.data[0],
        is_deleted: true,
      };
      await partnerProfileService.updateMember(
        userId,
        updatedData.id,
        updatedData
      );
      fetchData(); // Refresh the data after deleting
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit" ? "Edit Member" : "Add New Member";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const actionBeginDisaster = async (args) => {
    if (args.requestType === "save") {
      if (args.action === "add") {
        // Ensure disasterId is set and status is true
        const newDisasterData = {
          ...args.data,
          disasterId: args.data.disasterId || generateUniqueID(),
          is_deleted: false, // Set status to true when adding
        };
        await partnerProfileService.addDisasters(userId, newDisasterData);
      } else if (args.action === "edit") {
        await partnerProfileService.updateDisasters(
          userId,
          args.data.id,
          args.data
        );
      }
      fetchData(); // Refresh the data after adding or editing
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      const updatedData = {
        ...args.data[0],
        is_deleted: true,
      };
      await partnerProfileService.updateDisasters(
        userId,
        updatedData.id,
        updatedData
      );
      fetchData(); // Refresh the data after deleting
    } else if (args.requestType === "delete") {
      // Set status to false when deleting
      await partnerProfileService.deleteDisaster(userId, args.data[0].id);
    } else if (args.requestType === "beginEdit" || args.requestType === "add") {
      if (args.dialog) {
        const dialog = args.dialog;
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Disaster Info"
            : "Add Disaster Info";

        // Set the header styles
        if (dialog.element) {
          const headerContent = dialog.element.querySelector(
            ".e-dlg-header-content"
          );
          const header = dialog.element.querySelector(".e-dlg-header");
          if (headerContent) {
            headerContent.style.backgroundColor = "#348BFF";
            headerContent.style.color = "#FFFFFF";
          }
          if (header) {
            header.style.color = "#FFFFFF";
          }

          // Style the save and cancel buttons
          setTimeout(() => {
            const saveButton = dialog.element.querySelector(".e-primary");
            const cancelButton = dialog.element.querySelector(
              ".e-btn:not(.e-primary)"
            );

            if (saveButton) {
              saveButton.style.backgroundColor = "#FFFFFF";
              saveButton.style.color = "#348BFF";
              saveButton.style.border = "none";
            }

            if (cancelButton) {
              cancelButton.style.backgroundColor = "#FFFFFF";
              cancelButton.style.color = "#348BFF";
              cancelButton.style.border = "1px solid #348BFF";
            }
          }, 0);
        }
      }
    }
  };

  const sortSettings = {
    allowSorting: true,
  };
  const filterSettings = {
    type: "CheckBox",
  };

  const togglePersonalInfo = (event) => {
    event.preventDefault();
    setIsPersonalInfoVisible(!isPersonalInfoVisible);
  };

  const toggleDisaster = () => {
    setIsDisasterVisible(!isDisasterVisible);
  };

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isNewlyRegistered = localStorage.getItem("newlyRegistered");
    if (isNewlyRegistered === "true") {
      setShowPopup(true);
      localStorage.removeItem("newlyRegistered"); // Remove the flag after showing the popup
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleStateChange = (e) => {
    const stateAbbreviation = e.target.value;
    const stateObject = USStates.find(
      (s) => s.abbreviation === stateAbbreviation
    );
    const stateName = stateObject ? stateObject.name : "";

    // Update both states at once
    setSelectedStateAreaServiced(stateAbbreviation);
    setTempFormData((prev) => ({
      ...prev,
      area_serviced_state: stateAbbreviation,
    }));

    // Filter counties based on selected state
    const filteredCounties = countiesData
      .filter((cd) => cd.State === stateName)
      .map((cd) => cd.County);
    setCountiesAreaServiced(filteredCounties);

    // Update data state as well to prevent reversion on blur
    // setData((prev) => ({
    //   ...prev,
    //   area_serviced_state: stateAbbreviation,
    //   county: "", // Reset county when state changes
    // }));
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "Enter",
      "Shift",
      "ArrowLeft",
      "ArrowRight",
      "Space",
    ];

    if (!/^[a-zA-Z\s]$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyDownAddress = (e) => {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "Enter",
      "Shift",
      "ArrowLeft",
      "ArrowRight",
      "Space",
      "-",
      "#",
      ".",
      ",",
    ];

    if (!/^[a-zA-Z0-9\s,.\-#]$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const dialogContent = () => {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "normal",
            color: "#1e1919",
            marginBottom: "10px",
          }}
        >
          Welcome to your Partner Profile!
        </h2>
        <p style={{ fontSize: "16px", color: "#637381", marginBottom: "20px" }}>
          Please complete your profile to maximize our partnership benefits.
        </p>
        <button
          onClick={handleClosePopup}
          className="btn-default"
          style={{
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          Got it!
        </button>
      </div>
    );
  };

  const timeOptions = Array.from({ length: 12 }, (_, i) => ({
    text: `${i + 1}:00`,
    value: `${i + 1}:00`,
  }));

  const periodOptions = [
    { text: "AM", value: "AM" },
    { text: "PM", value: "PM" },
  ];

  const calculateHours = (startTime, startPeriod, endTime, endPeriod) => {
    // Convert time strings to numbers (e.g., "9:00" -> 9)
    const startHour = parseInt(startTime.split(":")[0]);
    const endHour = parseInt(endTime.split(":")[0]);

    // Convert to 24-hour format
    let start24 = startHour;
    if (startPeriod === "PM" && startHour !== 12) start24 += 12;
    if (startPeriod === "AM" && startHour === 12) start24 = 0;

    let end24 = endHour;
    if (endPeriod === "PM" && endHour !== 12) end24 += 12;
    if (endPeriod === "AM" && endHour === 12) end24 = 0;

    // Calculate difference
    let diff = end24 - start24;

    // Handle cases where end time is on the next day
    if (diff < 0) {
      diff += 24;
    }

    return diff;
  };

  useEffect(() => {
    if (data) {
      setTempFormData({
        ...data,
        donations_needed: data.donations_needed || [],
        emergency_flags: data.emergency_flags || {},
      });
    }
  }, [data]);

  // Add this useEffect to monitor changes to tempFormData
  useEffect(() => {}, [tempFormData]);

  const handleBusinessStateChange = (e) => {
    const stateAbbreviation = e.target.value;
    const stateObject = USStates.find(
      (s) => s.abbreviation === stateAbbreviation
    );
    const stateName = stateObject ? stateObject.name : "";

    // Update both states at once
    setSelectedState(stateAbbreviation);
    setTempFormData((prev) => ({
      ...prev,
      state: stateAbbreviation,
      county: "", // Reset county when state changes
    }));

    // Filter counties based on selected state
    const filteredCounties = countiesData
      .filter((cd) => cd.State === stateName)
      .map((cd) => cd.County);
    setCounties(filteredCounties);

    // Update data state as well to prevent reversion on blur
    // setData((prev) => ({
    //   ...prev,
    //   state: stateAbbreviation,
    //   county: "",
    // }));
  };

  return (
    <div
      className={`box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] text-left text-xs leading-[normal] tracking-[normal] text-darkslateblue ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5 py-0 pl-0 pr-5">
        <a className="relative inline-block min-w-[77px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Partner Profile
        </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
        <header className="flex flex-row items-center justify-between self-stretch">
          <div>
            <h2 className="text-sm font-bold text-blue-500">
              PERSONAL INFORMATION
            </h2>
          </div>
          <div className="flex items-center gap-2">
            {isEditMode ? (
              <>
                <button
                  type="button"
                  className="btn-default flex cursor-pointer items-center gap-1 px-3 py-1.5"
                  onClick={saveChanges}
                >
                  <FaSave className="h-3 w-3" />
                  Save
                </button>
                <button
                  type="button"
                  className="btn-cancel flex cursor-pointer  gap-1 px-3 py-1.5"
                  onClick={cancelEditing}
                >
                  <FaTimes className="h-3 w-3" />
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={startEditing}
              >
                <FaEdit className="h-3 w-3" />
                Edit
              </button>
            )}
            <button
              type="button"
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200"
              onClick={togglePersonalInfo}
            >
              <span
                className={`transition-transform duration-300 ease-in-out ${
                  isPersonalInfoVisible ? "rotate-0" : "rotate-180"
                }`}
              >
                <FaChevronDown />
              </span>
            </button>
          </div>
        </header>
        {isPersonalInfoVisible && (
          <div
            className={`flex w-full max-w-full flex-col items-start justify-start gap-0.5 text-left text-xs leading-[normal] tracking-[normal] text-gray-500 ${className}`}
          >
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
                <label htmlFor="organizationName">Organization Name</label>
                <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <TextBoxComponent
                onKeyDown={handleKeyDown}
                readOnly={!isEditMode}
                id="organizationName"
                type="text"
                cssClass="e-outline"
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                value={tempFormData.name_of_org || ""}
                // blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    name_of_org: e.target.value,
                  })
                }
              />
              {onShowRequiredFields("name_of_org") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="flex w-full gap-5">
              <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
                  <label htmlFor="organizationName">
                    Contact Person First Name
                  </label>
                  <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <TextBoxComponent
                  onKeyDown={handleKeyDown}
                  readOnly={!isEditMode}
                  id="organizationName"
                  type="text"
                  cssClass="e-outline"
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  value={tempFormData.first_name || ""}
                  // blur={inputBlur}
                  onChange={(e) =>
                    setTempFormData({
                      ...tempFormData,
                      first_name: e.target.value,
                    })
                  }
                />
                {onShowRequiredFields("first_name") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
              <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                <div className="relative inline-block min-w-[107px] leading-[20px] text-gray1-200">
                  <label htmlFor="organizationName">
                    Contact Person Last Name
                  </label>
                  <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <TextBoxComponent
                  onKeyDown={handleKeyDown}
                  readOnly={!isEditMode}
                  id="organizationName"
                  type="text"
                  cssClass="e-outline"
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  value={tempFormData.last_name || ""}
                  // blur={inputBlur}
                  onChange={(e) =>
                    setTempFormData({
                      ...tempFormData,
                      last_name: e.target.value,
                    })
                  }
                />
                {onShowRequiredFields("last_name") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[81px] leading-[20px] text-gray1-200">
                <div className="relative inline-block leading-[20px] text-gray1-200">
                  <label htmlFor="officeNumber">Contact Number</label>
                  <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
              </div>
              <TextBoxComponent
                readOnly={!isEditMode}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                id="officeNumber"
                type="number"
                cssClass="e-outline"
                value={tempFormData.mobile_number || ""}
                // blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    mobile_number: e.target.value,
                  })
                }
                onKeyDown={(e) => {
                  if (
                    !/^[0-9+()-]$/.test(e.key) &&
                    ![
                      "Backspace",
                      "Tab",
                      "Enter",
                      "ArrowLeft",
                      "ArrowRight",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              {onShowRequiredFields("mobile_number") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="flex w-full gap-5">
              <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                <div className="relative inline-block text-gray1-200">
                  <label htmlFor="address1">Area Serviced (State)</label>
                  <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <DropDownListComponent
                  readonly={!isEditMode}
                  floatLabelType="Auto"
                  dataSource={USStates}
                  fields={{ text: "name", value: "abbreviation" }}
                  value={tempFormData.area_serviced_state || ""}
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  name="state"
                  change={(e) => {
                    handleStateChange({
                      target: { value: e.value },
                    });
                  }}
                  cssClass="e-outline"
                />
                {onShowRequiredFields("area_serviced_state") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
              <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                <div className="relative inline-block text-gray1-200">
                  <label htmlFor="address1">Area Serviced (County)</label>
                  <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <MultiSelectComponent
                  readonly={!isEditMode}
                  dataSource={countiesAreaServiced}
                  fields={{ text: "County", value: "County" }}
                  value={tempFormData.area_serviced_county || ""}
                  change={(e) => {
                    setTempFormData({
                      ...tempFormData,
                      area_serviced_county: e.value,
                    });
                  }}
                  mode="CheckBox"
                  selectAllText="Select All"
                  unSelectAllText="Unselect All"
                  showSelectAll={true}
                  cssClass={`e-outline ${
                    isEditMode ? "" : "disabled-multiselect"
                  }`}
                  style={{
                    fontWeight: isEditMode ? "normal" : "bold",
                    maxHeight: "150px", // Set a fixed height
                    overflowY: "auto", // Enable vertical scrolling
                  }}
                  showDropDownIcon={true}
                  enableSelectionOrder={false}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
                {onShowRequiredFields("area_serviced_county") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-full gap-5">
              <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                <div className="relative inline-block text-gray1-200">
                  <label htmlFor="address1">
                    Are you a state or a national nonprofit
                  </label>
                  <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <DropDownListComponent
                  readonly={!isEditMode}
                  floatLabelType="Auto"
                  dataSource={yesNoOptions}
                  fields={{ text: "text", value: "value" }}
                  value={tempFormData.state_or_national_nonprofit || ""}
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  cssClass="e-outline"
                  onChange={(e) => {
                    setTempFormData((prev) => ({
                      ...prev,
                      state_or_national_nonprofit: e.value,
                    }));
                    // inputBlur();
                  }}
                />
                {onShowRequiredFields("state_or_national_nonprofit") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
              <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                <div className="relative inline-block text-gray1-200">
                  <label htmlFor="address1">Are you a 501c3</label>
                  <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <DropDownListComponent
                  readonly={!isEditMode}
                  floatLabelType="Auto"
                  dataSource={yesNoOptions}
                  fields={{ text: "text", value: "value" }}
                  value={tempFormData.a501c3 || ""}
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  cssClass="e-outline"
                  onChange={(e) => {
                    setTempFormData((prev) => ({ ...prev, a501c3: e.value }));
                    // inputBlur();
                  }}
                />
                {onShowRequiredFields("a501c3") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
              {tempFormData.a501c3 === "Yes" && (
                <div className="flex flex-1 flex-col items-start justify-start gap-1.5 self-stretch">
                  <div className="relative inline-block text-gray1-200">
                    <label htmlFor="ein">EIN (Number Only)</label>
                    <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                      *
                    </p>
                  </div>
                  <TextBoxComponent
                    readOnly={!isEditMode}
                    floatLabelType="Auto"
                    id="ein"
                    type="number"
                    cssClass="e-outline"
                    style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                    value={tempFormData.ein || ""}
                    onChange={(e) => {
                      setTempFormData((prev) => ({
                        ...prev,
                        ein: e.target.value,
                      }));
                    }}
                  />
                  {onShowRequiredFields("ein") && (
                    <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                      Required Field
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block text-gray1-200">
                <label htmlFor="address1">Services Provided</label>
                <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <MultiSelectComponent
                readonly={!isEditMode}
                dataSource={services}
                fields={fields}
                value={tempFormData.servicesProvided || ""}
                change={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    servicesProvided: e.value,
                  })
                }
                mode="CheckBox"
                selectAllText="Select All"
                unSelectAllText="Unselect All"
                showSelectAll={true}
                cssClass={`e-outline ${
                  isEditMode ? "" : "disabled-multiselect"
                }`}
                style={{ fontWeight: isEditMode ? "normal" : "bold" }}
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
              {onShowRequiredFields("servicesProvided") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div>
              <div className="mt-2 flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch pl-1">
                <div className="relative inline-block text-gray1-200">
                  <label htmlFor="hour_of_service">Hours of Service</label>
                  <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center ">
                    <input
                      className="hover:cursor-pointer"
                      type="radio"
                      id="24x7"
                      name="hours_of_service"
                      value="24x7"
                      checked={tempFormData.hours_of_service === "24x7"}
                      disabled={!isEditMode}
                      onChange={(e) =>
                        setTempFormData({
                          ...tempFormData,
                          hours_of_service: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="24x7" className="ml-2 text-black">
                      24 hrs x 7 days
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="hover:cursor-pointer"
                      type="radio"
                      id="select_hours"
                      name="hours_of_service"
                      value="select_hours"
                      checked={tempFormData.hours_of_service === "select_hours"}
                      disabled={!isEditMode}
                      onChange={(e) => {
                        setTempFormData({
                          ...tempFormData,
                          hours_of_service: e.target.value,
                        });
                        if (savedWorkingHours) {
                          setWorkingHours(savedWorkingHours);
                        }
                      }}
                    />
                    <label htmlFor="select_hours" className="ml-2 text-black">
                      Select working days/hours
                    </label>
                  </div>

                  {tempFormData.hours_of_service === "select_hours" && (
                    <div className="ml-6 mt-3 flex flex-col gap-3">
                      {Object.entries(workingHours).map(([day, hours]) => (
                        <div key={day} className="flex items-center gap-4">
                          <div className="flex w-24 items-center gap-2">
                            <input
                              className="hover:cursor-pointer"
                              type="checkbox"
                              id={`${day}-enabled`}
                              checked={hours.enabled}
                              disabled={!isEditMode}
                              onChange={(e) => {
                                setWorkingHours((prev) => ({
                                  ...prev,
                                  [day]: {
                                    ...prev[day],
                                    enabled: e.target.checked,
                                  },
                                }));
                              }}
                            />
                            <label
                              htmlFor={`${day}-enabled`}
                              className="capitalize text-black"
                            >
                              {day}
                            </label>
                          </div>

                          {hours.enabled && (
                            <div className="flex items-center gap-2">
                              <DropDownListComponent
                                cssClass="e-outline"
                                dataSource={timeOptions}
                                fields={{ text: "text", value: "value" }}
                                value={hours.startTime}
                                width="100px"
                                readonly={!isEditMode}
                                change={(e) => {
                                  setWorkingHours((prev) => ({
                                    ...prev,
                                    [day]: { ...prev[day], startTime: e.value },
                                  }));
                                }}
                              />
                              <DropDownListComponent
                                cssClass="e-outline"
                                dataSource={periodOptions}
                                fields={{ text: "text", value: "value" }}
                                value={hours.startPeriod}
                                width="80px"
                                readonly={!isEditMode}
                                change={(e) => {
                                  setWorkingHours((prev) => ({
                                    ...prev,
                                    [day]: {
                                      ...prev[day],
                                      startPeriod: e.value,
                                    },
                                  }));
                                }}
                              />
                              <span className="text-black">to</span>
                              <DropDownListComponent
                                cssClass="e-outline"
                                dataSource={timeOptions}
                                fields={{ text: "text", value: "value" }}
                                value={hours.endTime}
                                width="100px"
                                readonly={!isEditMode}
                                change={(e) => {
                                  setWorkingHours((prev) => ({
                                    ...prev,
                                    [day]: { ...prev[day], endTime: e.value },
                                  }));
                                }}
                              />
                              <DropDownListComponent
                                cssClass="e-outline"
                                dataSource={periodOptions}
                                fields={{ text: "text", value: "value" }}
                                value={hours.endPeriod}
                                width="80px"
                                readonly={!isEditMode}
                                change={(e) => {
                                  setWorkingHours((prev) => ({
                                    ...prev,
                                    [day]: { ...prev[day], endPeriod: e.value },
                                  }));
                                }}
                              />
                              {hours.enabled && (
                                <div className="ml-2 text-sm text-gray1-200">
                                  (
                                  {calculateHours(
                                    hours.startTime,
                                    hours.startPeriod,
                                    hours.endTime,
                                    hours.endPeriod
                                  )}{" "}
                                  hrs)
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {onShowRequiredFields("hours_of_service") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="w-full">
              <div className="mt-2 flex max-w-full  items-start justify-start gap-1.5 self-stretch pl-1">
                <div className="relative w-auto min-w-[180px] text-gray1-200">
                  <label htmlFor="do_you_accept_donations">
                    Do you accept donations?
                  </label>
                  <div className="mt-2 flex items-center gap-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="accept_donations"
                        value="yes"
                        checked={tempFormData.accept_donations === "yes"}
                        disabled={!isEditMode}
                        onChange={(e) => {
                          setTempFormData({
                            ...tempFormData,
                            accept_donations: e.target.value,
                          });
                        }}
                      />
                      <span className="ml-2">Yes</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="accept_donations"
                        value="no"
                        checked={tempFormData.accept_donations === "no"}
                        disabled={!isEditMode}
                        onChange={(e) => {
                          setTempFormData({
                            ...tempFormData,
                            accept_donations: e.target.value,
                          });
                        }}
                      />
                      <span className="ml-2">No</span>
                    </label>
                  </div>
                </div>
                <div className="w-full">
                  {tempFormData.accept_donations === "yes" && (
                    <div className="w-full">
                      <label className="mb-2 block text-gray1-200">
                        What types of donations do you accept?
                      </label>
                      <MultiSelectComponent
                        id="donation_types"
                        dataSource={donationTypeOptions}
                        fields={{ text: "text", value: "value" }}
                        placeholder="Select donation types"
                        mode="CheckBox"
                        showSelectAll={true}
                        showDropDownIcon={true}
                        popupHeight="350px"
                        cssClass={`e-outline ${
                          isEditMode ? "" : "disabled-multiselect"
                        }`}
                        readonly={!isEditMode}
                        value={tempFormData.donation_types || []}
                        change={(e) => {
                          setTempFormData({
                            ...tempFormData,
                            donation_types: e.value,
                          });
                        }}
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>

                      {tempFormData.donation_types?.includes("other") && (
                        <div className="mt-2">
                          <TextBoxComponent
                            onKeyDown={handleKeyDown}
                            placeholder="Please specify other donation types"
                            cssClass="e-outline"
                            value={tempFormData.other_donation_types || ""}
                            readonly={!isEditMode}
                            style={{
                              backgroundColor: isEditMode ? "" : "#f3f4f6",
                            }}
                            onChange={(e) => {
                              setTempFormData({
                                ...tempFormData,
                                other_donation_types: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {onShowRequiredFields("accept_donations") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-6 self-stretch">
              {/* Donations Needed */}
              {/* <div className="w-full">
                <div className="relative mb-2 inline-block text-gray1-200">
                  <label>Donations Needed</label>
                </div>
                <MultiSelectComponent
                  id="donations_needed"
                  dataSource={
                    tempFormData.donation_types
                      ?.sort((a, b) =>
                        a === "other" ? 1 : b === "other" ? -1 : 0
                      )
                      .map((type) => ({
                        text:
                          type === "other"
                            ? tempFormData.other_donation_types || "Other"
                            : donationTypeOptions.find(
                                (option) => option.value === type
                              )?.text || type,
                        value: type,
                      })) || []
                  }
                  fields={{ text: "text", value: "value" }}
                  placeholder="Select donations needed"
                  mode="CheckBox"
                  showSelectAll={true}
                  showDropDownIcon={true}
                  popupHeight="350px"
                  cssClass={`e-outline ${
                    isEditMode ? "" : "disabled-multiselect"
                  }`}
                  readonly={!isEditMode}
                  value={tempFormData.donations_needed || []}
                  change={(e) => {
                    setTempFormData((prev) => ({
                      ...prev,
                      donations_needed: e.value,
                    }));
                  }}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div> */}

              {/* Available Items */}
              <div className="w-full">
                <div className="relative mb-2 inline-block text-gray1-200">
                  <label>Available Items</label>
                </div>
                <MultiSelectComponent
                  id="available_items"
                  dataSource={
                    tempFormData.donation_types
                      ?.sort((a, b) =>
                        a === "other" ? 1 : b === "other" ? -1 : 0
                      )
                      .map((type) => ({
                        text:
                          type === "other"
                            ? tempFormData.other_donation_types || "Other"
                            : donationTypeOptions.find(
                                (option) => option.value === type
                              )?.text || type,
                        value: type,
                      })) || []
                  }
                  fields={{ text: "text", value: "value" }}
                  mode="CheckBox"
                  showSelectAll={true}
                  showDropDownIcon={true}
                  popupHeight="350px"
                  cssClass={`e-outline ${
                    isEditMode ? "" : "disabled-multiselect"
                  }`}
                  readonly={!isEditMode}
                  value={tempFormData.available_items || []}
                  change={(e) => {
                    setTempFormData((prev) => ({
                      ...prev,
                      available_items: e.value,
                    }));
                  }}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div
                className="mb-1 py-2 text-xs font-bold text-black"
                style={{ color: "rgb(25, 56, 97)" }}
              >
                BUSINESS ADDRESS
              </div>
              <div className="relative inline-block  text-gray1-200">
                <label htmlFor="address1">Address 1</label>
                <p className="absolute right-[-15px] top-[-4px] text-[26px] text-red-500">
                  *
                </p>
              </div>
              <TextBoxComponent
                onKeyDown={handleKeyDownAddress}
                readOnly={!isEditMode}
                floatLabelType="Auto"
                id="address1"
                type="text"
                cssClass="e-outline"
                style={{
                  backgroundColor: isEditMode ? "" : "#f3f4f6",
                }}
                value={tempFormData.address1 || ""}
                // blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    address1: e.target.value,
                  })
                }
              />
              {onShowRequiredFields("address1") && (
                <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                  Required Field
                </div>
              )}
            </div>
            <div className="flex max-w-full flex-col items-start justify-start gap-1.5 self-stretch">
              <div className="relative inline-block min-w-[55px] leading-[20px] text-gray1-200">
                <label htmlFor="address2">Address 2</label>
              </div>
              <TextBoxComponent
                onKeyDown={handleKeyDownAddress}
                readOnly={!isEditMode}
                floatLabelType="Auto"
                id="address1"
                type="text"
                cssClass="e-outline"
                value={tempFormData.address2 || ""}
                style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                // blur={inputBlur}
                onChange={(e) =>
                  setTempFormData({
                    ...tempFormData,
                    address2: e.target.value,
                  })
                }
              />
            </div>
            <div className="row-span-4 flex max-w-full flex-row flex-wrap items-center justify-start gap-5 self-stretch">
              <div className="mq391:min-w-full flex max-w-full flex-1 flex-col items-start justify-start gap-1.5">
                <div className="relative inline-block min-w-[22px] leading-[20px] text-gray1-200">
                  <label htmlFor="city">City</label>
                  <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <TextBoxComponent
                  onKeyDown={handleKeyDownAddress}
                  readOnly={!isEditMode}
                  floatLabelType="Auto"
                  className="bg-gray-200"
                  cssClass="e-outline"
                  id="city"
                  type="text"
                  value={tempFormData.city || ""}
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  // blur={inputBlur}
                  onChange={(e) =>
                    setTempFormData({ ...tempFormData, city: e.target.value })
                  }
                />
                {onShowRequiredFields("city") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
              <div className="mq391:min-w-full flex max-w-full flex-1 flex-col items-start justify-start gap-1.5">
                <div className="relative inline-block min-w-[76px] leading-[20px] text-gray1-200">
                  <label htmlFor="state">Select State</label>
                  <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <DropDownListComponent
                  onKeyDown={handleKeyDownAddress}
                  readonly={!isEditMode}
                  floatLabelType="Auto"
                  dataSource={USStates}
                  fields={{ text: "name", value: "abbreviation" }}
                  value={tempFormData.state || ""}
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  name="state"
                  change={(e) => {
                    handleBusinessStateChange({
                      target: { value: e.value },
                    });
                  }}
                  cssClass="e-outline"
                />
                {onShowRequiredFields("state") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
              <div className="mq391:min-w-full flex max-w-full flex-1 flex-col items-start justify-start gap-1.5 text-gray1-200">
                <div className="relative inline-block min-w-[18px] leading-[20px]">
                  <label htmlFor="zip">Zip</label>
                  <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <TextBoxComponent
                  readOnly={!isEditMode}
                  floatLabelType="Auto"
                  className="bg-gray-200"
                  cssClass="e-outline"
                  id="country"
                  type="text"
                  value={tempFormData.zip || ""}
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  // blur={inputBlur}
                  onChange={(e) =>
                    setTempFormData({ ...tempFormData, zip: e.target.value })
                  }
                />
                {onShowRequiredFields("zip") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
              <div className="mq391:min-w-full flex max-w-full flex-1 flex-col items-start justify-start gap-1.5 text-gray1-200">
                <div className="relative inline-block min-w-[40px] leading-[20px]">
                  <label htmlFor="county">County</label>
                  <p className="absolute right-[-15px] top-[4px] text-[26px] text-red-500">
                    *
                  </p>
                </div>
                <DropDownListComponent
                  readonly={!isEditMode}
                  floatLabelType="Auto"
                  className="bg-gray-200"
                  cssClass="e-outline"
                  id="county"
                  type="text"
                  value={tempFormData.county || ""}
                  dataSource={counties}
                  fields={{ text: "County", value: "County" }}
                  style={{ backgroundColor: isEditMode ? "" : "#f3f4f6" }}
                  // blur={inputBlur}
                  onChange={(e) =>
                    setTempFormData({
                      ...tempFormData,
                      county: e.target.value,
                    })
                  }
                />
                {onShowRequiredFields("county") && (
                  <div className="relative w-[100px] font-inter leading-[12px] text-red-500">
                    Required Field
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4 pt-4">
        <div className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <div
            className={`mq280:flex-wrap mb-2 flex w-full max-w-full flex-row items-start justify-between gap-5 text-left text-xs leading-[normal] tracking-[normal] text-dodgerblue ${className}`}
          >
            <div className="flex w-full flex-col items-start justify-start">
              <h2 className="text-sm font-bold text-blue-500">
                Last 3 disasters assisted in (if applicable)
              </h2>
              <div className="relative self-stretch font-medium leading-[21px] text-silver-200">
                {/* {data.lastUpdatedDisasters &&
                    `Last Updated: ${tempDisastersFormData.lastUpdatedDisasters}`}
                  {console.log("ahah ", data.lastUpdatedDisasters)} */}
              </div>
            </div>
            <div className="mb-1 flex items-center gap-2">
              <button
                type="button"
                className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
                onClick={handleAddDisaster}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button>
              <button
                type="button"
                className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-all duration-300 ease-in-out hover:bg-gray-300"
                onClick={toggleDisaster}
              >
                <span
                  className={`transition-transform duration-300 ease-in-out ${
                    isDisasterVisible ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <FaChevronDown />
                </span>
              </button>
            </div>
          </div>
          {isDisasterVisible && (
            <GridComponent
              dataSource={partnerDisasters.filter(
                (disaster) => disaster.is_deleted !== true
              )}
              // editSettings={{
              //   allowEditing: true,
              //   allowAdding: true,
              //   allowDeleting: true,
              //   showDeleteConfirmDialog: true,
              //   mode: "Dialog",
              // }}
              ref={gridRefDisasters}
              actionBegin={actionBeginDisaster}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowSorting={true}
              sortSettings={sortSettings}
              allowPaging={true}
              pageSettings={pageSettings}
              showColumnMenu={true}
              columnMenuItems={columnMenuItems}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="disasterId"
                  isPrimaryKey={true}
                  isIdentity={true}
                  visible={false}
                />
                <ColumnDirective
                  field="year"
                  format="N0"
                  headerText="Year"
                  validationRules={{ required: true }}
                  width="auto"
                />
                <ColumnDirective
                  field="month"
                  headerText="Month"
                  validationRules={{ required: true }}
                  width="auto"
                />
                <ColumnDirective
                  field="location"
                  headerText="Location of Disaster"
                  validationRules={{ required: true }}
                  width="auto"
                />
                <ColumnDirective
                  field="reference"
                  headerText="Reference Contact Person"
                  validationRules={{ required: true }}
                  width="auto"
                />
                <ColumnDirective
                  field="phone"
                  headerText="Contact Person Phone"
                  validationRules={{ required: true }}
                  width="auto"
                />
                <ColumnDirective
                  field="email"
                  headerText="Contact Person Email"
                  validationRules={{ required: true }}
                  width="auto"
                />
                <ColumnDirective
                  field="services"
                  headerText="Services Rendered"
                  validationRules={{ required: true }}
                  width="auto"
                />
                <ColumnDirective
                  field="comment"
                  headerText="Comment"
                  validationRules={{ required: false }}
                  width="auto"
                />
                <ColumnDirective
                  headerText="Actions"
                  width="auto"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                />
              </ColumnsDirective>
              <Inject
                services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
              />
            </GridComponent>
          )}
        </div>
      </section>
      <div className="box-border flex max-w-full flex-col items-start justify-start gap-[10px] self-stretch border-solid border-gray-200 bg-white px-[19px] pb-5 pt-[18px] text-left text-xs text-darkslateblue">
        {/* <Employees
          data={{ org: partner, orgId: partner?.id, orgType: "partner" }}
        /> */}
        <DialogComponent
          width="400px"
          isModal={true}
          visible={showPopup}
          close={handleClosePopup}
          header={null}
          content={dialogContent}
          showCloseIcon={true}
          closeOnEscape={true}
          target={document.body}
          cssClass="welcome-dialog"
        />
      </div>
    </div>
  );
};

export default PartnerProfile;
