import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase"; // Ensure this path is correct
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import blueSkyLogo from "../../src/assets/bluesky-logo.svg";
import mailer from "../service/api/mailer";
import {
  collection,
  query,
  getDocs,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Popup from "../components/Popup";
import { encrypt } from "../service/encryption/Encryption";
import { config } from "../utils/config";

const ForgotPassword = () => {
  const [email, setEmail] = useState(""); // State to store the user's email
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [message, setMessage] = useState(""); // State to store success messages
  const [error, setError] = useState(""); // State to store error messages
  const navigate = useNavigate(); // Hook for navigation

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setError(""); // Reset error state
    setMessage(""); // Reset message state

    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setLoading(true); // Set loading state to true

    try {
      const user = await getUserByEmail(email.toLowerCase());
      if (!user) {
        <Popup
          title="Email does not exist"
          message="Please try again later."
          onClose={() => {}}
        />;
        return;
      }

      // Attempt to send the password reset email
      const otp = generateOtp();

      // sign in with email and password
      await signInWithEmailAndPassword(
        auth,
        config.bluesky_email_auth,
        config.bluesky_password
      );

      // update the user's password in the database
      const q = query(
        collection(db, "users"),
        where("email", "==", email.toLowerCase())
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userRef = doc(db, "users", userDoc.id);
        let password = encrypt(otp);
        await updateDoc(userRef, {
          password: password,
        });
        console.log("Password updated successfully");
      }

      // send the otp to the user's email
      await mailer.sendForgotPasswordEmail(email, otp, user.first_name);

      // Sign out after sending email
      await signOut(auth);

      setMessage("Password reset email sent! Please check your inbox.");

      // Optionally, redirect to the login page after a delay
      setTimeout(() => {
        navigate("/login");
      }, 5000); // Redirects after 5 seconds
    } catch (err) {
      // Handle specific Firebase authentication errors
      switch (err.code) {
        case "auth/user-not-found":
          setError("No user found with this email.");
          break;
        case "auth/invalid-email":
          setError("Invalid email address.");
          break;
        default:
          setError("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const generateOtp = () => {
    const randomChars = () => Math.random().toString(36).substring(2, 15);
    const getUpperCase = () =>
      String.fromCharCode(Math.floor(Math.random() * 26) + 65);
    const getLowerCase = () =>
      String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    const getNumber = () => Math.floor(Math.random() * 10).toString();

    let password = randomChars() + randomChars();
    password = password.substring(0, 6); // Shorten to 6 chars

    // Add required character types
    password += getUpperCase();
    password += getLowerCase();
    password += getNumber();
    password += getNumber();

    // Shuffle all characters
    password = password
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    return password;
  };

  const getUserByEmail = async (email) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return null;
      }

      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });

      return userData.length > 0 ? userData[0] : null;
    } catch (error) {
      console.error("Error getting user by email:", error);
      return null;
    }
  };

  return (
    <div className="viewport h-screen w-full overflow-hidden ">
      <div className="viewport-bg flex h-full w-full items-center justify-center bg-[rgb(225,190,116)]/[0.13]">
        <div className="flex  rounded-l-lg shadow-[0px_4px_9px_rgba(0,0,0,0.25)] ">
          <div className="  w-[350px] rounded-l-lg bg-white  ">
            <h5 className="mt-12 flex flex-col items-center justify-center">
              <img
                loading="lazy"
                src={blueSkyLogo}
                className="aspect-[0.99] w-[90px] object-contain"
                alt="DARCI logo"
              />
              <label className="login-logo-label mt-2.5">DARCI</label>
            </h5>

            <div className="z-10 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center">
              <div className="text-gray-700 flex flex-col items-center text-center font-bold tracking-wide">
                <div className="text-xl leading-none ">
                  <span className="text-sm text-blue-500">
                    Disaster Aware & Ready Communities{" "}
                  </span>
                  {/* <span className="text-base text-blue-500">
                    Disaster Aware and Ready Communities
                  </span> */}
                </div>
              </div>
            </div>
            <form
              className="z-0 mt-2.5 flex w-[483px] max-w-full flex-col items-center justify-center px-5"
              onSubmit={handleSubmit} // Attach the submit handler to the form
            >
              <div className="flex w-full flex-col gap-[5px] pt-1">
                <p className="my-[10px] text-center  text-[20px] font-bold text-[#3D435A]">
                  Forgot Password
                </p>
                <p className="my-1 text-start text-base font-medium leading-[28px] text-[#3D435A] ">
                  Enter your email and we'll send you a link to reset your
                  password
                </p>
                <input
                  placeholder="Email Address"
                  type="email" // Changed type to "email" for better validation
                  className="h-[40px] rounded border border-[#D5D7DB] p-[10px] focus:outline-none" // Added "rounded" for better aesthetics
                  value={email} // Bind input value to state
                  onChange={(e) => setEmail(e.target.value)} // Update state on change
                  required // Make the field required
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e); // Allow form submission on Enter key
                    }
                  }}
                />
                {/* Display success or error message */}
                {message && (
                  <p className="mt-4 text-sm  text-green-600">{message}</p>
                )}
                {error && <p className="mt-4 text-sm  text-red-600">{error}</p>}
                <div className="mt-4 flex flex-col gap-[10px]">
                  <button
                    type="submit" // Ensure the button submits the form
                    className={`bg-login-btn h-10 w-full rounded-[4px] py-[10px] font-[Inter] hover:cursor-pointer ${
                      loading ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={loading} // Disable button when loading
                  >
                    {loading ? "Sending..." : "Submit"}
                    {/* Change button text based on loading state */}
                  </button>
                  <Link
                    to="/login"
                    className="text-center  text-base font-normal tracking-[0.24px] text-[#348BFF] no-underline hover:cursor-pointer"
                    style={{ fontSize: "12px", fontWeight: 700 }}
                  >
                    Back to login
                  </Link>
                </div>
                <div
                  className="flex w-full justify-center gap-0.5 pb-5  text-center"
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    marginTop: "40px",
                  }}
                >
                  <a
                    className="text-[inherit] text-blue-500 [text-decoration:none]"
                    href="/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    className="text-[inherit] text-blue-500 [text-decoration:none]"
                    href="/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div className="bg-login-right-panel block w-[350px] rounded-r-lg p-4 leading-4 mq700:hidden  ">
            <h3 className="pt-10 leading-relaxed text-white">
              Welcome to the DARCI Program, a disaster aware and community ready
              application.
              <br />
              <br />
              We are here to support your efforts in strengthening community
              preparedness and recovery.
              <br />
              <br />
              Access comprehensive tools, resources, and insights to enhance
              your leadership, response, and recovery capabilities.
              <br />
              <br />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
