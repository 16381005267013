import React, { useState, useRef, useEffect } from "react";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Page,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Search,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { db, auth } from "../../firebase";
import {
  getDoc,
  doc,
  setDoc,
  updateDoc,
  getFirestore,
  getDocs,
  collection,
  where,
  query,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import dataService from "../../service/data/DataService";
import { useNavigate } from "react-router-dom";

const Partnerships = ({ className = "" }) => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [approvedData, setApprovedData] = useState([]);
  const [forCertifyGrid, setForCertifyGrid] = useState([]);
  const [certifiedrid, setCertifiedGrid] = useState([]);
  const [centers, setCenters] = useState([]);
  const [centerId, setCenterId] = useState(null);
  const [activeTab, setActiveTab] = useState("Certified");
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();

  const tabs = ["Certified", "For Certification"];
  const selectedUserId = sessionStorage.getItem("userId");

  const sortSettings = {
    allowSorting: true,
  };

  const filterSettings = {
    type: "CheckBox",
  };
  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const handleTabClick = (tab) => setActiveTab(tab);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (selectedUserId) {
        setUserId(selectedUserId);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setCenterId(userDoc.data().center_id);

        // const forApprovalPartners = await dataService.getPartnerForApproval(
        //   userDoc.data().center_id
        // );
        // console.log("For approval", forApprovalPartners);
        // setGridData(forApprovalPartners);

        // const approvedPartners = await dataService.getApprovedPartners(
        //   userDoc.data().center_id
        // );
        // console.log("approved partners", approvedPartners);
        // setApprovedData(approvedPartners);

        const forCertifyPartners = await dataService.getPartnerForCertify(
          userDoc.data().center_id
        );
        console.log("For certify", forCertifyPartners);
        setForCertifyGrid(forCertifyPartners);

        const certifiedPartners = await dataService.getCertifiedPartners(
          userDoc.data().center_id
        );
        console.log("certified partners", certifiedPartners);
        setCertifiedGrid(certifiedPartners);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = (partnerId, field, currentStatus, Id) => {
    // if (!partnerId) {
    //   console.error("Invalid data passed to modal:", {
    //     partnerId,
    //     field,
    //     currentStatus,
    //     Id,
    //   });

    //   return;
    // }
    if (!partnerId) {
      partnerId = Id;
      setModalData({ partnerId, field, currentStatus });
    } else {
      setModalData({ partnerId, field, currentStatus });
    }
  };

  const closeModal = () => setModalData(null);

  const handleAction = async () => {
    if (!modalData || !centerId || !modalData.partnerId) {
      console.error("Invalid modal data:", modalData);
      return;
    }

    const { partnerId, field, currentStatus } = modalData;
    const newStatus = !currentStatus;

    try {
      // **Fetch all documents using partnerId**
      const usersQuery = query(
        collection(db, "users"),
        where("partner_id", "==", partnerId)
      );
      const partnersForApprovalQuery = query(
        collection(db, "partner-for-approval"),
        where("partner_id", "==", partnerId)
      );
      const partnerRef = doc(db, "partners", partnerId); // "partners" collection uses ID directly

      const [usersSnapshot, partnersForApprovalSnapshot] = await Promise.all([
        getDocs(usersQuery),
        getDocs(partnersForApprovalQuery),
      ]);

      if (usersSnapshot.empty && partnersForApprovalSnapshot.empty) {
        console.error("No documents found with partner_id:", partnerId);
        return;
      }

      // **Update "users" collection**
      for (const userDoc of usersSnapshot.docs) {
        const userRef = doc(db, "users", userDoc.id);
        await updateDoc(userRef, { [field]: newStatus });

        if (field === "is_certified") {
          if (newStatus) {
            // **Approving: Add center_id if not already present**
            if (!userDoc.data().center_id.includes(centerId)) {
              await updateDoc(userRef, { center_id: arrayUnion(centerId) });
            }
          } else {
            // **Unapproving: Remove center_id**
            if (userDoc.data().center_id.includes(centerId)) {
              await updateDoc(userRef, { center_id: arrayRemove(centerId) });
            }
          }
        }
      }

      // **Update "partners-for-approval" collection**
      for (const docSnap of partnersForApprovalSnapshot.docs) {
        await updateDoc(docSnap.ref, { [field]: newStatus });
      }

      // **Update "partners" collection using partnerId**
      await updateDoc(partnerRef, { [field]: newStatus });

      // **Update local state**
      setGridData((prev) =>
        prev.map((item) =>
          item.partner_id === partnerId ? { ...item, [field]: newStatus } : item
        )
      );
      setApprovedData((prev) =>
        prev.map((item) =>
          item.partner_id === partnerId ? { ...item, [field]: newStatus } : item
        )
      );

      closeModal();
      fetchData();
    } catch (error) {
      console.error(`Error updating ${field} status:`, error);
    }
  };

  const getModalTitle = (field, currentStatus) => {
    if (field === "is_approved") {
      return currentStatus ? "Unapprove User" : "Approve User";
    } else if (field === "is_certified") {
      return currentStatus ? "Uncertify Partner" : "Certify Partner";
    }
    return "Update User Status";
  };

  const getModalMessage = (field, currentStatus) => {
    if (field === "is_approved") {
      return `Are you sure you want to ${
        currentStatus ? "unapprove" : "approve"
      } this user? This action will update their status accordingly.`;
    } else if (field === "is_certified") {
      return `Are you sure you want to ${
        currentStatus ? "uncertify" : "certify"
      } this user? This action will update their status accordingly.`;
    }
    return "Are you sure you want to update this user's status?";
  };

  const statusTemplate = (rowData) => (
    <div className="flex justify-between">
      {/* <button
        onClick={() =>
          openModal(
            rowData.partner_id,
            "is_approved",
            rowData.is_approved,
            rowData.id
          )
        }
        className={`rounded px-2 py-1 ${
          rowData.is_approved
            ? "bg-green-500 text-white"
            : "bg-red-500 text-white"
        }`}
      >
        {rowData.is_approved ? "Approved" : "Approve"}
      </button> */}
      <button
        onClick={() =>
          openModal(
            rowData.partner_id,
            "is_certified",
            rowData.is_certified,
            rowData.id
          )
        }
        className={`rounded px-2 py-1 ${
          rowData.is_certified
            ? "bg-green-500 text-white"
            : "bg-red-500 text-white"
        }`}
      >
        {rowData.is_certified ? "Certified" : "Certify"}
      </button>
    </div>
  );

  const handleViewDocuments = (rowData) => {
    console.log("rowData", rowData);
    navigate(`/partnershipdocuments`, {
      state: { partnerUserId: rowData.id },
    });
  };

  const handleViewDocumentsForApproval = (rowData) => {
    console.log("rowData", rowData);
    navigate(`/partnershipdocuments`, {
      state: { partnerUserId: rowData.partner_id },
    });
  };

  return (
    <div
      className={`m-0 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 px-2.5 py-[15px] leading-[normal] tracking-[normal] ${className}`}
    >
      <div id="dialog-container"></div>
      <div className="flex flex-row items-start justify-start gap-1.5  text-xs text-darkslateblue">
        <a className="relative inline-block min-w-[40px] text-left  text-xs font-medium leading-[16px] text-dodgerblue [text-decoration:none]">
          Partnerships
        </a>
      </div>
      <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white pb-4">
        <header className="mb-2 flex flex-row items-center justify-end self-stretch">
          <div>
            {/* <h2 className="mb-1 text-sm font-bold text-blue-500">
              NON PROFIT PARTNERSHIPS
            </h2>
            <div className="mt-2 flex max-w-full flex-col items-start justify-start self-stretch bg-white">
              <div className="relative mb-2 inline-block max-w-full font-[Inter] font-medium leading-[21px] text-darkslategray-200">
                <p>
                  The Nonprofit Partnerships section allows you to review,
                  approve, or decline partnership requests from organizations
                  seeking to collaborate with your center. You can choose to
                  accept these partners into the Local Nonprofit Coalition for
                  year-round collaboration or designate them as Disaster
                  Recovery Partners, providing targeted support during disaster
                  events.
                </p>
              </div>
            </div> */}

            {/* Navigation Button Toggle */}
            <div className="flex flex-wrap justify-end gap-2 text-xs">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  onClick={() => handleTabClick(tab)}
                  className={`rounded-full px-4 py-1 ${
                    activeTab === tab
                      ? "btn-default"
                      : "btn-default-border hover:bg-blue-100"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>
        </header>
        {activeTab === "Certified" && (
          <div>
            <h2 className="mb-1 text-sm font-bold text-blue-500">
              CERTIFIED PARTNERS
            </h2>

            <div className="mb-8 flex flex-col gap-4 self-stretch">
              <GridComponent
                dataSource={certifiedrid}
                allowSorting={true}
                sortSettings={sortSettings}
                filterSettings={filterSettings}
                allowFiltering={true}
                toolbar={["Search"]}
                allowPaging={true}
                pageSettings={pageSettings}
                showColumnMenu={true}
                columnMenuItems={columnMenuItems}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="name_of_org"
                    headerText="Name of Organization"
                    width="100"
                  />
                  <ColumnDirective
                    field="address"
                    headerText="Address"
                    width="100"
                  />
                  <ColumnDirective
                    field="mobile_number"
                    headerText="Mobile Number"
                    width="100"
                  />
                  <ColumnDirective
                    field="email"
                    headerText="Email Address"
                    width="100"
                  />
                  <ColumnDirective
                    headerText="Documents"
                    width="100"
                    template={(rowData) => (
                      <button
                        onClick={() => handleViewDocuments(rowData)}
                        className="rounded bg-blue-500 px-3 py-1 text-white hover:bg-blue-700"
                      >
                        View Documents
                      </button>
                    )}
                  />
                  <ColumnDirective
                    headerText="Status"
                    width="80"
                    template={statusTemplate}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    Sort,
                    Filter,
                    Search,
                    ColumnMenu,
                  ]}
                />
              </GridComponent>
            </div>
          </div>
        )}

        {activeTab === "For Certification" && (
          <div>
            <h2 className="mb-1 text-sm font-bold text-blue-500">
              PARTNERSHIPS FOR CERTIFICATION
            </h2>
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                dataSource={forCertifyGrid}
                allowSorting={true}
                sortSettings={sortSettings}
                filterSettings={filterSettings}
                allowFiltering={true}
                toolbar={["Search"]}
                allowPaging={true}
                pageSettings={pageSettings}
                showColumnMenu={true}
                columnMenuItems={columnMenuItems}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="name_of_org"
                    headerText="Name of Organization"
                    width="100"
                  />
                  <ColumnDirective
                    field="address"
                    headerText="Address"
                    width="100"
                  />
                  <ColumnDirective
                    field="mobile_number"
                    headerText="Mobile Number"
                    width="100"
                  />
                  <ColumnDirective
                    field="email"
                    headerText="Email Address"
                    width="100"
                  />
                  <ColumnDirective
                    headerText="Documents"
                    width="100"
                    template={(rowData) => (
                      <button
                        onClick={() => handleViewDocumentsForApproval(rowData)}
                        className="rounded bg-blue-500 px-3 py-1 text-white hover:bg-blue-700"
                      >
                        View Documents
                      </button>
                    )}
                  />
                  <ColumnDirective
                    headerText="Status"
                    width="80"
                    template={statusTemplate}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    Sort,
                    Filter,
                    Search,
                    ColumnMenu,
                  ]}
                />
              </GridComponent>
            </div>
          </div>
        )}
      </section>

      {modalData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[440px] rounded-lg bg-white shadow-lg">
            <div className="flex items-center justify-between rounded-t-lg bg-[#467ba4] p-4">
              <p className="text-[24px] font-semibold text-[#fdb841]">
                {getModalTitle(modalData.field, modalData.currentStatus)}
              </p>
              <button
                className="cursor-pointer bg-[#467ba4] text-[20px] font-semibold text-[#fdb841]"
                onClick={closeModal}
              >
                ×
              </button>
            </div>
            <div className="space-y-2 p-4 text-sm">
              {getModalMessage(modalData.field, modalData.currentStatus)}
            </div>
            <div className="my-4 flex justify-end gap-2 pr-4">
              <button
                onClick={handleAction}
                className="btn-default justify-center rounded bg-red-500 px-4 py-2 text-white hover:cursor-pointer"
              >
                Yes
              </button>
              <button
                onClick={closeModal}
                className="btn-cancel justify-center rounded px-4  py-2 text-white hover:cursor-pointer"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Partnerships;
