import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import DisasterMgtService from "../../../service/citizen/DisasterMgtService";
import DisasterImpactGraph from "../.././DashboardGraphs/DisasterImpactGraph";
import InjuryGraph from "../.././DashboardGraphs/InjuryGraph";
import HomeDamageGraph from "../.././DashboardGraphs/HomeDamageGraph";
import HomeDestroyedGraph from "../.././DashboardGraphs/HomeDestroyedGraph";
import HomeDamageTypedGraph from "../.././DashboardGraphs/HomeDamageTypedGraph";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const DisasterDashboard = ({ propId }) => {
  const [disasterData, setDisasterData] = useState(null);
  const disasterId = Array.isArray(propId) ? propId[0] : propId;
  const [affectedCounties, setAffectedCounties] = useState([]);
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [data, setData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalValueSum, setTotalValue] = useState(0);
  const [immediateAssistance, setImmediateAssistance] = useState(0);
  const [recoveryAssistance, setRecoveryAssistance] = useState(0);
  const [disasterNames, setDisasterNames] = useState([]);
  const [userId, setUserId] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    show_disaster_impact_graph: false,
    show_injury_graph: false,
    show_home_damage_graph: false,
    show_homes_destroyed_graph: false,
    show_home_damage_typed_graph: false,
  });
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [disasterImpactData, setDisasterImpactData] = useState({
    affected: 0,
    notAffected: 0,
  });
  const [injuryData, setInjuryData] = useState({
    noInjury: 0,
    minorInjury: 0,
    medicalTreatment: 0,
    visitedHospital: 0,
    admitted: 0,
  });
  const [homeDamageData, setHomeDamageData] = useState({
    noDamage: 0,
    slightDamage: 0,
    limitedDamage: 0,
    severeDamage: 0,
    totalDestruction: 0,
  });
  const [homesDestroyedData, setHomesDestroyedData] = useState({
    damaged: 0,
    destroyed: 0,
    undamaged: 0,
  });
  const [typeOfHomeDamageData, setTypeOfHomeDamageData] = useState({
    "Check All": 0,
    Siding: 0,
    Flooring: 0,
    Cabinets: 0,
    "Shed/garage": 0,
    Roof: 0,
    Foundation: 0,
    "Floor Joists": 0,
    HVAC: 0,
    Driveway: 0,
    Gutters: 0,
    Ceiling: 0,
    Drywall: 0,
    Electrical: 0,
    "Yard/Uprooted trees": 0,
    Other: 0,
  });
  const [loadingStates, setLoadingStates] = useState({
    disasterData: true,
    surveyData: true,
  });
  const [error, setError] = useState(null);
  const formattedImmediateAssistance = formatter.format(immediateAssistance);
  const formattedRecoveryAssistance = formatter.format(recoveryAssistance);
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user && selectedUserId) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          const role = userDoc.data().user_role;
          setUserRole(role);
        }
      }
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchDisaster = async () => {
      if (!disasterId) {
        console.error("No valid ID provided.");
        return;
      }

      try {
        const docRef = doc(db, "declared-disaster", disasterId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setDisasterData(data);

          // Format the affected counties
          const formattedCounties = Array.isArray(data.affected_counties)
            ? data.affected_counties.map((county) => ({
                text: county,
                value: county,
              }))
            : [];

          setAffectedCounties(formattedCounties);

          // Set selectedCounties based on fetched data
          setSelectedCounties(formattedCounties.map((county) => county.value));
        } else {
          setAffectedCounties([]);
          setSelectedCounties([]);
          console.warn("No such disaster document found!");
        }
      } catch (error) {
        console.error("Error fetching disaster data:", error);
        setAffectedCounties([]);
        setSelectedCounties([]);
      }
    };

    fetchDisaster();
  }, [disasterId]);

  useEffect(() => {
    if (selectedCounties.length > 0) {
      loadData(selectedCounties);
      loadSurveyData(selectedCounties);
    }
  }, [selectedCounties]);

  const loadData = async (counties) => {
    try {
      setLoadingStates((prev) => ({ ...prev, disasterData: true }));
      const {
        allRecords,
        totalRecords,
        totalValueSum,
        immediateAssistanceSum,
        recoveryAssistanceSum,
      } = await DisasterMgtService.fetchDisasterData(disasterId, counties);

      setData(allRecords);
      // setTotalRecords(totalRecords);
      setTotalValue(totalValueSum);
      setImmediateAssistance(immediateAssistanceSum);
      setRecoveryAssistance(recoveryAssistanceSum);
    } catch (error) {
      console.error("Error loading disaster data:", error);
      setError("Failed to load disaster data");
    } finally {
      setLoadingStates((prev) => ({ ...prev, disasterData: false }));
    }
  };

  const loadSurveyData = async (counties) => {
    try {
      const {
        disasterImpactCount,
        injuryCount,
        homeDamageCount,
        homesDestroyedCount,
        typeOfHomeDamageCount,
        totalRecords,
      } = await DisasterMgtService.fetchDisasterSurvey(disasterId, counties);

      setDisasterImpactData(disasterImpactCount);
      setInjuryData(injuryCount);
      setHomeDamageData(homeDamageCount);
      setHomesDestroyedData(homesDestroyedCount);
      setTypeOfHomeDamageData(typeOfHomeDamageCount);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.error("Error loading survey data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserCheckboxStates = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user && selectedUserId) {
        setUserId(selectedUserId);
        const userDocRef = doc(db, "users", selectedUserId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCheckboxStates({
            ...checkboxStates,
            ...userData,
          });
        }
      }
    };

    fetchUserCheckboxStates();
  }, []);

  useEffect(() => {
    const fetchDisasterNames = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "declared-disaster")
        );
        const names = querySnapshot.docs.map((doc) => doc.data().disaster_name);
        setDisasterNames(names);
      } catch (error) {
        console.error("Error fetching disaster names:", error);
      }
    };

    fetchDisasterNames();
  }, []);

  const handleCountyChange = (event) => {
    try {
      if (event && event.value !== undefined) {
        const selectedValues = Array.isArray(event.value) ? event.value : [];
        setSelectedCounties(selectedValues);

        console.log("Selected counties:", selectedValues);

        // Rerun data fetching functions based on the new selected counties
        loadData(selectedValues);
        loadSurveyData(selectedValues);
      } else {
        setSelectedCounties([]);
        console.log("No counties selected.");
      }
    } catch (error) {
      console.error("Error in handleCountyChange:", error);
      setSelectedCounties([]);
    }
  };

  const handleCheckboxChange = async (field, checked) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [field]: checked,
    }));

    if (userId) {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        [field]: checked,
      });
    }
  };

  if (error) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="text-center text-red-600">
          <p className="text-lg">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (loading) {
    return <p className="mt-10">Loading disaster dashboard...</p>;
  }

  if (!disasterData) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="text-center">
          <p className="text-lg text-gray-600 font-medium">
            No declared disasters found
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      {/* Report Generator Start */}
      <div className="w-full flex-1 bg-white pr-4">
        <section
          className={`text-13xl flex w-full max-w-full flex-col items-start justify-start gap-5 text-left`}
        >
          <div className="flex w-full items-center justify-end gap-2.5">
            {affectedCounties.length > 0 ? (
              <div className="flex items-center gap-2">
                <p className="w-fit text-[15px] font-semibold text-blue-500">
                  Select County:{" "}
                </p>
                <MultiSelectComponent
                  id="countySelect"
                  dataSource={affectedCounties}
                  fields={{ text: "text", value: "value" }}
                  value={selectedCounties}
                  change={(e) => {
                    handleCountyChange(e);
                  }}
                  selectAllText="Select All"
                  unSelectAllText="Unselect All"
                  showSelectAll={true}
                  cssClass="e-outline"
                  width={300}
                  enabled={true}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            ) : (
              <p className="relative text-[20px] font-extrabold leading-normal text-blue-500">
                No counties available
              </p>
            )}
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-5">
            <div className="box-border flex w-full flex-row flex-wrap items-start justify-start gap-5">
              {/* Card: People Affected */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    Households Affected
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {totalRecords}
                  </div>
                </div>
              </div>

              {/* Card: Immediate Response Assistance Received */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    Immediate Response Assistance Received
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {formattedImmediateAssistance}
                  </div>
                </div>
              </div>

              {/* Card: Recovery Assistance Received */}
              <div className="box-border flex flex-1 flex-row items-start justify-between gap-4 rounded-md border border-gray-200 bg-white p-5 shadow-lg">
                <div className="flex flex-col items-start justify-start gap-2">
                  <p className="text-[15px] font-bold text-blue-500">
                    Recovery Assistance Received
                  </p>
                  <div className="text-[20px] font-semibold text-darkslategray-300">
                    {formattedRecoveryAssistance}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Dropdown Checkbox */}
        <div className="mt-4 flex w-full items-center justify-end">
          <div className="relative">
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="btn-default flex cursor-pointer gap-1 px-3 py-1.5"
            >
              {sidebarOpen ? "Close Settings" : "Open Settings"}
            </button>

            {/* Dropdown Content */}
            {sidebarOpen && (
              <div
                className="absolute right-0 mt-2 w-72 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg"
                style={{ zIndex: 1 }}
              >
                <div className="">
                  <div className=" flex flex-col gap-4">
                    <CheckBoxComponent
                      label="Show Disaster Impact Graph"
                      checked={checkboxStates.show_disaster_impact_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_disaster_impact_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Injury Graph"
                      checked={checkboxStates.show_injury_graph}
                      change={(e) =>
                        handleCheckboxChange("show_injury_graph", e.checked)
                      }
                    />
                    <CheckBoxComponent
                      label="Show Home Damage Graph"
                      checked={checkboxStates.show_home_damage_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_home_damage_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Homes Destroyed Graph"
                      checked={checkboxStates.show_homes_destroyed_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_homes_destroyed_graph",
                          e.checked
                        )
                      }
                    />
                    <CheckBoxComponent
                      label="Show Type of Home Damage Graph"
                      checked={checkboxStates.show_home_damage_typed_graph}
                      change={(e) =>
                        handleCheckboxChange(
                          "show_home_damage_typed_graph",
                          e.checked
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Graphs */}
        <section className="mx-auto mt-4 w-full">
          <div className="flex flex-wrap justify-between gap-5">
            {checkboxStates.show_disaster_impact_graph && (
              <div className="min-w-[400px] max-w-full flex-1">
                <DisasterImpactGraph disasterImpactData={disasterImpactData} />
              </div>
            )}
            {checkboxStates.show_injury_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <InjuryGraph injuryData={injuryData} />
              </div>
            )}
            {checkboxStates.show_home_damage_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDamageGraph homeDamageData={homeDamageData} />
              </div>
            )}
            {checkboxStates.show_homes_destroyed_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDestroyedGraph homesDestroyedData={homesDestroyedData} />
              </div>
            )}
            {checkboxStates.show_home_damage_typed_graph && (
              <div className="min-w-[350px] max-w-full flex-1">
                <HomeDamageTypedGraph
                  typeOfHomeDamageData={typeOfHomeDamageData}
                />
              </div>
            )}
          </div>
        </section>
      </div>
      {/* Report Generator End */}
    </div>
  );
};

export default DisasterDashboard;
