import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./components/AuthContext";
import LeftNavPane from "./components/LeftNavPane";
import Navbar from "./components/Navbar";
import { db } from "./firebase";
import AccessAccount from "./pages/AccessAccount";
import Center from "./pages/admin-pov/Center";
import Citizen from "./pages/admin-pov/Citizen";
import DRPartner from "./pages/admin-pov/DRPartner";
import NPCPartner from "./pages/admin-pov/NPCPartner";
import Calendar from "./pages/Calendar";
import CaseManagementCenter from "./pages/center-pov/CaseManagement/CaseManagementCenter.js";
import CenterDisasterRecovery from "./pages/center-pov/CenterDisasterRecovery";
import CenterLocalNonProfit from "./pages/center-pov/CenterLocalNonProfit";
import CenterLockBox from "./pages/center-pov/CenterLockbox.js";
import CitizenSearch from "./pages/center-pov/CitizenSearch";
import CommunityProfile from "./pages/center-pov/CommunityProfile";
import Contacts from "./pages/center-pov/Contacts";
import Demographics from "./pages/center-pov/Demographics";
import DisasterAffectedCounty from "./pages/center-pov/DisasterDeclaration/DisasterAffectedCounty.js";
import DisasterDashboard from "./pages/center-pov/DisasterDeclaration/DisasterDashboard.js";
import DisasterDeclaration from "./pages/center-pov/DisasterDeclaration/DisasterDeclaration.js";
import DisasterDetail from "./pages/center-pov/DisasterDeclaration/DisasterDetail.js";
import DisasterManagementList from "./pages/center-pov/DisasterDeclaration/DisasterManagementList.js";
import DisasterRecoverySearch from "./pages/center-pov/DisasterRecoverySearch";
import DonorPartners from "./pages/center-pov/DonorPartners";
import EmergencyMgtDocuments from "./pages/center-pov/EmergencyMgtDocuments";
import LocalNonProfitSearch from "./pages/center-pov/LocalNonProfitSearch";
import Members from "./pages/center-pov/Members";
import PartnershipDocuments from "./pages/center-pov/PartnershipDocuments.js";
import Partnerships from "./pages/center-pov/Partnerships";
import ReportGenerator from "./pages/center-pov/ReportGenerator";
import Settings from "./pages/center-pov/Settings";
import CertificationVideos from "./pages/CertificationVideos";
import CitizenHome from "./pages/CitizenHome.js";
import CitizenProfile from "./pages/CitizenProfile";
import Communication from "./pages/Communication";
import CommunityContacts from "./pages/CommunityContacts";
import Disasters from "./pages/Disasters";
import AssistanceLog from "./pages/Disasters/AssistanceLog";
import ContactsDisaster from "./pages/Disasters/ContactsDisaster";
import { DisasterProvider } from "./pages/Disasters/DisasterContext";
import DisasterManagement from "./pages/Disasters/DisasterManagement";
import ImmediateResponseSurvey from "./pages/Disasters/ImmediateResponseSurvey";
import Journals from "./pages/Disasters/Journals";
import RecoveryPlanDisasters from "./pages/Disasters/RecoveryPlan";
import DrcCenter from "./pages/DrcCenter";
import Employees from "./pages/Employees";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import JobOpportunities from "./pages/JobOpportunities";
import Library from "./pages/Library";
import Preparedness from "./pages/Library/Preparedness";
import QuickInfo from "./pages/Library/QuickInfo";
import Recovery from "./pages/Library/Recovery";
import Response from "./pages/Library/Response";
import LocalNonProfitCoalition from "./pages/LocalNonProfit";
import Lockbox from "./pages/Lockbox";
import Login from "./pages/Login";
import Map from "./pages/Map";
import MyHistory from "./pages/MyHistory";
import MyRecoveryPlan from "./pages/MyRecoveryPlan";
import Opportunities from "./pages/Opportunities";
import CaseManagementPartner from "./pages/partner-pov/CaseManagementPartners/CaseManagementPartners.js";
import DRCCenterPartnerships from "./pages/partner-pov/DRCCenterPartnerships";
import MyCenterPartnerships from "./pages/partner-pov/MyCenterPartnerships";
import PartnerAssistanceLog from "./pages/partner-pov/PartnerAssistanceLog";
import PartnerCitizenSearch from "./pages/partner-pov/PartnerCitizenSearch";
import PartnerGrantTracker from "./pages/partner-pov/PartnerGrantTracker";
import PartnerHistory from "./pages/partner-pov/PartnerHistory";
import PartnerJobOpportunities from "./pages/partner-pov/PartnerJobOpportunities";
import PartnerLockBox from "./pages/partner-pov/PartnerLockbox";
import PartnerOpportunities from "./pages/partner-pov/PartnerOpportunities";
import PartnerProfile from "./pages/partner-pov/PartnerProfile";
import PartnerSettings from "./pages/partner-pov/PartnerSettings";
import PartnerVolunteerOpportunities from "./pages/partner-pov/PartnerVolunteerOpportunities";
import Privacy from "./pages/privacy";
import Register2 from "./pages/Register2";
import RegisterV2 from "./pages/RegisterV2";
import Terms from "./pages/terms";
import VolunteerOpportunities from "./pages/VolunteerOpportunities";
import dataService from "./service/data/DataService";

import "./App.css";
import { DisasterObjProvider } from "./context/DisasterObjContext.js";
import { GeneralProvider } from "./context/GeneralContext.js";
import { SidebarProvider } from "./context/SidebarContext.js";
import AddressVerify from "./pages/AddressVerify";
import CaseManagementAddCitizen from "./pages/center-pov/CaseManagement/CaseManagementAddCitizen.js";
import CaseManagementDisasters from "./pages/center-pov/CaseManagement/CaseManagementDisasters.js";
import CenterAssistanceLog from "./pages/center-pov/CenterAssistanceLog.js";
import MessageCenter from "./pages/center-pov/MessageCenter.js";
import Donate from "./pages/Donate/Donate";
import CompleteYourProfile from "./pages/CompleteYourProfile.js";
import AdminDisasterLockbox from "./pages/admin-pov/DisastersTab/AdminDisasterLockbox.js";
import AdminSurveyQuestions from "./pages/admin-pov/DisastersTab/AdminSurveyQuestions.js";
const protectedCenterRoutes = [
  "/home",
  "/database",
  "/cenmyrecoveryplan",
  "/reportgenerator",
  "/communication",
  "/message-center",
  "/members",
  "/partnerships",
  "/employees",
  "/cencasemanagement",
  "/disasterdeclaration",
  "/map",
  "/centerlockbox",
];

const protectedPartnerRoutes = [
  "/home",
  "/map",
  "/employees",
  "/partneropportunities",
  "/partnergranttracker",
  "/calendar",
  "/partnerhistory",
  "/partnerlockbox",
  "/partnersettings",
  "/mycenterpartnerships",
  "/partnerassistancelog",
  "/communication",
  "/database/citizensearch",
  "/message-center",
  "/partnercasemanagement",
];

const submenuRedirects = {
  "/database": "/database/citizensearch",
  "/library": "/library/certificationvideos",
  "/opportunities": "/opportunities/jobopportunities",
  "/partneropportunities": "/partneropportunities/partnerjobopportunities",
  "/centerpartnerships": "/centerpartnerships/drccenterpartnerships",
  "/recoveryplan": "/myrecoveryplan/disasters/recoveryplan",
  "/disasters": "/citmyrecoveryplan/disasters",
  "/cenmyrecoveryplan": "/cenmyrecoveryplan/demographics",
  "/mylocalcommunity": "/mylocalcommunity/drccenter",
};

const AppContent = () => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const { role, setRoleBasedRoute } = useAuth();
  const navigate = useNavigate();
  const [userIsCertified, setUserIsCertified] = useState(false);
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);
        // Check user status
        const userData = await dataService.getUserProfile(
          "users",
          selectedUserId
        );
        if (userData) {
          if (
            userData.status === "DUPLICATE_ADDRESS_UNVERIFIED" ||
            userData.status === "EMAIL_UNVERIFIED"
          ) {
            // Clear all stored data
            localStorage.clear();
            sessionStorage.clear();

            // Reset auth context states if needed
            setData(null);
            setUserRole(null);
            setRoleBasedRoute(null);
          } else if (
            userData.status === "ACTIVE" ||
            userData.status === "ADDRESS_VERIFIED" ||
            userData.status === "DUPLICATE_ADDRESS_VERIFIED"
          ) {
            setData(userData);
            setUserRole(userData.user_role);
            if (!sessionStorage.getItem("originalRole")) {
              setRoleBasedRoute(userData.user_role);
            }
          }
        }
      } else {
        // Clear states when no user is logged in
        setData(null);
        setUserRole(null);
        localStorage.clear();
        // sessionStorage.clear();
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const userData = await dataService.getUserProfile("users", userId);
    if (userData) {
      if (userData.user_role === "center" && userData.center_id) {
        const centerRef = doc(db, "centers", userData.center_id);
        const centerSnap = await getDoc(centerRef);
        if (centerSnap.exists()) {
          setData(centerSnap.data());
          setUserIsCertified(centerSnap.data().is_certified);
        }
      } else if (userData.user_role === "partner" && userData.partner_id) {
        const partnerRef = doc(db, "partners", userData.partner_id);
        const partnerSnap = await getDoc(partnerRef);
        if (partnerSnap.exists()) {
          setData(partnerSnap.data());
          setUserIsCertified(partnerSnap.data().is_certified);
        }
      } else {
        setData(userData);
      }
      setUserRole(userData.user_role);
      setRoleBasedRoute(userData.user_role);
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const publicRoutes = [
    { path: "", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/completeyourprofile", element: <CompleteYourProfile /> },
    { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "/registration", element: <RegisterV2 /> },
    { path: "/registration/:id", element: <RegisterV2 /> },
    {
      path: "/registration/:docId/:userId/:householdId/:householdMemberId",
      element: <RegisterV2 />,
    },
    { path: "/privacy", element: <Privacy /> },
    { path: "/terms", element: <Terms /> },
    { path: "/accessaccount", element: <AccessAccount /> },
    { path: "/addressverify", element: <AddressVerify /> },
    { path: "/register2", element: <Register2 /> },
  ];

  const citizenAuthenticatedRoutes = [
    { path: "/register2", element: <Register2 /> },
    // { path: "/home", element: <Home /> },
    { path: "/home", element: <CitizenHome /> },
    { path: "/map", element: <Map /> },
    { path: "/library", element: <Library /> },
    { path: "/library/certificationvideos", element: <CertificationVideos /> },
    { path: "/library/communitycontacts", element: <CommunityContacts /> },
    { path: "/library/localnonprofit", element: <LocalNonProfitCoalition /> },
    { path: "/opportunities", element: <Opportunities /> },
    { path: "/opportunities/jobopportunities", element: <JobOpportunities /> },
    {
      path: "/opportunities/volunteeropportunities",
      element: <VolunteerOpportunities />,
    },
    { path: "/opportunities/donate", element: <Donate /> },
    { path: "/mylocalcommunity", element: <DrcCenter /> },
    { path: "/mylocalcommunity/drccenter", element: <DrcCenter /> },
    {
      path: "/mylocalcommunity/communitycontacts",
      element: <CommunityContacts />,
    },
    {
      path: "/mylocalcommunity/localnonprofit",
      element: <LocalNonProfitCoalition />,
    },
    { path: "/cenmyrecoveryplan", element: <MyRecoveryPlan /> },
    { path: "/citizenprofile", element: <CitizenProfile /> },
    {
      path: "/lockbox",
      element: <Lockbox onNavigateBack={handleNavigateBack} />,
    },
    { path: "/citmyrecoveryplan/disasters", element: <Disasters /> },
    { path: "/communication", element: <Communication /> },
    { path: "/calendar", element: <Calendar /> },
    { path: "/myhistory", element: <MyHistory /> },
    {
      path: "/myrecoveryplan/disasters/immediateresponsesurvey",
      element: <ImmediateResponseSurvey />,
    },
    {
      path: "/myrecoveryplan/disasters/disastermanagement",
      element: <DisasterManagement />,
    },
    {
      path: "/myrecoveryplan/disasters/assistancelog",
      element: <AssistanceLog />,
    },
    {
      path: "/myrecoveryplan/disasters/recoveryplan",
      element: <RecoveryPlanDisasters />,
    },
    { path: "/myrecoveryplan/disasters/journals", element: <Journals /> },
    {
      path: "/myrecoveryplan/disasters/contactsdisaster",
      element: <ContactsDisaster />,
    },
    {
      path: "/library/quickInfo",
      element: <QuickInfo />,
    },
    {
      path: "/library/prepareness",
      element: <Preparedness />,
    },
    {
      path: "/library/response",
      element: <Response />,
    },
    {
      path: "/library/recovery",
      element: <Recovery />,
    },
  ];

  // default center routes
  let centerAuthenticatedRoutes = [
    { path: "/register2", element: <Register2 /> },
    { path: "/home", element: <Home /> },
    { path: "/library", element: <Library /> },
    { path: "/library/certificationvideos", element: <CertificationVideos /> },
    { path: "/library/communitycontacts", element: <CommunityContacts /> },
    { path: "/library/localnonprofit", element: <LocalNonProfitCoalition /> },
    { path: "/library/quickInfo", element: <QuickInfo /> },
    { path: "/library/prepareness", element: <Preparedness /> },
    { path: "/library/response", element: <Response /> },
    { path: "/library/recovery", element: <Recovery /> },
    { path: "/centerprofile", element: <CommunityProfile /> },
  ];

  // Additional routes for approved centers
  const approvedCenterRoutes = [
    { path: "/members", element: <Members /> },
    { path: "/disasterdeclaration", element: <DisasterDeclaration /> },
    { path: "/disasterdeclaration/list", element: <DisasterManagementList /> },
    {
      path: "/disasterdeclaration/list/disasterdetails",
      element: <DisasterDetail />,
    },
    {
      path: "/disasterdeclaration/list/disasterdashboard",
      element: <DisasterDashboard />,
    },
    {
      path: "/disasterdeclaration/list/disasteraffectedcounty",
      element: <DisasterAffectedCounty />,
    },
    { path: "/map", element: <Map /> },
    { path: "/partnerships", element: <Partnerships /> },
    { path: "/partnershipdocuments", element: <PartnershipDocuments /> },
    { path: "/database/citizensearch", element: <CitizenSearch /> },
    { path: "/database/contacts", element: <Contacts /> },
    { path: "/database/donorpartners", element: <DonorPartners /> },
    {
      path: "/database/localnonprofitsearch",
      element: <LocalNonProfitSearch />,
    },
    {
      path: "/database/disasterrecoverysearch",
      element: <DisasterRecoverySearch />,
    },
    {
      path: "/centerlockbox",
      element: <CenterLockBox onNavigateBack={handleNavigateBack} />,
    },
    {
      path: "/cenmyrecoveryplan/contacts",
      element: <Contacts />,
    },
    {
      path: "/cenmyrecoveryplan/emergencydocuments",
      element: <EmergencyMgtDocuments />,
    },
    {
      path: "/cenmyrecoveryplan/demographics",
      element: <Demographics />,
    },
    {
      path: "/cenmyrecoveryplan/localnonprofit",
      element: <CenterLocalNonProfit />,
    },
    {
      path: "/cenmyrecoveryplan/disasterrecovery",
      element: <CenterDisasterRecovery />,
    },
    {
      path: "/cencasemanagement/:tab/:subtab",
      element: <CaseManagementCenter />,
    },
    // { path: "/granttracker", element: <GrantTracker /> },
    { path: "/communication", element: <Communication /> },
    { path: "/reportgenerator", element: <ReportGenerator /> },
    { path: "/calendar", element: <Calendar /> },
    {
      path: "/message-center",
      element: <MessageCenter />,
    },
    { path: "/settings", element: <Settings /> },
    {
      path: "/centerassistancelog",
      element: <CenterAssistanceLog />,
    },
    // {
    //   path: "/cencasemanagement/disastermanagement",
    //   element: <CaseManagementDisasters userType="center" />,
    // },
    {
      path: "/cencasemanagement/addcitizen",
      element: <CaseManagementAddCitizen />,
    },
    { path: "/partneropportunities", element: <PartnerOpportunities /> },
    {
      path: "/partneropportunities/partnerjobopportunities",
      element: <PartnerJobOpportunities />,
    },
    {
      path: "/partneropportunities/partnervolunteeropportunities",
      element: <PartnerVolunteerOpportunities />,
    },
  ];

  // default partner routes
  let partnerAuthenticatedRoutes = [
    { path: "/register2", element: <Register2 /> },
    { path: "/home", element: <Home /> },
    { path: "/library", element: <Library /> },
    { path: "/library/certificationvideos", element: <CertificationVideos /> },
    { path: "/library/communitycontacts", element: <CommunityContacts /> },
    { path: "/library/localnonprofit", element: <LocalNonProfitCoalition /> },
    { path: "/partnerprofile", element: <PartnerProfile /> },
    {
      path: "/centerpartnerships/drccenterpartnerships",
      element: <DRCCenterPartnerships />,
    },
  ];

  // Additional routes for approved partners (similar to approvedCenterRoutes)
  const approvedPartnerRoutes = [
    { path: "/employees", element: <Employees /> },
    { path: "/map", element: <Map /> },
    { path: "/partneropportunities", element: <PartnerOpportunities /> },
    {
      path: "/partneropportunities/partnerjobopportunities",
      element: <PartnerJobOpportunities />,
    },
    {
      path: "/partneropportunities/partnervolunteeropportunities",
      element: <PartnerVolunteerOpportunities />,
    },
    { path: "/centerpartnerships", element: <MyCenterPartnerships /> },
    {
      path: "/centerpartnerships/drccenterpartnerships",
      element: <DRCCenterPartnerships />,
    },
    { path: "/partnerassistancelog", element: <PartnerAssistanceLog /> },
    {
      path: "/partnerlockbox",
      element: <PartnerLockBox onNavigateBack={handleNavigateBack} />,
    },
    { path: "/communication", element: <Communication /> },
    { path: "/citizensearch", element: <PartnerCitizenSearch /> },
    { path: "/partnercasemanagement", element: <CaseManagementPartner /> },
    { path: "/partnergranttracker", element: <PartnerGrantTracker /> },
    { path: "/calendar", element: <Calendar /> },
    { path: "/partnerhistory", element: <PartnerHistory /> },
    { path: "/partnersettings", element: <PartnerSettings /> },
    {
      path: "/partnercasemanagement/disastermanagement",
      element: <CaseManagementDisasters userType="partner" />,
    },
    { path: "/message-center", element: <MessageCenter /> },
    { path: "/demographics", element: <Demographics /> },
  ];

  // Change to admin routes
  const adminAuthenticatedRoutes = [
    { path: "/register2", element: <Register2 /> },
    { path: "/center", element: <Center /> },
    { path: "/npcpartner", element: <NPCPartner /> },
    { path: "/drpartner", element: <DRPartner /> },
    { path: "/citizen", element: <Citizen /> },
    { path: "/home", element: <Home /> },

    { path: "/communication", element: <Communication /> },
    { path: "/library", element: <Library /> },
    { path: "/library/certificationvideos", element: <CertificationVideos /> },
    { path: "/library/communitycontacts", element: <CommunityContacts /> },
    { path: "/library/localnonprofit", element: <LocalNonProfitCoalition /> },
    { path: "/map", element: <Map /> },
    { path: "/message-center", element: <MessageCenter /> },
    { path: "/calendar", element: <Calendar /> },
    // { path: "/adminlockbox", element: <AdminDisasterLockbox /> },
    // { path: "/adminsurveyquestions", element: <AdminSurveyQuestions /> },
  ];

  if (role === "partner") {
    const storedUserData = sessionStorage.getItem("partner");
    const storedUser = storedUserData ? JSON.parse(storedUserData) : ""; // Check for null
    if (storedUser) {
      // Use storedUser here
      partnerAuthenticatedRoutes.push({
        path: "/employees",
        element: (
          <Employees
            data={{
              org: storedUser,
              orgId: storedUser?.id,
              orgType: "partner",
            }}
          />
        ),
      });
    }
  } else if (role === "center") {
    const storedUserData = sessionStorage.getItem("center");
    const storedUser = storedUserData ? JSON.parse(storedUserData) : ""; // Check for null
    if (storedUser) {
      centerAuthenticatedRoutes.push({
        path: "/employees",
        element: (
          <Employees
            data={{ org: storedUser, orgId: storedUser?.id, orgType: "center" }}
          />
        ),
      });
    }
  } else if (role === "admin") {
    const storedUserData = sessionStorage.getItem("admin");
    const storedUser = storedUserData ? JSON.parse(storedUserData) : null; // Check for null
    if (storedUser) {
      adminAuthenticatedRoutes.push({
        path: "/employees",
        element: (
          <Employees
            data={{ org: storedUser, orgId: storedUser?.id, orgType: "admin" }}
          />
        ),
      });
    }
  }

  // Uncomment this to logout.
  //sessionStorage.clear();

  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const isCaseManagement =
    location.pathname.startsWith("/cencasemanagement") ||
    location.pathname.startsWith("/partnercasemanagement");
  const isVerify = location.pathname == "/register2";

  let roleBasedRoutes = [];

  switch (role) {
    case "admin":
      roleBasedRoutes = adminAuthenticatedRoutes;
      break;
    case "center":
      // Check if center is approved
      if (userIsCertified) {
        roleBasedRoutes = [
          ...centerAuthenticatedRoutes,
          ...approvedCenterRoutes,
        ];
      } else {
        roleBasedRoutes = centerAuthenticatedRoutes;
      }
      break;
    case "partner":
      // Check if partner is approved
      if (userIsCertified) {
        roleBasedRoutes = [
          ...partnerAuthenticatedRoutes,
          ...approvedPartnerRoutes,
        ];
      } else {
        roleBasedRoutes = partnerAuthenticatedRoutes;
      }
      break;
    default:
      roleBasedRoutes = citizenAuthenticatedRoutes;
      break;
  }

  return (
    <div>
      {isAuthenticated && location.pathname !== "/completeyourprofile" ? (
        <div className="h-full w-full">
          {isVerify ? (
            <Routes>
              <Route path="/register2" element={<Register2 />} />
            </Routes>
          ) : (
            <div className="flex h-full w-full">
              {!isCaseManagement && (
                <LeftNavPane className="fixed z-10 h-full w-64 flex-shrink-0" />
              )}
              <div
                className={`${
                  !isCaseManagement ? "ml-64" : ""
                } flex flex-1 overflow-hidden`}
              >
                <main className="flex-1 overflow-x-auto overflow-y-auto">
                  <Navbar className="z-20" />
                  <div className="flex w-full flex-col">
                    <Routes>
                      {/* Add redirect routes for parent menu items */}
                      {Object.entries(submenuRedirects).map(([from, to]) => (
                        <Route
                          key={from}
                          path={from}
                          element={<Navigate to={to} replace />}
                        />
                      ))}

                      {roleBasedRoutes.map((route) => {
                        // Regular route rendering
                        return (
                          <Route
                            key={route.path}
                            path={route.path}
                            element={route.element}
                          />
                        );
                      })}
                      <Route
                        path="/citmyrecoveryplan/disasters"
                        element={<Disasters />}
                      />
                    </Routes>
                  </div>
                </main>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Routes>
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
        </div>
      )}
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <GeneralProvider>
        <DisasterProvider>
          <DisasterObjProvider>
            <SidebarProvider>
              <Router>
                <AppContent />
              </Router>
            </SidebarProvider>
          </DisasterObjProvider>
        </DisasterProvider>
      </GeneralProvider>
    </AuthProvider>
  );
};
export default App;
