import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import Map from "../../Map";

const CaseManagementPartners = () => {
  const [userData, setUserData] = useState(null);
  const [partners, setPartners] = useState([]);

  const selectedUserId = sessionStorage.getItem("userId");

  const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

  const pageSettings = { pageSize: 10 };

  const filterSettings = {
    type: "CheckBox",
  };

  const sortSettings = {
    allowSorting: true,
  };

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        if (!userData?.center_id) {
          return;
        }

        // First get partners from users collection that are linked to this center
        const usersRef = collection(db, "users");
        const usersQuery = query(
          usersRef,
          where("center_id", "array-contains", userData.center_id),
          where("user_role", "==", "partner")
        );
        const usersSnapshot = await getDocs(usersQuery);

        // Get all partner_ids from matching users
        const partnerIds = usersSnapshot.docs
          .map((doc) => doc.data().partner_id)
          .filter((id) => id); // Remove any undefined/null values

        if (partnerIds.length === 0) {
          setPartners([]);
          return;
        }

        // Fetch partner details from partners collection and filter for Case Management service
        const partnersList = await Promise.all(
          partnerIds.map(async (partnerId) => {
            const partnerDoc = await getDoc(doc(db, "partners", partnerId));
            if (partnerDoc.exists()) {
              const partnerData = partnerDoc.data();
              // Only include partners that have Case Management in their servicesProvided
              if (partnerData.servicesProvided?.includes("Case Management")) {
                return {
                  id: partnerDoc.id,
                  name: partnerData.name_of_org,
                  ...partnerData,
                };
              }
            }
            return null;
          })
        );

        // Filter out any null values and set partners
        const validPartners = partnersList.filter(
          (partner) => partner !== null && partner.is_certified
        );

        setPartners(validPartners);
      } catch (error) {
        setPartners([]);
      }
    };

    fetchPartners();
  }, [userData]);

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };

    fetchUserData();
  }, [selectedUserId]);

  console.log({ partners });

  return (
    <div className="flex-1 ">
      <div className="my-3 border-b border-solid border-[#a0a0a0] w-full"></div>
      {/* <GridComponent
        dataSource={partners}
        editSettings={{
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          showDeleteConfirmDialog: true,
          mode: "Dialog",
        }}
        filterSettings={filterSettings}
        allowFiltering={true}
        allowSorting={true}
        sortSettings={sortSettings}
        allowPaging={true}
        pageSettings={pageSettings}
        showColumnMenu={true}
        columnMenuItems={columnMenuItems}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="name_of_org"
            headerText="Name of Organization"
          />
          <ColumnDirective
            field="servicesProvided"
            headerText="Services"
            template={(props) => {
              return (
                <div className="flex flex-wrap gap-2">
                  {props.servicesProvided.map((sp, i) => {
                    return (
                      <div
                        key={i}
                        className="rounded-full border border-solid border-[#9D0F0F] bg-[#FFF3F3] px-2 py-1"
                      >
                        <p className="text-sm text-[#9D0F0F]">{sp}</p>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          />
        </ColumnsDirective>
        <Inject
          services={[Page, Sort, Filter, Edit, CommandColumn, ColumnMenu]}
        />
      </GridComponent> */}
      <Map />
    </div>
  );
};

export default CaseManagementPartners;
