import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
  FaChevronDown,
  FaPlus,
  FaDownload,
  FaUserPlus,
  FaChevronUp,
} from "react-icons/fa";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Toolbar,
  Filter,
  Edit,
  Page,
  Sort,
  Inject,
  actionBegin,
  DetailRow,
} from "@syncfusion/ej2-react-grids";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import DisasterMgtService from "../../../service/citizen/DisasterMgtService";
import dataService from "../../../service/data/DataService";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { db, auth } from "../../../firebase";
import { Link, useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import assistanceLogService from "../../../service/citizen/assistanceLogService";
import disasterCategories from "../../../disaster_categories.json";
import AddNewButton from "../../../components/AddNewButton";
import CustomAccordion from "../../../components/CustomAccordion";
import CustomInput from "../../../components/CustomInput";
import CustomTextArea from "../../../components/CustomTextArea";
import CustomSelect from "../../../components/CustomSelect";
import CustomTag from "../../../components/CustomTag";
import DatePickerTemplate from "../../../components/DatePickerTemplate";
import DeleteModal from "../../../components/DeleteModal";
import Modal from "../../../components/Modal";
import CustomSwitch from "../../../components/Switch";
import ThreeDotMenu from "../../../components/ThreeDotMenu";
import PhotosIcon from "../../../assets/disaster-icons/photo_library.svg";
import ImageDialog from "./ImageModal";
import { Controller, useForm } from "react-hook-form";
import SimpleFileUploader from "../../../components/SimpleFileUploader";
import SinglePhotoUploader from "../../../components/SinglePhotoUploader";
import FormPhotoUpload from "../../../components/FormPhotoUpload";
import HeaderTitle from "../../../components/HeaderTitle";
import { useDisasterObjContext } from "../../../context/DisasterObjContext";
import ModalFileViewer from "../../../components/ModalFileViewer";

const sortSettings = {
  allowSorting: true,
};

const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    value: new Date(),
    showClearButton: false,
    showTodayButton: false,
    start: "Decade",
    depth: "Day",
  },
};

// export const ImageDialog = ({
//   isOpen,
//   onClose,
//   images,
//   currentIndex,
//   setCurrentIndex,
// }) => {
//   if (!isOpen || !Array.isArray(images) || images.length === 0) return null;

//   // Filter out any invalid image objects and ensure they have a src property
//   const validImages = images
//     .filter(
//       (img) =>
//         img && typeof img === "object" && (img.src || typeof img === "string")
//     )
//     .map((img) => (typeof img === "string" ? { src: img } : img));

//   if (validImages.length === 0) return null;

//   const handlePrev = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex > 0 ? prevIndex - 1 : validImages.length - 1
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex < validImages.length - 1 ? prevIndex + 1 : 0
//     );
//   };

//   const handleBackgroundClick = (e) => {
//     if (e.target === e.currentTarget) {
//       onClose();
//     }
//   };

//   const currentImage = validImages[currentIndex] || {};

//   // Safely access image properties
//   const imageName =
//     currentImage.originalName ||
//     currentImage.name ||
//     `Image ${currentIndex + 1}`;
//   const imageSrc = currentImage.src || "";

//   return (
//     <div
//       className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
//       onClick={handleBackgroundClick}
//     >
//       <div className="relative flex h-[80vh] w-full max-w-4xl flex-col">
//         <div className="absolute left-0 right-0 top-0 flex items-center justify-between rounded-t-lg bg-black bg-opacity-50 p-4 text-white">
//           <h2 className="truncate text-xl font-bold">{imageName}</h2>
//           <button
//             onClick={onClose}
//             className="text-3xl flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
//           >
//             &times;
//           </button>
//         </div>
//         <div className="relative mt-16 flex-grow bg-black">
//           {imageSrc && (
//             <img
//               src={imageSrc}
//               alt={imageName}
//               className="absolute inset-0 h-full w-full object-contain"
//             />
//           )}
//           {validImages.length > 1 && (
//             <>
//               <button
//                 onClick={handlePrev}
//                 className="absolute left-4 top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
//               >
//                 &lt;
//               </button>
//               <button
//                 onClick={handleNext}
//                 className="absolute right-4 top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
//               >
//                 &gt;
//               </button>
//             </>
//           )}
//         </div>
//         <div className="rounded-b-lg bg-black bg-opacity-50 p-4 text-white">
//           <p className="text-center text-sm">
//             Image {currentIndex + 1} of {validImages.length}
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

function compressImage(file, maxSizeMB) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxWidth = img.width;
        const maxHeight = img.height;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = Math.floor((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.floor((width *= maxHeight / height));
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        let quality = 0.9; // Start with high quality
        let dataUrl = canvas.toDataURL("image/jpeg", quality);

        while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
          quality -= 0.1;
          dataUrl = canvas.toDataURL("image/jpeg", quality);
        }

        resolve(dataUrl);
      };

      img.onerror = function (error) {
        reject(error);
      };
    };

    reader.onerror = function (error) {
      reject(error);
    };
  });
}

// Upload image to Firebase Storage
async function uploadImageToStorage(filePath, dataUrl) {
  const storage = getStorage();
  const timestamp = Date.now();
  const uniqueFilePath = `${filePath.split(".")[0]}_${timestamp}.${
    filePath.split(".")[1]
  }`;
  const storageRef = ref(storage, uniqueFilePath);

  await uploadString(storageRef, dataUrl, "data_url");

  const downloadURL = await getDownloadURL(storageRef);

  return downloadURL;
}

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  showDeleteConfirmDialog: true,
  mode: "Dialog",
};

const filterSettings = {
  type: "Menu",
  mode: "Immediate",
  operators: {
    stringOperator: [
      { value: "contains", text: "Contains" },
      { value: "startswith", text: "Starts With" },
      { value: "endswith", text: "Ends With" },
    ],
    numberOperator: [
      { value: "equal", text: "Equal" },
      { value: "greaterthan", text: "Greater Than" },
      { value: "lessthan", text: "Less Than" },
    ],
    dateOperator: [
      { value: "equal", text: "Equal" },
      { value: "greaterthan", text: "After" },
      { value: "lessthan", text: "Before" },
    ],
  },
};

function CaseManagementAssistanceLog({ activeDisasters }) {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [centerId, setCenterId] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [editing, setEditing] = useState({
    allowEditing: false,
    allowAdding: true,
    allowDeleting: false,
    mode: "Dialog",
    dialog: { cssClass: "custom-dialog" },
    allowEditOnDblClick: false,
  });
  const selectedUserId = sessionStorage.getItem("userId");
  const { disaster } = useDisasterObjContext();
  const [images, setImages] = useState([]);
  const selectedDisasters = useMemo(() => {
    if (disaster) {
      return [disaster?.id];
    }
    return [];
  }, [disaster]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const user = auth.currentUser;
        if (selectedUserId && user) {
          const userProfile = await dataService.getUserProfile(
            "users",
            selectedUserId
          );
          if (userProfile) {
            setUserId(selectedUserId);
            setUserRole(userProfile.user_role);
            if (userProfile.user_role === "center") {
              setCenterId(userProfile.center_id);
              setEditing((prev) => ({
                ...prev,
                allowEditing: true,
                allowDeleting: true,
              }));
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      if (user && selectedUserId) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);
          if (userData.role === "center") {
            setCenterId(selectedUserId);
            setEditing((prev) => ({
              ...prev,
              allowEditing: true,
              allowDeleting: true,
            }));
          }
        }
      }
    };

    fetchUserRole();
  }, []);

  const [RecoveryAssistanceData, setRecoveryAssistanceData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const dialogRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (isDialogOpen && dialogRef.current) {
      dialogRef.current.show();
    } else if (!isDialogOpen && dialogRef.current) {
      dialogRef.current.hide();
    }
  }, [isDialogOpen]);

  const handleImageClick = (images) => {
    setSelectedImage(images);
    setCurrentImageIndex(0);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  let categoryElement, categoryObj, subcategoryElement, subcategoryObj;

  const [accessedUserId, setAccessedUserId] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([
    { id: "All", name: "All Disasters" },
  ]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchAccessedUserId = async () => {
      const user = auth.currentUser;
      if (user && selectedUserId) {
        const userDocRef = doc(db, "users", selectedUserId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("User data in CenterAssistanceLog:", userData);
          setAccessedUserId(userData.accessed_userId);
          setUserId(selectedUserId);
          setUserRole(userData.user_role);
        }
      }
    };

    fetchAccessedUserId();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);
        fetchUserRole(selectedUserId);
      } else {
        setData(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (accessedUserId) {
      fetchData();
    }
  }, [accessedUserId, selectedDisasters]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log("Accessed User ID in PartnerAssistanceLog:", accessedUserId);

  const fetchUserRole = async (uid) => {
    try {
      // Get the currently logged-in user's (center staff) document instead of accessed user
      const userDoc = await getDoc(doc(db, "users", selectedUserId));
      if (userDoc.exists()) {
        const role = userDoc.data().user_role; // or user_role depending on your db structure
        setUserRole(role);
        // or user_role depending on your db structure
        console.log("Fetched user role:", role);
        console.log("Current user ID:", selectedUserId);
        // Since this is center view, we'll set center permissions
        setEditing((prevEditing) => ({
          ...prevEditing,
          allowEditing: true, // Allow editing for center role
          allowAdding: true, // Allow adding for center role
          allowDeleting: true, // Allow deleting for center role
        }));
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };

  const fetchData = async () => {
    console.log("Fetching data for accessedUserId:", accessedUserId);
    try {
      const userDocRef = doc(db, "users", accessedUserId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });

        const filterBySelectedDisasters = (records) => {
          // If no disasters are selected, show no records
          if (selectedDisasters.length === 0) {
            return [];
          }
          return records.filter(
            (record) =>
              record.associatedDisasters &&
              record.associatedDisasters.some((disasterId) =>
                selectedDisasters.includes(disasterId)
              )
          );
        };

        const immediateAssistance = filterBySelectedDisasters(
          await assistanceLogService.fetchRecords(
            accessedUserId,
            "immediateAssistance"
          )
        );

        const general = filterBySelectedDisasters(
          await DisasterMgtService.fetchRecords(accessedUserId, "general")
        );
        const home = filterBySelectedDisasters(
          await DisasterMgtService.fetchRecords(accessedUserId, "home_repair")
        );
        const landscaping = filterBySelectedDisasters(
          await DisasterMgtService.fetchRecords(accessedUserId, "landscaping")
        );
        const vehicle = filterBySelectedDisasters(
          await DisasterMgtService.fetchRecords(accessedUserId, "vehicle")
        );
        const contents = filterBySelectedDisasters(
          await DisasterMgtService.fetchRecords(accessedUserId, "contents")
        );

        // Process and set the fetched data to your state variables
        setImmediateResponseData(
          immediateAssistance.filter((item) => !item.is_deleted)
        );
        setGeneralData(general.filter((item) => !item.is_deleted));
        setHomeRepairData(home.filter((item) => !item.is_deleted));
        setLandscapingData(landscaping.filter((item) => !item.is_deleted));
        setVehicleData(vehicle.filter((item) => !item.is_deleted));
        setContentsData(contents.filter((item) => !item.is_deleted));
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  let elem;
  let uploadObj;
  let strm;

  const formatLastEditedTime = (time) => {
    if (!time) return "Never";

    const now = new Date();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) return "Just now";
    if (diffInSeconds < 3600)
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} days ago`;

    return time.toLocaleDateString();
  };

  //Immediate Response
  const [ImmediateResponseData, setImmediateResponseData] = useState([]);
  const [isImmediateResponseVisible, setIsImmediateResponseVisible] =
    useState(true);
  const [lastImmediateResponseEditedTime, setLastImmediateResponseEditedTime] =
    useState(null);

  const toggleImmediateResponse = (event) => {
    event.preventDefault();
    setIsImmediateResponseVisible(!isImmediateResponseVisible);
  };

  const ImmediateResponseGridRef = useRef(null);

  const handleAddClickImmediateResponse = () => {
    if (ImmediateResponseGridRef.current) {
      ImmediateResponseGridRef.current.addRecord();
    }
  };

  const queryCellInfoImmediateResponse = (args) => {
    if (args.column.field === "disasterName") {
      let bgColor, textColor;
      switch (args.data.disasterName) {
        case "Fire":
          bgColor = "#FFF6E6";
          textColor = "#FDA502";
          break;
        case "Typhoon":
          bgColor = "#DCF2FF";
          textColor = "#1565C0";
          break;
        case "Flood":
          bgColor = "#BED8FF";
          textColor = "#0E47A1";
          break;
        case "Earthquake":
          bgColor = "#F4EDE8";
          textColor = "#8B4514";
          break;
        default:
          bgColor = "";
          textColor = "";
      }

      if (bgColor) {
        args.cell.innerHTML = `<div style="
               background-color: ${bgColor};
               color: ${textColor};
               padding: 5px 10px;
               border-radius: 15px;
               display: inline-block;
               font-weight: bold;
               text-align: center;
             ">${args.data.disasterName}</div>`;
      }
    }
  };
  const [selectedRow, setSelectedRow] = useState(null);
  // State for RecoveryAssistance section
  const [isRecoveryAssistanceVisible, setIsRecoveryAssistanceVisible] =
    useState(true);
  const [lastRecoveryAssistanceEditedTime, setLasRecoveryAssistanceEditedTime] =
    useState(null);
  const [maxRecoveryAssistanceID, setMaxRecoveryAssistanceID] = useState(
    RecoveryAssistanceData.length > 0
      ? Math.max(
          ...RecoveryAssistanceData.map((item) => item.RecoveryAssistanceID)
        )
      : 0
  );

  const toggleRecoveryAssistance = (event) => {
    event.preventDefault();
    setIsRecoveryAssistanceVisible(!isRecoveryAssistanceVisible);
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedData[key] = data[key] || "";
      }
    }
    return sanitizedData;
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const { categories, subcategories } = disasterCategories;
  const createImageEditTemplate = (category) => ({
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
          <div id="imagesPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
            <!-- Images will be dynamically inserted here -->
          </div>
          <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4 mb-4" style="display: none;">
            <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
          </div>
          <div id="dropArea">
            <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
              <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
              </svg>
              Upload Images
            </label>
            <input type="file" id="defaultUpload" style="display:none;" multiple accept="image/*" />
          </div>

        </div>
      `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagesPreviews = elem.querySelector("#imagesPreviews");
      const uploadProgressContainer = elem.querySelector("#uploadProgress");
      const uploadProgressBar = uploadProgressContainer.querySelector("div");

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);

            const filePath = `disaster-mgt/${category}/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );

            strm = strm
              ? [...strm, { src: downloadURL, name: file.name }]
              : [{ src: downloadURL, name: file.name }];
            displayUploadedImages(strm);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function onUploadProgress(args) {
        const progress = Math.round((args.e.loaded / args.e.total) * 100);
        uploadProgressContainer.style.display = "block";
        uploadProgressBar.style.width = `${progress}%`;
        if (progress === 100) {
          setTimeout(() => {
            uploadProgressContainer.style.display = "none";
          }, 1000);
        }
      }

      function displayUploadedImages(images) {
        if (!Array.isArray(images)) {
          console.error("Images is not an array:", images);
          return;
        }

        imagesPreviews.innerHTML = images
          .map(
            (image, index) => `
          <div class="relative group">
            <img src="${image.src}" alt="${image.name}" class="w-20 h-20 object-cover rounded-md" />
            <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <button onclick="removeImage(${index})" class="text-white hover:text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        `
          )
          .join("");
      }

      // Function to remove an image
      window.removeImage = (index) => {
        strm.splice(index, 1);
        displayUploadedImages(strm);
      };

      // Display the initial images if available
      const photoField = `photo_${category}`;
      if (args.rowData[photoField]) {
        strm = Array.isArray(args.rowData[photoField])
          ? args.rowData[photoField]
          : [args.rowData[photoField]];
        displayUploadedImages(strm);
      } else {
        imagesPreviews.innerHTML = "";
      }
    },
  });

  // Use the function for both general and landscaping
  const generalEdit = createImageEditTemplate("general");
  const landscapingEdit = createImageEditTemplate("landscaping");
  const contentsEdit = createImageEditTemplate("contents");
  const vehicleEdit = createImageEditTemplate("vehicle");
  const homeRepairEdit = createImageEditTemplate("home_repair");

  // Update the grid templates
  const gridTemplate = (props) => {
    const images = Array.isArray(props.photo_general)
      ? props.photo_general
      : props.photo_general
      ? [props.photo_general]
      : [];
    return renderImagePreview(images);
  };

  const landscapingTemplate = (props) => {
    const images = Array.isArray(props.photo_landscaping)
      ? props.photo_landscaping
      : props.photo_landscaping
      ? [props.photo_landscaping]
      : [];
    return renderImagePreview(images);
  };

  const contentsTemplate = (props) => {
    const images = Array.isArray(props.photo_contents)
      ? props.photo_contents
      : props.photo_contents
      ? [props.photo_contents]
      : [];
    return renderImagePreview(images);
  };

  const vehicleTemplate = (props) => {
    const images = Array.isArray(props.photo_vehicle)
      ? props.photo_vehicle
      : props.photo_vehicle
      ? [props.photo_vehicle]
      : [];
    return renderImagePreview(images);
  };

  const homeRepairTemplate = (props) => {
    const images = Array.isArray(props.photo_home_repair)
      ? props.photo_home_repair
      : props.photo_home_repair
      ? [props.photo_home_repair]
      : [];
    return renderImagePreview(images);
  };

  // Helper function to render image preview
  const renderImagePreview = (images) => {
    return (
      <div
        className="image-name-container"
        onClick={() => handleImageClick(images)}
        style={{
          cursor: "pointer",
          padding: "10px",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          textAlign: "center",
          minWidth: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3B82F6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "8px" }}
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <circle cx="8.5" cy="8.5" r="1.5" />
          <polyline points="21 15 16 10 5 21" />
        </svg>
        <p className="text-sm">
          {images.length > 0
            ? `${images.length} image${images.length > 1 ? "s" : ""}`
            : "No images"}
        </p>
      </div>
    );
  };

  const [generalData, setGeneralData] = useState([]);
  const [isGeneralVisible, setIsGeneralVisible] = useState(false);
  const [lastGeneralEditedTime, setLastGeneralEditedTime] = useState(null);

  const toggleGeneral = (event) => {
    event.preventDefault();
    setIsGeneralVisible(!isGeneralVisible);
  };

  const generalGridRef = useRef(null);

  const handleAddClickGeneral = () => {
    if (generalGridRef.current) {
      generalGridRef.current.addRecord();
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [currentGridRef, setCurrentGridRef] = useState(null);
  const updateColumnVisibility = (gridRef, visible) => {
    if (gridRef.current) {
      const cols = gridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = visible;
        }
      }
      gridRef.current.refreshColumns();
    }
  };

  // Update the showPopup function
  const showPopup = (message) => {
    setPopupMessage(message);
    setIsPopupOpen(true);

    // Hide the "value" column in all grids
    updateColumnVisibility(generalGridRef, true);
    updateColumnVisibility(homeRepairGridRef, true);
    updateColumnVisibility(vehicleGridRef, true);
    updateColumnVisibility(contentsGridRef, true);
    updateColumnVisibility(landscapingGridRef, true);
  };

  const actionBeginGeneral = async (args) => {
    if (
      args.requestType === "save" ||
      args.requestType === "beginEdit" ||
      args.requestType === "add"
    ) {
      // Hide the "value" column when editing or adding
      const cols = generalGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }

    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = centerId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "center") {
        if (args.rowData.editor_ID !== centerId) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }

    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.generalID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = centerId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(
          accessedUserId,
          sanitizedData,
          "general"
        );

        setLastGeneralEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "center") {
          if (updatedData.editor_ID !== centerId) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = updatedData.generalID;
        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "general"
        );

        setLastGeneralEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }

    if (args.requestType === "delete") {
      args.cancel = true;
      alert("You don't have permission to delete records.");
      return;
    }
  };

  const actionCompleteGeneral = async (args) => {
    if (args.requestType === "save") {
      const cols = generalGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }

      const documentId = args.data.generalID;
      let updatedData = { ...args.data };

      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }

      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails
          ? [updatedData.assistanceDetails]
          : [];
      }

      const sanitizedData = sanitizeData(updatedData);

      try {
        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "general"
        );

        // Refresh the grid data
        const allGeneralData = await DisasterMgtService.getGeneral(
          accessedUserId
        );
        const filteredGeneralData = allGeneralData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setGeneralData(filteredGeneralData.filter((item) => !item.is_deleted));

        setLastGeneralEditedTime(new Date());
      } catch (error) {
        console.error("Error updating general record:", error);
      }
    }
  };

  const generalDetailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedGeneralData = generalData.map((item) =>
        item.vehicleID === props.vehicleID
          ? { ...item, assistanceDetails: newDetails, value: totalAmount }
          : item
      );
      setGeneralData(updatedGeneralData);

      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          accessedUserId,
          props.generalID,
          { assistanceDetails: newDetails, value: totalAmount },
          "general"
        );

        setIsGeneralVisible((prev) => !prev);
        setTimeout(() => {
          setIsGeneralVisible((prev) => !prev);
        }, 100);
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            borderBottom: "1px solid #ccc",
          }}
        >
          <h3 style={{ fontWeight: "semibold", margin: 0 }}>
            Assistance Details
          </h3>
          <div style={{ fontSize: "14px", marginRight: "110px" }}>
            {" "}
            {/* Adjust marginRight */}
            <label classname="">Total Amount:</label>{" "}
            <span style={{ fontWeight: "bold" }}>
              $
              {props.assistanceDetails
                ?.reduce((sum, detail) => sum + (Number(detail.amount) || 0), 0)
                .toFixed(2) || "0.00"}
            </span>
          </div>
        </div>

        <AssistanceDetailsGrid
          assistanceDetails={props.assistanceDetails || []}
          onAssistanceDetailsChange={updateAssistanceDetails}
          generalID={props.generalID}
          userId={accessedUserId}
          centerId={centerId}
          userRole={userRole}
        />
      </div>
    );
  };

  //Home Repair

  const [isHomeRepairVisible, setIsHomeRepairVisible] = useState(false);
  const [lastHomeRepairEditedTime, setLastHomeRepairEditedTime] =
    useState(null);
  const [homeRepairData, setHomeRepairData] = useState([]);

  const toggleHomeRepair = (event) => {
    event.preventDefault();
    setIsHomeRepairVisible(!isHomeRepairVisible);
  };

  const homeRepairGridRef = useRef(null);

  const handleAddClickHomeRepair = () => {
    if (homeRepairGridRef.current) {
      homeRepairGridRef.current.addRecord();
    }
  };

  const actionBeginHomeRepair = async (args) => {
    if (
      args.requestType === "save" ||
      args.requestType === "beginEdit" ||
      args.requestType === "add"
    ) {
      // Hide the "value" column when editing or adding
      const cols = homeRepairGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }

    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = centerId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "center") {
        if (args.rowData.editor_ID !== centerId) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }

    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.homeRepairID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = centerId;
        updatedData.editor_type = userRole;

        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(
          accessedUserId,
          sanitizedData,
          "home_repair"
        );

        setLastHomeRepairEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "center") {
          if (updatedData.editor_ID !== centerId) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = updatedData.homeRepairID;
        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "home_repair"
        );

        setLastHomeRepairEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }

    if (args.requestType === "delete") {
      args.cancel = true;
      alert("You don't have permission to delete records.");
      return;
    }
  };

  const actionCompleteHomeRepair = async (args) => {
    if (args.requestType === "save") {
      const cols = homeRepairGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }

      const documentId = args.data.homeRepairID;
      let updatedData = { ...args.data };

      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }

      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails
          ? [updatedData.assistanceDetails]
          : [];
      }

      const sanitizedData = sanitizeData(updatedData);

      try {
        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "home_repair"
        );

        // Refresh the grid data
        const allHomeRepairData = await DisasterMgtService.getHomeRepair(
          accessedUserId
        );
        const filteredHomeRepairData = allHomeRepairData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setHomeRepairData(
          filteredHomeRepairData.filter((item) => !item.is_deleted)
        );

        setLastHomeRepairEditedTime(new Date());
      } catch (error) {
        console.error("Error updating home repair record:", error);
      }
    }
  };

  const detailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedHomeRepairData = homeRepairData.map((item) =>
        item.homeRepairID === props.homeRepairID
          ? { ...item, assistanceDetails: newDetails, value: totalAmount }
          : item
      );
      setHomeRepairData(updatedHomeRepairData);

      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          accessedUserId,
          props.homeRepairID,
          { assistanceDetails: newDetails, value: totalAmount },
          "home_repair"
        );

        setIsHomeRepairVisible((prev) => !prev);
        setTimeout(() => {
          setIsHomeRepairVisible((prev) => !prev);
        }, 100);
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            borderBottom: "1px solid #ccc",
          }}
        >
          <h3 style={{ fontWeight: "semibold", margin: 0 }}>
            Assistance Details
          </h3>
          <div style={{ fontSize: "14px", marginRight: "110px" }}>
            {" "}
            {/* Adjust marginRight */}
            <label classname="">Total Amount:</label>{" "}
            <span style={{ fontWeight: "bold" }}>
              $
              {props.assistanceDetails
                ?.reduce((sum, detail) => sum + (Number(detail.amount) || 0), 0)
                .toFixed(2) || "0.00"}
            </span>
          </div>
        </div>

        <AssistanceDetailsGrid
          assistanceDetails={props.assistanceDetails || []}
          onAssistanceDetailsChange={updateAssistanceDetails}
          homeRepairID={props.homeRepairID}
          userId={accessedUserId}
          centerId={centerId}
          userRole={userRole}
        />
      </div>
    );
  };

  // Extract unique categories from the data

  //End of Home Repair

  //Start of Landscaping
  const [landscapingData, setLandscapingData] = useState([]);
  const [isLandscapingVisible, setIsLandscapingVisible] = useState(false);
  const [lastLandscapingEditedTime, setLastLandscapingEditedTime] =
    useState(null);

  const toggleLandscaping = (event) => {
    event.preventDefault();
    setIsLandscapingVisible(!isLandscapingVisible);
  };

  const landscapingGridRef = useRef(null);

  const handleAddClickLandscaping = () => {
    if (landscapingGridRef.current) {
      landscapingGridRef.current.addRecord();
    }
  };

  const actionBeginLandscaping = async (args) => {
    if (
      args.requestType === "save" ||
      args.requestType === "beginEdit" ||
      args.requestType === "add"
    ) {
      // Hide the "value" column when editing or adding
      const cols = landscapingGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }

    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = centerId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "center") {
        if (args.rowData.editor_ID !== centerId) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }

    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.landscapingID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = centerId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(
          accessedUserId,
          sanitizedData,
          "landscaping"
        );

        setLastLandscapingEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "center") {
          if (updatedData.editor_ID !== centerId) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = updatedData.landscapingID;
        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "landscaping"
        );

        setLastLandscapingEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }

    if (args.requestType === "delete") {
      args.cancel = true;
      alert("You don't have permission to delete records.");
      return;
    }
  };

  const actionCompleteLandscaping = async (args) => {
    if (args.requestType === "save") {
      const cols = landscapingGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }

      const documentId = args.data.landscapingID;
      let updatedData = { ...args.data };

      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }

      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails
          ? [updatedData.assistanceDetails]
          : [];
      }

      const sanitizedData = sanitizeData(updatedData);

      try {
        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "landscaping"
        );

        // Refresh the grid data
        const allLandscapingData = await DisasterMgtService.getLandscaping(
          accessedUserId
        );
        const filteredLandscapingData = allLandscapingData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setLandscapingData(
          filteredLandscapingData.filter((item) => !item.is_deleted)
        );

        setLastLandscapingEditedTime(new Date());
      } catch (error) {
        console.error("Error updating landscaping record:", error);
      }
    }
  };

  const landscapingDetailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedLandscapingData = landscapingData.map((item) =>
        item.landscapingID === props.landscapingID
          ? { ...item, assistanceDetails: newDetails, value: totalAmount }
          : item
      );
      setLandscapingData(updatedLandscapingData);

      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          accessedUserId,
          props.landscapingID,
          { assistanceDetails: newDetails, value: totalAmount },
          "landscaping"
        );

        setIsLandscapingVisible((prev) => !prev);
        setTimeout(() => {
          setIsLandscapingVisible((prev) => !prev);
        }, 100);

        if (landscapingGridRef.current) {
          landscapingGridRef.current.refresh();
        }
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            borderBottom: "1px solid #ccc",
          }}
        >
          <h3 style={{ fontWeight: "semibold", margin: 0 }}>
            Assistance Details
          </h3>
          <div style={{ fontSize: "14px", marginRight: "110px" }}>
            {" "}
            {/* Adjust marginRight */}
            <label classname="">Total Amount:</label>{" "}
            <span style={{ fontWeight: "bold" }}>
              $
              {props.assistanceDetails
                ?.reduce((sum, detail) => sum + (Number(detail.amount) || 0), 0)
                .toFixed(2) || "0.00"}
            </span>
          </div>
        </div>

        <AssistanceDetailsGrid
          assistanceDetails={props.assistanceDetails || []}
          onAssistanceDetailsChange={updateAssistanceDetails}
          landscapingID={props.landscapingID}
          userId={accessedUserId}
          centerId={centerId}
          userRole={userRole}
        />
      </div>
    );
  };

  //Vehicle
  const [vehicleData, setVehicleData] = useState([]);
  const [isVehicleVisible, setIsVehicleVisible] = useState(false);
  const [lastVehicleEditedTime, setLastVehicleEditedTime] = useState(null);

  const toggleVehicle = (event) => {
    event.preventDefault();
    setIsVehicleVisible(!isVehicleVisible);
  };

  const vehicleGridRef = useRef(null);

  const handleAddClickVehicle = () => {
    if (vehicleGridRef.current) {
      vehicleGridRef.current.addRecord();
    }
  };

  const actionBeginVehicle = async (args) => {
    if (
      args.requestType === "save" ||
      args.requestType === "beginEdit" ||
      args.requestType === "add"
    ) {
      // Hide the "value" column when editing or adding
      const cols = vehicleGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }

    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = centerId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "center") {
        if (args.rowData.editor_ID !== centerId) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }

    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.vehicleID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = centerId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(
          accessedUserId,
          sanitizedData,
          "vehicle"
        );

        setLastVehicleEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "center") {
          if (updatedData.editor_ID !== centerId) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = updatedData.vehicleID;
        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "vehicle"
        );

        setLastVehicleEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }

    if (args.requestType === "delete") {
      args.cancel = true;
      alert("You don't have permission to delete records.");
      return;
    }
  };

  const actionCompleteVehicle = async (args) => {
    if (args.requestType === "save") {
      const cols = vehicleGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }

      const documentId = args.data.vehicleID;
      let updatedData = { ...args.data };

      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }

      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails
          ? [updatedData.assistanceDetails]
          : [];
      }

      const sanitizedData = sanitizeData(updatedData);

      try {
        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "vehicle"
        );

        // Refresh the grid data
        const allVehicleData = await DisasterMgtService.getVehicle(
          accessedUserId
        );
        const filteredVehicleData = allVehicleData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setVehicleData(filteredVehicleData.filter((item) => !item.is_deleted));

        setLastVehicleEditedTime(new Date());
      } catch (error) {
        console.error("Error updating vehicle record:", error);
      }
    }
  };

  const vehicleDetailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedVehicleData = vehicleData.map((item) =>
        item.vehicleID === props.vehicleID
          ? { ...item, assistanceDetails: newDetails, value: totalAmount }
          : item
      );
      setVehicleData(updatedVehicleData);

      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          accessedUserId,
          props.vehicleID,
          { assistanceDetails: newDetails, value: totalAmount },
          "vehicle"
        );

        setIsVehicleVisible((prev) => !prev);
        setTimeout(() => {
          setIsVehicleVisible((prev) => !prev);
        }, 100);

        if (vehicleGridRef.current) {
          vehicleGridRef.current.refresh();
        }
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            borderBottom: "1px solid #ccc",
          }}
        >
          <h3 style={{ fontWeight: "semibold", margin: 0 }}>
            Assistance Details
          </h3>
          <div style={{ fontSize: "14px", marginRight: "110px" }}>
            {" "}
            {/* Adjust marginRight */}
            <label classname="">Total Amount:</label>{" "}
            <span style={{ fontWeight: "bold" }}>
              $
              {props.assistanceDetails
                ?.reduce((sum, detail) => sum + (Number(detail.amount) || 0), 0)
                .toFixed(2) || "0.00"}
            </span>
          </div>
        </div>

        <AssistanceDetailsGrid
          assistanceDetails={props.assistanceDetails || []}
          onAssistanceDetailsChange={updateAssistanceDetails}
          vehicleID={props.vehicleID}
          userId={accessedUserId}
          centerId={centerId}
          userRole={userRole}
        />
      </div>
    );
  };

  //Contents
  const [contentsData, setContentsData] = useState([]);
  const [isContentsVisible, setIsContentsVisible] = useState(false);
  const [lastContentsEditedTime, setLastContentsEditedTime] = useState(null);

  const toggleContents = (event) => {
    event.preventDefault();
    setIsContentsVisible(!isContentsVisible);
  };

  const contentsGridRef = useRef(null);

  const handleAddClickContents = () => {
    if (contentsGridRef.current) {
      contentsGridRef.current.addRecord();
    }
  };

  const actionBeginContents = async (args) => {
    if (
      args.requestType === "save" ||
      args.requestType === "beginEdit" ||
      args.requestType === "add"
    ) {
      // Hide the "value" column when editing or adding
      const cols = contentsGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = false;
        }
      }
    }

    if (args.requestType === "add") {
      // Always allow adding
      args.data.editor_ID = centerId;
      args.data.editor_type = userRole;
      args.data.associatedDisasters = selectedDisasters.includes("All")
        ? activeDisasters
        : selectedDisasters;
    } else if (args.requestType === "beginEdit") {
      if (userRole === "center") {
        if (args.rowData.editor_ID !== centerId) {
          args.cancel = true;
          showPopup("You don't have permission to edit this record.");
          return;
        }
      }
      args.rowData = { ...args.rowData };
    }

    if (args.requestType === "save") {
      let updatedData = { ...args.data };
      if (args.action === "add") {
        updatedData.contentsID = generateUniqueId();
        updatedData.is_deleted = false;
        updatedData.last_edited = serverTimestamp();
        updatedData.editor_ID = centerId;
        updatedData.editor_type = userRole;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const sanitizedData = sanitizeData(updatedData);
        await DisasterMgtService.addRecord(
          accessedUserId,
          sanitizedData,
          "contents"
        );

        setLastContentsEditedTime(new Date());
      } else if (args.action === "edit") {
        if (userRole === "center") {
          if (updatedData.editor_ID !== centerId) {
            args.cancel = true;
            showPopup("You don't have permission to edit this record.");
            return;
          }
        }
        updatedData.last_edited = serverTimestamp();
        updatedData.is_deleted = false;
        updatedData.associatedDisasters = selectedDisasters.includes("All")
          ? activeDisasters
          : selectedDisasters;

        const documentId = updatedData.contentsID;
        const sanitizedData = sanitizeData(updatedData);

        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "contents"
        );

        setLastContentsEditedTime(new Date());
      }
      Object.assign(args.data, updatedData);
    }

    if (args.requestType === "delete") {
      args.cancel = true;
      alert("You don't have permission to delete records.");
      return;
    }
  };

  const actionCompleteContents = async (args) => {
    if (args.requestType === "save") {
      const cols = contentsGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "value") {
          col.visible = true;
        }
      }

      const documentId = args.data.contentsID;
      let updatedData = { ...args.data };

      if (args.action === "edit") {
        updatedData.is_deleted = false;
      }

      // Ensure assistanceDetails is an array
      if (!Array.isArray(updatedData.assistanceDetails)) {
        updatedData.assistanceDetails = updatedData.assistanceDetails
          ? [updatedData.assistanceDetails]
          : [];
      }

      const sanitizedData = sanitizeData(updatedData);

      try {
        await DisasterMgtService.updateRecord(
          accessedUserId,
          documentId,
          sanitizedData,
          "contents"
        );

        // Refresh the grid data
        const allContentsData = await DisasterMgtService.getContents(
          accessedUserId
        );
        const filteredContentsData = allContentsData.filter((item) => {
          if (selectedDisasters.includes("All")) {
            return item.associatedDisasters.some((disaster) =>
              activeDisasters.includes(disaster)
            );
          }
          return item.associatedDisasters.some((disaster) =>
            selectedDisasters.includes(disaster)
          );
        });
        setContentsData(
          filteredContentsData.filter((item) => !item.is_deleted)
        );

        setLastContentsEditedTime(new Date());
      } catch (error) {
        console.error("Error updating contents record:", error);
      }
    }
  };

  const contentsDetailTemplate = (props) => {
    const updateAssistanceDetails = async (newDetails, totalAmount) => {
      const updatedContentsData = contentsData.map((item) =>
        item.contentsID === props.contentsID
          ? { ...item, assistanceDetails: newDetails, value: totalAmount }
          : item
      );
      setContentsData(updatedContentsData);

      try {
        // Save the updated assistance details to the backend
        await DisasterMgtService.updateRecord(
          accessedUserId,
          props.contentsID,
          { assistanceDetails: newDetails, value: totalAmount },
          "contents"
        );

        setIsContentsVisible((prev) => !prev);
        setTimeout(() => {
          setIsContentsVisible((prev) => !prev);
        }, 100);

        if (contentsGridRef.current) {
          contentsGridRef.current.refresh();
        }
      } catch (error) {
        console.error("Error saving assistance details to backend:", error);
      }
      await fetchData();
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            borderBottom: "1px solid #ccc",
          }}
        >
          <h3 style={{ fontWeight: "semibold", margin: 0 }}>
            Assistance Details
          </h3>
          <div style={{ fontSize: "14px", marginRight: "110px" }}>
            {" "}
            {/* Adjust marginRight */}
            <label classname="">Total Amount:</label>{" "}
            <span style={{ fontWeight: "bold" }}>
              $
              {props.assistanceDetails
                ?.reduce((sum, detail) => sum + (Number(detail.amount) || 0), 0)
                .toFixed(2) || "0.00"}
            </span>
          </div>
        </div>

        <AssistanceDetailsGrid
          assistanceDetails={props.assistanceDetails || []}
          onAssistanceDetailsChange={updateAssistanceDetails}
          contentsID={props.contentsID}
          userId={accessedUserId}
          centerId={centerId}
          userRole="citizen"
        />
      </div>
    );
  };

  const [disasterMap, setDisasterMap] = useState({});
  const [dialogContent, setDialogContent] = useState("");

  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs.reduce((acc, doc) => {
          if (doc.data().disaster_status === "active") {
            acc[doc.id] = doc.data().disaster_name;
          }
          return acc;
        }, {});

        setDisasterMap(fetchedDisasters);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, []);

  const categoryTemplate = (props) => {
    const disasterCount = props.associatedDisasters
      ? props.associatedDisasters.length
      : 0;

    let editorTypeColor = "";
    let editorTypeLetter = "";
    switch (props.editor_type) {
      case "center":
        editorTypeColor = "bg-[#A020F0]";
        editorTypeLetter = "CM";
        break;
      case "partner":
        editorTypeColor = "bg-yellow-500";
        editorTypeLetter = "P";
        break;
      case "citizen":
        editorTypeColor = "bg-[#00008B]";
        editorTypeLetter = "S";
        break;
      default:
        editorTypeColor = "bg-gray-500";
        editorTypeLetter = "?";
    }

    return (
      <div className="flex items-center pl-1">
        <span className="mr-2">{props.sub_category}</span>
        <div className="flex items-center space-x-1">
          <span
            className={`h-7 w-7 rounded-full ${editorTypeColor} flex items-center justify-center text-xs font-bold text-white`}
            title={`Editor Type: ${props.editor_type}`}
          >
            {editorTypeLetter}
          </span>
          {/* {disasterCount > 0 && (
            <span 
              className="bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded cursor-pointer hover:bg-red-600"
              onClick={() => handleDisasterClick(props.associatedDisasters)}
              title={`${disasterCount} Associated Disaster${
                disasterCount > 1 ? "s" : ""
              }`}
            >
              {disasterCount}
            </span>
          )} */}
        </div>
      </div>
    );
  };

  const handleDisasterClick = (disasterIds) => {
    if (disasterIds && disasterIds.length > 0) {
      const disasterList = disasterIds
        .map(
          (id, index) =>
            `${index + 1}. ${disasterMap[id] || "Unknown Disaster"}`
        )
        .join("\n");
      setDialogContent(disasterList);
      setIsDialogOpen(true);
    } else {
      setDialogContent("No associated disasters.");
      setIsDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <div
        className={`box-border flex w-full max-w-[1640px] flex-col items-start justify-start  pb-[15px]`}
      >
        {isDialogOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
              <h2 className="bg-blue-500 p-4 text-xl font-bold text-white">
                Associated Disasters
              </h2>
              <div className="px-6 py-4">
                <pre className="whitespace-pre-wrap text-base">
                  {dialogContent}
                </pre>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={closeDialog}
                    className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="w-full">
          {/* <div className="text-[24px] text-[#003460] font-semibold pb-3">Assistance Log</div> */}
          <HeaderTitle
            id="immediateresponseassistancereceived"
            title="Immediate Response Assistance Received"
          />

          <section className="box-border h-auto self-stretch overflow-hidden rounded-3xs bg-white">
            <header
              id="general"
              className="flex flex-row items-center justify-between self-stretch"
            >
              <div>
                <h2 className="text-sm font-semibold text-black">General</h2>
                <p className="font-poppins text-xs font-medium text-gray-500">
                  {/* Last Edited: {formatLastEditedTime(lastGeneralEditedTime)} */}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-gray-600 text-[13px] font-medium">
                  Total Estimate to Repair:{" "}
                  <span className="font-bold">
                    $
                    {generalData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.estimate) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                <span className="text-gray-600 pr-[72px] text-[13px] font-medium">
                  Total Assistance Amount:{" "}
                  <span className="font-bold">
                    $
                    {generalData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.value) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                <button
                  type="button"
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white transition-all duration-300 ease-in-out hover:bg-gray-300"
                  onClick={toggleGeneral}
                >
                  <span
                    className={`transition-transform duration-300 ease-in-out ${
                      isGeneralVisible ? "rotate-0" : "rotate-180"
                    }`}
                  >
                    <FaChevronUp />
                  </span>
                </button>
              </div>
            </header>
            {isGeneralVisible && (
              <div className="flex flex-col self-stretch">
                <GridComponent
                  ref={generalGridRef}
                  dataSource={generalData}
                  allowSorting={true}
                  sortSettings={sortSettings}
                  editSettings={editing}
                  filterSettings={filterSettings}
                  allowFiltering={false}
                  actionBegin={actionBeginGeneral}
                  actionComplete={actionCompleteGeneral}
                  detailTemplate={generalDetailTemplate}
                  cssClass="new-grid-color-header-cell new-grid-color"
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="generalID"
                      headerText="generalID"
                      isPrimaryKey={true}
                      visible={false}
                    />
                    <ColumnDirective
                      field="category"
                      headerText="Category"
                      width="120"
                      visible={false}
                      defaultValue={
                        categories.find((cat) => cat.categoryId === "1")
                          .categoryName
                      }
                    />
                    <ColumnDirective
                      field="sub_category"
                      headerText="Category"
                      width="85"
                      template={categoryTemplate}
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "4px", fontWeight: "Bold" }}>
                          Category
                        </div>
                      )}
                      editType="dropdownedit"
                      edit={{
                        params: {
                          dataSource: subcategories
                            .filter((sub) => sub.categoryId === "1")
                            .map((sub) => sub.subcategoryName),
                          fields: {
                            text: "sub_category",
                            value: "sub_category",
                          },
                          query: new Query(),
                        },
                      }}
                    />
                    <ColumnDirective
                      field="damage"
                      headerText="Description of Damage"
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "0px", fontWeight: "Bold" }}>
                          Description of Damage
                        </div>
                      )}
                      width="95"
                    />
                    <ColumnDirective
                      field="date_entered"
                      headerText="Date Entered"
                      width="50"
                      textAlign="Center"
                    />
                    <ColumnDirective
                      field="estimate"
                      headerText="Estimate to Repair"
                      width="120"
                      format="C"
                      editType="numericedit"
                      textAlign="Right"
                      headerTextAlign="Right"
                      headerTemplate={() => (
                        <div
                          style={{ paddingRight: "100px", fontWeight: "Bold" }}
                        >
                          Estimate to Repair
                        </div>
                      )}
                      template={(props) => (
                        <div style={{ paddingRight: "100px" }}>
                          ${Number(props.estimate).toLocaleString("en-US")}{" "}
                          {/* Formats the number with commas */}
                        </div>
                      )}
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    <ColumnDirective
                      field="value"
                      headerText="Value"
                      visible={false}
                      width="80"
                      format="C"
                      textAlign="Right"
                      editType="numericedit"
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    {/* <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  /> */}
                    {/* <ColumnDirective
                      field="photo_general"
                      headerText="Photo Attachment"
                      width="120"
                      template={gridTemplate}
                      edit={generalEdit}
                    /> */}
                    {/* <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  /> */}
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Page,
                      Edit,
                      Toolbar,
                      CommandColumn,
                      Sort,
                      Filter,
                      DetailRow,
                    ]}
                  />
                </GridComponent>
              </div>
            )}
          </section>

          <HeaderTitle
            id="recoveryassistancereceived"
            title="Recovery Assistance Received"
          />
          <section className="box-border h-auto self-stretch overflow-hidden rounded-3xs  bg-white px-2"></section>

          <section className=" box-border h-auto self-stretch overflow-hidden rounded-3xs bg-white">
            <header
              id="homerepairandreplacement"
              className="flex flex-row items-center justify-between self-stretch"
            >
              <div>
                <h2 className="font-raleway text-sm font-semibold text-black">
                  Home Repair and Replacement
                </h2>
                <p className="font-poppins text-xs font-medium text-gray-500"></p>
              </div>
              <div className="flex items-center gap-4">
                {" "}
                {/* Added gap-4 for consistent spacing */}
                <span className="text-gray-600 text-[13px] font-medium">
                  Total Estimate to Repair:{" "}
                  <span className="font-bold">
                    $
                    {homeRepairData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.estimate) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                <span className="text-gray-600 pr-[72px] text-[13px] font-medium">
                  Total Assistance Amount:{" "}
                  <span className="font-bold">
                    $
                    {homeRepairData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.value) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                <button
                  type="button"
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white transition-all duration-300 ease-in-out hover:bg-gray-300"
                  onClick={toggleHomeRepair}
                >
                  <span
                    className={`transition-transform duration-300 ease-in-out ${
                      isHomeRepairVisible ? "rotate-0" : "rotate-180"
                    }`}
                  >
                    <FaChevronUp />
                  </span>
                </button>
              </div>
            </header>
            {isHomeRepairVisible && (
              <div className="flex flex-col self-stretch">
                <GridComponent
                  ref={homeRepairGridRef}
                  dataSource={homeRepairData}
                  allowSorting={true}
                  sortSettings={sortSettings}
                  editSettings={editing}
                  filterSettings={filterSettings}
                  allowFiltering={false}
                  actionBegin={actionBeginHomeRepair}
                  actionComplete={actionCompleteHomeRepair}
                  detailTemplate={detailTemplate}
                  cssClass="new-grid-color-header-cell new-grid-color"
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="homeRepairID"
                      headerText="homeRepairID"
                      isPrimaryKey={true}
                      visible={false}
                    />
                    <ColumnDirective
                      field="category"
                      headerText="Category"
                      width="120"
                      visible={false}
                      defaultValue={
                        categories.find((cat) => cat.categoryId === "2")
                          .categoryName
                      }
                    />
                    <ColumnDirective
                      field="sub_category"
                      headerText="Category"
                      width="85"
                      template={categoryTemplate}
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "4px", fontWeight: "Bold" }}>
                          Category
                        </div>
                      )}
                      editType="dropdownedit"
                      edit={{
                        params: {
                          dataSource: subcategories
                            .filter((sub) => sub.categoryId === "2")
                            .map((sub) => sub.subcategoryName),
                          fields: {
                            text: "subcategoryName",
                            value: "subcategoryName",
                          },
                          query: new Query(),
                        },
                      }}
                    />
                    <ColumnDirective
                      field="damage"
                      headerText="Description of Damage"
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "0px", fontWeight: "Bold" }}>
                          Description of Damage
                        </div>
                      )}
                      width="92"
                    />
                    <ColumnDirective
                      field="date_entered"
                      headerText="Date Entered"
                      width="50"
                      textAlign="Center"
                    />
                    <ColumnDirective
                      field="estimate"
                      headerText="Estimate to Repair"
                      width="120"
                      format="C"
                      editType="numericedit"
                      textAlign="Right"
                      headerTextAlign="Right"
                      headerTemplate={() => (
                        <div
                          style={{ paddingRight: "100px", fontWeight: "Bold" }}
                        >
                          Estimate to Repair
                        </div>
                      )}
                      template={(props) => (
                        <div style={{ paddingRight: "100px" }}>
                          ${Number(props.estimate).toLocaleString("en-US")}{" "}
                          {/* Formats the number with commas */}
                        </div>
                      )}
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    <ColumnDirective
                      field="value"
                      headerText="Value"
                      visible={false}
                      width="80"
                      format="C"
                      textAlign="Right"
                      editType="numericedit"
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    {/* <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  /> */}
                    {/* <ColumnDirective
                      field="photo_home_repair"
                      headerText="Photo Attachment"
                      width="120"
                      template={homeRepairTemplate}
                      edit={homeRepairEdit}
                    /> */}
                    {/* <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  /> */}
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Page,
                      Edit,
                      Toolbar,
                      CommandColumn,
                      Sort,
                      Filter,
                      DetailRow,
                    ]}
                  />
                </GridComponent>
              </div>
            )}
          </section>

          <section className="box-border h-auto self-stretch overflow-hidden rounded-3xs bg-white">
            <header
              id="landscaping"
              className="flex flex-row items-center justify-between self-stretch"
            >
              <div>
                <h2 className=" font-raleway text-sm font-semibold text-black">
                  Landscaping
                </h2>
                <p className="font-poppins text-xs font-medium text-gray-500"></p>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-gray-600 text-[13px] font-medium">
                  Total Estimate to Repair:{" "}
                  <span className="font-bold">
                    $
                    {landscapingData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.estimate) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                <span className="text-gray-600 pr-[72px] text-[13px] font-medium">
                  Total Assistance Amount:{" "}
                  <span className="font-bold">
                    $
                    {landscapingData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.value) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                {/* <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickLandscaping}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button> */}

                <button
                  type="button"
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white transition-all duration-300 ease-in-out hover:bg-gray-300"
                  onClick={toggleLandscaping}
                >
                  <span
                    className={`transition-transform duration-300 ease-in-out ${
                      isLandscapingVisible ? "rotate-0" : "rotate-180"
                    }`}
                  >
                    <FaChevronUp />
                  </span>
                </button>
              </div>
            </header>
            {isLandscapingVisible && (
              <div className="flex flex-col self-stretch">
                <GridComponent
                  ref={landscapingGridRef}
                  dataSource={landscapingData}
                  allowSorting={true}
                  sortSettings={sortSettings}
                  editSettings={editing}
                  filterSettings={filterSettings}
                  allowFiltering={false}
                  actionBegin={actionBeginLandscaping}
                  actionComplete={actionCompleteLandscaping}
                  detailTemplate={landscapingDetailTemplate}
                  cssClass="new-grid-color-header-cell new-grid-color"
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="landscapingID"
                      headerText="landscapingID"
                      isPrimaryKey={true}
                      visible={false}
                    />
                    <ColumnDirective
                      field="category"
                      headerText="Category"
                      width="120"
                      visible={false}
                      defaultValue={
                        categories.find((cat) => cat.categoryId === "3")
                          .categoryName
                      }
                    />
                    <ColumnDirective
                      field="sub_category"
                      headerText="Category"
                      width="85"
                      template={categoryTemplate}
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "4px", fontWeight: "Bold" }}>
                          Category
                        </div>
                      )}
                      editType="dropdownedit"
                      edit={{
                        params: {
                          dataSource: subcategories
                            .filter((sub) => sub.categoryId === "3")
                            .map((sub) => sub.subcategoryName),
                          fields: {
                            text: "subcategoryName",
                            value: "subcategoryName",
                          },
                          query: new Query(),
                        },
                      }}
                    />
                    <ColumnDirective
                      field="damage"
                      headerText="Description of Damage"
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "0px", fontWeight: "Bold" }}>
                          Description of Damage
                        </div>
                      )}
                      width="95"
                    />
                    <ColumnDirective
                      field="date_entered"
                      headerText="Date Entered"
                      width="50"
                      textAlign="Center"
                    />
                    <ColumnDirective
                      field="estimate"
                      headerText="Estimate to Repair"
                      width="120"
                      format="C"
                      editType="numericedit"
                      textAlign="Right"
                      headerTextAlign="Right"
                      headerTemplate={() => (
                        <div
                          style={{ paddingRight: "100px", fontWeight: "Bold" }}
                        >
                          Estimate to Repair
                        </div>
                      )}
                      template={(props) => (
                        <div style={{ paddingRight: "100px" }}>
                          ${Number(props.estimate).toLocaleString("en-US")}{" "}
                          {/* Formats the number with commas */}
                        </div>
                      )}
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    <ColumnDirective
                      field="value"
                      headerText="Value"
                      visible={false}
                      width="80"
                      format="C"
                      textAlign="Right"
                      editType="numericedit"
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    {/* <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  /> */}
                    {/* <ColumnDirective
                      field="photo_landscaping"
                      headerText="Photo Attachment"
                      width="120"
                      template={landscapingTemplate}
                      edit={landscapingEdit}
                    /> */}
                    {/* <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  /> */}
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Page,
                      Edit,
                      Toolbar,
                      CommandColumn,
                      Sort,
                      Filter,
                      DetailRow,
                    ]}
                  />
                </GridComponent>
              </div>
            )}
          </section>

          <section className="box-border h-auto self-stretch overflow-hidden rounded-3xs bg-white">
            <header
              id="vehicles"
              className="flex flex-row items-center justify-between self-stretch"
            >
              <div>
                <h2 className="mb-1 font-raleway text-sm font-semibold text-black">
                  Vehicle(s)
                </h2>
                <p className="font-poppins text-xs font-medium text-gray-500">
                  {/* Last Edited: {formatLastEditedTime(lastVehicleEditedTime)} */}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-gray-600 text-[13px] font-medium">
                  Total Estimate to Repair:{" "}
                  <span className="font-bold">
                    $
                    {vehicleData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.estimate) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                <span className="text-gray-600 pr-[72px] text-[13px] font-medium">
                  Total Assistance Amount:{" "}
                  <span className="font-bold">
                    $
                    {vehicleData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.value) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                {/* <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickVehicle}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button> */}

                <button
                  type="button"
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white transition-all duration-300 ease-in-out hover:bg-gray-300"
                  onClick={toggleVehicle}
                >
                  <span
                    className={`transition-transform duration-300 ease-in-out ${
                      isVehicleVisible ? "rotate-0" : "rotate-180"
                    }`}
                  >
                    <FaChevronUp />
                  </span>
                </button>
              </div>
            </header>
            {isVehicleVisible && (
              <div className="flex flex-col self-stretch">
                <GridComponent
                  ref={vehicleGridRef}
                  dataSource={vehicleData}
                  allowSorting={true}
                  sortSettings={sortSettings}
                  editSettings={editing}
                  filterSettings={filterSettings}
                  allowFiltering={false}
                  actionBegin={actionBeginVehicle}
                  actionComplete={actionCompleteVehicle}
                  detailTemplate={vehicleDetailTemplate}
                  cssClass="new-grid-color-header-cell new-grid-color"
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="vehicleID"
                      headerText="vehicleID"
                      isPrimaryKey={true}
                      visible={false}
                    />
                    <ColumnDirective
                      field="category"
                      headerText="Category"
                      width="120"
                      visible={false}
                      defaultValue={
                        categories.find((cat) => cat.categoryId === "4")
                          .categoryName
                      }
                    />
                    <ColumnDirective
                      field="sub_category"
                      headerText="Category"
                      width="85"
                      template={categoryTemplate}
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "4px", fontWeight: "Bold" }}>
                          Category
                        </div>
                      )}
                      editType="dropdownedit"
                      edit={{
                        params: {
                          dataSource: subcategories
                            .filter((sub) => sub.categoryId === "4")
                            .map((sub) => sub.subcategoryName),
                          fields: {
                            text: "subcategoryName",
                            value: "subcategoryName",
                          },
                          query: new Query(),
                        },
                      }}
                    />
                    <ColumnDirective
                      field="damage"
                      headerText="Description of Damage"
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "0px", fontWeight: "Bold" }}>
                          Description of Damage
                        </div>
                      )}
                      width="95"
                    />
                    <ColumnDirective
                      field="date_entered"
                      headerText="Date Entered"
                      width="50"
                      textAlign="Center"
                    />
                    <ColumnDirective
                      field="estimate"
                      headerText="Estimate to Repair"
                      width="120"
                      format="C"
                      editType="numericedit"
                      textAlign="Right"
                      headerTextAlign="Right"
                      headerTemplate={() => (
                        <div
                          style={{ paddingRight: "100px", fontWeight: "Bold" }}
                        >
                          Estimate to Repair
                        </div>
                      )}
                      template={(props) => (
                        <div style={{ paddingRight: "100px" }}>
                          ${Number(props.estimate).toLocaleString("en-US")}{" "}
                          {/* Formats the number with commas */}
                        </div>
                      )}
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    <ColumnDirective
                      field="value"
                      headerText="Value"
                      visible={false}
                      width="80"
                      format="C"
                      textAlign="Right"
                      editType="numericedit"
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    {/* <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  /> */}
                    {/* <ColumnDirective
                      field="photo_vehicle"
                      headerText="Photo Attachment"
                      width="120"
                      template={vehicleTemplate}
                      edit={vehicleEdit}
                    /> */}
                    {/* <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  /> */}
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Page,
                      Edit,
                      Toolbar,
                      CommandColumn,
                      Sort,
                      Filter,
                      DetailRow,
                    ]}
                  />
                </GridComponent>
              </div>
            )}
          </section>

          <section className=" box-border h-auto self-stretch overflow-hidden rounded-3xs bg-white">
            <header
              id="contents"
              className="flex flex-row items-center justify-between self-stretch"
            >
              <div>
                <h2 className="mb-1 font-raleway text-sm font-semibold text-black">
                  Contents
                </h2>
                <p className="font-poppins text-xs font-medium text-gray-500">
                  {/* Last Edited: {formatLastEditedTime(lastContentsEditedTime)} */}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <span className="text-gray-600 text-[13px] font-medium">
                  Total Estimate to Repair:{" "}
                  <span className="font-bold">
                    $
                    {contentsData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.estimate) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                <span className="text-gray-600 pr-[72px] text-[13px] font-medium">
                  Total Assistance Amount:{" "}
                  <span className="font-bold">
                    $
                    {contentsData
                      ?.reduce(
                        (sum, item) => sum + (Number(item.value) || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"}
                  </span>
                </span>
                {/* <button
                type="button"
                className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
                onClick={handleAddClickContents}
              >
                <FaPlus className="h-3 w-3" />
                Add
              </button> */}

                <button
                  type="button"
                  className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white transition-all duration-300 ease-in-out hover:bg-gray-300"
                  onClick={toggleContents}
                >
                  <span
                    className={`transition-transform duration-300 ease-in-out ${
                      isContentsVisible ? "rotate-0" : "rotate-180"
                    }`}
                  >
                    <FaChevronUp />
                  </span>
                </button>
              </div>
            </header>
            {isContentsVisible && (
              <div className="flex flex-col self-stretch">
                <GridComponent
                  ref={contentsGridRef}
                  dataSource={contentsData}
                  allowSorting={true}
                  sortSettings={sortSettings}
                  editSettings={editing}
                  filterSettings={filterSettings}
                  allowFiltering={false}
                  actionBegin={actionBeginContents}
                  actionComplete={actionCompleteContents}
                  detailTemplate={contentsDetailTemplate}
                  cssClass="new-grid-color-header-cell new-grid-color"
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="contentsID"
                      headerText="contentsID"
                      isPrimaryKey={true}
                      visible={false}
                    />
                    <ColumnDirective
                      field="category"
                      headerText="Category"
                      width="120"
                      visible={false}
                      defaultValue={
                        categories.find((cat) => cat.categoryId === "5")
                          .categoryName
                      }
                    />
                    <ColumnDirective
                      field="sub_category"
                      headerText="Category"
                      width="85"
                      template={categoryTemplate}
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "4px", fontWeight: "Bold" }}>
                          Category
                        </div>
                      )}
                      editType="dropdownedit"
                      edit={{
                        params: {
                          dataSource: subcategories
                            .filter((sub) => sub.categoryId === "5")
                            .map((sub) => sub.subcategoryName),
                          fields: {
                            text: "subcategoryName",
                            value: "subcategoryName",
                          },
                          query: new Query(),
                        },
                      }}
                    />
                    <ColumnDirective
                      field="damage"
                      headerText="Description of Damage"
                      headerTemplate={() => (
                        <div style={{ paddingLeft: "0px", fontWeight: "Bold" }}>
                          Description of Damage
                        </div>
                      )}
                      width="95"
                    />
                    <ColumnDirective
                      field="date_entered"
                      headerText="Date Entered"
                      width="50"
                      textAlign="Center"
                    />
                    <ColumnDirective
                      field="estimate"
                      headerText="Estimate to Repair"
                      width="120"
                      format="C"
                      editType="numericedit"
                      textAlign="Right"
                      headerTextAlign="Right"
                      headerTemplate={() => (
                        <div
                          style={{ paddingRight: "100px", fontWeight: "Bold" }}
                        >
                          Estimate to Repair
                        </div>
                      )}
                      template={(props) => (
                        <div style={{ paddingRight: "100px" }}>
                          ${Number(props.estimate).toLocaleString("en-US")}{" "}
                          {/* Formats the number with commas */}
                        </div>
                      )}
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    <ColumnDirective
                      field="value"
                      headerText="Value"
                      visible={false}
                      width="80"
                      format="C"
                      textAlign="Right"
                      editType="numericedit"
                      edit={{
                        params: {
                          validateDecimalOnType: true,
                          decimals: 0,
                          format: "C",
                          showSpinButton: false,
                        },
                      }}
                    />
                    {/* <ColumnDirective
                    field="organization"
                    headerText="Organization"
                    width="150"
                  /> */}
                    {/* <ColumnDirective
                      field="photo_contents"
                      headerText="Photo Attachment"
                      width="120"
                      template={contentsTemplate}
                      edit={contentsEdit}
                    /> */}
                    {/* <ColumnDirective
                    headerText="Actions"
                    width="70"
                    commands={[
                      {
                        type: "Edit",
                        buttonOption: {
                          content: '<i class="fas fa-edit"></i>',
                          cssClass: "e-outline custom-button",
                        },
                      },
                      
                    ]}
                  /> */}
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Page,
                      Edit,
                      Toolbar,
                      CommandColumn,
                      Sort,
                      Filter,
                      DetailRow,
                    ]}
                  />
                </GridComponent>
              </div>
            )}
          </section>
        </div>
        <ImageDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          images={selectedImage}
          currentIndex={currentImageIndex}
          setCurrentIndex={setCurrentImageIndex}
          setImages={setImages}
        />
        <CustomPopup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          message={popupMessage}
        />
      </div>
    </>
  );
}
const AssistanceDetailsGrid = ({
  assistanceDetails,
  onAssistanceDetailsChange,
  generalID,
  homeRepairID,
  landscapingID,
  vehicleID,
  contentsID,
  userId,
  userRole,
  selectedDisasters, // Add this
  activeDisasters,
}) => {
  const gridRef = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const editOptions = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Dialog",
    allowEditOnDblClick: false,
  };
  const toolbarOptions = ["Add", "Edit", "Delete", "Update", "Cancel"];
  const [errorDialogProps, setErrorDialogProps] = useState({
    isOpen: false,
    message: "",
  });
  const [disasterStatuses, setDisasterStatuses] = useState({});
  const [images, setImages] = useState([]);

  // Add useEffect to fetch disaster statuses
  useEffect(() => {
    const fetchDisasterStatuses = async () => {
      try {
        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const statuses = {};
        querySnapshot.docs.forEach((doc) => {
          statuses[doc.id] = doc.data().disaster_status;
        });
        setDisasterStatuses(statuses);
      } catch (error) {
        console.error("Error fetching disaster statuses:", error);
      }
    };

    fetchDisasterStatuses();
  }, []);

  const hasClosedDisaster = () => {
    return (activeDisasters || []).some(
      (disaster) => disaster.status === "closed"
    );
  };

  const showErrorDialog = useCallback((message) => {
    setErrorDialogProps({ isOpen: true, message });
  }, []);

  const closeErrorDialog = useCallback(() => {
    setErrorDialogProps({ isOpen: false, message: "" });
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, []);

  useEffect(() => {
    // Create a custom dialog element
    const dialogElement = document.createElement("div");
    dialogElement.className =
      "custom-error-dialog hidden fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50";
    dialogElement.innerHTML = `
      <div class="bg-white rounded-lg shadow-xl max-w-md w-full overflow-hidden">
        <h2 class="text-xl font-bold bg-blue-500 text-white p-4">Access Denied</h2>
        <div class="p-6">
          <p class="text-base" id="error-message"></p>
          <div class="mt-4 flex justify-end">
            <button id="close-dialog" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
              Close
            </button>
          </div>
        </div>
      </div>
    `;
    document.body.appendChild(dialogElement);

    const closeButton = dialogElement.querySelector("#close-dialog");
    closeButton.addEventListener("click", () => {
      dialogElement.classList.add("hidden");
      if (gridRef.current) {
        gridRef.current.refresh();
      }
    });

    // Function to show the custom dialog
    window.showCustomErrorDialog = (message) => {
      const messageElement = dialogElement.querySelector("#error-message");
      messageElement.textContent = message;
      dialogElement.classList.remove("hidden");
    };

    // Cleanup function
    return () => {
      document.body.removeChild(dialogElement);
      delete window.showCustomErrorDialog;
    };
  }, []);

  const actionBegin = (args) => {
    console.log("Current userRole:", userRole); // Debug log

    if (
      args.requestType === "save" ||
      args.requestType === "beginEdit" ||
      args.requestType === "add"
    ) {
      // Store the current visibility state before hiding
      const cols = gridRef.current.columns;
      cols.forEach((col) => {
        if (col.field === "description") {
          col.originalVisibility = col.visible;
          col.visible = false;
        }
      });
    }

    const restoreColumnVisibility = () => {
      if (gridRef.current) {
        const cols = gridRef.current.columns;
        cols.forEach((col) => {
          if (
            col.field === "description" &&
            col.hasOwnProperty("originalVisibility")
          ) {
            col.visible = col.originalVisibility;
            delete col.originalVisibility;
          }
        });
        gridRef.current.refreshColumns();
      }
    };

    if (args.requestType === "beginEdit") {
      console.log("Row editor_type:", args.rowData.editor_type); // Debug log

      // First check: editor_type must match userRole
      if (args.rowData.editor_type !== userRole) {
        args.cancel = true;
        window.showCustomErrorDialog(
          `Only ${args.rowData.editor_type} users can edit this record.`
        );
        if (gridRef.current) {
          setTimeout(() => {
            gridRef.current.closeEdit();
            restoreColumnVisibility();
          }, 0);
        }
        return;
      }

      // Second check: editor_ID must match userId
      if (args.rowData.editor_ID !== userId) {
        args.cancel = true;
        window.showCustomErrorDialog(
          "You don't have permission to edit this record."
        );
        if (gridRef.current) {
          setTimeout(() => {
            gridRef.current.closeEdit();
            restoreColumnVisibility();
          }, 0);
        }
      }
    }

    if (args.requestType === "delete") {
      console.log("Delete row editor_type:", args.data[0].editor_type); // Debug log

      // First check: editor_type must match userRole
      if (args.data[0].editor_type !== userRole) {
        args.cancel = true;
        window.showCustomErrorDialog(
          `Only ${args.data[0].editor_type} users can delete this record.`
        );
        if (gridRef.current) {
          setTimeout(() => {
            gridRef.current.closeEdit();
            gridRef.current.refresh();
          }, 0);
        }
        return;
      }

      // Second check: editor_ID must match userId
      if (args.data[0].editor_ID !== userId) {
        args.cancel = true;
        window.showCustomErrorDialog(
          "You don't have permission to delete this record."
        );
        if (gridRef.current) {
          setTimeout(() => {
            gridRef.current.closeEdit();
            gridRef.current.refresh();
          }, 0);
        }
      }
    }

    if (args.requestType === "cancel") {
      restoreColumnVisibility();
    }
  };

  const actionComplete = async (args) => {
    if (!args || !args.requestType) return;

    if (args.requestType === "save" || args.requestType === "delete") {
      if (!gridRef.current) {
        console.warn("Grid reference not available");
        return;
      }

      try {
        const updatedDetails = gridRef.current
          .getCurrentViewRecords()
          .map((record) => {
            let formattedDate = null;
            if (record.date_assistance) {
              let date;
              if (record.date_assistance instanceof Date) {
                date = record.date_assistance;
              } else if (typeof record.date_assistance === "string") {
                date = new Date(record.date_assistance);
              }

              if (date && !isNaN(date.getTime())) {
                formattedDate = `${(date.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}/${date
                  .getDate()
                  .toString()
                  .padStart(2, "0")}/${date.getFullYear()}`;
              }
            }

            const editorTypeDisplay =
              {
                center: "Center",
                partner: "Partner",
                citizen: "Survivor",
              }[userRole] || userRole;

            // Check if description already includes editor type prefix
            let description = record.description || "";
            const editorTypes = ["Center", "Partner", "Survivor"];
            const hasEditorTypePrefix = editorTypes.some(
              (type) =>
                description.startsWith(`${type} `) ||
                description.startsWith(`${type} - `)
            );

            // Only add editor type prefix if it's not already there
            if (!hasEditorTypePrefix) {
              description = `${editorTypeDisplay} ${description}`; // Remove the hyphen here
            } else {
              // If it has a prefix with hyphen, remove the hyphen
              editorTypes.forEach((type) => {
                if (description.startsWith(`${type} - `)) {
                  description = description.replace(`${type} - `, `${type} `);
                }
              });
            }

            return {
              description: description,
              source: record.source || "",
              organization: record.organization || "",
              amount: typeof record.amount === "number" ? record.amount : 0,
              date_assistance: formattedDate || "",
              photo_details: Array.isArray(record.photo_details)
                ? record.photo_details
                : [],
              // Keep existing editor_ID and editor_type if they exist, only set new ones for new records
              editor_ID: record.editor_ID || userId,
              editor_type: record.editor_type || userRole,
            };
          })
          .filter((detail) => Object.keys(detail).length > 0);

        const totalAmount = updatedDetails.reduce(
          (sum, detail) => sum + (detail.amount || 0),
          0
        );

        await onAssistanceDetailsChange(updatedDetails, totalAmount);

        if (gridRef.current) {
          gridRef.current.dataSource = updatedDetails;

          const columns = gridRef.current.getColumns();
          if (columns) {
            columns.forEach((col) => {
              if (
                col.field === "description" &&
                col.hasOwnProperty("originalVisibility")
              ) {
                col.visible = col.originalVisibility;
                delete col.originalVisibility;
              }
            });
            gridRef.current.refreshColumns();
          }
        }
      } catch (error) {
        console.error("Error updating assistance details:", error);
      }
    }

    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      if (dialog) {
        dialog.header =
          args.requestType === "beginEdit"
            ? "Edit Assistance Details"
            : "Add Assistance Details";
      }
    }
  };

  let elem;
  let uploadObj;
  let strm;

  const createImageEditTemplate = (category) => ({
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
        <div class="flex flex-col justify-center w-full">
          <div id="imagesPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
            <!-- Images will be dynamically inserted here -->
          </div>
          <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4 mb-4" style="display: none;">
            <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
          </div>
          <div id="dropArea">
            <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
              <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
              </svg>
              Upload Images
            </label>
            <input type="file" id="defaultUpload" style="display:none;" multiple accept="image/*" />
          </div>
          
        </div>
      `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const imagesPreviews = elem.querySelector("#imagesPreviews");
      const uploadProgressContainer = elem.querySelector("#uploadProgress");
      const uploadProgressBar = uploadProgressContainer.querySelector("div");

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);

            const filePath = `disaster-mgt/assistance_details/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );

            strm = strm
              ? [...strm, { src: downloadURL, name: file.name }]
              : [{ src: downloadURL, name: file.name }];
            displayUploadedImages(strm);
          } catch (error) {
            console.error("Error during upload:", error);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function onUploadProgress(args) {
        const progress = Math.round((args.e.loaded / args.e.total) * 100);
        uploadProgressContainer.style.display = "block";
        uploadProgressBar.style.width = `${progress}%`;
        if (progress === 100) {
          setTimeout(() => {
            uploadProgressContainer.style.display = "none";
          }, 1000);
        }
      }

      function displayUploadedImages(images) {
        if (!Array.isArray(images)) {
          console.error("Images is not an array:", images);
          return;
        }

        imagesPreviews.innerHTML = images
          .map(
            (image, index) => `
          <div class="relative group">
            <img src="${image.src}" alt="${image.name}" class="w-20 h-20 object-cover rounded-md" />
            <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <button onclick="removeImage(${index})" class="text-white hover:text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
          </div>
        `
          )
          .join("");
      }

      // Function to remove an image
      window.removeImage = (index) => {
        strm.splice(index, 1);
        displayUploadedImages(strm);
      };

      // Display the initial images if available
      const photoField = `photo_${category}`;
      if (args.rowData.photo_details) {
        strm = Array.isArray(args.rowData.photo_details)
          ? args.rowData.photo_details
          : [args.rowData.photo_details];
        displayUploadedImages(strm);
      } else {
        imagesPreviews.innerHTML = "";
      }
    },
  });

  const imageEditDetails = createImageEditTemplate();

  const template = (props) => {
    const images = Array.isArray(props.photo_details)
      ? props.photo_details
      : props.photo_details
      ? [props.photo_details]
      : [];
    return renderImagePreview(images);
  };

  const renderImagePreview = (images) => {
    return (
      <div
        className="flex cursor-pointer items-center space-x-2 pl-1"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleImageClick(images);
        }}
      >
        {/* Custom Image Icon with Color */}
        <img
          src={PhotosIcon} // <-- Ensure this is correctly imported
          alt="Photos"
          className="h-4 w-4"
          style={{
            filter:
              "brightness(0) saturate(100%) invert(16%) sepia(100%) saturate(2000%) hue-rotate(190deg)",
          }}
        />

        {/* Text with Image Count Always Visible */}
        <p className="text-xs font-medium text-[#003460]">
          Photos <span className="text-[#003460]">({images.length})</span>
        </p>
      </div>
    );
  };

  const datepickerparams = {
    params: {
      format: "MM/dd/yyyy",
      value: new Date(),
      showClearButton: false,
      showTodayButton: false,
      start: "Decade",
      depth: "Day",
    },
  };
  const dialogRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (isDialogOpen && dialogRef.current) {
      dialogRef.current.show();
    } else if (!isDialogOpen && dialogRef.current) {
      dialogRef.current.hide();
    }
  }, [isDialogOpen]);

  const handleImageClick = (images) => {
    setSelectedImage(images);
    setIsDialogOpen(true);
    setCurrentImageIndex(0);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const descriptionTemplate = (props) => {
    return (
      <div className="flex items-center justify-center">
        <div className="flex items-center space-x-2">
          <div
            className={`h-7 w-7 rounded-full text-white ${getEditorTypeBackground(
              props.editor_type
            )} flex items-center justify-center`}
          >
            {getEditorTypeLetter(props.editor_type)}
          </div>
          {/* <span className="font-medium">
            {props.description}
          </span> */}
        </div>
      </div>
    );
  };

  const sourceTemplate = (props) => {
    return (
      <div className="flex items-center">
        <span className="pr-2 font-medium">{props.source}</span>
        <div className="flex items-center">
          <div
            className={`h-7 w-7 rounded-full text-white ${getEditorTypeBackground(
              props.editor_type
            )} flex items-center justify-center`}
          >
            {getEditorTypeLetter(props.editor_type)}
          </div>
        </div>
      </div>
    );
  };

  const getEditorTypeBackground = (editorType) => {
    switch (editorType) {
      case "center":
        return "bg-[#A020F0]";
      case "partner":
        return "bg-orange-500";
      case "citizen":
        return "bg-[#00008B]";
      default:
        return "bg-gray-500";
    }
  };

  const getEditorTypeLetter = (editorType) => {
    switch (editorType) {
      case "center":
        return "CM";
      case "partner":
        return "P";
      case "citizen":
        return "S";
      default:
        return "?";
    }
  };

  const getEditorTypeLabel = (editorType) => {
    switch (editorType) {
      case "center":
        return "Center";
      case "partner":
        return "Partner";
      case "citizen":
        return "Survivor";
      default:
        return "Unknown";
    }
  };

  const handleCommandClick = (args) => {
    args.cancel = true; // Prevent row selection
    const rowData = args.rowData; // Get row data

    console.log("Clicked row data:", rowData); // Debugging log

    // Remove existing menus before adding a new one
    document.querySelectorAll(".custom-menu").forEach((menu) => {
      if (menu.parentNode) {
        menu.parentNode.removeChild(menu);
      }
    });

    // Create the dropdown menu
    const menu = document.createElement("div");
    menu.className =
      "custom-menu absolute bg-white shadow-lg rounded mt-1 py-1 border border-gray-200";
    menu.style.zIndex = "1000";
    menu.innerHTML = `
     <div class="px-4 py-2 hover:bg-[#FFF3CE] cursor-pointer" data-action="edit">Edit</div>
      <div class="px-4 py-2 hover:bg-red-800 hover:text-white cursor-pointer" data-action="delete">Delete</div>
   `;

    // Position the menu near the clicked button
    const rect = args.target.getBoundingClientRect();
    menu.style.position = "absolute";
    menu.style.top = `${window.scrollY + rect.bottom}px`;
    menu.style.left = `${window.scrollX + rect.left}px`;

    // Append menu to the document
    document.body.appendChild(menu);

    // Handle menu clicks (Edit/Delete)
    menu.addEventListener("click", (event) => {
      const action = event.target.getAttribute("data-action");

      if (action === "edit") {
        console.log("Edit clicked"); // Debugging log
        gridRef.current.startEdit(args.row); // Trigger Syncfusion edit mode
      } else if (action === "delete") {
        console.log("Delete clicked"); // Debugging log
        gridRef.current.deleteRecord(rowData); // Delete the selected row
      }

      // Remove menu after selection
      if (document.body.contains(menu)) {
        document.body.removeChild(menu);
      }
    });

    // Close the menu when clicking outside
    setTimeout(() => {
      document.addEventListener("click", function closeMenu(event) {
        if (!menu.contains(event.target)) {
          if (document.body.contains(menu)) {
            document.body.removeChild(menu);
          }
          document.removeEventListener("click", closeMenu);
        }
      });
    }, 10);
  };

  const [activeMenuAssistances, setActiveMenuAssistances] = useState(null);
  const [isEditingAssistances, setIsEditingAssistances] = useState(false);
  const [showAssistancesModal, setShowAssistancesModal] = useState(false);
  const assistancesFormRef = useRef(null);
  const [isSubmittingAssistances, setIsSubmittingAssistances] = useState(false);
  const [assistancesToDeleteId, setAssistancesToDeleteId] = useState(null);
  const [showDeleteAssistanceModal, setShowDeleteAssistanceModal] =
    useState(false);
  const [showAssistancesEditModal, setShowAssistancesEditModal] =
    useState(false);
  const [showAssistancesPhotoEditModal, setShowAssistancesPhotoEditModal] =
    useState(false);
  const {
    register: assistancesRegister,
    handleSubmit: assistancesHandleSubmit,
    formState: { errors: assistancesErrors },
    reset: assistancesReset,
    control: assistancesControl,
    setValue: assistancesSetValue,
    watch: assistancesWatch,
  } = useForm({
    defaultValues: {},
  });
  const assistanceValues = assistancesWatch();
  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const onSubmitAssistances = async (values) => {
    setIsSubmittingAssistances(true);
    try {
      // Format the date
      const currentDate = new Date();
      const formattedDate = isEditingAssistances
        ? values.date_assistance // Keep existing date when editing
        : `${(currentDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${currentDate
            .getDate()
            .toString()
            .padStart(2, "0")}/${currentDate.getFullYear()}`; // New date for new records

      // Prepare the data

      // Get editor type display
      const editorTypeDisplay =
        {
          center: "Center",
          partner: "Partner",
          citizen: "Survivor",
        }[userRole] || userRole;

      // Check if description already includes editor type prefix
      let description = values.description || "";
      const editorTypes = ["Center", "Partner", "Survivor"];
      const hasEditorTypePrefix = editorTypes.some(
        (type) =>
          description.startsWith(`${type} `) ||
          description.startsWith(`${type} - `)
      );

      // Only add editor type prefix if it's not already there
      if (!hasEditorTypePrefix) {
        description = `${editorTypeDisplay} ${description}`;
      } else {
        // If it has a prefix with hyphen, remove the hyphen
        editorTypes.forEach((type) => {
          if (description.startsWith(`${type} - `)) {
            description = description.replace(`${type} - `, `${type} `);
          }
        });
      }

      // Prepare the data
      const updatedData = {
        description: description,
        ...values,
        id: isEditingAssistances ? values.id : generateUniqueId(),
        source: values.source || "",
        organization: values.organization || "",
        amount: typeof values.amount === "number" ? values.amount : 0,
        date_assistance: formattedDate,
        photo_details: Array.isArray(values.photo_details)
          ? values.photo_details
          : [],
        editor_ID: values.editor_ID || userId,
        editor_type: values.editor_type || userRole,
        is_deleted: false,
      };

      // Calculate total amount for all records
      const currentRecords = gridRef.current.getCurrentViewRecords();
      const updatedRecords = isEditingAssistances
        ? currentRecords.map((record) =>
            record.id === values.id ? updatedData : record
          )
        : [...currentRecords, updatedData];

      const totalAmount = updatedRecords.reduce(
        (sum, detail) => sum + (detail.amount || 0),
        0
      );

      // Save the data
      if (isEditingAssistances) {
        await onAssistanceDetailsChange(updatedRecords, totalAmount);
      } else {
        await onAssistanceDetailsChange(
          [...currentRecords, updatedData],
          totalAmount
        );
      }

      // Update grid data
      if (gridRef.current) {
        gridRef.current.dataSource = updatedRecords;
        gridRef.current.refresh();
      }

      // Reset form and close modal
      setShowAssistancesModal(false);
      setIsEditingAssistances(false);
      assistancesReset();
      setIsSubmittingAssistances(false);
    } catch (error) {
      console.error("Error saving assistance:", error);
      setIsSubmittingAssistances(false);
    }
  };
  const handleImageDelete = async (imagesToKeep) => {
    try {
      const currentRecords = gridRef.current.getCurrentViewRecords();
  
      const updatedRecords = currentRecords.map((record) => {
        // Only update the record that contains the photo we're deleting
        if (record.photo_details && 
            Array.isArray(record.photo_details) && 
            record.photo_details[currentImageIndex] && 
            record.photo_details[currentImageIndex].src === selectedImage[currentImageIndex].src) {
          
          const updatedPhotoDetails = record.photo_details.filter((_, index) => 
            index !== currentImageIndex
          );
  
          return {
            ...record,
            photo_details: updatedPhotoDetails,
          };
        }
        return record; // Return unchanged record if it's not the one containing our photo
      });
  
      // Update the grid and total amount
      await onAssistanceDetailsChange(
        updatedRecords,
        updatedRecords.reduce((sum, detail) => sum + (detail.amount || 0), 0)
      );
  
      // Update grid data source
      gridRef.current.dataSource = updatedRecords;
      gridRef.current.refresh();
  
      // If no images left, close the dialog
      if (imagesToKeep.length === 0) {
        setIsDialogOpen(false);
      }
  
      // Update selected image state
      setSelectedImage(imagesToKeep.map(img => ({
        name: img.name,
        details: img.description,
        dateAdded: img.uploaded_at,
        src: img.src
      })));
  
    } catch (error) {
      console.error("Error deleting image:", error);
      setErrorDialogProps({
        isOpen: true,
        message: "Failed to delete image. Please try again."
      });
    }
  };
  const photoEditFormRef = useRef(null);
  const {
    register: photoEditRegister,
    handleSubmit: photoEditHandleSubmit,
    formState: { errors: photoEditErrors },
    reset: photoEditReset,
    setValue: photoEditSetValue,
    watch: photoEditWatch,
  } = useForm({
    defaultValues: {},
  });

  const onSubmitAssistancesEdit = async (values) => {
    setIsSubmittingAssistances(true);
    try {
      const currentRecords = gridRef.current.getCurrentViewRecords();

      const updatedRecords = currentRecords.map((record) => {
        if (record.photo_details && record.photo_details[currentImageIndex]) {
          const updatedPhotoDetails = [...record.photo_details];
          updatedPhotoDetails[currentImageIndex] = {
            ...updatedPhotoDetails[currentImageIndex],
            ...values.photo_details?.[0], // Include any new uploaded photo data
            name: values.name,
            details: values.details,
            dateAdded: new Date().toISOString(),
          };
          return {
            ...record,
            photo_details: updatedPhotoDetails,
          };
        }
        return record;
      });

      await onAssistanceDetailsChange(
        updatedRecords,
        updatedRecords.reduce((sum, detail) => sum + (detail.amount || 0), 0)
      );

      gridRef.current.dataSource = updatedRecords;
      gridRef.current.refresh();

      setShowAssistancesPhotoEditModal(false);
      photoEditReset();
      setIsSubmittingAssistances(false);
    } catch (error) {
      console.error("Error updating photo details:", error);
      setIsSubmittingAssistances(false);
    }
  };

  const [showFullView, setShowFullView] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  
  const [showPhotoUploadModal, setShowPhotoUploadModal] = useState(false);
  const {
    register: photoUploadRegister,
    handleSubmit: photoUploadHandleSubmit,
    setValue: photoUploadSetValue,
    watch: photoUploadWatch,
    formState: { errors: photoUploadErrors },
    reset: photoUploadReset,
  } = useForm();
  const photoUploadFormRef = useRef(null);
  const onSubmitPhotoUpload = async (values) => {
    setIsSubmittingAssistances(true);
    try {
      const currentRecords = gridRef.current.getCurrentViewRecords();

      const updatedRecords = currentRecords.map((record) => {
        if (record.photo_details) {
          const newPhoto = {
            ...values.photo_details?.[0],
            name: values.name,
            details: values.details,
            dateAdded: new Date().toISOString(),
          };

          return {
            ...record,
            photo_details: [...(record.photo_details || []), newPhoto],
          };
        }
        return record;
      });

      await onAssistanceDetailsChange(
        updatedRecords,
        updatedRecords.reduce((sum, detail) => sum + (detail.amount || 0), 0)
      );

      gridRef.current.dataSource = updatedRecords;
      gridRef.current.refresh();

      setShowPhotoUploadModal(false);
      photoUploadReset();
      setIsSubmittingAssistances(false);
    } catch (error) {
      console.error("Error adding new photo:", error);
      setIsSubmittingAssistances(false);
    }
  };

  return (
    <>
      <GridComponent
        ref={gridRef}
        dataSource={assistanceDetails}
        editSettings={editOptions}
        // toolbar={hasClosedDisaster() ? "" : toolbarOptions}

        actionComplete={actionComplete}
        actionBegin={actionBegin}
        cssClass="detail-grid allow-overflow-grid allow-overflow-grid-text"
      >
        <style>
          {`
    .detail-grid .e-headercell {
      color: #333 !important;
      font-weight: 600 !important;
      font-size: 13px !important;
      padding: 8px !important;
      border-bottom: 2px solid #e5e7eb !important;
      background-color: #F3F3F3 !important;
      border-right: 1px solid #e5e7eb !important;
    }
    .detail-grid .e-columnheader:hover {
      background-color: #F3F3F3 !important;
    }
    .detail-grid .e-headercell:hover {
      background-color: #F3F3F3 !important;
    }

    /* Remove borders and background color for expanded row and content */
    .detail-grid .e-detailrow,
    .detail-grid .e-detailcell,
    .detail-grid .expanded-row,
    .detail-grid .e-gridcontent {
      border: none !important;
      background-color: transparent !important;
      padding: 0 !important;
    }
    
    .detail-grid .expanded-row:hover {
      background-color: transparent !important;
    }

    /* Adjust grid content border */
    .detail-grid .e-gridcontent {
      border: none !important;
    }
      grid-noheader-assistance .e-headercontent {
  display: none;
}
  `}
        </style>

        <ColumnsDirective>
          <ColumnDirective
            field="assistancesID"
            headerText="assistancesID"
            isPrimaryKey={true}
            visible={false}
          />
          {/* <ColumnDirective
            field="description"
            headerText="Source of Funding"
            template={descriptionTemplate}
            textAlign="Center"
            width="50"
          /> */}
          <ColumnDirective
            field="source"
            headerText="Source of Funding"
            width="105" // Increased width to accommodate both elements
            editType="stringedit"
            template={sourceTemplate}
            headerTemplate={() => (
              <div style={{ paddingLeft: "6px", fontWeight: "Bold" }}>
                Source of Funding
              </div>
            )}
          />
          <ColumnDirective
            field="organization"
            headerText="Organization"
            headerTemplate={() => (
              <div style={{ paddingLeft: "4px", fontWeight: "Bold" }}>
                Organization
              </div>
            )}
            width="110"
            editType="stringedit"
          />
          <ColumnDirective
            field="date_assistance"
            headerText="Date Entered"
            width="80"
            textAlign="Center"
            editType="datepickeredit"
            edit={datepickerparams}
          />
          <ColumnDirective
            field="photo_details"
            headerText="Photos"
            headerTemplate={() => (
              <div style={{ paddingLeft: "16px", fontWeight: "Bold" }}>
                Photos
              </div>
            )}
            width="60"
            template={template}
            edit={imageEditDetails}
          />
          <ColumnDirective
            field="amount"
            headerText="Amount"
            width="50"
            format="C"
            textAlign="Right"
            editType="numericedit"
            headerTemplate={() => (
              <div style={{ paddingRight: "6px", fontWeight: "Bold" }}>
                Amount
              </div>
            )}
            edit={{
              params: {
                validateDecimalOnType: true,
                decimals: 0,
                format: "C",
                showSpinButton: false,
              },
            }}
          />

          <ColumnDirective
            field="editor_ID"
            headerText="Editor ID"
            width="20"
            visible={false}
          />
          <ColumnDirective
            field="editor_type"
            headerText="Editor Type"
            width="20"
            visible={false}
          />
          <ColumnDirective
            headerText="Actions"
            textAlign="Center"
            width="30"
            template={(props) => {
              return (
                <div className="flex w-full justify-center overflow-visible px-4">
                  <ThreeDotMenu
                    menuId={props?.id} // Change from AssistancesID to id
                    activeMenuId={activeMenuAssistances}
                    setActiveMenuId={setActiveMenuAssistances}
                    clickOutside={false}
                    onEditClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const { column, index, foreignKeyData, ...rest } = props;
                      setIsEditingAssistances(true);
                      setShowAssistancesEditModal(true);
                      setActiveMenuAssistances(null);
                      assistancesReset({
                        id: props?.id, // Make sure to include the id
                        description: props?.description || "",
                        organization: props?.organization || "",
                        date_assistance: props?.date_assistance || "",
                        amount: props?.amount || "",
                        editor_ID: props?.editor_ID || "",
                        editor_type: props?.editor_type || "",
                        photo_details: props?.photo_details || [],
                        source: props?.source || "",
                        ...rest,
                      });
                    }}
                    onDeleteClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowDeleteAssistanceModal(true);
                      setActiveMenuAssistances(null);
                      setAssistancesToDeleteId(props?.id);
                      console.log("delete");
                    }}
                  />
                </div>
              );
            }}
          />
        </ColumnsDirective>
        <Inject services={[Edit, Toolbar]} />
      </GridComponent>
      {!hasClosedDisaster() && (
        <AddNewButton
          onClick={() => {
            setIsEditingAssistances(false);
            setShowAssistancesModal(true);
            assistancesReset({
              id: "",
              source: "",
              organization: "",
              date_assistance: "",
              amount: "",
              editor_ID: "",
              editor_type: "",
              photo_details: [],
              is_deleted: false,
              fileUrl: "",
              photo: null,
            });
          }}
          name="Add New Assistance"
        />
      )}
      <ImageDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        images={
          selectedImage
            ? selectedImage.map((img) => ({
                ...img,
                uploaded_at: img.dateAdded,
                description: img.details,
              }))
            : []
        }
        currentIndex={currentImageIndex}
        setCurrentIndex={setCurrentImageIndex}
        onEditClick={(imageData) => {
          setShowAssistancesPhotoEditModal(true);
          setIsEditingAssistances(true);
          photoEditSetValue("name", imageData.name || "");
          photoEditSetValue("details", imageData.details || "");
          photoEditSetValue(
            "dateAdded",
            imageData.dateAdded || new Date().toISOString()
          );
        }}
        setImages={handleImageDelete}
        onUploadClick={() => setShowPhotoUploadModal(true)}
      />
      <ErrorDialog
        isOpen={errorDialogProps.isOpen}
        message={errorDialogProps.message}
        onClose={closeErrorDialog}
      />

      <Modal
        isOpen={showAssistancesPhotoEditModal}
        title="Edit Photo Details"
        onClose={() => {
          setShowAssistancesPhotoEditModal(false);
          photoEditReset();
        }}
        formRef={photoEditFormRef}
        isLoading={isSubmittingAssistances}
        isEdit={true}
      >
        <form
          onSubmit={photoEditHandleSubmit(onSubmitAssistancesEdit)}
          className="flex flex-col gap-4"
          ref={photoEditFormRef}
        >
          <div className="flex gap-4">
            <div className="bg-gray-50 relative flex h-[300px] w-[30%] items-center justify-center rounded-md border-2 border-gray-400 p-2">
              {selectedImage && selectedImage[currentImageIndex]?.src ? (
                <div
                  className="relative h-full w-full"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <img
                    src={selectedImage[currentImageIndex].src}
                    alt="Current Image"
                    className="h-[70%] w-full rounded-md object-contain"
                  />
                  {isHovered && (
                    <div
                      className="absolute inset-0 flex cursor-pointer items-center justify-center bg-black/50 transition-all duration-200"
                      onClick={() => setShowFullView(true)}
                    >
                      <div className="flex flex-col items-center gap-2 text-white">
                        <svg
                          className="h-8 w-8"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v6m4-3H6"
                          />
                        </svg>
                        <span className="text-sm font-medium">
                          View Full Image
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <p className="text-gray-500">Current Photo</p>
              )}
            </div>

            <div className="w-[70%]">
              <SinglePhotoUploader
                storagePath={`disaster-mgt/assistance_details/${userId}`}
                onFileUpload={(file) => {
                  const updatedPhoto = {
                    ...file,
                    name: photoEditWatch("name") || file.name,
                    details: photoEditWatch("details") || "",
                  };
                  photoEditSetValue("photo_details", [updatedPhoto]);
                }}
                initialPhoto={photoEditWatch("photo_details")?.[0]}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="w-full py-2">
              <CustomInput
                type="text"
                name="name"
                placeholder="Name"
                register={{
                  ...photoEditRegister("name", {
                    required: "Name is required",
                  }),
                }}
                errors={photoEditErrors}
              />
            </div>
            <div className="w-full">
              <CustomInput
                type="text"
                name="details"
                placeholder="Details"
                register={{
                  ...photoEditRegister("details", {
                    required: "Details is required",
                  }),
                }}
                errors={photoEditErrors}
              />
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={showPhotoUploadModal}
        title="Upload New Photo"
        onClose={() => {
          setShowPhotoUploadModal(false);
          photoUploadReset();
        }}
        formRef={photoUploadFormRef}
        isLoading={isSubmittingAssistances}
        isEdit={true}
      >
        <form
          onSubmit={photoUploadHandleSubmit(onSubmitPhotoUpload)}
          className="flex flex-col gap-4"
          ref={photoUploadFormRef}
        >
          <div className="flex gap-4">
            <div className="w-full">
              <SinglePhotoUploader
                storagePath={`disaster-mgt/assistance_details/${userId}`}
                onFileUpload={(file) => {
                  const newPhoto = {
                    ...file,
                    name: photoUploadWatch("name") || file.name,
                    details: photoUploadWatch("details") || "",
                  };
                  photoUploadSetValue("photo_details", [newPhoto]);
                }}
                initialPhoto={photoUploadWatch("photo_details")?.[0]}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="w-full py-2">
              <CustomInput
                type="text"
                name="name"
                placeholder="Name"
                register={{
                  ...photoUploadRegister("name", {
                    required: "Name is required",
                  }),
                }}
                errors={photoUploadErrors}
              />
            </div>
            <div className="w-full">
              <CustomInput
                type="text"
                name="details"
                placeholder="Details"
                register={{
                  ...photoUploadRegister("details", {
                    required: "Details is required",
                  }),
                }}
                errors={photoUploadErrors}
              />
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={showAssistancesModal}
        title={
          isEditingAssistances ? "Edit Assistances" : "Add New Assistances"
        }
        onClose={() => {
          setShowAssistancesModal(false);
          setActiveMenuAssistances(false);
          assistancesReset();
          // setSelectedHouseholdMember(null);
        }}
        formRef={assistancesFormRef}
        isLoading={isSubmittingAssistances}
        isEdit={isEditingAssistances}
      >
        <form
          onSubmit={assistancesHandleSubmit(onSubmitAssistances)}
          className="flex flex-col gap-4"
          ref={assistancesFormRef}
        >
          <div className="flex flex-row">
            <div className="w-full pr-2">
              <CustomInput
                type="text"
                name="source"
                placeholder="Source"
                register={{
                  ...assistancesRegister("source", {
                    required: "Source is required",
                  }),
                }}
                errors={assistancesErrors}
              />
            </div>
            <div className="w-full">
              <CustomInput
                type="text"
                name="organization"
                placeholder="Organization"
                register={{
                  ...assistancesRegister("organization", {
                    required: "Organization is required",
                    // pattern: {
                    //   value: alphanumericRegex,
                    //   message: "Numbers are not allowed in names",
                    // },
                  }),
                }}
                errors={assistancesErrors}
              />
            </div>
          </div>
          <div className="w-1/2 pr-2">
            <CustomInput
              type="number"
              name="amount"
              placeholder="Amount"
              register={{
                ...assistancesRegister("amount", {
                  required: "Amount is required",
                  valueAsNumber: true, // This ensures the value is converted to a number
                  validate: {
                    positive: (value) =>
                      value >= 0 || "Amount must be positive",
                  },
                }),
              }}
              errors={assistancesErrors}
            />
          </div>
          <FormPhotoUpload
            storagePath={`disaster-mgt/assistance_details/${userId}`}
            photoDetails={assistancesWatch("photo_details")}
            onPhotoUpdate={(files) =>
              assistancesSetValue("photo_details", files)
            }
            className="mb-4"
          />
        </form>
      </Modal>

      <Modal
        isOpen={showAssistancesEditModal}
        title={"Edit Assistances"}
        onClose={() => {
          setShowAssistancesEditModal(false);
          setActiveMenuAssistances(false);
          assistancesReset();
        }}
        formRef={assistancesFormRef}
        isLoading={isSubmittingAssistances}
        isEdit={isEditingAssistances}
      >
        <form
          onSubmit={assistancesHandleSubmit(onSubmitAssistances)}
          className="flex flex-col gap-4"
          ref={assistancesFormRef}
        >
          <div className="flex flex-row">
            <div className="w-full pr-2">
              <CustomInput
                type="text"
                name="source"
                placeholder="Source"
                register={{
                  ...assistancesRegister("source", {
                    required: "Source is required",
                  }),
                }}
                errors={assistancesErrors}
              />
            </div>
            <div className="w-full">
              <CustomInput
                type="text"
                name="organization"
                placeholder="Organization"
                register={{
                  ...assistancesRegister("organization", {
                    required: "Organization is required",
                  }),
                }}
                errors={assistancesErrors}
              />
            </div>
          </div>

          <div className="w-1/2 pr-2">
            <CustomInput
              type="number"
              name="amount"
              placeholder="Amount"
              register={{
                ...assistancesRegister("amount", {
                  required: "Amount is required",
                  valueAsNumber: true,
                  validate: {
                    positive: (value) =>
                      value >= 0 || "Amount must be positive",
                  },
                }),
              }}
              errors={assistancesErrors}
            />
          </div>
          <div
            className="flex w-full cursor-pointer items-center justify-center rounded-md bg-[#002B5B] py-3 hover:bg-[#001F40]"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const images = assistancesWatch("photo_details");
              setShowAssistancesEditModal(false);
              handleImageClick(
                Array.isArray(images) ? images : images ? [images] : []
              );
            }}
          >
            <p className="text-base font-semibold text-yellow-400">
              View Photo ({assistancesWatch("photo_details")?.length || 0})
            </p>
          </div>
        </form>
      </Modal>

      <DeleteModal
        isOpen={showDeleteAssistanceModal}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDeleteAssistanceModal(false);
          setAssistancesToDeleteId(null);
        }}
      />
      <ModalFileViewer
        isOpen={showFullView}
        setIsOpen={setShowFullView}
        title={selectedImage?.[currentImageIndex]?.name || "View Photo"}
        fileUrl={selectedImage?.[currentImageIndex]?.src}
        fileType="image"
        height="600px"
      />
    </>
  );
};

const ErrorDialog = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
        <h2 className="bg-blue-500 p-4 text-xl font-bold text-white">
          Access Denied
        </h2>
        <div className="p-6">
          <p className="text-base">{message}</p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomPopup = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl">
        <h2 className="bg-blue-500 p-4 text-xl font-bold text-white">
          Access Denied
        </h2>
        <div className="px-6 py-4">
          <p className="text-lg">{message}</p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CaseManagementAssistanceLog;
