import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  deleteUser,
} from "firebase/auth";

const auth = getAuth();

const CenterService = {
  createUserAccount: async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      console.error("Error creating user: ", error);
      throw error;
    }
  },

  addCenter: async (collectionName, data) => {
    try {
      const docRef = await setDoc(
        doc(db, collectionName, data.center_id),
        data
      );
      return docRef;
    } catch (error) {
      console.error("Error adding document: ", error);
      throw error;
    }
  },

  updateCenter: async (collectionName, docId, data) => {
    try {
      const docRef = doc(db, collectionName, docId);
      await updateDoc(docRef, data);
    } catch (error) {
      console.error("Error updating document: ", error);
      throw error;
    }
  },

  deleteUserAccount: async (uid) => {
    try {
      const user = auth.currentUser;

      // Ensure the user is authenticated and the current user matches the user to delete
      if (user && user.uid === uid) {
        await deleteUser(user);
      } else {
        // If not the current user, you might need to re-authenticate or use an admin SDK
        console.error("User mismatch or not authenticated.");
      }
    } catch (error) {
      console.error("Error deleting user from authentication: ", error);
      throw error;
    }
  },

  deleteCenter: async (collectionName, docId) => {
    try {
      const docRef = doc(db, collectionName, docId);
      await deleteDoc(docRef);
    } catch (error) {
      console.error("Error deleting document: ", error);
      throw error;
    }
  },

  getCenterById: async (id) => {
    try {
      const centerDocRef = doc(db, "centers", id);
      const centerDoc = await getDoc(centerDocRef);
      if (centerDoc.exists()) {
        return { id: centerDoc.id, ...centerDoc.data() };
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting user:", error);
    }
  },

  getCenterByUserId: async (userId) => {
    try {
      const centerDocRef = doc(db, "users", userId);
      const centerDoc = await getDoc(centerDocRef);
      console.log("centerDoc", centerDoc.data());
      if (centerDoc.exists()) {
        return { id: centerDoc.id, ...centerDoc.data() };
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting center:", error);
    }
  },

  getAllCenters: async () => {
    try {
      // Get all users first to get created_dates
      const usersQuery = query(
        collection(db, "users"),
        where("user_role", "==", "center")
      );
      const usersSnapshot = await getDocs(usersQuery);
      const userDates = {};

      // Store dates by both document ID and center_id
      usersSnapshot.docs.forEach((doc) => {
        const userData = doc.data();
        if (userData.created_date) {
          userDates[doc.id] = userData.created_date;
          if (userData.center_id) {
            userDates[userData.center_id] = userData.created_date;
          }
        }
      });

      // Get centers from the "centers" collection
      const centersQuery = query(collection(db, "centers"));
      const centersSnapshot = await getDocs(centersQuery);

      // Get all center data with their IDs
      const centersData = await Promise.all(
        centersSnapshot.docs.map(async (centerDoc) => {
          const centerId = centerDoc.id;
          const centerData = centerDoc.data();

          // Get number of partners
          const partnersQuery = query(
            collection(db, "users"),
            where("user_role", "==", "partner"),
            where("center_id", "array-contains", centerId)
          );
          const partnerSnapshot = await getDocs(partnersQuery);
          const number_of_partners = partnerSnapshot.size;

          // Get number of citizens
          const citizensQuery = query(
            collection(db, "users"),
            where("user_role", "==", "citizen"),
            where("center_id", "==", centerId)
          );
          const citizenSnapshot = await getDocs(citizensQuery);
          const number_of_citizens = citizenSnapshot.size;

          const created_date = userDates[centerId];

          return {
            ...centerData,
            id: centerId,
            created_date,
            number_of_partners,
            number_of_citizens,
          };
        })
      );

      return centersData;
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  },

  getEmployees: async (centerId) => {
    try {
      const employeesRef = collection(db, `centers/${centerId}/employees`);
      const querySnapshot = await getDocs(employeesRef);
      const employees = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return employees;
    } catch (error) {
      return [];
    }
  },

  updateEmployee: async (userId, centerId, updatedData) => {
    try {
      const employeeRef = doc(db, `centers/${centerId}/employees`, userId);
      await updateDoc(employeeRef, updatedData);
    } catch (error) {
      console.error("Error updating employee:", error);
    }
  },

  createEmployeeInCenter: async (userId, docId, employeeData) => {
    try {
      const employeeRef = doc(db, `centers/${userId}/employees`, docId);
      await setDoc(employeeRef, employeeData);
      return docId;
    } catch (error) {
      console.error("Error creating employee in center:", error);
    }
  },

  updateEmployeeInCenter: async (centerId, docId, updatedData) => {
    try {
      const employeeRef = doc(db, `centers/${centerId}/employees`, docId);
      await updateDoc(employeeRef, updatedData);
      console.log("Employee updated in center:", docId);
    } catch (error) {
      console.error("Error updating employee in center:", error);
    }
  },

  updateEmployeeInUsersCollection: async (employeeEmail, updatedData) => {
    try {
      const employeesRef = collection(db, `users`);
      const q = query(employeesRef, where("email", "==", employeeEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const employeeDoc = querySnapshot.docs[0];
        await updateDoc(employeeDoc.ref, updatedData);
      } else {
        console.error("Employee not found with email:", employeeEmail);
      }
    } catch (error) {
      console.error("Error updating employee in users:", error);
    }
  },

  deleteEmployeeInUsersCollection: async (employeeEmail) => {
    try {
      const employeesRef = collection(db, `users`);
      const q = query(employeesRef, where("email", "==", employeeEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const employeeDoc = querySnapshot.docs[0];
        await updateDoc(employeeDoc.ref, { is_deleted: true });
      }
    } catch (error) {
      console.error("Error deleting employee in users:", error);
    }
  },

  deleteEmployeeInCentersCollection: async (employeeEmail, centerId) => {
    try {
      const employeesRef = collection(db, `centers/${centerId}/employees`);
      const q = query(employeesRef, where("email", "==", employeeEmail));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const employeeDoc = querySnapshot.docs[0];
        await updateDoc(employeeDoc.ref, { is_deleted: true });
      }
    } catch (error) {
      console.error("Error deleting employee in center:", error);
    }
  },
};

export default CenterService;
