import { registerLicense } from "@syncfusion/ej2-base";
import React, { useState } from "react";
import { Inject } from "@syncfusion/ej2-react-grids";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  ColumnSeries,
  Category,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

// Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxceXRcQmZdV0R/XUM="
);

const HomeDamageTypedGraph = ({
  typeOfHomeDamageData = {
    "Check All": 0,
    Siding: 0,
    Flooring: 0,
    Cabinets: 0,
    "Shed/garage": 0,
    Roof: 0,
    Foundation: 0,
    "Floor Joists": 0,
    HVAC: 0,
    Driveway: 0,
    Gutters: 0,
    Ceiling: 0,
    Drywall: 0,
    Electrical: 0,
    "Yard/Uprooted trees": 0,
    Other: 0,
  },
}) => {
  const chartColorPalette = [
    "#EF4444",
    "#F59E0B",
    "#3B82F6",
    "#10B981",
    "#F97316",
    "#8B5CF6",
  ];

  const ChartCardLong = ({ title, children }) => (
    <div className="w-full flex-1 rounded-md border border-solid border-gainsboro-200 bg-white p-5 shadow-lg">
      <h2 className="mb-4 flex items-center justify-between text-sm font-bold text-blue-500">
        {title}
        <span className="text-gray-400">⋮</span>
      </h2>
      {children}
    </div>
  );

  return (
    <div className="w-full">
      <ChartCardLong title="Type of Home Damage">
        <ChartComponent
          id="home-damage-type"
          primaryXAxis={{
            valueType: "Category",
            labelStyle: { size: "10px" },
            enableTextWrapping: true,
            maximumLabelWidth: 60,
            labelIntersectAction: "Wrap",
            majorGridLines: { width: 0 },
            minorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
          primaryYAxis={{
            minimum: 0,
            maximum: 70,
            interval: 10,
            labelFormat: "{value}%",
          }}
          height="350px"
          chartArea={{ border: { width: 0 } }}
          palettes={chartColorPalette}
          tooltip={{ enable: true }}
          style={{ zIndex: 0 }}
        >
          <Inject services={[ColumnSeries, Category, DataLabel, Tooltip]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={[
                {
                  x: "Check All",
                  y: typeOfHomeDamageData["Check All"],
                },
                { x: "Siding", y: typeOfHomeDamageData.Siding },
                { x: "Flooring", y: typeOfHomeDamageData.Flooring },
                { x: "Cabinets", y: typeOfHomeDamageData.Cabinets },
                {
                  x: "Shed/garage",
                  y: typeOfHomeDamageData["Shed/garage"],
                },
                { x: "Roof", y: typeOfHomeDamageData.Roof },
                { x: "Foundation", y: typeOfHomeDamageData.Foundation },
                {
                  x: "Floor Joists",
                  y: typeOfHomeDamageData["Floor Joists"],
                },
                { x: "HVAC", y: typeOfHomeDamageData.HVAC },
                { x: "Driveway", y: typeOfHomeDamageData.Driveway },
                { x: "Gutters", y: typeOfHomeDamageData.Gutters },
                { x: "Ceiling", y: typeOfHomeDamageData.Ceiling },
                { x: "Drywall", y: typeOfHomeDamageData.Drywall },
                { x: "Electrical", y: typeOfHomeDamageData.Electrical },
                {
                  x: "Yard/Uprooted trees",
                  y: typeOfHomeDamageData["Yard/Uprooted trees"],
                },
                { x: "Other", y: typeOfHomeDamageData.Other },
              ]}
              xName="x"
              yName="y"
              type="Column"
              palettes={chartColorPalette}
              dataLabel={{
                visible: true,
                position: "Top",
                format: "{value}%",
                font: { size: "10px" },
              }}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </ChartCardLong>
    </div>
  );
};
export default HomeDamageTypedGraph;
