import React, { useState, useRef, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Edit,
  Page,
  Sort,
  Filter,
  CommandColumn,
  Search,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import { USStates } from "../datasource";
import { Query } from "@syncfusion/ej2-data";
import { FaPlus } from "react-icons/fa";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import dataService from "../../service/data/DataService";

const filterSettings = {
  type: "CheckBox",
};

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 18 };

const Citizen = () => {
  const [userId, setUserId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);

  const [citizen, setCitizen] = useState([]);
  const gridRef = useRef(null);
  const selectedUserId = sessionStorage.getItem("userId");

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUserId(selectedUserId);
        const fetchCitizens = async () => {
          try {
            const citizen = await dataService.getAllCitizens("users");
            setCitizen(citizen);
            const transformedCitizen = citizen.map((c) => ({
              ...c,
              name: `${c.first_name || ""} ${c.last_name || ""}`.trim(),
              address: `${c.address1 || ""} ${c.address2 || ""} ${
                c.city || ""
              } ${c.state || ""} ${c.zip || ""}`.trim(),
            }));

            setCitizen(transformedCitizen);
          } catch (error) {
            console.error("Error fetching centers: ", error);
          }
        };

        fetchCitizens();
      } else {
        setCurrentUserData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    const userData = await dataService.getUserProfile("users", userId);
    if (userData) {
      setCurrentUserData(userData);
    }
  };

  return (
    <>
      <div className={`flex max-w-full flex-col gap-5 px-2.5 py-[15px]`}>
        <div className="flex items-center gap-2 pt-2 text-xs text-darkslateblue">
          <span className="font-medium text-blue-500">Citizen</span>
        </div>
      </div>
      <header className=" flex w-full flex-row items-center  justify-between self-stretch rounded-3xs px-2.5 text-left text-xs text-darkslateblue">
        <div>
          <h2 className="mb-1 text-sm font-bold uppercase text-blue-500">
            List of Citizens
          </h2>
          {/* <p className="font-poppins text-xs font-medium text-gray-500">
            Last Edited: {formatLastEditedTime(lastEditedTime)}
          </p> */}
        </div>
        {/* <div className="flex items-center gap-2">
          <button
            type="button"
            className="flex cursor-pointer items-center gap-1 rounded bg-blue-500 px-3 py-1.5 text-xs font-semibold text-white"
            onClick={handleAdd}
          >
            <FaPlus className="h-3 w-3" />
            Add
          </button>
        </div> */}
      </header>
      <div className="mt-3 w-full rounded-3xs px-2.5   text-left text-xs text-darkslateblue">
        <GridComponent
          ref={gridRef}
          dataSource={citizen}
          allowSorting={true}
          sortSettings={sortSettings}
          filterSettings={filterSettings}
          allowFiltering={true}
          // toolbar={["Search"]}

          allowPaging={true}
          pageSettings={pageSettings}
          showColumnMenu={true}
          columnMenuItems={columnMenuItems}
          height="70vh"
        >
          <ColumnsDirective>
            <ColumnDirective
              field="email"
              headerText="Email"
              width="100"
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="password"
              headerText="Password"
              width="100"
              editType="passwordedit"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective field="name" headerText="Name" width="150" />
            <ColumnDirective
              field="first_name"
              headerText="First Name"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="last_name"
              headerText="Last Name"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective field="address" headerText="Address" width="150" />
            <ColumnDirective
              field="address1"
              headerText="Address 1"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="address2"
              headerText="Address 2"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="city"
              headerText="City"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="state"
              headerText="State"
              visible={false}
              editType="dropdownedit"
              edit={{
                params: {
                  dataSource: USStates,
                  fields: { text: "abbreviation", value: "abbreviation" },
                  query: new Query(),
                },
              }}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="zip"
              headerText="Zip"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="county"
              headerText="County"
              width="100"
              visible={false}
              validationRules={{ required: true }}
            />
            <ColumnDirective
              field="mobile_number"
              headerText="Mobile Number"
              width="100"
              validationRules={{ required: true }}
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter, Search, ColumnMenu]} />
        </GridComponent>
      </div>
    </>
  );
};

export default Citizen;
