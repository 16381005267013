import { L10n } from "@syncfusion/ej2-base";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  Search,
  Selection,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import MilestoneImage from "../../../assets/Milestone.png";
import { CitizenForm } from "../../../components/CitizenForm";
import Modal from "../../../components/Modal";
import { useDisasterObjContext } from "../../../context/DisasterObjContext";
import { db } from "../../../firebase";
import AssignIcon from "../../../icons/CaseManagement/assign.svg";
import ReassignIcon from "../../../icons/CaseManagement/reassign.svg";
import RecoveryPlanIcon from "../../../icons/CaseManagement/recovery plan.svg";
import StatusClosedIcon from "../../../icons/CaseManagement/status-closed.svg";
import StatusOpenIcon from "../../../icons/CaseManagement/status-open.svg";
import Calendar from "../../Calendar";
import CaseManagementAgency from "./CaseManagementAgency";
import CaseManagementContactsDisaster from "./CaseManagementContacts";
import CaseManagementDashboard from "./CaseManagementDashboard";
import CaseManagementDisaster from "./CaseManagementDisaster";
import CenterDisasters from "./CaseManagementDisasters";
import CaseManagementLayout, {
  caseManagementSidebarItems,
} from "./CaseManagementLayout";
import CaseManagementPartners from "./CaseManagementPartners";
import CaseManager from "./CaseManager";
import dataService from "../../../service/data/DataService";
import CustomInput from "./../../../../src/components/CustomInput";
import DatePickerTemplate from "./../../../../src/components/DatePickerTemplate";
import CaseManagementDisasterLockbox from "./CaseManagementDisasterLockbox";

import {
  FaFolderOpen,
  FaPauseCircle,
  FaCheck,
  FaPlus,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import CaseManagementSurveyQuestions from "./CaseManagementSurveyQuestions";
import { useSidebarContext } from "../../../context/SidebarContext";
import CommunityContacts from "./CommunityContacts";

L10n.load({
  "en-US": {
    grid: {
      GroupCaption: "${field}: ${key} - ${count} cases",
      UnassignedCaption: "Unassigned",
      UnspecifiedCaption: "Unspecified",
      EmptyDataSourceError: "No records to display",
      GroupDropArea: "Drag a column header here to group by that column",
    },
  },
});

const filterSettings = {
  type: "CheckBox",
};

const sortSettings = {
  allowSorting: true,
};

const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  showDeleteConfirmDialog: true,
  allowDeleting: true,
  allowEditOnDblClick: false,
  mode: "Dialog",
  dialog: { cssClass: "custom-dialog" },
};

const sortOptions = [
  "By Date (Ascending Order)",
  "By Date (Descending Order)",
  "By Alphabetical (Ascending Order)",
  "By Alphabetical (Descending Order)",
];

const damageMapping = {
  "Minor Damage": ["No Damage", "Slight Damage"],
  "Regular Damage": ["Limited Damage"],
  "Major Damage": ["Severe Damage"],
  "Badly Damage": ["Total Destruction"],
};

const damageOptions = [
  "No Damage",
  "Slight Damage",
  "Limited Damage",
  "Severe Damage",
  "Total Destruction",
];

const CaseManagementCenter = () => {
  // const [selectedDisasters, setSelectedDisasters] = useState([]);
  const { disaster } = useDisasterObjContext();
  const selectedDisasters = useMemo(() => {
    if (disaster) {
      return [disaster?.id];
    }
    return [];
  }, [disaster]);
  const scrollRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disasterOptions, setDisasterOptions] = useState([]);
  const location = useLocation();
  const [activeDisasters, setActiveDisasters] = useState([]);
  const dropdownRef = useRef(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    openCases: false,
    closedCases: false,
  });
  const [ownershipStatuses, setOwnershipStatuses] = useState({
    Owner: false,
    Renter: false,
    "Rent to Own": false,
  });

  const [tempOwnershipStatuses, setTempOwnershipStatuses] = useState({
    Owner: false,
    Renter: false,
    "Rent to Own": false,
  });

  const [selectedDamages, setSelectedDamages] = useState(
    damageOptions.reduce(
      (acc, damage) => ({
        ...acc,
        [damage]: false,
      }),
      {}
    )
  );
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  // Update the temporary state as well
  const [tempSelectedDamages, setTempSelectedDamages] = useState(
    damageOptions.reduce(
      (acc, damage) => ({
        ...acc,
        [damage]: false,
      }),
      {}
    )
  );
  const [gridData, setGridData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [applyToFutureGroup, setApplyToFutureGroup] = useState(false);

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [disasterStats, setDisasterStats] = useState([]);
  const [assignmentType, setAssignmentType] = useState("Case Manager");

  const [partners, setPartners] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(null);
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGridData, setFilteredGridData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [openPanel, setOpenPanel] = useState(false);
  const [selectedDisaster, setSelectedDisaster] = useState(null);
  const [tempSelectedSort, setTempSelectedSort] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [tempSelectedDisasters, setTempSelectedDisasters] = useState(
    selectedDisasters || ""
  );
  const selectedUserId = sessionStorage.getItem("userId");
  const employeeDetails = JSON.parse(sessionStorage.getItem("employeeDetails"));
  const { toggleSidebar } = useSidebarContext();
  const { tab, subtab } = useParams();

  const [tempSelectedStatus, setTempSelectedStatus] = useState({
    Open: false,
    Closed: false,
    Unassigned: false,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedOption, setSelectedOption] = useState(null);
  const options = ["Newest to Oldest", "Oldest to Newest", "A-Z", "Z-A"];
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const [showDisaster, setShowDisaster] = useState(true);
  const [showHomeowner, setShowHomeowner] = useState(true);
  const [showDamage, setShowDamage] = useState(true);
  const [showStatus, setShowStatus] = useState(true);
  const [activePanel, setActivePanel] = useState("filter");
  const [damageFilters, setDamageFilters] = useState([]);
  const [selectedOwnershipStatus, setSelectedOwnershipStatus] = useState(null);
  // Add this useEffect for authentication
  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);
        console.log("Employee Details", employeeDetails);
      } else {
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);
  const [showForm, setShowForm] = useState(false);
  const handleAddCitizenClick = async () => {
    try {
      const centerDoc = await getDoc(doc(db, "centers", userData?.center_id));
      if (centerDoc.exists()) {
        const centerData = centerDoc.data();
        localStorage.setItem(
          "selectedDisasters",
          JSON.stringify(selectedDisasters)
        );
        const disasterName = disasterOptions.find(
          (disaster) => disaster.id === selectedDisasters[0]
        )?.name;
        localStorage.setItem("disasterName", disasterName);
        localStorage.setItem("centerState", centerData.state);
        localStorage.setItem("centerCounty", centerData.county);
        setShowForm(true); // Show form instead of navigating
      } else {
        console.error("Center data not found");
      }
    } catch (error) {
      console.error("Error fetching center data:", error);
    }
  };
  // Modify your existing fetchDisasters useEffect
  useEffect(() => {
    const fetchDisasters = async () => {
      try {
        // First get the current user's center
        const auth = getAuth();
        const userDoc = await getDoc(doc(db, "centers", userData?.center_id));
        const centerCounty = userDoc.data()?.county;

        if (!centerCounty) {
          console.warn("Center county not found");
          return;
        }

        const disastersQuery = query(collection(db, "declared-disaster"));
        const querySnapshot = await getDocs(disastersQuery);
        const fetchedDisasters = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            name: doc.data().disaster_name,
            status: doc.data().disaster_status,
            disaster_type: doc.data().disaster_type,
            county: doc.data().disaster_county,
            state: doc.data().disaster_state,
            affected_counties: doc.data().affected_counties || [],
          }))
          .filter((disaster) =>
            disaster.affected_counties.includes(centerCounty)
          );

        // Sort disasters by status: active first, then inactive, then closed
        const activeDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "active"
        );
        const inactiveDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "inactive"
        );
        const closedDisasters = fetchedDisasters.filter(
          (disaster) => disaster.status === "closed"
        );

        const sortedDisasters = [
          ...activeDisasters,
          ...inactiveDisasters,
          ...closedDisasters,
        ];

        setActiveDisasters(activeDisasters.map((disaster) => disaster.id));
        setDisasterOptions(sortedDisasters);
        if (activeDisasters.length > 0) {
          // setSelectedDisasters([activeDisasters[0].id]);
        }

        // Fetch statistics for the disasters
        await fetchDisasterStats(sortedDisasters);
      } catch (error) {
        console.error("Error fetching disasters:", error);
      }
    };

    fetchDisasters();
  }, [userData?.center_id]);

  const handleDialogClose = () => {
    setAssignDialogOpen(false);
    setSelectedEmployee(null);
    setSelectedCompany(null);
    setAssignmentType("Case Manager");
    setApplyToFutureGroup(false);
    setDropdownValue(null); // Reset dropdown value
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Add useEffect to fetch user data if you don't have it already
  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", selectedUserId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };

    fetchUserData();
  }, []);

  // Add handler for selection change
  const selectionHandler = (args) => {
    const gridInstance = gridRef.current;
    if (!gridInstance) return;

    // Get current selected records
    const selectedRecords = gridInstance.getSelectedRecords() || [];
    console.log("Selection changed:", selectedRecords);
    setSelectedRecords(selectedRecords);

    // Only handle group selection if this was triggered by a group checkbox
    if (args.target && args.target.classList.contains("group-checkbox")) {
      const groupField = gridInstance.groupSettings.columns[0];
      if (groupField) {
        const groupValue = args.data[groupField];

        // Get all records in the current group
        const allGroupRecords = gridInstance
          .getCurrentViewRecords()
          .filter((record) => record[groupField] === groupValue);

        // Check if all records in the group are selected
        const allSelected = allGroupRecords.every((record) =>
          selectedRecords.some((selected) => selected.id === record.id)
        );

        // Update group selection
        if (args.isInteracted) {
          if (!allSelected) {
            // Select all records in the group
            allGroupRecords.forEach((record) => {
              if (
                !selectedRecords.some((selected) => selected.id === record.id)
              ) {
                gridInstance.selectRows([
                  gridInstance.getRowIndexByPrimaryKey(record.id),
                ]);
              }
            });
          } else {
            // Deselect all records in the group
            allGroupRecords.forEach((record) => {
              gridInstance.selectRows(
                [gridInstance.getRowIndexByPrimaryKey(record.id)],
                false
              );
            });
          }
        }
      }
    }

    // Force refresh of group captions
    gridInstance.refreshHeader();
  };

  // Update the groupCaptionTemplate
  const captionRow = (data) => {
    return `${data.items[0][data.field]}` + " - " + data.count;
  };

  const assignAll = (data) => {
    // Get all items from the group
    const groupItems = data.items;

    // Create a summary object for the group
    const groupSummary = {
      isMultiple: true,
      cases: groupItems.map((item) => ({
        case_number: item.case_number,
        name: item.client_name || item.name, // Add fallback for name
        county: item.county,
        address: item.address,
        email: item.email,
        phone: item.phone || item.mobile_number, // Add fallback for phone
        status: item.status,
        assigned_to: item.assigned_to,
        assigned_company: item.assigned_company,
        disaster_id: item.disaster_id,
        disaster_name: item.disaster_name,
        citizen_id: item.citizen_id,
      })),
      name: `${data.count} cases from ${data.field}: ${
        data.items[0][data.field]
      }`,
      case_number: `Multiple (${data.count})`,
      county:
        data.field === "county"
          ? data.items[0][data.field]
          : "Multiple Counties",
    };

    // Debug logs
    console.log("Group Data:", data);
    console.log("Mapped Cases:", groupSummary.cases);

    // Set the selected case and open assign dialog
    setSelectedCase(groupSummary);
    setAssignDialogOpen(true);
  };

  const [selectedGroups, setSelectedGroups] = useState(new Map());

  // Update the groupCaptionTemplate function
  const groupCaptionTemplate = (data) => {
    const gridInstance = gridRef.current;
    if (!gridInstance) return null;

    // Get all records in this group
    const allGroupRecords = gridInstance
      .getCurrentViewRecords()
      .filter((record) => record[data.field] === data.items[0][data.field]);

    // Get currently selected records
    const selectedRecords = gridInstance.getSelectedRecords() || [];

    // Count how many records from this group are selected
    const selectedInGroup = selectedRecords.filter(
      (record) => record[data.field] === data.items[0][data.field]
    ).length;

    // Calculate selection states
    const isAllSelected =
      selectedInGroup === allGroupRecords.length && allGroupRecords.length > 0;
    const isPartiallySelected =
      selectedInGroup > 0 && selectedInGroup < allGroupRecords.length;

    return (
      <div className="flex w-full items-center justify-between px-4 py-2">
        <div className="flex items-center gap-2">
          {/* <input
          type="checkbox"
          checked={isAllSelected}
          ref={input => {
            if (input) {
              input.indeterminate = isPartiallySelected;
            }
          }}
          onChange={(e) => {
            e.stopPropagation();
            toggleGroupSelection(e, data);
          }}
          className="form-checkbox h-4 w-4 text-blue-600 rounded group-checkbox"
        /> */}
          <span className="text-gray-700">{captionRow(data)}</span>
        </div>
        <div className="flex gap-2">
          <button
            className="rounded-full bg-green-500 px-3 py-1 text-xs font-medium text-white hover:bg-green-600"
            onClick={(e) => {
              e.stopPropagation();
              handleGroupSelection(data);
            }}
          >
            Assign Selected {selectedInGroup > 0 ? `(${selectedInGroup})` : ""}
          </button>
          <button
            className="rounded-full bg-blue-500 px-3 py-1 text-xs font-medium text-white hover:bg-blue-600"
            onClick={(e) => {
              e.stopPropagation();
              assignAll(data);
            }}
          >
            Assign All ({data.count})
          </button>
        </div>
      </div>
    );
  };

  // Add this effect to refresh the grid when selection changes
  useEffect(() => {
    const gridInstance = gridRef.current;
    if (gridInstance) {
      gridInstance.refresh();
    }
  }, [selectedRecords]);

  // Update the handleGroupSelection function
  const handleGroupSelection = (data) => {
    const selectedRecords = gridRef.current?.getSelectedRecords() || [];
    const groupRecords = selectedRecords.filter(
      (record) => record[data.field] === data.items[0][data.field]
    );

    if (groupRecords.length === 0) {
      console.log("No records selected in this group");
      return;
    }

    // Create a summary object for the selected records
    const groupSummary = {
      isMultiple: true,
      cases: groupRecords.map((record) => ({
        case_number: record.case_number,
        name: record.name || record.client_name,
        county: record.county,
        address: record.address,
        email: record.email,
        phone: record.phone || record.mobile_number,
        status: record.status,
        assigned_to: record.assigned_to,
        assigned_company: record.assigned_company,
        disaster_id: record.disaster_id,
        disaster_name: record.disaster_name,
        citizen_id: record.citizen_id,
      })),
      groupInfo: {
        field: data.field,
        value: data.items[0][data.field],
        displayValue: `${data.field}: ${data.items[0][data.field]}`,
      },
      name: `${groupRecords.length} selected cases from ${data.field}: ${
        data.items[0][data.field]
      }`,
      case_number: `Multiple (${groupRecords.length})`,
      county:
        data.field === "county"
          ? data.items[0][data.field]
          : "Multiple Counties",
    };

    setSelectedCase(groupSummary);
    setAssignDialogOpen(true);
  };

  // Add cleanup for selectedGroups when dialog closes
  useEffect(() => {
    if (!assignDialogOpen) {
      setSelectedGroups(new Map());
    }
  }, [assignDialogOpen]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        if (!userData?.center_id) {
          console.log("No center ID available");
          return;
        }

        const employeesRef = collection(
          db,
          "centers",
          userData.center_id,
          "employees"
        );
        const employeesSnapshot = await getDocs(employeesRef);
        const employeesData = employeesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          fullName: `${doc.data().first_name} ${doc.data().last_name}`,
        }));

        setEmployees(employeesData);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [userData?.center_id]);

  const [caseManagerCaseCounts, setCaseManagerCaseCounts] = useState({});

  // Add this useEffect to fetch case counts
  useEffect(() => {
    const fetchCaseManagerCaseCounts = async () => {
      if (!userData?.center_id || !employees.length) return;

      try {
        const caseManagementRef = collection(db, "case_management");
        const caseCounts = {};

        // Fetch cases for all employees in parallel
        await Promise.all(
          employees.map(async (employee) => {
            const caseQuery = query(
              caseManagementRef,
              where("center_id", "==", userData.center_id),
              where("assigned_to_case_manager_uid", "==", employee.id)
            );

            const querySnapshot = await getDocs(caseQuery);
            caseCounts[employee.id] = querySnapshot.size;
          })
        );

        setCaseManagerCaseCounts(caseCounts);
      } catch (error) {
        console.error("Error fetching case manager case counts:", error);
      }
    };

    fetchCaseManagerCaseCounts();
  }, [userData?.center_id, employees]);

  const fetchDisasterStats = async (disasters) => {
    try {
      if (!disasters || disasters.length === 0) {
        console.log("No disasters provided to fetch stats");
        return [];
      }
      const stats = await Promise.all(
        disasters.map(async (disaster) => {
          // Get all cases from case_management for this disaster
          const casesQuery = query(
            collection(db, "case_management"),
            where("disaster_id", "==", disaster.id),
            where("center_id", "==", userData.center_id)
          );
          const casesSnapshot = await getDocs(casesQuery);

          // Get all survey responses that have this disaster
          const surveyResponses = new Map(); // Use Map to store case details
          const usersRef = collection(db, "users");
          const usersQuery = query(
            usersRef,
            where("center_id", "==", userData.center_id),
            where("user_role", "==", "citizen")
          );
          const usersSnapshot = await getDocs(usersQuery);

          // Collect all survey responses with case numbers
          for (const userDoc of usersSnapshot.docs) {
            const surveyQuery = query(
              collection(db, `users/${userDoc.id}/survey_responses`)
            );
            const surveySnapshot = await getDocs(surveyQuery);

            surveySnapshot.docs.forEach((doc) => {
              const surveyData = doc.data();
              if (
                surveyData.disasters?.some(
                  (d) => d.disaster_id === disaster.id
                ) &&
                surveyData.case_number
              ) {
                surveyResponses.set(surveyData.case_number, {
                  case_number: surveyData.case_number,
                  client_name: `${userDoc.data().first_name} ${
                    userDoc.data().last_name
                  }`,
                  client_id: userDoc.id,
                  email: userDoc.data().email,
                  phone: userDoc.data().mobile_number,
                  address: `${userDoc.data().address1}${
                    userDoc.data().address2
                      ? `, ${userDoc.data().address2}`
                      : ""
                  }, ${userDoc.data().city}, ${userDoc.data().state} ${
                    userDoc.data().zip
                  }`,
                  survey_date: surveyData.created_at || surveyData.timestamp,
                  status: "Unassigned",
                  assigned_to: "",
                  county: userDoc.data().county || "Unspecified", // Add county from user data
                });
              }
            });
          }

          // Update status for cases that exist in case_management
          casesSnapshot.docs.forEach((doc) => {
            const caseData = doc.data();
            if (surveyResponses.has(caseData.case_number)) {
              const existingCase = surveyResponses.get(caseData.case_number);
              surveyResponses.set(caseData.case_number, {
                ...existingCase,
                status: caseData.status || "Open",
                assigned_to: caseData.assigned_to || "",
                assigned_company: caseData.assigned_company || "",
                assignment_date: caseData.assignment_date || "",
              });
            }
          });

          const casesByCounty = new Map();
          surveyResponses.forEach((caseItem) => {
            const county = caseItem.county || "Unspecified";
            if (!casesByCounty.has(county)) {
              casesByCounty.set(county, {
                county: county,
                disaster_name: disaster.name,
                disaster_id: disaster.id,
                status: disaster.status,
                cases: [],
                open_cases: 0,
                unassigned_cases: 0,
                total_cases: 0,
              });
            }

            const countyStats = casesByCounty.get(county);
            countyStats.cases.push(caseItem);
            countyStats.total_cases++;
            if (caseItem.status === "Open") countyStats.open_cases++;
            if (caseItem.status === "Unassigned")
              countyStats.unassigned_cases++;
          });

          // Calculate overall disaster statistics
          const allCases = Array.from(surveyResponses.values());
          const partnerStats = new Map();
          allCases.forEach((caseItem) => {
            if (caseItem.assigned_company) {
              if (!partnerStats.has(caseItem.assigned_company)) {
                partnerStats.set(caseItem.assigned_company, {
                  company: caseItem.assigned_company,
                  assigned_cases: 0,
                });
              }
              partnerStats.get(caseItem.assigned_company).assigned_cases++;
            }
          });

          return {
            disaster_name: disaster.name,
            disaster_id: disaster.id,
            status: disaster.status,
            counties: Array.from(casesByCounty.values()),
            open_cases: allCases.filter((c) => c.status === "Open").length,
            unassigned_cases: allCases.filter((c) => c.status === "Unassigned")
              .length,
            total_cases: surveyResponses.size,
            partners: Array.from(partnerStats.values()),
          };
        })
      );

      const validStats = stats.filter((stat) => stat !== null);
      setDisasterStats(validStats);
      return validStats;
    } catch (error) {
      console.error("Error fetching disaster statistics:", error);
      return [];
    }
  };

  // Add function to fetch partners
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        if (!userData?.center_id) {
          console.log("No center ID available");
          return;
        }

        // First get partners from users collection that are linked to this center
        const usersRef = collection(db, "users");
        const usersQuery = query(
          usersRef,
          where("center_id", "array-contains", userData.center_id),
          where("user_role", "==", "partner")
        );
        const usersSnapshot = await getDocs(usersQuery);

        // Get all partner_ids from matching users
        const partnerIds = usersSnapshot.docs
          .map((doc) => doc.data().partner_id)
          .filter((id) => id); // Remove any undefined/null values

        if (partnerIds.length === 0) {
          console.log("No partners found for this center");
          setPartners([]);
          return;
        }

        // Fetch partner details from partners collection and filter for Case Management service
        const partnersList = await Promise.all(
          partnerIds.map(async (partnerId) => {
            const partnerDoc = await getDoc(doc(db, "partners", partnerId));
            if (partnerDoc.exists()) {
              const partnerData = partnerDoc.data();
              // Only include partners that have Case Management in their servicesProvided
              if (partnerData.servicesProvided?.includes("Case Management")) {
                return {
                  id: partnerDoc.id,
                  name: partnerData.name_of_org,
                  ...partnerData,
                };
              }
            }
            return null;
          })
        );

        // Filter out any null values and set partners
        const validPartners = partnersList.filter(
          (partner) => partner !== null && partner.is_certified
        );
        console.log(
          `Found ${validPartners.length} partners with Case Management service`
        );
        setPartners(validPartners);
      } catch (error) {
        console.error("Error fetching partners:", error);
        setPartners([]);
      }
    };

    fetchPartners();
  }, [userData?.center_id]);

  const mapCitizenData = async (citizens) => {
    // Batch fetch all survey responses and impacted addresses at once
    const batchPromises = citizens.map((citizen) => ({
      id: citizen.id,
      surveyPromise: getDocs(
        query(collection(db, `users/${citizen.id}/survey_responses`))
      ),
      addressPromise: getDocs(
        query(collection(db, `users/${citizen.id}/impacted-addresses`))
      ),
      userPromise: getDoc(doc(db, "users", citizen.id)),
    }));

    // Execute all promises in parallel
    const results = await Promise.all(
      batchPromises.map(
        async ({ id, surveyPromise, addressPromise, userPromise }) => {
          const [surveySnapshot, addressSnapshot, userDoc] = await Promise.all([
            surveyPromise,
            addressPromise,
            userPromise,
          ]);

          // Get household_id from user document
          const userData = userDoc.data();
          const householdId = userData?.household_id;

          // Fetch household details if household_id exists
          let ownershipStatus = "Not Specified";
          if (householdId) {
            try {
              const householdDetailsDoc = await getDoc(
                doc(db, "household", householdId, "household_info", "details")
              );
              const householdData = householdDetailsDoc.data();
              ownershipStatus =
                householdData?.owner_or_renter || "Not Specified";
            } catch (error) {
              console.error("Error fetching household details:", error);
            }
          }

          // Create maps for faster lookups (existing code)
          const impactedAddresses = new Map();
          addressSnapshot.forEach((doc) => {
            const addressData = doc.data();
            if (addressData.disaster_id) {
              impactedAddresses.set(addressData.disaster_id, addressData);
            }
          });

          const caseGroups = new Map();
          const damageImpacts = new Map();
          surveySnapshot.forEach((doc) => {
            const surveyData = doc.data();
            if (surveyData.disasters && surveyData.case_number) {
              caseGroups.set(surveyData.case_number, surveyData.disasters);

              // Check for damage impact in responses and extract just the answer value
              if (surveyData.responses) {
                const damageResponse =
                  surveyData.responses[
                    "What level of damage did your home sustain?"
                  ];
                if (damageResponse && damageResponse.answer) {
                  damageImpacts.set(
                    surveyData.case_number,
                    damageResponse.answer
                  );
                }
              }
            }
          });

          return {
            id,
            impactedAddresses,
            caseGroups,
            ownershipStatus,
            damageImpacts,
          };
        }
      )
    );

    // Batch fetch all case management data at once
    const caseNumbers = results.flatMap(({ caseGroups }) =>
      Array.from(caseGroups.keys())
    );
    const caseManagementQuery = query(
      collection(db, "case_management"),
      where("case_number", "in", caseNumbers)
    );
    const caseManagementSnapshot = await getDocs(caseManagementQuery);
    const caseManagementMap = new Map();
    caseManagementSnapshot.forEach((doc) => {
      const data = doc.data();
      caseManagementMap.set(data.case_number, data);
    });

    // Map the final data
    const mappedCitizens = [];
    results.forEach(
      ({
        id,
        impactedAddresses,
        caseGroups,
        ownershipStatus,
        damageImpacts,
      }) => {
        const citizen = citizens.find((c) => c.id === id);

        for (const [caseNumber, disasters] of caseGroups) {
          const damageImpact = damageImpacts.get(caseNumber);
          const caseManagementData = caseManagementMap.get(caseNumber) || {};
          const disasterId = disasters[0].disaster_id;
          const impactedAddress = impactedAddresses.get(disasterId);

          mappedCitizens.push({
            ownership_status: ownershipStatus,
            id,
            name: impactedAddress
              ? `${impactedAddress.first_name} ${impactedAddress.last_name}`
              : `${citizen.first_name} ${citizen.last_name}`,
            address: impactedAddress
              ? `${impactedAddress.address1}${
                  impactedAddress.address2
                    ? `, ${impactedAddress.address2}`
                    : ""
                }, ${impactedAddress.city}, ${impactedAddress.state}, ${
                  impactedAddress.zip
                }`
              : `${citizen.address1}${
                  citizen.address2 ? `, ${citizen.address2}` : ""
                }, ${citizen.city}, ${citizen.state}, ${citizen.zip}`,
            mobile_number: impactedAddress?.phone || citizen.mobile_number,
            email: impactedAddress?.email || citizen.email,
            county: impactedAddress?.county || citizen.county || "Unspecified",
            state: impactedAddress?.state || citizen.state || "Unspecified",

            // Case and disaster info
            citizen_id: citizen.citizen_id,
            case_number: caseNumber,
            disaster_id: disasters[0].disaster_id,
            disaster_name: disasters.map((d) => d.disaster_name).join(", "),

            // Case management info
            assigned_to: caseManagementData.assigned_to_case_manager || "",
            assigned_to_uid:
              caseManagementData.assigned_to_case_manager_uid || "",
            assigned_to_case_manager:
              caseManagementData.assigned_to_case_manager || "",
            assigned_to_case_manager_uid:
              caseManagementData.assigned_to_case_manager_uid || "",
            assigned_to_org: caseManagementData.assigned_to_org || "",
            assigned_to_org_uid: caseManagementData.assigned_to_org_uid || "",
            assignment_type: caseManagementData.assignment_type || "",
            assignment_date: caseManagementData.assignment_date || "",
            status: caseManagementData.status || "Open",
            status_date:
              caseManagementData.updated_at ||
              caseManagementData.assignment_date ||
              "",

            // Add the full impacted address object for reference if needed
            impacted_address: impactedAddress || null,
            damage_impact: damageImpact || "Not Assessed",
          });
        }
      }
    );
    console.log(
      "Final Mapped Citizens Data:",
      mappedCitizens.map((citizen) => ({
        caseNumber: citizen.case_number,
        ownershipStatus: citizen.ownership_status,
        damageImpact: citizen.damage_impact,
      }))
    );
    return mappedCitizens;
  };
  const [originalGridData, setOriginalGridData] = useState([]);
  const fetchCitizens = async () => {
    try {
      setIsLoading(true);
      const currentUserId = employeeDetails?.id || selectedUserId;

      if (!userData?.center_id || !currentUserId) {
        setGridData([]);
        setIsLoading(false);
        return;
      }

      console.log("Current User ID:", employeeDetails?.id);
      console.log("Center id:", userData.center_id);

      // Get employee data
      const empData = employeeDetails;

      let caseQuery;
      console.log("currentUserId", currentUserId);

      // Determine query based on role
      if (empData.is_admin || empData.role === "Case Supervisor") {
        // Admin or Supervisor - fetch all cases for the center
        caseQuery = query(
          collection(db, "case_management"),
          where("center_id", "==", userData.center_id)
        );
      } else if (empData.role === "Case Manager") {
        // Case Manager - fetch only assigned cases
        caseQuery = query(
          collection(db, "case_management"),
          where("center_id", "==", userData.center_id),
          where("assigned_to_case_manager_uid", "==", currentUserId)
        );
      } else {
        setGridData([]);
        setIsLoading(false);
        return;
      }

      // Execute the query
      const caseSnapshot = await getDocs(caseQuery);
      console.log("Found cases:", caseSnapshot.size);

      // Get unique citizen IDs and store case data
      const citizenIds = new Set();
      const caseDataMap = new Map();

      caseSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.citizen_id) {
          citizenIds.add(data.citizen_id);
          caseDataMap.set(data.citizen_id, {
            ...data,
            case_management_id: doc.id, // Store the case management document ID
          });
        }
      });

      // Batch fetch all citizens
      const citizenPromises = Array.from(citizenIds).map((id) =>
        getDoc(doc(db, "users", id))
      );

      const citizenDocs = await Promise.all(citizenPromises);

      // Combine citizen and case data
      const citizens = citizenDocs
        .filter((doc) => doc.exists())
        .map((doc) => {
          const citizenData = doc.data();
          const caseData = caseDataMap.get(doc.id);
          return {
            id: doc.id,
            ...citizenData,
            ...caseData,
            // Ensure critical fields are present
            case_number: caseData?.case_number || "N/A",
            status: caseData?.status || "Unassigned",
            assigned_to_case_manager:
              caseData?.assigned_to_case_manager || "None",
            assigned_to_case_manager_uid:
              caseData?.assigned_to_case_manager_uid || "",
            disaster_id: caseData?.disaster_id || "",
            disaster_name: caseData?.disaster_name || "",
          };
        });

      // Process and map citizen data
      const formattedData = await mapCitizenData(citizens);

      // Filter by selected disasters if any

      // Additional filtering based on role and status
      const disasterFilteredData = selectedDisasters.includes("All")
        ? formattedData
        : formattedData.filter((citizen) =>
            selectedDisasters.includes(citizen.disaster_id)
          );

      // Store the disaster-filtered data as our original data
      setOriginalGridData(disasterFilteredData);

      // Apply any existing filters
      const anyOwnershipSelected = Object.values(ownershipStatuses).some(
        (value) => value
      );
      const anyDamageSelected = Object.values(selectedDamages).some(
        (value) => value
      );
      const anyStatusSelected = Object.values(selectedStatus).some(
        (value) => value
      );

      let filteredData = disasterFilteredData;
      if (anyOwnershipSelected || anyDamageSelected || anyStatusSelected) {
        filteredData = disasterFilteredData.filter((item) => {
          const ownershipMatch =
            !anyOwnershipSelected ||
            Object.entries(ownershipStatuses).some(([status, isSelected]) => {
              if (!isSelected) return false;
              return item.ownership_status === status;
            });

          const damageMatch =
            !anyDamageSelected ||
            Object.entries(selectedDamages).some(([damage, isSelected]) => {
              if (!isSelected) return false;
              return item.damage_impact === damage;
            });

          const statusMatch =
            !anyStatusSelected ||
            (selectedStatus.Open && item.status === "Open") ||
            (selectedStatus.Closed && item.status === "Closed") ||
            (selectedStatus.Unassigned &&
              (!item.status || item.status === "Unassigned"));

          return ownershipMatch && damageMatch && statusMatch;
        });
      }

      // Additional filtering based on role and status
      let finalData = filteredData;
      if (empData.role === "Case Manager") {
        // Case managers only see their assigned cases
        finalData = filteredData.filter(
          (case_) => case_.assigned_to_case_manager_uid === currentUserId
        );
      }

      console.log("Final filtered data:", finalData);
      setGridData(finalData);
    } catch (error) {
      console.error("Error in fetchCitizens:", error);
      setGridData([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCitizens();
  }, [userData?.center_id, selectedDisasters]);

  // Add this effect to handle initial disaster selection if needed
  useEffect(() => {
    if (
      disasterOptions.length > 0 &&
      (!selectedDisasters || selectedDisasters.length === 0)
    ) {
      const activeDisasters = disasterOptions.filter(
        (d) => d.status === "active"
      );
      if (activeDisasters.length > 0) {
        // setSelectedDisasters([activeDisasters[0].id]);
      } else {
        // setSelectedDisasters([disasterOptions[0].id]);
      }
    }
  }, [disasterOptions]);

  const handleCommandClick = async (args) => {
    if (args.commandColumn.type === "View") {
      try {
        if (!userId) {
          console.error("User ID is not set. Cannot update document.");
          return;
        }

        const rowData = args.rowData;

        if (!rowData) {
          return;
        }

        const citizen_id = rowData.id;
        const case_number = rowData.case_number;
        const disaster_id = rowData.disaster_id;

        // Add validation for required fields
        if (!citizen_id || !case_number || !disaster_id) {
          return;
        }
        const userDocRef = doc(db, "users", userId);

        // Store both the accessed_userId and the case number
        await updateDoc(userDocRef, {
          accessed_userId: citizen_id,
          accessed_case_number: case_number,
          accessed_disaster_id: disaster_id,
        });

        // Store the accessedUserId in sessionStorage
        sessionStorage.setItem("accessedUserId", citizen_id);
        const lastVisitedSubTab = sessionStorage.getItem("lastVisitedSubTab");

        const urlQueryParams = new URLSearchParams();
        urlQueryParams.set("tab", 2);

        navigate(
          `/cencasemanagement/survivors/${
            lastVisitedSubTab || "irs"
          }/?${urlQueryParams.toString()}`
        );
      } catch (error) {
        console.error("Error in handleCommandClick: ", error);
      }
    } else if (args.commandColumn.type === "Assign") {
      setSelectedCase(args.rowData);

      // Check assignment type and set appropriate value
      if (args.rowData.assignment_type === "Case Manager") {
        setDropdownValue(
          employees.find(
            (emp) => emp.id === args.rowData.assigned_to_case_manager_uid
          )?.id
        );
        setAssignmentType("Case Manager");
      } else if (args.rowData.assignment_type === "Partner") {
        setDropdownValue(
          partners.find(
            (partner) => partner.name_of_org === args.rowData.assigned_to_org
          )?.id
        );
        setAssignmentType("Partner");
      } else {
        setDropdownValue(null);
        setAssignmentType("Case Manager");
      }
      setAssignDialogOpen(true);
    } else if (args.commandColumn.type === "Access") {
      // Handle Access Account functionality
      const citizenData = args.rowData;
      // Store necessary information in localStorage
      localStorage.setItem("accessAccountEmail", citizenData.email);
      localStorage.setItem("orgUserId", userId);
      localStorage.setItem("orgType", "center");

      // Open in new tab
      window.open(window.location.origin + "/accessaccount", "_blank");
    }
  };

  const handleAssign = async () => {
    try {
      // Validate assignment selections (only if not unassigning)
      if (assignmentType !== "Unassign") {
        if (assignmentType === "Case Manager" && !selectedEmployee) {
          console.error("No case manager selected");
          return;
        }
        if (assignmentType === "Partner" && !selectedCompany) {
          console.error("No partner selected");
          return;
        }
      }

      // Get cases to update
      const casesToUpdate = selectedCase.isMultiple
        ? selectedCase.cases
        : [selectedCase];

      // Add data validation and transformation
      const processedCases = casesToUpdate
        .map((caseData) => {
          // Extract case number from the data object
          const caseNumber = caseData.case_number || caseData.id;
          // Extract citizen ID, checking multiple possible locations
          const citizenId = caseData.citizen_id || caseData.id;

          if (!caseNumber || !citizenId) {
            console.error("Missing required fields:", caseData);
            return null;
          }

          return {
            ...caseData,
            case_number: caseNumber,
            citizen_id: citizenId,
            name: caseData.name || caseData.citizen_name || "",
            address: caseData.address || "",
            email: caseData.email || "",
            phone: caseData.phone || caseData.mobile_number || "",
          };
        })
        .filter(Boolean); // Remove any null entries

      if (processedCases.length === 0) {
        throw new Error("No valid cases to update");
      }

      let centerName = "";
      if (assignmentType === "Case Manager" && userData?.center_id) {
        const centerDoc = await getDoc(doc(db, "centers", userData.center_id));
        if (centerDoc.exists()) {
          centerName = centerDoc.data().name_of_hub || "";
        }
      }

      // Create base update data
      const baseUpdateData =
        assignmentType === "Unassign"
          ? {
              // Clear all assignment fields with empty strings
              assignment_type: "",
              assigned_to_org: "",
              assigned_to_org_uid: "",
              assigned_to_case_manager: "",
              assigned_to_case_manager_uid: "",
              partner_id: "",
              assignment_date: "",
              status: "Unassigned",
              updated_at: new Date().toISOString(),
            }
          : {
              // Assignment type
              assignment_type: assignmentType || "",

              // Organization assignment
              assigned_to_org:
                assignmentType === "Case Manager"
                  ? centerName
                  : selectedCompany?.name_of_org || "",
              assigned_to_org_uid:
                assignmentType === "Case Manager"
                  ? userData?.center_id || ""
                  : selectedCompany?.id || "",

              // Rest of the fields remain the same...
              assigned_to_case_manager:
                assignmentType === "Case Manager"
                  ? `${selectedEmployee?.first_name || ""} ${
                      selectedEmployee?.last_name || ""
                    }`.trim() || ""
                  : "",
              assigned_to_case_manager_uid:
                assignmentType === "Case Manager"
                  ? selectedEmployee?.id || ""
                  : "",

              // Partner specific data
              partner_id:
                assignmentType === "Partner" ? selectedCompany?.id || "" : "",

              // Assignment metadata
              assignment_date: new Date().toISOString(),

              // Center details
              center_id: userData?.center_id || "",

              // Status
              status:
                typeof selectedStatus === "object"
                  ? "Open"
                  : selectedStatus || "Open",
              updated_at: new Date().toISOString(),
            };

      // Update the promises section
      const updatePromises = processedCases.map(async (caseData) => {
        const updateData = {
          ...baseUpdateData,
          case_number: caseData.case_number,
          citizen_id: caseData.citizen_id,
          citizen_name: caseData.name,
          citizen_address: caseData.address,
          citizen_email: caseData.email,
          citizen_mobile: caseData.phone,
          disaster_id: caseData.disaster_id || "",
          disaster_name: caseData.disaster_name || "",
          status:
            typeof baseUpdateData.status === "object"
              ? "Open"
              : baseUpdateData.status,
        };

        try {
          const caseManagementRef = collection(db, "case_management");
          const q = query(
            caseManagementRef,
            where("case_number", "==", caseData.case_number),
            where("citizen_id", "==", caseData.citizen_id)
          );
          const querySnapshot = await getDocs(q);

          if (querySnapshot.empty) {
            const docRef = await addDoc(caseManagementRef, {
              ...updateData,
              created_at: new Date().toISOString(),
            });
            return { success: true, case: caseData, id: docRef.id };
          } else {
            const existingDoc = querySnapshot.docs[0];
            await updateDoc(
              doc(db, "case_management", existingDoc.id),
              updateData
            );
            return { success: true, case: caseData, id: existingDoc.id };
          }
        } catch (error) {
          console.error(`Error processing case:`, error, caseData);
          return { success: false, case: caseData, error: error.message };
        }
      });

      // Wait for all updates to complete
      const results = await Promise.all(updatePromises);

      // Reset states
      setAssignDialogOpen(false);
      setSelectedEmployee(null);
      setSelectedCompany(null);
      setSelectedSupervisor(null);
      setSelectedStatus("Open");
      setAssignmentType("Case Manager");
      setSelectedRecords([]);
      await fetchCitizens();
      // Refresh the grid data
      // setSelectedDisasters([...selectedDisasters]);

      // Show success message
      console.log(
        `Successfully assigned ${casesToUpdate.length} case${
          casesToUpdate.length > 1 ? "s" : ""
        }`
      );

      // If checkbox is checked, store the group assignment preference
      if (applyToFutureGroup && selectedCase?.groupInfo) {
        const groupAssignment = {
          field: selectedCase.groupInfo.field,
          value: selectedCase.groupInfo.value,
          assignee:
            assignmentType === "Case Manager"
              ? {
                  id: selectedEmployee.id,
                  name: selectedEmployee.fullName,
                  type: "Case Manager",
                }
              : {
                  id: selectedCompany.id,
                  name: selectedCompany.name_of_org,
                  type: "Partner",
                },
          created_at: new Date().toISOString(),
        };

        // Store in Firestore
        await addDoc(collection(db, "group_assignments"), groupAssignment);
      }

      setApplyToFutureGroup(false);
    } catch (error) {
      console.error("Error assigning cases:", error);
      throw error;
    }
  };

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [activeFilterTab, setActiveFilterTab] = useState("filter");
  const [filterCriteria, setFilterCriteria] = useState({
    status: {
      available: [], // all available options
      selected: [], // selected options
    },
    county: {
      available: [],
      selected: [],
    },
    assigned_to: {
      available: [],
      selected: [],
    },
    disaster_id: {
      available: [],
      selected: [],
    },
  });

  // Add this after the other useEffect hooks
  useEffect(() => {
    // Initialize filter options from grid data
    if (gridData.length > 0) {
      const uniqueStatuses = [
        ...new Set(gridData.map((item) => item.status)),
      ].filter(Boolean);
      const uniqueCounties = [
        ...new Set(gridData.map((item) => item.county)),
      ].filter(Boolean);
      const uniqueAssignees = [
        ...new Set(gridData.map((item) => item.assigned_to_case_manager)),
      ].filter(Boolean);
      const uniqueDisasters = [
        ...new Set(gridData.map((item) => item.disaster_name)),
      ].filter(Boolean);

      setFilterCriteria((prev) => ({
        status: {
          available: uniqueStatuses,
          selected: prev.status.selected,
        },
        county: {
          available: uniqueCounties,
          selected: prev.county.selected,
        },
        assigned_to: {
          available: uniqueAssignees,
          selected: prev.assigned_to.selected,
        },
        disaster_id: {
          available: uniqueDisasters,
          selected: prev.disaster_id.selected,
        },
      }));
    }
  }, [gridData]);

  // Add this function to handle applying filters
  const handleApplyFilters = () => {
    console.log("Filter Values:", {
      // tempSelectedDisasters,
      // selectedDisaster,
      // selectedDisasters,
      tempOwnershipStatuses,
      tempSelectedDamages,
      tempSelectedSort, // Add this
      gridDataLength: originalGridData.length,
    });

    // Handle disaster selection
    // if (tempSelectedDisasters) {
    //   console.log("Setting new disaster:", tempSelectedDisasters);
    //   setSelectedDisaster(tempSelectedDisasters);
    //   setSelectedDisasters(tempSelectedDisasters);
    // }

    // Update the real states with temporary values
    setOwnershipStatuses(tempOwnershipStatuses);
    setSelectedDamages(tempSelectedDamages);
    setSelectedStatus(tempSelectedStatus);
    setSelectedSort(tempSelectedSort); // Add this

    const anyOwnershipSelected = Object.values(tempOwnershipStatuses).some(
      (value) => value
    );
    const anyDamageSelected = Object.values(tempSelectedDamages).some(
      (value) => value
    );
    const anyStatusSelected = Object.values(tempSelectedStatus).some(
      (value) => value
    );

    // First apply filters
    let resultData;
    if (!anyOwnershipSelected && !anyDamageSelected && !anyStatusSelected) {
      resultData = [...originalGridData]; // Create a copy to avoid mutating original
    } else {
      // Filter the data
      resultData = originalGridData.filter((item) => {
        const ownershipMatch =
          !anyOwnershipSelected ||
          Object.entries(tempOwnershipStatuses).some(([status, isSelected]) => {
            if (!isSelected) return false;
            return item.ownership_status === status;
          });

        const damageMatch =
          !anyDamageSelected ||
          Object.entries(tempSelectedDamages).some(([damage, isSelected]) => {
            if (!isSelected) return false;
            return item.damage_impact === damage;
          });

        const statusMatch =
          !anyStatusSelected ||
          (tempSelectedStatus.Open && item.status === "Open") ||
          (tempSelectedStatus.Closed && item.status === "Closed") ||
          (tempSelectedStatus.Unassigned &&
            (!item.status || item.status === "Unassigned"));

        return ownershipMatch && damageMatch && statusMatch;
      });
    }

    // Then apply sorting if selected
    if (tempSelectedSort) {
      resultData.sort((a, b) => {
        switch (tempSelectedSort) {
          case "By Date (Ascending Order)":
            return new Date(a.status_date) - new Date(b.status_date);
          case "By Date (Descending Order)":
            return new Date(b.status_date) - new Date(a.status_date);
          case "By Alphabetical (Ascending Order)":
            return a.name.localeCompare(b.name);
          case "By Alphabetical (Descending Order)":
            return b.name.localeCompare(a.name);
          default:
            return 0;
        }
      });
    }

    setGridData(resultData);
    setOpenPanel(null);
    setFilterDialogOpen(false);
  };
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("newest");
  const handleSelectAll = (field) => {
    setFilterCriteria((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        selected: [...prev[field].available],
      },
    }));
  };
  const handleClearAll = (field) => {
    setFilterCriteria((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        selected: [],
      },
    }));
  };

  const gridRefSearch = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchFilters, setSearchFilters] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "", // Updated name
  });

  const [allLocalUsers, setAllLocalUsers] = useState([]); // Store all local users
  const [searchedCitizenData, setSearchedCitizenData] = useState([]); // Store filtered results
  const [hasSearched, setHasSearched] = useState(false); // New state to track if search was performed

  const sessionAccessedUserId = sessionStorage.getItem("accessedUserId");

  // console.log("selected", selectedDisasters);

  const handleAddToCaseManagement = async (citizenData) => {
    try {
      // Generate case number using existing function
      const newCaseNumber = await dataService.generateCaseNumber();

      // 1. Create case management entry
      const caseManagementRef = collection(db, "case_management");
      const caseData = {
        case_number: newCaseNumber,
        citizen_id: citizenData.id,
        citizen_name: `${citizenData.first_name} ${citizenData.last_name}`,
        citizen_email: citizenData.email,
        citizen_address: citizenData.address || "",
        citizen_mobile: citizenData.mobile_number || "",
        disaster_id: selectedDisasters[0],
        disaster_name:
          disasterOptions.find((d) => d.id === selectedDisasters[0])?.name ||
          "",
        center_id: userData?.center_id,
        status: "Unassigned",
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      };

      await addDoc(caseManagementRef, caseData);

      // 2. Create survey response entry (without actual survey responses)
      const surveyData = {
        user_id: citizenData.id,
        disasters: [
          {
            disaster_id: selectedDisasters[0],
            disaster_name:
              disasterOptions.find((d) => d.id === selectedDisasters[0])
                ?.name || "",
            disaster_type:
              disasterOptions.find((d) => d.id === selectedDisasters[0])
                ?.disaster_type || "",
          },
        ],
        survey_id: uuidv4(),
        case_number: newCaseNumber,
        submitted_at: serverTimestamp(),
        responses: {}, // Empty responses object
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      };

      const docRef = doc(
        db,
        `users/${citizenData.id}/survey_responses`,
        surveyData.survey_id
      );

      await setDoc(docRef, surveyData);

      // Close the modal
      closeModal();
      await fetchCitizens();
    } catch (error) {
      console.error("Error adding to case management:", error);
      alert(`Error adding to case management: ${error.message}`);
    }
  };
  // Function to fetch users from the same state/county as the center
  const fetchLocalUsers = async () => {
    try {
      if (!userData?.center_id) {
        console.log("No center ID available");
        return;
      }

      // First get the center's state and county
      const centerDoc = await getDoc(doc(db, "centers", userData.center_id));
      if (!centerDoc.exists()) {
        console.log("Center not found");
        return;
      }

      const centerData = centerDoc.data();
      const centerState = centerData.state;
      const centerCounty = centerData.county;

      // Query users collection for matching state/county
      const usersRef = collection(db, "users");
      const usersQuery = query(
        usersRef,
        where("user_role", "==", "citizen"),
        where("state", "==", centerState),
        where("county", "==", centerCounty)
      );

      const usersSnapshot = await getDocs(usersQuery);

      // Get all case management entries for the selected disaster
      const caseManagementRef = collection(db, "case_management");
      const caseManagementQuery = query(
        caseManagementRef,
        where("disaster_id", "==", selectedDisasters[0])
      );
      const caseManagementSnapshot = await getDocs(caseManagementQuery);

      // Create a Set of citizen IDs who already have cases for this disaster
      const existingCaseIds = new Set(
        caseManagementSnapshot.docs.map((doc) => doc.data().citizen_id)
      );

      // Map users data and include case status
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        hasExistingCase: existingCaseIds.has(doc.id),
      }));

      setAllLocalUsers(usersData);
      setSearchedCitizenData([]);
      setHasSearched(false);
    } catch (error) {
      console.error("Error fetching local users:", error);
    }
  };

  const handleInputChange = (args) => {
    const { id, value } = args.target;
    setSearchFilters((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  // Update the search handler to maintain the case status
  const handleSearch = () => {
    const hasActiveFilter = Object.values(searchFilters).some(
      (filter) => filter && filter.length > 0
    );
    setHasSearched(hasActiveFilter);

    const filteredData = allLocalUsers.filter((item) => {
      // Case-insensitive partial matching for first name
      const matchesFirstName =
        !searchFilters.firstName ||
        item.first_name
          ?.toLowerCase()
          .includes(searchFilters.firstName.toLowerCase().trim());

      // Case-insensitive partial matching for last name
      const matchesLastName =
        !searchFilters.lastName ||
        item.last_name
          ?.toLowerCase()
          .includes(searchFilters.lastName.toLowerCase().trim());

      // Case-insensitive partial matching for email
      const matchesEmail =
        !searchFilters.email ||
        item.email
          ?.toLowerCase()
          .includes(searchFilters.email.toLowerCase().trim());

      // Exact match for birth date (if needed)
      const matchesBirthDate =
        !searchFilters.birthDate || item.birth_date === searchFilters.birthDate;

      return (
        matchesFirstName && matchesLastName && matchesEmail && matchesBirthDate
      );
    });

    setSearchedCitizenData(hasActiveFilter ? filteredData : []);
    setShowForm(false);
  };

  // Update the modal open handler
  const handleCitizenSearch = async () => {
    setIsModalOpen(true);
    setSearchFilters({ firstName: "", lastName: "", email: "", birthDate: "" }); // Reset all filters
    setHasSearched(false);
    await fetchLocalUsers();
  };

  // Update the close modal handler
  const closeModal = () => {
    setIsModalOpen(false);
    setSearchFilters({ firstName: "", lastName: "", email: "" });
    setSearchedCitizenData([]);
  };

  const togglePanel = () => {
    if (openPanel) {
      setOpenPanel(false);
    } else {
      setOpenPanel(true);
    }
  };

  // Add this handler for Enter key
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const tabComponent = {
    dashboard: <CaseManagementDashboard />,
    survivors: (
      <div className="">
        {openPanel && (
          <div className="absolute left-0 top-0 z-10 h-full w-[300px]">
            <div className="pointer-events-auto flex h-full w-[300px] flex-col border border-solid border-[#CFCFCF] bg-white shadow-lg">
              <div className="flex items-center justify-between border-b border-[#CFCFCF] bg-tropical-blue px-4 py-3">
                <h2 className="text-[20px] font-semibold text-dark-cerulean ">
                  Advanced Search
                </h2>
                <button
                  onClick={() => setOpenPanel(false)}
                  className="cursor-pointer bg-transparent"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_10134_3366"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="32"
                      height="33"
                    >
                      <rect y="0.5" width="32" height="32" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_10134_3366)">
                      <circle
                        cx="16.0013"
                        cy="16.4974"
                        r="13.3333"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M16.0013 18.3641L19.868 22.2307C20.1124 22.4752 20.4235 22.5974 20.8013 22.5974C21.1791 22.5974 21.4902 22.4752 21.7346 22.2307C21.9791 21.9863 22.1013 21.6752 22.1013 21.2974C22.1013 20.9196 21.9791 20.6085 21.7346 20.3641L17.868 16.4974L21.7346 12.6307C21.9791 12.3863 22.1013 12.0752 22.1013 11.6974C22.1013 11.3196 21.9791 11.0085 21.7346 10.7641C21.4902 10.5196 21.1791 10.3974 20.8013 10.3974C20.4235 10.3974 20.1124 10.5196 19.868 10.7641L16.0013 14.6307L12.1346 10.7641C11.8902 10.5196 11.5791 10.3974 11.2013 10.3974C10.8235 10.3974 10.5124 10.5196 10.268 10.7641C10.0235 11.0085 9.9013 11.3196 9.9013 11.6974C9.9013 12.0752 10.0235 12.3863 10.268 12.6307L14.1346 16.4974L10.268 20.3641C10.0235 20.6085 9.9013 20.9196 9.9013 21.2974C9.9013 21.6752 10.0235 21.9863 10.268 22.2307C10.5124 22.4752 10.8235 22.5974 11.2013 22.5974C11.5791 22.5974 11.8902 22.4752 12.1346 22.2307L16.0013 18.3641ZM16.0013 29.8307C14.1569 29.8307 12.4235 29.4807 10.8013 28.7807C9.17908 28.0807 7.76797 27.1307 6.56797 25.9307C5.36797 24.7307 4.41797 23.3196 3.71797 21.6974C3.01797 20.0752 2.66797 18.3418 2.66797 16.4974C2.66797 14.653 3.01797 12.9196 3.71797 11.2974C4.41797 9.67517 5.36797 8.26406 6.56797 7.06406C7.76797 5.86406 9.17908 4.91406 10.8013 4.21406C12.4235 3.51406 14.1569 3.16406 16.0013 3.16406C17.8457 3.16406 19.5791 3.51406 21.2013 4.21406C22.8235 4.91406 24.2346 5.86406 25.4346 7.06406C26.6346 8.26406 27.5846 9.67517 28.2846 11.2974C28.9846 12.9196 29.3346 14.653 29.3346 16.4974C29.3346 18.3418 28.9846 20.0752 28.2846 21.6974C27.5846 23.3196 26.6346 24.7307 25.4346 25.9307C24.2346 27.1307 22.8235 28.0807 21.2013 28.7807C19.5791 29.4807 17.8457 29.8307 16.0013 29.8307Z"
                        fill="#E72528"
                      />
                    </g>
                  </svg>
                </button>
              </div>

              <div className="flex flex-col px-4 py-2">
                <div className="row-span-3 flex w-full items-center border-b pb-2">
                  {/* Filter Button */}
                  <div
                    className={`flex w-full cursor-pointer items-center justify-center space-x-1 py-2 text-sm font-semibold ${
                      activePanel === "filter"
                        ? "border-b-2 border-solid border-[#003460] text-[#003460]"
                        : "border-b border-solid border-[#525252] text-[#525252]"
                    }`}
                    onClick={() => setActivePanel("filter")}
                  >
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                    >
                      <path
                        d="M7.16668 16C6.88334 16 6.64584 15.9042 6.45418 15.7125C6.26251 15.5208 6.16668 15.2833 6.16668 15V9L0.366676 1.6C0.116676 1.26667 0.0791759 0.916667 0.254176 0.55C0.429176 0.183333 0.733343 0 1.16668 0H15.1667C15.6 0 15.9042 0.183333 16.0792 0.55C16.2542 0.916667 16.2167 1.26667 15.9667 1.6L10.1667 9V15C10.1667 15.2833 10.0708 15.5208 9.87918 15.7125C9.68751 15.9042 9.45001 16 9.16668 16H7.16668Z"
                        fill={activePanel === "filter" ? "#003460" : "#525252"}
                      />
                    </svg>

                    <span>Filter</span>
                  </div>

                  {/* Sort Button */}
                  <div
                    className={`flex w-full cursor-pointer items-center justify-center space-x-1 py-2 text-sm font-semibold ${
                      activePanel === "sort"
                        ? "border-b-2 border-solid border-[#003460] text-[#003460]"
                        : "border-b border-solid border-[#525252] text-[#525252]"
                    }`}
                    onClick={() => setActivePanel("sort")}
                  >
                    <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                    >
                      <path
                        d="M1 12C0.716667 12 0.479167 11.9042 0.2875 11.7125C0.0958333 11.5208 0 11.2833 0 11C0 10.7167 0.0958333 10.4792 0.2875 10.2875C0.479167 10.0958 0.716667 10 1 10H5C5.28333 10 5.52083 10.0958 5.7125 10.2875C5.90417 10.4792 6 10.7167 6 11C6 11.2833 5.90417 11.5208 5.7125 11.7125C5.52083 11.9042 5.28333 12 5 12H1ZM1 7C0.716667 7 0.479167 6.90417 0.2875 6.7125C0.0958333 6.52083 0 6.28333 0 6C0 5.71667 0.0958333 5.47917 0.2875 5.2875C0.479167 5.09583 0.716667 5 1 5H11C11.2833 5 11.5208 5.09583 11.7125 5.2875C11.9042 5.47917 12 5.71667 12 6C12 6.28333 11.9042 6.52083 11.7125 6.7125C11.5208 6.90417 11.2833 7 11 7H1ZM1 2C0.716667 2 0.479167 1.90417 0.2875 1.7125C0.0958333 1.52083 0 1.28333 0 1C0 0.716667 0.0958333 0.479167 0.2875 0.2875C0.479167 0.0958333 0.716667 0 1 0H17C17.2833 0 17.5208 0.0958333 17.7125 0.2875C17.9042 0.479167 18 0.716667 18 1C18 1.28333 17.9042 1.52083 17.7125 1.7125C17.5208 1.90417 17.2833 2 17 2H1Z"
                        fill={activePanel === "sort" ? "#003460" : "#525252"}
                      />
                    </svg>

                    <span>Sort</span>
                  </div>

                  {/* Group Button */}
                  <div
                    className={`flex w-full cursor-pointer items-center justify-center space-x-1 py-2 text-sm font-semibold ${
                      activePanel === "group"
                        ? "border-b-2 border-solid border-[#003460] text-[#003460]"
                        : "border-b border-solid border-[#525252] text-[#525252]"
                    }`}
                    onClick={() => setActivePanel("group")}
                  >
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                    >
                      <path
                        d="M7.33331 14C7.88331 14 8.35415 13.8042 8.74581 13.4125C9.13748 13.0208 9.33331 12.55 9.33331 12C9.33331 11.45 9.13748 10.9792 8.74581 10.5875C8.35415 10.1958 7.88331 10 7.33331 10C6.78331 10 6.31248 10.1958 5.92081 10.5875C5.52915 10.9792 5.33331 11.45 5.33331 12C5.33331 12.55 5.52915 13.0208 5.92081 13.4125C6.31248 13.8042 6.78331 14 7.33331 14ZM13.3333 14C13.8833 14 14.3541 13.8042 14.7458 13.4125C15.1375 13.0208 15.3333 12.55 15.3333 12C15.3333 11.45 15.1375 10.9792 14.7458 10.5875C14.3541 10.1958 13.8833 10 13.3333 10C12.7833 10 12.3125 10.1958 11.9208 10.5875C11.5291 10.9792 11.3333 11.45 11.3333 12C11.3333 12.55 11.5291 13.0208 11.9208 13.4125C12.3125 13.8042 12.7833 14 13.3333 14ZM10.3333 9C10.8833 9 11.3541 8.80417 11.7458 8.4125C12.1375 8.02083 12.3333 7.55 12.3333 7C12.3333 6.45 12.1375 5.97917 11.7458 5.5875C11.3541 5.19583 10.8833 5 10.3333 5C9.78331 5 9.31248 5.19583 8.92081 5.5875C8.52915 5.97917 8.33331 6.45 8.33331 7C8.33331 7.55 8.52915 8.02083 8.92081 8.4125C9.31248 8.80417 9.78331 9 10.3333 9ZM10.3333 20C8.94998 20 7.64998 19.7375 6.43331 19.2125C5.21665 18.6875 4.15831 17.975 3.25831 17.075C2.35831 16.175 1.64581 15.1167 1.12081 13.9C0.595813 12.6833 0.333313 11.3833 0.333313 10C0.333313 8.61667 0.595813 7.31667 1.12081 6.1C1.64581 4.88333 2.35831 3.825 3.25831 2.925C4.15831 2.025 5.21665 1.3125 6.43331 0.7875C7.64998 0.2625 8.94998 0 10.3333 0C11.7166 0 13.0166 0.2625 14.2333 0.7875C15.45 1.3125 16.5083 2.025 17.4083 2.925C18.3083 3.825 19.0208 4.88333 19.5458 6.1C20.0708 7.31667 20.3333 8.61667 20.3333 10C20.3333 11.3833 20.0708 12.6833 19.5458 13.9C19.0208 15.1167 18.3083 16.175 17.4083 17.075C16.5083 17.975 15.45 18.6875 14.2333 19.2125C13.0166 19.7375 11.7166 20 10.3333 20Z"
                        fill={activePanel === "group" ? "#003460" : "#525252"}
                      />
                    </svg>

                    <span>Group</span>
                  </div>
                </div>

                {activePanel === "filter" && (
                  <div className="space-y-2">
                    {/* Disaster Section */}

                    {/* Homeowner Section */}
                    <div className="border-b pb-2">
                      <div
                        className="flex cursor-pointer items-center justify-between"
                        onClick={() => setShowHomeowner(!showHomeowner)}
                      >
                        <p className="text-sm">Homeowner or Renter</p>
                        {showHomeowner ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                      {showHomeowner && (
                        <div className="mt-2 space-y-1">
                          <label className="block cursor-pointer text-sm">
                            <input
                              type="checkbox"
                              className="mr-2 h-4 w-4"
                              checked={tempOwnershipStatuses.Owner}
                              onChange={(e) => {
                                setTempOwnershipStatuses((prev) => ({
                                  ...prev,
                                  Owner: e.target.checked,
                                }));
                              }}
                            />
                            Homeowners
                          </label>
                          <label className="block cursor-pointer text-sm">
                            <input
                              type="checkbox"
                              className="mr-2 h-4 w-4"
                              checked={tempOwnershipStatuses.Renter}
                              onChange={(e) => {
                                setTempOwnershipStatuses((prev) => ({
                                  ...prev,
                                  Renter: e.target.checked,
                                }));
                              }}
                            />
                            Renter
                          </label>
                          <label className="block cursor-pointer text-sm">
                            <input
                              type="checkbox"
                              className="mr-2 h-4 w-4"
                              checked={tempOwnershipStatuses["Rent to Own"]}
                              onChange={(e) => {
                                setTempOwnershipStatuses((prev) => ({
                                  ...prev,
                                  "Rent to Own": e.target.checked,
                                }));
                              }}
                            />
                            Rent to Own
                          </label>
                        </div>
                      )}
                    </div>

                    {/* Damage Impact Section */}
                    <div className="border-b pb-2">
                      <div
                        className="flex cursor-pointer items-center justify-between"
                        onClick={() => setShowDamage(!showDamage)}
                      >
                        <p className="text-sm">Damage Impact</p>
                        {showDamage ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                      {showDamage && (
                        <div className="mt-2 space-y-1">
                          {damageOptions.map((damage) => (
                            <label
                              key={damage}
                              className="block cursor-pointer text-sm"
                            >
                              <input
                                type="checkbox"
                                className="mr-2 h-4 w-4"
                                checked={tempSelectedDamages[damage]}
                                onChange={(e) => {
                                  setTempSelectedDamages((prev) => ({
                                    ...prev,
                                    [damage]: e.target.checked,
                                  }));
                                }}
                              />
                              {damage}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Status Section */}
                    <div className="border-b pb-2">
                      <div
                        className="flex cursor-pointer items-center justify-between"
                        onClick={() => setShowStatus(!showStatus)}
                      >
                        <p className="text-sm">Status</p>
                        {showStatus ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                      {showStatus && (
                        <div className="mt-2 space-y-1">
                          <label className="block cursor-pointer text-sm">
                            <input
                              type="checkbox"
                              className="mr-2 h-4 w-4"
                              checked={tempSelectedStatus.Open}
                              onChange={(e) =>
                                setTempSelectedStatus((prev) => ({
                                  ...prev,
                                  Open: e.target.checked,
                                }))
                              }
                            />
                            Open Cases
                          </label>
                          <label className="block cursor-pointer text-sm">
                            <input
                              type="checkbox"
                              className="mr-2 h-4 w-4"
                              checked={tempSelectedStatus.Closed}
                              onChange={(e) =>
                                setTempSelectedStatus((prev) => ({
                                  ...prev,
                                  Closed: e.target.checked,
                                }))
                              }
                            />
                            Closed Cases
                          </label>
                          <label className="block cursor-pointer text-sm">
                            <input
                              type="checkbox"
                              className="mr-2 h-4 w-4"
                              checked={tempSelectedStatus.Unassigned}
                              onChange={(e) =>
                                setTempSelectedStatus((prev) => ({
                                  ...prev,
                                  Unassigned: e.target.checked,
                                }))
                              }
                            />
                            Unassigned Cases
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activePanel === "sort" && (
                  <div className="space-y-2">
                    {/* <p className="mb-2 text-sm font-semibold">Sort By</p> */}
                    <div className="space-y-2">
                      {sortOptions.map((option) => (
                        <label
                          key={option}
                          className="block cursor-pointer text-sm"
                        >
                          <input
                            type="radio"
                            name="sort"
                            className="mr-2 h-4 w-4"
                            checked={tempSelectedSort === option}
                            onChange={() => setTempSelectedSort(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {activePanel === "group" && (
                  <div className="space-y-2">
                    <p className="mb-2 text-sm font-semibold">Group By</p>
                    <p className="text-sm text-gray-500">
                      Placeholder for Grouping options...
                    </p>
                  </div>
                )}
              </div>

              <div className="mt-auto border-t p-4">
                <button
                  onClick={handleApplyFilters}
                  className="w-full rounded bg-[#003460] py-3 text-[#F7CA41] hover:bg-[#003460]"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="">
          <div
            className={`max-w-[calc(100%)] ${subtab === "none" ? "px-5" : ""}`}
          >
            <div
              className={`flex items-center justify-end gap-2 ${
                tab === "survivors" && subtab !== "none"
                  ? "absolute right-[23px] top-0 z-50"
                  : ""
              }`}
              ref={dropdownRef}
            >
              <div className="mb-1 flex gap-2">
                <button
                  className="flex items-center gap-2 rounded border border-solid border-[#003460] bg-white px-5 py-4 text-[#003460] hover:text-[#2563EB]"
                  onClick={() => togglePanel()}
                >
                  <svg
                    width="20"
                    height="13"
                    viewBox="0 0 20 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13C0.716667 13 0.479167 12.9042 0.2875 12.7125C0.0958333 12.5208 0 12.2833 0 12C0 11.7167 0.0958333 11.4792 0.2875 11.2875C0.479167 11.0958 0.716667 11 1 11H9C9.28333 11 9.52083 11.0958 9.7125 11.2875C9.90417 11.4792 10 11.7167 10 12C10 12.2833 9.90417 12.5208 9.7125 12.7125C9.52083 12.9042 9.28333 13 9 13H1ZM1 8C0.716667 8 0.479167 7.90417 0.2875 7.7125C0.0958333 7.52083 0 7.28333 0 7C0 6.71667 0.0958333 6.47917 0.2875 6.2875C0.479167 6.09583 0.716667 6 1 6H4C4.28333 6 4.52083 6.09583 4.7125 6.2875C4.90417 6.47917 5 6.71667 5 7C5 7.28333 4.90417 7.52083 4.7125 7.7125C4.52083 7.90417 4.28333 8 4 8H1ZM1 3C0.716667 3 0.479167 2.90417 0.2875 2.7125C0.0958333 2.52083 0 2.28333 0 2C0 1.71667 0.0958333 1.47917 0.2875 1.2875C0.479167 1.09583 0.716667 1 1 1H4C4.28333 1 4.52083 1.09583 4.7125 1.2875C4.90417 1.47917 5 1.71667 5 2C5 2.28333 4.90417 2.52083 4.7125 2.7125C4.52083 2.90417 4.28333 3 4 3H1ZM12 10C10.6167 10 9.4375 9.5125 8.4625 8.5375C7.4875 7.5625 7 6.38333 7 5C7 3.61667 7.4875 2.4375 8.4625 1.4625C9.4375 0.4875 10.6167 0 12 0C13.3833 0 14.5625 0.4875 15.5375 1.4625C16.5125 2.4375 17 3.61667 17 5C17 5.48333 16.9292 5.9625 16.7875 6.4375C16.6458 6.9125 16.4333 7.35 16.15 7.75L19.3 10.9C19.4833 11.0833 19.575 11.3167 19.575 11.6C19.575 11.8833 19.4833 12.1167 19.3 12.3C19.1167 12.4833 18.8833 12.575 18.6 12.575C18.3167 12.575 18.0833 12.4833 17.9 12.3L14.75 9.15C14.35 9.43333 13.9125 9.64583 13.4375 9.7875C12.9625 9.92917 12.4833 10 12 10ZM12 8C12.8333 8 13.5417 7.70833 14.125 7.125C14.7083 6.54167 15 5.83333 15 5C15 4.16667 14.7083 3.45833 14.125 2.875C13.5417 2.29167 12.8333 2 12 2C11.1667 2 10.4583 2.29167 9.875 2.875C9.29167 3.45833 9 4.16667 9 5C9 5.83333 9.29167 6.54167 9.875 7.125C10.4583 7.70833 11.1667 8 12 8Z"
                      fill="#003460"
                    />
                  </svg>

                  <span className="text-lg text-[#003460] ">Advanced</span>
                </button>
              </div>

              <div className="mb-1 flex justify-end gap-2">
                <button
                  type="button"
                  className="my-auto flex cursor-pointer items-center gap-1 rounded bg-[#003460] px-5 py-3 text-center font-semibold text-[#F7CA41]"
                  onClick={handleCitizenSearch}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_9555_8128"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="25"
                      height="24"
                    >
                      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_9555_8128)">
                      <path
                        d="M10 16C8.18333 16 6.64583 15.3708 5.3875 14.1125C4.12917 12.8542 3.5 11.3167 3.5 9.5C3.5 7.68333 4.12917 6.14583 5.3875 4.8875C6.64583 3.62917 8.18333 3 10 3C11.8167 3 13.3542 3.62917 14.6125 4.8875C15.8708 6.14583 16.5 7.68333 16.5 9.5C16.5 10.2333 16.3833 10.925 16.15 11.575C15.9167 12.225 15.6 12.8 15.2 13.3L20.8 18.9C20.9833 19.0833 21.075 19.3167 21.075 19.6C21.075 19.8833 20.9833 20.1167 20.8 20.3C20.6167 20.4833 20.3833 20.575 20.1 20.575C19.8167 20.575 19.5833 20.4833 19.4 20.3L13.8 14.7C13.3 15.1 12.725 15.4167 12.075 15.65C11.425 15.8833 10.7333 16 10 16ZM10 14C11.25 14 12.3125 13.5625 13.1875 12.6875C14.0625 11.8125 14.5 10.75 14.5 9.5C14.5 8.25 14.0625 7.1875 13.1875 6.3125C12.3125 5.4375 11.25 5 10 5C8.75 5 7.6875 5.4375 6.8125 6.3125C5.9375 7.1875 5.5 8.25 5.5 9.5C5.5 10.75 5.9375 11.8125 6.8125 12.6875C7.6875 13.5625 8.75 14 10 14Z"
                        fill="#F9B54C"
                      />
                    </g>
                  </svg>
                  Citizen Search
                </button>
              </div>
            </div>

            <div className="relative flex">
              <div className={`flex-grow transition-all duration-300`}>
                <GridComponent
                  ref={gridRef}
                  dataSource={searchQuery ? filteredGridData : gridData}
                  allowSorting={true}
                  allowGrouping={true}
                  groupSettings={{
                    showGroupedColumn: true,
                    showDropArea: true,
                    expandAll: false,
                    captionTemplate:
                      employeeDetails?.role === "Case Manager"
                        ? null
                        : groupCaptionTemplate,
                  }}
                  sortSettings={sortSettings}
                  editSettings={editSettings}
                  filterSettings={filterSettings}
                  allowFiltering={true}
                  allowPaging={true}
                  pageSettings={pageSettings}
                  showColumnMenu={true}
                  columnMenuItems={columnMenuItems}
                  commandClick={handleCommandClick}
                  selectionSettings={{
                    type: "Multiple",
                    mode: "Row",
                    checkboxOnly: true,
                    persistSelection: true,
                  }}
                  cssClass="casemanagement-grid"
                  rowSelected={selectionHandler}
                  rowDeselected={selectionHandler}
                >
                  <ColumnsDirective>
                    <ColumnDirective field="id" isPrimaryKey visible={false} />
                    <ColumnDirective field="citizen_id" visible={false} />
                    <ColumnDirective
                      type="checkbox"
                      width="50"
                      allowGrouping={false}
                    />
                    <ColumnDirective
                      field="name"
                      headerText="Name"
                      width="150"
                      allowGrouping={true}
                      template={(props) => (
                        <div className="flex items-center gap-2 py-2">
                          {/* Status Icon */}
                          <div
                            className={`inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full ${
                              props.status === "Open"
                                ? "bg-[#56C33B] text-white"
                                : props.status === "Closed"
                                ? "bg-[#525252] text-white"
                                : "bg-[#606060] text-white"
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowMilestoneModal(true);
                            }}
                          >
                            <img
                              src={
                                props.status === "Open"
                                  ? StatusClosedIcon
                                  : StatusOpenIcon
                              }
                              alt=""
                              className="h-4 w-4"
                            />
                          </div>

                          {/* Name */}
                          <div>{props.name}</div>
                        </div>
                      )}
                    />
                    <ColumnDirective
                      field="case_number"
                      headerText="Case No."
                      width="120"
                      allowGrouping={false}
                    />
                    <ColumnDirective
                      field="address"
                      headerText="Impacted Address"
                      width="200"
                      allowGrouping={false}
                    />

                    <ColumnDirective
                      field="ownership_status"
                      headerText="Ownership Status"
                      visible={true}
                      width="150"
                      allowGrouping={true}
                      template={(props) => (
                        <div className="text-sm">
                          {props.ownership_status || "Not Specified"}
                        </div>
                      )}
                      groupCaptionTemplate={(props) => (
                        <div>Status: {props.key || "Not Specified"}</div>
                      )}
                    />
                    <ColumnDirective
                      field="damage_impact"
                      headerText="Damage Impact"
                      visible={true}
                      width="150"
                      allowGrouping={true}
                      template={(props) => (
                        <div className="text-sm">
                          {props.damage_impact || "Not Assessed"}
                        </div>
                      )}
                      groupCaptionTemplate={(props) => (
                        <div>Impact: {props.key || "Not Assessed"}</div>
                      )}
                    />
                    <ColumnDirective
                      field="assigned_to_case_manager"
                      headerText="Assignment Details"
                      width="300"
                      allowGrouping={true}
                      template={(props) => (
                        <div className="py-2">
                          <div className="grid grid-cols-2 gap-x-4 gap-y-1">
                            {props.assignment_type === "Case Manager" ? (
                              <>
                                <div className="font-semibold">
                                  Case Manager
                                </div>
                                <div>
                                  {props.assigned_to_case_manager ||
                                    "Unassigned"}
                                </div>
                              </>
                            ) : props.assignment_type === "Partner" ? (
                              <>
                                <div className="font-semibold">
                                  Organization
                                </div>
                                <div>
                                  {props.assigned_to_org || "Unassigned"}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="font-semibold">Status</div>
                                <div>Unassigned</div>
                              </>
                            )}

                            <div className="font-semibold">Date Assigned</div>
                            <div>
                              {props.assignment_date
                                ? new Date(
                                    props.assignment_date
                                  ).toLocaleDateString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                  })
                                : "-"}
                            </div>
                          </div>
                        </div>
                      )}
                      groupCaptionTemplate={(props) => (
                        <div>
                          {props.field === "assigned_to_case_manager"
                            ? `Case Manager: ${props.key || "Unassigned"}`
                            : props.key}
                        </div>
                      )}
                    />
                    <ColumnDirective
                      field="status"
                      headerText="Status"
                      width="200"
                      allowGrouping={true}
                      template={(props) => {
                        return (
                          <div className="">
                            <div className=" flex justify-center gap-2 border-t">
                              <button
                                onClick={() =>
                                  handleCommandClick({
                                    commandColumn: { type: "View" },
                                    rowData: props,
                                  })
                                }
                                className="group relative flex items-center gap-1 bg-[#0A8525] px-2 py-2 text-xs font-medium text-white transition-colors hover:bg-[#084D16]"
                              >
                                <img
                                  src={RecoveryPlanIcon}
                                  alt=""
                                  className="icon-recovery-tracker h-5 w-5"
                                />
                                <span className="absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
                                  View Recovery
                                </span>
                              </button>

                              {employeeDetails?.role !== "Case Manager" && (
                                <button
                                  onClick={() =>
                                    handleCommandClick({
                                      commandColumn: { type: "Assign" },
                                      rowData: props,
                                    })
                                  }
                                  className={`group relative flex items-center gap-1 px-3 py-1.5 text-xs font-medium text-white transition-colors ${
                                    props.assigned_to_org
                                      ? "bg-[#606060] hover:bg-[#404040]"
                                      : "bg-[#22B0ED] hover:bg-[#1A8BB9]"
                                  }`}
                                >
                                  <img
                                    src={
                                      props.assigned_to_org
                                        ? ReassignIcon
                                        : AssignIcon
                                    }
                                    alt=""
                                    className={`h-4 w-4 ${
                                      props.assigned_to_org
                                        ? "icon-reassign"
                                        : "icon-assign"
                                    }`}
                                  />
                                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity group-hover:opacity-100">
                                    {props.assigned_to_org
                                      ? "Reassign"
                                      : "Assign"}
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      }}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Page,
                      Sort,
                      Filter,
                      Search,
                      ColumnMenu,
                      Group,
                      Toolbar,
                      Selection,
                    ]}
                  />
                </GridComponent>
              </div>
            </div>
            <DialogComponent
              width="500px"
              isModal={true}
              visible={assignDialogOpen}
              close={handleDialogClose}
              header={`${
                selectedCase?.assigned_to_case_manager ? "Reassign" : "Assign"
              } Case`}
              showCloseIcon={true}
              closeOnEscape={true}
              target="#root"
            >
              <div className="px-3 pb-1 pt-4">
                <h2 className="mb-4 text-xl font-medium">
                  {selectedCase?.name}
                </h2>
                <div className="mb-3 flex items-center gap-2 text-base">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#22B0ED"
                  >
                    <path
                      d="M6 2V14M10 2V14M2 6H14M2 10H14"
                      stroke="#22B0ED"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <div>
                    <span>Case No.</span>
                    <span className="font-medium">
                      {" "}
                      {selectedCase?.case_number}
                    </span>
                  </div>
                </div>
                <div className="mb-6">
                  <div className="mb-2 flex items-center gap-2 text-base">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="#22B0ED"
                    >
                      <path d="M8 0C4.1 0 1 3.1 1 7c0 5.2 7 9 7 9s7-3.8 7-9c0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S6.6 4.5 8 4.5s2.5 1.1 2.5 2.5S9.4 9.5 8 9.5z" />
                    </svg>
                    <span className="">Location Details:</span>
                  </div>
                  <div className="text-sm">
                    <div className="mb-1.5">
                      <span className="text-base">Address: </span>
                      <span className="font-medium">
                        {selectedCase?.address}
                      </span>
                    </div>
                    <div>
                      <span className="text-base">County: </span>
                      <span className="font-medium">
                        {selectedCase?.county}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="mb-2 flex items-center justify-between">
                    <label className="block">Assign To</label>
                  </div>
                  <DropDownListComponent
                    dataSource={[
                      ...employees.map((emp) => ({
                        value: emp.id,
                        text: emp.fullName,
                        type: "Case Manager",
                        role: emp.role,
                        data: emp,
                      })),
                      ...partners.map((partner) => ({
                        value: partner.id,
                        text: partner.name_of_org,
                        type: "Partner",
                        role: "Partner Organization",
                        data: partner,
                      })),
                    ]}
                    fields={{
                      text: "text",
                      value: "value",
                    }}
                    placeholder="Select Assignee"
                    itemTemplate={(props) => (
                      <div className="flex w-full items-center justify-between">
                        <span>{props.text}</span>
                        <span className="text-xs text-gray-500">
                          {props.role}
                        </span>
                      </div>
                    )}
                    value={dropdownValue}
                    enabled={assignmentType !== "Unassign"}
                    change={(args) => {
                      if (!args.itemData) return;

                      setDropdownValue(args.itemData.value);

                      if (args.itemData.type === "Case Manager") {
                        setSelectedEmployee(args.itemData.data);
                        setSelectedCompany(null);
                        setAssignmentType("Case Manager");
                      } else {
                        setSelectedEmployee(null);
                        setSelectedCompany(args.itemData.data);
                        setAssignmentType("Partner");
                      }
                    }}
                  />
                </div>
                {assignmentType !== "Unassign" && selectedCase?.groupInfo && (
                  <div className="mb-4">
                    <label className="text-gray-600 flex items-center gap-2 text-sm">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={applyToFutureGroup}
                        onChange={(e) =>
                          setApplyToFutureGroup(e.target.checked)
                        }
                      />
                      <span>
                        Assign{" "}
                        {selectedEmployee?.fullName ||
                          selectedCompany?.name_of_org}{" "}
                        to all {selectedCase.groupInfo.field} cases in{" "}
                        {selectedCase.groupInfo.value} in the future
                      </span>
                    </label>
                  </div>
                )}
                <div className="mb-6">
                  <label className="flex items-center gap-2 text-sm">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300"
                      checked={assignmentType === "Unassign"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAssignmentType("Unassign");
                          setSelectedEmployee(null);
                          setSelectedCompany(null);
                        } else {
                          setAssignmentType("Case Manager");
                        }
                      }}
                    />
                    <span>Unassign this case</span>
                  </label>
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    onClick={handleDialogClose}
                    className="hover:bg-gray-50 cursor-pointer rounded border border-blue-300 bg-white px-4 py-2 text-[#C02626] hover:bg-slate-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAssign}
                    className={`rounded px-4 py-2 text-white ${
                      assignmentType === "Unassign"
                        ? "bg-red-600 hover:bg-red-700"
                        : "cursor-pointer rounded bg-[#4B789B] px-6 py-2 text-sm font-medium text-[#FFB547] hover:bg-[#3D6179]"
                    }`}
                  >
                    {assignmentType === "Unassign" ? "Unassign" : "Save"}
                  </button>
                </div>
              </div>
            </DialogComponent>

            <DialogComponent
              width="600px"
              isModal={true}
              visible={filterDialogOpen}
              close={() => setFilterDialogOpen(false)}
              header="Filter and Sort"
              showCloseIcon={true}
              closeOnEscape={true}
              target="#root"
            >
              <div className="p-4">
                <div className="mb-6 flex justify-between">
                  <div className="flex items-center gap-2">
                    <button
                      className={`rounded-full px-4 py-2 text-sm font-medium transition-colors ${
                        activeFilterTab === "filter"
                          ? "bg-blue-600 text-white"
                          : "hover:bg-gray-100 text-gray-500"
                      }`}
                      onClick={() => setActiveFilterTab("filter")}
                    >
                      Filter
                    </button>
                  </div>

                  <div className="flex items-center gap-2">
                    <button
                      className={`flex items-center gap-2 rounded-full px-4 py-2 text-sm font-medium transition-colors ${
                        activeFilterTab === "sort"
                          ? "bg-blue-600 text-white"
                          : "hover:bg-gray-100 text-gray-500"
                      }`}
                      onClick={() => setActiveFilterTab("sort")}
                    >
                      Sort
                      <svg
                        className="h-4 w-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {activeFilterTab === "filter" && (
                  <div className="space-y-6">
                    <div>
                      <div className="mb-2 flex items-center justify-between">
                        <h3 className="text-gray-700 text-sm font-medium">
                          Status
                        </h3>
                        <div className="space-x-2">
                          <button
                            onClick={() => handleSelectAll("status")}
                            className="text-xs text-blue-600 hover:text-blue-800"
                          >
                            Select All
                          </button>
                          <span className="text-gray-300">|</span>
                          <button
                            onClick={() => handleClearAll("status")}
                            className="text-xs text-blue-600 hover:text-blue-800"
                          >
                            Clear All
                          </button>
                        </div>
                      </div>
                      <div className="flex max-h-32 flex-wrap gap-2 overflow-y-auto">
                        {filterCriteria.status.available.map((status) => (
                          <label
                            key={status}
                            className="flex items-center gap-2"
                          >
                            <input
                              type="checkbox"
                              className="form-checkbox h-4 w-4 rounded text-blue-600"
                              checked={filterCriteria.status.selected.includes(
                                status
                              )}
                              onChange={(e) => {
                                setFilterCriteria((prev) => ({
                                  ...prev,
                                  status: {
                                    ...prev.status,
                                    selected: e.target.checked
                                      ? [...prev.status.selected, status]
                                      : prev.status.selected.filter(
                                          (s) => s !== status
                                        ),
                                  },
                                }));
                              }}
                            />
                            <span className="text-gray-600 text-sm">
                              {status}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="mb-2 flex items-center justify-between">
                        <h3 className="text-gray-700 text-sm font-medium">
                          County
                        </h3>
                        <div className="space-x-2">
                          <button
                            onClick={() => handleSelectAll("county")}
                            className="text-xs text-blue-600 hover:text-blue-800"
                          >
                            Select All
                          </button>
                          <span className="text-gray-300">|</span>
                          <button
                            onClick={() => handleClearAll("county")}
                            className="text-xs text-blue-600 hover:text-blue-800"
                          >
                            Clear All
                          </button>
                        </div>
                      </div>
                      <div className="flex max-h-32 flex-wrap gap-2 overflow-y-auto">
                        {filterCriteria.county.available.map((county) => (
                          <label
                            key={county}
                            className="flex items-center gap-2"
                          >
                            <input
                              type="checkbox"
                              className="form-checkbox h-4 w-4 rounded text-blue-600"
                              checked={filterCriteria.county.selected.includes(
                                county
                              )}
                              onChange={(e) => {
                                setFilterCriteria((prev) => ({
                                  ...prev,
                                  county: {
                                    ...prev.county,
                                    selected: e.target.checked
                                      ? [...prev.county.selected, county]
                                      : prev.county.selected.filter(
                                          (c) => c !== county
                                        ),
                                  },
                                }));
                              }}
                            />
                            <span className="text-gray-600 text-sm">
                              {county}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="mb-2 flex items-center justify-between">
                        <h3 className="text-gray-700 text-sm font-medium">
                          Assigned To
                        </h3>
                        <div className="space-x-2">
                          <button
                            onClick={() => handleSelectAll("assigned_to")}
                            className="text-xs text-blue-600 hover:text-blue-800"
                          >
                            Select All
                          </button>
                          <span className="text-gray-300">|</span>
                          <button
                            onClick={() => handleClearAll("assigned_to")}
                            className="text-xs text-blue-600 hover:text-blue-800"
                          >
                            Clear All
                          </button>
                        </div>
                      </div>
                      <div className="flex max-h-32 flex-wrap gap-2 overflow-y-auto">
                        {filterCriteria.assigned_to.available.map(
                          (assignee) => (
                            <label
                              key={assignee}
                              className="flex items-center gap-2"
                            >
                              <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 rounded text-blue-600"
                                checked={filterCriteria.assigned_to.selected.includes(
                                  assignee
                                )}
                                onChange={(e) => {
                                  setFilterCriteria((prev) => ({
                                    ...prev,
                                    assigned_to: {
                                      ...prev.assigned_to,
                                      selected: e.target.checked
                                        ? [
                                            ...prev.assigned_to.selected,
                                            assignee,
                                          ]
                                        : prev.assigned_to.selected.filter(
                                            (a) => a !== assignee
                                          ),
                                    },
                                  }));
                                }}
                              />
                              <span className="text-gray-600 text-sm">
                                {assignee || "Unassigned"}
                              </span>
                            </label>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {activeFilterTab === "sort" && (
                  <div className="space-y-6">
                    <div>
                      <h3 className="mb-3 text-sm font-medium text-blue-600">
                        Sort By
                      </h3>
                      <div className="space-y-2">
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="sortBy"
                            value="date"
                            checked={sortBy === "date"}
                            onChange={(e) => setSortBy(e.target.value)}
                            className=" text-blue-600"
                          />
                          <span className="text-gray-600 text-sm">Date</span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <h3 className="mb-3 text-sm font-medium text-blue-600">
                        Sort Order
                      </h3>
                      <div className="space-y-2">
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="sortOrder"
                            value="newest"
                            checked={sortOrder === "newest"}
                            onChange={(e) => setSortOrder(e.target.value)}
                            className=" text-blue-600"
                          />
                          <span className="text-gray-600 text-sm">
                            Newest on Top
                          </span>
                        </label>
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            name="sortOrder"
                            value="oldest"
                            checked={sortOrder === "oldest"}
                            onChange={(e) => setSortOrder(e.target.value)}
                            className=" text-blue-600"
                          />
                          <span className="text-gray-600 text-sm">
                            Oldest on Top
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-8 flex justify-between border-t pt-4">
                  <button
                    onClick={() => {
                      if (activeFilterTab === "filter") {
                        setFilterCriteria((prev) => ({
                          status: { ...prev.status, selected: [] },
                          county: { ...prev.county, selected: [] },
                          assigned_to: {
                            ...prev.assigned_to,
                            selected: [],
                          },
                          disaster_id: {
                            ...prev.disaster_id,
                            selected: [],
                          },
                        }));
                        gridRef.current?.clearFiltering();
                      } else {
                        setSortBy("date");
                        setSortOrder("newest");
                        gridRef.current?.clearSorting();
                      }
                      setFilterDialogOpen(false);
                    }}
                    className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700"
                  >
                    {activeFilterTab === "filter"
                      ? "Clear Filter"
                      : "Clear Sort"}
                  </button>
                  <button
                    onClick={() => {
                      if (activeFilterTab === "filter") {
                        const selectedFilters = {
                          status: filterCriteria.status.selected,
                          county: filterCriteria.county.selected,
                          assigned_to: filterCriteria.assigned_to.selected,
                          disaster_id: filterCriteria.disaster_id.selected,
                        };
                        handleApplyFilters(selectedFilters);
                      } else {
                        const sortDirection =
                          sortOrder === "newest" ? "descending" : "ascending";
                        const gridInstance = gridRef.current;
                        if (gridInstance) {
                          gridInstance.sortColumn(sortBy, sortDirection);
                        }
                      }
                      setFilterDialogOpen(false);
                    }}
                    className="rounded bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700"
                  >
                    {activeFilterTab === "filter"
                      ? "Apply Filter"
                      : "Apply Sort"}
                  </button>
                </div>
              </div>
            </DialogComponent>
            <Modal
              isOpen={showMilestoneModal}
              onClose={() => setShowMilestoneModal(false)}
              title="Case Milestones"
              width="1000px"
              noFooter={true}
            >
              <div className="p-4">
                <img
                  src={MilestoneImage}
                  alt="Case Milestones"
                  className="w-full"
                />
              </div>
            </Modal>

            <Modal
              isOpen={isModalOpen}
              onClose={closeModal}
              title={showForm ? "Personal Information" : "Search Citizen"}
              width="1500px"
              noFooter={true}
              className={`!mt-[200px]  ${
                toggleSidebar ? "ml-[266px]" : "ml-[80px]"
              }`}
            >
              <div className="flex flex-row gap-4">
                {/* Left Section - Inputs */}

                {/* Left Section - Inputs */}
                <div className="flex w-full max-w-xs flex-col gap-4 p-4">
                  {/* Darci ID */}
                  <CustomInput
                    name="darci_id"
                    placeholder="Darci ID"
                    register={{
                      name: "darci_id",
                      onChange: (e) =>
                        handleInputChange({
                          target: { id: "darci_id", value: e.target.value },
                        }),
                      onKeyPress: handleKeyPress,
                    }}
                    value={searchFilters.darci_id}
                    errors={{}}
                  />

                  <span className="text-gray-600 py-2 text-center text-[24px] font-bold">
                    OR
                  </span>

                  {/* First Name */}
                  <CustomInput
                    name="firstName"
                    placeholder="First Name"
                    register={{
                      name: "firstName",
                      onChange: (e) =>
                        handleInputChange({
                          target: { id: "firstName", value: e.target.value },
                        }),
                      onKeyPress: handleKeyPress,
                    }}
                    value={searchFilters.firstName}
                    errors={{}}
                  />

                  {/* Last Name */}
                  <CustomInput
                    name="lastName"
                    placeholder="Last Name"
                    register={{
                      name: "lastName",
                      onChange: (e) =>
                        handleInputChange({
                          target: { id: "lastName", value: e.target.value },
                        }),
                      onKeyPress: handleKeyPress,
                    }}
                    value={searchFilters.lastName}
                    errors={{}}
                  />

                  {/* Date of Birth */}
                  <DatePickerTemplate
                    name="birthDate"
                    label="Date of Birth"
                    value={searchFilters.birthDate}
                    onChange={(value) =>
                      handleInputChange({
                        target: { id: "birthDate", value },
                      })
                    }
                    errors={{}}
                  />

                  {/* Search Button */}
                  <button
                    onClick={handleSearch}
                    className="w-full cursor-pointer rounded-[4px] bg-[#0a2558] px-4 py-4 text-xl font-medium text-[#F7CA41] hover:bg-[#0a2558]/90"
                  >
                    Search
                  </button>
                </div>

                <div className="h-auto w-[2px] bg-gray-300"></div>
                {/* Right Section - Grid */}
                {showForm ? (
                  <div className="w-full">
                    <div className="flex-1">
                      <CitizenForm
                        onSubmitSuccess={() => {
                          setShowForm(false);
                          fetchCitizens();
                        }}
                        centerState={localStorage.getItem("centerState")}
                        centerCounty={localStorage.getItem("centerCounty")}
                        centerId={userData.center_id}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    {searchedCitizenData.length > 0 ? (
                      <div className="flex h-full flex-col gap-4">
                        <GridComponent
                          height="205px"
                          dataSource={searchedCitizenData}
                          ref={gridRefSearch}
                          cssClass="new-grid-color"
                          // allowFiltering={true}
                          filterSettings={filterSettings}
                          allowSorting={true}
                          sortSettings={sortSettings}
                          allowPaging={true}
                          pageSettings={{ pageSize: 10 }}
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              field="id"
                              headerText="ID"
                              width={0}
                              visible={false}
                            />
                            <ColumnDirective
                              field="darci_id"
                              headerText="Darci ID"
                              width={120}
                            />
                            <ColumnDirective
                              field="first_name"
                              headerText="First Name"
                              width={120}
                            />
                            <ColumnDirective
                              field="last_name"
                              headerText="Last Name"
                              width={120}
                            />
                            <ColumnDirective
                              field="birth_date"
                              headerText="Birth Date"
                              width={120}
                              template={(props) => (
                                <div>{props.birth_date || ""}</div>
                              )}
                            />
                            <ColumnDirective
                              field="address1"
                              headerText="Address"
                              width={200}
                              template={(props) => (
                                <div className="whitespace-normal break-words text-sm">
                                  {[
                                    props.address1,
                                    props.address2,
                                    props.city,
                                    props.state,
                                    props.zip,
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                                </div>
                              )}
                            />
                            <ColumnDirective
                              headerText="Actions"
                              width={200}
                              headerTextAlign="Center"
                              template={(props) => (
                                <div className="flex justify-center">
                                  {props.hasExistingCase ? (
                                    <button
                                      disabled
                                      className="cursor-not-allowed rounded border border-gray-500 px-9 py-3 text-sm font-medium text-gray-500"
                                    >
                                      Already Added
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handleAddToCaseManagement(props)
                                      }
                                      className="inline-flex cursor-pointer items-center gap-2 rounded border border-[#0a2558] bg-white px-6 py-3 text-sm font-medium text-[#0a2558] hover:bg-slate-100"
                                    >
                                      <svg
                                        width="30"
                                        height="19"
                                        viewBox="0 0 30 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6.53519 0.664062C5.78787 0.664062 5.18626 1.35142 5.18626 2.20516V4.85248H1.38886C0.803913 4.85248 0.333008 5.32337 0.333008 5.9083V16.4872C0.333008 17.0721 0.803913 17.543 1.38886 17.543H16.9353C17.5202 17.543 17.9911 17.0721 17.9911 16.4872V16.3193C16.7071 16.17 15.5225 15.5546 14.662 14.5899C13.8016 13.6251 13.3252 12.3781 13.3232 11.0854C13.3251 9.79367 13.8008 8.5475 14.66 7.58298C15.5193 6.61845 16.7025 6.00256 17.9854 5.85199C17.9558 5.29399 17.5009 4.85248 16.9353 4.85248H13.2911V2.20516C13.2911 1.35142 12.6895 0.664062 11.9421 0.664062H6.53519ZM6.70019 2.44969H11.711V4.85248H6.70019V2.44969ZM22.4326 3.64795C21.6311 3.64795 20.9859 4.29322 20.9859 5.09472V9.44116L16.6395 9.45969C15.838 9.46305 15.1955 10.1111 15.1989 10.9125C15.2023 11.714 15.8504 12.3566 16.6519 12.3532L20.9859 12.3347V16.6687C20.9859 17.4703 21.6311 18.1156 22.4326 18.1156C23.2341 18.1156 23.8794 17.4703 23.8794 16.6687V12.3224L28.2258 12.3038C29.0273 12.3005 29.6697 11.6525 29.6663 10.851C29.6629 10.0495 29.015 9.40695 28.2135 9.41037L23.8794 9.42881V5.09472C23.8794 4.29322 23.2341 3.64795 22.4326 3.64795Z"
                                          fill="#0a2558"
                                        />
                                      </svg>
                                      Add to Case
                                    </button>
                                  )}
                                </div>
                              )}
                            />
                          </ColumnsDirective>
                          <Inject services={[Page, Sort, Filter, ColumnMenu]} />
                        </GridComponent>
                        <div className="flex justify-center">
                          <button
                            onClick={handleAddCitizenClick}
                            className="cursor-pointer rounded-[4px] bg-[#0a2558] px-9 py-4 text-xl font-medium text-[#F7CA41] hover:cursor-pointer hover:bg-[#0a2558]/90"
                          >
                            <div className="flex items-center justify-center gap-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                <circle cx="9" cy="7" r="4" />
                                <line x1="19" y1="8" x2="19" y2="14" />
                                <line x1="16" y1="11" x2="22" y2="11" />
                              </svg>
                              Add Citizen
                            </div>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex h-full w-full items-center justify-center">
                        <div className="flex flex-col items-center justify-center space-y-4 text-center">
                          <p className="text-gray-500">
                            {Object.values(searchFilters).some(
                              (filter) => filter.length > 0
                            ) ? (
                              <>
                                <p className="mb-4">
                                  No matching results found
                                </p>
                                <button
                                  onClick={handleAddCitizenClick}
                                  className="flex items-center gap-2 bg-[#003460] px-9 py-3 text-xl text-[#FDB841] transition-colors hover:cursor-pointer hover:bg-[#002d56]"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                    <circle cx="9" cy="7" r="4" />
                                    <line x1="19" y1="8" x2="19" y2="14" />
                                    <line x1="16" y1="11" x2="22" y2="11" />
                                  </svg>
                                  Add Citizen
                                </button>
                              </>
                            ) : (
                              <div className="flex h-full w-full flex-col items-center justify-center gap-4">
                                <p>Start typing to search for citizens</p>
                                <p>or</p>
                                <button
                                  onClick={handleAddCitizenClick}
                                  className="flex items-center gap-2 bg-[#003460] px-9 py-3 text-xl text-[#FDB841] transition-colors hover:cursor-pointer hover:bg-[#002d56]"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                    <circle cx="9" cy="7" r="4" />
                                    <line x1="19" y1="8" x2="19" y2="14" />
                                    <line x1="16" y1="11" x2="22" y2="11" />
                                  </svg>
                                  Add Citizen
                                </button>
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Modal>

            <style>
              {`
            .casemanagement-grid .e-groupdroparea {
              background-color: #003460!important;
              color: white;
          }

            .casemanagement-grid .e-groupcaption {
              background-color: #deecf9;
          }
            .casemanagement-grid .e-groupdroparea.e-drag {
              border-color: #3b82f6;
              background-color: white;
            }
              .casemanagement-grid .e-gridheader .e-headercell {
              background-color: #FFF3CE !important;
              color: #000000 !important;
              font-weight: bold;
            }

            .casemanagement-grid .e-groupdroparea .e-grouped-header {
              background-color: #e2e8f0;
              border: 1px solid #cbd5e1;
              border-radius: 4px;
            }
              .assign-center.e-dialog {
              max-height: 1000px !important;
              height: 500px !important;
            }
              .casemanagement-grid .e-gridcontent,
.casemanagement-grid .e-content,
.casemanagement-grid .e-table,
.casemanagement-grid .e-row,
.casemanagement-grid .e-rowcell {
  transform: none !important;
  white-space: normal !important;   
  word-wrap: break-word;
  position: relative !important;
 overflow: visible !important;
}

            .assign-center .e-footer-content {
              background: white;
              border-top: 1px solid #e5e7eb;
              padding: 1rem;
            }
          .icon-recovery-tracker {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }
          .group:hover .icon-recovery-tracker {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }

          .icon-assign {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }
          .group:hover .icon-assign {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }

          .icon-reassign {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }
          .group:hover .icon-reassign {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }

          .icon-access-account {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }
          .group:hover .icon-access-account {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }
            .custom-grid-dialog .e-gridheader {
              background-color: #F8F8F8 !important;
              border-bottom: 1px solid #E0E0E0;
            }

            .custom-grid-dialog .e-headercell {
              background-color: #F8F8F8 !important;
              color: #242424 !important;
              font-weight: 500 !important;
              font-size: 14px !important;
              border-right: none !important;
            }

            .custom-grid-dialog .e-headercelldiv {
              display: flex !important;
              align-items: center !important;
              gap: 4px !important;
            }

            .custom-grid-dialog .e-icon-filter::before {

              font-size: 8px !important;
              color: #242424 !important;
            }

          `}
            </style>
          </div>
        </div>
      </div>
    ),
    partners: <CaseManagementPartners />,
    casemgt: <CaseManagementAgency />,
    casemanager: <CaseManager />,
    disaster: <CaseManagementDisaster />,
    overview: <CaseManagementDisaster />,
    communitycontacts: (
      <CommunityContacts
        className="p-[20px]"
      />
    ),
    calendar: <Calendar />,
    // questions: <CaseManagementSurveyQuestions />,
  };

  const renderTabComponent = useMemo(() => {
    if (tab === "disaster") {
      return <CaseManagementDisaster />;
    }
    
    return !!subtab && subtab !== "none" ? (
      <CenterDisasters />
    ) : (
      tabComponent[tab]
    );
  }, [tab, subtab, tabComponent]);

  const currentSubtabSelected = caseManagementSidebarItems?.[1]?.subItems?.find(
    ({ id }) => id === subtab
  );

  const searchParamsTab = searchParams.get("tab");

  const onSetSearchParams = (tab) => {
    const queryParamsTab = new URLSearchParams();
    queryParamsTab.set("tab", tab);
    setSearchParams(queryParamsTab);
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [location.pathname]);

  return (
    <CaseManagementLayout tab={tab}>
      <div className="flex flex-1 flex-col overflow-y-auto">
        {tab === "survivors" &&
        subtab !== "none" &&
        !!sessionAccessedUserId &&
        tab === "survivors" ? (
          <div
            className="flex flex-1 flex-col overflow-y-auto"
            {...(searchParamsTab === "1" && {
              style: { position: "relative" },
            })}
          >
            <div className="flex items-center space-x-[8px] px-[23px]">
              <div
                className={`flex h-[50px] min-w-[100px] cursor-pointer items-center justify-center rounded-md px-[23px] ${
                  parseInt(searchParamsTab) === 1
                    ? "bg-[#003460] font-bold text-[#F7CA41]"
                    : "border border-solid border-[#CFCFCF] bg-[#F3F3F3] text-dark-midnight-blue"
                }`}
                onClick={() => onSetSearchParams(1)}
              >
                <p>Cases</p>
              </div>
              <div
                className={`flex h-[50px] min-w-[100px] cursor-pointer items-center justify-center rounded-md px-[23px] ${
                  parseInt(searchParamsTab) !== 1
                    ? "bg-[#003460] font-bold text-[#F7CA41]"
                    : "border border-solid border-[#CFCFCF] bg-[#F3F3F3] text-dark-midnight-blue"
                }`}
                onClick={() => onSetSearchParams(2)}
              >
                <p>{currentSubtabSelected?.label || ""}</p>
              </div>
            </div>
            <div
              ref={scrollRef}
              className="mx-[24px] mt-2 flex-1 overflow-y-auto"
            >
              <div className="-mt-1 h-[100vh]">
                {parseInt(searchParamsTab) === 1
                  ? tabComponent.survivors
                  : renderTabComponent}
              </div>
            </div>
          </div>
        ) : (
          renderTabComponent
        )}
      </div>
    </CaseManagementLayout>
  );
};

export default CaseManagementCenter;
