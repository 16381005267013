import { createContext, useContext, useState } from "react";

const DisasterObjContext = createContext(null);

export const useDisasterObjContext = () => {
  const context = useContext(DisasterObjContext);
  if (!context) {
    throw new Error(
      "useDisasterContext must be used within a DisasterProvider"
    );
  }
  return context;
};

export const DisasterObjProvider = ({ children }) => {
  const [disaster, setDisaster] = useState(null);
  return (
    <DisasterObjContext.Provider value={{ disaster, setDisaster }}>
      {children}
    </DisasterObjContext.Provider>
  );
};
