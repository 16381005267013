export const UserStatus = {
  EMAIL_UNVERIFIED: "EMAIL_UNVERIFIED",
  EMAIL_VERIFIED: "EMAIL_VERIFIED",
  ADDRESS_VERIFIED: "ADDRESS_VERIFIED",
  DUPLICATE_ADDRESS_VERIFIED: "DUPLICATE_ADDRESS_VERIFIED",
  DUPLICATE_ADDRESS_UNVERIFIED: "DUPLICATE_ADDRESS_UNVERIFIED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
  DELETED: "DELETED",
  PENDING: "PENDING",
};

export const UserRoles = {
  CITIZEN: "citizen",
  CENTER: "center",
  PARTNER: "partner",
  ADMIN: "admin",
};

// Freeze the objects to make them immutable
Object.freeze(UserStatus);
Object.freeze(UserRoles);
