import { Controller } from "react-hook-form";
import Switch from "react-switch";

const CustomSwitch = ({
  onColor = "#0A8525",
  offColor = "#8F8F8F",
  checkedIcon = false,
  uncheckedIcon = false,
  height = 32,
  width = 74,
  handleDiameter = 20,
  control,
  name,
  rules,
  label,
  errors,
  rowType = false,
}) => {
  return (
    <div className="flex flex-col items-start justify-center gap-2">
      <div
        className={`flex  justify-center gap-2 ${
          rowType ? "flex-row items-center" : "flex-col items-start"
        }`}
      >
        <label className="text-[14px]">{label}</label>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => (
            <Switch
              checked={field.value} // your state variable
              onChange={field.onChange} // your change handler
              onColor={onColor} // green color for "on" state
              offColor={offColor} // gray color for "off" state
              checkedIcon={checkedIcon}
              uncheckedIcon={uncheckedIcon}
              height={height}
              width={width}
              handleDiameter={handleDiameter}
            />
          )}
        />
      </div>
      {errors[name] && (
        <p className="mt-1 text-xs text-red-500">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default CustomSwitch;
