import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as CentersIcon } from "../../icons/Maps/centers.svg";
import { ReactComponent as PartnershipIcon } from "../../icons/Maps/partnership.svg";
import { ReactComponent as FireIcon } from "../../icons/Maps/fire.svg";
import { ReactComponent as FloodIcon } from "../../icons/Maps/flood.svg";
import { ReactComponent as EarthquakeIcon } from "../../icons/Maps/Earthquake.svg";
import { ReactComponent as HurricaneIcon } from "../../icons/Maps/hurricane.svg";
import { ReactComponent as TornadoIcon } from "../../icons/Maps/tornado.svg";
import {
  Map as VISGoogleMap,
  APIProvider,
  AdvancedMarker,
  Marker,
  useMap,
} from "@vis.gl/react-google-maps";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import citizenProfileService from "../../service/citizen/CitizenProfile";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { config } from "../../utils/config";
import { useAuth } from "../../components/AuthContext";
import { useNavigate } from "react-router-dom";
import handshake from "../../assets/handshake.svg";
import donate from "../../assets/donate.svg";
import citizenmarkernew from "../../assets/citizenmarkernew.svg";
import centermarkernew from "../../assets/centermarkernew.svg";
import partnermarkernew from "../../assets/partnermarkernew.svg";
import ownmarker from "../../assets/ownmarker.svg";
import citizenown from "../../assets/citizenown.svg";
import partnerown from "../../assets/partnerown.svg";
import centerown from "../../assets/centerown.svg";
import { collectionGroup } from "firebase/firestore";
import { role } from "../../components/AuthContext";
import dollordonor from "../../assets/dollor-donor.svg";
import donatedollor from "../../assets/donate-dollor.svg";

const CustomMarkerWithLabel = ({
  position,
  icon,
  label,
  role,
  onMouseOver,
  onMouseOut,
}) => {
  const [markerRef, setMarkerRef] = useState(null);
  const mapRef = useMap();

  useEffect(() => {
    if (!mapRef) return;

    const overlay = new window.google.maps.OverlayView();

    overlay.onAdd = function () {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.className = "custom-marker-container";

      // Create marker element
      const markerDiv = document.createElement("div");
      markerDiv.className = "marker";
      markerDiv.style.width = "35px";
      markerDiv.style.height = "35px";
      markerDiv.style.backgroundImage = `url(${icon?.url || ""})`;
      markerDiv.style.backgroundSize = "35px 35px";
      markerDiv.style.backgroundPosition = "center";
      markerDiv.style.backgroundRepeat = "no-repeat";
      markerDiv.style.position = "absolute";
      markerDiv.style.cursor = "pointer";

      // Create label element
      const labelDiv = document.createElement("div");
      labelDiv.className = "marker-label";
      labelDiv.innerHTML = label || "";
      labelDiv.style.position = "absolute";
      labelDiv.style.left = "40px";
      labelDiv.style.top = "50%";
      labelDiv.style.transform = "translateY(-0%)";
      labelDiv.style.background = "rgba(255, 255, 255, 0.95)";
      labelDiv.style.padding = "6px 12px";
      labelDiv.style.borderRadius = "6px";
      labelDiv.style.boxShadow = "0 8px 20px rgba(0,0,0,0.4)";
      labelDiv.style.fontSize = "16px";
      labelDiv.style.fontWeight = "600";
      labelDiv.style.color = "#1A202C";
      labelDiv.style.textTransform = "capitalize";
      labelDiv.style.letterSpacing = "0.2px";
      labelDiv.style.whiteSpace = "nowrap";
      labelDiv.style.zIndex = "1000";
      labelDiv.style.pointerEvents = "none";

      div.appendChild(markerDiv);
      if (role !== "citizen") {
        div.appendChild(labelDiv);
      }

      if (onMouseOver) {
        div.addEventListener("mouseover", (e) => {
          // Create a custom event object with the marker's position
          const markerRect = div.getBoundingClientRect();
          const customEvent = {
            domEvent: {
              target: div,
              clientX: markerRect.left + markerRect.width / 2,
              clientY: markerRect.top,
            },
            latLng: new window.google.maps.LatLng(position.lat, position.lng),
          };
          onMouseOver(customEvent);
        });
      }
      if (onMouseOut) {
        div.addEventListener("mouseout", onMouseOut);
      }

      this.div = div;
      const panes = this.getPanes();
      panes.overlayMouseTarget.appendChild(div);
    };

    overlay.draw = function () {
      if (!this.div) return;

      const projection = this.getProjection();
      if (!projection) return;

      const pos = projection.fromLatLngToDivPixel(
        new window.google.maps.LatLng(position.lat, position.lng)
      );

      if (pos) {
        this.div.style.left = pos.x - 17 + "px";
        this.div.style.top = pos.y - 34 + "px";
      }
    };

    overlay.onRemove = function () {
      if (this.div) {
        if (this.div.parentNode) {
          this.div.parentNode.removeChild(this.div);
        }
        delete this.div;
      }
    };

    overlay.setMap(mapRef);
    setMarkerRef(overlay);

    return () => {
      if (overlay) {
        overlay.setMap(null);
      }
    };
  }, [mapRef, position, icon, label, onMouseOver, onMouseOut]);

  return null;
};

// Add this new component above the Map component
const StreetViewMarker = ({
  position,
  icon,
  label,
  onMouseOver,
  onMouseOut,
  role,
}) => {
  return (
    <CustomMarkerWithLabel
      position={position}
      icon={icon}
      label={label}
      role={role}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      style={{
        zIndex: 1000, // Ensure markers stay above Street View
      }}
    >
      <div
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        style={{
          cursor: "pointer",
          position: "relative",
        }}
      >
        <img
          src={icon}
          alt={`${role} marker`}
          style={{
            width: "40px",
            height: "40px",
          }}
        />
        {label && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              padding: "2px 4px",
              borderRadius: "4px",
              fontSize: "12px",
              marginTop: "2px",
            }}
          >
            {label}
          </div>
        )}
      </div>
    </CustomMarkerWithLabel>
  );
};

const Donate = (className = "") => {
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [communityContacts, setCommunityContacts] = useState([]);
  const [partnerAreaServiced, setpartnerAreaServiced] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const mapRef = useRef(null);
  const [permanentInfo, setPermanentInfo] = useState(null);
  const [labelText, setLabelText] = useState("");
  const [relatedUsers, setRelatedUsers] = useState([]);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const { role } = useAuth();
  const [centerId, setCenterId] = useState(null);
  const [showSidePanel, setShowSidePanel] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [showCommunityContacts, setShowCommunityContacts] = useState(false);
  const [mousePosition, setMousePosition] = useState(null);
  const [isHoveringInfo, setIsHoveringInfo] = useState(false);
  const [hideTimeout, setHideTimeout] = useState(null);
  const hoverDelay = 100; // Reduced delay for more responsive feel
  const [showAllDonations, setShowAllDonations] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);
  const [showAllServices, setShowAllServices] = useState(false);
  const [needsExpansion, setNeedsExpansion] = useState({
    donations: false,
    items: false,
    services: false,
  });

  // Add these refs at the beginning of your component
  const donationTypesRef = useRef(null);
  const availableItemsRef = useRef(null);
  const servicesProvidedRef = useRef(null);

  const [markerDataCache, setMarkerDataCache] = useState({});

  // Keep the expandedSections state
  const [expandedSections, setExpandedSections] = useState({
    donations: {},
    items: {},
    services: {},
  });

  const [donorPartners, setDonorPartners] = useState([]);
  const [partners, setPartners] = useState([]); // New state for partners
  const [selectedPartner, setSelectedPartner] = useState(null); // New state for selected partner
  const [mapCenter, setMapCenter] = useState(null); // State to control map center
  const [mapZoom, setMapZoom] = useState(15); // State to control map zoom
  const selectedUserId = sessionStorage.getItem("userId");

  const fetchData = async () => {
    let profileData;
    const data = await citizenProfileService.getCitizenProfile(
      "users",
      selectedUserId
    );
    setCenterId(data.center_id);
    if (role === "partner") {
      profileData = await citizenProfileService.getCitizenProfile(
        "partners",
        data.partner_id
      );
      setLabelText(profileData.name_of_org || "");
    } else {
      profileData = await citizenProfileService.getCitizenProfile(
        "users",
        selectedUserId
      );
      setLabelText("");

      setUserRole(role);
    }

    if (profileData) {
      setData(profileData);

      // if (profileData.latitude && profileData.longitude) {
      //   const newCoordinates = {
      //     lat: profileData.latitude,
      //     lon: profileData.longitude,
      //   };
      //   setCoordinates(newCoordinates);
      //   setPermanentInfo({
      //     mobileNumber: profileData.mobile_number,
      //     address: `${profileData.address1}, ${profileData.city}, ${profileData.state}, ${profileData.zip}`,
      //     role: role,
      //   });
      // } else {
      try {
        const address = `${profileData.address1}, ${profileData.city}, ${profileData.state}, ${profileData.zip}`;
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${config.google_maps}`
        );
        const data = await response.json();

        if (data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          setCoordinates({ lat, lon: lng });

          let updateId = role === "partner" ? profileData.id : userId;

          await citizenProfileService.updateCitizenProfile(
            role === "partner" ? "partners" : "users",
            updateId,
            {
              latitude: lat,
              longitude: lng,
            }
          );
        } else {
          throw new Error("No results found");
        }
      } catch (err) {
        setShowPopup(true);
      }
    }
    if (profileData.center_id && role === "citizen") {
      await fetchUsersWithCenterId(profileData.center_id);
    } else if (role === "partner") {
      const partnerRef = await citizenProfileService.getCitizenProfile(
        "users",
        userId
      );
      await fetchUsersWithCenterId(partnerRef.center_id);
    }

    if (mapRef.current) {
      if (coordinates) {
        mapRef.current.setCenter({
          lat: coordinates.lat,
          lng: coordinates.lon,
        });
      }
    }
  };

  // Update fetchUsersWithCenterId to set partners
  const fetchUsersWithCenterId = async (center_id) => {
    try {
      let centerIdArray = Array.isArray(center_id) ? center_id : [center_id];

      const markersQuery = collectionGroup(db, "users");
      const centersQuery = collection(db, "centers");
      const partnersQuery = query(collection(db, "partners"));

      const [markersSnapshot, centersSnapshot, partnersSnapshot] =
        await Promise.all([
          getDocs(markersQuery),
          getDocs(centersQuery),
          getDocs(partnersQuery),
        ]);

      // Create indices for faster lookups
      const centersById = Object.fromEntries(
        centersSnapshot.docs.map((doc) => [
          doc.id,
          { id: doc.id, ...doc.data() },
        ])
      );
      const partnersById = Object.fromEntries(
        partnersSnapshot.docs.map((doc) => [
          doc.id,
          { id: doc.id, ...doc.data() },
        ])
      );

      // Process and filter markers
      const relatedUsersData = [];
      const partnersData = []; // New array to hold partners

      markersSnapshot.docs.forEach((doc) => {
        const data = doc.data();

        // Check if the document is related to our center_id
        const docCenterId = data.center_id;
        if (!docCenterId) return;

        const isRelated = Array.isArray(docCenterId)
          ? docCenterId.some((id) => centerIdArray.includes(id))
          : centerIdArray.includes(docCenterId);

        if (!isRelated) return;

        // Add partner data
        if (data.partner_id && partnersById[data.partner_id]) {
          relatedUsersData.push(partnersById[data.partner_id]);
          partnersData.push(partnersById[data.partner_id]); // Store partners
        }
        // Add user data
        else if (role === "citizen" ? data.citizen_id === userId : true) {
          relatedUsersData.push({ id: doc.id, ...data });
        }
      });

      // Cache the results
      const cacheKey = centerIdArray.sort().join(",");
      setMarkerDataCache((prev) => ({
        ...prev,
        [cacheKey]: relatedUsersData,
      }));
      setRelatedUsers(relatedUsersData);
      setPartners(partnersData); // Set partners state
    } catch (error) {
      console.error("Error fetching related users:", error);
    }
  };

  // Fetch all community contacts from Firestore
  const fetchCommunityContacts = async () => {
    // Early return if no role is defined
    if (!role) {
      return;
    }

    let contactsQuery;
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const uid = currentUser ? currentUser.uid : null;

    // Early return if no centerId for citizen role
    if (role === "citizen" && !centerId) {
      return;
    }

    try {
      if (role === "citizen") {
        // Fetch the center doc id using the citizen's center_id
        const userQuery = query(
          collection(db, "users"),
          where("user_role", "==", "center"),
          where("center_id", "==", centerId)
        );
        const userSnapshot = await getDocs(userQuery);

        // Only proceed if we found a matching center
        if (!userSnapshot.empty) {
          const userDoc = userSnapshot.docs[0];
          const docId = userDoc.id;

          // Fetch the community contacts for the center using the docId
          contactsQuery = query(
            collection(db, "donor-partners"),
            where("is_deleted", "==", false),
            where("centerId", "==", docId)
          );
        } else {
          return;
        }
      } else if (role === "partner") {
        // Fetch the center docs using the array of center_ids
        const centerQueries = centerId.map(async (id) => {
          const userQuery = query(
            collection(db, "users"),
            where("user_role", "==", "center"),
            where("center_id", "==", id)
          );
          return getDocs(userQuery);
        });

        const centerSnapshots = await Promise.all(centerQueries);
        const centerDocIds = [];

        // Get all center doc IDs
        centerSnapshots.forEach((snapshot) => {
          if (!snapshot.empty) {
            centerDocIds.push(snapshot.docs[0].id);
          }
        });

        if (centerDocIds.length > 0) {
          // Fetch community contacts for all centers
          contactsQuery = query(
            collection(db, "donor-partners"),
            where("is_deleted", "==", false),
            where("centerId", "in", centerDocIds)
          );
        } else {
          return;
        }
      } else {
        return;
      }

      // Only proceed if we have a valid query
      if (!contactsQuery) {
        return;
      }

      const contactsSnapshot = await getDocs(contactsQuery);

      const contacts = contactsSnapshot.docs.map((doc) => ({
        id: doc.id,
        company_name: doc.data().company_name,
        email: doc.data().email,
        phone: doc.data().phone,
        address: doc.data().address,
        website_url: doc.data().website_url,
      }));

      const updatedContacts = await Promise.all(
        contacts.map(async (contact) => {
          if (contact.latitude && contact.longitude) {
            return contact;
          } else {
            try {
              const address = `${contact.address1}, ${contact.city}, ${contact.state}, ${contact.zip}`;
              const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                  address
                )}&key=${config.google_maps}`
              );
              const data = await response.json();
              if (data.results.length > 0) {
                const { lat, lng } = data.results[0].geometry.location;
                const contactRef = doc(db, "community-contacts", contact.id);
                await updateDoc(contactRef, {
                  latitude: lat,
                  longitude: lng,
                });

                return { ...contact, latitude: lat, longitude: lng };
              } else {
                throw new Error("No results found for address");
              }
            } catch (err) {
              console.error(
                "Error fetching coordinates for contact:",
                contact.id,
                err
              );
              setShowPopup(true);
              return contact;
            }
          }
        })
      );

      setCommunityContacts(
        updatedContacts.filter(
          (contact) => contact.latitude && contact.longitude
        )
      );
    } catch (error) {
      console.error("Error in fetchCommunityContacts:", error);
    }
  };

  const fetchPartnersByCounty = async () => {
    try {
      const partnerQuery = query(
        collection(db, "partners"),
        where("area_serviced_county", "array-contains", data.county)
      );

      const partnersSnapshot = await getDocs(partnerQuery);
      const partners = partnersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setpartnerAreaServiced(partners);
    } catch (error) {
      console.error("Error fetching partners by county:", error);
    }
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser && selectedUserId) {
        setUserId(selectedUserId);
      } else {
        setData(null);
        console.log("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId && role) {
      fetchData();
    }
  }, [userId, role]);

  useEffect(() => {
    if (userId && role && centerId && data) {
      if (role === "citizen") {
        fetchPartnersByCounty();
      }
    }
  }, [userId, role, centerId, data]);

  useEffect(() => {
    if (userId && role && centerId) {
      fetchCommunityContacts();
    }
  }, [userId, role, centerId]);

  useEffect(() => {
    // Only set map center if coordinates are available
    if (mapRef.current && coordinates) {
      mapRef.current.setCenter({
        lat: coordinates.lat,
        lng: coordinates.lon,
      });
    }
  }, [coordinates]);

  // Determine marker color based on user_role
  const getMarkerColor = (role) => {
    switch (role) {
      case "center":
        return "green";
      case "partner":
        return "yellow";
      case "citizen":
        return "blue";
      case "community-contact":
        return "pink";
      default:
        return "red";
    }
  };

  const getMarkerImage = (passedRole, isCurrentUser) => {
    // Always use ownmarker for the current user, regardless of role
    if (isCurrentUser || role === userId) {
      return {
        url:
          role === "citizen"
            ? citizenown
            : role === "partner"
            ? partnerown
            : centerown,
        scaledSize: new window.google.maps.Size(65, 65), // Increased from 50 to 65
        labelOrigin: new window.google.maps.Point(17, -10),
      };
    }

    // For other users, use role-specific markers
    let markerUrl;
    switch (passedRole) {
      case "center":
        markerUrl = centermarkernew;
        break;
      case "partner":
        markerUrl = partnermarkernew;
        break;
      case "citizen":
        markerUrl = citizenmarkernew;
        break;
      case "donor-partner":
        markerUrl = dollordonor;
        break;
      default:
        markerUrl = ownmarker;
    }
    return {
      url: markerUrl,
      scaledSize: new window.google.maps.Size(35, 35),
      labelOrigin: new window.google.maps.Point(17, -10),
    };
  };

  const handleMarkerHover = (markerData, event) => {
    setHoveredMarker(markerData);
    if (event && event.domEvent) {
      setMousePosition({
        x: event.domEvent.clientX,
        y: event.domEvent.clientY,
      });
    }
  };

  const handleMarkerLeave = () => {
    // Simple timeout to allow moving to info window
    const timeout = setTimeout(() => {
      setHoveredMarker(null);
      setMousePosition(null);
    }, 300); // Increased timeout for better usability
    setHideTimeout(timeout);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const renderMarkerInfo = () => {
    if (!hoveredMarker) return null;

    const role =
      hoveredMarker.user_role || hoveredMarker.userType?.toLowerCase();

    // Check if the hovered marker is a donor partner
    const isDonorPartner =
      hoveredMarker.collection === "donor-partners" &&
      hoveredMarker.user_role === "partner";

    // Display different information based on the type of marker hovered
    if (role === "partner" || isDonorPartner) {
      const fullAddress = `${hoveredMarker.address1 || ""} ${
        hoveredMarker.address2 || ""
      }, ${hoveredMarker.city || ""}, ${hoveredMarker.state || ""} ${
        hoveredMarker.zip || ""
      }`;
      return (
        <div
          className="flex flex-col gap-1 px-4 py-4"
          style={{ background: "white" }}
        >
          <div className="flex flex-col gap-1">
            <div className="flex flex-col gap-1 rounded-lg bg-[#003460] p-2">
              <div className="flex justify-between">
                <div className="flex flex-col gap-1">
                  <h3 className="text-lg text-start font-bold text-white ">
                    {hoveredMarker.name_of_org || ""}
                  </h3>
                </div>
                <div>
                  <button
                    className="btn-donate mt-1 flex w-full items-center justify-center gap-2 p-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (hoveredMarker.website_url) {
                        window.open(hoveredMarker.website_url, "_blank");
                      }
                    }}
                  >
                    <img src={donate} alt="donate" className="h-5 w-5" />
                    <span className="text-center">Donate Here</span>
                  </button>
                </div>
              </div>

              <div className="flex justify-between ">
                <div className="flex flex-col gap-1">
                  <div className="text-gray-600 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#F6C46D"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-[#DCDCDC]">{fullAddress || ""}</span>
                  </div>
                  <div className="text-gray-600 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#F6C46D"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span className="text-[#DCDCDC]">
                      {hoveredMarker.email || ""}
                    </span>
                  </div>
                  <div className="text-gray-600 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#F6C46D"
                    >
                      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                    </svg>
                    <span className="text-[#DCDCDC]">
                      {hoveredMarker.mobile_number || ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1 rounded-lg bg-[#D3EBFF] p-1">
            <div className="rounded-lg  p-1">
              <h4 className="mb-2 font-semibold text-[#003460]">
                Donations Accepted
              </h4>
              <div className="mx-auto">
                <div
                  ref={donationTypesRef}
                  className={`flex flex-wrap gap-x-1 gap-y-2 ${
                    !showAllDonations && "max-h-[64px] overflow-hidden"
                  }`}
                >
                  {hoveredMarker.donation_types &&
                  hoveredMarker.accept_donations == "yes" ? (
                    hoveredMarker.donation_types.map((type) => (
                      <div key={type} className="flex justify-center">
                        <span className="my-auto block min-w-fit whitespace-nowrap rounded-full border-[0.5px] border-solid border-[#9D0F0F] bg-[#FFF3F3] px-3 py-1 text-center text-sm text-[#9D0F0F]">
                          {type === "other"
                            ? hoveredMarker.other_donation_types || "Other"
                            : type.charAt(0).toUpperCase() + type.slice(1)}
                        </span>
                      </div>
                    ))
                  ) : (
                    <span className="text-gray-500"></span>
                  )}
                </div>
                {needsExpansion.donations && (
                  <button
                    onClick={() => setShowAllDonations(!showAllDonations)}
                    className="mt-2 bg-transparent text-base font-normal  text-[#8B2323] hover:cursor-pointer"
                  >
                    {showAllDonations ? "See less" : "See more"}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-1">
            <h4 className="mb-2 font-bold text-[#003460]">Service Hours</h4>
            {hoveredMarker.hours_of_service === "24x7" ? (
              <div className="text-left font-medium text-green-600">
                Open 24/7
              </div>
            ) : (
              <div className="rounded-lg bg-white shadow-sm">
                <table className="w-full border-collapse text-sm">
                  <tbody>
                    {(() => {
                      const days = [
                        "monday",
                        "tuesday",
                        "wednesday",
                        "thursday",
                        "friday",
                        "saturday",
                        "sunday",
                      ];
                      const groupedHours = {};

                      // Group days by their service hours
                      days.forEach((day) => {
                        const dayData = hoveredMarker.service_sched?.[day];
                        if (!dayData?.enabled) return;

                        const timeKey = `${dayData.start?.time} ${dayData.start?.period} - ${dayData.end?.time} ${dayData.end?.period}`;
                        if (!groupedHours[timeKey]) {
                          groupedHours[timeKey] = [];
                        }
                        groupedHours[timeKey].push(day);
                      });

                      // Render grouped days
                      return Object.entries(groupedHours).map(
                        ([hours, groupedDays]) => (
                          <tr key={hours}>
                            <td className="w-1/2 border-[1px] border-solid border-gray-300 px-2 py-1.5 font-medium text-[#242424]">
                              {groupedDays
                                .map(
                                  (day) =>
                                    day.charAt(0).toUpperCase() + day.slice(1)
                                )
                                .join(", ")}
                              :
                            </td>
                            <td className="w-1/2 bg-[#D3EBFF] px-2 py-1.5 text-[#242424]">
                              {hours}
                            </td>
                          </tr>
                        )
                      );
                    })()}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      );
    } else if (role === "citizen") {
      return null;
    } else {
      const fullAddress = `${hoveredMarker.address1 || ""} ${
        hoveredMarker.address2 || ""
      }, ${hoveredMarker.city || ""}, ${hoveredMarker.state || ""} ${
        hoveredMarker.zip || ""
      }`;
      return (
        <div
          className="flex flex-col gap-1 px-4 py-4"
          style={{ background: "white" }}
        >
          <div className="flex flex-col gap-1">
            <div className="flex flex-col gap-1 rounded-lg bg-[#003460] p-2">
              <div className="flex justify-between">
                <div className="flex flex-col gap-1">
                  <h3 className="text-lg text-start font-bold text-white ">
                    {hoveredMarker.company_name || ""}
                  </h3>
                </div>
                <div>
                  <button
                    className="btn-donate mt-1 flex w-full items-center justify-center gap-2 p-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (hoveredMarker.website_url) {
                        window.open(hoveredMarker.website_url, "_blank");
                      }
                    }}
                  >
                    <img
                      src={donatedollor}
                      alt="donate"
                      className="h-5 w-5 fill-white"
                    />
                    <span className="text-center">Donate Here</span>
                  </button>
                </div>
              </div>

              <div className="flex justify-between ">
                <div className="flex flex-col gap-1">
                  <div className="text-gray-600 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#F6C46D"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-[#DCDCDC]">{fullAddress || ""}</span>
                  </div>
                  <div className="text-gray-600 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#F6C46D"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    <span className="text-[#DCDCDC]">
                      {hoveredMarker.email || ""}
                    </span>
                  </div>
                  <div className="text-gray-600 flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#F6C46D"
                    >
                      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                    </svg>
                    <span className="text-[#DCDCDC]">
                      {hoveredMarker.phone || ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const fetchDonorPartners = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "donor-partners"));
      const partners = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDonorPartners(partners);
    } catch (error) {
      console.error("Error fetching donor partners:", error);
    }
  };

  useEffect(() => {
    fetchDonorPartners();
  }, []);

  // check if the text is overflowing to render "See more"
  const checkOverflow = (text) => {
    if (!text) return false;

    const tempDiv = document.createElement("div");
    tempDiv.style.cssText = `
    position: absolute;
    visibility: hidden;
    width: 380px;
    font-size: 14px;
    line-height: 1.5;
    padding: 0;
    margin: 0;
  `;
    tempDiv.innerText = text;
    document.body.appendChild(tempDiv);

    const hasOverflow = tempDiv.offsetHeight > 42;
    document.body.removeChild(tempDiv);

    return hasOverflow;
  };

  const filteredContacts = showCommunityContacts
    ? communityContacts.filter(
        (contact) =>
          (contact.company_name || "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (contact.phone || "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (contact.email || "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      )
    : donorPartners.filter(
        (partner) =>
          partner.is_deleted !== true &&
          ((partner.name_of_org || "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
            (partner.phone || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            (partner.email || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) &&
          partner.is_certified !== false
      );

  const handleInfoMouseEnter = () => {
    if (hideTimeout) {
      clearTimeout(hideTimeout);
      setHideTimeout(null);
    }
  };

  const handleInfoMouseLeave = () => {
    setHoveredMarker(null);
    setMousePosition(null);
  };

  // Update the cleanup useEffect
  useEffect(() => {
    // Clear marker cache when component unmounts
    return () => setMarkerDataCache({});
  }, []);

  const getInfoPosition = (x, y) => {
    const padding = 20; // Padding from screen edges
    const infoWidth = 650; // Width of info window
    const infoMaxHeight = 450; // Max height of info window
    const leftNavWidth = 246; // Width of LeftNavPane
    const sidePanelWidth = showSidePanel ? 450 : 0; // Sidebar width when expanded
    const totalLeftOffset = leftNavWidth + sidePanelWidth;

    let translateX = "-50%"; // Default center alignment
    let leftPos = x;
    let arrowLeftPos = "50%"; // Default arrow position

    // Check if info window would overlap with expanded sidebar
    if (x - infoWidth / 2 < totalLeftOffset) {
      // Adjust position to avoid sidebar overlap
      translateX = "0";
      leftPos = totalLeftOffset + padding;
      arrowLeftPos = `${x - leftPos}px`;
    }
    // Handle right edge
    else if (x + infoWidth / 2 > window.innerWidth - padding) {
      translateX = "-100%";
      leftPos = window.innerWidth - padding;
      arrowLeftPos = `${x - (leftPos - infoWidth)}px`;
    }
    // Center position when there's enough space
    else {
      leftPos = x;
      translateX = "-50%";
      arrowLeftPos = "50%";
    }

    // Calculate vertical position
    let topPos = y;
    let translateY = "20px"; // Default position below marker

    // If the info window would go below the viewport, show it above the marker
    if (y + infoMaxHeight + padding > window.innerHeight) {
      translateY = "-100%";
      topPos = y - 0;
    }

    return {
      infoStyle: {
        left: `${leftPos}px`,
        top: `${topPos}px`,
        transform: `translate(${translateX}, ${translateY})`,
      },
      arrowStyle: {
        left: arrowLeftPos,
        transform: arrowLeftPos === "50%" ? "translateX(-50%)" : "none",
      },
    };
  };

  // Add this useEffect to check for overflow
  useEffect(() => {
    if (hoveredMarker) {
      // Check if content exceeds 2 rows AND there's more content to show
      const checkOverflow = (ref, items) => {
        if (ref.current) {
          const maxHeight = 64; // height for 2 rows
          return ref.current.scrollHeight > maxHeight && items?.length > 0;
        }
        return false;
      };

      setNeedsExpansion({
        donations: checkOverflow(
          donationTypesRef,
          hoveredMarker.donation_types
        ),
        items: checkOverflow(availableItemsRef, hoveredMarker.available_items),
        services: checkOverflow(
          servicesProvidedRef,
          hoveredMarker.servicesProvided
        ),
      });
    }
  }, [hoveredMarker]);

  const animateToLocation = (latitude, longitude, partner) => {
    console.log("Animating to location:", { latitude, longitude, partner });
    if (latitude && longitude) {
      setMapCenter({ lat: latitude, lng: longitude });
      setSelectedPartner(partner);
      console.log("Selected partner set:", partner);
    } else {
      console.error("Invalid latitude or longitude:", { latitude, longitude });
    }
  };

  // Use useEffect to set the initial map center to the user's location
  useEffect(() => {
    if (coordinates && coordinates.lat && coordinates.lon) {
      setMapCenter({ lat: coordinates.lat, lng: coordinates.lon });
    }
  }, [coordinates]);

  // Use useEffect to update the map center and zoom when selectedPartner changes
  useEffect(() => {
    if (selectedPartner) {
      console.log("Selected partner updated:", selectedPartner);
      setMapCenter({
        lat: selectedPartner.latitude,
        lng: selectedPartner.longitude,
      });
      setMapZoom(15);
    }
  }, [selectedPartner]);

  return (
    <div className="relative h-[calc(100vh-64px)] w-full ">
      {/* left Side Panel */}
      <div className="relative">
        <div
          className={`absolute left-0 top-0 z-10 w-[450px] transform bg-white shadow-lg transition-transform ${
            showSidePanel ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="h-[calc(100vh-64px)] bg-white  p-2">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="font-Inter mt-4 w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-[#467BA4] focus:outline-none"
            />

            <div className="font-Inter font-xl mt-4 font-bold text-[#467ba4]">
              <label>List of Partners</label>
            </div>

            <div
              className={`mt-2  overflow-y-auto ${
                role === "citizen"
                  ? "h-[calc(100vh-165px)]"
                  : "h-[calc(100vh-165px)]"
              }`}
            >
              <div>
                {/* Filtered Donor Partners */}
                {donorPartners.length > 0 ? (
                  donorPartners
                    .filter(
                      (partner) =>
                        partner.is_deleted !== true &&
                        partner.company_name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                    )
                    .map((partner) => (
                      <div
                        key={partner.id}
                        className="border-1 mb-2 mr-2 rounded-lg border border-[#1C82D8] bg-[#F9FCFF] p-3 shadow-[2px_2px_4px_2px_rgba(0,0,0,0.2)]"
                        onClick={() => {
                          console.log("Donor partner clicked:", partner);
                          animateToLocation(
                            partner.latitude,
                            partner.longitude,
                            partner
                          );
                        }}
                      >
                        <div className="flex">
                          <img
                            src={dollordonor}
                            alt="dollordonor"
                            className="mr-1 h-6 w-6" // Added icon here
                          />
                          <p className="font-semibold text-[#414141]">
                            {partner.company_name || "No Company Name"}
                          </p>
                        </div>
                        <div className="mt-1 flex items-center gap-2 text-sm text-[#414141] ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="#1C82D8"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p className="text-sm">
                            {partner.address1
                              ? `${partner.address1}${
                                  partner.address2
                                    ? `, ${partner.address2}`
                                    : ""
                                }, ${partner.city || ""}, ${
                                  partner.state || ""
                                } ${partner.zip || ""}`
                              : ""}
                          </p>
                        </div>
                        <div className="mt-1 flex items-center gap-2 text-sm text-[#414141] ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="#1C82D8"
                          >
                            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                          </svg>
                          <p className="text-sm">
                            {partner.phone || "No phone"}
                          </p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-[#414141]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="#1C82D8"
                          >
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                          <p className="text-sm">
                            {partner.email || "No email"}
                          </p>
                        </div>
                        <div>
                          <button
                            className="btn-donate mt-1 flex w-full items-center justify-center gap-2 p-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              animateToLocation(
                                partner.latitude,
                                partner.longitude,
                                partner
                              );
                            }}
                          >
                            <img
                              src={donatedollor}
                              alt="donate"
                              className="w-5b h-5 fill-white"
                            />
                            <span className="text-center">Donate Here</span>
                          </button>
                        </div>
                      </div>
                    ))
                ) : (
                  <p className="text-center text-gray-500"></p>
                )}
                {/* Filtered Partners */}
                {partners.length > 0 ? (
                  partners
                    .filter(
                      (partner) =>
                        partner.is_deleted !== true &&
                        partner.name_of_org
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                    )
                    .map((partner) => (
                      <div
                        key={partner.id}
                        className="border-1 mb-2 mr-2 rounded-lg border border-[#1C82D8] bg-[#F9FCFF] p-3 shadow-[2px_2px_4px_2px_rgba(0,0,0,0.2)]"
                        onClick={() => {
                          console.log("Partner clicked:", partner);
                          animateToLocation(
                            partner.latitude,
                            partner.longitude,
                            partner
                          );
                        }}
                      >
                        <div className="flex">
                          <img
                            src={partnermarkernew}
                            alt="partnermarkernew"
                            className="mr-1 h-6 w-6" // Added icon here
                          />
                          <p className="font-semibold text-[#414141]">
                            {partner.name_of_org || "No Org Name"}
                          </p>
                        </div>
                        <div className="mt-1 flex items-center gap-2 text-sm text-[#414141] ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="#1C82D8"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <p className="text-sm">
                            {partner.address1
                              ? `${partner.address1}${
                                  partner.address2
                                    ? `, ${partner.address2}`
                                    : ""
                                }, ${partner.city || ""}, ${
                                  partner.state || ""
                                } ${partner.zip || ""}`
                              : ""}
                          </p>
                        </div>
                        <div className="mt-1 flex items-center gap-2 text-sm text-[#414141] ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="#1C82D8"
                          >
                            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                          </svg>
                          <p className="text-sm">
                            {partner.mobile_number || "No phone"}
                          </p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-[#414141]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="#1C82D8"
                          >
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                          <p className="text-sm">
                            {partner.email || "No email"}
                          </p>
                        </div>
                        <div>
                          <button
                            className="btn-donate mt-1 flex w-full items-center justify-center gap-2 p-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              animateToLocation(
                                partner.latitude,
                                partner.longitude,
                                partner
                              );
                            }}
                          >
                            <img
                              src={donate}
                              alt="donate"
                              className="w-5b h-5 fill-white"
                            />
                            <span className="text-center">Donate Here</span>
                          </button>
                        </div>
                      </div>
                    ))
                ) : (
                  <p className="text-center text-gray-500"></p>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Toggle Button (Now on the Left) */}
        <div
          className={`absolute left-0 top-0 z-10 mt-[50vh] h-full w-8 transform cursor-pointer bg-white shadow-lg transition-transform ${
            showSidePanel ? "translate-x-[450px]" : "ml-0 -translate-x-0"
          }`}
          onClick={() => setShowSidePanel(!showSidePanel)}
        >
          <div className="rounded-r border-b border-r border-t border-solid border-gray-400 bg-white p-2">
            {showSidePanel ? "❮" : "❯"}
          </div>
        </div>
      </div>

      <APIProvider apiKey={config.google_maps}>
        <VISGoogleMap
          key={mapCenter ? `${mapCenter.lat}-${mapCenter.lng}` : "default-map"}
          defaultZoom={mapZoom}
          defaultCenter={mapCenter || { lat: 39.8, lng: -98.57 }}
          options={{
            disableDefaultUI: true,
            gestureHandling: "greedy",
            styles: [
              { featureType: "poi", stylers: [{ visibility: "off" }] },
              { featureType: "transit", stylers: [{ visibility: "off" }] },
            ],
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: window.google?.maps?.ControlPosition?.TOP_LEFT,
              style: window.google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR,
              mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain"],
            },
            fullscreenControl: true,
            streetViewControl: true,
            scaleControl: true,
            zoomControl: true,
            rotateControl: true,
            controlSize: 28,
            streetView: {
              visible: true,
              enableCloseButton: true,
              motionTracking: false,
            },
          }}
          ref={mapRef}
          onLoad={handleMapLoad}
        >
          {/* Render current user marker */}
          {coordinates && coordinates.lat && coordinates.lon && role && (
            <CustomMarkerWithLabel
              position={{ lat: coordinates.lat, lng: coordinates.lon }}
              icon={getMarkerImage(role, true)}
              label={"You"}
              onMouseOver={(event) => handleMarkerHover(data, event)}
              onMouseOut={handleMarkerLeave}
              role={"current_user"}
            />
          )}

          {/* Render related users with the same center_id */}
          {relatedUsers
            .filter(
              (user, index, self) =>
                user.id !== userId &&
                !(
                  user.latitude === coordinates?.lat &&
                  user.longitude === coordinates?.lon
                ) &&
                index === self.findIndex((t) => t.id === user.id) &&
                user.latitude != null &&
                user.longitude != null
            )
            .map((user) => {
              const role = user.user_role || user.userType?.toLowerCase();
              const labelText =
                role === "partner"
                  ? user.name_of_org
                    ? `${user.name_of_org}`
                    : "No Org Name"
                  : user.name || " ";

              const markerIcon = getMarkerImage(role, false);

              return (
                <StreetViewMarker
                  key={user.id}
                  position={{ lat: user.latitude, lng: user.longitude }}
                  icon={markerIcon}
                  label={labelText}
                  onMouseOver={(event) => handleMarkerHover(user, event)}
                  onMouseOut={handleMarkerLeave}
                  role={role}
                />
              );
            })}

          {/* Render all donor partners on the map */}
          {donorPartners.map((donor) => (
            <StreetViewMarker
              key={donor.id}
              position={{ lat: donor.latitude, lng: donor.longitude }}
              icon={getMarkerImage("donor-partner", false)}
              label={donor.company_name || "No Company Name"}
              onMouseOver={(event) => handleMarkerHover(donor, event)}
              onMouseOut={handleMarkerLeave}
              role="donor-partner"
              style={{ zIndex: 10 }}
            >
              <button
                className="btn-donate mt-1 flex w-full items-center justify-center gap-2 p-2"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from bubbling up
                  e.preventDefault(); // Prevent default action
                  if (donor.website_url) {
                    window.open(donor.website_url, "_blank");
                  }
                }}
                style={{ zIndex: 20 }} // Ensure button is on top
              >
                <img src={donatedollor} alt="donate" className="h-5 w-5" />
                <span className="text-center">Donate Here</span>
              </button>
            </StreetViewMarker>
          ))}

          {/* Render the selected partner's marker on the map only if it's not a donor partner */}
          {selectedPartner &&
            !donorPartners.some((d) => d.id === selectedPartner.id) && (
              <StreetViewMarker
                key={selectedPartner.id}
                position={{
                  lat: selectedPartner.latitude,
                  lng: selectedPartner.longitude,
                }}
                icon={getMarkerImage("partner", false)}
                label={selectedPartner.name_of_org || "No Company Name"}
                onMouseOver={(event) =>
                  handleMarkerHover(selectedPartner, event)
                }
                onMouseOut={handleMarkerLeave}
                role="partner"
                style={{ zIndex: 20 }}
              />
            )}

          {/* Render the "You" marker when no partner is selected */}
          {!selectedPartner &&
            coordinates &&
            coordinates.lat &&
            coordinates.lon && (
              <CustomMarkerWithLabel
                position={{ lat: coordinates.lat, lng: coordinates.lon }}
                icon={getMarkerImage(role, true)}
                label={"You"}
                onMouseOver={(event) => handleMarkerHover(data, event)}
                onMouseOut={handleMarkerLeave}
                role={"current_user"}
                style={{ zIndex: 30 }}
              />
            )}

          {/* Update info window to handle hover */}
          {hoveredMarker &&
            hoveredMarker.user_role !== "citizen" &&
            mousePosition && (
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  boxShadow: "12px 12px 60px rgba(0,0,0,0.8)",
                  zIndex: 1000,
                  width: "650px",
                  maxHeight: "450px",
                  opacity: 1,
                  transition: "all 0.15s ease-out",
                  willChange: "transform, opacity, left",
                  overflow: "hidden",
                  ...getInfoPosition(mousePosition.x, mousePosition.y)
                    .infoStyle,
                }}
                className="relative hover:cursor-default"
                onMouseEnter={handleInfoMouseEnter}
                onMouseLeave={handleInfoMouseLeave}
              >
                {/* Add invisible buffer zone */}
                <div
                  style={{
                    position: "absolute",
                    top:
                      mousePosition.y > window.innerHeight / 2
                        ? "100%"
                        : "-20px",
                    left: 0,
                    width: "100%",
                    height: "20px",
                  }}
                  onMouseEnter={handleInfoMouseEnter}
                />

                {/* Arrow with dynamic positioning */}
                <div
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    [mousePosition.y > window.innerHeight / 2
                      ? "bottom"
                      : "top"]: "-20px",
                    width: "30px",
                    height: "20px",
                    background: "#1387EA",
                    clipPath:
                      mousePosition.y > window.innerHeight / 2
                        ? "polygon(50% 100%, 0 0, 100% 0)"
                        : "polygon(50% 0, 0 100%, 100% 100%)",
                    zIndex: 9999,
                    pointerEvents: "none",
                  }}
                />

                {/* Content container */}
                <div
                  className="relative z-10 overflow-y-auto"
                  style={{
                    maxHeight: "calc(450px - 24px)",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#808080 white", // Gray scrollbar with white background
                    boxShadow: "inset 0 12px 24px -12px rgba(0,0,0,0.5)",
                  }}
                >
                  {renderMarkerInfo()}
                </div>
              </div>
            )}
        </VISGoogleMap>
      </APIProvider>
    </div>
  );
};

export default Donate;
