import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import CustomInput from "./CustomInput";
import { useForm } from "react-hook-form";
import fileUploadIcon from "../assets/imageUploader/file-upload-icon.png";
import deleteFIleIcon from "../assets/imageUploader/delete-file-icon.png";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import CustomSelect from "./CustomSelect";

const FileUploader = ({
  title,
  onSave,
  storagePath,
  children,
  className,
  style,
  defaultValues,
  index,
}) => {
  const [toggleUploadModal, setToggleUploadModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const formRef = useRef();
  const inputFileRef = useRef();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      file: "",
      type: "",
      verified_by_id: "",
      verified_by_name: "",
      verified_by_role: "",
      verified_date: "",
      declined_date: "",
      status: "not-verified",
    },
  });

  const watchedFile = watch("file");

  const employeeDetails = JSON.parse(sessionStorage.getItem("employeeDetails"));

  const onUploadFile = useCallback(
    async (e) => {
      const file = e.target.files[0];

      if (!file && !storagePath) {
        alert("Something went wrong while uploading the file.");
        return;
      }

      const path = `${storagePath}/${file.name}`;
      const uploadFileRef = ref(storage, path);

      if (!!employeeDetails) {
        const { role, first_name, last_name, id } = employeeDetails || {};
        if (role === "Case Supervisor" || role === "Case Manager") {
          const currentDate = new Date();
          currentDate.toLocaleDateString("en-US");

          const formattedCurrentDate = `${
            currentDate.getMonth() + 1
          }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

          setValue("verified_by_id", id);
          setValue("verified_by_name", `${first_name} ${last_name}`);
          setValue("verified_by_role", role);
          setValue("status", "verified");
          setValue("verified_date", formattedCurrentDate);
        }
      }

      try {
        setUploading(() => true);
        const uploadFile = await uploadBytes(uploadFileRef, file);
        const downloadUrl = await getDownloadURL(uploadFile.ref);
        setValue("file", downloadUrl);
        setValue("type", file.type);
        setUploading(() => false);
      } catch (error) {
        setUploading(() => false);
        alert("Something went wrong while uploading the file.");
      }
    },
    [storagePath, employeeDetails]
  );

  const onFormSubmit = useCallback(
    (props) => {
      const date = new Date();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      const { file, ...rest } = props || {};
      onSave?.(
        {
          ...rest,
          src: file,
          uploaded_at: `${month}/${day}/${year}`,
        },
        index
      );
      reset();
      setToggleUploadModal(() => false);
    },
    [index]
  );

  const renderFileList = useMemo(() => {
    return !!watchedFile ? (
      <div className="relative flex h-[300px] rounded-md border border-solid border-[#CFCFCF]">
        <img src={watchedFile} className="h-full w-full object-cover" />
        <button
          className="absolute right-3 top-3 h-[40px] w-[40px] cursor-pointer"
          onClick={() => {
            setValue("file", "");
          }}
        >
          <img src={deleteFIleIcon} className="h-full w-full object-contain" />
        </button>
      </div>
    ) : (
      <div
        className="flex h-[300px] cursor-pointer flex-col items-center justify-center space-y-3 rounded-md border border-dashed border-[#003460]"
        onClick={() => inputFileRef.current.click()}
      >
        <div className="h-[30px] w-[30px]">
          <img src={fileUploadIcon} className="h-full w-full object-contain" />
        </div>
        <p className="text-sm text-[#525252]">
          {uploading ? "Uploading..." : "Click to Upload"}
        </p>
      </div>
    );
  }, [watchedFile, uploading]);

  const renderUploadModal = useMemo(() => {
    return (
      <Modal
        title={title}
        isEdit={true}
        isOpen={toggleUploadModal}
        onClose={() => {
          reset();
          setToggleUploadModal(() => false);
        }}
        formRef={formRef}
        disabled={!!!watchedFile}
      >
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          ref={formRef}
          className="grid grid-cols-1 gap-4"
        >
          <input
            type="file"
            className="hidden"
            disabled={uploading}
            ref={inputFileRef}
            onChange={onUploadFile}
          />
          {renderFileList}
          <div className="flex items-center space-x-3">
            <div className="flex-1">
              <CustomInput
                placeholder="Name"
                name="name"
                type="text"
                register={{
                  ...register("name", {
                    required: "This field is Required!",
                  }),
                }}
                errors={errors}
              />
            </div>
            {(employeeDetails?.role === "Case Supervisor" ||
              employeeDetails?.role === "Case Manager") &&
              !!defaultValues && (
                <div className="flex-1">
                  <CustomSelect
                    placeholder="Status"
                    options={[
                      { label: "Not Verified", value: "not-verified" },
                      { label: "Verified", value: "verified" },
                    ]}
                    name="status"
                    register={{
                      ...register("status", {
                        required: "This field is Required!",
                      }),
                    }}
                    errors={errors}
                  />
                </div>
              )}
          </div>
          <CustomInput
            placeholder="Descriptions"
            name="description"
            type="text"
            register={{
              ...register("description", {
                required: "This field is Required!",
              }),
            }}
            errors={errors}
          />
        </form>
      </Modal>
    );
  }, [
    toggleUploadModal,
    title,
    errors,
    onUploadFile,
    defaultValues,
    renderFileList,
    uploading,
  ]);

  useEffect(() => {
    if (!!defaultValues) {
      Object.entries(defaultValues).map(([key, val]) => {
        if (key === "src") {
          setValue("file", val);
        } else if (key === "status") {
          setValue(key, val !== "verified" ? "not-verified" : "verified");
        } else {
          setValue(key, val);
        }
      });
    }
  }, [defaultValues]);

  return (
    <>
      <button
        className={`${className} w-full cursor-pointer bg-transparent`}
        style={style}
        onClick={() => {
          setToggleUploadModal(() => true);
        }}
      >
        {children || (
          <div className="flex h-[136px] w-full cursor-pointer flex-col items-center justify-center space-y-3 rounded-md border border-dashed border-[#003460]">
            <div className="h-[30px] w-[30px]">
              <img
                src={fileUploadIcon}
                className="h-full w-full object-contain"
              />
            </div>
            <p className="text-sm text-[#525252]">Click to Upload</p>
          </div>
        )}
      </button>
      {renderUploadModal}
    </>
  );
};

FileUploader.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onSave: PropTypes.func,
  storagePath: PropTypes.string,
  className: PropTypes.string,
};

export default FileUploader;
