import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import LockImage from "../assets/lockbox.png";
import {
  FaEdit,
  FaChevronDown,
  FaChevronUp,
  FaSave,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import {
  CheckBoxSelection,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnMenu,
  ColumnsDirective,
  CommandColumn,
  Edit,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { UploaderComponent, Uploader } from "@syncfusion/ej2-react-inputs";
import LockBoxService from "../service/citizen/LockboxService";
import { ImageDialog } from "./Disasters/AssistanceLog";
import { db, auth } from "../firebase";
import {
  and,
  collection,
  doc,
  getDoc,
  query,
  where,
  or,
  getDocs,
  serverTimestamp,
  updateDoc,
  orderBy
} from "firebase/firestore";
import { FaTrash } from "react-icons/fa";
import { Query } from "@syncfusion/ej2-data";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { Controller, useForm } from "react-hook-form";
import Calendar from "../assets/disaster-icons/calendar.svg";
import NavigationArrow from "../assets/disaster-icons/navigation_arrow.svg";
import panZoomIcon from "../assets/disaster-icons/pan_zoom.svg";
import AddNewButton from "../components/AddNewButton";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import CustomTextArea from "../components/CustomTextArea";
import DeleteModal from "../components/DeleteModal";
import Modal from "../components/Modal";
import ModalFileViewer from "../components/ModalFileViewer";
import PdfViewer from "../components/PDFViewer";
import PdfViewerMultiplePage from "../components/PDFViewerMultiplePage";
import PhotoColumn from "../components/PhotoColumn";
import SimpleFileUploader from "../components/SimpleFileUploader";
import ThreeDotMenu from "../components/ThreeDotMenu";
import ViewPhotosModal from "../components/ViewPhotosModal";
import { decrypt } from "../service/encryption/Encryption";
import HeaderTitle from "../components/HeaderTitle";
import ShowPin from "./Profile-Popup/ShowPin";
import CustomMultiSelect from "../components/CustomMultiSelect";

const sortSettings = {
  allowSorting: true,
};
const columnMenuItems = ["SortAscending", "SortDescending", "Filter"];

const pageSettings = { pageSize: 10 };

const editing = {
  allowDeleting: true,
  allowAdding: true,
  allowEditing: true,
  mode: "Dialog",
  showDeleteConfirmDialog: true,
  allowEditOnDblClick: false,
  dialog: { cssClass: "custom-dialog" },
};

const filterSettings = {
  type: "CheckBox",
};

const datepickerparams = {
  params: {
    format: "MM/dd/yyyy",
    value: new Date(),
    showClearButton: false,
    showTodayButton: false,
    start: "Decade",
    depth: "Day",
  },
};

// const documentTypes = [
//   { idName: "Driver's License", id: "1" },
//   { idName: "Credit Cards/Banking Information", id: "2" },
//   { idName: "Insurance Information", id: "3" },
//   { idName: "Passport", id: "4" },
//   { idName: "Titles/Deed/Registration", id: "5" },
// ];

const documentTypes = [
  { label: "Driver's License", value: "Driver's License" },
  {
    label: "Credit Cards/Banking Information",
    value: "Credit Cards/Banking Information",
  },
  { label: "Insurance Information", value: "Insurance Information" },
  { label: "Passport", value: "Passport" },
  { label: "Titles/Deed/Registration", value: "Titles/Deed/Registration" },
];

const typeParams = {
  params: {
    allowFiltering: true,
    dataSource: documentTypes,
    fields: { text: "idName", value: "idName" },
    query: new Query(),
  },
};

const Lockbox = ({ className = "", onNavigateBack }) => {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [enteredPIN, setEnteredPIN] = useState("");
  const [correctPIN, setCorrectPIN] = useState("1111");
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [PhotosData, setPhotosData] = useState([]);
  const [isDocumentsVisible, setIsDocumentsVisible] = useState(true);
  const [accessOptions, setAccessOptions] = useState([]);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showPinInstructions, setShowPinInstructions] = useState(
    localStorage.getItem("pinMessageShown") !== "true"
  );
  const [showPin, setShowPin] = useState(false);
  const [pinShow, setPinShow] = useState(false);
  const [accessedUserId, setAccessedUserId] = useState(null);
  const selectedUserId = sessionStorage.getItem("userId");
  const [currentPhotos, setCurrentPhotos] = useState([]);

  // PHOTOS
  const [showViewPhotosModal, setShowViewPhotosModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);

  // DOCUMENTS
  const [showViewDocumentsModal, setShowViewDocumentsModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUserId(selectedUserId);
        // Fetch the user's PIN
        const userDocRef = doc(db, "users", selectedUserId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().pin) {
          setCorrectPIN(decrypt(userDoc.data().pin));
          if (decrypt(userDoc.data().pin) === "7777") {
            setTimeout(() => setPinShow(true), 100);
            console.log("pinShow: ", pinShow);
          }
        }
        // If no PIN is set, it will use the default '1111'
      } else {
        setData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Update the data fetching effect to use userId
  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      // Fetch user document
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setData({ id: userDoc.id, ...userDoc.data() });

        const documents = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "documents_data"
        );

        const activeDocuments = documents.filter((doc) => !doc.is_deleted);

        setDocumentsData(activeDocuments);

        const photos = await LockBoxService.fetchDocumentsFromLockbox(
          userId,
          "photos_data"
        );

        // Filter out photos with status set to false
        const activePhotos = photos.filter((photo) => !photo.is_deleted);

        setPhotosData(activePhotos);
        let centerId = userDoc.data().center_id;
        // If center_id is empty, set it to "1" to avoid matching with documents with empty center_id
        if (centerId === "") {
          centerId = "1234";
        }

        const q = query(
          collection(db, "users"),
          and(
            or(
              where("center_id", "array-contains", centerId),
              where("center_id", "==", centerId)
            ),
            or(
              where("user_role", "==", "center"),
              where("user_role", "==", "partner")
            )
          )
        );
        const querySnapshot = await getDocs(q);

        const options = await Promise.all(
          querySnapshot.docs.map(async (document) => {
            const userData = document.data();
            console.log("userData: ", userData);

            if (userData.user_role === "center") {
              const centerDocRef = doc(db, "centers", userData.center_id);
              const centerDoc = await getDoc(centerDocRef);

              if (centerDoc.exists()) {
                const centerData = centerDoc.data();
                console.log("Center Data: ", centerData);
                return {
                  value: centerData.name_of_hub,
                  text: `${centerData.name_of_hub} (Center)`,
                };
              } else {
                console.error("No such center document!");
              }
            } else if (userData.user_role === "partner") {
              const partnerDocRef = doc(db, "partners", userData.partner_id);
              const partnerDoc = await getDoc(partnerDocRef);
              if (partnerDoc.exists()) {
                const partnerData = partnerDoc.data();
                console.log("Partner Data: ", partnerData);
                return {
                  value: partnerData.name_of_org,
                  text: `${partnerData.name_of_org} (Partner)`,
                };
              } else {
                console.error("No such partner document!");
              }
            }

            return null; // Ensure a valid return to avoid empty elements in the final array
          })
        );

        setAccessOptions([
          ...options,
          // ,
          // {
          //   value: "Disaster Case Management Agency",
          //   text: "Disaster Case Management Agency",
          // },
        ]);
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const [showPinMessage, setShowPinMessage] = useState(
    localStorage.getItem("pinMessageShown") !== "true"
  );

  const handleUnlock = async () => {
    if (enteredPIN === correctPIN) {
      setIsUnlocked(true);
      setShowPinInstructions(false);
      localStorage.setItem("pinMessageShown", "true");
    } else {
      setWrongPinMessage(true);
      setEnteredPIN("");
      setInputValues(["", "", "", ""]);
      inputRefs.current[0].focus();

      setTimeout(() => {
        setWrongPinMessage(false);
      }, 3000);
    }
  };

  const onInput = (index, event) => {
    const value = event.target.value;
    if (/^\d$/.test(value)) {
      const newValues = [...inputValues];
      newValues[index] = value;
      setInputValues(newValues);
      setEnteredPIN(newValues.join(""));

      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const onKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      event.preventDefault();

      const newValues = [...inputValues];

      if (inputValues[index]) {
        newValues[index] = "";
      } else if (index > 0) {
        newValues[index - 1] = "";
        inputRefs.current[index - 1].focus();
      }

      setInputValues(newValues);
      setEnteredPIN(newValues.join(""));
    }
  };
  const handleGoBack = () => {
    // Call the onNavigateBack function passed as a prop
    window.history.back();
    window.history.back();
  };

  let elem;
  let uploadObj;
  let strm;
  let selectedRow;
  // const accessOptions = [
  //   { value: "Local Recovery Center", text: "Local Recovery Center" },
  //   { value: "Recovery Center Partners", text: "Recovery Center Partners" },
  //   { value: "Local Emergency Management", text: "Local Emergency Management" },
  // ];
  const fields = { value: "value", text: "text" };

  const [isPinCorrect, setIsPinCorrect] = useState(false);
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [wrongPinMessage, setWrongPinMessage] = useState(false);
  const inputRefs = useRef([]);

  const correctPin = "1111"; // Set your correct PIN here

  const checkPin = (newValues) => {
    const enteredPin = newValues.join("");
    if (enteredPin === correctPIN) {
      setIsPinCorrect(true);
      setWrongPinMessage(false);
    } else if (enteredPin.length === 4) {
      setWrongPinMessage(true);
      // Clear input values
      setInputValues(["", "", "", ""]);
      // Focus on the first input
      inputRefs.current[0].focus();
      // Set a timeout to clear the wrong PIN message
      setTimeout(() => {
        setWrongPinMessage(false);
      }, 3000); // Message will disappear after 3 seconds
    } else {
      setWrongPinMessage(false);
    }
  };

  {
    /* Documents */
  }
  const [documentAccessData, setDocumentAccessData] = useState({ access: [] });

  const accessEditTemplateDocuments = (props) => {
    const currentAccess = Array.isArray(props?.access) ? props.access : [];

    return (
      <div>
        <label htmlFor="access" className="mb-2 block text-xs text-gray-500">
          People with Access
        </label>
        <MultiSelectComponent
          id="access"
          dataSource={accessOptions}
          fields={fields}
          value={currentAccess}
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  const toggleDocuments = (event) => {
    event.preventDefault();
    setIsDocumentsVisible(!isDocumentsVisible);
  };

  const PhotosGridRef = useRef(null);

  const handleAddClickdocuments = () => {
    if (documentGridRef.current) {
      documentGridRef.current.addRecord();
    }
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  async function uploadFileToStorage(filePath, fileData, fileType) {
    const storage = getStorage();
    const timestamp = Date.now();
    const uniqueFilePath = `${filePath.split(".")[0]}_${timestamp}.${
      filePath.split(".")[1]
    }`;
    const storageRef = ref(storage, uniqueFilePath);

    if (fileType.startsWith("image/")) {
      await uploadString(storageRef, fileData, "data_url");
    } else {
      await uploadBytes(storageRef, fileData);
    }

    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  }

  function compressImage(file, maxSizeMB) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = img.width;
          const maxHeight = img.height;
          let width = img.width;
          let height = img.height;

          // Resize the image maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.floor((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.floor((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          let quality = 0.9; // Start with high quality
          let dataUrl = canvas.toDataURL("image/jpeg", quality);

          // Compress the image by reducing quality until it's below maxSizeMB
          while (dataUrl.length / 1024 / 1024 > maxSizeMB && quality > 0.1) {
            quality -= 0.1;
            dataUrl = canvas.toDataURL("image/jpeg", quality);
          }

          resolve(dataUrl);
        };

        img.onerror = function (error) {
          reject(error);
        };
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  // Upload image to Firebase Storage
  async function uploadImageToStorage(filePath, dataUrl) {
    const storage = getStorage();
    const timestamp = Date.now();
    const uniqueFilePath = `${filePath.split(".")[0]}_${timestamp}.${
      filePath.split(".")[1]
    }`;
    const storageRef = ref(storage, uniqueFilePath);

    await uploadString(storageRef, dataUrl, "data_url");

    const downloadURL = await getDownloadURL(storageRef);

    return downloadURL;
  }

  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const handleFileClick = (files, props) => {
    console.log("props: ", props);
    if (Array.isArray(files) && files.length > 0) {
      // Format files with additional metadata from the record
      const formattedFiles = files.map((file) => ({
        ...file,
        type: file.type || "image/",
        description: props.description,
        date_inserted: props.date_inserted,
        access: props.access,
        name: file.name || props.name,
      }));

      setSelectedFiles(formattedFiles);
      setCurrentFileIndex(0);
      setIsFileDialogOpen(true);
    }
  };

  // Add this component to your render method
  // const FileDialog = ({
  //   isOpen,
  //   onClose,
  //   files,
  //   currentIndex,
  //   setCurrentIndex,
  // }) => {
  //   if (!isOpen || !Array.isArray(files) || files.length === 0) return null;

  //   const file = files[currentIndex];
  //   const isImage = file.type && file.type.startsWith("image/");
  //   const fileName = file.name || "Untitled File";

  //   const handlePrev = () => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex > 0 ? prevIndex - 1 : files.length - 1
  //     );
  //   };

  //   const handleNext = () => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex < files.length - 1 ? prevIndex + 1 : 0
  //     );
  //   };

  //   const handleBackgroundClick = (e) => {
  //     if (e.target === e.currentTarget) {
  //       onClose();
  //     }
  //   };

  //   return (
  //     <div
  //       className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
  //       onClick={handleBackgroundClick}
  //     >
  //       <div className="relative flex h-[80vh] w-full max-w-4xl flex-col">
  //         <div className="absolute left-0 right-0 top-0 flex items-center justify-between rounded-t-lg bg-black bg-opacity-50 p-4 text-white">
  //           <h2 className="text-xl truncate font-bold">{fileName}</h2>
  //           <button
  //             onClick={onClose}
  //             className="text-3xl flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
  //           >
  //             &times;
  //           </button>
  //         </div>
  //         <div className="relative mt-16 flex-grow bg-black">
  //           {isImage ? (
  //             <div className="absolute inset-0 flex items-center justify-center">
  //               <img
  //                 src={file.src}
  //                 alt={fileName}
  //                 className="max-h-full max-w-full object-contain"
  //               />
  //             </div>
  //           ) : (
  //             <iframe
  //               src={file.src}
  //               title={fileName}
  //               className="absolute inset-0 h-full w-full"
  //             />
  //           )}
  //           {files.length > 1 && (
  //             <>
  //               <button
  //                 onClick={handlePrev}
  //                 className="absolute left-4 top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
  //               >
  //                 &lt;
  //               </button>
  //               <button
  //                 onClick={handleNext}
  //                 className="absolute right-4 top-1/2 flex h-12 w-12 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all hover:bg-opacity-75"
  //               >
  //                 &gt;
  //               </button>
  //             </>
  //           )}
  //         </div>
  //         <div className="flex items-center justify-between rounded-b-lg bg-black bg-opacity-50 p-4 text-white">
  //           <p className="text-sm">
  //             {isImage ? "Image" : "Document"} {currentIndex + 1} of{" "}
  //             {files.length}
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  // ... existing code ...
  const FullViewModal = ({ isOpen, onClose, imageSrc, imageAlt, fileType }) => {
    if (!isOpen) return null;
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
        <div className="relative flex h-full w-full flex-col items-center justify-center p-4">
          {fileType.includes("pdf") ? (
            <PdfViewerMultiplePage fileUrl={imageSrc} />
          ) : (
            <div className="flex items-center justify-center">
              <img
                src={imageSrc}
                alt={imageAlt}
                className="h-[600px] w-[1000px] object-contain" // Fixed size but keeps aspect ratio
              />
            </div>
          )}
          <div className="mt-4 flex justify-center">
            <button
              onClick={onClose}
              className="flex h-10 w-10 items-center justify-center rounded-full bg-[#E72528] text-white transition-colors hover:text-gray-200"
              title="Close"
            >
              &#10005;
            </button>
          </div>
        </div>
      </div>
    );
  };

  const FileDialog = ({
    isOpen,
    onClose,
    files,
    currentIndex,
    setCurrentIndex,
  }) => {
    const [isFullViewOpen, setIsFullViewOpen] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editFormData, setEditFormData] = useState({
      name: "",
      description: "",
      label: "",
      tags: "",
    });

    if (!isOpen || !Array.isArray(files) || files.length === 0) return null;

    const file = files[currentIndex];
    const isImage = file.type && file.type.startsWith("image");
    const fileName = file.name || "Document Name";

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : files.length - 1
      );
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex < files.length - 1 ? prevIndex + 1 : 0
      );
    };

    const handleFullViewClick = () => {
      setIsFullViewOpen(true);
    };

    const handleUploadNewDocument = () => {
      // Find the document in the grid data that contains the current file
      const currentDoc = documentsData.find((doc) => {
        if (Array.isArray(doc.photodocuments)) {
          return doc.photodocuments.some(
            (pdoc) => pdoc.src === file.src || pdoc === file.src
          );
        }
        return false;
      });

      if (!currentDoc) {
        console.error("Could not find the current document record");
        return;
      }

      // Create a temporary file input element
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.multiple = true;
      fileInput.accept = ".pdf, .jpg,.jpeg,.png";
      fileInput.style.display = "none";

      // Add it to the DOM
      document.body.appendChild(fileInput);

      // Set up event listener for when files are selected
      fileInput.addEventListener("change", async (event) => {
        if (event.target.files.length > 0) {
          // Create a progress indicator
          const progressContainer = document.createElement("div");
          progressContainer.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 4px;
          background-color: #e5e7eb;
          z-index: 9999;
        `;

          const progressBar = document.createElement("div");
          progressBar.style.cssText = `
          height: 100%;
          background-color: #3b82f6;
          width: 0%;
          transition: width 0.3s ease;
        `;

          progressContainer.appendChild(progressBar);
          document.body.appendChild(progressContainer);

          // Process the files
          const newFiles = [];

          for (const file of event.target.files) {
            try {
              // Update progress to 50%
              progressBar.style.width = "50%";

              let fileData = file;
              let isImage = file.type.startsWith("image/");

              if (isImage) {
                fileData = await compressImage(file, 1);
              }

              const filePath = `lockbox/documents/${userId}/${file.name}`;
              const downloadURL = await uploadFileToStorage(
                filePath,
                fileData,
                file.type
              );

              // Create a new file object
              const newFile = {
                src: downloadURL,
                name: file.name,
                type: file.type,
                details: "",
                dateAdded: new Date().toISOString(),
              };

              newFiles.push(newFile);
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          }

          // Update progress to 80%
          progressBar.style.width = "80%";

          if (newFiles.length > 0) {
            try {
              // Update the document with the new files
              const updatedDoc = { ...currentDoc };
              updatedDoc.photodocuments = Array.isArray(
                updatedDoc.photodocuments
              )
                ? [...updatedDoc.photodocuments, ...newFiles]
                : newFiles;
              updatedDoc.modified_date = new Date().toLocaleDateString("en-US");

              // Update the document in the database
              await LockBoxService.updateDocumentInLockbox(
                userId,
                currentDoc.documentsID,
                updatedDoc,
                "documents_data"
              );

              // Update progress to 100%
              progressBar.style.width = "100%";

              // Refresh the data
              await fetchData();

              // Update the files in the current view
              const updatedFiles = [...selectedFiles, ...newFiles];
              setSelectedFiles(updatedFiles);

              // Show success message
              setTimeout(() => {
                progressBar.style.backgroundColor = "#10b981"; // Success color

                // Remove progress indicator after a delay
                setTimeout(() => {
                  document.body.removeChild(progressContainer);
                }, 1000);
              }, 500);
            } catch (error) {
              console.error("Error updating document:", error);
              progressBar.style.backgroundColor = "#ef4444"; // Error color

              // Remove progress indicator after a delay
              setTimeout(() => {
                document.body.removeChild(progressContainer);
              }, 2000);
            }
          } else {
            // No files were successfully processed
            progressBar.style.backgroundColor = "#ef4444"; // Error color

            // Remove progress indicator after a delay
            setTimeout(() => {
              document.body.removeChild(progressContainer);
            }, 2000);
          }
        }

        // Clean up
        document.body.removeChild(fileInput);
      });

      // Trigger the file selection dialog
      fileInput.click();
    };

    const handleDeleteClick = () => {
      setShowDeleteConfirm(true);
    };

    const handleDeleteCancel = () => {
      setShowDeleteConfirm(false);
    };

    const handleDeleteConfirm = async () => {
      // Find the document in the grid data that contains the current file
      const currentDoc = documentsData.find((doc) => {
        if (Array.isArray(doc.photodocuments)) {
          return doc.photodocuments.some(
            (pdoc) => pdoc.src === file.src || pdoc === file.src
          );
        }
        return false;
      });

      if (!currentDoc) {
        console.error("Could not find the current document record");
        setShowDeleteConfirm(false);
        return;
      }

      try {
        // Remove the file from the photodocuments array
        const updatedPhotodocuments = currentDoc.photodocuments.filter(
          (doc) => doc.src !== file.src
        );

        // Update the document with the filtered array
        const updatedDoc = {
          ...currentDoc,
          photodocuments: updatedPhotodocuments,
          modified_date: new Date().toLocaleDateString("en-US"),
        };

        // Update in the database
        await LockBoxService.updateDocumentInLockbox(
          userId,
          currentDoc.documentsID,
          updatedDoc,
          "documents_data"
        );

        // Refresh the data
        await fetchData();

        // Close the dialog if there are no more files
        if (updatedPhotodocuments.length === 0) {
          onClose();
        } else {
          // Update the selected files
          const updatedFiles = selectedFiles.filter((f) => f.src !== file.src);
          setSelectedFiles(updatedFiles);

          // Adjust current index if needed
          if (currentIndex >= updatedFiles.length) {
            setCurrentIndex(updatedFiles.length - 1);
          }
        }
      } catch (error) {
        console.error("Error deleting file:", error);
      }

      setShowDeleteConfirm(false);
    };
    const handleEditClick = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const currentDoc = documentsData.find((doc) => {
        if (Array.isArray(doc.photodocuments)) {
          return doc.photodocuments.some(
            (pdoc) => pdoc.src === file.src || pdoc === file.src
          );
        }
        return false;
      });

      if (!currentDoc) {
        console.error("Could not find the current document record");
        return;
      }

      setIsFileDialogOpen(false);
      setIsEditingDocuments(true);
      setShowDocumentsModal(true);
      setActiveMenuDocuments(null);

      documentsReset({
        title: currentDoc?.title || "",
        description: currentDoc?.description || "",
        access: currentDoc?.access || "",
        modified_date: currentDoc?.modified_date || "",
        relationship: currentDoc?.relationship || "",
        type: currentDoc?.type || "",
        photodocuments: currentDoc?.photodocuments || [],
        documentsID: currentDoc?.documentsID || "",
        ...currentDoc,
      });
    };

    const handleEditCancel = () => {
      setShowEditForm(false);
    };

    const handleEditFormChange = (e) => {
      const { name, value } = e.target;
      setEditFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleEditSave = async () => {
      // Find the document in the grid data that contains the current file
      const currentDoc = documentsData.find((doc) => {
        if (Array.isArray(doc.photodocuments)) {
          return doc.photodocuments.some(
            (pdoc) => pdoc.src === file.src || pdoc === file.src
          );
        }
        return false;
      });

      if (!currentDoc) {
        console.error("Could not find the current document record");
        setShowEditForm(false);
        return;
      }

      try {
        // Create updated photodocuments array with edited file label and description
        const updatedPhotodocuments = currentDoc.photodocuments.map((doc) => {
          if (doc.src === file.src) {
            return {
              ...doc,
              description: editFormData.description, // Add description to the file
              label: editFormData.label,
              lastEdited: new Date().toISOString(),
            };
          }
          return doc;
        });

        // Update the document with the modified description and photodocuments array
        const updatedDoc = {
          ...currentDoc,
          description: editFormData.description, // Update the record's description
          photodocuments: updatedPhotodocuments,
          modified_date: new Date().toLocaleDateString("en-US"),
        };

        // Update in the database
        await LockBoxService.updateDocumentInLockbox(
          userId,
          currentDoc.documentsID,
          updatedDoc,
          "documents_data"
        );

        // Refresh the data
        await fetchData();

        // Update the selected files to reflect changes
        const updatedFiles = selectedFiles.map((f, idx) => {
          if (idx === currentIndex) {
            return {
              ...f,
              description: editFormData.description,
              label: editFormData.label,
              lastEdited: new Date().toISOString(),
            };
          }
          return f;
        });

        setSelectedFiles(updatedFiles);

        // Close the edit form
        setShowEditForm(false);
      } catch (error) {
        console.error("Error updating document information:", error);
      }
    };

    return (
      <>
        <div className="fixed inset-0 z-50 flex items-center justify-center ">
          <div className="relative w-full max-w-4xl overflow-hidden bg-white shadow-[0_4px_12px_rgba(0,0,0,0.2)]">
            {/* Header */}
            <div className="flex items-center justify-between border-b bg-[#C4DAF3] p-4">
              <h3 className="text-md font-semibold text-[#04447A]">
                {file.label ? file.label : fileName}
              </h3>
              <button
                onClick={onClose}
                className="flex h-5 w-5 items-center justify-center rounded-full bg-[#E72528] text-white transition-colors hover:text-gray-200"
                title="Close"
              >
                &#10005;
              </button>
            </div>

            {file.length > 1 && (
              <div className="my-3 flex justify-center space-x-2">
                {file.map((_, index) => (
                  <div
                    key={index}
                    className={`h-3 w-3 rounded-full ${
                      index === currentIndex ? "bg-blue-900" : "bg-gray-400"
                    } transition-all duration-300`}
                  ></div>
                ))}
              </div>
            )}

            {/* File Display */}
            <div className="group relative mx-5 mt-4 flex h-96 items-center justify-center overflow-hidden bg-gray-200">
              {/* Gradient Overlay */}
              <div className="absolute inset-0 z-10 bg-gradient-to-t from-black/60 to-transparent"></div>

              {files.length > 1 && (
                <button
                  onClick={handlePrev}
                  className="absolute left-4 top-1/2 z-50 flex -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-[#D9D9D9] bg-opacity-90 text-zinc-400 transition-all hover:bg-black"
                >
                  <img
                    src={NavigationArrow}
                    alt="Previous"
                    className="h-12 w-12 rotate-180"
                  />
                </button>
              )}
              {isImage ? (
                <img
                  src={file.src}
                  alt={fileName}
                  className=" w-full object-cover"
                />
              ) : (
                <PdfViewer fileUrl={file.src} />
              )}
              {files.length > 1 && (
                <button
                  onClick={handleNext}
                  className="absolute right-4 top-1/2 z-50 flex -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-[#D9D9D9] bg-opacity-90 text-zinc-400 transition-all hover:bg-black"
                >
                  <img
                    src={NavigationArrow}
                    alt="Previous"
                    className="h-12 w-12"
                  />
                </button>
              )}
              {/* Delete Button */}
              <button
                onClick={handleDeleteClick}
                className="absolute right-4 top-4 flex h-10 w-10 items-center justify-center rounded bg-red-600 text-white opacity-0 transition-opacity duration-200 hover:bg-red-800 group-hover:opacity-100"
                title="Delete"
              >
                <FaTrash className="h-5 w-5" />
              </button>

              {/* Tooltip with Full View Icon */}
              <div
                className="absolute bottom-4 z-50 flex cursor-pointer  items-center rounded px-2 py-1 text-sm text-white opacity-0 transition-opacity group-hover:opacity-100"
                onClick={handleFullViewClick}
              >
                <img src={panZoomIcon} alt="Full View Icon" className="mr-1" />
                Click for Full View
              </div>
            </div>

            {/* Metadata Section */}
            <div className="ml-1 border-t px-4 pt-2 text-sm">
              <div className="space-y-3">
                {/* Date Uploaded */}
                <div className="flex items-center">
                  <img
                    src={Calendar}
                    alt="Date Uploaded"
                    className="mr-2 h-4 w-4"
                  />
                  <p className="text-[15px] text-[#525252]">
                    Date Uploaded: {file.date_inserted}
                  </p>
                </div>

                {/* People with Access */}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex w-full justify-between space-x-4 border-t px-4 py-2">
              <button
                className="flex-1 cursor-pointer border border-[#003460] bg-white px-6 py-3 text-[#003460] transition-colors hover:bg-gray-200"
                onClick={handleEditClick}
              >
                Edit Information
              </button>
              <button
                className="flex-1 cursor-pointer bg-[#003460] px-6 py-3 text-[#F7CA41] transition-colors hover:bg-[#002244]"
                onClick={handleUploadNewDocument}
              >
                Upload New Document
              </button>
            </div>
          </div>
        </div>

        {showDeleteConfirm && (
          <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md rounded-lg bg-white shadow-lg">
              <h3 className="flex items-center justify-between border-b bg-[#FFF3CE] p-4">
                Confirm Delete
              </h3>
              <p className="text-gray-600 mb-6 p-4">
                Are you sure you want to delete "{fileName}"? This action cannot
                be undone.
              </p>
              <div className="flex justify-end space-x-3 p-4">
                <button
                  onClick={handleDeleteCancel}
                  className="text-gray-700 hover:bg-gray-100 rounded-md border border-gray-300 px-4 py-2 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteConfirm}
                  className="rounded-md bg-red-600 px-4 py-2 text-white transition-colors hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Edit Information Form */}
        {showEditForm && (
          <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md rounded-lg bg-white shadow-lg">
              {/* Header with background color */}
              <div className="flex items-center justify-between border-b bg-[#FFF3CE] p-4">
                <h3 className="text-lg font-semibold text-black">
                  Edit Document Information
                </h3>
              </div>

              <div className="space-y-4 p-6">
                {/* Label Field - Unique to this file */}
                <div>
                  <label
                    htmlFor="label"
                    className="text-gray-700 mb-1 block text-sm font-medium"
                  >
                    Label
                  </label>
                  <input
                    type="text"
                    id="label"
                    name="label"
                    value={editFormData.label}
                    onChange={handleEditFormChange}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                    placeholder="e.g. Important, Personal, Medical"
                  />
                </div>

                {/* Description Field - Updates the entire record */}
                <div>
                  <label
                    htmlFor="description"
                    className="text-gray-700 mb-1 block text-sm font-medium"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    rows="5"
                    value={editFormData.description}
                    onChange={handleEditFormChange}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                    placeholder="Enter information about this document"
                  ></textarea>
                </div>

                {/* Action buttons */}
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={handleEditCancel}
                    className="flex-1 cursor-pointer border border-[#003460] px-6 py-3 text-[#003460] transition-colors hover:bg-gray-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditSave}
                    className="flex-1 cursor-pointer bg-[#003460] px-6 py-3  text-[#F7CA41] transition-colors hover:bg-[#002244]"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Full View Modal */}
        <ModalFileViewer
          isOpen={isFullViewOpen}
          setIsOpen={setIsFullViewOpen}
          title={fileName}
          fileUrl={file.src}
          fileType={file.type}
        />
        {/* <FullViewModal
          isOpen={isFullViewOpen}
          onClose={() => setIsFullViewOpen(false)}
          imageSrc={file.src}
          fileType={file.type}
          imageAlt={fileName}
        /> */}
      </>
    );
  };

  const handleDocumentCommandClick = (args) => {
    args.cancel = true; // Prevent row selection
    const rowData = args.rowData; // Get row data

    console.log("Clicked row data:", rowData); // Debugging log

    // Remove existing menus before adding a new one
    document.querySelectorAll(".custom-menu").forEach((menu) => {
      if (menu.parentNode) {
        menu.parentNode.removeChild(menu);
      }
    });

    // Create the dropdown menu
    const menu = document.createElement("div");
    menu.className =
      "custom-menu absolute bg-white shadow-lg rounded mt-1 py-1 border border-gray-200";
    menu.style.zIndex = "1000";
    menu.innerHTML = `
     <div class="px-4 py-2 hover:bg-[#FFF3CE] cursor-pointer" data-action="edit">Edit</div>
      <div class="px-4 py-2 hover:bg-red-800 hover:text-white cursor-pointer" data-action="delete">Delete</div>
  `;

    // Position the menu near the clicked button
    const rect = args.target.getBoundingClientRect();
    menu.style.position = "absolute";
    menu.style.top = `${window.scrollY + rect.bottom}px`;
    menu.style.left = `${window.scrollX + rect.left}px`;

    // Append menu to the document
    document.body.appendChild(menu);

    // Handle menu clicks (Edit/Delete)
    menu.addEventListener("click", (event) => {
      const action = event.target.getAttribute("data-action");

      if (action === "edit") {
        console.log("Edit clicked"); // Debugging log
        documentGridRef.current.startEdit(args.row); // Trigger Syncfusion edit mode
      } else if (action === "delete") {
        console.log("Delete clicked"); // Debugging log
        documentGridRef.current.deleteRecord(rowData); // Delete the selected row
      }

      // Remove menu after selection
      if (document.body.contains(menu)) {
        document.body.removeChild(menu);
      }
    });

    // Close the menu when clicking outside
    setTimeout(() => {
      document.addEventListener("click", function closeMenu(event) {
        if (!menu.contains(event.target)) {
          if (document.body.contains(menu)) {
            document.body.removeChild(menu);
          }
          document.removeEventListener("click", closeMenu);
        }
      });
    }, 10);
  };

  const documentsTemplate = (props) => {
    const files = Array.isArray(props.photodocuments)
      ? typeof props.photodocuments[0] === "string"
        ? [{ src: props.photodocuments[0] }]
        : props.photodocuments
      : props.photodocuments
      ? [props.photodocuments]
      : [];

    const documentCount = files.length;
    const firstFile = files[0];

    const isPDF =
      firstFile &&
      (firstFile.type === "application/pdf" || firstFile.src?.endsWith(".pdf"));
    const isImage = firstFile && !isPDF; // If it's not a PDF, assume it's an image

    // Check if there are both PDFs and images
    const hasPDF = files.some(
      (file) => file.type === "application/pdf" || file.src?.endsWith(".pdf")
    );
    const hasImage = files.some(
      (file) => !file.type || file.type.startsWith("image/")
    );

    let label = "None";
    if (hasPDF && hasImage) label = "Image / Document";
    else if (hasPDF) label = "Document";
    else if (hasImage) label = "Image";

    return (
      <div
        className="image-name-container"
        onClick={() => documentCount > 0 && handleFileClick(files, props)}
        style={{
          cursor: documentCount > 0 ? "pointer" : "default",
          padding: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {firstFile ? (
          <div className="flex items-center">
            {/* Show PDF preview or icon, otherwise show image */}
            {isPDF ? (
              <div className="bg-gray-50 border-gray-100 relative flex h-16 w-16 items-center justify-center rounded border">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#003460"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                  <polyline points="14 2 14 8 20 8" />
                  <line x1="16" y1="13" x2="8" y2="13" />
                  <line x1="16" y1="17" x2="8" y2="17" />
                </svg>
                <div className="absolute bottom-0 right-0 rounded-tl bg-[#003460] px-1.5 py-0.5 text-[10px] text-white">
                  PDF
                </div>
              </div>
            ) : (
              <img
                src={firstFile.src || firstFile}
                alt="Document preview"
                className="h-16 w-16 rounded object-cover"
              />
            )}

            {/* Document or Image Count & Label */}
            <div className="ml-3 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#003460"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
              </svg>
              <span className="text-lg ml-2 font-medium text-[#003460]">
                {label} ({documentCount})
              </span>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#003460"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ marginRight: "8px" }}
            >
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
              <line x1="16" y1="13" x2="8" y2="13"></line>
              <line x1="16" y1="17" x2="8" y2="17"></line>
            </svg>
            <p className="text-sm">None</p>
          </div>
        )}
      </div>
    );
  };

  const documentsEdit = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
    <div class="flex flex-col justify-center w-full">
      <div id="documentsPreviews" class="mb-4 flex flex-wrap justify-center gap-2">
        <!-- Documents will be dynamically inserted here -->
      </div>
      
      <div id="documentsDetails" class="mb-4 w-full" style="display: none;">
        <div class="border p-4 rounded-md bg-gray-50">
          <h3 class="font-medium text-gray-900 mb-2" id="detailsDocumentName">Document Name</h3>
          <label for="documentDetailsText" class="block text-sm font-medium text-gray-700 mb-1">Document Details</label>
          <textarea id="documentDetailsText" rows="3" class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500" placeholder="Enter additional information about this document"></textarea>
          <div class="mt-3 flex justify-end">
            <button id="saveDetailsBtn" class="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">Save</button>
            <button id="cancelDetailsBtn" class="ml-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400">Cancel</button>
          </div>
        </div>
      </div>
      
      <div id="dropArea">
        <label class="upload-button cursor-pointer flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50" id="uploadLabel">
          <svg class="mr-2 -ml-1 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          Upload Documents
        </label>
        <input type="file" id="defaultUpload" style="display:none;" multiple accept=".pdf,.doc,.docx,.jpg,.jpeg,.png" />
      </div>
      <div id="uploadProgress" class="w-full bg-gray-200 rounded-full h-2.5 mt-4" style="display: none;">
        <div class="bg-blue-600 h-2.5 rounded-full" style="width: 0%;"></div>
      </div>
    </div>
            `;

      const inputElement = elem.querySelector("#defaultUpload");
      const uploadLabel = elem.querySelector("#uploadLabel");
      const documentsPreviews = elem.querySelector("#documentsPreviews");
      const uploadProgressContainer = elem.querySelector("#uploadProgress");
      const uploadProgressBar = uploadProgressContainer.querySelector("div");
      const documentsDetails = elem.querySelector("#documentsDetails");
      const detailsDocumentName = elem.querySelector("#detailsDocumentName");
      const documentDetailsText = elem.querySelector("#documentDetailsText");
      const saveDetailsBtn = elem.querySelector("#saveDetailsBtn");
      const cancelDetailsBtn = elem.querySelector("#cancelDetailsBtn");

      let currentEditIndex = -1;

      // Setup details save button
      saveDetailsBtn.addEventListener("click", () => {
        if (currentEditIndex >= 0 && currentEditIndex < strm.length) {
          // Update the document details
          strm[currentEditIndex].details = documentDetailsText.value;

          // Hide the details section and update the display
          documentsDetails.style.display = "none";
          displayUploadedDocuments(strm);
          currentEditIndex = -1;
        }
      });

      // Setup details cancel button
      cancelDetailsBtn.addEventListener("click", () => {
        documentsDetails.style.display = "none";
        currentEditIndex = -1;
      });

      uploadProgressContainer.style.cssText += `
              margin-top: 1rem;
              background-color: #e5e7eb;
              border-radius: 0.5rem;
              overflow: hidden;
            `;

      uploadProgressBar.style.cssText += `
              height: 0.5rem;
              background-color: #3b82f6;
              transition: width 0.3s ease;
              border-radius: 0.5rem;
            `;

      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".pdf, .jpg,.jpeg,.png",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      uploadLabel.addEventListener("click", () => {
        inputElement.click();
      });

      inputElement.addEventListener("change", () => {
        if (inputElement.files.length > 0) {
          uploadObj.upload(inputElement.files);
        }
      });

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            // Show progress bar at 90% during processing
            uploadProgressContainer.style.display = "block";
            uploadProgressBar.style.width = "90%";
            uploadProgressBar.style.transition = "width 0.3s ease";

            const file = args.file.rawFile;
            let fileData = file;
            let isImage = file.type.startsWith("image/");

            if (isImage) {
              fileData = await compressImage(file, 1);
            }

            const filePath = `lockbox/documents/${userId}/${file.name}`;
            const downloadURL = await uploadFileToStorage(
              filePath,
              fileData,
              file.type
            );

            strm = strm
              ? [
                  ...strm,
                  {
                    src: downloadURL,
                    name: file.name,
                    type: file.type,
                    details: "", // Initialize with empty details
                    dateAdded: new Date().toISOString(),
                  },
                ]
              : [
                  {
                    src: downloadURL,
                    name: file.name,
                    type: file.type,
                    details: "", // Initialize with empty details
                    dateAdded: new Date().toISOString(),
                  },
                ];
            // Complete the progress bar
            uploadProgressBar.style.width = "100%";

            // Wait for transition before updating display
            setTimeout(() => {
              displayUploadedDocuments(strm);
              // Reset and hide progress bar
              setTimeout(() => {
                uploadProgressContainer.style.display = "none";
                uploadProgressBar.style.width = "0%";
                uploadProgressBar.style.transition = "none";
              }, 300);
            }, 500);
          } catch (error) {
            console.error("Error during upload:", error);
            // Show error state
            uploadProgressBar.style.backgroundColor = "#ef4444";
            setTimeout(() => {
              uploadProgressContainer.style.display = "none";
              uploadProgressBar.style.width = "0%";
              uploadProgressBar.style.backgroundColor = "#3b82f6";
            }, 2000);
          }
        }
      }

      function onUploadFailure(args) {
        console.log("File failed to upload");
      }

      function onUploadProgress(args) {
        const progress = Math.round((args.e.loaded / args.e.total) * 100);
        // Only update progress up to 80% during upload
        const displayProgress = Math.min(progress, 80);
        uploadProgressContainer.style.display = "block";
        uploadProgressBar.style.width = `${displayProgress}%`;
        uploadProgressBar.style.transition = "width 0.3s ease";
      }

      function showDocumentDetails(index) {
        if (index >= 0 && index < strm.length) {
          const doc = strm[index];

          // Set the current document being edited
          currentEditIndex = index;

          // Update the details section
          detailsDocumentName.textContent = doc.name;
          documentDetailsText.value = doc.details || "";

          // Show the details section
          documentsDetails.style.display = "block";

          // Scroll to the details section if needed
          documentsDetails.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }

      // Add the showDocumentDetails function to the window for the onclick handler
      window.showDocumentDetails = showDocumentDetails;

      function displayUploadedDocuments(documents) {
        if (!Array.isArray(documents)) {
          console.error("Documents is not an array:", documents);
          return;
        }

        // Add loading state
        documentsPreviews.innerHTML = `
                <div class="loading-placeholder w-20 h-20 bg-gray-100 rounded-md animate-pulse">
                  <div class="flex items-center justify-center h-full">
                    <svg class="animate-spin h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                </div>
              `;

        // Use setTimeout to ensure the loading state is visible
        setTimeout(() => {
          documentsPreviews.innerHTML = documents
            .map(
              (doc, index) => `
                <div class="relative group">
                  <div class="w-20 h-20 flex items-center justify-center bg-gray-100 rounded-md cursor-pointer" 
                       onclick="event.preventDefault(); showDocumentDetails(${index})">
                    ${getDocumentIcon(doc.type)}
                  </div>
                  <p class="text-xs text-center mt-1 truncate w-20">${
                    doc.name
                  }</p>
                  ${
                    doc.details
                      ? `<p class="text-xs text-center text-gray-500 truncate w-20" title="${doc.details}">With details</p>`
                      : ""
                  }
                  <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                   
                    <button onclick="event.preventDefault(); removeDocument(${index})" class="text-white hover:text-red-500 mx-1" title="Remove Document">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              `
            )
            .join("");
        }, 300);
      }

      function getDocumentIcon(fileType) {
        if (fileType.startsWith("image/")) {
          return `<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>`;
        } else {
          return `<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>`;
        }
      }

      // Function to remove a document
      window.removeDocument = (index) => {
        strm.splice(index, 1);
        displayUploadedDocuments(strm);
      };

      // Display the initial documents if available
      if (args.rowData.photodocuments) {
        strm = Array.isArray(args.rowData.photodocuments)
          ? args.rowData.photodocuments
          : [args.rowData.photodocuments];
        displayUploadedDocuments(strm);
      } else {
        documentsPreviews.innerHTML = "";
      }
    },
  };

  function generateUniqueId(length = 28) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const sanitizeData = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== undefined) {
        sanitizedData[key] = data[key];
      }
    }
    return sanitizedData;
  };

  const [deleteDialogProps, setDeleteDialogProps] = useState({
    isOpen: false,
    title: "",
    content: "",
    onConfirm: null,
  });

  const showDeleteConfirmDialog = (title, content, onConfirm) => {
    setDeleteDialogProps({
      isOpen: true,
      title,
      content,
      onConfirm,
    });
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogProps((prev) => ({ ...prev, isOpen: false }));
  };

  const handleDeleteConfirm = () => {
    if (deleteDialogProps.onConfirm) {
      deleteDialogProps.onConfirm();
    }
    handleDeleteDialogClose();
  };

  const [activeMenuDocuments, setActiveMenuDocuments] = useState(null);
  const [isEditingDocuments, setIsEditingDocuments] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const documentsFormRef = useRef(null);
  const [isSubmittingDocuments, setIsSubmittingDocuments] = useState(false);
  const [documentsToDeleteId, setDocumentsToDeleteId] = useState(null);
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);

  const {
    register: documentsRegister,
    handleSubmit: documentsHandleSubmit,
    formState: { errors: documentsErrors },
    reset: documentsReset,
    control: documentsControl,
    setValue: documentsSetValue,
    watch: documentsWatch,
  } = useForm({
    defaultValues: {},
  });
  const documentsValues = documentsWatch();
  const onSubmitDocuments = async (values) => {
    setIsSubmittingDocuments(true);
    try {
      const documentId = isEditingDocuments
        ? values.documentsID
        : generateUniqueId();
      const currentDate = new Date();

      const uploadedDocuments = values.photodocuments || [];

      const updatedData = {
        ...values,
        documentsID: documentId,
        photodocuments: Array.isArray(uploadedDocuments)
          ? uploadedDocuments
          : [uploadedDocuments],
        is_deleted: false,
        modified_date: currentDate.toLocaleDateString("en-US"),
        ...(isEditingDocuments
          ? {}
          : { date_inserted: currentDate.toLocaleDateString("en-US") }),
        access: Array.isArray(values.access) ? values.access : [],
      };

      const sanitizedData = sanitizeData(updatedData);

      if (isEditingDocuments) {
        delete sanitizedData.date_inserted;

        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "documents_data"
        );

        const updatedDocumentsData = documentsData.map((item) =>
          item.documentsID === documentId ? sanitizedData : item
        );
        setDocumentsData(updatedDocumentsData);
      } else {
        await LockBoxService.addDocumentToLockbox(
          userId,
          sanitizedData,
          "documents_data"
        );
      }

      await fetchData();
      setShowDocumentsModal(false);
      setIsEditingDocuments(false);
      documentsReset();
      setIsSubmittingDocuments(false);
    } catch (error) {
      console.error("Error saving document:", error);
      setIsSubmittingDocuments(false);
    }
  };

  const actionBeginDocuments = async (args) => {
    if (documentGridRef.current && args.requestType === "beginEdit") {
      const cols = documentGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = false; // Hide modifiedDate when editing
        }
        if (col.field === "date_inserted") {
          col.allowEditing = false; // Disable editing for date_inserted
        }
      }
    }

    if (documentGridRef.current && args.requestType === "add") {
      const cols = documentGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = false; // Hide modifiedDate when adding
        }
      }
    }

    if (documentGridRef.current && args.requestType === "save") {
      const cols = documentGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = true; // Show modifiedDate after saving
        }
      }
    }

    if (args.requestType === "save" && args.action === "add") {
      args.data.documentsID = generateUniqueId();
      args.data.is_deleted = false;

      const currentDate = new Date();
      args.data.modified_date = currentDate.toLocaleDateString("en-US");
      args.data.date_inserted = currentDate.toLocaleDateString("en-US");

      const sanitizedData = sanitizeData(args.data);
      await LockBoxService.addDocumentToLockbox(
        userId,
        sanitizedData,
        "documents_data"
      );
    }

    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      deleteDocument(args.data);
      await fetchData();
    }
  };

  const deleteDocument = async (data) => {
    try {
      for (const docData of data) {
        const documentId = docData.id;

        await LockBoxService.softDeleteItemInLockbox(
          userId,
          documentId,
          "documents_data"
        );
      }
      // Refresh the grid data after marking as deleted
      fetchData();
    } catch (error) {
      console.error("Error marking document as deleted:", error);
    }
  };

  const actionCompleteDocuments = async (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      try {
        const documentId = selectedRow.documentsID;

        delete args.data.date_inserted;

        if (args.data.modified_date) {
          const dateObj = new Date(args.data.modified_date);
          args.data.modified_date = dateObj.toLocaleDateString("en-US");
        }

        const sanitizedData = sanitizeData(args.data);

        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "documents_data"
        );

        const updatedData = documentsData.map((item) =>
          item.documentsID === documentId ? sanitizedData : item
        );

        setDocumentsData(updatedData);
        await fetchData();
      } catch (error) {
        console.error("Error updating document:", error);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  const handleAddClickDocument = () => {
    if (documentGridRef.current) {
      documentGridRef.current.addRecord();
    }
  };

  const accessEditTemplatePhotos = (props) => {
    const currentAccess = Array.isArray(props?.access) ? props.access : [];

    return (
      <div>
        <label htmlFor="access" className="mb-2 block text-xs text-gray-500">
          People with Access
        </label>
        <MultiSelectComponent
          id="access"
          dataSource={accessOptions}
          fields={fields}
          value={currentAccess}
          mode="CheckBox"
          selectAllText="Select All"
          unSelectAllText="Unselect All"
          showSelectAll={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
    );
  };

  const [isPhotosVisible, setIsPhotosVisible] = useState(true);

  const togglePhotos = (event) => {
    event.preventDefault();
    setIsPhotosVisible(!isPhotosVisible);
  };

  const documentGridRef = useRef(null);

  const handleAddClickPhotos = () => {
    if (PhotosGridRef.current) {
      PhotosGridRef.current.addRecord();
    }
  };

  const handleCommandClick = (args) => {
    args.cancel = true; // Prevent row selection
    const rowData = args.rowData; // Get row data

    console.log("Clicked row data:", rowData); // Debugging log

    // Remove existing menus before adding a new one
    document.querySelectorAll(".custom-menu").forEach((menu) => {
      if (menu.parentNode) {
        menu.parentNode.removeChild(menu);
      }
    });

    // Create the dropdown menu
    const menu = document.createElement("div");
    menu.className =
      "custom-menu absolute bg-white shadow-lg rounded mt-1 py-1 border border-gray-200 rounded-lg";
    menu.style.zIndex = "1000";
    menu.innerHTML = `
      <div class="px-4 py-2 hover:bg-[#FFF3CE] cursor-pointer" data-action="edit">Edit</div>
      <div class="px-4 py-2 hover:bg-red-800 hover:text-white cursor-pointer" data-action="delete">Delete</div>
    `;

    // Position the menu near the clicked button
    const rect = args.target.getBoundingClientRect();
    menu.style.position = "absolute";
    menu.style.top = `${window.scrollY + rect.bottom}px`;
    menu.style.left = `${window.scrollX + rect.left}px`;

    // Append menu to the document
    document.body.appendChild(menu);

    // Handle menu clicks (Edit/Delete)
    menu.addEventListener("click", (event) => {
      const action = event.target.getAttribute("data-action");

      if (action === "edit") {
        console.log("Edit clicked"); // Debugging log
        PhotosGridRef.current.startEdit(args.row); // Trigger Syncfusion edit mode
      } else if (action === "delete") {
        console.log("Delete clicked"); // Debugging log
        PhotosGridRef.current.deleteRecord(rowData); // Delete the selected row
      }

      // Remove menu after selection
      if (document.body.contains(menu)) {
        document.body.removeChild(menu);
      }
    });

    // Close the menu when clicking outside
    setTimeout(() => {
      document.addEventListener("click", function closeMenu(event) {
        if (!menu.contains(event.target)) {
          if (document.body.contains(menu)) {
            document.body.removeChild(menu);
          }
          document.removeEventListener("click", closeMenu);
        }
      });
    }, 10);
  };

  const photosTemplate = (props) => {
    const images = Array.isArray(props.photoPhotos)
      ? props.photoPhotos
      : props.photoPhotos
      ? [props.photoPhotos]
      : [];
    return renderImagePreview(images, props);
  };

  const handleImageClick = (images, documentData) => {
    const formattedImages = images.map((image) => {
      if (typeof image === "string") {
        return {
          src: image,
          type: "image/jpeg", // Default type for string URLs
          description: documentData.description || "",
          date_inserted: documentData.date_inserted || "",
          access: documentData.access || [],
          label: image.label || "",
        };
      }
      return {
        src: image.src || image,
        name: image.name || "",
        type: "image/jpeg", // Default type for images
        description: documentData.description || "",
        date_inserted: documentData.date_inserted || "",
        access: documentData.access || [],
        label: image.label || "",
        originalName: image.originalName || "",
        title: documentData.title || "",
      };
    });

    setSelectedImages(formattedImages);
    setCurrentImageIndex(0);
    setIsImageDialogOpen(true);
  };

  const ImageDialog = ({
    isOpen,
    onClose,
    images,
    currentIndex,
    setCurrentIndex,
  }) => {
    const [isFullViewOpen, setIsFullViewOpen] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [editFormData, setEditFormData] = useState({
      description: "",
      label: "",
    });

    if (!isOpen || !Array.isArray(images) || images.length === 0) return null;

    const image = images[currentIndex];
    const imageName = image.name || "Image";

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : images.length - 1
      );
      // Reset edit form when changing images
      setShowEditForm(false);
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex < images.length - 1 ? prevIndex + 1 : 0
      );
      // Reset edit form when changing images
      setShowEditForm(false);
    };

    const handleFullViewClick = () => {
      setIsFullViewOpen(true);
    };

    const handleDeleteClick = () => {
      setShowDeleteConfirm(true);
    };

    const handleDeleteCancel = () => {
      setShowDeleteConfirm(false);
    };

    const handleDeleteConfirm = async () => {
      // Find the photo in the grid data that contains the current image
      const currentPhoto = PhotosData.find((photo) => {
        if (Array.isArray(photo.photoPhotos)) {
          return photo.photoPhotos.some(
            (p) => p.src === image.src || p === image.src
          );
        }
        return false;
      });

      if (!currentPhoto) {
        console.error("Could not find the current photo record");
        setShowDeleteConfirm(false);
        return;
      }

      try {
        // Remove the image from the photoPhotos array
        const updatedPhotoPhotos = currentPhoto.photoPhotos.filter(
          (p) => p.src !== image.src && p !== image.src
        );

        // Update the photo with the filtered array
        const updatedPhoto = {
          ...currentPhoto,
          photoPhotos: updatedPhotoPhotos,
          modified_date: new Date().toLocaleDateString("en-US"),
        };

        // Update in the database
        await LockBoxService.updateDocumentInLockbox(
          userId,
          currentPhoto.PhotosID,
          updatedPhoto,
          "photos_data"
        );

        // Refresh the data
        await fetchData();

        // Close the dialog if there are no more images
        if (updatedPhotoPhotos.length === 0) {
          onClose();
        } else {
          // Update the selected images
          const updatedImages = selectedImages.filter(
            (img) => img.src !== image.src
          );
          setSelectedImages(updatedImages);

          // Adjust current index if needed
          if (currentIndex >= updatedImages.length) {
            setCurrentIndex(updatedImages.length - 1);
          }
        }
      } catch (error) {
        console.error("Error deleting image:", error);
      }

      setShowDeleteConfirm(false);
    };

    const handleEditClick = () => {
      const currentPhoto = PhotosData.find((photo) => {
        if (Array.isArray(photo.photoPhotos)) {
          return photo.photoPhotos.some(
            (p) => p.src === image.src || p === image.src
          );
        }
        return false;
      });

      if (!currentPhoto) {
        console.error("Could not find the current photo record");
        return;
      }

      const { column, index, foreignKeyData, ...rest } = currentPhoto;
      setIsImageDialogOpen(false);
      setIsEditingPhotos(true);
      setShowPhotosModal(true);
      setActiveMenuPhotos(null);

      console.log("Photo data in edit:", currentPhoto?.photoPhotos);

      photosReset({
        title: currentPhoto?.title || "",
        description: currentPhoto?.description || "",
        access: currentPhoto?.access || "",
        modified_date: currentPhoto?.modified_date || "",
        relationship: currentPhoto?.relationship || "",
        photoPhotos: currentPhoto?.photoPhotos || "",
        photo: currentPhoto?.photoPhotos || "",
        PhotosID: currentPhoto?.PhotosID || "",
        ...rest,
      });
    };

    const handleEditCancel = () => {
      setShowEditForm(false);
    };

    const handleEditFormChange = (e) => {
      const { name, value } = e.target;
      setEditFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleEditSave = async () => {
      // Find the photo in the grid data that contains the current image
      const currentPhoto = PhotosData.find((photo) => {
        if (Array.isArray(photo.photoPhotos)) {
          return photo.photoPhotos.some(
            (p) => p.src === image.src || p === image.src
          );
        }
        return false;
      });

      if (!currentPhoto) {
        console.error("Could not find the current photo record");
        setShowEditForm(false);
        return;
      }

      try {
        // Update the image metadata in the photoPhotos array
        const updatedPhotoPhotos = currentPhoto.photoPhotos.map((p) => {
          if (p.src === image.src || p === image.src) {
            // If this is a string, convert it to an object first
            const photoObj = typeof p === "string" ? { src: p } : { ...p };
            return {
              ...photoObj,
              label: editFormData.label,
            };
          }
          return p;
        });

        // Update the photo with the modified array and description
        const updatedPhoto = {
          ...currentPhoto,
          photoPhotos: updatedPhotoPhotos,
          description: editFormData.description,
          modified_date: new Date().toLocaleDateString("en-US"),
        };

        // Update in the database
        await LockBoxService.updateDocumentInLockbox(
          userId,
          currentPhoto.PhotosID,
          updatedPhoto,
          "photos_data"
        );

        // Refresh the data
        await fetchData();

        // Update the current image in the view
        const updatedImage = {
          ...image,
          label: editFormData.label,
        };

        // Update the selected images array
        const updatedImages = selectedImages.map((img) =>
          img.src === image.src ? updatedImage : img
        );

        setSelectedImages(updatedImages);

        // Instead of using setImage, update the images array at the current index
        if (currentIndex >= 0 && currentIndex < updatedImages.length) {
          // The current image will be updated in the array
          // No need to call setImage separately
        }
      } catch (error) {
        console.error("Error updating image:", error);
      }

      setShowEditForm(false);
    };

    const handleUploadNewPhoto = () => {
      // Find the photo in the grid data that contains the current image
      const currentPhoto = PhotosData.find((photo) => {
        if (Array.isArray(photo.photoPhotos)) {
          return photo.photoPhotos.some(
            (p) => p.src === image.src || p === image.src
          );
        }
        return false;
      });

      if (!currentPhoto) {
        console.error("Could not find the current photo record");
        return;
      }

      // Log the found photo to debug
      console.log("Found photo record:", currentPhoto);

      // Use the correct ID field - it might be docId instead of PhotosID
      const photoId = currentPhoto.docId || currentPhoto.PhotosID;

      if (!photoId) {
        console.error("Photo record is missing ID field", currentPhoto);
        return;
      }

      // Create a temporary file input element
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.multiple = true;
      fileInput.accept = "image/*";
      fileInput.style.display = "none";

      // Add it to the DOM
      document.body.appendChild(fileInput);

      // Set up event listener for when files are selected
      fileInput.addEventListener("change", async (event) => {
        if (event.target.files.length > 0) {
          // Create a progress indicator
          const progressContainer = document.createElement("div");
          progressContainer.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 4px;
          background-color: #e5e7eb;
          z-index: 9999;
        `;

          const progressBar = document.createElement("div");
          progressBar.style.cssText = `
          height: 100%;
          background-color: #3b82f6;
          width: 0%;
          transition: width 0.3s ease;
        `;

          progressContainer.appendChild(progressBar);
          document.body.appendChild(progressContainer);

          // Process the files
          const newPhotos = [];

          for (const file of event.target.files) {
            try {
              // Update progress to 50%
              progressBar.style.width = "50%";

              // Compress the image
              const compressedFile = await compressImage(file, 1);

              // Upload to Firebase
              const filePath = `lockbox/photos/${userId}/${file.name}`;
              const downloadURL = await uploadImageToStorage(
                filePath,
                compressedFile
              );

              // Create a new photo object
              const newPhoto = {
                src: downloadURL,
                name: file.name,
                type: file.type,
                dateAdded: new Date().toISOString(),
              };

              newPhotos.push(newPhoto);
            } catch (error) {
              console.error("Error uploading photo:", error);
            }
          }

          // Update progress to 80%
          progressBar.style.width = "80%";

          if (newPhotos.length > 0) {
            try {
              // Update the photo record with the new photos
              const updatedPhoto = { ...currentPhoto };
              updatedPhoto.photoPhotos = Array.isArray(updatedPhoto.photoPhotos)
                ? [...updatedPhoto.photoPhotos, ...newPhotos]
                : newPhotos;
              updatedPhoto.modified_date = new Date().toLocaleDateString(
                "en-US"
              );

              // Update the photo in the database using the correct ID
              await LockBoxService.updateDocumentInLockbox(
                userId,
                currentPhoto.PhotosID, // Use PhotosID consistently
                updatedPhoto,
                "photos_data"
              );

              // Update progress to 100%
              progressBar.style.width = "100%";

              // Refresh the data
              await fetchData();

              // Update the images in the current view
              const updatedImages = [...selectedImages, ...newPhotos];
              setSelectedImages(updatedImages);

              // Show success message
              setTimeout(() => {
                progressBar.style.backgroundColor = "#10b981"; // Success color

                // Remove progress indicator after a delay
                setTimeout(() => {
                  document.body.removeChild(progressContainer);
                }, 1000);
              }, 500);
            } catch (error) {
              console.error("Error updating photo record:", error);
              progressBar.style.backgroundColor = "#ef4444"; // Error color

              // Remove progress indicator after a delay
              setTimeout(() => {
                document.body.removeChild(progressContainer);
              }, 2000);
            }
          } else {
            // No files were successfully processed
            progressBar.style.backgroundColor = "#ef4444"; // Error color

            // Remove progress indicator after a delay
            setTimeout(() => {
              document.body.removeChild(progressContainer);
            }, 2000);
          }
        }

        // Clean up
        document.body.removeChild(fileInput);
      });

      // Trigger the file selection dialog
      fileInput.click();
    };

    return (
      <>
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="relative w-full max-w-4xl overflow-hidden bg-white shadow-[4px_4px_30px_rgba(82,82,82,0.4)]">
            {/* Header */}
            <div className="flex items-center justify-between border-b bg-[#C4DAF3] p-4">
              <h3 className="text-md font-semibold text-[#04447A]">
                {image.label ? image.label : imageName}
              </h3>
              <button
                onClick={onClose}
                className="flex h-5 w-5 items-center justify-center rounded-full bg-[#E72528] text-white transition-colors hover:text-gray-200"
                title="Close"
              >
                &#10005;
              </button>
            </div>

            {/* Image Indicators - BELOW HEADER, ABOVE IMAGE */}
            {images.length > 1 && (
              <div className="my-3 flex justify-center space-x-2">
                {images.map((_, index) => (
                  <div
                    key={index}
                    className={`h-3 w-3 rounded-full ${
                      index === currentIndex ? "bg-blue-900" : "bg-gray-400"
                    } transition-all duration-300`}
                  ></div>
                ))}
              </div>
            )}

            {/* Image Display Section */}
            <div className="group relative mx-5 mt-4 flex h-96 items-center justify-center overflow-hidden bg-gray-200 p-4">
              {/* Gradient Overlay */}
              <div className="absolute inset-0 z-10 bg-gradient-to-t from-black/60 to-transparent"></div>

              {/* Navigation Buttons */}
              {images.length > 1 && (
                <button
                  onClick={handlePrev}
                  className="absolute left-4 top-1/2 z-50 flex -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-[#D9D9D9] bg-opacity-90 text-zinc-400 transition-all hover:bg-black"
                >
                  <img
                    src={NavigationArrow}
                    alt="Previous"
                    className="h-12 w-12 rotate-180"
                  />
                </button>
              )}

              {/* Image */}
              <img
                src={images[currentIndex].src}
                alt={images[currentIndex].label || imageName}
                className="zoomed-image max-h-full max-w-full object-contain"
              />

              {images.length > 1 && (
                <button
                  onClick={handleNext}
                  className="absolute right-4 top-1/2 z-50 flex -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-[#D9D9D9] bg-opacity-90 text-zinc-400 transition-all hover:bg-black"
                >
                  <img
                    src={NavigationArrow}
                    alt="Previous"
                    className="h-12 w-12"
                  />
                </button>
              )}

              {/* Delete Button */}
              <button
                onClick={handleDeleteClick}
                className="absolute right-4 top-4 z-50 flex h-10 w-10 items-center justify-center rounded bg-red-600 text-white opacity-0 transition-opacity duration-200 hover:bg-red-800 group-hover:opacity-100"
                title="Delete"
              >
                <FaTrash className="h-5 w-5" />
              </button>

              {/* Tooltip with Full View Icon */}
              <div
                className="absolute bottom-4 z-50 flex cursor-pointer  items-center rounded px-2 py-1 text-sm text-white opacity-0 transition-opacity group-hover:opacity-100"
                onClick={handleFullViewClick}
              >
                <img src={panZoomIcon} alt="Full View Icon" className="mr-1" />
                Click for Full View
              </div>
            </div>

            {/* Metadata Section */}
            <div className="ml-1 border-t px-4 pt-2 text-sm">
              <div className="space-y-3">
                {/* Date Uploaded */}
                <div className="flex items-center">
                  <img
                    src={Calendar}
                    alt="Date Uploaded"
                    className="mr-2 h-4 w-4"
                  />
                  <p className="text-[15px] text-[#525252]">
                    Date Uploaded: {image.date_inserted}
                  </p>
                </div>

                {/* People with Access */}
                {/* <div className="flex items-center">
                <svg 
                  className="w-4 h-4 text-gray-500 mr-2" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" 
                  />
                </svg>
                <p className="text-gray-600">
                  People with Access: {Array.isArray(image.access) && image.access.length > 0 ? image.access.join(", ") : "None"}
                </p>
              </div> 

              {/* Description 
              <div>
                <p className="text-gray-600">
                  {image.description || "No description available."}
                </p>
              </div>
              */}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex w-full justify-between space-x-4 border-t px-4 py-2">
              <button
                className="flex-1 cursor-pointer border border-[#003460] bg-white px-6 py-3 text-[#003460] transition-colors hover:bg-gray-200"
                onClick={handleEditClick}
              >
                Edit File
              </button>
              <button
                className="flex-1 cursor-pointer bg-[#003460] px-6 py-3 text-[#F7CA41] transition-colors hover:bg-[#002244]"
                onClick={handleUploadNewPhoto}
              >
                Upload New File
              </button>
            </div>
          </div>
        </div>

        {/* Delete Confirmation Dialog */}
        {showDeleteConfirm && (
          <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md rounded-lg bg-white shadow-lg">
              <h3 className="flex items-center justify-between border-b bg-[#FFF3CE] p-4">
                Confirm Delete
              </h3>
              <p className="text-gray-600 mb-6 p-4">
                Are you sure you want to delete this image? This action cannot
                be undone.
              </p>
              <div className="flex justify-end space-x-3 p-4">
                <button
                  onClick={handleDeleteCancel}
                  className="text-gray-700 hover:bg-gray-100 rounded-md border border-gray-300 px-4 py-2 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteConfirm}
                  className="rounded-md bg-red-600 px-4 py-2 text-white transition-colors hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Edit Information Form */}
        {showEditForm && (
          <div className="fixed inset-0 z-[60] flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md rounded-lg bg-white shadow-lg">
              <div className="flex items-center justify-between border-b bg-[#FFF3CE] p-4">
                <h3 className="text-lg text-gray-900 mb-4 font-semibold">
                  Edit Image Information
                </h3>
              </div>

              <div className="space-y-4 p-6">
                {/* Label Field */}
                <div>
                  <label
                    htmlFor="label"
                    className="text-gray-700 mb-1 block text-sm font-medium"
                  >
                    Label
                  </label>
                  <input
                    type="text"
                    id="label"
                    name="label"
                    value={editFormData.label}
                    onChange={handleEditFormChange}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                    placeholder="e.g. Important, Personal, Medical"
                  />
                </div>

                {/* Description Field */}
                <div>
                  <label
                    htmlFor="description"
                    className="text-gray-700 mb-1 block text-sm font-medium"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    rows="3"
                    value={editFormData.description}
                    onChange={handleEditFormChange}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                    placeholder="Enter information about this image"
                  ></textarea>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    onClick={handleEditCancel}
                    className="flex-1 cursor-pointer border border-[#003460] px-6 py-3 text-[#003460] transition-colors hover:bg-gray-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditSave}
                    className="flex-1 cursor-pointer bg-[#003460] px-6 py-3  text-[#F7CA41] transition-colors hover:bg-[#002244]"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Full View Modal */}
        <FullViewModal
          isOpen={isFullViewOpen}
          onClose={() => setIsFullViewOpen(false)}
          imageSrc={image.src}
          imageAlt={image.label || imageName}
        />
      </>
    );
  };

  const renderImagePreview = (images, documentData) => {
    const firstImage = images.length > 0 ? images[0] : null;
    const imageCount = images.length;
    const label = imageCount === 1 ? "Photo" : "Photos"; // Dynamic label

    return (
      <div
        className="image-name-container"
        onClick={() => imageCount > 0 && handleImageClick(images, documentData)}
        style={{
          cursor: imageCount > 0 ? "pointer" : "default",
          padding: "0", // Removed padding
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {firstImage ? (
          <div className="flex items-center">
            {/* Larger Image preview */}
            <img
              src={firstImage.src || firstImage}
              alt="Image preview"
              className="h-16 w-16 rounded object-cover" // Added rounded corners
            />

            {/* Image count with label */}
            <div className="ml-3 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#003460"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                <circle cx="8.5" cy="8.5" r="1.5" />
                <polyline points="21 15 16 10 5 21" />
              </svg>
              <span className="text-lg ml-2 font-medium text-[#003460]">
                {label} ({imageCount}) {/* Shows "Image (1)" or "Images (3)" */}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#003460"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ marginRight: "8px" }}
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
              <circle cx="8.5" cy="8.5" r="1.5" />
              <polyline points="21 15 16 10 5 21" />
            </svg>
            <p className="text-sm">No images</p>
          </div>
        )}
      </div>
    );
  };

  const PhotosEdit = {
    create: () => {
      elem = document.createElement("div");
      return elem;
    },
    read: () => {
      return strm;
    },
    destroy: () => {
      if (uploadObj) {
        uploadObj.destroy();
      }
      strm = null;
    },
    write: (args) => {
      const path = {
        removeUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
        saveUrl:
          "https://services.syncfusion.com/react/production/api/FileUploader/Save",
      };

      elem.innerHTML = `
      <div class="flex flex-col justify-center w-full">
        <div id="dropArea" class="border-2 border-dashed border-[#0066b2] rounded-md cursor-pointer hover:bg-[#0066b2]/5 transition-colors relative" style="min-height: 100px;">
          <div id="uploadContent" class="flex flex-col items-center justify-center h-full p-8">
            <div class="text-gray-600">
              <svg class="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <div class="text-gray-600 mt-2">Click to Upload File</div>
          </div>

          <div id="imagePreview" class="hidden h-full">
            <img src="" alt="Preview" class="w-full h-full object-contain" />
            <button type="button" id="prevButton" class="absolute left-4 top-1/2 -translate-y-1/2 bg-white rounded-full p-3 shadow-lg hover:bg-gray-100">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
    </svg>
  </button>
  <button type="button" id="nextButton" class="absolute right-4 top-1/2 -translate-y-1/2 bg-white rounded-full p-3 shadow-lg hover:bg-gray-100">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
    </svg>
  </button>

            <div class="absolute bottom-4 left-1/2 -translate-x-1/2 flex items-center gap-2 bg-white px-4 py-2 rounded-md shadow-lg hover:bg-gray-50 cursor-pointer" id="uploadNewBtn">
              <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
              </svg>
              Upload New File
            </div>

            <button id="deleteButton" class="absolute top-4 right-4 bg-white rounded-full p-2 shadow-lg hover:bg-red-500 hover:text-white">
              <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          <input type="file" id="defaultUpload" class="hidden" multiple accept="image/*" />
        </div>

        <div id="uploadProgress" class="hidden mt-4">
          <div class="bg-gray-200 rounded-full h-2">
            <div class="bg-[#0066b2] h-2 rounded-full transition-all duration-300" style="width: 0%"></div>
          </div>
        </div>
      </div>
    `;

      let currentImageIndex = 0;
      const dropArea = elem.querySelector("#dropArea");
      const inputElement = elem.querySelector("#defaultUpload");
      const uploadContent = elem.querySelector("#uploadContent");
      const imagePreview = elem.querySelector("#imagePreview");
      const mainImage = imagePreview.querySelector("img");
      const uploadProgress = elem.querySelector("#uploadProgress");
      const progressBar = uploadProgress.querySelector("div");

      // Set up Syncfusion uploader
      uploadObj = new Uploader({
        asyncSettings: path,
        success: onUploadSuccess,
        failure: onUploadFailure,
        progress: onUploadProgress,
        multiple: true,
        allowedExtensions: ".jpeg, .png, .jpg",
        cssClass: "hidden",
      });

      uploadObj.appendTo(inputElement);

      const displayImages = () => {
        if (!strm || strm.length === 0) {
          uploadContent.classList.remove("hidden");
          imagePreview.classList.add("hidden");
          return;
        }

        uploadContent.classList.add("hidden");
        imagePreview.classList.remove("hidden");
        mainImage.src = strm[currentImageIndex].src;

        // Update navigation buttons visibility
        elem.querySelector("#prevButton").style.display =
          strm.length > 1 ? "block" : "none";
        elem.querySelector("#nextButton").style.display =
          strm.length > 1 ? "block" : "none";
      };

      async function onUploadSuccess(args) {
        if (args.operation === "upload") {
          try {
            uploadProgress.classList.remove("hidden");
            progressBar.style.width = "90%";

            const file = args.file.rawFile;
            const compressedFile = await compressImage(file, 1);
            const filePath = `lockbox/photos/${userId}/${file.name}`;
            const downloadURL = await uploadImageToStorage(
              filePath,
              compressedFile
            );

            strm = strm
              ? [...strm, { src: downloadURL, name: file.name }]
              : [{ src: downloadURL, name: file.name }];
            currentImageIndex = strm.length - 1;

            progressBar.style.width = "100%";
            setTimeout(() => {
              displayImages();
              setTimeout(() => {
                uploadProgress.classList.add("hidden");
                progressBar.style.width = "0%";
              }, 300);
            }, 500);
          } catch (error) {
            console.error("Upload failed:", error);
            progressBar.classList.add("bg-red-500");
            setTimeout(() => {
              uploadProgress.classList.add("hidden");
              progressBar.style.width = "0%";
              progressBar.classList.remove("bg-red-500");
            }, 2000);
          }
        }
      }

      function onUploadProgress(args) {
        const progress = Math.min(
          Math.round((args.e.loaded / args.e.total) * 100),
          80
        );
        uploadProgress.classList.remove("hidden");
        progressBar.style.width = `${progress}%`;
      }

      function onUploadFailure(args) {
        console.error("Upload failed:", args);
      }

      // Event Listeners
      dropArea.addEventListener("click", (e) => {
        if (e.target.closest("#uploadContent")) {
          inputElement.click();
        }
      });

      const removeImage = (index) => {
        strm.splice(index, 1);
        if (currentImageIndex >= strm.length) {
          currentImageIndex = Math.max(0, strm.length - 1);
        }
        displayImages();
      };

      // Navigation and button handlers
      elem.querySelector("#prevButton").onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (strm && strm.length > 0) {
          currentImageIndex =
            (currentImageIndex - 1 + strm.length) % strm.length;
          displayImages();
        }
      };

      elem.querySelector("#nextButton").onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (strm && strm.length > 0) {
          currentImageIndex = (currentImageIndex + 1) % strm.length;
          displayImages();
        }
      };

      elem.querySelector("#uploadNewBtn").onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        inputElement.click();
      };

      elem.querySelector("#deleteButton").onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (strm && strm.length > 0) {
          removeImage(currentImageIndex);
        }
      };

      // Also update the dropArea click handler to be more specific
      dropArea.addEventListener("click", (e) => {
        const isButton = e.target.closest("button");
        const isUploadBtn = e.target.closest("#uploadNewBtn");
        if (!isButton && !isUploadBtn && e.target.closest("#uploadContent")) {
          inputElement.click();
        }
      });

      // Initialize with existing images
      if (args.rowData.photoPhotos) {
        strm = Array.isArray(args.rowData.photoPhotos)
          ? args.rowData.photoPhotos
          : [args.rowData.photoPhotos];
        displayImages();
      }
    },
  };

  let grid;

  const [activeMenuPhotos, setActiveMenuPhotos] = useState(null);
  const [isEditingPhotos, setIsEditingPhotos] = useState(false);
  const [showPhotosModal, setShowPhotosModal] = useState(false);
  const photosFormRef = useRef(null);
  const [isSubmittingPhotos, setIsSubmittingPhotos] = useState(false);
  const [photosToDeleteId, setPhotosToDeleteId] = useState(null);
  const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false);

  const {
    register: photosRegister,
    handleSubmit: photosHandleSubmit,
    formState: { errors: photosErrors },
    reset: photosReset,
    control: photosControl,
    setValue: photosSetValue,
    watch: photosWatch,
  } = useForm({
    defaultValues: {},
  });
  const photosValues = photosWatch();
  const onSubmitPhotos = async (values) => {
    setIsSubmittingPhotos(true);
    try {
      const photoId = isEditingPhotos ? values.PhotosID : generateUniqueId();
      const currentDate = new Date();

      const updatedData = {
        title: values.title,
        description: values.description,
        PhotosID: photoId,
        photoPhotos: values.photo || null,
        is_deleted: false,
        modified_date: currentDate.toLocaleDateString("en-US"),
        date_inserted: isEditingPhotos
          ? values.date_inserted
          : currentDate.toLocaleDateString("en-US"),
        access: Array.isArray(values.access) ? values.access : [],
      };

      const sanitizedData = sanitizeData(updatedData);

      // Log the final data to debug
      console.log("Sanitized data:", sanitizedData);

      if (isEditingPhotos) {
        await LockBoxService.updateDocumentInLockbox(
          userId,
          photoId,
          sanitizedData,
          "photos_data"
        );
      } else {
        await LockBoxService.addDocumentToLockbox(
          userId,
          sanitizedData,
          "photos_data"
        );
      }

      await fetchData();

      setShowPhotosModal(false);
      setIsEditingPhotos(false);
      photosReset();
      setIsSubmittingPhotos(false);
    } catch (error) {
      setIsSubmittingPhotos(false);
    }
  };

  const deletePhoto = async (data) => {
    try {
      for (const photoData of data) {
        const photoId = photoData.PhotosID;
        const fileUrl = photoData.photoPhotos;

        if (fileUrl) {
          const storage = getStorage();
          const fileRef = ref(storage, fileUrl);

          await deleteObject(fileRef);
        }

        const updatedData = { ...photoData, is_deleted: true };

        await LockBoxService.updateDocumentInLockbox(
          userId,
          photoId,
          updatedData,
          "photos_data"
        );
      }
      // Refresh the grid data after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  const actionBeginPhotos = async (args) => {
    if (PhotosGridRef.current && args.requestType === "beginEdit") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = false; // Hide modifiedDate when editing
        }
        if (col.field === "date_inserted") {
          col.allowEditing = false;
        }
      }
    }

    if (PhotosGridRef.current && args.requestType === "add") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = false; // Hide modifiedDate when adding
        }
      }
    }

    if (PhotosGridRef.current && args.requestType === "save") {
      const cols = PhotosGridRef.current.columns;
      for (const col of cols) {
        if (col.field === "date_inserted") {
          col.visible = true; // Show modifiedDate after saving
        }
      }
    }

    if (args.requestType === "save" && args.action === "add") {
      args.data.PhotosID = generateUniqueId();
      args.data.is_deleted = false;

      const currentDate = new Date();
      args.data.modified_date = currentDate.toLocaleDateString("en-US");
      args.data.date_inserted = currentDate.toLocaleDateString("en-US");

      const sanitizedData = sanitizeData(args.data);

      try {
        await LockBoxService.addDocumentToLockbox(
          userId,
          sanitizedData,
          "photos_data"
        );
        console.log("Photo added successfully:", args.data);
      } catch (error) {
        console.error("Error adding photo:", error);
      }
    }

    if (args.requestType === "beginEdit") {
      selectedRow = args.rowData;
    }

    if (args.requestType === "delete") {
      try {
        // Get the document data from args.data[0]
        const documentDataId = args.data[0].id;

        // Example usage
        await LockBoxService.softDeleteItemInLockbox(
          userId,
          documentDataId,
          "photos_data"
        );

        console.log("Photo marked as deleted:", documentDataId);

        // Refresh the grid data after updating
        await fetchData();
      } catch (error) {
        console.error("Error marking document as deleted:", error);
      }
    }
  };

  const actionCompletePhotos = async (args) => {
    if (args.requestType === "save" && args.action === "edit") {
      try {
        const documentId = selectedRow.PhotosID;

        delete args.data.date_inserted;

        if (args.data.modified_date) {
          const dateObj = new Date(args.data.modified_date);
          args.data.modified_date = dateObj.toLocaleDateString("en-US");
        }

        const sanitizedData = sanitizeData(args.data);

        await LockBoxService.updateDocumentInLockbox(
          userId,
          documentId,
          sanitizedData,
          "photos_data"
        );

        const updatedData = PhotosData.map((item) =>
          item.PhotosID === documentId ? sanitizedData : item
        );

        setPhotosData(updatedData);
        await fetchData();
      } catch (error) {
        console.error("Error updating photo:", error);
      }
    }
    if (args.requestType === "delete") {
      try {
        for (const docData of args.data) {
          const documentId = args.data[0].id;

          await LockBoxService.softDeleteItemInLockbox(
            userId,
            documentId,
            "documents_data"
          );
          console.log("Document marked as deleted:", args.data[0].id);
        }

        // Refresh the grid data after updating
        await fetchData();
      } catch (error) {
        console.error("Error marking document as deleted:", error);
      }
    }
    if (args.requestType === "beginEdit" || args.requestType === "add") {
      const dialog = args.dialog;
      dialog.header =
        args.requestType === "beginEdit" ? "Edit " : "Add New Record";
    }
  };

  const handleEditFilePhotos = useCallback(
    (newData) => {
      setIsEditingPhotos(true);
      setShowPhotosModal(true);
      setActiveMenuPhotos(null);
      const latestPhotoData = PhotosData?.filter(
        (photo) => photo?.id === selectedPhotoId
      )[0];
      photosReset({
        ...latestPhotoData,
        photo: newData,
      });
    },
    [PhotosData, photosReset, selectedPhotoId]
  );

  const onClosePhotosModal = useCallback(() => {
    setShowViewPhotosModal(false);
    setSelectedPhotoId(null);
    setSelectedPhoto({});
  }, []);

  const handlePhotosCollectionDelete = useCallback(
    async (newData) => {
      try {
        setIsSubmittingPhotos(true);

        const lockboxRef = doc(
          db,
          `lockbox/${userId}/photos_data/${selectedPhotoId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          await updateDoc(lockboxRef, {
            photoPhotos: newData,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedPhoto((prev) => ({
            ...prev,
            photoPhotos: newData,
          }));

          if (newData.length === 0) {
            onClosePhotosModal();
          }
        }

        await fetchData();
      } catch (error) {
        console.error("Error deleting photos:", error);
      } finally {
        setIsSubmittingPhotos(false);
      }
    },
    [userId, selectedPhotoId, fetchData, onClosePhotosModal]
  );

  const handlePhotosCollectionUpdate = useCallback(
    async (newFile) => {
      try {
        const lockboxRef = doc(
          db,
          `lockbox/${userId}/photos_data/${selectedPhotoId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          const existingPhotos = lockboxData.data()?.photoPhotos || [];
          const formattedNewFile = {
            dateAdded: new Date().toISOString(),
            details: "",
            name: newFile.name,
            src: newFile.src,
            type: newFile.type || "image/jpeg",
          };

          existingPhotos.push(formattedNewFile);

          await updateDoc(lockboxRef, {
            photoPhotos: existingPhotos,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedPhoto((prev) => ({
            ...prev,
            photoPhotos: existingPhotos,
          }));
        }

        await fetchData();
      } catch (error) {
        console.error("Error updating photos:", error);
      }
    },
    [userId, selectedPhotoId, fetchData]
  );

  const renderPhotosModal = useMemo(() => {
    return (
      <ViewPhotosModal
        isOpen={showViewPhotosModal}
        onClose={() => {
          onClosePhotosModal();
        }}
        photosData={selectedPhoto?.photoPhotos || []}
        storagePath={`lockbox/photos/${userId}`}
        handleCollectionUpdate={handlePhotosCollectionUpdate}
        dateKey="dateAdded"
        onEditClick={(newData) => {
          onClosePhotosModal();
          handleEditFilePhotos(newData);
        }}
        isLoadingDelete={isSubmittingPhotos}
        handleDelete={handlePhotosCollectionDelete}
      />
    );
  }, [
    showViewPhotosModal,
    selectedPhoto,
    userId,
    handlePhotosCollectionUpdate,
    isSubmittingPhotos,
    handlePhotosCollectionDelete,
    handleEditFilePhotos,
    onClosePhotosModal,
  ]);

  const onCloseDocumentsModal = useCallback(() => {
    setShowViewDocumentsModal(false);
    setSelectedDocument(null);
    setSelectedDocumentId(null);
  }, []);

  const handleEditFileDocuments = useCallback(
    (newData) => {
      setIsEditingDocuments(true);
      setShowDocumentsModal(true);
      setActiveMenuDocuments(null);
      const latestDocumentData = documentsData?.filter(
        (document) => document?.id === selectedDocumentId
      )[0];
      documentsReset({
        ...latestDocumentData,
        photodocuments: newData,
      });
    },
    [documentsData, documentsReset, selectedDocumentId]
  );

  const handleDocumentsCollectionDelete = useCallback(
    async (newData) => {
      try {
        setIsSubmittingDocuments(true);

        const lockboxRef = doc(
          db,
          `lockbox/${userId}/documents_data/${selectedDocumentId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          await updateDoc(lockboxRef, {
            photodocuments: newData,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedDocument((prev) => ({
            ...prev,
            photodocuments: newData,
          }));

          if (newData.length === 0) {
            onCloseDocumentsModal();
          }
        }

        await fetchData();
      } catch (error) {
        console.error("Error deleting documents:", error);
      } finally {
        setIsSubmittingDocuments(false);
      }
    },
    [userId, selectedDocumentId, fetchData, onCloseDocumentsModal]
  );

  const handleDocumentsCollectionUpdate = useCallback(
    async (newFile) => {
      try {
        const lockboxRef = doc(
          db,
          `lockbox/${userId}/documents_data/${selectedDocumentId}`
        );

        const lockboxData = await getDoc(lockboxRef);

        if (lockboxData.exists()) {
          const existingDocuments = lockboxData.data()?.photodocuments || [];
          const formattedNewFile = {
            dateAdded: new Date().toISOString(),
            details: "",
            name: newFile.name,
            src: newFile.src,
            type: newFile.type || "application/pdf",
          };

          existingDocuments.push(formattedNewFile);

          await updateDoc(lockboxRef, {
            photodocuments: existingDocuments,
            modified_date: new Date().toLocaleDateString("en-US"),
            updated_at: serverTimestamp(),
          });

          setSelectedDocument((prev) => ({
            ...prev,
            photodocuments: existingDocuments,
          }));
        }

        await fetchData();
      } catch (error) {
        console.error("Error updating documents:", error);
      }
    },
    [userId, selectedDocumentId, fetchData]
  );

  const renderDocumentsModal = useMemo(() => {
    return (
      <ViewPhotosModal
        isOpen={showViewDocumentsModal}
        onClose={() => {
          onCloseDocumentsModal();
        }}
        photosData={selectedDocument?.photodocuments || []}
        storagePath={`lockbox/documents/${userId}`}
        handleCollectionUpdate={handleDocumentsCollectionUpdate}
        dateKey="dateAdded"
        onEditClick={(newData) => {
          onCloseDocumentsModal();
          handleEditFileDocuments(newData);
        }}
        isLoadingDelete={isSubmittingDocuments}
        acceptedTypes=".pdf,image/*"
        handleDelete={handleDocumentsCollectionDelete}
      />
    );
  }, [
    showViewDocumentsModal,
    selectedDocument,
    userId,
    handleDocumentsCollectionUpdate,
    isSubmittingDocuments,
    handleDocumentsCollectionDelete,
    handleEditFileDocuments,
    onCloseDocumentsModal,
  ]);

  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);

  const [isInboxModalOpen, setIsInboxModalOpen] = useState(false);
  const [accessRequests, setAccessRequests] = useState([]);


  const fetchAccessRequests = async () => {
    try {
      const accessRequestsRef = collection(db, "lockbox", userId, "access_requests");
      const q = query(
        accessRequestsRef,
        where("is_deleted", "==", false)

      );
      const querySnapshot = await getDocs(q);
      const requests = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      const sortedRequests = requests.sort((a, b) => 
        new Date(b.requested_at) - new Date(a.requested_at)
      );
      
      setAccessRequests(sortedRequests);
    } catch (error) {
      console.error("Error fetching access requests:", error);
    }
  };


  useEffect(() => {
    if (isInboxModalOpen) {
      fetchAccessRequests();
    }
  }, [isInboxModalOpen]);

  const handleApproveRequest = async (requestId) => {
    try {
      const requestRef = doc(db, "lockbox", userId, "access_requests", requestId);
      await updateDoc(requestRef, {
        status: 'approved',
        updated_at: new Date().toISOString()
      });
      fetchAccessRequests();
    } catch (error) {
      console.error("Error approving request:", error);
    }
  };
  
  const handleRejectRequest = async (requestId) => {
    try {
      const requestRef = doc(db, "lockbox", userId, "access_requests", requestId);
      await updateDoc(requestRef, {
        status: 'rejected',
        updated_at: new Date().toISOString()
      });
      fetchAccessRequests();
    } catch (error) {
      console.error("Error rejecting request:", error);
    }
  };

  if (!isUnlocked) {
    return (
      <>
        {pinShow ? (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
            <ShowPin
              isOpen={pinShow}
              onClose={() => setPinShow(false)}
              firstTime={true}
            />
          </div>
        ) : (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-md">
            <div className="absolute right-4 top-4">
              <button
                onClick={() => setShowPinInstructions(!showPinInstructions)}
                className="hover:bg-gray-100 inline-flex h-8 w-8 items-center justify-center rounded-full border border-gray-300 bg-gray-200 text-sm text-gray-500 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                title="Show PIN instructions"
              >
                ?
              </button>
              {showPinInstructions && (
                <div className="absolute right-0 top-10 z-10 w-64 rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
                  <p className="text-gray-600 mb-2 text-sm font-medium">
                    To view your Lockbox PIN:
                  </p>
                  <ol className="text-gray-600 ml-4 list-decimal text-left text-sm">
                    <li>Click on your profile icon in the top right</li>
                    <li>Select "Show Lockbox PIN"</li>
                    <li>Enter your account password to reveal the PIN</li>
                  </ol>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center rounded-lg p-8 ">
              <img
                src={LockImage}
                alt="Lockbox"
                className="mb-6 h-[290px] w-[290px]"
              />
              <div className="mb-4 text-center text-sm font-medium text-red-600">
                <strong>Note:</strong> For secure access, your Lockbox PIN can
                be viewed in "My Profile" menu <br />
                by selecting 'Show Lockbox PIN'.
              </div>

              <div className="mb-4 flex space-x-8">
                {inputValues.map((value, index) => (
                  <input
                    key={index}
                    type="password"
                    maxLength="1"
                    className="lockbox-input h-12 w-12 rounded-xl border border-gray-300 bg-gray-300 text-center text-2xl"
                    pattern="[0-9]"
                    value={value}
                    onChange={(event) => onInput(index, event)}
                    onKeyDown={(event) => onKeyDown(index, event)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              {wrongPinMessage && (
                <div className="mb-4 text-sm text-red-600">
                  Wrong PIN entered, please try again.
                </div>
              )}
              <div className="flex w-full space-x-4">
                <button
                  onClick={handleGoBack}
                  className="btn-cancel flex flex-1 cursor-pointer items-center justify-center px-4 py-2"
                >
                  <span className="mr-2">←</span> Go Back
                </button>
                <button
                  onClick={handleUnlock}
                  className="btn-default flex flex-1 cursor-pointer items-center justify-center px-4 py-2"
                >
                  <svg
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <g id="Shield Done">
                      <path
                        id="Fill 1"
                        opacity="0.4"
                        d="M9.08649 20.5C8.96266 20.5 8.83884 20.4716 8.72709 20.4137L5.12599 18.5496C4.10415 18.0201 3.30481 17.4259 2.68063 16.7336C1.31449 15.2195 0.554402 13.276 0.542321 11.2599L0.500038 4.62426C0.495004 3.85842 0.989312 3.17103 1.72826 2.91215L8.3405 0.606788C8.73313 0.466564 9.17106 0.464603 9.57073 0.599924L16.2081 2.82684C16.9511 3.07493 17.4535 3.75742 17.4575 4.52228L17.4998 11.1628C17.5129 13.176 16.779 15.1274 15.434 16.6581C14.8168 17.3602 14.0245 17.9632 13.0128 18.5025L9.44388 20.4088C9.33314 20.4686 9.21032 20.499 9.08649 20.5Z"
                        fill="white"
                      />
                      <path
                        id="Fill 4"
                        d="M8.31936 12.8209C8.12607 12.8219 7.93278 12.7523 7.78378 12.6091L5.86695 10.7656C5.57097 10.4793 5.56795 10.0145 5.86091 9.72617C6.15387 9.4369 6.63207 9.43396 6.92906 9.71931L8.30829 11.0451L11.6758 7.72479C11.9698 7.43552 12.448 7.43258 12.744 7.71793C13.041 8.00426 13.044 8.47004 12.751 8.75735L8.85193 12.6022C8.70494 12.7474 8.51266 12.8199 8.31936 12.8209Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {renderPhotosModal}
      {renderDocumentsModal}
      <div
        className={`m-0 p-2 box-border flex w-[1648px] max-w-full flex-col items-start justify-start gap-2.5 leading-[normal] tracking-[normal] ${className}`}
      >
        <div className="w-full">
          {/* <div className="text-[24px] text-[#003460] font-semibold pb-3">Lockbox</div> */}
          <DialogComponent
            header={deleteDialogProps.title}
            content={deleteDialogProps.content}
            visible={deleteDialogProps.isOpen}
            showCloseIcon={true}
            closeOnEscape={true}
            width="300px"
            buttons={[
              {
                click: handleDeleteConfirm,
                buttonModel: { content: "Yes", isPrimary: true },
              },
              {
                click: handleDeleteDialogClose,
                buttonModel: { content: "No" },
              },
            ]}
            close={handleDeleteDialogClose}
            cssClass="delete-confirmation-dialog"
          />

          <section className="flex max-w-full flex-col items-start justify-start self-stretch bg-white">
          <HeaderTitle 
  id="documents" 
  title="DOCUMENTS" 
  rightComponent={
    <div className="flex gap-2">
      
      <button 
        className="px-4 py-1 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 text-sm flex items-center gap-1"
        onClick={() => setIsInboxModalOpen(true)}
      >
        <span>Inbox</span>
        {accessRequests.length > 0 && (
          <span className="bg-red-500 text-white text-xs rounded-full px-2">
            {accessRequests.length}
          </span>
        )}
      </button>
    </div>
  }
/>
<Modal
  isOpen={isInboxModalOpen}
  title="Access Requests Inbox"
  onClose={() => setIsInboxModalOpen(false)}
  noFooter={true}
>
  <div className="flex flex-col gap-4">
    {accessRequests.length > 0 ? (
      accessRequests.map((request) => (
        <div key={request.id} className="border rounded-lg p-4 hover:bg-gray-50">
          <div className="flex justify-between items-start mb-2">
            <div>
              <div className="font-semibold">{request.requested_by_name}</div>
              <div className="text-sm text-gray-500">{request.requested_by_org}</div>
            </div>
            <div className="flex items-center gap-2">
              <span className={`px-2 py-1 rounded-full text-xs ${
                request.status === 'pending' 
                  ? 'bg-yellow-100 text-yellow-800'
                  : request.status === 'approved'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              }`}>
                {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
              </span>
              <div className="text-xs text-gray-500">
                {new Date(request.requested_at).toLocaleDateString()}
              </div>
            </div>
          </div>
          <div className="text-sm mb-2">
            <span className="font-medium">Requested Documents: </span>
            {request.documents.join(", ")}
          </div>
          <div className="text-sm text-gray-600">
            <span className="font-medium">Message: </span>
            {request.message}
          </div>
          {request.status === 'pending' && (
            <div className="flex gap-2 mt-3">
              <button
                className="px-3 py-1 bg-green-600 text-white rounded-md hover:bg-green-700 text-sm"
                onClick={() => handleApproveRequest(request.id)}
              >
                Approve
              </button>
              <button
                className="px-3 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 text-sm"
                onClick={() => handleRejectRequest(request.id)}
              >
                Reject
              </button>
            </div>
          )}
        </div>
      ))
    ) : (
      <div className="text-center text-gray-500 py-8">
        No access requests found
      </div>
    )}
  </div>
</Modal>  
            <div className="mt-3 flex w-full items-center justify-end"></div>
            <div className="flex flex-col gap-4 self-stretch">
              <GridComponent
                key={documentsData.length}
                ref={documentGridRef}
                dataSource={documentsData}
                // allowSorting={true}
                // sortSettings={sortSettings}
                editSettings={editing}
                // filterSettings={filterSettings}
                // allowFiltering={true}
                // actionBegin={actionBeginDocuments}
                // actionComplete={actionCompleteDocuments}
                allowPaging={true}
                pageSettings={pageSettings}
                // showColumnMenu={true}
                columnMenuItems={columnMenuItems}
                cssClass="new-grid-color allow-overflow-grid allow-overflow-grid-text"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="documentsID"
                    headerText="documentsID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="type"
                    headerText="Type"
                    width="100"
                    editType="dropdownedit"
                    edit={typeParams}
                  />
                  <ColumnDirective
                    field="description"
                    headerText="Description"
                    width="100"
                  />
                  <ColumnDirective
                    field="access"
                    headerText="People with Access"
                    width="80"
                    editTemplate={accessEditTemplateDocuments}
                    template={(props) => {
                      return Array.isArray(props.access)
                        ? props.access.length
                        : 0;
                    }}
                  />
                  <ColumnDirective
                    field="date_inserted"
                    headerText="Date Uploaded"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="modified_date"
                    headerText="Modified"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    visible={false}
                  />
                  <ColumnDirective
                    field="photodocuments"
                    headerText="Documents"
                    width="100"
                    // template={documentsTemplate}
                    template={(props) => {
                      return (
                        <PhotoColumn
                          data={props.photodocuments}
                          // showImage={true}
                          // src={props?.photodocuments[0]?.src}
                          type="document"
                          onClick={() => {
                            const { column, index, foreignKeyData, ...rest } =
                              props;
                            console.log("Clicked props:", props);

                            setShowViewDocumentsModal(true);
                            setSelectedDocument(rest);
                            setSelectedDocumentId(props.id);
                          }}
                        />
                      );
                    }}
                    // edit={documentsEdit}
                  />

                  <ColumnDirective
                    headerText="Actions"
                    textAlign="Center"
                    width="120"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.documentsID}
                            activeMenuId={activeMenuDocuments}
                            setActiveMenuId={setActiveMenuDocuments}
                            clickOutside={false}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // setSelectedHouseholdMember(props);
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingDocuments(true);
                              setShowDocumentsModal(true);
                              setActiveMenuDocuments(null);
                              documentsReset({
                                title: props?.title || "",
                                description: props?.description || "",
                                access: props?.access || "",
                                modified_date: props?.modified_date || "",
                                relationship: props?.relationship || "",
                                photo: props?.photoDocuments || "",
                                documentsID: props?.documentsID || "",
                                ...rest,
                              });
                              console.log("edit");
                              console.log("PROPSSSSSS", props);
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteDocumentModal(true);
                              setActiveMenuDocuments(null);
                              setDocumentsToDeleteId(props?.id);
                              console.log("delete");
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  {/* <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                /> */}
                </ColumnsDirective>
                <Inject
                  services={[
                    Page,
                    Edit,
                    Toolbar,
                    Sort,
                    Filter,
                    ColumnMenu,
                    CommandColumn,
                  ]}
                />
              </GridComponent>
            </div>
            <AddNewButton
              onClick={() => {
                setIsEditingDocuments(false);
                setShowDocumentsModal(true);
                documentsReset({
                  type: "",
                  description: "",
                  access: "",
                  date_inserted: "",
                  modified_date: "",
                  photodocuments: "",
                  is_deleted: false,
                  documentsID: "",
                  fileUrl: "",
                  documents: null,
                });
              }}
              name="Add New Documents"
            />
          </section>

          <DeleteModal
            isOpen={showDeleteDocumentModal}
            onClose={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowDeleteDocumentModal(false);
              setDocumentsToDeleteId(null);
            }}
            onDelete={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsSubmittingDocuments(true);

              try {
                // Call the LockBoxService to soft delete the document
                await LockBoxService.softDeleteItemInLockbox(
                  userId,
                  documentsToDeleteId,
                  "documents_data"
                );

                // Close modal and refresh data
                setShowDeleteDocumentModal(false);
                setIsSubmittingDocuments(false);
                await fetchData();
              } catch (error) {
                console.error("Error deleting document:", error);
                setIsSubmittingDocuments(false);
              }
            }}
            itemName="Document"
            loading={isSubmittingDocuments}
          />

          <Modal
            isOpen={showDocumentsModal}
            title={isEditingDocuments ? "Edit Documents" : "Add New Documents"}
            onClose={() => {
              setShowDocumentsModal(false);
              setActiveMenuDocuments(false);
              documentsReset();
              // setSelectedHouseholdMember(null);
            }}
            formRef={documentsFormRef}
            isLoading={isSubmittingDocuments}
            isEdit={isEditingDocuments}
          >
            <form
              onSubmit={documentsHandleSubmit(onSubmitDocuments)}
              className="flex flex-col gap-4"
              ref={documentsFormRef}
            >
              <SimpleFileUploader
                storagePath={`lockbox/documents/${userId}`}
                onFileUpload={(files) => {
                  if (files) {
                    documentsSetValue("photodocuments", files);
                  }
                }}
                initialPhoto={documentsWatch("photodocuments")}
                acceptedTypes=".pdf,image/*"
                className="mb-4"
              />
              <div className="w-full">
                <CustomSelect
                  name="type"
                  placeholder="Type"
                  register={{
                    ...documentsRegister("type", {
                      required: "Type is required",
                    }),
                  }}
                  options={documentTypes}
                  errors={documentsErrors}
                />
              </div>
              <div className="w-full">
                <CustomTextArea
                  type="text"
                  name="description"
                  placeholder="Description"
                  register={{
                    ...documentsRegister("description", {
                      required: "Description is required",
                      // pattern: {
                      //   value: alphanumericRegex,
                      //   message: "Numbers are not allowed in names",
                      // },
                    }),
                  }}
                  errors={documentsErrors}
                />
              </div>
              <div className="col-span-4">
              <Controller
                control={documentsControl}
                name="access"
                defaultValue={[]}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <CustomMultiSelect
                    id="access"
                    label="People with Access"
                    options={accessOptions}
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
              </div>
            </form>
          </Modal>

          <section className="mt-4 flex max-w-full flex-col items-start justify-start self-stretch bg-white">
            <HeaderTitle id="photos" title="PHOTOS" />
            <div className="flex w-full justify-end"></div>
            <div className=" mt-3 flex flex-col gap-4 self-stretch">
              <GridComponent
                ref={PhotosGridRef}
                dataSource={PhotosData}
                // allowSorting={true}
                // sortSettings={sortSettings}
                editSettings={editing}
                // filterSettings={filterSettings}
                // allowFiltering={true}
                // actionComplete={actionCompletePhotos}
                // actionBegin={actionBeginPhotos}
                allowPaging={true}
                pageSettings={pageSettings}
                // showColumnMenu={true}
                // columnMenuItems={columnMenuItems}
                cssClass="new-grid-color allow-overflow-grid allow-overflow-grid-text"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="PhotosID"
                    headerText="PhotosID"
                    isPrimaryKey={true}
                    visible={false}
                  />
                  <ColumnDirective
                    field="is_deleted"
                    headerText="Status"
                    visible={false}
                  />
                  <ColumnDirective
                    field="title"
                    headerText="Title"
                    width="100"
                  />
                  <ColumnDirective
                    field="description"
                    headerText="Description"
                    width="100"
                  />

                  <ColumnDirective
                    field="access"
                    headerText="People with Access"
                    width="80"
                    editTemplate={accessEditTemplatePhotos}
                    template={(props) => {
                      return Array.isArray(props.access)
                        ? props.access.length
                        : 0;
                    }}
                  />
                  <ColumnDirective
                    field="date_inserted"
                    headerText="Date Uploaded"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    allowEditing={false}
                  />

                  <ColumnDirective
                    field="modified_date"
                    headerText="Modified"
                    width="100"
                    format="MM/dd/yyyy"
                    editType="datepickeredit"
                    edit={datepickerparams}
                    visible={false}
                  />
                  <ColumnDirective
                    field="photoPhotos"
                    headerText="Photos"
                    width="100"
                    // template={photosTemplate}
                    template={(props) => {
                      return (
                        <PhotoColumn
                          data={props.photoPhotos}
                          onClick={() => {
                            const { column, index, foreignKeyData, ...rest } =
                              props;
                            console.log("Clicked props:", props);

                            setShowViewPhotosModal(true);
                            setSelectedPhoto(rest);
                            setSelectedPhotoId(props.id);
                          }}
                        />
                      );
                    }}
                    // edit={PhotosEdit}
                  />
                  <ColumnDirective
                    headerText="Actions"
                    textAlign="Center"
                    width="120"
                    template={(props) => {
                      return (
                        <div className="flex w-full justify-center overflow-visible px-4">
                          <ThreeDotMenu
                            menuId={props?.PhotosID}
                            activeMenuId={activeMenuPhotos}
                            setActiveMenuId={setActiveMenuPhotos}
                            clickOutside={false}
                            onEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const { column, index, foreignKeyData, ...rest } =
                                props;
                              setIsEditingPhotos(true);
                              setShowPhotosModal(true);
                              setActiveMenuPhotos(null);

                              photosReset({
                                title: props?.title || "",
                                description: props?.description || "",
                                access: props?.access || "",
                                modified_date: props?.modified_date || "",
                                relationship: props?.relationship || "",
                                photoPhotos: props?.photoPhotos || "",
                                photo: props?.photoPhotos || "",
                                PhotosID: props?.PhotosID || "",
                                ...rest,
                              });
                            }}
                            onDeleteClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeletePhotoModal(true);
                              setActiveMenuPhotos(null);
                              setPhotosToDeleteId(props?.id);
                              console.log("delete");
                            }}
                          />
                        </div>
                      );
                    }}
                  />

                  {/* <ColumnDirective
                  headerText="Actions"
                  width="120"
                  commands={[
                    {
                      type: "Edit",
                      buttonOption: {
                        content: '<i class="fas fa-edit"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                    {
                      type: "Delete",
                      buttonOption: {
                        content: '<i class="fas fa-trash-alt"></i>',
                        cssClass: "e-outline custom-button",
                      },
                    },
                  ]}
                /> */}
                </ColumnsDirective>
                <Inject
                  services={[Page, Edit, Toolbar, Sort, Filter, ColumnMenu]}
                />
              </GridComponent>
            </div>
            <AddNewButton
              onClick={() => {
                setIsEditingPhotos(false);
                setShowPhotosModal(true);
                photosReset({
                  title: "",
                  description: "",
                  access: "",
                  date_inserted: "",
                  modified_date: "",
                  photoPhotos: "",
                  fileUrl: "",
                  is_deleted: false,
                  PhotosID: "",
                  photo: null,
                });
              }}
              name="Add New Photos"
            />
            {/* <button
            type="button"
            className="mt-3 flex cursor-pointer items-center gap-1 bg-white py-1 text-[#003460] hover:text-blue-800"
            onClick={handleAddClickPhotos}
          >
            <FaPlus className="h-4 w-4" />
            <span className="px-1 text-[#003460]">Add New Photos</span>
          </button> */}
          </section>
        </div>
        <Modal
          isOpen={showPhotosModal}
          title={isEditingPhotos ? "Edit Photos" : "Add New Photos"}
          onClose={() => {
            setShowPhotosModal(false);
            setActiveMenuPhotos(false);
            photosReset();
            // setSelectedHouseholdMember(null);
          }}
          formRef={photosFormRef}
          isLoading={isSubmittingPhotos}
          isEdit={isEditingPhotos}
        >
          <form
            onSubmit={photosHandleSubmit(onSubmitPhotos)}
            className="flex flex-col gap-4"
            ref={photosFormRef}
          >
            <SimpleFileUploader
              storagePath={`lockbox/photos/${userId}`}
              onFileUpload={(photoUrls) => {
                if (photoUrls) {
                  photosSetValue("photo", photoUrls);
                  photosSetValue("photoPhotos", photoUrls);
                }
              }}
              initialPhoto={photosValues.photo}
              acceptedTypes="image/*"
              className="mb-4"
            />
            <div className="w-full">
              <CustomInput
                type="text"
                name="title"
                placeholder="Name"
                register={{
                  ...photosRegister("title", {
                    required: "Name is required",
                  }),
                }}
                errors={photosErrors}
              />
            </div>
            <div className="w-full">
              <CustomTextArea
                type="text"
                name="description"
                placeholder="Description"
                register={{
                  ...photosRegister("description", {
                    required: "Description is required",
                    // pattern: {
                    //   value: alphanumericRegex,
                    //   message: "Numbers are not allowed in names",
                    // },
                  }),
                }}
                errors={photosErrors}
              />
            </div>
            <div className="col-span-4">
              <Controller
                control={photosControl}
                name="access"
                defaultValue={[]}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <CustomMultiSelect
                    id="access"
                    label="People with Access"
                    options={accessOptions}
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </div>
          </form>
        </Modal>

        <DeleteModal
          isOpen={showDeletePhotoModal}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeletePhotoModal(false);
            setPhotosToDeleteId(null);
          }}
          onDelete={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsSubmittingPhotos(true);

            try {
              // Call the LockBoxService to soft delete the document
              await LockBoxService.softDeleteItemInLockbox(
                userId,
                photosToDeleteId,
                "photos_data"
              );

              // Close modal and refresh data
              setShowDeletePhotoModal(false);
              setIsSubmittingPhotos(false);
              await fetchData();
            } catch (error) {
              console.error("Error deleting Photo:", error);
              setIsSubmittingPhotos(false);
            }
          }}
          itemName="Photo"
          loading={isSubmittingPhotos}
        />

        <FileDialog
          isOpen={isFileDialogOpen}
          onClose={() => setIsFileDialogOpen(false)}
          files={selectedFiles}
          currentIndex={currentFileIndex}
          setCurrentIndex={setCurrentFileIndex}
        />
        <ImageDialog
          isOpen={isImageDialogOpen}
          onClose={() => setIsImageDialogOpen(false)}
          images={selectedImages}
          currentIndex={currentImageIndex}
          setCurrentIndex={setCurrentImageIndex}
        />
      </div>
    </>
  );
};

export default Lockbox;
