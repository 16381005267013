import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import deleteFIleIcon from "../assets/imageUploader/delete-file-icon.png";
import fileUploadIcon from "../assets/imageUploader/file-upload-icon.png";
import { storage } from "../firebase";
import { imageNameFormatToDate } from "../utils/formatter";
import ViewPhotoOverlay from "./ViewPhotoOverlay";

const SimpleFileUploader = ({
  storagePath,
  onFileUpload,
  className,
  initialPhoto,
  acceptedTypes = "image/*",
  dateKey = "dateAdded",
}) => {
  const [uploading, setUploading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const inputFileRef = useRef();

  useEffect(() => {
    if (initialPhoto) {
      const initialPhotos = Array.isArray(initialPhoto)
        ? initialPhoto
        : [initialPhoto];

      const formattedPhotos = initialPhotos
        .map((photo) => {
          if (typeof photo === "string") {
            return {
              src: photo,
              name: "Uploaded file",
              type: "image",
              [dateKey]: new Date().toISOString(),
              details: "",
            };
          }
          return photo;
        })
        .filter(Boolean);

      setPhotos(formattedPhotos);
    }
  }, [initialPhoto, dateKey]);

  const handleFileChange = useCallback(
    (newPhotos) => {
      setPhotos(newPhotos);
      onFileUpload?.(newPhotos);
    },
    [onFileUpload]
  );

  const onUploadFile = useCallback(
    async (e) => {
      const file = e.target.files[0];

      if (!file && !storagePath) {
        alert("Something went wrong while uploading the file.");
        return;
      }
      const formattedFileName = imageNameFormatToDate(file?.name);
      const path = `${storagePath}/${formattedFileName}`;
      const uploadFileRef = ref(storage, path);

      try {
        setUploading(true);
        const uploadFile = await uploadBytes(uploadFileRef, file);
        const downloadUrl = await getDownloadURL(uploadFile.ref);

        const newFile = {
          src: downloadUrl,
          name: file.name,
          type: file.type,
          [dateKey]: new Date().toISOString(),
          details: "",
        };

        const newPhotos = [...photos, newFile];
        handleFileChange(newPhotos);
        setCurrentIndex(newPhotos.length - 1);
        inputFileRef.current.value = "";
        setUploading(false);
      } catch (error) {
        setUploading(false);
        alert("Something went wrong while uploading the file.");
      }
    },
    [storagePath, photos, dateKey, handleFileChange]
  );

  const handleDelete = (indexToDelete) => {
    const newPhotos = photos.filter((_, index) => index !== indexToDelete);
    setCurrentIndex(Math.min(currentIndex, newPhotos.length - 1));
    handleFileChange(newPhotos);
  };

  const handleNavigation = (direction) => {
    setCurrentIndex((prev) => {
      if (direction === "next") {
        return (prev + 1) % photos.length;
      } else {
        return (prev - 1 + photos.length) % photos.length;
      }
    });
  };

  const renderFilePreview = (file) => {
    const isPDF =
      file.src?.toLowerCase().includes(".pdf") || file.type?.includes("pdf");

    if (isPDF) {
      return (
        <div className="bg-gray-100 flex h-full w-full flex-col items-center justify-center">
          <svg
            className="h-20 w-20 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>

          <p className="text-gray-600 mt-2 text-sm">
            {file.name || "PDF Document"}
          </p>
        </div>
      );
    } else {
      return (
        <img
          src={file.src}
          className="h-full w-full object-cover"
          alt={file.name || `uploaded-${currentIndex}`}
        />
      );
    }
  };

  const renderFileList = useMemo(() => {
    return photos.length > 0 ? (
      <div className="flex flex-col gap-3">
        {" "}
        {/* Wrapper div for indicators and image */}
        {/* Indicators above the image */}
        {photos.length > 1 && (
          <div className="flex justify-center space-x-2">
            {photos.map((_, index) => (
              <div
                key={index}
                className={`h-3 w-3 rounded-full transition-all duration-300 ${
                  index === currentIndex ? "bg-blue-900" : "bg-gray-400"
                } cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentIndex(index);
                }}
              ></div>
            ))}
          </div>
        )}
        <div className="relative flex h-[300px] rounded-md border border-solid border-[#CFCFCF]">
          <ViewPhotoOverlay />
          {renderFilePreview(photos[currentIndex])}

          {photos.length > 1 && (
            <>
              <button
                type="button"
                className="hover:bg-gray-50 absolute left-3 top-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-white p-2 shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigation("prev");
                }}
              >
                <svg
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="hover:bg-gray-50 absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-white p-2 shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigation("next");
                }}
              >
                <svg
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </>
          )}

          <button
            type="button"
            className="absolute right-3 top-3 h-[60px] w-[60px] cursor-pointer bg-transparent  hover:text-white"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(currentIndex);
            }}
          >
            <img
              src={deleteFIleIcon}
              className="h-full w-full object-contain p-2"
              alt="delete"
            />
          </button>

          <button
            type="button"
            className="hover:bg-gray-50 absolute bottom-4 left-1/2 flex -translate-x-1/2 cursor-pointer items-center gap-2 rounded-md bg-transparent px-4 py-2"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              inputFileRef.current.click();
            }}
          >
            <svg
              width="19"
              height="22"
              viewBox="0 0 19 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 22C1.45 22 0.979167 21.8042 0.5875 21.4125C0.195833 21.0208 0 20.55 0 20V6H2V20H13V22H2ZM6 18C5.45 18 4.97917 17.8042 4.5875 17.4125C4.19583 17.0208 4 16.55 4 16V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H13L19 6V16C19 16.55 18.8042 17.0208 18.4125 17.4125C18.0208 17.8042 17.55 18 17 18H6ZM12 7H17L12 2V7Z"
                fill="#FAFAFA"
              />
            </svg>
            <p className=" text-base text-white">Upload New File</p>
          </button>
        </div>
      </div>
    ) : (
      <div
        className="flex h-[300px] cursor-pointer flex-col items-center justify-center space-y-3 rounded-md border border-dashed border-[#003460]"
        onClick={() => inputFileRef.current.click()}
      >
        <div className="h-[30px] w-[30px]">
          <img
            src={fileUploadIcon}
            className="h-full w-full object-contain"
            alt="upload"
          />
        </div>
        <p className="text-sm text-[#525252]">
          {uploading ? "Uploading..." : "Click to Upload"}
        </p>
        <p className="text-xs text-gray-500">
          {acceptedTypes === "image/*"
            ? "Images only"
            : "PDF and Images supported"}
        </p>
      </div>
    );
  }, [photos, currentIndex, uploading, acceptedTypes]);

  return (
    <div className={className}>
      <input
        type="file"
        className="hidden"
        disabled={uploading}
        ref={inputFileRef}
        onChange={onUploadFile}
        accept={acceptedTypes}
      />
      {renderFileList}
    </div>
  );
};

SimpleFileUploader.propTypes = {
  storagePath: PropTypes.string.isRequired,
  onFileUpload: PropTypes.func,
  className: PropTypes.string,
  initialPhoto: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  acceptedTypes: PropTypes.string,
};

export default SimpleFileUploader;
