import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import fileUploadIcon from "../assets/imageUploader/file-upload-icon.png";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

const SinglePhotoUploader = ({ storagePath, onFileUpload, className, initialPhoto }) => {
  const [uploading, setUploading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const inputFileRef = useRef();

  const onUploadFile = useCallback(
    async (e) => {
      const file = e.target.files[0];

      if (!file && !storagePath) {
        alert("Something went wrong while uploading the file.");
        return;
      }

      const path = `${storagePath}/${file.name}`;
      const uploadFileRef = ref(storage, path);

      try {
        setUploading(true);
        const uploadFile = await uploadBytes(uploadFileRef, file);
        const downloadUrl = await getDownloadURL(uploadFile.ref);
        
        const newFile = {
          src: downloadUrl,
          name: file.name,
          type: file.type,
          dateAdded: new Date().toISOString(),
          details: ""
        };

        onFileUpload?.(newFile);
        setUploading(false);
      } catch (error) {
        setUploading(false);
        alert("Something went wrong while uploading the file.");
      }
    },
    [storagePath, onFileUpload]
  );

  return (
    <div className={className}>
      <input
        type="file"
        className="hidden"
        disabled={uploading}
        ref={inputFileRef}
        onChange={onUploadFile}
        accept="image/*"
      />
      
      {initialPhoto?.src ? (
        // Show uploaded photo with hover overlay
        <div 
          className="relative h-[300px] rounded-md border border-solid border-[#CFCFCF] overflow-hidden"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img 
            src={initialPhoto.src} 
            className="h-full w-full object-cover" 
            alt={initialPhoto.name || 'Uploaded photo'} 
          />
          
          {/* Hover Overlay */}
          {isHovered && (
            <div 
              className="absolute inset-0 bg-black/50 flex items-center justify-center cursor-pointer transition-all duration-200"
              onClick={() => inputFileRef.current.click()}
            >
              <div className="flex flex-col items-center text-white gap-2">
                <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                </svg>
                <span className="text-sm font-medium">Change Photo</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        // Empty state uploader
        <div
          className="flex h-[300px] cursor-pointer flex-col items-center justify-center space-y-3 rounded-md border border-dashed border-[#003460]"
          onClick={() => inputFileRef.current.click()}
        >
          <div className="h-[30px] w-[30px]">
            <img src={fileUploadIcon} className="h-full w-full object-contain" alt="upload" />
          </div>
          <p className="text-sm text-[#525252]">
            {uploading ? "Uploading..." : "Click to Upload Photo"}
          </p>
        </div>
      )}
    </div>
  );
};

SinglePhotoUploader.propTypes = {
  storagePath: PropTypes.string.isRequired,
  onFileUpload: PropTypes.func,
  className: PropTypes.string,
  initialPhoto: PropTypes.shape({
    src: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default SinglePhotoUploader;