const CustomInput = ({ errors, name, placeholder, register, ...props }) => {
  return (
    <div className="relative">
      {/* <label htmlFor={name}>{label}</label> */}
      <input
        // id={name}
        className={`peer h-[50px] w-full rounded-[4px] px-3 pb-1 pt-4 text-base outline-none transition-all
          ${
            errors[name]
              ? "border-red-500 focus:border-red-500"
              : "border-gray-200 hover:border-[#8F8F8F] focus:!border-darkslateblue"
          }
        `}
        style={{
          border: errors[name] ? "1px solid #FF0000" : "1px solid #CFCFCF",
          borderColor: errors[name] ? "#FF0000" : "#CFCFCF",
          "&:hover": {
            borderColor: errors[name] ? "#FF0000" : "#8F8F8F",
          },
          "&:focus": {
            borderColor: errors[name] ? "#FF0000" : "#193861",
          },
        }}
        {...props}
        {...register}
      />
      <label
        htmlFor={name}
        className={`absolute left-3 top-1 text-[14px] transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-xs peer-focus:text-[14px]
          ${errors[name] ? "text-red-500" : "text-smokey-grey"}  
        `}
      >
        {placeholder}
      </label>
      {errors[name] && (
        <p className="mt-1 text-xs text-red-500">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default CustomInput;
