import { format } from "date-fns";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { CalendarDays } from "lucide-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import blueSkyTransparentIcon from "../../../assets/bluesky-transparent-icon.png";
import editIcon from "../../../assets/caseManagement/header/edit-icon.png";
import anchorIcon from "../../../assets/caseManagement/sidebar/anchor-icon.png";
import anchorLastIndexIcon from "../../../assets/caseManagement/sidebar/anchor-last-index-icon.png";
import calendarHighlightedIcon from "../../../assets/caseManagement/sidebar/calendar-highlighted-icon.png";
import calendarIcon from "../../../assets/caseManagement/sidebar/calendar-icon.png";
import caseMgtIcon from "../../../assets/caseManagement/sidebar/case-management-agency-icon.png";
import caseMgtHighlightedIcon from "../../../assets/caseManagement/sidebar/case-management-highlighted-icon.png";
import caseManagerIcon from "../../../assets/caseManagement/sidebar/case-manager-icon.png";
import communityContactsHighlightedIcon from "../../../assets/caseManagement/sidebar/community-contacts-highlighted-icon.png";
import communityContactsIcon from "../../../assets/caseManagement/sidebar/community-contacts-icon.png";
import dashboardHighlightedIcon from "../../../assets/caseManagement/sidebar/dashboard-highlighted-icon.png";
import dashboardIcon from "../../../assets/caseManagement/sidebar/dashboard-icon.png";
import disasterHighlightedIcon from "../../../assets/caseManagement/sidebar/disaster-highlighted-icon.png";
import disasterIcon from "../../../assets/caseManagement/sidebar/disaster-icon.png";
import partnerHighlightedIcon from "../../../assets/caseManagement/sidebar/partner-highlighted-icon.png";
import partnersIcon from "../../../assets/caseManagement/sidebar/partners-icon.png";
import survivorsHighlightedIcon from "../../../assets/caseManagement/sidebar/survivors-highlighted-icon.png";
import survivorsIcon from "../../../assets/caseManagement/sidebar/survivors-icon.png";
import CaseManagementNavbar from "../../../components/CaseManagementNavbar";
import Modal from "../../../components/Modal";
import { useDisasterObjContext } from "../../../context/DisasterObjContext";
import { useSidebarContext } from "../../../context/SidebarContext";
import { db } from "../../../firebase";
import citizenProfileService from "../../../service/citizen/CitizenProfile";

const employeeDetails = JSON.parse(sessionStorage.getItem("employeeDetails"));

export const caseManagementSidebarItems = [
  {
    id: "dashboard",
    label: "Dashboard",
    icon: (active) => (active ? dashboardHighlightedIcon : dashboardIcon),
  },
  {
    id: "survivors",
    label: "Cases",
    icon: (active) => (active ? survivorsHighlightedIcon : survivorsIcon),
    subItems: [
      {
        id: "irs",
        label: "Immediate Response Survey",
        subItems: [
          {
            id: "impactedaddress",
            label: "Impacted Address",
          },
          {
            id: "displacedaddress",
            label: "Displaced Address",
          },
          {
            id: "alternatecontact",
            label: "Alternate Contact",
          },
          {
            id: "householdinformation",
            label: "Household Information",
          },
          {
            id: "additionalhouseholdmembers",
            label: "Additional Household Members",
          },
          {
            id: "pets",
            label: "Pets",
          },
          {
            id: "generalsurveyquestion",
            label: "General Survey Question",
          },
        ],
      },
      {
        id: "damageassessment",
        label: "Damage Assessment",
        subItems: [
          {
            id: "general",
            label: "General",
          },
          {
            id: "homerepairandreplacement",
            label: "Home Repair and Replacement",
          },
          {
            id: "landscaping",
            label: "Landscaping",
          },
          {
            id: "vehicles",
            label: "Vehicles",
          },
          {
            id: "contents",
            label: "Contents",
          },
        ],
      },
      {
        id: "assistancelog",
        label: "Assistance Log",
        subItems: [
          {
            id: "general",
            label: "General",
          },
          {
            id: "homerepairandreplacement",
            label: "Home Repair and Replacement",
          },
          {
            id: "landscaping",
            label: "Landscaping",
          },
          {
            id: "vehicles",
            label: "Vehicles",
          },
          {
            id: "contents",
            label: "Contents",
          },
        ],
      },
      {
        id: "recoverytracker",
        label: "Unmet Needs",
        subItems: [
          {
            id: "general",
            label: "General",
          },
          {
            id: "homerepairandreplacement",
            label: "Home Repair and Replacement",
          },
          {
            id: "landscaping",
            label: "Landscaping",
          },
          {
            id: "vehicles",
            label: "Vehicles",
          },
          {
            id: "contents",
            label: "Contents",
          },
        ],
      },
      {
        id: "contacts",
        label: "Contacts",
      },
      {
        id: "journals",
        label: "Journals",
      },
      {
        id: "case-notes",
        label: "Case Notes",
      },
      {
        id: "lockbox",
        label: "Lockbox",
        subItems: [
          {
            id: "documents",
            label: "Documents",
          },
          {
            id: "photos",
            label: "Photos",
          },
        ],
      },
    ],
  },
  {
    id: "partners",
    label: "Partners",
    icon: (active) => (active ? partnerHighlightedIcon : partnersIcon),
  },
  ...(employeeDetails?.role === "Case Supervisor" || employeeDetails?.is_admin
    ? [
        {
          id: "casemgt",
          label: "Case Management Agency",
          icon: (active) => (active ? caseMgtHighlightedIcon : caseMgtIcon),
        },
      ]
    : []),
  {
    id: "casemanager",
    label: "Case Managers",
    icon: (active) => (active ? caseMgtHighlightedIcon : caseManagerIcon),
  },
  {
    id: "disaster",
    label: "Disaster",
    icon: (active) => (active ? disasterHighlightedIcon : disasterIcon),
    subItems: [
      {
        id: "overview",
        label: "Overview"
      },
      {
        id: "disaster_lockbox",
        label: "Disaster Lockbox",
      },
      {
        id: "survey_questions",
        label: "Survey Questions",
      },
      {
        id: "project",
        label: "Project",
      },
    ]
  },
  {
    id: "communitycontacts",
    label: "Community Contacts",
    icon: (active) =>
      active ? communityContactsHighlightedIcon : communityContactsIcon,
  },
  {
    id: "calendar",
    label: "Calendar",
    icon: (active) => (active ? calendarHighlightedIcon : calendarIcon),
  },
  // {
  //   id: "questions",
  //   label: "Survey Questions",
  //   icon: (active) => (active ? calendarHighlightedIcon : calendarIcon),
  // },
];

const CaseManagementLayout = ({ children, tab }) => {
  const { tab: selectedTab, subtab: selectedSubTab } = useParams();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [
    {
      clientName,
      caseNumber,
      birthDate,
      mobileNumber,
      email,
      fema_number,
      disaster_number,
    },
    setCaseDetails,
  ] = useState({
    clientName: "",
    caseNumber: "",
    birthDate: "",
    mobileNumber: "",
    email: "",
    fema_number: "",
    disaster_number: "",
  });
  // const [toggleSidebar, setToggleSidebar] = useState(true);
  const { toggleSidebar, setToggleSidebar } = useSidebarContext();

  const [toggleCaseDetailsModal, setToggleCaseDetailsModal] = useState(false);

  const [editPersonalInformationModal, setEditPersonalInformationModal] =
    useState(false);

  const { disaster } = useDisasterObjContext();

  const [citizenData, setCitizendata] = useState();

  const [accessedUserId, setAccessedUserId] = useState();
  
  const [lastVisitedDisasterSubTab, setLastVisitedDisasterSubTab] = useState(sessionStorage.getItem("lastVisitedDisasterSubTab") || "disasterinfo");

  const selectedUserId = sessionStorage.getItem("userId");

  const sessionAccessedUserId = sessionStorage.getItem("accessedUserId");

  const lastVisitedSubTab = sessionStorage.getItem("lastVisitedSubTab");

  const onNavigate = useCallback(
    (tab, subTab, anchor, activeTab) => () => {
      const searchParams = new URLSearchParams();

      if (activeTab) {
        searchParams.set("tab", activeTab);
      }

      navigate(
        `/cencasemanagement/${tab}/${
          subTab ||
          (tab === "survivors" ? lastVisitedSubTab || "" : "") ||
          (tab === "disaster" ? lastVisitedDisasterSubTab || "" : "") ||
          "none"
        }?${searchParams.toString()}`
      );

      if (!!anchor) {
        onScrollToAnchor(anchor)();
      }
    },
    [tab, lastVisitedSubTab, lastVisitedDisasterSubTab]
  );

  const onScrollToAnchor = useCallback(
    (anchorId) => () => {
      window.location.hash = anchorId;
      const element = document.getElementById(anchorId);
      if (!!element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    []
  );

  const onVisitSurvivors = useCallback(() => {
    const urlQueryParams = new URLSearchParams();
    urlQueryParams.set("tab", 2);

    navigate(
      `/cencasemanagement/survivors/${
        lastVisitedSubTab || "irs"
      }/?${urlQueryParams.toString()}`
    );
  }, [lastVisitedSubTab]);

  // const onVisitDisaster = useCallback(() => {
  //   const urlQueryParams = new URLSearchParams();
  //   urlQueryParams.set("tab", 5);

  //   navigate(
  //     `/cencasemanagement/disaster/${
  //       lastVisitedDisasterSubTab || "disasterinfo"
  //     }/?${urlQueryParams.toString()}`
  //   );
  // }, [lastVisitedDisasterSubTab]);

  const fetchName = async () => {
    try {
      const auth = getAuth();
      if (!auth.currentUser?.uid || !disaster) return;

      // Get the accessed user ID from current user's document
      const userDoc = await getDoc(doc(db, "users", selectedUserId));
      const userData = userDoc.data();
      const accessedUserId = userData.accessed_userId;

      if (!accessedUserId) {
        console.log("No accessed user found");
        return;
      }

      const surveyQuery = query(
        collection(db, `users/${accessedUserId}/survey_responses`)
      );

      const querySnapshot = await getDocs(surveyQuery);

      const parsedQuerySnapShot = querySnapshot.docs
        .map((doc) => doc.data())
        .find(
          ({ disasters }) =>
            !!disasters.find(({ disaster_id }) => disaster_id === disaster.id)
        );

      if (!!parsedQuerySnapShot) {
        setCaseDetails((prev) => ({
          ...prev,
          caseNumber: parsedQuerySnapShot.case_number,
          fema_number: parsedQuerySnapShot.fema_number,
          disaster_number: parsedQuerySnapShot.disaster_number,
        }));
      }

      // First fetch the default profile data
      const citizenData = await citizenProfileService.getCitizenProfile(
        "users",
        accessedUserId
      );

      setCitizendata(citizenData);
      setAccessedUserId(accessedUserId);

      if (citizenData) {
        // If we have selected disasters, try to fetch disaster-specific address
        if (!!disaster) {
          const impactedAddressRef = doc(
            db,
            `users/${accessedUserId}/impacted-addresses/${disaster.id}`
          );
          const impactedAddressDoc = await getDoc(impactedAddressRef);

          if (impactedAddressDoc.exists()) {
            // Use disaster-specific address data
            const addressData = impactedAddressDoc.data();
            setCaseDetails((prev) => ({
              ...prev,
              clientName: `${
                addressData.first_name || citizenData.first_name
              } ${addressData.last_name || citizenData.last_name}`,
              email: addressData.email || citizenData.email,
              mobileNumber:
                addressData.mobile_number || citizenData.mobile_number,
              birthDate: addressData.birth_date || citizenData.birth_date,
            }));
          } else {
            // Use default profile data for new disaster entry
            const fullName = `${citizenData.first_name} ${citizenData.last_name}`;
            setCaseDetails((prev) => ({
              ...prev,
              clientName: fullName,
            }));
          }
        } else {
          // Use default profile data when no disaster is selected
          const fullName = `${citizenData.first_name} ${citizenData.last_name}`;
          setCaseDetails((prev) => ({
            ...prev,
            clientName: fullName,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching name:", error);
    }
  };

  useEffect(() => {
    if (disaster && selectedUserId) {
      fetchName();
    }
  }, [disaster, selectedUserId, sessionAccessedUserId]);

  const renderSidebar = useMemo(() => {
    return (
      <div className="flex flex-col">
        {caseManagementSidebarItems.map(({ icon, label, id, subItems }) => {
          const isSelected = selectedTab === id;
          return (
            <div key={id} className="flex flex-col space-y-2">
              <div
                className={`flex cursor-pointer items-center space-x-3 rounded-md p-[12px] ${
                  isSelected ? "bg-[#003460]" : ""
                } ${!toggleSidebar ? "justify-center" : ""}`}
                onClick={onNavigate(id)}
              >
                <div className="h-[20px] w-[20px]">
                  <img
                    src={icon(isSelected)}
                    className="h-full w-full object-contain"
                  />
                </div>
                {toggleSidebar && (
                  <p className={`${isSelected ? "text-white" : ""} text-sm`}>
                    {label}
                  </p>
                )}
              </div>
              {toggleSidebar &&
                !!subItems &&
                !!subItems.length &&
                isSelected && 
                !!selectedSubTab &&
                selectedSubTab !== "none" && (
                  <div className="flex flex-col">
                    {subItems.map(
                      ({ label, id: subItemId, subItems: anchorSub }) => {
                        const isSelectedSubTab = subItemId === selectedSubTab;
                        return (
                          <div className="flex flex-col" key={subItemId}>
                            <div
                              key={subItemId}
                              className={`cursor-pointer rounded-md px-[30px] py-[12px] ${
                                isSelectedSubTab ? "bg-[#FFF3CE]" : ""
                              }`}
                              onClick={() => {
                                onNavigate(
                                  id,
                                  subItemId,
                                  !!anchorSub && !!anchorSub.length
                                    ? anchorSub[0].id
                                    : null,
                                  selectedTab === "survivors" ? 2 : 5
                                )();
                                if (selectedTab === "survivors") {
                                  sessionStorage.setItem(
                                    "lastVisitedSubTab",
                                    subItemId
                                  );
                                } else if (selectedTab === "disaster") {
                                  sessionStorage.setItem(
                                    "lastVisitedDisasterSubTab",
                                    subItemId
                                  );
                                  setLastVisitedDisasterSubTab(subItemId);
                                }
                              }}
                            >
                              <p className="text-sm">{label}</p>
                            </div>
                            <div className="flex flex-col">
                              {!!anchorSub &&
                                !!anchorSub.length &&
                                isSelectedSubTab && 
                                anchorSub.map(
                                  (
                                    { id: anchorId, label: anchorLabel },
                                    k,
                                    arr
                                  ) => {
                                    const lastItemIndex = arr.length - 1;
                                    const isLastItem = k === lastItemIndex;
                                    return (
                                      <div
                                        key={anchorId}
                                        className={`relative flex h-[40px] cursor-pointer items-center space-x-2 rounded-md pl-[25px] pr-[10px] ${
                                          anchorId &&
                                          hash.substring(1) === anchorId
                                            ? "bg-[#F3F3F3]"
                                            : ""
                                        } ${isLastItem === k ? "mb-2" : ""}`}
                                        onClick={onScrollToAnchor(anchorId)}
                                      >
                                        <div className="h-[40px] w-[24px]">
                                          <img
                                            src={
                                              isLastItem
                                                ? anchorLastIndexIcon
                                                : anchorIcon
                                            }
                                            className="h-full w-full object-contain"
                                          />
                                        </div>
                                        <div className="flex-1">
                                          <p className="text-sm">
                                            {anchorLabel}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
            </div>
          );
        })}
      </div>
    );
  }, [
    selectedTab,
    caseManagementSidebarItems,
    selectedSubTab,
    hash,
    toggleSidebar,
    onNavigate,
  ]);

  const EditPersonalInformationModal = ({
    editPersonalInformationModal,
    setEditPersonalInformationModal,
    citizenData,
    accessedUserId,
    disaster,
    fema_number,
    disaster_number,
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      defaultValues: {
        fema_no: "",
        contract_no: "",
      },
    });

    useEffect(() => {
      setValue("fema_no", fema_number || "");
      setValue("contract_no", disaster_number || "");
    }, [fema_number, disaster_number, setValue]);

    const onSubmit = async (data) => {
      try {
        if (!accessedUserId || !disaster) {
          console.error("Missing user ID or disaster information.");
          return;
        }

        const surveyQuery = query(
          collection(db, `users/${accessedUserId}/survey_responses`)
        );

        const querySnapshot = await getDocs(surveyQuery);

        const surveyDoc = querySnapshot.docs.find((doc) =>
          doc.data().disasters.some((d) => d.disaster_id === disaster.id)
        );

        if (!surveyDoc) {
          console.error("No matching survey response found.");
          return;
        }

        const surveyDocRef = doc(
          db,
          `users/${accessedUserId}/survey_responses`,
          surveyDoc.id
        );

        await updateDoc(surveyDocRef, {
          fema_number: data.fema_no,
          disaster_number: data.contract_no,
        });

        console.log("Successfully updated FEMA and Contract number.");
        fetchName();
        setEditPersonalInformationModal(false);
      } catch (error) {
        console.error("Error updating data:", error);
      }
    };

    const handleFormSubmit = () => {
      handleSubmit(onSubmit)();
      fetchName();
    };

    return editPersonalInformationModal ? (
      <Modal
        isOpen={editPersonalInformationModal}
        title={"Edit Personal Information"}
        onClose={() => {
          setEditPersonalInformationModal(false);
        }}
        customOnRightButtonClick={handleFormSubmit}
      >
        <form className="relative flex flex-col gap-5">
          {/* Birth Date and Contact No. */}
          <div className="flex w-full flex-row gap-5">
            {/* Date of Birth - Read Only */}
            <div className="flex flex-1 flex-row items-center rounded border border-solid border-[#CFCFCF] p-2 px-3 text-gray1-100">
              <div className="flex flex-1 flex-col items-start gap-1">
                <p className="text-[#8F8F8F]">Date of Birth</p>
                <p className="text-sm font-normal text-gray1-100">
                  {citizenData?.birth_date
                    ? format(new Date(citizenData.birth_date), "MM/dd/yyyy")
                    : "N/A"}
                </p>
              </div>
              <CalendarDays className="text-[#CFCFCF]" />
            </div>

            {/* Contact Number - Read Only */}
            <div className="flex flex-1 flex-row items-center rounded border border-solid border-[#CFCFCF] p-2 px-3 text-gray1-100">
              <div className="flex flex-1 flex-col items-start gap-1">
                <p className="text-[#8F8F8F]">Phone No.</p>
                <p className="text-sm font-normal text-gray1-100">
                  {citizenData?.mobile_number || "N/A"}
                </p>
              </div>
            </div>
          </div>
          {/* Email Address - Read Only */}
          <div className="flex flex-1 flex-row items-center rounded border border-solid border-[#CFCFCF] p-2 px-3 text-gray1-100">
            <div className="flex flex-1 flex-col items-start gap-1">
              <p className="text-[#8F8F8F]">Email Address</p>
              <p className="text-sm font-normal text-gray1-100">
                {citizenData?.email || "N/A"}
              </p>
            </div>
          </div>

          {/* FEMA No. and Disaster No. - Editable */}
          <div className="flex w-full flex-row gap-5">
            {/* FEMA No. */}
            <div
              className={`flex flex-1 flex-row items-center rounded border border-solid border-[#CFCFCF] p-2 px-3 text-gray1-100 
              transition-all focus-within:border-blue-500 ${
                errors["fema_no"] && "border-red-500 text-red-500"
              }`}
            >
              <div className="flex flex-1 flex-col items-start gap-1">
                <p className="text-[#8F8F8F]">FEMA No.</p>
                <input
                  type="text"
                  {...register("fema_no", {
                    required: "FEMA number is required.",
                  })}
                  className="w-full border-none bg-transparent text-sm text-gray1-100 outline-none"
                  placeholder="Input FEMA no."
                />
              </div>
            </div>

            {/* Disaster No. */}
            <div
              className={`flex flex-1 flex-row items-center rounded border border-solid border-[#CFCFCF] p-2 px-3 text-gray1-100 
              transition-all focus-within:border-blue-500 ${
                errors["contract_no"] && "border-red-500 text-red-500"
              }`}
            >
              <div className="flex flex-1 flex-col items-start gap-1">
                <p className="text-[#8F8F8F]">Disaster No.</p>
                <input
                  type="text"
                  {...register("contract_no", {
                    required: "Contract number is required.",
                  })}
                  className="w-full border-none bg-transparent text-sm text-gray1-100 outline-none"
                  placeholder="Input contract no."
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    ) : null;
  };

  const renderCaseDetails = useMemo(() => {
    return (
      <>
        <EditPersonalInformationModal
          setEditPersonalInformationModal={setEditPersonalInformationModal}
          editPersonalInformationModal={editPersonalInformationModal}
          citizenData={citizenData}
          accessedUserId={accessedUserId}
          disaster={disaster}
          fema_number={fema_number}
          disaster_number={disaster_number}
        />
        <div className="mb-2 flex w-full items-center justify-between">
          <div className="text-3xl">
            <button
              className="cursor-pointer bg-transparent"
              onClick={onVisitSurvivors}
            >
              <p className="text-[16px] font-bold">{clientName || ""}</p>
            </button>
            {caseNumber && (
              <span className="text-[#525252]">
                {" "}
                (Case No. {caseNumber || ""})
              </span>
            )}
          </div>
          <div className="mr-3 flex items-center space-x-3">
            <div>
              <div className="flex gap-1">
                <p className="w-24 text-right text-[14px] text-[#525252]">
                  Disaster No.
                </p>
                <p className="text-[14px] text-[#525252]">
                  {disaster_number || ""}
                </p>
              </div>
              <div className="flex gap-1">
                <p className="w-24 text-right text-[14px] text-[#525252]">
                  FEMA No.
                </p>
                <p className="text-[14px] text-[#525252]">
                  {fema_number || ""}
                </p>
              </div>
            </div>

            <button
              className="h-[24px] w-[24px] cursor-pointer"
              onClick={() => setEditPersonalInformationModal(true)}
            >
              <img src={editIcon} className="h-full w-full object-contain" />
            </button>
          </div>
        </div>
        <div className="mb-2 flex w-full flex-row items-center gap-4">
          <div className="flex items-center gap-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_9807_680"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_9807_680)">
                <path
                  d="M4 22.0031C3.71667 22.0031 3.47917 21.9073 3.2875 21.7156C3.09583 21.524 3 21.2865 3 21.0031V17.0031C3 16.4531 3.19583 15.9823 3.5875 15.5906C3.97917 15.199 4.45 15.0031 5 15.0031H19C19.55 15.0031 20.0208 15.199 20.4125 15.5906C20.8042 15.9823 21 16.4531 21 17.0031V21.0031C21 21.2865 20.9042 21.524 20.7125 21.7156C20.5208 21.9073 20.2833 22.0031 20 22.0031H4ZM5 13.0031V10.0031C5 9.45313 5.19583 8.98229 5.5875 8.59063C5.97917 8.19896 6.45 8.00313 7 8.00313H11V6.55313C10.7 6.35313 10.4583 6.11146 10.275 5.82813C10.0917 5.54479 10 5.20313 10 4.80313C10 4.55313 10.05 4.30729 10.15 4.06563C10.25 3.82396 10.4 3.60313 10.6 3.40313L11.65 2.35313C11.6833 2.31979 11.8 2.26979 12 2.20312C12.0333 2.20312 12.15 2.25312 12.35 2.35313L13.4 3.40313C13.6 3.60313 13.75 3.82396 13.85 4.06563C13.95 4.30729 14 4.55313 14 4.80313C14 5.20313 13.9083 5.54479 13.725 5.82813C13.5417 6.11146 13.3 6.35313 13 6.55313V8.00313H17C17.55 8.00313 18.0208 8.19896 18.4125 8.59063C18.8042 8.98229 19 9.45313 19 10.0031V13.0031H5Z"
                  fill="#770C0E"
                />
              </g>
            </svg>

            {birthDate && (
              <span>
                {new Date(birthDate).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </span>
            )}
          </div>
          <div className="flex items-center gap-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_9807_685"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_9807_685)">
                <path
                  d="M10.5 20H13.5C13.6333 20 13.75 19.95 13.85 19.85C13.95 19.75 14 19.6333 14 19.5C14 19.3667 13.95 19.25 13.85 19.15C13.75 19.05 13.6333 19 13.5 19H10.5C10.3667 19 10.25 19.05 10.15 19.15C10.05 19.25 10 19.3667 10 19.5C10 19.6333 10.05 19.75 10.15 19.85C10.25 19.95 10.3667 20 10.5 20ZM7 23C6.45 23 5.97917 22.8042 5.5875 22.4125C5.19583 22.0208 5 21.55 5 21V3C5 2.45 5.19583 1.97917 5.5875 1.5875C5.97917 1.19583 6.45 1 7 1H17C17.55 1 18.0208 1.19583 18.4125 1.5875C18.8042 1.97917 19 2.45 19 3V21C19 21.55 18.8042 22.0208 18.4125 22.4125C18.0208 22.8042 17.55 23 17 23H7ZM7 16H17V6H7V16Z"
                  fill="#770C0E"
                />
              </g>
            </svg>

            <span>{mobileNumber || ""}</span>
          </div>
          <div className="flex items-center gap-2">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2ZM10 8.825C10.0833 8.825 10.1708 8.8125 10.2625 8.7875C10.3542 8.7625 10.4417 8.725 10.525 8.675L17.6 4.25C17.7333 4.16667 17.8333 4.0625 17.9 3.9375C17.9667 3.8125 18 3.675 18 3.525C18 3.19167 17.8583 2.94167 17.575 2.775C17.2917 2.60833 17 2.61667 16.7 2.8L10 7L3.3 2.8C3 2.61667 2.70833 2.6125 2.425 2.7875C2.14167 2.9625 2 3.20833 2 3.525C2 3.69167 2.03333 3.8375 2.1 3.9625C2.16667 4.0875 2.26667 4.18333 2.4 4.25L9.475 8.675C9.55833 8.725 9.64583 8.7625 9.7375 8.7875C9.82917 8.8125 9.91667 8.825 10 8.825Z"
                fill="#770C0E"
              />
            </svg>
            <span>{email || ""}</span>
          </div>
        </div>
      </>
    );
  }, [
    clientName,
    caseNumber,
    birthDate,
    mobileNumber,
    email,
    toggleCaseDetailsModal,
    editPersonalInformationModal,
    onVisitSurvivors,
    setEditPersonalInformationModal,
  ]);

  return (
    <div className="h-full w-full">
      <CaseManagementNavbar setToggleSidebar={setToggleSidebar} />
      <div className="flex h-[92vh] w-full overflow-y-auto">
        <div
          className={`flex flex-col items-center space-y-5 overflow-y-auto border-r border-solid border-[#CFCFCF] px-[8px] py-[20px] transition-all duration-75 ease-in-out ${
            toggleSidebar ? "w-[335px]" : "w-[80px]"
          }`}
        >
          <div className={`h-[100px] ${toggleSidebar ? "w-[100px]" : ""}`}>
            <img
              src={blueSkyTransparentIcon}
              className={`h-full w-full object-contain transition-all ease-in-out ${
                toggleSidebar ? "scale-100" : "scale-90"
              }`}
            />
          </div>
          <div className="flex w-full flex-1 flex-col justify-between">
            {renderSidebar}
          </div>
        </div>
        <div className="flex h-full w-full flex-col overflow-y-auto">
          {selectedTab !== "dashboard" && (
            <div className="mx-5 pt-5">{renderCaseDetails}</div>
          )}

          {children}
        </div>
      </div>
    </div>
  );
};

export default CaseManagementLayout;
