import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { memo, useEffect, useState } from "react";
import { Months } from "../utils/constants";

const DatePickerTemplate = ({
  label,
  onChange,
  value,
  labelClassName,
  className,
  errors = {},
  name,
}) => {

  const [date, setDate] = useState(() => {
    if (!value) return { month: null, day: null, year: null };
    
    try {
      const parts = value.split("/");
      return {
        month: parts[0] ? parseInt(parts[0]) : null,
        day: parts[1] ? parseInt(parts[1]) : null,
        year: parts[2] ? parseInt(parts[2]) : null,
      };
    } catch (error) {
      return { month: null, day: null, year: null };
    }
  });

  const dayDataSource = Array.from({ length: 31 }, (_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  }));

  const startYear = 1899;
  const endYear = 2024;

  const yearDataSource = Array.from(
    { length: endYear - startYear },
    (_, index) => ({
      label: `${endYear - index}`,
      value: endYear - index,
    })
  );

  const field = { text: "label", value: "value" };

  const onDropDownChange =
    (key) =>
    ({ value }) => {
      setDate((prev) => ({ ...prev, [key]: value }));
    };

  useEffect(() => {
    if (Object.values(date).some((d) => !d)) return;
    onChange(`${date.month}/${date.day}/${date.year}`);
  }, [date]);

  return (
    <div className={`${className || ""}`}>
      {!!label && (
        <p className={`text-smokey-grey ${labelClassName || ""}`}>{label}</p>
      )}
      <div className="flex space-x-3">
        <DropDownListComponent
          dataSource={Months}
          fields={field}
          placeholder="Month"
          floatLabelType="Auto"
          width={80}
          {...(date.month && { value: date.month })}
          onChange={onDropDownChange("month")}
        />
        <DropDownListComponent
          dataSource={dayDataSource}
          fields={field}
          placeholder="Day"
          floatLabelType="Auto"
          width={70}
          {...(date.day && { value: date.day })}
          onChange={onDropDownChange("day")}
        />
        <DropDownListComponent
          dataSource={yearDataSource}
          fields={field}
          placeholder="Year"
          floatLabelType="Auto"
          width={80}
          {...(date.year && { value: date.year })}
          onChange={onDropDownChange("year")}
        />
      </div>
      {errors && name && errors[name] && (
        <p className="mt-1 text-xs text-red-500">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default memo(DatePickerTemplate);