import React, { useState, useRef, useEffect } from 'react';

const CustomMultiSelect = ({
  id,
  label,
  options,
  value = [],
  onChange,
  placeholder = "Select items",
  error
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredOptions = options.filter(option =>
    option.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggleOption = (optionValue) => {
    const newValue = value.includes(optionValue)
      ? value.filter(v => v !== optionValue)
      : [...value, optionValue];
    onChange(newValue);
  };

  const handleSelectAll = () => {
    const allValues = options.map(option => option.value);
    onChange(value.length === options.length ? [] : allValues);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {label && (
        <label htmlFor={id} className="mb-2 block text-xs text-gray-500">
          {label}
        </label>
      )}
      
      <div
        className={`border rounded-md p-2 min-h-[38px] cursor-pointer bg-white
          ${error ? 'border-red-500' : 'border-gray-300'}
          ${isOpen ? 'ring-2 ring-blue-500 border-blue-500' : 'hover:border-gray-400'}
        `}
        onClick={() => setIsOpen(!isOpen)}
      >
        {value.length > 0 ? (
          <div className="flex flex-wrap gap-1">
            {value.map(v => {
              const option = options.find(opt => opt.value === v);
              return (
                <span
                  key={v}
                  className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full"
                >
                  {option?.text}
                </span>
              );
            })}
          </div>
        ) : (
          <span className="text-gray-500">{placeholder}</span>
        )}
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="p-2 border-b">
            <input
              type="text"
              className="w-full px-2 py-1 border border-gray-300 rounded-md text-sm"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>

          <div
            className="px-3 py-2 hover:bg-gray-100 cursor-pointer border-b text-sm flex items-center"
            onClick={(e) => {
              e.stopPropagation();
              handleSelectAll();
            }}
          >
            <input
              type="checkbox"
              checked={value.length === options.length}
              onChange={() => {}}
              className="rounded border-gray-300 mr-2"
            />
            <span className="flex-grow">
              {value.length === options.length ? 'Unselect All' : 'Select All'}
            </span>
          </div>

          <div className="max-h-48 overflow-y-auto">
            {filteredOptions.map(option => (
              <div
                key={option.value}
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm flex items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleOption(option.value);
                }}
              >
                <input
                  type="checkbox"
                  checked={value.includes(option.value)}
                  onChange={() => {}}
                  className="rounded border-gray-300 mr-2"
                />
                <span className="flex-grow">
                  {option.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {error && (
        <div className="mt-1 text-xs text-red-500">
          {error}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;